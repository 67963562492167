import React, { Component } from "react";
import $ from "jquery";
import moment from "moment";
import { Row, Col, Modal, Button } from "react-bootstrap";
import Icon from "react-icons-kit";

import { locationArrow } from "react-icons-kit/fa/locationArrow";
import { clockO } from "react-icons-kit/fa/clockO";
import { chevronCircleRight } from "react-icons-kit/fa/chevronCircleRight";
import { ic_computer } from "react-icons-kit/md/ic_computer";
import SingleOpportunityApplyModal from "./SingleOpportunity/SingleOpportunityApplyModal";
import { ArrowRight } from "react-feather";
import { ic_keyboard_arrow_right } from "react-icons-kit/md/ic_keyboard_arrow_right";
import Pagination from "../MyVolunteerHistory/Pagination";
import { generatePreSignedGetUrl } from "../../../../s3PresignedUrls";
import configs from "../../../../config/urlConfigs";
import "quill/dist/quill.snow.css";

export default class DiscoverOpportunitiesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReserveModal: false,
      rowsPerPage: 10,
      // rowsPerPage: 25,
      currentPage: props.pageNumber || 1,
    };

    this.closeReserveModal = this.closeReserveModal.bind(this);
    this.openReserveModal = this.openReserveModal.bind(this);
  }

  closeReserveModal() {
    this.setState({ showReserveModal: false });
  }

  openReserveModal(e) {
    let volunteerOpportunityId = e.currentTarget.getAttribute("data-opp-id");

    this.setState({
      showReserveModal: true,
      volunteerOpportunityId,
    });
  }

  componentDidMount() {}

  fetchOpportunities = (pageNumber) => {
    // fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/admin-discover' , {

    fetch(
      configs.BACKEND_API_URL +
        "/ee/volunteer-opportunity/ee-discover" +
        (pageNumber ? `?pageNumber=${pageNumber}` : ""),
      {
        method: "GET",
        headers: {
          Auth: JSON.parse(readCookie("access_token")).access_token,
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {
          this.props.updateOpportunities(data.data, data.totalOpportunities);
        }.bind(this)
      );
  };

  // Change page
  paginate = (pageNumber) => {
    this.fetchOpportunities(pageNumber);
    this.props.updateOpportunities(null);
    this.props.updatePageNumber(pageNumber);
    this.setState({
      currentPage: pageNumber,
    });
  };

  pageSize = (Number) => {
    this.setState({
      rowsPerPage: Number,
    });
  };
  render() {
    // const indexOfLastRow = this.state.currentPage * this.state.rowsPerPage;
    // const indexOfFirstRow = indexOfLastRow - this.state.rowsPerPage;
    // const currentRows = this.props.opportunities.slice(
    //   indexOfFirstRow,
    //   indexOfLastRow
    // );
    if (this.props.opportunities.length) {
      return (
        <div className="discover-opportunities-container">
          <Row>
            {this.props.opportunities.map(
              function (opportunity, i) {
                // {currentRows.map(function (opportunity, i) {
                return (
                  <div key={i}>
                    <Col md={12} className="ee-single-opportunity">
                      <Row>
                        {/* Adding hyperlink to the opportunity details */}
                        <a
                          href={
                            opportunity.dates && opportunity.dates.length
                              ? "/opportunity/" + opportunity.dates[0].customUrl
                              : "#"
                          }
                        >
                          <Col
                            md={3}
                            className="ee-single-opportunity-img-container"
                          >
                            {opportunity.image ? (
                              <div
                                className="ee-single-opportunity-img"
                                style={{
                                  backgroundImage:
                                    "url(" +
                                    generatePreSignedGetUrl(opportunity.image) +
                                    ")",
                                  backgroundSize: "contain",
                                }}
                              ></div>
                            ) : (
                              <div
                                className="ee-single-opportunity-img"
                                style={{
                                  backgroundImage:
                                    "url(https://assets.letsendorse.com/sahaj/project-placeholder.jpg)",
                                }}
                              ></div>
                            )}
                          </Col>
                        </a>
                        <div className="volunteer current-state opportunity-status">
                          {(() => {
                            if (opportunity.status === "Pending HR Approval") {
                              return (
                                <span className="current-state-outer submitted">
                                  <span className="current-state-text">
                                    Pending Approval
                                  </span>
                                </span>
                              );
                            } else if (opportunity.status === "Completed") {
                              return (
                                <span className="current-state-outer completed">
                                  <span className="current-state-text">
                                    Completed
                                  </span>
                                </span>
                              );
                            } else if (opportunity.status === "Live") {
                              return (
                                <span className="current-state-outer live">
                                  <span className="current-state-text">
                                    Upcoming
                                  </span>
                                </span>
                              );
                            } else {
                              return (
                                <span
                                  className={
                                    opportunity.status === "Cancelled"
                                      ? "current-state-outer cancelled"
                                      : "current-state-outer live"
                                  }
                                >
                                  <span className="current-state-text">
                                    {opportunity.status}
                                  </span>
                                </span>
                              );
                            }
                          })()}
                        </div>
                        <Col
                          md={9}
                          className="ee-single-opportunity-details-container"
                        >
                          {/* Adding hyperlink to the opportunity details */}
                          <a
                            style={{ cursor: "pointer" }}
                            href={
                              opportunity.dates && opportunity.dates.length
                                ? "/opportunity/" +
                                  opportunity.dates[0].customUrl
                                : "#"
                            }
                            target="_blank"
                          >
                            <div className="ee-title-section">
                              <a className="ee-single-opportunity-title">
                                {opportunity.title}
                              </a>
                            </div>
                          </a>
                          <div className="ee-location-date-section">
                            {opportunity.address1 && (
                              <span className="ee-location" sty>
                                <Icon
                                  className="pad-right-5"
                                  icon={locationArrow}
                                />
                                <span>{opportunity.address1}</span>
                              </span>
                            )}
                            {!opportunity.physicalOpportunity && (
                              <span className="ee-location">
                                <Icon
                                  className="pad-right-5"
                                  icon={ic_computer}
                                />
                                <span>Virtual</span>
                              </span>
                            )}
                            <span className="ee-event-time">
                              <Icon className="pad-right-5" icon={clockO} />
                              <span>
                                {opportunity.startTime +
                                  " - " +
                                  opportunity.endTime}
                              </span>
                            </span>
                          </div>
                          <div
                            className="ql-editor project-description recomended-opportunity-card-des"
                            style={{
                               
								color: "#707070",
								display: '-webkit-box',
								WebkitLineClamp: '2',
								WebkitBoxOrient: 'vertical',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								maxHeight: '51px'
                            }}
                            dangerouslySetInnerHTML={{
                              __html: opportunity.description,
                            }}
                          />
                          {/* <p className='project-description recomended-opportunity-card-des' style={{ WebkitLineClamp: '2' }}>{opportunity.description}</p> */}
                          <div
                            className="ee-related-project-section"
                            style={{ paddingLeft: 0 }}
                          >
                            <p className="project-desc-click-to-know">
                              Click on the date to know more{" "}
                              <Icon
                                className="pad-right-5"
                                icon={ic_keyboard_arrow_right}
                              />
                            </p>
                            {opportunity.dates.map(
                              function (date, i) {
                                if (date.status === "Ongoing") {
                                  return (
                                    <div
                                      className="date-wise-apply-button-container"
                                      key={i}
                                    >
                                      <a
                                        href={"/opportunity/" + date.customUrl}
                                        className={
                                          "ee-apply-btn btn-le date-wise-apply-button ongoing"
                                        }
                                        target="_blank"
                                      >
                                        {moment(date.date, "DD/MM/YYYY").format(
                                          "ddd, DD MMM YYYY"
                                        )}
                                      </a>
                                      {date.isUserAttendee ? (
                                        <div className="hover-container">
                                          <span className="booked">
                                            You have reserved this
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="hover-container">
                                          Currently Ongoing
                                        </div>
                                      )}
                                    </div>
                                  );
                                } else if (date.status === "Live") {
                                  if (date.isOpen) {
                                    return (
                                      <div
                                        className="date-wise-apply-button-container"
                                        key={i}
                                      >
                                        <a
                                          href={
                                            "/opportunity/" + date.customUrl
                                          }
                                          className={
                                            date.isUserAttendee
                                              ? "ee-apply-btn btn-le date-wise-apply-button user-attending"
                                              : "ee-apply-btn btn-le date-wise-apply-button"
                                          }
                                          target="_blank"
                                        >
                                          {moment(
                                            date.date,
                                            "DD/MM/YYYY"
                                          ).format("ddd, DD MMM YYYY")}
                                        </a>
                                        {date.isUserAttendee ? (
                                          <div className="hover-container">
                                            <span className="booked">
                                              You have reserved this
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="hover-container">
                                            {date.volunteersRequired -
                                              date.totalAttendees}{" "}
                                            slots available
                                            <br />
                                            {date.volunteersRequired ===
                                            date.totalAttendees ? (
                                              <span className="filling-fast">
                                                Filled
                                              </span>
                                            ) : (date.totalAttendees /
                                                date.volunteersRequired) *
                                                100 >
                                              60 ? (
                                              <span className="filling-fast">
                                                Filling Fast
                                              </span>
                                            ) : (
                                              <span className="available">
                                                Available
                                              </span>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div
                                        className="date-wise-apply-button-container"
                                        key={i}
                                      >
                                        <a
                                          href={
                                            "/opportunity/" + date.customUrl
                                          }
                                          target="_blank"
                                          className={
                                            date.isUserAttendee
                                              ? "ee-apply-btn btn-le date-wise-apply-button user-attending"
                                              : "ee-apply-btn btn-le date-wise-apply-button unavailable"
                                          }
                                        >
                                          {moment(
                                            date.date,
                                            "DD/MM/YYYY"
                                          ).format("DD MMM YYYY")}
                                        </a>
                                        {date.isUserAttendee ? (
                                          <div className="hover-container">
                                            <span className="booked">
                                              You have reserved this
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="hover-container">
                                            All slots filled
                                          </div>
                                        )}
                                      </div>
                                    );
                                  }
                                } else if (date.status === "Completed") {
                                  return (
                                    <div
                                      className="date-wise-apply-button-container"
                                      key={i}
                                    >
                                      <a
                                        href={"/opportunity/" + date.customUrl}
                                        className="ee-apply-btn btn-le date-wise-apply-button unavailable"
                                        target="_blank"
                                      >
                                        {moment(date.date, "DD/MM/YYYY").format(
                                          "DD MMM YYYY"
                                        )}
                                      </a>
                                      {date.isUserAttendee ? (
                                        <div className="hover-container">
                                          <span className="booked">
                                            You had reserved this
                                          </span>
                                        </div>
                                      ) : null}
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      className="date-wise-apply-button-container"
                                      key={i}
                                    >
                                      {date.status === "Cancelled" ? (
                                        <a
                                          style={{ cursor: "default" }}
                                          className={
                                            date.isUserAttendee
                                              ? "ee-apply-btn btn-le date-wise-apply-button user-attending"
                                              : "ee-apply-btn btn-le date-wise-apply-button unavailable"
                                          }
                                        >
                                          {moment(
                                            date.date,
                                            "DD/MM/YYYY"
                                          ).format("DD MMM YYYY")}
                                        </a>
                                      ) : (
                                        <a
                                          href={
                                            "/opportunity/" + date.customUrl
                                          }
                                          target="_blank"
                                          className={
                                            date.isUserAttendee
                                              ? "ee-apply-btn btn-le date-wise-apply-button user-attending"
                                              : "ee-apply-btn btn-le date-wise-apply-button unavailable"
                                          }
                                        >
                                          {moment(
                                            date.date,
                                            "DD/MM/YYYY"
                                          ).format("DD MMM YYYY")}
                                        </a>
                                      )}
                                    </div>
                                  );
                                }
                              }.bind(this)
                            )}
                          </div>
                        </Col>
                      </Row>
                      <div className="le-divider" />
                    </Col>
                  </div>
                );
              }.bind(this)
            )}
          </Row>

          {/*<SingleOpportunityApplyModal volunteerOpportunity={this.state.volunteerOpportunity}
	          showReserveModal={this.state.showReserveModal} closeReserveModal={this.closeReserveModal}
	          updateSelectedOpportunity={this.props.actions.updateSelectedOpportunity}
	          openReserveModal={this.openReserveModal} /> */}
          <Pagination
            rowsPerPage={this.state.rowsPerPage}
            totalRows={this.props.totalOpportunities}
            paginate={this.paginate}
            pageSize={this.pageSize}
            currentPage={this.state.currentPage}
            url="/discover-opportunities"
          />
        </div>
      );
    } else {
      return (
        <Row>
          <Col md={12} className="text-center">
            <p className="no-data-text">No Opportunities Found</p>
          </Col>
        </Row>
      );
    }
  }
}
