const initialState = {
	leaderboard: null,
	filterType: 'hours',
	selectedOpportunity: {},
};

const EEAdminLeaderboardReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_LEADERBOARD':
			return Object.assign({}, state, {
				leaderboard: action.leaderboard,
			});
		case 'UPDATE_FILTER_TYPE':
			return Object.assign({}, state, {
				filterType: action.filterType,
			});
		default:
			return state
	}
}

export default EEAdminLeaderboardReducer;