import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

export default class EndorseMoneyPage extends Component {
	onUpdateAmountBlur(e) {
		if(e.target.value < 500) {
			this.props.updateEndorseAmount(500);
			this.props.updateFinalPayableAmount(500);
			if(this.props.usePayroll) {
				if(500 >= this.props.userData.payroll.current.pending) {
					let extraAmount = 500 - this.props.userData.payroll.current.pending;
					this.props.updateUsePayrollAmount(500 - extraAmount);
					this.props.updateFinalPayableAmount(extraAmount);
				} else {
					this.props.updateUsePayrollAmount(parseInt(e.target.value, 10));
				}
			} else {
				this.props.updateUsePayrollAmount(0)
			}
		} else {
			this.props.updateEndorseAmount(parseInt(e.target.value, 10));
			if(this.props.usePayroll) {
				if(500 >= this.props.userData.payroll.current.pending) {
					let extraAmount = 500 - this.props.userData.payroll.current.pending;
					this.props.updateUsePayrollAmount(500 - extraAmount);
					this.props.updateFinalPayableAmount(extraAmount);
				} else {
					this.props.updateUsePayrollAmount(parseInt(e.target.value, 10));
				}
			} else {
				this.props.updateUsePayrollAmount(0)
			}
		}
	}

	onUpdateUnitBlur(e) {
		if(e.target.value < 1) {
			this.props.updateEndorseUnit(1)
			this.props.updateEndorseUnitAmount(parseInt(this.props.project.unitAmount, 10));
			this.props.updateFinalPayableAmount(0);
			if(this.props.usePayroll) {
				if(parseInt(this.props.project.unitAmount, 10) >= this.props.userData.payroll.current.pending) {
					let extraAmount = parseInt(this.props.project.unitAmount, 10) - this.props.userData.payroll.current.pending
					this.props.updateUsePayrollAmount(parseInt(this.props.project.unitAmount, 10) - extraAmount);
					this.props.updateFinalPayableAmount(extraAmount);
				} else {
					this.props.updateUsePayrollAmount(parseInt(this.props.project.unitAmount, 10));
					this.props.updateFinalPayableAmount(0);
				}
			} else {
				this.props.updateUsePayrollAmount(0)
				this.props.updateFinalPayableAmount(parseInt(this.props.project.unitAmount, 10));
			}
		}
	}

	handleChange(field, e) {
		if (field === 'endorseTextReason') {
			this.props.updateEndorseReasonText(e.target.value);
		} else if (field === 'paymentType') {
			this.props.updatePaymentType(e.target.value);
		} else if (field === 'endorseType') {
			this.props.updateEndorseType(e.target.value);
			if(this.props.usePayroll) {
				if(e.target.value === 'unit') {
					if(this.props.endorseUnitAmount >= this.props.userData.payroll.current.pending) {
						let extraAmount = this.props.endorseUnitAmount - this.props.userData.payroll.current.pending;
						this.props.updateUsePayrollAmount(this.props.endorseUnitAmount - extraAmount);
						this.props.updateFinalPayableAmount(extraAmount);
					} else {
						this.props.updateUsePayrollAmount(this.props.endorseUnitAmount);
						this.props.updateFinalPayableAmount(0);
					}
				} else {
					if(this.props.endorseAmount >= this.props.userData.payroll.current.pending) {
						let extraAmount = this.props.endorseAmount - this.props.userData.payroll.current.pending;
						this.props.updateUsePayrollAmount(this.props.endorseAmount - extraAmount);
						this.props.updateFinalPayableAmount(extraAmount);
					} else {
						this.props.updateUsePayrollAmount(this.props.endorseAmount);
						this.props.updateFinalPayableAmount(0);
					}
				}
			} else {
				this.props.updateUsePayrollAmount(0);
				if(e.target.value === 'unit') {
					this.props.updateUsePayrollAmount(0);
					this.props.updateFinalPayableAmount(this.props.endorseUnitAmount);
				} else {
					this.props.updateUsePayrollAmount(0);
					this.props.updateFinalPayableAmount(this.props.endorseAmount);
				}
			}
		} else if (field === 'endorseAmount') {
			this.props.updateEndorseAmount(parseInt(e.target.value, 10));
			this.props.updateFinalPayableAmount(parseInt(e.target.value, 10));
			if(this.props.usePayroll) {
				if(parseInt(e.target.value, 10) >= this.props.userData.payroll.current.pending) {
					let extraAmount = parseInt(e.target.value, 10) - this.props.userData.payroll.current.pending
					this.props.updateUsePayrollAmount(parseInt(e.target.value, 10) - extraAmount);
					this.props.updateFinalPayableAmount(extraAmount);
				} else {
					this.props.updateUsePayrollAmount(parseInt(e.target.value, 10));
					this.props.updateFinalPayableAmount(0);
				}
			} else {
				this.props.updateUsePayrollAmount(0)
			}
		} else if (field === 'endorseUnit') {
			this.props.updateEndorseUnit(parseInt(e.target.value, 10));
			this.props.updateEndorseUnitAmount(parseInt(e.target.value * this.props.project.unitAmount, 10));
			this.props.updateFinalPayableAmount(parseInt(e.target.value * this.props.project.unitAmount, 10));
			if(this.props.usePayroll) {
				if(parseInt(e.target.value * this.props.project.unitAmount, 10) >= this.props.userData.payroll.current.pending) {
					let extraAmount = parseInt(e.target.value * this.props.project.unitAmount, 10) - this.props.userData.payroll.current.pending
					this.props.updateUsePayrollAmount(parseInt(e.target.value * this.props.project.unitAmount, 10) - extraAmount);
					this.props.updateFinalPayableAmount(extraAmount);
				} else {
					this.props.updateUsePayrollAmount(parseInt(e.target.value * this.props.project.unitAmount, 10));
					this.props.updateFinalPayableAmount(0);
				}
			} else {
				this.props.updateUsePayrollAmount(0)
			}
		} else if(field === 'anonymous') {
			this.props.updateAnonymous(e.target.checked);
		} else if(field === 'usePayroll') {
			this.props.updateUsePayroll(e.target.checked);
			if(e.target.checked) {
				if(this.props.endorseType === 'unit') {
					if(this.props.endorseUnitAmount >= this.props.userData.payroll.current.pending) {
						let extraAmount = this.props.endorseUnitAmount - this.props.userData.payroll.current.pending
						this.props.updateUsePayrollAmount(this.props.endorseUnitAmount - extraAmount);
						this.props.updateFinalPayableAmount(extraAmount);
					} else {
						this.props.updateUsePayrollAmount(this.props.endorseUnitAmount);
						this.props.updateFinalPayableAmount(0);
					}
				} else {
					if(this.props.endorseAmount >= this.props.userData.payroll.current.pending) {
						let extraAmount = this.props.endorseAmount - this.props.userData.payroll.current.pending
						this.props.updateUsePayrollAmount(this.props.endorseAmount - extraAmount);
						this.props.updateFinalPayableAmount(extraAmount);
					} else {
						this.props.updateUsePayrollAmount(this.props.endorseAmount);
						this.props.updateFinalPayableAmount(0);
					}
				}
			} else {
				this.props.updateUsePayrollAmount(0)
				if(this.props.endorseType === 'unit') {
					this.props.updateUsePayrollAmount(0);
					this.props.updateFinalPayableAmount(this.props.endorseUnitAmount);
				} else {
					this.props.updateUsePayrollAmount(0);
					this.props.updateFinalPayableAmount(this.props.endorseAmount);
				}
			}
		}
	}

	togglePageNum(value) {
		this.props.updatePageNum(value);
	}

	render () {
		if(this.props.project && this.props.userData) {
			const project = this.props.project;
			return (
				<Row className="endorse-rightbar">
					<Col md={8} mdOffset={2}>
						<p className="endorse-project-title">{project.title}</p>

						<Row>

							<Col md={10} mdOffset={1}>
								<p className="endorse-radio-parent-label">Enter your contribution</p>
								{
									this.props.paymentType === 'international' ? (
										<p className="international-select-note">To cover the costs of international transfers, an additional 2% shall be levied on your contribution.</p>
									) : (
										null
									)
								}

								{
									this.props.project.unitName ? (
										<div>
											<div id="payment">
												<div className="form-group">
													<input type="radio" id="unit"
													       name="endorseType"
													       value="unit"
													       className="mr10"
													       defaultChecked={this.props.endorseType === 'unit'}
													       onChange={this.handleChange.bind(this, 'endorseType')}
													/>
													<label htmlFor="unit" className="control-label">Endorse In Units</label>
												</div>

												{
													this.props.endorseType === 'unit' ? (
														<div className="form-group">
															<div className="input-group donate-flexible" id="paymentform">
																<input type="number" className="form-control input-number product-limit"
																       value={this.props.endorseUnit}
																       onChange={this.handleChange.bind(this, 'endorseUnit')}
																       onBlur={this.onUpdateUnitBlur.bind(this)}
																/>
																<span className="pad-0-5 unit-name">{this.props.project.unitName}</span>
																<span className="pad-0-5 math-mark">X</span>
																<span className="per-unit-amount">₹ {this.props.project.unitAmount}</span>
																<span className="pad-0-5 per-unit">per unit</span>
																<span className="pad-0-5 math-mark">=</span>
																<span className="pad-0-5 total-amount-donating">₹ {this.props.endorseUnitAmount}</span>
															</div>
														</div>
													) : (
														null
													)
												}
											</div>

											<div id="payment">
												<div className="form-group">
													<input type="radio" id="custom"
													       name="endorseType"
													       value="custom"
													       className="mr10"
													       defaultChecked={this.props.endorseType === 'custom'}
													       onChange={this.handleChange.bind(this, 'endorseType')}
													/>
													<label htmlFor="custom" className="control-label">Custom Endorsement</label>
												</div>

												{
													this.props.endorseType === 'custom' ? (
														<div className="input-group" id="paymentform">
															<span className="input-group-addon" id="basic-addon1">₹</span>
															<input type="number"
															       className="form-control input-number product-limit"
															       value={this.props.endorseAmount}
															       onChange={this.handleChange.bind(this, 'endorseAmount')}
															       onBlur={this.onUpdateAmountBlur.bind(this)}
															/>
														</div>
													) : (
														null
													)
												}
											</div>
										</div>
									) : (
										<div className="col-md-6 col-sm-12 padding0" id="payment">
											<div className="input-group" id="paymentform">
												<span className="input-group-addon" id="basic-addon1">₹</span>
												<input type="number"
												       className="form-control input-number product-limit"
												       value={this.props.endorseAmount}
												       onChange={this.handleChange.bind(this, 'endorseAmount')}
												       onBlur={this.onUpdateAmountBlur.bind(this)}
												/>
											</div>
										</div>
									)
								}

								{
									(this.props.userData.payroll.hasPledged && parseInt(this.props.userData.payroll.current.pending) > 0) ? (
										<div className="col-md-12 col-sm-12 padding0">
											<div className="form-group">
												<input type="checkbox"
												       className="mr10"
												       id="usePayroll"
												       name="usePayroll"
												       value="usePayroll"
												       defaultChecked={this.props.usePayroll}
												       onChange={this.handleChange.bind(this, 'usePayroll')}
												/>
												<label htmlFor="usePayroll" className="control-label">
													Deduct from payroll balance? Current Balance <u><b>₹ {this.props.userData.payroll.current.pending}</b></u>.
												</label>
											</div>
										</div>
									) : (
										null
									)
								}
							</Col>

							{
								(!this.props.usePayroll || (this.props.usePayrollAmount >= parseInt(this.props.userData.payroll.current.pending))) ? (
									<Col md={10} mdOffset={1}>
										<p className="endorse-radio-parent-label">Choose your payment type</p>

										<div>
											<div>
												<input type="radio" id="indian"
												       name="paymentType"
												       value="indian"
												       className="mr10"
												       defaultChecked={this.props.paymentType === 'indian'}
												       onChange={this.handleChange.bind(this, 'paymentType')}
												/>
												<label htmlFor="indian" className="control-label">Indian(Cards and Netbanking)</label>
											</div>

											<div className="form-group">
												<input type="radio" id="international"
												       name="paymentType"
												       value="international"
												       className="mr10"
												       defaultChecked={this.props.paymentType === 'international'}
												       onChange={this.handleChange.bind(this, 'paymentType')}
												/>
												<label htmlFor="international" className="control-label">International(All Cards)</label>
											</div>
										</div>
									</Col>
								) : (
									null
								)
							}

							<Col md={10} mdOffset={1}>

								<div className="form-group">
									<label className="control-label">Let us know why you're endorsing this project (optional):</label>
									<textarea className="form-control"
									          placeholder="Type your reason here"
									          required
									          onChange={this.handleChange.bind(this, 'endorseTextReason')}
									>
									</textarea>
								</div>


								<div className="form-group">
									<input type="checkbox"
									       className="mr10"
									       id="contributeAnonymously"
									       name="contributeAnonymously"
									       value="contributeAnonymously"
									       defaultChecked={this.props.anonymous}
									       onChange={this.handleChange.bind(this, 'anonymous')}
									/>
									<label htmlFor="contributeAnonymously" className="control-label">Contribute anonymously
										<span className="ref-tooltip md10">
											<i className="fa fa-question-circle"> </i>
											<span className="tooltiptext">
												If you check this, you shall not appear among the list of endorsers of this project. You are still however required to provide your details for record-keeping and compliance.
											</span>
										</span>
									</label>
								</div>
							</Col>

							{
								this.props.endorseType === 'custom' ? (
									<Col md={12} className="text-center">
										<button className="btn btn-endorse" id="trigger-sticky-header"
										        onClick={this.togglePageNum.bind(this, false)}
										>
											{(() => {
												if(this.props.paymentType === 'international') {
													return (
														<p>Proceed to pay ₹ {Math.ceil(this.props.finalPayableAmount + ((this.props.finalPayableAmount * 2) / 100))}</p>
													)
												} else {
													return (
														<p>Proceed to pay ₹ {this.props.finalPayableAmount}</p>
													)
												}
											})()}
										</button>
									</Col>
								) : (
									<Col md={12} className="text-center">
										<button className="btn btn-endorse" id="trigger-sticky-header"
										        onClick={this.togglePageNum.bind(this, false)}
										>
											{(() => {
												if(this.props.paymentType === 'international') {
													return (
														<p>Proceed to pay ₹ {Math.ceil(this.props.finalPayableAmount + ((this.props.finalPayableAmount * 2) / 100))}</p>
													)
												} else {
													return (
														<p>Proceed to pay ₹ {this.props.finalPayableAmount}</p>
													)
												}
											})()}
										</button>
									</Col>
								)
							}
						</Row>
					</Col>
				</Row>
			)
		} else {
			return null
		}
	}
}