import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as action from '../../../../../actions/EEAdminDiscoverOpportunitiesAction';

import VolunteerOpportunitiesList from './VolunteerOpportunitiesList';
import VolunteerOpportunitiesFilter from './VolunteerOpportunitiesFilter';
import configs from '../../../../../config/urlConfigs';

const readCookie = require('../../../../../cookie.js').readCookie;

class VolunteerOpportunities extends Component {
	componentDidMount() {
		fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/admin-discover', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			this.props.actions.updateOpportunities(data.data, data.totalOpportunities)
		}.bind(this));
	}

	render() {
		return (
			<div className="ee-page-container manage-volunteer-opportunities-page">
				<Row>
					<Col md={12} className="text-center">
						<h2 className="ee-page-title">Manage Volunteering Opportunities</h2>
					</Col>
				</Row>
				{this.props.opportunities !== null ? (
					<div>
						<VolunteerOpportunitiesFilter causes={this.props.causes} updateLocation={this.props.actions.updateLocation}
							updateOpportunities={this.props.actions.updateOpportunities} updateCauses={this.props.actions.updateCauses} 
							completedOpportunities={this.props.completedOpportunities || ""}
							updatePageNumber={this.props.actions.updatePageNumber}
							/>
						<VolunteerOpportunitiesList opportunities={this.props.opportunities}
							updateOpportunities={this.props.actions.updateOpportunities}
							updatePageNumber={this.props.actions.updatePageNumber}
							totalOpportunities={this.props.totalOpportunities}
							pageNumber={this.props.pageNumber}
						/>
					</div>
				) : <div className="data-loader" />}
			</div>
		);
	}
}

VolunteerOpportunities.propTypes = {
	opportunities: PropTypes.array,
	causes: PropTypes.array,
	pageNumber: PropTypes.number,
	totalOpportunities: PropTypes.number,
};

function mapStateToProps(state) {
	return {
		opportunities: state.EEAdminDiscoverOpportunities.opportunities,
		causes: state.EEAdminDiscoverOpportunities.causes,
		pageNumber: state.EEAdminDiscoverOpportunities.pageNumber,
		totalOpportunities: state.EEAdminDiscoverOpportunities.totalOpportunities,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VolunteerOpportunities);