import React, { Component } from 'react';
import { AutoComplete } from 'antd';
import { Row, Col } from 'react-bootstrap';
import Multiselect from 'react-bootstrap-multiselect';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import enUS from 'rc-calendar/lib/locale/en_US';
import moment from 'moment';
import 'moment/locale/en-gb';
import $ from 'jquery';
import Geosuggest from 'react-geosuggest';
// import Select, { Option } from 'rc-select';
import { ToastContainer, toast } from "react-toastify";

import 'rc-calendar/assets/index.css';
import 'rc-select/assets/index.css';
import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css';
import Icon from 'react-icons-kit';
import { search } from 'react-icons-kit/fa/search'
import configs from '../../../../../config/urlConfigs.js';
const Option = AutoComplete.Option;

const format = 'DD-MM-YYYY';

const now = moment();
now.locale('en-gb').utcOffset('+5:30');
now.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

function getFormat(time) {
	return time ? format : 'DD-MM-YYYY';
}

const defaultCalendarValue = now.clone();
defaultCalendarValue.add(-1, 'month');

const readCookie = require('../../../../../cookie.js').readCookie;

const SHOW_TIME = true;

const subdomain = configs.SUBDOMAIN;
// const subdomain = window.location.hostname.split('.')[0];
class Picker extends React.Component {
	render() {
		const props = this.props;
		const calendar = (<Calendar
			locale={enUS}
			defaultValue={now}
			disabledDate={props.disabledDate}
		/>);
		return (
			<DatePicker
				animation="slide-up"
				disabled={false}
				calendar={calendar}
				value={props.value}
				onChange={props.onChange}>
				{
					({ value }) => {
						return (
							<span>
								<input
									placeholder="Select Date"
									disabled={false}
									readOnly
									className="form-control inline readonly-white"
									value={value && value.format(getFormat(true)) || ''}
								/>
							</span>
						);
					}
				}
			</DatePicker>
		);
	}
}

export default class VolunteerOpportunitiesFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cities: [],
			selectedCity: '',
			citiesToDisplay: [],
			search: "",
			goal: null,
			goalValue: null,
			goalValueIndusInd: null,
			startValue: '',
			endValue: '',
			latitude: null,
			longitude: null,
			status: '',
			type: 'All',
			citySearchQuery: '',

		}
		this.fetchIBLCities = this.fetchIBLCities.bind(this);
		this.fetchGoals = this.fetchGoals.bind(this);
	}

	onChange(field, value) {
		this.setState({
			[field]: value,
		},
			this.filterResult
		)
	}

	onSuggestSelect(suggest) {
		
		if (suggest && suggest.label !== '') {
			this.setState({
				latitude: suggest.location.lat,
				longitude: suggest.location.lng,
			},
				this.filterResult
			)
		} else {
			this._geoSuggest.clear();
			this.setState({
				latitude: null,
				longitude: null,
			},
				this.filterResult
			)
		}
	}

	disabledEndDate(endValue) {
		if (!endValue) {
			return false;
		}
		const startValue = this.state.startValue;
		if (!startValue) {
			return false;
		}
		return SHOW_TIME ? endValue.isBefore(startValue) :
			endValue.diff(startValue, 'days') <= 0;
	}

	disabledStartDate(startValue) {
		if (!startValue) {
			return false;
		}
		const endValue = this.state.endValue;
		if (!endValue) {
			return false;
		}
		return SHOW_TIME ? startValue.isAfter(endValue) : startValue.diff(endValue, 'days') > 0;
	}

	fetchGoals() {
		fetch(configs.BACKEND_API_URL + '/getOtAttributes?type=goal', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			// let d = data.otAttributes;
			let arr = data.otAttributes;
			let d = [];
			for (var i = 0; i < arr.length; i++) {
				if (subdomain.toLowerCase() === 'indusind') {
					if (arr[i].organization && arr[i].organization === 'indusind') {
						const existingIndex = d.map(x => x.name).indexOf(arr[i].parent)
						if (existingIndex > -1) {
							d[existingIndex].ids.push(arr[i]._id)
						} else {
							const obj = {
								id: arr[i].id,
								name: arr[i].parent,
								_id: arr[i]._id,
								type: arr[i].type,
								totalIdeas: arr[i].totalIdeas,
								ids: []
							}
							d.push(obj);
						}
					}
				} else if (!arr[i].hasOwnProperty('organization')) {
					d.push(arr[i]);
				}
			}
			let goal = [];
			let goalValue = [];
			let goalValueIndusInd = [];
			this.props.updateCauses(d);
			for (let i = 0; i < d.length; i++) {
				let goalObj = {};
				goalObj['value'] = d[i]._id;
				goalObj['label'] = d[i].name;
				goalObj['selected'] = true;
				goal.push(goalObj);
				// goalValue.push(d[i]._id);
				if (subdomain.toLowerCase() === 'indusind') {
					for (let j = 0; j < d[i].ids.length; j++) {
						goalValueIndusInd.push(d[i].ids[j])
					}
				}
				goalValue.push(d[i]._id);
			}
			this.setState({ goal, goalValue, goalValueIndusInd });
		}.bind(this));
	}
	componentDidMount() {
		this.fetchIBLCities();
		this.fetchGoals();

		fetch(configs.BACKEND_API_URL + '/volunteer-opportunity/all-cities', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			let d = data.data;
			let location = [];
			this.props.updateLocation(d);
			for (let i = 0; i < d.length; i++) {
				let locationObj = {};
				locationObj['value'] = d[i];
				location.push(locationObj);
			}
			this.setState({
				location
			});
		}.bind(this));
	}

	showButtonText(options, select) {
		if (options.length === 0) {
			return 'None selected ...';
		} else if (options.length === 15) {
			return 'All selected';
		} else if (options.length > 1) {
			return options.length + ' selected';
		} else {
			let labels = [];
			options.each(function () {
				if ($(this).attr('label') !== undefined) {
					labels.push($(this).attr('label'));
				} else {
					labels.push($(this).html());
				}
			});
			return labels.join(', ') + '';
		}
	}

	handleChange(name, value) {
		if (subdomain.toLowerCase() === 'indusind' && name === 'goalValue') {
			const goalArr = value.parent().val();
			const selected = this.state.goal.filter(x => goalArr.includes(x.value));
			let arr = selected.map(y => y.value);
			// for (let i = 0; i < selected.length; i++) {
			// 	for (let j = 0; j < selected[i].ids.length; j++) {
			// 		arr.push(selected[i].ids[j]);
			// 	}
			// }
			this.setState({ goalValue: arr }, this.filterResult);
		} else {
			this.setState({ [name]: $(value).parent().val() }, this.filterResult);
		}
	}

	onSearchChange(e) {
		let commentRegex = new RegExp(`^[\.a-zA-Z0-9,!?:;@#$~' ]*$`)
		if (commentRegex.test(e.target.value) === true || true){
		this.setState({ search: e.target.value },
			this.filterResult
		)}
		else {
			this.notifyError("Only letters, numbers and special characters are allowed");
		}
	}
	notifyError = (text) => toast.error(text);

	filterResult() {
		let urlParams = '?';
		// let urlParams = '?pageNumber=1';

		if (this.state.search.length) {
			urlParams += 'query=' + this.state.search;
		}

		if (this.state.startValue) {
			if (urlParams !== "?") {
				urlParams += '&';
			}

			urlParams += 'startDate=' + this.state.startValue;
		}

		if (this.state.endValue) {
			if (urlParams !== "?") {
				urlParams += '&';
			}

			urlParams += 'endDate=' + this.state.endValue;
		}

		if (this.state.goalValue) {
			if (urlParams !== "?") {
				urlParams += '&';
			}

			urlParams += 'goalId=' + this.state.goalValue;
		}
		if (this.state.type) {
			if (urlParams !== "?") urlParams += '&';
			urlParams += 'type=' + this.state.type;
		}

		if (this.state.latitude) {
			if (urlParams !== "?") {
				urlParams += '&';
			}

			urlParams += 'latitude=' + this.state.latitude;
		}

		if (this.state.longitude) {
			if (urlParams !== "?") {
				urlParams += '&';
			}
			urlParams += 'longitude=' + this.state.longitude;
		}

		if (this.state.status) {
			if ($('#opportunity-filter-status-select').find('option').length > 1) {
				if (urlParams !== "?") urlParams += '&';
				urlParams += 'status=' + this.state.status;
			}
		}


		if (this.state.selectedCity) {
				if (urlParams !== "?") urlParams += '&';
				urlParams += 'selectedCity=' + this.state.selectedCity;
		}

		if (urlParams === '?') urlParams = '';

		// this.props.updateOpportunities(null);
		fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/admin-discover' + urlParams, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			this.props.updateOpportunities(data.data, data.totalOpportunities);
		}.bind(this));
	}

	handleCitySelection(value) {
		// console.log("🚀 ~ file: MakeLive.js:287 ~ handleSearch ~ value", value)
		// let citiesToDisplay = this.state.cities.filter(city => city.value.toLowerCase().includes(value.toLowerCase()));
		// citiesToDisplay = citiesToDisplay.map(city => city.value);
		this.setState({ selectedCity: value, citySearchQuery: value }, this.filterResult);
		
	}
	handleCitySearch(value) {
		// console.log("🚀 ~ file: VolunteerOpportunitiesFilter.js:352 ~ VolunteerOpportunitiesFilter ~ handleCitySearch ~ value", value)
		const citiesToDisplay = this.state.cities.filter(city => city.value.toLowerCase().includes(value.toLowerCase()));
		this.setState({ citiesToDisplay, citySearchQuery: value });
	}

	fetchIBLCities() {
		var that = this;
		fetch(configs.BACKEND_API_URL + '/ibl-particulars/city', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			let cities = [];
			for (var i = 0; i < data.cities.length; i++) {
				let city = {};
				city['value'] = data.cities[i];
				city['label'] = data.cities[i];
				cities.push(city);
			}
			that.setState({
				cities,
			});
		});
	}

	resetFilters() {
		let search = "";
		let goal = null;
		let goalValue = null;
		let latitude = null;
		let longitude = null;
		let startValue = '';
		let endValue = '';
		let status = '';
		let type = 'All'
		let selectedCity = '';

		// this._geoSuggest.clear();

		this.setState({ search: null, goal: null, goalValue: null, latitude: null, longitude: null, selectedCity: '' }, function () {
			this.setState({ search, goal, goalValue, latitude, longitude, endValue, startValue, status, type, selectedCity });
		});

		// this.props.updateOpportunities(null);


		fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/admin-discover', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			this.props.updateOpportunities(data.data, data.totalOpportunities)
		}.bind(this));

		this.fetchGoals();
		// fetch(configs.BACKEND_API_URL + '/getOtAttributes?type=goal', {
		// 	method: "GET",
		// 	headers: {
		// 		'Auth': JSON.parse(readCookie('access_token')).access_token
		// 	}
		// }).then(function (response) {
		// 	return response.json();
		// }).then(function (data) {
		// 	let d = data.otAttributes;
		// 	let goal = [];
		// 	let goalValue = [];
		// 	this.props.updateCauses(d);
		// 	for (let i = 0; i < d.length; i++) {
		// 		let goalObj = {};
		// 		goalObj['value'] = d[i]._id;
		// 		goalObj['label'] = d[i].name;
		// 		goalObj['selected'] = true;
		// 		goal.push(goalObj);
		// 		goalValue.push(d[i]._id);
		// 	}
		// 	this.setState({ goal, goalValue });
		// }.bind(this));

		fetch(configs.BACKEND_API_URL + '/volunteer-opportunity/all-cities', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			let d = data.data;
			let location = [];
			this.props.updateLocation(d);
			for (let i = 0; i < d.length; i++) {
				let locationObj = {};
				locationObj['value'] = d[i];
				location.push(locationObj);
			}
			this.setState({
				location
			});
		}.bind(this));
	}

	statusChange(e) {
		this.setState({ status: e.target.value }, this.filterResult);
	}

	render() {
		const options = this.state.citiesToDisplay.map(function (city, index) {
			return (
				<Option key={index} value={city.value}>{city.label}</Option>
			)
		});
		if (this.props.causes) {
			return (
				<div className="manage-opportunities-filters-container">
					<ToastContainer
						position="bottom-center"
						autoClose={50000}
						hideProgressBar={true}
						closeOnClick={false}
						newestOnTop={false}
						pauseOnHover={true}
					/>
					<Row className="pad-tb-20 flex-between">
						<Col md={2} className="form-group mb0">
							<label className="control-label">Start Date:</label>
							<Picker className="form-control inline" disabledDate={this.disabledStartDate.bind(this)} value={this.state.startValue} onChange={this.onChange.bind(this, 'startValue')} />
						</Col>

						<Col md={2} className="form-group mb0">
							<label className="control-label">End Date:</label>
							<Picker className="form-control inline" disabledDate={this.disabledEndDate.bind(this)} value={this.state.endValue} onChange={this.onChange.bind(this, 'endValue')} />
						</Col>
						{/* <Col md={2} className="form-group mb0">
							<label className="control-label">Location:</label>
							<Geosuggest id="location" className="form-control" ref={el => this._geoSuggest = el} onSuggestSelect={this.onSuggestSelect.bind(this)} />
						</Col> */}
						<Col md={2} className="form-group mb0">
							<label className="control-label">City:</label>
							{/* <Geosuggest id="location" className="form-control" ref={el => this._geoSuggest = el}
								onSuggestSelect={this.onSuggestSelect.bind(this)} /> */}
							{/* <Multiselect
								style={{ height: '100%' }}
								options={this.state.cities} // Options to display in the dropdown
								selectedValues={this.state.selectedCity} // Preselected value to persist in dropdown
								onSelect={this.handleCitySelection.bind(this)} // Function will trigger on select event
								onRemove={this.handleCitySelection.bind(this)} // Function will trigger on remove event
								displayValue="label" // Property name to display in the dropdown options
							/> */}
							<AutoComplete
							// value={this.state.selectedCity}
								value={this.state.citySearchQuery}

								dataSource={options}
								style={{width: '130px'}}
								// options={this.state.citiesToDisplay.map(x=>x.label)}
								// style={{
								// 	width: 200,
								// }}
								onSelect={this.handleCitySelection.bind(this)}
								onSearch={this.handleCitySearch.bind(this)}
								placeholder="All Cities"
							/>
						</Col>
						<Col md={2} className="form-group mb0">
							<label className="control-label">Cause:</label>
							{this.state.goalValue !== null ? (
								<Multiselect id="causeGoal" className="form-control inline" value={this.state.goalValue} onChange={this.handleChange.bind(this, 'goalValue')} buttonWidth={'100%'} buttonText={this.showButtonText.bind(this)} data={this.state.goal} enableCaseInsensitiveFiltering multiple />
							) : (null)}
						</Col>
						<Col md={2} className="form-group mb0">
							<label className="control-label">Status:</label>
							<div className="flex-center">
								<select className="form-control" id="opportunity-filter-status-select" value={this.state.status} onChange={this.statusChange.bind(this)}>
									<option value="">All</option>
									<option value="Draft">Draft</option>
									<option value="Draft">Ongoing</option>
									{this.state.endValue === '' ? (
										<option value="Live">Upcoming</option>
									) : (
										moment().diff(this.state.endValue, 'days') < 1 ? (
											<option value="Live">Upcoming</option>
										) : (null)
									)}
									<option value="Pending HR Approval">Pending / Expired</option>
									{this.state.startValue === '' ? (
										<option value="Completed">Completed</option>
									) : (
										moment().diff(moment(this.state.startValue), 'hours') >= 0 ? (
											<option value="Completed">Completed</option>
										) : (null)
									)}
									<option value ="Cancelled">Cancelled</option>
								</select>
							</div>
						</Col>
						<Col md={2} className="form-group mb0">
							<label className="control-label">Type:</label>
							<div className="flex-center">
								<select className="form-control" id="opportunity-filter-type-select" value={this.state.type}
									// onChange={val => this.setState({ type: val.target.value })
									// ,this.filterResult
									// }
									onChange={val => { this.setState({ type: val.target.value }); this.onChange(this, 'type'); }}
								>
									<option value="All">All</option>
									<option value="onGround">On-Ground</option>
									<option value="virtual">Virtual</option>
								</select>
							</div>
						</Col>
						<Col md={2} className="form-group mb0">
							<label className="control-label mb0">Search:</label>
							<input type="search" className="form-control inline"
								placeholder="Enter Search Term"
								value={this.state.search} onChange={this.onSearchChange.bind(this)} />
						</Col>
						<Col md={2} className="form-group mb0">
							<label className="control-label" style={{ 'color': 'white' }}>Actions:</label>
							<div className="flex-center">
								{/* <button className="btn-sq btn-le w100" onClick={this.resetFilters.bind(this)}>Reset</button> */}
								<button type="button" class="btn btn-primary" style={{ marginLeft: 15 }} onClick={this.resetFilters.bind(this)}>Reset</button>
							</div>
						</Col>
					</Row>
				</div>
			);
		} else {
			return null;
		}
	}
}