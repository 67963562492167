const initialState = {
	searchTerm: '',
	discoverCSRProjectsListView: true,
	hideProjectsAdvancedFilter: true,
	projects: null,
	filteredProjects: null,
	shortlistedProjects: null,
	selectedProject: {},
	priceRangeMax: 0,
	priceRangeMin: 0,
	initialPosition: {
		lat: 20.5937,
		lng: 78.9629
	},
	showShortlisted: false
};

const DiscoverCSRProjectsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_PROJECTS':
			let priceRangeMax = 0;
			action.projects.map(function(project) {
				if(project.max_budget > priceRangeMax) return priceRangeMax = project.max_budget
			});
			return Object.assign({}, state, {
				projects: action.projects,
				priceRangeMax
			});
		case 'UPDATE_SELECTED_PROJECT':
			return Object.assign({}, state, {
				selectedProject: action.selectedProject
			});
		case 'TOGGLE_PROJECT_FILTER':
			return Object.assign({}, state, {
				hideProjectsAdvancedFilter: action.hideProjectsAdvancedFilter
			});
		case 'UPDATE_FILTERED_PROJECTS':
			let priceRangeMax2 = 0;
			action.filteredProjects.map(function(filteredProject) {
				if(filteredProject.max_budget > priceRangeMax2) return priceRangeMax2 = filteredProject.max_budget
			});
			return Object.assign({}, state, {
				filteredProjects: action.filteredProjects,
				priceRangeMax: priceRangeMax2
			});
		case 'UPDATE_SEARCH_TERM':
			return Object.assign({}, state, {
				searchTerm: action.searchTerm
			});
		case 'TOGGLE_DISCOVER_CSR_PROJECTS_VIEW':
			return Object.assign({}, state, {
				discoverCSRProjectsListView: !state.discoverCSRProjectsListView
			});
		case 'TOGGLE_SHORTLISTED':
			return Object.assign({}, state, {
				showShortlisted: !state.showShortlisted
			});
		case 'UPDATE_SHORTLISTED_PROJECTS':
			let priceRangeMax3 = 0;
			let shortlistedProjects = [];
			action.shortlistedProjects.map(function(shortlistedProject) {
				for(var i = 0; i < state.projects.length; i++) {
					if(state.projects[i].id === shortlistedProject.project.id) {
						shortlistedProjects.push(state.projects[i]);
						if(state.projects[i].max_budget > priceRangeMax3) return priceRangeMax3 = state.projects[i].max_budget
					}
				}
			});
			return Object.assign({}, state, {
				shortlistedProjects: shortlistedProjects,
				filteredProjects: shortlistedProjects,
				priceRangeMax: priceRangeMax3
			});
		default:
			return state
	}
}

export default DiscoverCSRProjectsReducer;