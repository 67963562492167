import moment from 'moment';
import swal from 'sweetalert';
import Switch from 'rc-switch';
import { AutoComplete } from 'antd';
import React, { Component } from 'react';
import Flatpickr from 'react-flatpickr';
import Autocomplete from 'react-google-autocomplete';
import { Row, Col, ControlLabel, FormControl, Button, InputGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';
import imageCompression from 'browser-image-compression';
import heic2any from "heic2any";

import 'flatpickr/dist/themes/material_orange.css';
import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css';
import { Check } from 'react-feather';
import { ToastContainer, toast } from "react-toastify";
import configs from '../../../config/urlConfigs.js';
import { generatePreSignedGetUrl } from '../../../s3PresignedUrls.js';
const Option = AutoComplete.Option;

const readCookie = require('../../../cookie.js').readCookie;

const subdomain = configs.SUBDOMAIN;
// const subdomain = window.location.hostname.split('.')[0];
export default class EEVolunteerCloneForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			goals: [],
			activityList: [],
			selectedGoal: '',
			goalsParent: [],
			selectedParentGoal: '',
			goalId: '',
			activities: [''],
			name: '',
			title: '',
			country: [],
			selectedCountry: '',
			states: [],
			selectedState: '',
			area: '',
			street: '',
			cities: [''],
			selectedcity: '',
			pincode: '',
			need: '',
			impact: '',
			dates: [],
			virtualOpportunityUrl: '',
			physicalOpportunity: true,
			startTime: '',
			endTime: '',
			maxVolunteer: '',
			minVolunteer: '',
			location: '',
			image: '',
			facilities: [],
			safetyProtocol: '',
			dos: [''],
			projectLeaderContact: '',
			registrationsAllowed: 0,
			helpfulDocument: null,
			backoutWindow: 0,
			employeeAttendanceOverride: false,
			allowPostEventSignUp : false,
			isIndicativeImage : false
		}
		this.autocomplete = null;
		this.handleChange = this.handleChange.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.handleEmployeeAttendanceOverrideChange = this.handleEmployeeAttendanceOverrideChange.bind(this);
		this.handlePostSignUpChange = this.handlePostSignUpChange.bind(this);
		this.handleIndicativeImage = this.handleIndicativeImage.bind(this);
	}

	componentDidMount() {
		fetch(configs.BACKEND_API_URL + '/getOtAttributes', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			var goalset = [];
			var activityset = [];
			let goalsParent = [];
			for (var i = 0; i < data.otAttributes.length; i++) {
				if (data.otAttributes[i].type === "goal") {
					// goalset.push(data.otAttributes[i]);
					if (subdomain.toLowerCase() === 'indusind') {
						if (data.otAttributes[i].organization && data.otAttributes[i].organization === 'indusind') {
							!goalsParent.includes(data.otAttributes[i].parent) && goalsParent.push(data.otAttributes[i].parent);
							goalset.push(data.otAttributes[i]);
						}
					} else if (!data.otAttributes[i].hasOwnProperty('organization')) {
						goalset.push(data.otAttributes[i]);
					}
				} else if (data.otAttributes[i].type === "activity") {
					var activityObj = {};
					activityObj.value = data.otAttributes[i]._id;
					activityObj.label = data.otAttributes[i].name;
					activityset.push(activityObj);
				}
			}
			this.setState({ goals: goalset, activityList: activityset, goalsParent }, function () {
				//to fetch list of countries
				fetch(process.env.REACT_APP_COUNTRY_API, {
					method: "GET",
				}).then(function (response) {
					return response.json();
				}).then(function (data) {
					var countrieslist = [];
					for (var j = 0; j < data.length; j++) {
						countrieslist.push(data[j]);
					}
					this.setState({ country: countrieslist }, function () {
						if (this.props.match.params.hasOwnProperty('opportunityId')) {
							if (this.props.match.params.opportunityId && this.props.match.params.opportunityId !== 'create') this.edit(this.props.match.params.opportunityId);
							else this.setState({ loaded: true });
						} else this.setState({ loaded: true });
					}.bind(this));
				}.bind(this));
			}.bind(this))
		}.bind(this));

	}

	handleEmployeeAttendanceOverrideChange(e) {
		this.setState({ employeeAttendanceOverride: !this.state.employeeAttendanceOverride || false });
	}
	handlePostSignUpChange(e){
		this.setState({allowPostEventSignUp : !this.state.allowPostEventSignUp || false});
}
handleIndicativeImage(e) {
	this.setState({isIndicativeImage : !this.state.isIndicativeImage || false});
}
	handleCountryChange(name, event) {
		var selected = event.target.value;
		this.setState({ [name]: selected });
	}

	submitform(event) {
		if ((this.state.dates.length || this.props.admin === 'LEadmin') && this.state.title !== '' && this.state.name !== '' && this.state.minVolunteer !== '' && this.state.maxVolunteer !== '') {
			var obj = {
				ngoName: this.state.name,
				title: this.state.title,
				country: this.state.selectedCountry,
				address1: this.state.area,
				latitude: this.state.latitude,
				longitude: this.state.longitude,
				state: this.state.selectedState,
				city: this.state.selectedcity,
				address2: this.state.street,
				pincode: this.state.pincode,
				addressUrl: this.state.location,
				goalId: this.state.goalId,
				description: this.state.need,
				expectedImpact: this.state.impact,
				dates: this.state.dates,
				virtualOpportunityUrl: this.state.virtualOpportunityUrl,
				physicalOpportunity: this.state.physicalOpportunity,
				activities: this.state.activities,
				startTime: this.state.startTime,
				endTime: this.state.endTime,
				minVolunteers: this.state.minVolunteer,
				maxVolunteers: this.state.maxVolunteer,
				image: this.state.image,
				facilities: this.state.facilities,
				safetyProtocol: this.state.safetyProtocol,
				dos: this.state.dos,
				projectLeaderContact: this.state.projectLeaderContact,
				registrationsAllowed: this.state.registrationsAllowed,
				helpfulDocument: this.state.helpfulDocument,
				backoutWindow: this.state.backoutWindow,
				employeeAttendanceOverride: this.state.employeeAttendanceOverride,
				allowPostEventSignUp:this.state.allowPostEventSignUp,
				isIndicativeImage:this.state.isIndicativeImage,
			}

			let pastDate = '';
			for (var i = 0; i < this.state.dates.length; i++) {
				var date = moment(this.state.dates[i] + ' ' + this.state.startTime + ':00', 'DD/MM/YYYY HH:mm:ss');
				if (date.isBefore(moment())) {
					pastDate = this.state.dates[i];
				}
			}

			var url = configs.BACKEND_API_URL;
			if (this.props.admin === 'admin') url += '/ee/volunteer-opportunity';
			else if (this.props.admin === 'LEadmin') {
				url += '/volunteer-opportunity';
				obj['date'] = [];
				obj['dateType'] = 'unavailable';
			}

			if (pastDate) {
				swal({
					title: 'Are you sure you want to submit this?',
					html: 'The opportunity seems to have this (<note>' + pastDate + '</note>) as past date/time.',
					type: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes, Submit!',
					cancelButtonText: 'Cancel'
				}).then((result) => {
					if (result) {
						fetch(url, {
							method: "POST",
							headers: {
								'Auth': JSON.parse(readCookie('access_token')).access_token,
								'Content-Type': 'application/json',
							},
							body: JSON.stringify(obj),
						}).then(function (response) {
							return response.json();
						}).then(function (data) {
							if (data.status === "ok") {
								swal("Success!", "Your form is saved successfully", "success");
								if (this.props.admin === 'admin') window.location.pathname = '/volunteer-management';
								else if (this.props.admin === 'LEadmin') window.location.pathname = '/manage-volunteer-opportunity';
							} else if (data.status === "error") {
								swal("Oops!", "Have you filled in all the required fields?", "error");
							}
						}.bind(this));
					}
				}).catch(() => { });
			} else {
				fetch(url, {
					method: "POST",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token,
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(obj),
				}).then(function (response) {
					return response.json();
				}).then(function (data) {
					if (data.status === "ok") {
						swal("Success!", "Your form is saved successfully", "success");
						if (this.props.admin === 'admin') window.location.pathname = '/volunteer-management';
						else if (this.props.admin === 'LEadmin') window.location.pathname = '/manage-volunteer-opportunity';
					} else if (data.status === "error") {
						swal("Oops!", "Have you filled in all the required fields?", "error");
					}
				}.bind(this));
			}

		} else {
			swal("Oops!", "Have you filled in all the required fields?", "error");
		}
	}

	saveAsDraft(e) {
		if ((this.state.dates.length || this.props.admin === 'LEadmin') && this.state.title !== '') {
			var obj = {
				ngoName: this.state.name,
				title: this.state.title,
				country: this.state.selectedCountry,
				address1: this.state.area,
				latitude: this.state.latitude,
				longitude: this.state.longitude,
				state: this.state.selectedState,
				city: this.state.selectedcity,
				address2: this.state.street,
				pincode: this.state.pincode,
				addressUrl: this.state.location,
				goalId: this.state.goalId,
				description: this.state.need,
				expectedImpact: this.state.impact,
				dates: this.state.dates,
				virtualOpportunityUrl: this.state.virtualOpportunityUrl,
				physicalOpportunity: this.state.physicalOpportunity,
				activities: this.state.activities,
				startTime: this.state.startTime,
				endTime: this.state.endTime,
				minVolunteers: this.state.minVolunteer,
				maxVolunteers: this.state.maxVolunteer,
				image: this.state.image,
				facilities: this.state.facilities,
				safetyProtocol: this.state.safetyProtocol,
				dos: this.state.dos,
				projectLeaderContact: this.state.projectLeaderContact,
				registrationsAllowed: this.state.registrationsAllowed,
				helpfulDocument: this.state.helpfulDocument,
				backoutWindow: this.state.backoutWindow,
				employeeAttendanceOverride: this.state.employeeAttendanceOverride,
				allowPostEventSignUp: this.state.allowPostEventSignUp,
				isIndicativeImage :this.state.isIndicativeImage,
			}
			// if(this.state.status === )

			fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/save-as-draft', {
				method: "POST",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(obj),
			}).then(function (response) {
				return response.json();
			}).then(function (data) {
				if (data.status === "ok") {
					swal("Success!", "Your form has been saved for now.", "success").then(function () {
						window.location.pathname = '/volunteer-opportunity/' + data.projectId;
					});
				} else if (data.status === "error") {
					swal("Oops!", "Make sure you have entered the Title and date of project!", "error")
				}
			});
		} else {
			swal("Oops!", "Make sure you have entered the Title and date of project!", "error")
		}
	}

	edit(id) {
		fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + id, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			if (data.status === 'ok' && data.data && data.data.type === 'Internal') {
				var loc = document.getElementById('autocompleteBox');
				if (loc !== null) loc.value = data.data.address1;
				let selectedGoalParent = '';
				for (var i = 0; i < this.state.goals.length; i++) {
					if (data.data.goalId === this.state.goals[i]._id) {
						var goalname = this.state.goals[i].name;
						if (subdomain.toLowerCase() === 'indusind') {
							selectedGoalParent = this.state.goals[i].parent
						}
					}
				}

				var dates = [];
				// Remove default date selection
				// if (data.data.hasOwnProperty('dates')) dates = data.data.dates;

				this.setState({
					name: data.data.ngoName,
					title: data.data.title,
					selectedCountry: data.data.country,
					area: data.data.address1,
					street: data.data.address2,
					selectedcity: data.data.city,
					selectedState: data.data.state,
					pincode: data.data.pincode,
					location: data.data.addressUrl,
					latitude: data.data.latitude,
					longitude: data.data.longitude,
					selectedGoal: goalname,
					goalId: data.data.goalId,
					selectedParentGoal: selectedGoalParent,
					need: data.data.description,
					impact: data.data.expectedImpact,
					activities: data.data.activities,
					dates: dates,
					virtualOpportunityUrl: data.data.virtualOpportunityUrl,
					physicalOpportunity: data.data.physicalOpportunity,
					startTime: data.data.startTime,
					endTime: data.data.endTime,
					maxVolunteer: data.data.maxVolunteers,
					minVolunteer: data.data.minVolunteers,
					image: data.data.image,
					facilities: data.data.facilities && data.data.facilities,
					safetyProtocol: data.data.safetyProtocol && data.data.safetyProtocol,
					dos: data.data.dos ? data.data.dos : [''],
					projectLeaderContact: data.data.projectLeaderContact && data.data.projectLeaderContact,
					registrationsAllowed: data.data.registrationsAllowed && data.data.registrationsAllowed,
					helpfulDocument: data.data.helpfulDocument && data.data.helpfulDocument,
					backoutWindow: data.data.backoutWindow && data.data.backoutWindow,
					employeeAttendanceOverride: data.data.employeeAttendanceOverride && data.data.employeeAttendanceOverride,
					allowPostEventSignUp:data.data.allowPostEventSignUp ? data.data.allowPostEventSignUp : false,
					isIndicativeImage :data.data.isIndicativeImage ?  data.data.isIndicative :false
				}, function () {
					this.setState({ loaded: true });
				}.bind(this));
			} else if (data.status === 'ok' && data.data.type !== 'Internal') {
				if (data.data.customUrl) window.location.pathname = '/opportunity/' + data.data.customUrl;
				else window.location.pathname = '/opportunity/' + data.data.id;
			} else window.location.pathname = '/volunteer-management';
		}.bind(this))
	}

	addrow() {
		var addrow = [];
		addrow = this.state.activities;
		addrow.push('');
		this.setState({ activities: addrow });
	}

	addDosrow() {
		var addDosRow = [];
		addDosRow = this.state.dos;
		addDosRow.push('');
		this.setState({ dos: addDosRow });
	}

	deleterow(id, e) {
		var delrow = [];
		delrow = this.state.activities;
		delrow.splice(id, 1)
		this.setState({ activities: delrow })
	}

	deleteDosrow(id, e) {
		var delDoRow = [];
		delDoRow = this.state.dos;
		delDoRow.splice(id, 1);
		this.setState({ dos: delDoRow })
	}
	error =(text)=> toast.error(text)
	notifyError = (text) => toast.error(text);
		convertImage = (file, event) => {
		let data = new FormData();

		const blobURL = URL.createObjectURL(file);
		fetch(blobURL)
			.then((res) => res.blob())
			.then((blob) => heic2any({ blob }))
			.then((conversionResult) => {
				let image = conversionResult;
				data.append("image", image);
				fetch(configs.BACKEND_API_URL + '/uploadImage', {
					method: "POST",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token,
					},
					body: data
				}).then((data) => data.json())
					.then(function (data) {
						if (data && data.url) {
							this.setState({ image: data.url })
						}
					}.bind(this)).catch(err => { this.notifyError("Unsupported file format detected!"); event.target.value = null; });
			});
			}
	onImageChange(event, key) {
		key === 'document' ? this.setState({ helpfulDocument: event.target.files[0] }) : this.setState({ image: event.target.files[0] })
		let data = new FormData();
		if(key === "image"){
			if (/\.(jpe?g|png|heic|heif)$/i.test(event.target.files[0].name) === false) {
				this.error("Only png, heic/heif and jpeg formats are accepted.");
			}
			else {
				if ((event.target.files[0].type
				).includes('hei')) {
					// image =
					this.convertImage(event.target.files[0], event);
				} else {
					data.append("image", event.target.files[0]);
					fetch(configs.BACKEND_API_URL + '/uploadImage', {
						method: "POST",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token,
						},
						body: data
					}).then((data) => data.json())
						.then(function (data) {
							if (data && data.url) {
								this.setState({ image: data.url })
							}
						}.bind(this)).catch(err => { this.notifyError("Unsupported file format detected!"); event.target.value = null; });
				}
			}
			  }
			 else if(key === 'document'){
				if(event.target.files[0].type === 'application/pdf' ){ 
					data.append("image", event.target.files[0]);
					fetch(configs.BACKEND_API_URL + '/uploadImage', {
						method: "POST",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token,
						},
						body: data
					}).then((data) => data.json())
						.then(function (data) {
							if (data && data.url) {
								this.setState({ helpfulDocument: data.url }) 
							}
						}.bind(this)).catch(err =>  {this.notifyError("Unsupported file format detected!"); event.target.value = null;});
					}
				  else {
					this.error("Only pdf format is accepted");
					}
				}
	}

	deleteImage() {
		this.setState({ image: '' })
	}
	WORD_LIMITS = {
		name: 20,
		title: 20,
		street: 20,
		need: 2000,
		impact: 2000,
		safetyProtocol: 1000,
		DOSANDDONTS: 50,
	};

	handleChange(name, e) {

		if(e.target){
			// let commentRegex = new RegExp(`^([A-Za-z]+)(?:[A-Za-z0-9 _]*)$`)
		//    let commentRegex = new RegExp("^[A-Za-z0-9\s!@#$%^&*()_+=-`~\\\]\[{}|';:/.,?]*$")
		let commentRegex = new RegExp	('^[a-zA-Z0-9!@#$&?!*%()\\-`.+,/\\s\w"]+(?: [a-zA-Z0-9!@#$&?!*%()\\-`.+,/\\s\w"]+)*$')
			if (commentRegex.test(e.target.value) === true || true){
			
			if (e.target) {
				if (e.target.value.split(" ").length > this.WORD_LIMITS[name]) {
					this.notifyError(`Input can't be more than ${this.WORD_LIMITS[name]} words!`);
					e.target.value = e.target.value.split(" ").slice(0, this.WORD_LIMITS[name]).join(" ");
				}
				this.setState({ [name]: e.target.value });
			}
			else if(name === "startTime" || name === "endTime"){
				this.setState({ [name]: e.target.value });
			}
			else {
				if (e && (e).split(" ").length > this.WORD_LIMITS[name]) {
					this.notifyError(`Input can't be more than ${this.WORD_LIMITS[name]} words!`);
					e = (e).split(" ").slice(0, this.WORD_LIMITS[name]).join(" ");
				} 
				this.setState({ [name]: e });
			}
		}
		else {
			this.notifyError("Only letters, numbers and special characters are allowed")
		}
		}
		else if(name === "physicalOpportunity") {
			this.setState({ physicalOpportunity: !this.state.physicalOpportunity });
		}
		
	}

	handleGoalChange(name, e) {
		var val = e.target.value;
		for (var i = 0; i < this.state.goals.length; i++) {
			if (val === this.state.goals[i].name) {
				var id = this.state.goals[i]._id;
				this.setState({ goalId: id, [name]: e.target.value });
			}
		}
	}

	onSelect(index, value, x) {
		if (value) {
			var activities = this.state.activities;
			activities[index] = { 'id': value, 'name': x.props.children }
			this.setState({ activities });
		}
	}

	onAddDosAndDonts(index, value, x) {
		let commentRegex = new RegExp(`^[\.a-zA-Z0-9,!?:;@#$~' ]*$`)
		if (commentRegex.test(e.target.value) === true || true){
		if (value) {
			var dos = this.state.dos;
			dos[index] = { 'id': index, 'name': value.target.value }
			this.setState({ dos });
		}
	}
	else {
		this.notifyError("Only letters, numbers and special characters are allowed");
	}
	}

	handleAddFacilities(e) {
		var facilities = [];
		facilities = this.state.facilities;
		facilities.includes(e) ? facilities.splice(facilities.indexOf(e), 1) : facilities.push(e);
		this.setState({ facilities })
	}

	handleSearch(index, value) {
		for (var i = 0; i < this.state.activityList.length; i++) {
			var option = this.state.activityList[i];
			if (option.label.toLowerCase().match(value.toLowerCase()) !== null) {
				break;
			}
		}
		var newActivity = {};
		newActivity['id'] = '';
		newActivity['name'] = value;

		var activities = this.state.activities;
		activities[index] = newActivity;
		this.setState({ activities });
	}

	handleDateChange(dates) {
		var convertedDates = [];
		for (var i = 0; i < dates.length; i++) {
			if (dates[i] !== '') convertedDates.push(moment(dates[i], 'YYYY-MM-DD').format('DD/MM/YYYY'));
		}
		this.setState({ dates: convertedDates });
	}

	render() {
		const tooltip = (
			<Tooltip id="tooltip">
				<p>your location URL</p>
			</Tooltip>
		);


		const options = this.state.activityList.map(function (activity, index) {
			return (
				<Option key={index} value={activity.value}>{activity.label}</Option>
			)
		});

		if (this.state.loaded) {
			return (
				<div className="ee-page-container volunteer-form">
					<form className="ee-form-layout">
						<div className="ee-form">
							<Row className="form-row">
								<Col md={12} xs={12} className="form-header">
									{/* <h2><b>Volunteer Opportunity Creation Form</b></h2> */}
									<p className='ee-page-title'>Volunteer Opportunity Creation Form</p>
								</Col>
							</Row>
							<ToastContainer
							 position="bottom-center"
						     autoClose={50000}
						     hideProgressBar={true}
					 	     closeOnClick={false}
						     newestOnTop={false}
						     pauseOnHover={true} />

							<Row className="form-row">
								<Col md={4} xs={12} className="form-ngo-name">
									<div className="form-label">
										<ControlLabel className="label padding0 m0">Name of the NGO <span className="star"> * </span> </ControlLabel>
									</div>
								</Col>

								<Col md={8} xs={12}>
									<div className="form-input">
										<FormControl key="ngoname" onChange={this.handleChange.bind(this, 'name')} type="text" value={this.state.name} placeholder="NGO Name"></FormControl>
									</div>
								</Col>
							</Row>

							<Row className="form-row">
								<Col md={4} className="form-project-title">
									<div className="form-label">
										<ControlLabel className="label padding0 m0">Title of the project <span className="star"> * </span></ControlLabel>
									</div>
								</Col>

								<Col md={8}>
									<div className="form-input">
										<FormControl type="text" onChange={this.handleChange.bind(this, 'title')} value={this.state.title} placeholder="Project Title"></FormControl>
									</div>
								</Col>
							</Row>

							<Row className="form-row">
								<Col md={4} className="form-project-title">
									<div className="form-label">
										<ControlLabel className="label padding0 m0">Type of opportunity <span className="star"> * </span></ControlLabel>
									</div>
								</Col>

								<Col md={8}>
									<div className="form-input">
										<span style={{ marginRight: 15 }}>Virtual</span>
										<Switch onChange={this.handleChange.bind(this, 'physicalOpportunity')} checked={this.state.physicalOpportunity} />
										<span style={{ marginLeft: 15 }}>Physical</span>
									</div>
								</Col>
							</Row>

							{this.state.physicalOpportunity ? (
								<Row className="form-row">
									<Col md={4} className="form-address">
										<div className="form-label">
											<ControlLabel className="label padding0 m0">Address of the project site <span className="star"> * </span></ControlLabel>
										</div>
									</Col>
									<Col md={8}>
										<div className="form-address-details">
											<Row className="form-row">
												<Col md={12}>
													<FormControl componentClass="select" value={this.state.selectedCountry} className="select-tab" id="sel1" onChange={this.handleCountryChange.bind(this, 'selectedCountry')}>
														<option value="">Select your country</option>
														{this.state.country.map(function (countries, index) {
															return (
																<option key={index} value={countries.name}>{countries.name}</option>
															)
														})}
													</FormControl>
												</Col>
											</Row>

											<Row className="form-row">
												<Col md={12}>
													<Autocomplete className="form-address-autocomplete" id="autocompleteBox" ref="autocomplete"
														onPlaceSelected={(place) => {
															if (typeof place.address_components != 'undefined') {
																var input = document.getElementById('autocompleteBox').value;
																this.setState({ 'area': input });

																for (var i = 0; i < place.address_components.length; i++) {
																	var addressType = place.address_components[i].types[0];
																	var val = place.address_components[i]['long_name'];
																	if (addressType === 'locality') {
																		this.setState({ selectedcity: val })
																	} else if (addressType === 'administrative_area_level_1') {
																		this.setState({ selectedState: val })
																	} else if (addressType === 'postal_code') {
																		this.setState({ pincode: val })
																	}
																}
															}
															var url = place.url;
															this.setState({
																location: url,
																latitude: place.geometry.location.lat(),
																longitude: place.geometry.location.lng()
															});
														}} types={[]} defaultValue={this.state.area}
													/>
												</Col>
											</Row>

											<Row className="form-row">
												<Col md={12}>
													<FormControl type="text" onChange={this.handleChange.bind(this, 'street')} value={this.state.street} placeholder="Street/House/Flat no."></FormControl>
												</Col>
											</Row>

											<Row className="form-row">
												<Col md={12}>
													<FormControl type="text" value={this.state.selectedcity} className="select-tab" onChange={this.handleChange.bind(this, 'selectedcity')} placeholder="City"></FormControl>
												</Col>
											</Row>

											<Row className="form-row">
												<Col sm={6} md={6}>
													<FormControl type="text" value={this.state.selectedState} className="select-tab" onChange={this.handleChange.bind(this, 'selectedState')} placeholder="State"></FormControl>
												</Col>

												<Col sm={6} md={6}>
													<FormControl type="text" onChange={this.handleChange.bind(this, 'pincode')} value={this.state.pincode} placeholder="PinCode"></FormControl>
												</Col>
											</Row>

											<Row className="form-row">
												<Col md={12}>
													<OverlayTrigger placement="left" overlay={tooltip}>
														<InputGroup>
															<InputGroup.Button>
																<Button className="locate"><i className="fa fa-map-marker"></i> </Button>
															</InputGroup.Button>
															<FormControl type="text" onChange={this.handleChange.bind(this, 'location')} value={this.state.location} placeholder="Google maps location URL"></FormControl>
														</InputGroup>
													</OverlayTrigger>
												</Col>
											</Row>
										</div>
									</Col>
								</Row>
							) : (
								<Row className="form-row">
									<Col md={4} className="form-virtual-opportunity-url">
										<div className="form-label">
											<ControlLabel className="label padding0 m0">Link to join the opportunity</ControlLabel>
										</div>
									</Col>

									<Col md={8}>
										<div className="form-input">
											<FormControl type="text" onChange={this.handleChange.bind(this, 'virtualOpportunityUrl')} value={this.state.virtualOpportunityUrl} placeholder="Virtual Opportunity Url"></FormControl>
										</div>
									</Col>
								</Row>
							)}

							<Row className="form-row">
								<Col md={4} className="form-goal">
									<div className="form-label">
										<ControlLabel className="label padding0 m0"> Goal <span className="star"> * </span> </ControlLabel>
									</div>
								</Col>
								<Col md={8}>
									{subdomain.toLowerCase() === 'indusind' ? <>
										<FormControl componentClass="select" value={this.state.selectedParentGoal} className="select-tab" onChange={(e) => this.setState({ selectedParentGoal: e.target.value, selectedGoal: '' })}>
											<option value=""> Select a goal </option>
											{this.state.goalsParent.map((goallist, index) => <option key={index} value={goallist}>{goallist}</option>)}
										</FormControl>
										{this.state.selectedParentGoal && <div className="ngoGoal" style={{ marginTop: 10 }}>
											<FormControl componentClass="select" value={this.state.selectedGoal} className="select-tab" onChange={this.handleGoalChange.bind(this, 'selectedGoal')}>
												<option value=""> Select a cause </option>
												{this.state.goals.filter(x => x.parent === this.state.selectedParentGoal).map(function (goallist, index) {
													return (
														<option key={index} value={goallist.name}>{goallist.name}</option>
													)
												})}
											</FormControl>
										</div>} </>
										: <div className="ngoGoal">
											<FormControl componentClass="select" value={this.state.selectedGoal} className="select-tab" onChange={this.handleGoalChange.bind(this, 'selectedGoal')}>
												<option value=""> Select a goal </option>
												{this.state.goals.map(function (goallist, index) {
													return (
														<option key={index} value={goallist.name}>{goallist.name}</option>
													)
												})}
											</FormControl>
										</div>}
									{/* <div className="ngoGoal">
										<FormControl componentClass="select" value={this.state.selectedGoal} className="select-tab" onChange={this.handleGoalChange.bind(this, 'selectedGoal')}>
											<option value=""> Select a goal </option>
											{this.state.goals.map(function (goallist, index) {
												return (
													<option key={index} value={goallist.name}>{goallist.name}</option>
												)
											})}
										</FormControl>
									</div> */}
								</Col>
							</Row>

							<Row className="form-row">
								<Col md={12} xs={12} className="form-need-and-plan">
									<div className="form-label">
										<ControlLabel className="label padding0 m0">A description of the need and plan of the project <span className="star"> * </span> </ControlLabel>
									</div>
									<div className="form-input">
										<FormControl componentClass="textarea" className="need-and-plan" value={this.state.need} onChange={this.handleChange.bind(this, 'need')} placeholder=""></FormControl>
									</div>
								</Col>
							</Row>

							<Row className="form-row">
								<Col md={12} className="form-expected-impact">
									<div className="form-label">
										<ControlLabel className="label padding0 m0">What is the expected impact of the volunteering project? <span className="star"> * </span></ControlLabel>
									</div>
									<div className="form-input">
										<FormControl className="impact" componentClass="textarea" value={this.state.impact} onChange={this.handleChange.bind(this, 'impact')} placeholder=""></FormControl>
									</div>
								</Col>
							</Row>

							<Row className="form-row">
								<Col sm={12} md={12} className="form-activities-undertaken">
									<div className="form-label">
										<ControlLabel className="label padding0 m0">Enlist activities undertaken as part of this project<span className="star"> * </span></ControlLabel>
									</div>
								</Col>
							</Row>

							<Row style={{ marginTop: -20, position: 'relative', height: 40 }}>
								<Col md={2} className="activity-button" style={{ position: 'absolute', right: 0, textAlign: 'right' }}>
									<Button className="btn btn-primary" onClick={this.addrow.bind(this)}> <i className="fa fa-plus"></i> Add </Button>
								</Col>
							</Row>

							<Row>
								{this.state.activities.map((newrow, index) => {
									return (
										<Col key={index} md={12} style={{ marginTop: 5 }}>
											<Row className="ml5 mr5" style={{ display: 'flex' }}>
												<div style={{ flexGrow: 1, marginRight: 10 }}>
													<div className="form-input">
														<AutoComplete dataSource={options}
															className="autosearch"
															placeholder="Enter Activity"
															onSelect={this.onSelect.bind(this, index)}
															value={this.state.activities[index].name}
															onSearch={this.handleSearch.bind(this, index)}
															filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
													</div>
												</div>
												<div className="text-right">
													<button className="btn btn-primary" style={{ height: 32 }} onClick={this.deleterow.bind(this, index)}><i className="fa fa-trash"></i></button>
												</div>
											</Row>
										</Col>
									)
								})}
							</Row>

							{this.state.physicalOpportunity && <>
								<Row style={{ marginTop: 30 }} >
									<Col md={4} className="form-goal">
										<div className="form-label">
											<ControlLabel className="label padding0 m0"> Available facilities <span className="star"> * </span> </ControlLabel>
										</div>
									</Col>
									<Col md={8}>
										<Row>
											<Col md={4}>
												{/* <div class="form-group form-check">
													<input type="checkbox" class="form-check-input" id="toilet" checked={this.state.facilities.includes('toilet')} onChange={() => this.handleAddFacilities('toilet')} />
													<label class="form-check-label" style={{ marginLeft: 10 }} for="toilet">Toilet</label>
												</div> */}
												<div class="form-group form-check checkbox-row">
													<a className={this.state.facilities.includes('toilet') ? 'checkbox-active' : 'checkbox-inactive'} onClick={() => this.handleAddFacilities('toilet')}>
														<Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
													</a>
													<label class="form-check-label" style={{ margin: 10 }} for="toilet">Toilet</label>
												</div>
											</Col >
											<Col md={4}>
												{/* <div class="form-group form-check">
													<input type="checkbox" class="form-check-input" id="drinkingWater" checked={this.state.facilities.includes('drinkingWater')} onChange={() => this.handleAddFacilities('drinkingWater')} />
													<label class="form-check-label" style={{ marginLeft: 10 }} for="drinkingWater">Drinking Water</label>
												</div> */}
												<div class="form-group form-check checkbox-row">
													<a className={this.state.facilities.includes('drinkingWater') ? 'checkbox-active' : 'checkbox-inactive'} onClick={() => this.handleAddFacilities('drinkingWater')}>
														<Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
													</a>
													<label class="form-check-label" style={{ margin: 10 }} for="drinkingWater">Drinking Water</label>
												</div>
											</Col >

											<Col md={4}>
												{/* <div class="form-group form-check">
													<input type="checkbox" class="form-check-input" id="mealOrSnack" checked={this.state.facilities.includes('mealOrSnack')} onChange={() => this.handleAddFacilities('mealOrSnack')} />
													<label class="form-check-label" style={{ marginLeft: 10 }} for="mealOrSnack">Meal/Snacks</label>
												</div> */}
												<div class="form-group form-check checkbox-row">
													<a className={this.state.facilities.includes('mealOrSnack') ? 'checkbox-active' : 'checkbox-inactive'} onClick={() => this.handleAddFacilities('mealOrSnack')}>
														<Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
													</a>
													<label class="form-check-label" style={{ margin: 10 }} for="mealOrSnack">Meal/Snacks</label>
												</div>
											</Col >
										</Row>
									</Col>
								</Row>
								<Row>
									<Col md={4} className="form-goal">
										<div className="form-label">
											<ControlLabel className="label padding0 m0"> Covid-safety protocol </ControlLabel>
										</div>
									</Col>
									<Col md={8}>
										<textarea
											class="form-control"
											id="exampleFormControlTextarea1"
											rows="3"
											placeholder='Covid-safety protocol'
											value={this.state.safetyProtocol}
											onChange={this.handleChange.bind(this, 'safetyProtocol')}
										></textarea>
									</Col >
								</Row>
							</>}

							<Row style={{ marginTop: 30 }} >
								<Col md={10} className="form-goal">
									<div className="form-label">
										<ControlLabel className="label padding0 m0"> Important Instructions</ControlLabel>
									</div>
								</Col>
								<Col md={2}>
									<Row>
										<div className="activity-button" style={{ position: 'absolute', right: 0, textAlign: 'right', width: '100%', marginTop: -10, marginRight: 15 }}>
											{/* <Button className="btn-sq btn-le" style={{ width: '100%' }} onClick={this.addDosrow.bind(this)} bsSize="sm"> <i className="fa fa-plus"></i> Add </Button> */}
											<button type="button" class="btn btn-primary" onClick={this.addDosrow.bind(this)}><i className="fa fa-plus"></i> Add</button>
										</div>
									</Row>
								</Col>
							</Row>
							{this.state.dos.map((newrow, index) => {
								return (
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
										<div className="form-input" style={{ width: '98%' }}>
											<FormControl type="text"
												onChange={this.onAddDosAndDonts.bind(this, index)}
												value={this.state.dos[index].name} placeholder="Important Instructions"
											></FormControl>
										</div>
										<div className="text-right">
											<button className="btn btn-primary" style={{ height: 32, alignSelf: 'flex-start' }} onClick={this.deleteDosrow.bind(this, index)} > <i className="fa fa-trash"></i></button>
										</div>
									</div>
								)
							})}

							<Row className="form-row">
								<Col md={4} xs={12} className="form-ngo-name">
									<div className="form-label">
										<ControlLabel className="label padding0 m0">Emergency/ Project leader contact <span className="star"> * </span></ControlLabel>
									</div>
								</Col>

								<Col md={8} xs={12}>
									<div className="form-input">
										<FormControl key="projectLeaderContact" onChange={this.handleChange.bind(this, 'projectLeaderContact')} type="text" value={this.state.projectLeaderContact} placeholder="Emergency Project leader contact"></FormControl>
									</div>
								</Col>
							</Row>

							<Row className="form-row">
								<Col md={4} xs={12} className="form-ngo-name">
									<div className="form-label">
										<ControlLabel className="label padding0 m0">Maximum Family/Friends allowed per volunteer<span className="star"> * </span></ControlLabel>
									</div>
								</Col>

								<Col md={8} xs={12}>
									<div className="form-input">
										<FormControl key="registrationsAllowed" onChange={(e) => (e.target.value > -1 && e.target.value < 11) && this.setState({ registrationsAllowed: e.target.value })} type="number" value={this.state.registrationsAllowed} placeholder="Max. Family/Friends Registration allowed"></FormControl>
									</div>
								</Col>
							</Row>

							<Row className="form-row">
								<Col md={4} xs={12} className="form-ngo-name">
									<div className="form-label">
										<ControlLabel className="label padding0 m0">Backout window in hours (before the event begins) <span className="star"> * </span></ControlLabel>
									</div>
								</Col>

								<Col md={8} xs={12}>
									<div className="form-input">
										<FormControl key="backoutWindow" onChange={(e) => (e.target.value > -1 && e.target.value < 251) && this.setState({ backoutWindow: e.target.value })} type="number" value={this.state.backoutWindow} placeholder="Backout window in hours (before the event begins)"></FormControl>
									</div>
								</Col>
							</Row>

							<Row className="form-row">
								<Col md={4} xs={12} className="form-ngo-name">
									<div className="form-label">
										<ControlLabel className="label padding0 m0">Any helpful document </ControlLabel>
									</div>
								</Col>

								<Col md={8} xs={12}>
									<Row className="form-row image-buttons">
										
										{this.state.helpfulDocument  ? <><Col md={6}>
											<note>
												<a href={generatePreSignedGetUrl(this.state.helpfulDocument)} target="_blank">Document Link</a> 
												{/* {this.state.helpfulDocument && typeof this.state.helpfulDocument === 'string' ?
												this.state.helpfulDocument.split("/")[this.state.helpfulDocument.split("/").length - 1] : ''} */}
											</note>
											{/* <note>Document Selected</note> */}

										</Col><Col md={6}><button className="btn btn-primary" onClick={() => this.setState({ helpfulDocument: null })}>
												<i className="fa fa-trash"></i> Delete</button>
										</Col></>: <Col md={6}>
											<FormControl type="file" accept=".pdf" onChange={(e) => this.onImageChange(e, "document")}></FormControl>
										</Col>}
									</Row>
								</Col>
							</Row>

							<Row className="form-row">
								<Col md={12} className="form-volunteering-date">
									<div className="form-label">
										<ControlLabel className="label padding0 m0">Enlist following details of volunteering</ControlLabel>
									</div>
								</Col>

								<Col md={12}>
									{this.props.admin === 'admin' ? (
										<Col md={4} className="mb15 padding0">
											<Col md={12}>
												<div className="form-label">
													<label className="control-label label padding0 m0">Date(s) of volunteering<span className="star"> * </span></label>
												</div>
											</Col>

											<Col md={12}>
												<div className="form-input date-picker-container">
													<Flatpickr options={{ allowInput: false, mode: 'multiple', dateFormat: 'd/m/Y', altFormat: 'd/m/Y', altInputClass: 'form-control', altInput: true }} defaultValue={[]} value={[]} onChange={dates => this.handleDateChange(dates)} />
												</div>
											</Col>
										</Col>
									) : (null)}

									<Col md={4} className="mb15 padding0">
										<Col md={12}>
											<div className="form-label">
												<label className="control-label label padding0 m0">Suggested start time of project<span className="star"> * </span></label>
											</div>
										</Col>

										<Col md={12}>
											<div className="form-input">
												<FormControl type="time" value={this.state.startTime} onChange={this.handleChange.bind(this, 'startTime')} placeholder=""></FormControl>
											</div>
										</Col>
									</Col>

									<Col md={4} className="mb15 padding0">
										<Col md={12}>
											<div className="form-label">
												<label className="control-label label padding0 m0">Suggested end time of project <span className="star"> * </span></label>
											</div>
										</Col>

										<Col md={12}>
											<div className="form-input">
												<FormControl type="time" value={this.state.endTime} onChange={this.handleChange.bind(this, 'endTime')} placeholder=""></FormControl>
											</div>
										</Col>
									</Col>
								</Col>
							</Row>

							<Row className="form-row">
								<Col md={8} className="form-min-volunteer">
									<div className="form-label">
										<ControlLabel className="label padding0 m0">How many volunteers (min) can be accommodated at the event? (including family & friends) <span className="star"> * </span></ControlLabel>
									</div>
								</Col>

								<Col md={4}>
									<div className="form-input">
										<FormControl type="number" value={this.state.minVolunteer} onChange={this.handleChange.bind(this, 'minVolunteer')} placeholder="Min Volunteers"></FormControl>
									</div>
								</Col>
							</Row>

							<Row className="form-row">
								<Col md={8} className="form-max-volunteer">
									<div className="form-label">
										<ControlLabel className="label padding0 m0">How many volunteers (max) can be accommodated at the event? (including family & friends)<span className="star"> * </span></ControlLabel>
									</div>
								</Col>

								<Col md={4}>
									<div className="form-input">
										<FormControl type="number" value={this.state.maxVolunteer} onChange={this.handleChange.bind(this, 'maxVolunteer')} placeholder="Max Volunteers"></FormControl>
									</div>
								</Col>
							</Row>

							<Row className="form-row">
								<Col md={8} className="form-max-volunteer">
									<div className="form-group form-check checkbox-row">
										<a className={this.state.employeeAttendanceOverride ? 'checkbox-active' : 'checkbox-inactive'} onClick={this.handleEmployeeAttendanceOverrideChange.bind(this)} onChange={this.handleEmployeeAttendanceOverrideChange.bind(this)}>
											<Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
										</a>
									<div className="form-label">

										<ControlLabel className="label padding0 m2">This event requires an attendance approver.</ControlLabel>
</div>
										{/* <label class="form-check-label" style={{ margin: 10 }} for="employeeAttendanceOverride"> */}
											{/* Toilet</label> */}
											{/* Enable employee attendance validation/override */}
										{/* </label> */}
									</div>
								</Col>

								{/* <Col md={4}>
									<div className="form-input">
										<FormControl type="number" value={this.state.maxVolunteer} onChange={this.handleChange.bind(this, 'maxVolunteer')} placeholder="Max Volunteers"></FormControl>
									</div>
								</Col> */}
							</Row>
							<Row className="form-row">
								<Col md={8} className="form-max-volunteer">
									<div className="form-group form-check checkbox-row">
										<a className={this.state.allowPostEventSignUp ? 'checkbox-active' : 'checkbox-inactive'} onClick={this.handlePostSignUpChange.bind(this)} onChange={this.handlePostSignUpChange.bind(this)}>
											<Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
										</a>
									<div className="form-label">
										<ControlLabel className="label padding0 m2">
											Allow post-event signup.
                                        </ControlLabel></div>
									</div>
								</Col>
							</Row>
							<Row className="form-row">
								<Col md={8} className="form-max-volunteer">
									<div className="form-group form-check checkbox-row">
										<a className={this.state.isIndicativeImage ? 'checkbox-active' : 'checkbox-inactive'} onClick={this.handleIndicativeImage.bind(this)} onChange={this.handleIndicativeImage.bind(this)}>
											<Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
										</a>
									<div className="form-label">
										<ControlLabel className="label padding0 m2">
											Check if this is an indicative image of the event
                                        </ControlLabel>
									</div>
									</div>
								</Col>
							</Row>
							
							<Row className="form-row">
								<Col md={12} className="form-upload">
									<div className="form-label">
										<ControlLabel className="label padding0 m0">A representative cover image of the event <span className="star"> * </span></ControlLabel>
									</div>
								</Col>
							</Row>

							<Row className="form-row">
								<Col md={8} className="image-field">
									<div className="input-image-file">
										<img onError={(e) => { e.target.src = 'https://assets.letsendorse.com/sahaj/project-placeholder.jpg' }}
											src={generatePreSignedGetUrl(this.state.image)} className="myimage" alt="choose an image" />
									</div>
								</Col>

								<Col md={4} className="image-buttons">
									<Row className="form-row">
										<FormControl type="file" accept=".png, .jpeg, .heic, .heif" onChange={(e) => this.onImageChange(e, "image")}></FormControl>
									</Row>
									<Row className="form-row">
										<Button className="btn btn-primary" onClick={this.deleteImage.bind(this)}><i className="fa fa-trash"></i> Delete</Button>
									</Row>
								</Col>
							</Row>

							<Row className="form-row">
								{/* <div className="bottom-buttons">
									{this.props.admin === 'admin' ? (
										<Col md={6} sm={6} className="form-save-as-draft">
											<Button className="btn btn-primary" onClick={this.saveAsDraft.bind(this)}><i className="fa fa-download"></i> Save as Draft</Button>
										</Col>
									) : (null)}

									<Col md={6} sm={6} className="form-submit">
										<Button className="btn btn-primary" onClick={this.submitform.bind(this)}> <i className="fa fa-paper-plane"></i> Submit</Button>
									</Col>
								</div> */}

								{this.props.admin === 'admin' ? (
									<div className="bottom-buttons" style={{ marginTop: 20 }}>
										<Button className="btn btn-primary" onClick={this.saveAsDraft.bind(this)}><i className="fa fa-download"></i> Save as Draft</Button>
										{(this.state.dates.length || this.props.admin === 'LEadmin') &&
										this.state.title !== '' && this.state.name !== '' && this.state.minVolunteer !== '' &&
										this.state.maxVolunteer !== '' && this.state.projectLeaderContact !== '' &&
										this.state.registrationsAllowed !== '' && this.state.image !== '' ?
									<Button className="btn btn-primary" onClick={this.submitform.bind(this)} style={{ marginLeft: 10 }}> <i className="fa fa-paper-plane"></i> Submit</Button>:
									<Button type="button" class="btn  btn-save-disabled" disabled style={{ marginLeft: 15 }} ><i className="fa fa-paper-plane"></i> Submit</Button>}


									</div>
								) : (null)}
							</Row>
						</div>
					</form>
				</div>
			);
		} else return null;
	}
}