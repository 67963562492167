import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import "rc-calendar/assets/index.css";
import Calendar from "rc-calendar";
import DatePicker from "rc-calendar/lib/Picker";
import enUS from "rc-calendar/lib/locale/en_US";
import moment from "moment";
import "moment/locale/en-gb";
import Checkbox from "rc-checkbox";

import "rc-select/assets/index.css";
import "rc-checkbox/assets/index.css";
import "react-bootstrap-multiselect/css/bootstrap-multiselect.css";
import configs from "../../../../../config/urlConfigs.js";

const format = "DD-MM-YYYY";

const now = moment();
now.locale("en-gb").utcOffset("+5:30");
now.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

function getFormat(time) {
  return time ? format : "DD-MM-YYYY";
}

const defaultCalendarValue = now.clone();
defaultCalendarValue.add(-1, "month");

const readCookie = require("../../../../../cookie.js").readCookie;

const SHOW_TIME = true;

class Picker extends React.Component {
  getDefaultProps() {
    return {
      showTime: SHOW_TIME,
      disabled: false,
    };
  }

  render() {
    const props = this.props;
    const calendar = (
      <Calendar
        locale={enUS}
        defaultValue={now}
        disabledDate={props.disabledDate}
      />
    );
    return (
      <DatePicker
        animation="slide-up"
        disabled={props.disabled}
        calendar={calendar}
        value={props.value}
        onChange={props.onChange}
      >
        {({ value }) => {
          return (
            <span>
              <input
                placeholder="Select Date"
                disabled={props.disabled}
                readOnly
                className="form-control inline readonly-white"
                value={(value && value.format(getFormat(props.showTime))) || ""}
              />
            </span>
          );
        }}
      </DatePicker>
    );
  }
}

export default class VolunteeringAnalyticsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startValue: null,
      endValue: null,
      lifetime: false,
      office: "",
      department: "",
      offices: [],
      departments: [],
      volunteer: "employeesHours",
      
    };
    this.filterResult = this.filterResult.bind(this);
  }

  componentDidMount() {
    fetch(configs.BACKEND_API_URL + "/list", {
      method: "GET",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {
          this.setState({ offices: data.lists });
        }.bind(this)
      );

    fetch(configs.BACKEND_API_URL + "/departments", {
      method: "GET",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {
          this.setState({ departments: data.data });
        }.bind(this)
      );
    // this.filterResult();
  }

  onChange(field, value) {
    if (field === "lifetime") {
      this.setState({ [field]: !this.state.lifetime }, this.filterResult);
    } else if (field === "office" || field === "department") {
      this.setState({ [field]: value.target.value }, this.filterResult);
    } else if (field === "volunteer") {
      this.setState({ volunteer: value.target.value }, this.filterResult);
    } else {
      this.setState({ [field]: value }, this.filterResult);
    }
  }

  disabledEndDate(endValue) {
    if (!endValue) {
      return false;
    }
    const startValue = this.state.startValue;
    if (!startValue) {
      return false;
    }
    return SHOW_TIME
      ? endValue.isBefore(startValue)
      : endValue.diff(startValue, "days") <= 0;
  }

  disabledStartDate(startValue) {
    if (!startValue) {
      return false;
    }
    const endValue = this.state.endValue;
    if (!endValue) {
      return false;
    }
    return SHOW_TIME
      ? startValue.isAfter(endValue)
      : startValue.diff(endValue, "days") > 0;
  }

  filterResult() {
    let urlParams = "?";
    if (this.state.lifetime) {
      if (urlParams !== "?") {
        urlParams += "&";
      }
      urlParams += "lifetime=" + this.state.lifetime;
    }

    if (this.state.startValue) {
      if (urlParams !== "?") {
        urlParams += "&";
      }
      urlParams += "startDate=" + this.state.startValue;
    }

    if (this.state.endValue) {
      if (urlParams !== "?") {
        urlParams += "&";
      }

      urlParams += "endDate=" + this.state.endValue;
    }

    if (this.state.office !== "") {
      if (urlParams !== "?") {
        urlParams += "&";
      }

      urlParams += "listId=" + this.state.office;
    }

    if (this.state.department !== "") {
      if (urlParams !== "?") {
        urlParams += "&";
      }

      urlParams += "department=" + this.state.department;
    }

    if (urlParams === "?") urlParams = "";

    fetch(
      configs.BACKEND_API_URL +
        "/ee/volunteer-opportunity/per-day-hours" +
        urlParams,
      {
        method: "GET",
        headers: {
          Auth: JSON.parse(readCookie("access_token")).access_token,
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {

          let totalAttendeeHours = 0,guestTotalHours =0 ;
        
          if (this.state.volunteer === "guestHours") {
            let d = { tableData: [], goalsData: [], volunteeringHoursData: [] ,totalExternalParticipantsHours:'00:00',totalAttendeeHours:'00:00', totalGuestHours :'00:00'};
            
            data.eventIdTableData.map((x) => {
              // totalAttendeeHours+= data.hours
              d.tableData.push({
                totalGuest :x.totalGuest,
                guestHours: x.guestHours,
                customUrl: x.customUrl,
                date: x.date,
                id: x.id,
                title: x.title,
              });
            });
            data.goalsData.map((data) => {
              d.goalsData.push({
                axisLabel: data.axisLabel,
                backgroundColor: data.backgroundColor,
                totalGuestHours: data.totalGuestHours,
                iconUrl: data.iconUrl,
                id: data.id,
                name: data.name,
                value: data.value,
              });
            });
            data.volunteeringHoursData.map((data) => {
              d.volunteeringHoursData.push({
                date: data.date,
                totalGuestHours : data.totalGuestHours,
              });
            });
            
            d.attendeeHours = totalAttendeeHours;
            d.totalGuestHours = data.totalGuestHours;
            d.totalExternalParticipantsHours = data.totalExternalParticipantsHours;
            
              d['departmentData'] = data.departmentData; 

            this.props.getContribution(this.state.volunteer);
            this.props.updateEmployeeVolunteering(d);
            } else if (this.state.volunteer === "employeesHours") {
            let d = {
              tableData: data.tableData,
              goalsData: [],
              volunteeringHoursData: [],
              totalAttendeeHours: data.totalAttendeeHours,
              totalGuestHours: data.totalGuestHours,
              totalExternalParticipantsHours: data.totalExternalParticipantsHours,
              totalOrganisationHours :data.totalOrganisationHours,
              departmentData:data.departmentData
            };
            // data.eventIdTableData.map((x) => {
            //   totalAttendeeHours+= x.hours;
            //   guestTotalHours+= x.guestHours;
            //   // console.log("x.guestHours .. . . .. . >>>>",x.guestHours);

                
            //   d.tableData.push({
            //     signUpAttendees :x.signUpAttendees,
            //     totalGuestAttendees : x.totalGuestAttendees,
            //     totalGuestHours: x.totalGuestHours,
            //     customUrl: x.customUrl,
            //     date: x.date,
            //     hours: x.hours,
            //     id: x.id,
            //     title: x.title,
            //     totalAttendees: x.totalAttendees,
            //     guestHours: x.guestHours,
            //     externalParticipantsHours :x.externalParticipantsHours,
            //   });
            // });
            // data.goalsData.map((data) => {
            //   d.goalsData.push({
            //     axisLabel: data.axisLabel,
            //     backgroundColor: data.backgroundColor,
            //     hours: data.hours,
            //     iconUrl: data.iconUrl,
            //     id: data.id,
            //     name: data.name,
            //     value: data.value,
            //   });
            // });
            // data.volunteeringHoursData.map((data) => {
            //   d.volunteeringHoursData.push({
            //     date: data.date,
            //     hours: data.hours,
            //   });
            // });

            // console.log("x.guestHours .. . . .. . >>>>",x.guestHours);
            // d.totalGuestHours = data.totalGuestHours;
            // d['departmentData'] = data.departmentData; 
            d.totalExternalParticipantsHours = data.totalExternalParticipantsHours;
            this.props.getContribution(this.state.volunteer);
            this.props.updateEmployeeVolunteering(d);
          } else if (this.state.volunteer === "employeesAndGuestHours") {
            this.props.getContribution(this.state.volunteer);
            this.props.updateEmployeeVolunteering(data);
          } else {
            this.props.updateEmployeeVolunteering(data);
          }
        }.bind(this)
      );
  }

  resetFilters() {
    let startValue = null;
    let endValue = null;
    this.setState({ endValue: null, startValue: null }, function () {
      this.setState({
        endValue,
        startValue,
        lifetime: false,
        department: "",
        office: "",
        volunteer:"employeesHours",
      });
    });

    // fetch(
    //   configs.BACKEND_API_URL + "/ee/volunteer-opportunity/per-day-hours",
    //   {
    //     method: "GET",
    //     headers: {
    //       Auth: JSON.parse(readCookie("access_token")).access_token,
    //     },
    //   }
    // )
    //   .then(function (response) {
    //     return response.json();
    //   })
    //   .then(
    //     function (data) {
    //       this.props.updateEmployeeVolunteering(data);
    //     }.bind(this)
    //   );
  }

  render() {
    if (this.props.employeeVolunteering) {
      return (
        <div className="volunteering-analytics-filter-container">
          <Row className="pad-tb-20 flex-between">
            <Col md={1} className="form-group mb0">
              <label className="control-label lifetime-check-label">
                <Checkbox
                  onChange={this.onChange.bind(this, "lifetime")}
                  disabled={false}
                />
                Lifetime
              </label>
            </Col>

            <Col md={2} className="form-group mb0">
              <label className="control-label">Start Date:</label>
              <Picker
                className="form-control inline"
                value={this.state.startValue}
                disabledDate={this.disabledStartDate.bind(this)}
                onChange={this.onChange.bind(this, "startValue")}
                disabled={this.state.lifetime}
              />
            </Col>

            <Col md={2} className="form-group mb0">
              <label className="control-label">End Date:</label>
              <Picker
                className="form-control inline"
                disabled={this.state.lifetime}
                disabledDate={this.disabledEndDate.bind(this)}
                value={this.state.endValue}
                onChange={this.onChange.bind(this, "endValue")}
              />
            </Col>

            <Col
              md={2}
              className="form-group mb0 volunteering-analytics-select"
            >
              <label className="control-label">Office:</label>
              <select
                className="inline"
                value={this.state.office}
                onChange={this.onChange.bind(this, "office")}
              >
                <option value="">All</option>
                {this.state.offices.map(function (office, index) {
                  return <option value={office.id}>{office.name}</option>;
                })}
              </select>
            </Col>

            <Col
              md={2}
              className="form-group mb0 volunteering-analytics-select"
            >
              <label className="control-label">Department:</label>
              <select
                className="inline"
                value={this.state.department}
                onChange={this.onChange.bind(this, "department")}
              >
                <option value="">All</option>
                {this.state.departments.map(function (department, index) {
                  return <option value={department}>{department}</option>;
                })}
              </select>
            </Col>
            <Col
              md={2}
              className="form-group mb0 volunteering-analytics-select"
            >
              <label className="control-label">Contribution:</label>
              <select
                className="inline"
                value={this.state.volunteer}
                onChange={this.onChange.bind(this, "volunteer")}
              >
                <option value="employeesHours">Employees Hours</option>
                <option value="guestHours">Guest Hours</option>
                <option value="employeesAndGuestHours">
                  Employees and Guest Hours
                </option>
              </select>
            </Col>

            <Col md={2} className="form-group mb0">
              <label className="control-label"> </label>
              <div className="flex-center">
                <button
                  className="btn-sq btn-le btn-pad-5-20 w100"
                  onClick={this.resetFilters.bind(this)}
                >
                  Reset
                </button>
              </div>
            </Col>
          </Row>
        </div>
      );
    } else {
      return null;
    }
  }
}
