const initialState = {
	loginType: '',
	loginEmail: '',
	loginPassword: '',
};

const SignupReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'CHANGE_LOGIN_TYPE':
			return Object.assign({}, state, {
				loginType: action.loginType
			});
		case 'CANCEL_CORPORATE_SIGNIN':
			return Object.assign({}, state, {
				loginType: ''
			});
		case 'CANCEL_NGO_SIGNIN':
			return Object.assign({}, state, {
				loginType: ''
			});
		case 'CHANGE_LOGIN_EMAIL':
			return Object.assign({}, state, {
				loginEmail: action.loginEmail
			});
		case 'CHANGE_LOGIN_PASSWORD':
			return Object.assign({}, state, {
				loginPassword: action.loginPassword
			});
		default:
			return state
	}
}

export default SignupReducer;