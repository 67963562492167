import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.css';

export default class ProfileSettings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		return (
			<div className="ee-page-container manage-corporate-page">
				<Col md={12} className="manage-corporate-container">
					<h3>Profile Settings</h3>
				</Col>
			</div>
		);
	}
}