import React, { Component } from 'react';
import {Row, Col} from 'react-bootstrap';
import 'rc-calendar/assets/index.css';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import enUS from 'rc-calendar/lib/locale/en_US';
import moment from 'moment';
import 'moment/locale/en-gb';
import Checkbox from 'rc-checkbox';

import 'rc-select/assets/index.css';
import 'rc-checkbox/assets/index.css';
import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css';
import configs from '../../../../../config/urlConfigs.js';

const format = 'DD-MM-YYYY';

const now = moment();
now.locale('en-gb').utcOffset('+5:30');
now.set({hour: 0, minute: 0, second: 0, millisecond: 0});

function getFormat(time) {
	return time ? format : 'DD-MM-YYYY';
}

const defaultCalendarValue = now.clone();
defaultCalendarValue.add(-1, 'month');

const readCookie = require('../../../../../cookie.js').readCookie;

const SHOW_TIME = true;

class Picker extends React.Component {
	getDefaultProps() {
		return {
			showTime: SHOW_TIME,
			disabled: false,
		};
	}

	render() {
		const props = this.props;
		const calendar = (<Calendar
			locale={enUS}
			defaultValue={now}
			disabledDate={props.disabledDate}
		/>);
		return (
			<DatePicker animation="slide-up" disabled={props.disabled}
				calendar={calendar} value={props.value} onChange={props.onChange}>
				{({ value }) => {
					return (
						<span>
              <input placeholder="Select Date"
                disabled={props.disabled} readOnly
                className="form-control inline readonly-white"
                value={value && value.format(getFormat(props.showTime)) || ''} />
            </span>
					);
				}}
			</DatePicker>
		);
	}
}

export default class ContributionAnalyticsFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startValue: null,
			endValue: null,
			lifetime: false,
			office: '',
			department: '',
			offices: [],
			departments: []
		}
	}

	componentDidMount() {
		fetch(configs.BACKEND_API_URL + '/list', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			this.setState({ offices: data.lists });
		}.bind(this));

		fetch(configs.BACKEND_API_URL + '/departments', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			this.setState({ departments: data.data });
		}.bind(this));
	}

	onChange(field, value) {
		if(field === 'lifetime') this.setState({ [field]: !this.state.lifetime }, this.filterResult);
		else if(field === 'office' || field === 'department') this.setState({ [field]: value.target.value }, this.filterResult);
		else this.setState({ [field]: value }, this.filterResult);
	}

	disabledEndDate(endValue) {
		if (!endValue) {
			return false;
		}
		const startValue = this.state.startValue;
		if (!startValue) {
			return false;
		}
		return SHOW_TIME ? endValue.isBefore(startValue) : endValue.diff(startValue, 'days') <= 0;
	}

	disabledStartDate(startValue) {
		if (!startValue) {
			return false;
		}
		const endValue = this.state.endValue;
		if (!endValue) {
			return false;
		}
		return SHOW_TIME ? startValue.isAfter(endValue) : startValue.diff(endValue, 'days') > 0;
	}

	filterResult() {
		let urlParams = '?';

		if(this.state.lifetime) {
			if(urlParams !== "?") {
				urlParams += '&';
			}
			urlParams += 'lifetime=' + this.state.lifetime;
		}

		if(this.state.startValue) {
			if(urlParams !== "?") {
				urlParams += '&';
			}
			urlParams += 'startDate=' + this.state.startValue;
		}

		if(this.state.endValue) {
			if(urlParams !== "?") {
				urlParams += '&';
			}
			urlParams += 'endDate=' + this.state.endValue;
		}

		if(this.state.office !== '') {
			if(urlParams !== "?") {
				urlParams += '&';
			}
			urlParams += 'listId=' + this.state.office;
		}

		if(this.state.department !== '') {
			if(urlParams !== "?") {
				urlParams += '&';
			}
			urlParams += 'department=' + this.state.department;
		}

		if(urlParams === '?') urlParams = '';
		fetch(configs.BACKEND_API_URL + '/get-combined-stats' + urlParams, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			this.props.updateContributionAnalyticsData(data);
		}.bind(this));
	}

	resetFilters() {
		let startValue = null;
		let endValue = null;

		this.setState({ endValue: null, startValue: null }, function() {
			this.setState({ endValue, startValue, lifetime: false, department: '', office: '' });
		});

		fetch(configs.BACKEND_API_URL + '/get-combined-stats', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			this.props.updateContributionAnalyticsData(data);
		}.bind(this));
	}

	render () {
		if(!this.props.employeeVolunteering) {
			return (
				<div className="contribution-analytics-filter-container">
					<Row className="pad-tb-20 flex-between">
						<Col md={2} className="form-group mb0">
							<label className="control-label lifetime-check-label">
								<Checkbox onChange={this.onChange.bind(this, 'lifetime')} disabled={false} />
								Lifetime
							</label>
						</Col>

						<Col md={2} className="form-group mb0">
							<label className="control-label">Start Date:</label>
							<Picker className="form-control inline" value={this.state.startValue} disabledDate={this.disabledStartDate.bind(this)} onChange={this.onChange.bind(this, 'startValue')} disabled={this.state.lifetime} />
						</Col>

						<Col md={2} className="form-group mb0">
							<label className="control-label">End Date:</label>
							<Picker className="form-control inline" disabled={this.state.lifetime} disabledDate={this.disabledEndDate.bind(this)} value={this.state.endValue} onChange={this.onChange.bind(this, 'endValue')} />
						</Col>

						<Col md={2} className="form-group mb0 contribution-analytics-select">
							<label className="control-label">Office:</label>
							<select className="inline form-control" value={this.state.office}
								onChange={this.onChange.bind(this, 'office')}>
								<option value=''>All</option>
								{this.state.offices.map(function(office, index) {
									return (
										<option value={office.id}>{office.name}</option>
									)
								})}
							</select>
						</Col>

						<Col md={2} className="form-group mb0 contribution-analytics-select">
							<label className="control-label">Department:</label>
							<select className="inline form-control" value={this.state.department}
								onChange={this.onChange.bind(this, 'department')}>
								<option value=''>All</option>
								{this.state.departments.map(function(department, index) {
									return (
										<option value={department}>{department}</option>
									)
								})}
							</select>
						</Col>

						<Col md={2} className="form-group mb0">
							<label className="control-label"> </label>
							<div className="flex-center">
								<button className="btn-sq btn-le btn-pad-5-20 w100" onClick={this.resetFilters.bind(this)}>Reset</button>
							</div>
						</Col>
					</Row>
				</div>
			);
		} else {
			return null;
		}
	}
}