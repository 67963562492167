import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Check } from 'react-feather';

const RenderObject = ({ dataObject, handleSelect, selected }) => {
    const [expandObj, setExpandObj] = useState('')
    return (
        <Row>
            <Col md={6} lg={6} sm={6}>
                <div style={{ marginBottom: 30 }}>
                    {Object.keys(dataObject).map(item => <a
                        style={{ textDecoration: 'none' }}
                        onClick={() => setExpandObj(item)}
                    >
                        <p style={{ fontWeight: item === expandObj ? 'bold' : null, marginTop: 10 }}>{item}</p>
                    </a>)}
                </div>
            </Col>
            <Col md={6} lg={6} sm={6}>
                {expandObj && dataObject[expandObj].map(item =>
                    <div class="form-group form-check skills-option" onClick={() => handleSelect(item)}>
                        <a className={selected.includes(item) ? 'checkbox-active' : 'checkbox-inactive'} style={{ paddingLeft: 2, paddingRight: 2 }} onChange={() => handleSelect(item)}>
                            <Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
                        </a>
                        <label class="form-check-label skills-option-label" style={{ fontWeight: selected.includes(item) ? 'bold' : 'normal   ' }} for="exampleCheck1">
                            {item}
                        </label>
                    </div>)}
            </Col>
        </Row>
    )
}

export default RenderObject