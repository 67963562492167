import React, {Component} from 'react';
import {Row, Col, Button, Modal} from 'react-bootstrap';
import Multiselect from 'react-bootstrap-multiselect';
import $ from 'jquery';
import swal from 'sweetalert2';

import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import { ToastContainer, toast } from "react-toastify";
import configs from '../../../../config/urlConfigs.js';
const readCookie = require('../../../../cookie.js').readCookie;

export default class AddBulletinModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '',
      bulletinTypes: [],
      bulletinImages: [],
      displayImages: [],
      description: '',
      offices: [],
      allOffices: [],
      error: []
    }
  }

  updateChange(name, required, value) {
    if(name === 'type') {
      this.setState({ [name]: value.target.value });
    } else if(name === 'offices') {
    }
  }

  onImageUpload(e) {
    let imageUrls = [], displayUrls =[],
      fileLength = e.target.files.length;

    for (let i = 0; i < fileLength; i++) {
      let data = new FormData();
      data.append("image", e.target.files[i]);

      fetch(configs.BACKEND_API_URL + '/uploadImage', {
        method: "POST",
        headers: {
          'Auth': JSON.parse(readCookie('access_token')).access_token,
        },
        body: data
      }).then((data) => data.json())
      .then(function(data) {
        if(data.status === 'ok') {
          imageUrls.push(data.url);
          displayUrls.push(data.displayUrl);
          if(fileLength === imageUrls.length) {
            this.setState({ bulletinImages: imageUrls, displayImages: displayUrls });
          }
        }
      }.bind(this)).catch(err => { this.notifyError("Unsupported file format detected!"); e.target.value = null; });
    }
  }

  addBulletin(saveType) {
    let type = 'bulletin';
    let postCategory = this.state.type;
    let text = this.state.description;
    let images = this.state.bulletinImages;
    let list = this.state.offices;
    if(postCategory !== '' && list.length || true) {
      var data = {};
      if(saveType === 'add') data['type'] = type;
      data['postCategory'] = postCategory;
      data['text'] = text;
      data['images'] = images;
      if(list.length === this.state.allOffices.length) list = true;
      data['list'] = list;
      if(saveType === 'add') {
        fetch(configs.BACKEND_API_URL + '/post', {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Auth': JSON.parse(readCookie('access_token')).access_token
          },
          body: JSON.stringify(data)
        }).then(function(response) {
          return response.json();
        }).then(function(data) {
          if(data.status === 'ok') {
            swal(
              'Awesome!',
              'You have successfully created the bulletin.',
              'success'
            )
            this.props.hideBulletinModal();
          } else {
            swal(
              'Oops!',
              'The bulletin was not created. Please try again!',
              'error'
            )
          }
        }.bind(this));
      } else if(saveType === 'edit') {
        fetch(configs.BACKEND_API_URL + '/post/' + this.props.bulletinToEdit.id, {
          method: "PUT",
          headers: {
            'Content-Type': 'application/json',
            'Auth': JSON.parse(readCookie('access_token')).access_token
          },
          body: JSON.stringify(data)
        }).then(function(response) {
          return response.json();
        }).then(function(data) {
          if(data.status === 'ok') {
            swal(
              'Awesome!',
              'You have successfully updated the bulletin.',
              'success'
            )
            this.props.hideBulletinModal();
          } else {
            swal(
              'Oops!',
              'The bulletin was not updated. Please try again!',
              'error'
            )
          }
        }.bind(this));
      }
    } else {
      var error = [];
      if(postCategory === '') error.push('type');
      if(!list.length) error.push('offices');
      this.setState({ error });
    }
  }

  onDescChange(e) {
    let commentRegex = new RegExp(`^[\.a-zA-Z0-9,!?:;@#$~' ]*$`)
    if (commentRegex.test(e.target.value) === true || true){
    this.setState({ description: e.target.value });}
    else {
      this.notifyError("Only letters, numbers and special characters are allowed");
    }
  }
  notifyError = (text) => toast.error(text);

  componentDidMount() {
    fetch(configs.BACKEND_API_URL + '/post/categories', {
      method: "GET",
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token
      },
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      this.setState({ bulletinTypes: data.categories }, function() {
        if(this.props.hasOwnProperty('bulletinToEdit')) {
          var bulletinToEdit = this.props.bulletinToEdit;
          this.setState({ type: bulletinToEdit.postCategory.id });
        }
      }.bind(this));
    }.bind(this));

    fetch(configs.BACKEND_API_URL + '/list', {
      method: "GET",
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token
      },
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      let allOffices = [];
      // var allOfficeObj = {};
      // allOfficeObj['value'] = true;
      // allOfficeObj['label'] = 'Select All Offices';
      // if(this.props.hasOwnProperty('bulletinToEdit') && this.props.bulletinToEdit.list === true) {
        // allOfficeObj['selected'] = true;
        // this.setState({ offices: ['true'] });
      // }
      // allOffices.push(allOfficeObj);
      for(var i = 0; i < data.lists.length; i++) {
        var officeObj = {};
        officeObj['value'] = data.lists[i].id;
        officeObj['label'] = data.lists[i].name;
        if(this.props.hasOwnProperty('bulletinToEdit')) {
          if(Array.isArray(this.props.bulletinToEdit.list)) {
            if(this.props.bulletinToEdit.list.indexOf(data.lists[i].id) > -1) officeObj['selected'] = true;
          } else if(this.props.bulletinToEdit.list === true) {
            officeObj['selected'] = true;
          }
        }
        allOffices.push(officeObj);
      }
      if(this.props.hasOwnProperty('bulletinToEdit') && Array.isArray(this.props.bulletinToEdit.list)) {
        this.setState({ offices: this.props.bulletinToEdit.list });
      }
      this.setState({ allOffices });
    }.bind(this));

    if(this.props.hasOwnProperty('bulletinToEdit')) {
      var bulletinToEdit = this.props.bulletinToEdit;
      this.setState({ bulletinImages: bulletinToEdit.images, description: bulletinToEdit.text });
    }
  }

  showButtonText(options, select) {
    let offices = [];
    $(options).each(function() {
      offices.push($(this).val());
    });
    this.setState({ offices });
    if (options.length === 0) {
      return 'None selected ...';
    } else if (options.length === $(select).find('option').length) {
      return 'All selected';
    } else if (options.length > 1) {
      return options.length + ' selected';
    } else {
      let labels = [];
      options.each(function() {
        if ($(this).attr('label') !== undefined) {
          labels.push($(this).attr('label'));
        } else {
          labels.push($(this).html());
        }
      });
      return labels.join(', ') + '';
    }
  }

	render() {
    return (
      <Modal bsSize="large" className="add-bulletin-modal" show={this.props.bulletinModalVisibility} onHide={this.props.hideBulletinModal}>
        <Modal.Header>
          <Modal.Title className="modal-header-custom">
            {this.props.type === 'add' ? 'Add Bulletin' : 'Edit Bulletin'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
          <ToastContainer
						position="bottom-center"
						autoClose={50000}
						hideProgressBar={true}
						closeOnClick={false}
						newestOnTop={false}
						pauseOnHover={true}
					/>
            <Col className="margin-col-50" md={12}>
              <form id="bulletin-modal-form"
                // ref="vForm"
              >
                <Col className="form-group" md={4}>
                  <label className="control-label is-imp">Bulletin Type: </label>
                  <select className="form-control" id="type" name="type" onChange={this.updateChange.bind(this, "type", true)} required value={this.state.type}>
                    <option value="" disabled>Select Type</option>
                    {this.state.bulletinTypes.map(function(type, index) {
                      return (
                        <option key={index} value={type.id}>{type.name}</option>
                      )
                    })}
                  </select>
                  {this.state.error.indexOf('type') > -1 ? (
                    <span className="red-color">Incorrect Type!</span>
                   ) : (null)}
                </Col>

                <Col md={6} className="form-group">
                  <label className="control-label is-imp">Select Office(s): </label>
                  <Multiselect id="bulletin-office-select" ref="offices" onChange={this.updateChange.bind(this, 'offices', true)} buttonText={this.showButtonText.bind(this)} enableCaseInsensitiveFiltering enableFiltering buttonWidth={'100%'} data={this.state.allOffices} multiple enableClickableOptGroups enableCollapsibleOptGroups numberDisplayed={3} includeSelectAllOption />
                  {this.state.error.indexOf('offices') > -1 ? (
                    <span className="red-color">Select some office!</span>
                   ) : (null)}
                </Col>

                <Col md={12} className="form-group">
                  <label className="control-label is-imp">Bulletin Description: </label>
                  <textarea className="bulletin-editor form-control" value={this.state.description} onChange={this.onDescChange.bind(this)}></textarea>
                </Col>

                <Col md={12} className="mt20 form-group bulletin-images-container">
                  <label className="control-label bulletin-images-label">Bulletin Images: </label>
                  <label htmlFor="postImageUpload" className="attachment btn-file">
                    <i className="fa fa-camera"> </i>
                    <input id="postImageUpload" accept="image/*" type="file" multiple="multiple" style={{'display': 'block !important'}} onChange={this.onImageUpload.bind(this)}/>
                  </label>
                  <br/>
                  <note style={{fontSize: '10px'}}>(Only JPEG and PNG image formats are allowed.)</note>
                  {this.state.displayImages.length ? (
                    // {this.state.bulletinImages.length ? (
                    <div className="col-md-12 uploadedImage">
                      {this.state.displayImages.map(function(img, i){
                        // this.state.bulletinImages.map(function (img, i) {
                        return (
                          <div style={{marginLeft: '10px', border: '2px solid black', float: 'left', width: '64px', height: '64px'}} key={i}>
                            <img className="updImage" src={img} alt="image" style={{width: '60px', height: '60px'}} />
                          </div>
                        )
                      })}
                    </div>
                  ) : (null)}
                </Col>
              </form>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="footer-center">
        	<Button className="btn btn-success" onClick={this.addBulletin.bind(this, this.props.type)}>Save</Button>
        	<Button className="btn btn-danger" onClick={this.props.hideBulletinModal.bind(this)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
		)
	}
}