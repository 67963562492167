import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import FreshdeskWidget from '@personare/react-freshdesk-widget';
import $ from 'jquery';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as action from "./actions/RoleAction";
import Signup from "./components/2-Signup/Signup";
import FirstLogin from "./components/EE/FirstLogin";
import NotFound from "./components/NotFound/NotFound";
import Verify from "./components/Verify/Verify";
import ChangePasswordLayout from "./components/EE/ChangePasswordLayout";

// Admin
import AdminLogin from "./components/Admin/AdminLogin";
import AdminDashboard from "./components/Admin/AdminDashboard";
import AdminSidebar from "./components/Admin/AdminSidebar";
import ManageCorporate from "./components/Admin/ManageCorporate";
import ManageNGO from "./components/Admin/ManageNGO";
import CreateVolunteerOpportunity from "./components/Admin/CreateVolunteerOpportunity";
import ManageVolunteerOpportunity from "./components/Admin/ManageVolunteerOpportunity";
import ManageSingleVolunteerOpportunity from "./components/Admin/ManageSingleVolunteerOpportunity";
import AddMember from "./components/Admin/AddMember";
import ProfileSettings from "./components/Admin/ProfileSettings";

// EE
import Recommendations from "./components/EE/2-ImpactVolunteering/RecommendedOpportunities/RecommendedOpportunities";
import EETopBar from "./components/EE/0-Navigation/TopBar";
import EEHeader from "./components/EE/0-Navigation/Header";
import EEFeed from "./components/EE/1-Feed/Feed";
import EEPreferences from "./components/EE/4-Preferences/Preferences";
import EEDiscoverOpportunities from "./components/EE/2-ImpactVolunteering/DiscoverOpportunities/DiscoverOpportunities";
import EEVolunteerHistory from "./components/EE/2-ImpactVolunteering/MyVolunteerHistory/VolunteerHistory";
import EESingleVolunteerHistory from "./components/EE/2-ImpactVolunteering/MyVolunteerHistory/EESingleVolunteerHistory";
import EERecommendAnOpportunity from "./components/EE/2-ImpactVolunteering/RecommendAnOpportunity/RecommentAnOpportunity";
import EESingleOpportunity from "./components/EE/2-ImpactVolunteering/DiscoverOpportunities/SingleOpportunity/SingleOpportunity";
import EESingleOpportunityGallery from "./components/EE/2-ImpactVolunteering/DiscoverOpportunities/SingleOpportunity/SingleOpportunityGallery";
import EEEndorseProjects from "./components/EE/3-Endorsements/EndorseProjects/EndorsementProjects";
import EESingleProject from "./components/EE/3-Endorsements/EndorseProjects/SingleProject/SingleProject";
import EEEndorsePayment from "./components/EE/3-Endorsements/EndorseProjects/SingleProject/Endorse/Endorse";
import EEEndorseHistory from "./components/EE/3-Endorsements/MyEndorsementHistory/EndorsementHistory";
import EEEndorseInitiate from "./components/EE/3-Endorsements/EndorseProjects/SingleProject/Endorse/Initiate";
import EEVolunteerForm from "./components/EE/Form/Form";
import EEVolunteerCloneForm from "./components/EE/Form/EEVolunteerCloneForm";
import EEProfile from "./components/EE/Profile/EEProfile";
import EEQuestionnaire from "./components/EE/EEQuestionnaire/EEQuestionnaire";
import EEPayrollGiving from "./components/EE/EEPayrollGiving/EEPayrollGiving";
import EEPayrollPartners from "./components/EE/EEPayrollGiving/EEPayrollPartners";
import EESingleOpportunityParticipantsList from "./components/EE/2-ImpactVolunteering/DiscoverOpportunities/SingleOpportunity/SingleOpportunityParticipantsList";
// EE Admin
import EEAdminHeader from "./components/EE/Admin/0-Navigation/Header";
import EEAdminContributionAnalytics from "./components/EE/Admin/1-Dashboard/ContributionAnalytics/ContributionAnalytics";
import EEAdminVolunteeringAnalytics from "./components/EE/Admin/1-Dashboard/VolunteeringAnalytics/VolunteeringAnalytics";
import EEAdminLeaderboard from "./components/EE/Admin/2-Leaderboard/Leaderboard";
import EEAdminBulletin from "./components/EE/Admin/3-Bulletin/Bulletin";
import EEAdminRecommendedOpportunities from "./components/EE/Admin/4-ProjectManagement/RecommendedOpportunities/RecommendedOpportunities";
import EEAdminVolunteerManagement from "./components/EE/Admin/4-ProjectManagement/VolunteerOpportunities/VolunteerOpportunities";
import EESingleOpportunityAnalytics from "./components/EE/Admin/4-ProjectManagement/VolunteerOpportunities/SingleOpportunityAnalytics";
import EEAdminFundraiserManagement from "./components/EE/Admin/4-ProjectManagement/Fundraisers/Fundraisers";
import EEAdminManageNGOs from "./components/EE/Admin/4-ProjectManagement/EEAdminManageNGOs";

import EEAdminSettings from "./components/EE/Admin/5-Settings/Settings";
import EEAdminManageTeams from "./components/EE/Admin/6-ManageTeam/ManageTeams";
import EEAdminManageSingleTeam from "./components/EE/Admin/6-ManageTeam/ManageSingleTeam";
import EEAdminTeamMember from "./components/EE/Admin/6-ManageTeam/TeamMember";
import EEAdminBilling from "./components/EE/Admin/7-Billing/Billing";
import RoleContext from "./contexts/RoleContext";
import configs from "./config/urlConfigs";
import FAQs from "./components/EE/Faqs/FAQs";
import PledgesForm from "./components/EE/1-Feed/PledgesForm.js";
import NewLeaderboard from "./components/EE/Admin/2-Leaderboard/NewLeaderboard.js";

const readCookie = require("./cookie.js").readCookie;
const createCookie = require("./cookie.js").createCookie;
const eraseCookie = require("./cookie.js").eraseCookie;
// const subdomain = window.location.hostname.split('.')[0];
const subdomain = configs.SUBDOMAIN;

export const IBLNavbar = () => {
  return <div className="ibl-navbar">
    {/* <div className="ee-page-container sticky-logo-wrap"> */}
    <img src="/img/IBL/IBL sattvam logo.png" className='sticky-sattvam-logo-image' alt="IBL Sattvam logo PNG" />
    <img src="/img/IBL_Logo_top_right.png" className='sticky-logo-image' alt="IBL Logo PNG" />
    {/* </div> */}
  </div>;
}


export const OrgDataContext = React.createContext();
const DefaultEELayout = ({ component: Component, ...rest }) => {

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <div className="ee-container">
          {subdomain.toLowerCase() === 'indusind' ?
            <DocumentTitle title="The IndusInd Employee Volunteering Portal (Powered by LetsEndorse)" /> :
            <DocumentTitle title="Strengthen your volunteer and giving Management today (Powered by LetsEndorse)" />

          }



          {/* <DocumentTitle title="Strengthen your volunteer and giving Management today (Powered by LetsEndorse)" /> */}
          {subdomain.toLowerCase() === 'indusind'
            ?
            <IBLNavbar />
            : null}
          {subdomain.toLowerCase() === 'indusind'
            ?
            // <div className="sticky-logo-container">
            //   <div className="ee-page-container sticky-logo-wrap">
            //     <img src="/img/IBL_Logo_top_right.png" className='sticky-logo-image' alt="IBL Logo PNG" />
            //   </div>
            // </div>
            null
            : <div className="sticky-logo-container">
              <div className="ee-page-container sticky-logo-wrap">
                {/* <img src="/img/IBL_Logo_top_left.png" className='sticky-sattvam-logo-image' alt="IBL Sattvam logo PNG" /> */}

                <img src="/img/LetsEngage-top-right.png" className='sticky-logo-image' alt="LetsEngage Logo PNG" />
              </div>
            </div>}
          <EEHeader showModal={rest.showModal} selected={rest.selected} />
          <EETopBar />
          <div style={{ zIndex: "999999999999", position: "sticky" }}
          >
            {/* <button className="btn send-feedback-btn"> */}
            {/* <Menu.Item key="freshdesk"> */}
            {/* <SvgIcon size={14} icon={logout} /> */}
            {/* <i class="fa-duotone fa-message"></i>
                         */}
            {/* <i class="fa-solid fa-comment"/> */}
            <FreshdeskWidget url="https://letsendorse.freshdesk.com/" key={'555'} type="pop-up">
              {/* <span className="ee-header-menu-text">Send Feedback</span>
                         */}
              {/* <i class="fafa-regular fa-messages-question"></i> */}
              {/* <i class="fa fa-ticket"></i> */}
              <div class="bottom-left-caller" style={{ position: "fixed", bottom: "20px", right: "20px", cursor: "pointer" }}>
                <i class="btn btn-primary btn-lg fa 
                          fa-flip-horizontal fa-5x fa-comments" aria-hidden="true" title="Send feedback"></i>
              </div>
              {/* <i class="fa-solid fa-message-question"></i> */}

            </FreshdeskWidget>
            {/* </Menu.Item> */}
            {/* </button> */}
          </div>
          <Component {...matchProps} paymentGateway={rest.paymentGateway} />
          {/* <ProfileModal /> */}
        </div>
      )}
    />
  );
};


const DefaultEndorseLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <div className={subdomain === 'indusind' ? "ee-container ibl-ee-container" : "ee-container"}>
          {subdomain.toLowerCase() === 'indusind' ?
            <DocumentTitle title="The IndusInd Employee Volunteering Portal (Powered by LetsEndorse)" /> :
            <DocumentTitle title="Strengthen your volunteer and giving Management today (Powered by LetsEndorse)" />

          }
          {/* <DocumentTitle title="Strengthen your volunteer and giving Management today (Powered by LetsEndorse)" /> */}
          <Component {...matchProps} />
        </div>
      )}
    />
  );
};

const DefaultEEAdminLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <div className="ee-container">
          {/* <DocumentTitle title="Admin - LetsEngage" />
           */}
          {subdomain.toLowerCase() === 'indusind' ?
            // <DocumentTitle title="Admin - LetsEngage" /> :
            <DocumentTitle title="The IndusInd Employee Volunteering Portal (Powered by LetsEndorse)" /> :
            <DocumentTitle title="Strengthen your volunteer and giving Management today (Powered by LetsEndorse)" />

          }
          {subdomain.toLowerCase() === 'indusind'
            ?
            <IBLNavbar />
            : null}
          {subdomain.toLowerCase() === 'indusind'
            ?
            // <div className="sticky-logo-container">
            //   <div className="ee-page-container sticky-logo-wrap">
            //     <img src="/img/IBL_Logo_top_right.png" className='sticky-logo-image' alt="IBL Logo PNG" />
            //   </div>
            // </div>
            null
            : <div className="sticky-logo-container">
              <div className="ee-page-container sticky-logo-wrap">
                <img src="/img/LetsEngage-top-right.png" className='sticky-logo-image' alt="LetsEngage Logo PNG" />
              </div>
            </div>}
          <EEAdminHeader selected={rest.selected} />
          <EETopBar />
          <Component {...matchProps} admin={rest.admin} />
        </div>
      )}
    />
  );
};

const DefaultSuperAdminLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <div className="ee-container">
          {subdomain.toLowerCase() === 'indusind' ?
            <DocumentTitle title="Admin Dashboard" /> :
            <DocumentTitle title="Strengthen your volunteer and giving Management today (Powered by LetsEndorse)" />

          }
          {/* <DocumentTitle title="Admin Dashboard" /> */}
          <AdminSidebar selected={rest.selected} />
          <Component {...matchProps} />
        </div>
      )}
    />
  );
};

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: null,
      showProfileModal: false,
      organisationData: null,
    };
  }

  componentWillMount() {

    var that = this;
    if (readCookie("userData") !== null) {
      if (readCookie("letsEngageVersion") !== null) {
        if (readCookie("letsEngageVersion") === process.env.REACT_APP_VERSION) {
          let userDataCookie = readCookie("userData");
          let userDataJSON = JSON.parse(userDataCookie);
          if (window.location.href.includes('/admin') && userDataJSON.role === 'admin') {
            this.props.actions.updateUserRole('admin');
            window.location.pathname = window.location.href.split('/admin')[1] || '';
          }
          this.setState({ userData: userDataJSON });
        } else {
          console.log("case 1");
          eraseCookie("userData");
          eraseCookie("access_token");
          eraseCookie("letsEngageVersion");

          window.location.pathname = "";
        }
      } else {
        console.log("case 2");

        eraseCookie("userData");
        eraseCookie("access_token");
        eraseCookie("letsEngageVersion");

        window.location.pathname = "";
      }
    } else {
      const RESUMPTION_URL = window.location.pathname;
      if (RESUMPTION_URL && RESUMPTION_URL !== '/') {
        createCookie('RESUMPTION_URL', RESUMPTION_URL, 1);
      }
      eraseCookie("userData");
      eraseCookie("access_token");
      eraseCookie("letsEngageVersion");
    }
    if (readCookie('userData') && readCookie("access_token") && JSON.parse(readCookie('userData'))._organisationId && JSON.parse(readCookie("access_token")) && !this.state.organisationData) {
      fetch(configs.BACKEND_API_URL + '/org-data/' + JSON.parse(readCookie('userData'))._organisationId,
        {
          headers: {
            Auth: JSON.parse(readCookie("access_token")).access_token,
          },
        }
      )
        .then(
          data => data.json()
        ).then(data => {
          if(data.status = 'ok'){
            this.setState({ organisationData: data.organisationData })
          }
        
        })
        .catch(console.error);

      // fetch(configs.BACKEND_API_URL + '/get-user-data', {
      //   method: "GET",
      //   headers: {
      //     'Auth': JSON.parse(readCookie('access_token')).access_token
      //   },
      // }).then(function (response) {
      //   return response.json();
      // }).then(function (responseData) {
      //   // console.log(data.data);
      let userData = JSON.parse(readCookie("userData"));
      that.setState({ userData });

        // let data = responseData.data;
        // that.setState({ userData: data });
      //   userData.fullName = data.fullName;
      //   userData.mobileNo = data.mobileNo;
      //   userData.department = data.department;
      //   userData.profilePicture = data.profilePicture;
      //   userData.designationAtOrganisation = data.designationAtOrganisation;
      //   delete userData.organisationData;
      //   delete userData.questionnairePreferences;
      //   delete userData.preference;

      //   createCookie("userData", JSON.stringify(userData), 7);
      // });
      // .bind(this));
    }
  }

  componentDidMount() {
    $(document).ready(function () {
      if (subdomain === 'indusind') {
        // $('.ee-page-container').css('top', '105px !important');
        $('.back-to-employee-mode').css('bottom', '200px');
        $('.switch-to-admin-mode').css('bottom', '200px');
        $('#powered-by-container').css('bottom', '120px');
      }
      else {
        $('.ee-page-container').css('top', '15%');
        $('.back-to-employee-mode').css('bottom', '80px');
        $('.switch-to-admin-mode').css('bottom', '80px');
        $('#powered-by-container').css('bottom', '5px');
      }
    });
  }

  render() {
    let link = document.queryCommandValue("link[rel~={Logo}]");
    if (!link && subdomain === 'indusind') {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
      link.href = '/ibl-favicon.ico';
    }

    return (
      <Router>
        <div>
          {(() => {
            if (this.state.userData !== null) {
              if (this.state.organisationData !== null) {
                if (this.props.role === 'admin' && (this.state.userData.type === "corporate" ||
                  this.state.userData.type === "employee")) {
                  return <OrgDataContext.Provider value={this.state.organisationData}>
                    <Switch>
                      {/* EE Admin */}
                      {/* Controlled with redux props*/}
                      (
                      <Switch>
                        <Redirect
                          from="/"
                          exact
                          to="/volunteering-analytics"
                        />
                        <Redirect
                          from="/feed"
                          exact
                          to="/volunteering-analytics"
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/contribution-analytics"
                          selected="contribution-analytics"
                          component={EEAdminContributionAnalytics}
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/volunteering-analytics"
                          selected="volunteering-analytics"
                          component={EEAdminVolunteeringAnalytics}
                        />
                        {/* <DefaultEEAdminLayout
                          exact
                          path="/leaderboard"
                          selected="leaderboard"
                          component={EEAdminLeaderboard}
                        /> */}
                        <DefaultEEAdminLayout 
                        exact
                        path='/leaderboard'
                        selected="leaderboard"
                        component={NewLeaderboard}
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/bulletin"
                          selected="bulletin"
                          component={EEAdminBulletin}
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/settings"
                          selected="settings"
                          component={EEAdminSettings}
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/opportunity/:name"
                          selected="volunteer-management"
                          component={EESingleOpportunity}
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/opportunity/:name/analytics"
                          selected="volunteer-management"
                          component={EESingleOpportunityAnalytics}
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/opportunity/:name/gallery"
                          selected="volunteer-management"
                          component={EESingleOpportunityGallery}
                        />
                        <DefaultEEAdminLayout
                          exact path="/opportunity/:name"
                          selected="volunteer-management"
                          component={EESingleOpportunityParticipantsList}
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/volunteer-opportunity/create"
                          selected="create-volunteer"
                          admin="admin"
                          component={EEVolunteerForm}
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/volunteer-opportunity/:opportunityId"
                          admin="admin"
                          component={EEVolunteerForm}
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/volunteer-opportunity/:opportunityId/clone"
                          admin="admin"
                          component={EEVolunteerCloneForm}
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/manage-ngos"
                          selected="manage-ngos"
                          component={EEAdminManageNGOs}
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/discover-opportunities"
                          selected="discover-opportunities"
                          component={EEDiscoverOpportunities}
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/opportunity-recommendations"
                          selected="opportunity-recommendations"
                          component={EEAdminRecommendedOpportunities}
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/volunteer-management"
                          selected="volunteer-management"
                          component={EEAdminVolunteerManagement}
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/fundraiser-management"
                          selected="fundraiser-management"
                          component={EEAdminFundraiserManagement}
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/project/:name"
                          selected="fundraiser-management"
                          component={EESingleProject}
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/team-management"
                          selected="team-management"
                          component={EEAdminManageTeams}
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/team-management/:listId"
                          selected="team-management"
                          component={EEAdminManageSingleTeam}
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/team-management/:listId/:memberId"
                          selected="team-management"
                          component={EEAdminTeamMember}
                        />
                        <DefaultEEAdminLayout
                          exact
                          path="/profile/:memberId"
                          selected="team-management"
                          component={EEAdminTeamMember}
                        />

                        {/*<DefaultEEAdminLayout exact path="/billing" selected='billing' component={EEAdminBilling} />*/}
                        <DefaultEEAdminLayout
                          exact
                          path="/profile"
                          selected="profile"
                          component={EEAdminTeamMember}
                        />

                        <Redirect from="/" to="/feed" />
                      </Switch>
                      )
                      {/* : (
                      <Redirect to="/feed" />
                     )
                    } */}
                      <Route component={NotFound} />
                    </Switch></OrgDataContext.Provider>;
                }
                else if (
                  this.state.userData.type === "corporate" ||
                  this.state.userData.type === "employee"
                ) {
                  //   (this.state.userData.type === "employee"));
                  if (this.state.userData.hasCustomPassword || this.state.organisationData.ssoEnabled) {
                    return (
                      <OrgDataContext.Provider value={this.state.organisationData}>
                        <Switch>
                          {/* EE */}
                          {this.state.organisationData.eePreferences
                            .enableEmployeeQuestionnaire &&
                            this.state.organisationData.eePreferences.hasOwnProperty(
                              "questionnaire"
                            ) &&
                            this.state.organisationData.eePreferences
                              .questionnaire.selectedEmployeeQuestions.length &&
                            !Object.keys(this.state.userData.questionnairePreferences)
                              .length &&
                            readCookie("skippedQuestionnairePreferences") !==
                            "true" ? (
                            <DefaultEELayout
                              path="/"
                              selected="questionnaire"
                              component={EEQuestionnaire}
                            />
                          ) : (
                            <Redirect exact from="/" to="/feed" />
                          )}

                          <DefaultEELayout
                            exact
                            path="/feed"
                            selected="feed"
                            component={EEFeed}
                          />
                          <DefaultEELayout
                            path="/pledges/:pledgeId"
                            selected="pledges"
                            component={PledgesForm}
                          />
                          <DefaultEELayout
                            exact
                            path="/faqs"
                            selected="faqs"
                            component={FAQs}
                          />
                          <DefaultEELayout
                            exact
                            path="/discover-opportunities"
                            selected="discover-opportunities"
                            component={EEDiscoverOpportunities}
                          />

                          <DefaultEELayout
                            exact
                            path="/recommendations"
                            selected="recommendations"
                            component={Recommendations}
                          />

                          <DefaultEELayout
                            exact
                            path="/recommend-an-opportunity"
                            selected="recommend-an-opportunity"
                            component={Recommendations}
                          />
                          <DefaultEELayout
                            exact
                            path="/opportunity/:name"
                            selected="discover-opportunities"
                            component={EESingleOpportunity}
                          />
                          <DefaultEELayout
                            exact
                            path="/opportunity/:name/gallery"
                            selected="discover-opportunities"
                            component={EESingleOpportunityGallery}
                          />
                          <DefaultEELayout
                            exact path="/opportunity/:name"
                            selected="discover-opportunities"
                            component={EESingleOpportunityParticipantsList}
                          />
                          <DefaultEELayout
                            exact
                            path="/my-volunteer-history"
                            selected="my-volunteer-history"
                            component={EEVolunteerHistory}
                          />
                          <DefaultEELayout
                            exact
                            path="/my-volunteer-history/:name"
                            selected="my-volunteer-history"
                            component={EESingleOpportunity}
                          />
                          <DefaultEELayout
                            exact
                            path="/my-volunteer-history/:name/gallery"
                            selected="my-volunteer-history"
                            component={EESingleOpportunityGallery}
                          />
                          <DefaultEELayout exact path="/my-volunteer-history/:name/participants"
                            selected="my-volunteer-history"
                            component={EESingleOpportunityParticipantsList}
                          />
                          <DefaultEELayout
                            exact
                            path="/endorse-projects"
                            selected="endorse-projects"
                            component={EEEndorseProjects}
                          />
                          <DefaultEELayout
                            exact
                            path="/project/:name"
                            selected="endorse-projects"
                            component={EESingleProject}
                          />
                          <DefaultEELayout
                            exact
                            path="/project/:name/complete-im"
                            selected="endorse-projects"
                            paymentGateway="instamojo"
                            component={EESingleProject}
                          />

                          <DefaultEELayout
                            exact
                            path="/project/:name/complete-cp"
                            selected="endorse-projects"
                            paymentGateway="citrus"
                            component={EESingleProject}
                          />
                          <DefaultEELayout
                            exact
                            path="/my-endorsement-history"
                            selected="my-endorsement-history"
                            component={EEEndorseHistory}
                          />
                          <DefaultEELayout
                            exact
                            path="/profile"
                            selected="profile"
                            component={EEAdminTeamMember}
                          />
                          <DefaultEELayout
                            exact
                            path="/questionnaire"
                            selected="questionnaire"
                            component={EEQuestionnaire}
                          />

                          <DefaultEELayout
                            exact
                            path="/user/:memberId"
                            selected="team-management"
                            component={EEAdminTeamMember}
                          />

                          <DefaultEELayout
                            exact
                            path="/questionnaire"
                            selected="questionnaire"
                            component={EEQuestionnaire}
                          />

                          <DefaultEELayout
                            exact
                            path="/profile/:memberId"
                            selected="profile"
                            component={EEAdminTeamMember}
                          />
                          

                          {this.state.organisationData
                            .eePreferences.enablePayrollGiving ? (
                            <DefaultEELayout
                              exact
                              path="/payroll-giving"
                              selected="payroll-giving"
                              component={EEPayrollGiving}
                            />
                          ) : null}
                          {this.state.organisationData
                            .eePreferences.enablePayrollGiving ? (
                            <DefaultEELayout
                              exact
                              path="/payroll-partners"
                              selected="payroll-partners"
                              component={EEPayrollPartners}
                            />
                          ) : null}

                          {/*<DefaultEELayout path="/preferences" selected='preferences' component={EEPreferences} />*/}

                          {/* Endorse */}
                          {this.state.organisationData
                            .eePreferences.enableGiveBack ? (
                            <Route
                              exact
                              path="/endorse/initiate"
                              component={EEEndorseInitiate}
                            />
                          ) : null}
                          {this.state.organisationData
                            .eePreferences.enableGiveBack ? (
                            <DefaultEndorseLayout
                              exact
                              path="/endorse"
                              component={EEEndorsePayment}
                            />
                          ) : null}

                          {/* EE Admin */}
                          {/* Controlled with redux props*/}
                          {JSON.parse(readCookie("userData")).role === "admin" && this.props.role === 'admin' ? (
                            <Switch>
                              <Redirect
                                from="/"
                                exact
                                to="/volunteering-analytics"
                              />
                              <DefaultEEAdminLayout
                                exact
                                path="/contribution-analytics"
                                selected="contribution-analytics"
                                component={EEAdminContributionAnalytics}
                              />
                              <DefaultEEAdminLayout
                                exact
                                path="/volunteering-analytics"
                                selected="volunteering-analytics"
                                component={EEAdminVolunteeringAnalytics}
                              />
                              {/* <DefaultEEAdminLayout
                                exact
                                path="/leaderboard"
                                selected="leaderboard"
                                component={EEAdminLeaderboard}
                              /> */}
                              <DefaultEEAdminLayout 
                                exact
                                path='/leaderboard'
                                selected="leaderboard"
                                component={NewLeaderboard}
                                />
                              <DefaultEEAdminLayout
                                exact
                                path="/bulletin"
                                selected="bulletin"
                                component={EEAdminBulletin}
                              />
                              <DefaultEEAdminLayout
                                exact
                                path="/settings"
                                selected="settings"
                                component={EEAdminSettings}
                              />
                              <DefaultEEAdminLayout
                                exact
                                path="/opportunity/:name"
                                selected="volunteer-management"
                                component={EESingleOpportunity}
                              />
                              <DefaultEEAdminLayout
                                exact
                                path="/opportunity/:name/analytics"
                                selected="volunteer-management"
                                component={EESingleOpportunityAnalytics}
                              />
                              <DefaultEEAdminLayout
                                exact
                                path="/opportunity/:name/gallery"
                                selected="volunteer-management"
                                component={EESingleOpportunityGallery}
                              />
                              <DefaultEEAdminLayout
                                exact path="/opportunity/:name"
                                selected="volunteer-management"
                                component={EESingleOpportunityParticipantsList}
                              />
                              <DefaultEEAdminLayout
                                exact
                                path="/volunteer-opportunity/create"
                                selected="create-volunteer"
                                admin="admin"
                                component={EEVolunteerForm}
                              />
                              <DefaultEEAdminLayout
                                exact
                                path="/volunteer-opportunity/:opportunityId"
                                admin="admin"
                                component={EEVolunteerForm}
                              />
                              <DefaultEEAdminLayout
                                exact
                                path="/volunteer-opportunity/:opportunityId/clone"
                                admin="admin"
                                component={EEVolunteerCloneForm}
                              />
                              <DefaultEEAdminLayout
                                exact
                                path="/manage-ngos"
                                selected="manage-ngos"
                                component={EEAdminManageNGOs}
                              />
                              <DefaultEEAdminLayout
                                exact
                                path="/discover-opportunities"
                                selected="discover-opportunities"
                                component={EEDiscoverOpportunities}
                              />
                              <DefaultEEAdminLayout
                                exact
                                path="/opportunity-recommendations"
                                selected="opportunity-recommendations"
                                component={EEAdminRecommendedOpportunities}
                              />
                              <DefaultEEAdminLayout
                                exact
                                path="/volunteer-management"
                                selected="volunteer-management"
                                component={EEAdminVolunteerManagement}
                              />
                              <DefaultEEAdminLayout
                                exact
                                path="/fundraiser-management"
                                selected="fundraiser-management"
                                component={EEAdminFundraiserManagement}
                              />
                              <DefaultEEAdminLayout
                                exact
                                path="/project/:name"
                                selected="fundraiser-management"
                                component={EESingleProject}
                              />
                              <DefaultEEAdminLayout
                                exact
                                path="/team-management"
                                selected="team-management"
                                component={EEAdminManageTeams}
                              />
                              <DefaultEEAdminLayout
                                exact
                                path="/team-management/:listId"
                                selected="team-management"
                                component={EEAdminManageSingleTeam}
                              />
                              <DefaultEEAdminLayout
                                exact
                                path="/team-management/:listId/:memberId"
                                selected="team-management"
                                component={EEAdminTeamMember}
                              />
                              <DefaultEEAdminLayout
                                exact
                                path="/:memberId"
                                selected="team-management"
                                component={EEAdminTeamMember}
                              />

                              {/*<DefaultEEAdminLayout exact path="/billing" selected='billing' component={EEAdminBilling} />*/}
                              <DefaultEEAdminLayout
                                exact
                                path="/profile"
                                selected="profile"
                                component={EEAdminTeamMember}
                              />

                              <Redirect from="/" to="/feed" />
                            </Switch>
                          ) : (
                            <Redirect to="/feed" />
                          )}
                          <Route component={NotFound} a={"a"} />
                        </Switch>

                      </OrgDataContext.Provider>
                    );
                  } else {
                    return (
                      <OrgDataContext.Provider value={this.state.organisationData}>
                        <Switch>
                          <Route
                            exact
                            path="/change-password"
                            component={FirstLogin}
                          />
                          <Route component={NotFound} />
                        </Switch>
                      </OrgDataContext.Provider>
                    );
                  }
                } else if (this.state.userData.type === "super_admin") {
                  return (
                    <div>
                      <OrgDataContext.Provider value={this.state.organisationData}>
                        <Switch>
                          <Redirect from="/" exact to="/admin/manage-corporate" />
                          <Redirect
                            from="/admin"
                            exact
                            to="/admin/manage-corporate"
                          />
                          <DefaultSuperAdminLayout
                            exact
                            path="/admin/manage-corporate"
                            selected="manage-corporate"
                            component={ManageCorporate}
                          />
                          <DefaultSuperAdminLayout
                            exact
                            path="/admin/create-volunteer-opportunity"
                            selected="create-volunteer-opportunity"
                            component={CreateVolunteerOpportunity}
                          />
                          <DefaultSuperAdminLayout
                            exact
                            path="/admin/manage-volunteer-opportunity"
                            selected="manage-volunteer-opportunity"
                            component={ManageVolunteerOpportunity}
                          />
                          <DefaultSuperAdminLayout
                            exact
                            path="/admin/manage-volunteer-opportunity/:opportunityId"
                            component={ManageSingleVolunteerOpportunity}
                          />
                          <DefaultSuperAdminLayout
                            exact
                            path="/admin/manage-ngo"
                            selected="manage-ngo"
                            component={ManageNGO}
                          />
                          <DefaultSuperAdminLayout
                            exact
                            path="/admin/add-member"
                            selected="add-member"
                            component={AddMember}
                          />
                          <DefaultSuperAdminLayout
                            exact
                            path="/admin/profile-settings"
                            selected="profile-settings"
                            component={ProfileSettings}
                          />
                          <Route component={NotFound} />
                        </Switch>
                      </OrgDataContext.Provider>
                    </div>
                  );

                } else {
                  console.log('case 4');
                  eraseCookie("userData");
                  return (
                    <OrgDataContext.Provider value={this.state.organisationData}>

                      <div>
                        {/* <DocumentTitle title="Strengthen your volunteer and giving Management today (Powered by LetsEndorse)" />
                       */}
                        {subdomain.toLowerCase() === 'indusind' ?
                          <DocumentTitle title="The IndusInd Employee Volunteering Portal (Powered by LetsEndorse)" /> :
                          <DocumentTitle title="Strengthen your volunteer and giving Management today (Powered by LetsEndorse)" />

                        }
                        <Switch>
                          <Route path="/" component={Signup} />
                          <Route component={NotFound} d={"d"} />
                        </Switch>
                      </div>
                    </OrgDataContext.Provider>
                  );
                }
              }
              else return <div className="org-data-loader" />;
            } else {
              return (
                <div>
                  {/* <DocumentTitle title="Strengthen your volunteer and giving Management today (Powered by LetsEndorse)" />
                   */}
                  {subdomain.toLowerCase() === 'indusind' ?
                    <DocumentTitle title="The IndusInd Employee Volunteering Portal (Powered by LetsEndorse)" /> :
                    <DocumentTitle title="Strengthen your volunteer and giving Management today (Powered by LetsEndorse)" />

                  }
                  <Switch>
                    <Route path="/verify-email/:name" component={Verify} />
                    {/* <Route path="/admin" component={AdminLogin} /> */}
                    <Route
                      exact
                      path="/reset-password/:token"
                      component={ChangePasswordLayout}
                    />
                    <Route path="/" component={Signup} />
                    <Route component={NotFound} e={"e"} />
                  </Switch>
                </div>
              );
            }
          })()}
        </div>
      </Router>
    );
  }
}

Routes.propTypes = {
  role: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    role: state.RoleReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(action, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes);