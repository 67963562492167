const initialState = {
	adminLoggedIn: false,
	selectedMenuItem: 'manageCorporate',
	corporateUsers: [],
	ngoUsers: []
}

const AdminReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'CHANGE_LOGIN_STATUS':
			return Object.assign({}, state, {
				adminLoggedIn: action.loginStatus
			});
		case 'CHANGE_SELECTED_MENU_ITEM':
			return Object.assign({}, state, {
				selectedMenuItem: action.selectedMenuItem
			});
		case 'UPDATE_CORPORATE_USERS':
			return Object.assign({}, state, {
				corporateUsers: action.corporateUsers
			});
		case 'UPDATE_NGO_USERS':
			return Object.assign({}, state, {
				ngoUsers: action.ngoUsers
			});
		default:
			return state
	}
}

export default AdminReducer;