export const updateProjects = (projects) => {
	return {
		projects,
		type: 'UPDATE_PROJECTS'
	}
};

export const updateSelectedProject = (selectedProject) => {
	return {
		selectedProject,
		type: 'UPDATE_SELECTED_PROJECT'
	}
};

export const updateCauses = (causes) => {
	return {
		causes,
		type: 'UPDATE_CAUSES'
	}
};

export const updateLocation = (location) => {
	return {
		location,
		type: 'UPDATE_LOCATION'
	}
};