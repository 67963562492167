import React, { Component } from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import TimePicker from 'rc-time-picker';
import Switch from 'rc-switch';
import swal from 'sweetalert2';
import moment from 'moment';
import { ToastContainer, toast } from "react-toastify";

import 'sweetalert2/dist/sweetalert2.min.css';
import { Check } from 'react-feather';
import configs from '../../../../../config/urlConfigs.js';

const readCookie = require("../../../../../cookie.js").readCookie;
export default class SingleOpportunityEmployeeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      friendsList: [],
      page: 0,
      attendanceValidation: false,
      toggleValue: props.hasOwnProperty('participants') ? (props.participants === undefined ? true : props.participants.participated) : (true),
      start_time: props.hasOwnProperty('participants') ? (props.participants === undefined ? props.selectedOpportunityData.startTime : props.participants.startTime) : (props.selectedOpportunityData.startTime),
      end_time: props.hasOwnProperty('participants') ? (props.participants === undefined ? props.selectedOpportunityData.endTime : props.participants.endTime) : (props.selectedOpportunityData.endTime),
      experience: props.hasOwnProperty('participants') ? (props.participants === undefined ? "" : props.participants.experience) : (""),
      reason: props.hasOwnProperty('participants') ? (props.participants === undefined ? "" : props.participants.reason) : (""),
      error: [],
      questionSelectedOption1: props.hasOwnProperty('participants') ? (props.participants === undefined ? null : props.participants.questionSelectedOption1) : (null),
      questionSelectedOption2: props.hasOwnProperty('participants') ? (props.participants === undefined ? null : props.participants.questionSelectedOption2) : (null),
      questionSelectedOption3: props.hasOwnProperty('participants') ? (props.participants === undefined ? null : props.participants.questionSelectedOption3) : (null),
    }
    this.handleChange = this.handleChange.bind(this);
    this.onOptionChange = this.onOptionChange.bind(this);
    this.onOptionChange2 = this.onOptionChange2.bind(this);
    this.onOptionChange3 = this.onOptionChange3.bind(this);
  }


  componentDidMount() {
    this.fetchFriendsList()
  }


  onChange(name, required, value) {
    let commentRegex = new RegExp(`^([\.a-zA-Z0-9,!?:;@#$~' ]*$)|(^$)`);
    // let commentRegex = new RegExp(`^[\.a-zA-Z0-9,!?:;@#$~' ]*$`);
    if (name === 'toggleValue') {
      this.setState({ toggleValue: !this.state.toggleValue });
    } else {
      var error = this.state.error;
      var index = error.indexOf(name);
      if (name === 'start_time' || name === 'end_time') {
        if (required && value !== '' && index > -1) error.splice(index, 1);
        this.setState({ [name]: moment(value).format('HH:mm'), error });
      }
      else {
      //   if ((commentRegex.test(value.target.value) ===false && false) && name === "experience" && required && value.target.value !== '' && index > -1) {error.splice(index, 1);}
      //   else if (name === "experience" && commentRegex.test(value.target.value) === true || true){
      //                    this.setState({ [name]: value.target.value, error });
      if(name === "experience")  { this.setState({ [name]: value.target.value, error });
        }
      //     }
      }
    }
  }

  onOptionChange(e) {
    this.setState({ questionSelectedOption1: e.target.value })
  }

  onOptionChange2(e) {
    this.setState({ questionSelectedOption2: e.target.value });

  }

  onOptionChange3(e) {
    this.setState({ questionSelectedOption3: e.target.value });

  }

  hours(start_time, end_time) {
    var endTime = moment(end_time, 'h:mm');
    var startTime = moment(start_time, 'h:mm');
    var duration = moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) % 60;
    return hours + ' hour and ' + minutes + ' minutes';
  }

  handleChange(e) {
    this.setState({ reason: e.target.value });
    this.onChange.bind(this, this.state.reason, true)
  }


  declare() {
    var that = this;
    let data = {}
    // data['attendanceValidation'] = this.state.attendanceValidation;
    data['voId'] = this.props.opportunityId;
    data['participated'] = this.state.toggleValue;
    if (this.state.toggleValue) {
      data['startTime'] = this.state.start_time;
      data['endTime'] = this.state.end_time;
      data['experience'] = this.state.experience;
      data['questionSelectedOption1'] = this.state.questionSelectedOption1;
      data['questionSelectedOption2'] = this.state.questionSelectedOption2;
      data['questionSelectedOption3'] = this.state.questionSelectedOption3;
      data['friendsAttended'] = this.state.friendsList;
    } else {
      data['reason'] = this.state.reason;
    }
    if ((this.props.hasOwnProperty('participantsData') || this.props.hasOwnProperty('participants'))) {

      let error = [];
      if (this.state.toggleValue) {
        if (!this.state.start_time || this.state.start_time === "Invalid date") error.push('start_time');
        if (!this.state.end_time || this.state.end_time === "Invalid date") error.push('end_time');
        // if (!this.state.experience) error.push('experience');
        if (!this.state.questionSelectedOption1 || !this.state.questionSelectedOption2 || !this.state.questionSelectedOption3) {
          error.push('option');
        }
        if (this.state.start_time && this.state.end_time) {
          let start_time_to_check = moment().hour(this.state.start_time.split(":")[0]).minute
            (this.state.start_time.split(":")[1]).toDate(), end_time_to_check = moment().hour(this.state.end_time.split(":")[0]).minute
              (this.state.end_time.split(":")[1]).toDate();
          if (start_time_to_check > end_time_to_check) {
            error.push('start_time_error')
          }
        }
      } else {
        if (!this.state.reason) error.push("reason");
      }
      this.setState({ error });
      if ((error.length === 0)) {
        let friendsAttended = 0 ;
              if(this.state.friendsList.length > 0 ){
                this.state.friendsList.map((friend)=>{
                      if(friend.attended){
                        friendsAttended++;
                      }
                })
              }

        fetch(
          configs.BACKEND_API_URL + '/ee/volunteer-opportunity/attendee-declaration',
          {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              'Auth': JSON.parse(readCookie('access_token')).access_token
            },
            body: JSON.stringify(data)
          }).then(function (response) {
            return response.json();
          }).then(function (data) {

            if (data.status === 'ok' && this.state.toggleValue) {
              
              if(this.state.friendsList.length > 0 && friendsAttended > 0 ){
                swal({title : 'Awesome!',
                html : `<p>Thank you for spending your precious  ${this.hours(this.state.start_time || this.props.participants.
                 selectedOpportunity.attendeeDeclaration['startTime'], this.state.end_time || this.props.participants.
                 selectedOpportunity.attendeeDeclaration['endTime'])} ${" "}  towards voluntary contribution to the society</p>`,
                 showCancelButton: true,
                 cancelButtonColor: configs.THEME_COLOR,
                 confirmButtonText : `<p className="btn-contribute-photo btn-primary btn-declare-attendance" > Upload event photos</p>`,
                 cancelButtonText: `<p  className="btn-contribute-photo btn-primary btn-declare-attendance" >I"ll do later</p>`,
               }).then(
               function() {
                 window.location.href = `/my-volunteer-history/${ that.props.selectedOpportunityData.customUrl}#gallery`;
                 window.location.reload();
                 that.props.hideDeclareModal()
               }, function(dismiss) {
                 if (dismiss === 'cancel') {
                   // console.log("dismiss",dismiss);
                   that.props.hideDeclareModal();
                   window.location.reload();
                 }
               });
              }
              else {
                swal({title : 'Awesome!',
                html : `<p>Thank you for spending your precious  ${this.hours(this.state.start_time || this.props.participants.
                 selectedOpportunity.attendeeDeclaration['startTime'], this.state.end_time || this.props.participants.
                 selectedOpportunity.attendeeDeclaration['endTime'])} ${" "}  towards voluntary contribution to the society</p>
                 <note> You have not marked the attendance of your family/friends/guests. If they attended the event, kindly check the boxes against their names.</note>`,
                 showCancelButton: true,
                 cancelButtonColor: configs.THEME_COLOR,
                 confirmButtonText : `<p className="btn-contribute-photo btn-primary btn-declare-attendance" > Upload event photos</p>`,
                 cancelButtonText: `<p  className="btn-contribute-photo btn-primary btn-declare-attendance" >I"ll do later</p>`,
               }).then(
               function() {
                 window.location.href = `/my-volunteer-history/${that.props.selectedOpportunityData.customUrl}#gallery`;
                 window.location.reload();
                 that.props.hideDeclareModal()
               }, function(dismiss) {
                 if (dismiss === 'cancel') {
                   // console.log("dismiss",dismiss);
                   that.props.hideDeclareModal();
                   window.location.reload();
                 }
               });
              }
            }
            else if (data.status == 'ok' && !this.state.toggleValue) {
              swal(
                "Thank you ",
                "Thank you for letting us know"
              )
              this.props.hideDeclareModal()
              window.location.reload();
            }
          }.bind(this));
      }
    }

    else {
      let error = [];
      if (this.state.toggleValue) {
        if (!this.state.start_time) error.push('start_time');
        if (!this.state.end_time) error.push('end_time');
        // if (!this.state.experience) error.push('experience');
        if (!this.state.questionSelectedOption1 || !this.state.questionSelectedOption2 || !this.state.questionSelectedOption3) {
          error.push('option');
        }
        if (this.state.start_time && this.state.end_time) {
          let start_time_to_check = moment().hour(this.state.start_time.split(":")[0]).minute
            (this.state.start_time.split(":")[1]).toDate(), end_time_to_check = moment().hour(this.state.end_time.split(":")[0]).minute
              (this.state.end_time.split(":")[1]).toDate();
          if (start_time_to_check > end_time_to_check) {
            error.push('start_time_error')
          }
        }
      } else {
        if (!this.state.reason) error.push("reason");
      }
      this.setState({ error });
      if (((error.length === 0 && this.props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')))) {
        const attendeedata = this.props.participants.selectedOpportunity.attendees.filter((data) => data.id === JSON.parse
          (readCookie('userData')).id)
        if (attendeedata[0]['declared'] != "" && attendeedata[0]['updatedParticipatedAttendence'] === "") {

          fetch(
            configs.BACKEND_API_URL + '/ee/volunteer-opportunity/attendee-declaration',
            {
              method: "PUT",
              headers: {
                'Content-Type': 'application/json',
                'Auth': JSON.parse(readCookie('access_token')).access_token
              },
              body: JSON.stringify(data),
            })
            .then(function (response) {
              return response.json();
            }).then(function (data) {

              if (data.status === 'ok' && this.state.toggleValue || this.props.participants.
                selectedOpportunity.attendeeDeclaration['participated']) {
                  swal({title : 'Awesome!',
                  html : `<p>Thank you for spending your precious  ${this.hours(this.state.start_time || this.props.participants.
                   selectedOpportunity.attendeeDeclaration['startTime'], this.state.end_time || this.props.participants.
                   selectedOpportunity.attendeeDeclaration['endTime'])} ${" "}  towards voluntary contribution to the society</p>`,
                   showCancelButton: true,
                   cancelButtonColor: configs.THEME_COLOR,
                   confirmButtonText : `<p className="btn-contribute-photo btn-primary btn-declare-attendance" > Upload event photos</p>`,
                   cancelButtonText: `<p  className="btn-contribute-photo btn-primary btn-declare-attendance" >I"ll do later</p>`,
                 }).then(
                 function() {
                   window.location.href = `/my-volunteer-history/${that.props.selectedOpportunityData.customUrl}#gallery`;
                   that.props.hideDeclareModal();
                   window.location.reload();
                 }, function(dismiss) {
                   if (dismiss === 'cancel') {
                     // console.log("dismiss",dismiss);
                     that.props.hideDeclareModal();
                     window.location.reload();
                   }
                 });
              } else if (data.status == 'ok' && (!this.state.toggleValue || !this.props.participants.
                selectedOpportunity.attendeeDeclaration['participated']) && (this.state.start_time === "")) {
                swal(
                  "Thank you ",
                  "Thank you for letting us know"
                )
                this.props.hideDeclareModal()
                window.location.reload();
              }
            }.bind(this));

        }
      }
    }
  }

  fetchFriendsList() {
    fetch(
      configs.BACKEND_API_URL + '/ee/friends-list/' + this.props.selectedOpportunity.id,
      {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Auth': JSON.parse(readCookie('access_token')).access_token
        },
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        data.friends.map((d,index)=>{
          if(d.hasOwnProperty('attended')){
            d['attended'] = d.attended;
            return d ;
          }else {
               d['attended'] = true;
               return d 
          }
         })
        this.setState({ friendsList: data.friends });
      }.bind(this))
  }

  handleFriendsAttended(index) {
    var friends = this.state.friendsList;
    if (friends[index].hasOwnProperty('attended')) {
      friends[index].attended = !friends[index].attended
    } else {
      friends[index]['attended'] = true
    }
    this.setState({ friendsList: friends })
  }
  
  handleFriendsNameChange(index,e){
    var friends = this.state.friendsList;
     friends[index].name = e.target.value
    this.setState({friendsList :friends}) 
  }


  render() {
    return (
      <Modal bsSize="lg" className="declare-modal" style={{ width: '100%' }} show={this.props.showDeclareModalVisibility} onHide={this.props.hideDeclareModal}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-header-custom">
            Declare Your Volunteer Hours
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: 500, overflowY: 'scroll', overflowX: 'hidden' }}>
            <Row>
              <Col md={12}>
                <form id="edit-org-details-modal-form"
                  // ref="vForm"
                >
                  <Col md={12}>
                    <div className="ee-recommend-switch-container">
                      <span className="ee-recommend-switch-left-text">I missed this opportunity</span>
                      <Switch onChange={this.onChange.bind(this, 'toggleValue')}
                        disabled={this.state.disabled} checked={this.state.toggleValue} />
                      <span className="ee-recommend-switch-right-text">I took part in this opportunity</span>
                    </div>
                  </Col>

                  {this.state.toggleValue ? <>
                    {this.props.enableParticipantsForTimeChange ? <Col md={12}>
                      <Col className="form-group" md={3} mdOffset={3}>
                        <label className="control-label is-imp">Start Time: </label>

                        <TimePicker defaultValue=
                          {this.state.start_time ? moment().hour(this.state.start_time.split(":")[0]).minute
                            (this.state.start_time.split(":")[1]) : ""}
                          className="form-control" showSecond={false}
                          onChange={this.onChange.bind(this, "start_time", true)} 
                          disabled={this.props.enableParticipantsForTimeChange ? false :true}
                          />
                        {this.state.error.indexOf('start_time')
                          > -1 ? (
                          <span className="red-color">Incorrect Time!</span>
                        ) : (null)}
                        {this.state.error.indexOf('start_time_error') > -1 ? (<span className="red-color">Incorrect Time!</span>) : (null)}
                      </Col>

                      <Col className="form-group" md={3}>
                        <label className="control-label is-imp">End Time: </label>

                        <TimePicker
                          defaultValue={this.state.end_time ? moment().hour(this.state.end_time.split(":")[0]).minute
                            (this.state.end_time.split(":")[1]) : ""}
                          className="form-control" showSecond={false} 
                          onChange={ this.onChange.bind(this, "end_time", true) } 
                          disabled={ this.props.enableParticipantsForTimeChange ?false : true }
                          />
                        {this.state.error.indexOf('end_time') > -1 ? (
                          <span className="red-color">Incorrect Time!</span>
                        ) : (null)}
                        {this.state.error.indexOf('end_time_error') > -1 ? (<span className="red-color">End time can't be smaller than start Time</span>) : (null)}
                      </Col>
                    </Col> : null}

                    <div style={{ marginBottom: 20 }}>
                      {this.state.friendsList.length > 0 && <><Row>
                        <Col md={6} sm={6} mdOffset={3}>
                          <label className="control-label" style={{ fontWeight: 'bold', textDecoration: 'underline' }} htmlFor='friendsName' >Names will appear on the certificate as above. Correct the names if necessary.</label>
                        </Col>
                      </Row><Row><Col md={6} sm={6} mdOffset={3}><note>Names will appear on the certificate as above. Correct the names if necessary.</note></Col></Row></>}
                      {this.state.friendsList.map((friend, index) => <Row>
                        <Col md={5} sm={5} xs={8} mdOffset={3}>
                          <div className="form-group pb0 mb0" >
                            <input type="text" className="form-control" style={{ margin: 0 }}
                            required 
                            value={this.state.friendsList[index].name} 
                            onChange={this.handleFriendsNameChange.bind(this,index)}
                            />
                          </div>
                        </Col>
                        <Col md={1} sm={1} xs={1} style={{ padding: 0 }}>
                          <div class="form-group form-check checkbox-row" style={{ margin: 0 }}>
                            <a className={friend.attended ? 'checkbox-active mt10' : 'checkbox-inactive mt10'} onClick={() => this.handleFriendsAttended(index)}>
                              <Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
                            </a>
                          </div>
                        </Col>
                      </Row>)}
                    </div>
                    
                    <Col className='declare-modal-volunteer-work'>
                      <p className='ee-page-title' style={{ color: 'black', fontWeight: 'normal' }}>Tell Us About Your Volunteer Work</p>
                    </Col>
                    <Col md={12}>
                      <Row sm={'auto'}>
                        <Col md={5} lg={5}></Col>
                        <Col md={7}>
                          <Row>
                            <Col md={7}>
                              <Row>
                                <Col md={4} className="declare-modal-question declare-modal-question-1">Very much</Col>
                                <Col md={4} className="declare-modal-question declare-modal-question-1">Somewhat</Col>
                                <Col md={4} className="declare-modal-question declare-modal-question-1">Neutral</Col>
                              </Row>
                            </Col>
                            <Col md={5}>
                              <Row>
                                <Col md={6} className="declare-modal-question declare-modal-question-1">Not much</Col >
                                <Col md={6} className="declare-modal-question declare-modal-question-1">Not at all</Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row sm={'auto'}>
                        <Col md={5} lg={5} className='control-label is-imp declare-modal-question'>
                          How much of an impact do you feel your volunteer work had?</Col>
                        <Col md={7}>
                          <form onChange={this.onOptionChange}>
                            <Row>
                              <Col md={7}>
                                <Row>
                                  <Col md={4} className="declare-modal-question">
                                    <div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                      <input type="radio" className='declare-modal-radio'

                                        aria-label="..."
                                        name="radio" value={5}
                                        checked={this.state.questionSelectedOption1 === "5"}
                                      /><div  className="mobile-view-option-name">Very much</div></div>
                                  </Col>
                                  <Col md={4} className="declare-modal-question">
                                    <div className='declare-modal-radio-div declare-modal-radio-div-mobile-view' >
                                    <input type="radio" className='declare-modal-radio' aria-label="..."
                                      name="radio" value={4}
                                      checked={this.state.questionSelectedOption1 === "4"} />
                                      <div  className="mobile-view-option-name" >Somewhat</div>
                                  </div></Col>
                                  <Col md={4} className="declare-modal-question">
                                    <div className='declare-modal-radio-div declare-modal-radio-div-mobile-view' >
                                    <input type="radio" className='declare-modal-radio'

                                      aria-label="..."
                                      name="radio" value={3}
                                      checked={this.state.questionSelectedOption1 === "3"} />
                                      <div  className="mobile-view-option-name">Neutral</div>
                                      </div></Col>
                                </Row>
                              </Col>
                              <Col md={5}>
                                <Row>
                                  <Col md={6} className="declare-modal-question">
                                    <div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                    <input type="radio" className='declare-modal-radio'aria-label="..."
                                      name="radio" value={2} checked={this.state.questionSelectedOption1 === "2"}
                                    />
                                    <div  className="mobile-view-option-name">Not much</div>
                                    </div>
                                    </Col>
                                  <Col md={6} className="declare-modal-question">
                                    <div className='declare-modal-radio-div declare-modal-radio-div-mobile-view' >
                                    <input type="radio" className='declare-modal-radio'
                                      aria-label="..."
                                      name="radio" value={1}
                                      checked={this.state.questionSelectedOption1 === "1"} />
                                    <div  className="mobile-view-option-name">Not at all</div>
                                    </div></Col>
                                </Row>
                              </Col>
                            </Row>
                          </form>
                        </Col>

                      </Row>
                      <Row sm={'auto'}>
                        <Col md={5} lg={5} className='control-label is-imp declare-modal-question'>   How satisfied are you with your volunteer experience?</Col>
                        <Col md={7}>
                          <form onChange={this.onOptionChange2}>
                            <Row>
                              <Col md={7}>
                                <Row>
                                  <Col md={4} className="declare-modal-question">
                                    <div className='declare-modal-radio-div declare-modal-radio-div-mobile-view' >
                                      <input type="radio" className='declare-modal-radio'

                                        aria-label="..."
                                        name="radio" value={5}
                                        checked={this.state.questionSelectedOption2 === "5"}
                                      /><div  className="mobile-view-option-name">Very much</div></div>
                                  </Col>
                                  <Col md={4} className="declare-modal-question">
                                    <div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                    <input type="radio" className='declare-modal-radio' aria-label="..."
                                      name="radio" value={4}
                                      checked={this.state.questionSelectedOption2 === "4"}
                                    /><div  className="mobile-view-option-name" >Somewhat</div>
                                    </div>
                                    </Col>
                                  <Col md={4} className="declare-modal-question">
                                    <div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                    <input type="radio" className='declare-modal-radio'

                                      aria-label="..."
                                      name="radio" value={3}
                                      checked={this.state.questionSelectedOption2 === "3"}
                                    /><div  className="mobile-view-option-name">Neutral</div>
                                    </div></Col>
                                </Row>
                              </Col>
                              <Col md={5}>
                                <Row>
                                  <Col md={6} className="declare-modal-question">
                                    <div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                    <input type="radio" className='declare-modal-radio'

                                      aria-label="..."
                                      name="radio" value={2}
                                      checked={this.state.questionSelectedOption2 === "2"}
                                    />
                                     <div  className="mobile-view-option-name">Not much</div>
                                    </div></Col >
                                  <Col md={6} className="declare-modal-question">
                                    <div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                    <input type="radio" className='declare-modal-radio'

                                      aria-label="..."
                                      name="radio" value={1}
                                      checked={this.state.questionSelectedOption2 === "1"}
                                    /> <div  className="mobile-view-option-name">Not at all</div>
                                    </div></Col>
                                </Row>
                              </Col>
                            </Row>
                          </form>
                        </Col>
                      </Row>
                      <Row sm={'auto'}>
                        <Col md={5} lg={5} className='control-label is-imp declare-modal-question' >  How likely are you to participate in a similar event again?</Col>
                        <Col md={7}>
                          <form onChange={this.onOptionChange3}>
                            <Row>
                              <Col md={7}>
                                <Row>
                                  <Col md={4} className="declare-modal-question">
                                    <div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                      <input type="radio"

                                        className='declare-modal-radio'
                                        aria-label="..."
                                        name="radio" value={5}
                                        checked={this.state.questionSelectedOption3 === "5"}
                                      /><div  className="mobile-view-option-name">Very much</div>
                                      </div>
                                  </Col>
                                  <Col md={4} className="declare-modal-question">
                                    <div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                    <input type="radio" className='declare-modal-radio' aria-label="..."
                                      name="radio" value={4} checked={this.state.questionSelectedOption3 === "4"}
                                    />
                                    <div  className="mobile-view-option-name" >Somewhat</div>
                                    </div>
                                    </Col>
                                  <Col md={4} className="declare-modal-question">
                                    <div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                    <input type="radio" className='declare-modal-radio' aria-label="..." name="radio" value={3}
                                      checked={this.state.questionSelectedOption3 === "3"}
                                    /><div  className="mobile-view-option-name">Neutral</div>
                                    </div></Col>
                                </Row>
                              </Col>
                              <Col md={5}>
                                <Row>
                                  <Col md={6} className="declare-modal-question">
                                    <div className='declare-modal-radio-div declare-modal-radio-div-mobile-view' >
                                    <input type="radio" className='declare-modal-radio'

                                      aria-label="..."
                                      name="radio" value={2}
                                      checked={this.state.questionSelectedOption3 === "2"}
                                    />  <div  className="mobile-view-option-name">Not much</div>
                                    </div>
                                    </Col >
                                  <Col md={6} className="declare-modal-question">
                                    <div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                    <input type="radio" className='declare-modal-radio'
                                      aria-label="..."
                                      name="radio" value={1}
                                      checked={this.state.questionSelectedOption3 === "1"}
                                    />
                                    <div  className="mobile-view-option-name">Not at all</div>
                                    </div></Col>
                                </Row>
                              </Col>
                            </Row>
                          </form>
                        </Col></Row>
                    </Col>
                    {this.state.error.indexOf('option')
                      > -1 ? (
                      <span className="red-color" style={{ marginTop: 25, marginLeft: 14 }}>Make sure you've filled valid feedback</span>
                    ) : (null)}

                    <Col className="form-group" md={12}>
                      <label className="control-label  declare-modal-question">
                        {/* Is there anything else you'd like for us to know about your volunteer experience? */}
                        Let us know what you thought of the activity and your volunteer experience
                        <br /></label>
                      <textarea placeholder="You may want to highlight your favorite parts of the volunteering experience, the difference you created, what could be improved, etc."
                        className="form-control" id="experience" defaultValue={this.state.experience} onChange={this.onChange.bind(this, "experience", true)} required></textarea>
                      {this.state.error.indexOf('experience') > -1 ? (
                        // <span className="red-color">Incorrect Experience!</span>
                        <span className="red-color">Only letters ,numbers and special characters are allowed.</span>
                      ) : (null)}
                    </Col>
                  </>
                    :
                    <Col md={12}>
                      <Col className="form-group" md={12}>
                        <Row>
                          <Col md={7}>
                            <label className="control-label is-imp">
                              Oh no! Please select the reason for missing this opportunity
                            </label>
                          </Col>
                          <Col md={3} className="selected-option">
                            <select id="type" defaultValue={this.state.reason} onChange={this.handleChange}>
                              <option value="">Choose one</option>
                              <option value="Got engaged with another official work">Got engaged with another official work </option>
                              <option value="Got engaged with another unofficial work">Got engaged with another unofficial work</option>
                              <option value="Had an emergency situation">Had an emergency situation</option>
                              <option value="Forgot about the event">Forgot about the event</option>
                              <option value="Opted for another event">Opted for another event</option>
                              <option value="Another Reason">Another Reason</option>
                            </select>
                            {this.state.error.indexOf('reason') > -1 ? (<span className="red-color">Incorrect Reason!</span>) : (null)}
                          </Col>
                        </Row>
                      </Col>
                    </Col>}
                </form>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="footer-center">
          {/* <Button className="btn btn-primary" onClick={this.declare.bind(this)}>
            Submit Your Declaration
          </Button> */}
           {this.state.toggleValue ? ( this.state.experience && this.state.questionSelectedOption1 &&
       this.state.questionSelectedOption2 && this.state.questionSelectedOption3 ?      <Button className="btn btn-primary" onClick={this.declare.bind(this)}>
       Submit Your Declaration
     </Button> :     <Button className="btn btn-primary" disabled onClick={this.declare.bind(this)}>
            Submit Your Declaration
          </Button>     ) : (this.state.reason ? <Button className="btn btn-primary" onClick={this.declare.bind(this)}>
       Submit Your Declaration
     </Button> :     <Button className="btn btn-primary" disabled onClick={this.declare.bind(this)}>
            Submit Your Declaration
          </Button> ) }
          <Button className="btn btn-outline-primary" onClick={this.props.hideDeclareModal.bind(this)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
