import React,{ Component } from 'react';
import {Col, Row} from 'react-bootstrap';
import PlacesAutocomplete from 'react-places-autocomplete';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as action from '../../../actions/EEPreferencesAction';

import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import configs from '../../../config/urlConfigs';

const readCookie = require('../../../cookie.js').readCookie;

class Preferences extends Component {
	constructor() {
		super();
		this.state = {
			preferableCity: '',
			preferableCityError: '',
			payrollTotalPledged: 0,
			pledgeStartsFromMonth: '',
			pledgeStartsFromYear: '',
			hasPledged: false,
			enableNotifications: true,
		};

		this.onPreferableCityChange = this.onPreferableCityChange.bind(this);
		this.onPayrollTotalPledgedChange = this.onPayrollTotalPledgedChange.bind(this);
		this.onPledgeMonthChange = this.onPledgeMonthChange.bind(this);
		this.onPledgeYearChange = this.onPledgeYearChange.bind(this);
		this.onPledgeCheckChange = this.onPledgeCheckChange.bind(this);
		this.onEnableNotificationsChange = this.onEnableNotificationsChange.bind(this);
		this.onPreferenceSubmit = this.onPreferenceSubmit.bind(this);
	}

	componentDidMount() {
		fetch(configs.BACKEND_API_URL + '/get-user-preference', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			if(Object.keys(data.data).length) {
				this.props.actions.updatePreferableCity(data.data.preferableCity);
				this.props.actions.updatePayroll(data.data.payrollTotalPledged);
				let month =  moment(data.data.pledgeStartsFromMonth, 'M').format('MMMM');
				this.props.actions.updatePledgeMonth(month);
				this.props.actions.updatePledgeYear(data.data.pledgeStartsFromYear);
				this.props.actions.updateHasPledged(data.data.hasPledged);
				this.props.actions.updateEnableNotifications(data.data.enableNotifications);
				this.setState({
					preferableCity: this.props.preferableCity,
					payrollTotalPledged: this.props.payrollTotalPledged,
					pledgeStartsFromMonth: this.props.pledgeStartsFromMonth,
					pledgeStartsFromYear: this.props.pledgeStartsFromYear,
					hasPledged: this.props.hasPledged,
					enableNotifications: this.props.enableNotifications
				})
			} else {
				this.setState({
					preferableCity: '',
					preferableCityError: '',
					payrollTotalPledged: 0,
					pledgeStartsFromMonth: '',
					pledgeStartsFromYear: '',
					hasPledged: '',
					enableNotifications: false
				})
			}
		}.bind(this));
	}

	onPreferableCityChange(preferableCity) {
		this.setState({
			preferableCity
		})
	}

	onPayrollTotalPledgedChange(e) {
		this.setState({
			payrollTotalPledged: e.target.value
		})
	}

	onPledgeMonthChange(e) {
		this.setState({
			pledgeStartsFromMonth: e.target.value
		});
	}

	onPledgeYearChange(e) {
		this.setState({
			pledgeStartsFromYear: e.target.value
		})
	}

	onPledgeCheckChange(e) {
		this.setState({
			hasPledged: e.target.checked
		})
	}

	onEnableNotificationsChange(e) {
		this.setState({
			enableNotifications: e.target.checked
		})
	}

	onPreferenceSubmit(){
		let data = {};
		data['preferableCity'] = this.state.preferableCity;
		data['payrollTotalPledged'] = this.state.payrollTotalPledged;
		data['pledgeStartsFromMonth'] = moment().month(this.state.pledgeStartsFromMonth).format("M");
		data['pledgeStartsFromYear'] = this.state.pledgeStartsFromYear;
		data['hasPledged'] = this.state.hasPledged;
		data['enableNotifications'] = this.state.enableNotifications;

		fetch(configs.BACKEND_API_URL + '/save-user-preference', {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify(data)
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			if(data.status === 'ok') {
				swal(
					'Awesome!',
					'Successfully saved your preferences.',
					'success'
				);
			}
		}.bind(this))
	}

	render() {
		const inputProps = {
			value: this.state.preferableCity,
			onChange: this.onPreferableCityChange,
		};

		const options = {
			types: ['(cities)'],
		};

		const cssClasses = {
			input: 'form-control' + this.state.preferableCityError,
			autocompleteContainer: 'my-autocomplete-container'
		};

		let year = moment().year();

		const years = [];
		for(let i = 0; i < 5; i++) {
			years.push(year + i);
		}

		const months = [];
		for (let i = 0; i < 12; i++) {
			months.push(moment().month(i).format('MMMM'));
		}

		return (
			<div className="ee-page-container">
				<Row>
					<Col md={12} className="text-center">
						<h2 className="ee-page-title">My Payroll Pledge</h2>
					</Col>
					<Col md={8} mdOffset={2} className="margin-col-50">
						<form name="groupForm">
							<div className="form-group">
								<label className="control-label is-imp">Select Your Location:</label>
								<PlacesAutocomplete inputProps={inputProps} classNames={cssClasses} options={options}/>
							</div>

							<div className="form-group">
								<input type="checkbox" name="pledge" onChange={this.onPledgeCheckChange} checked={this.state.hasPledged}/>
								<label className="checkbox-label">
									I pledge to contribute INR
									<input type="number" className="form-control-sm" onChange={this.onPayrollTotalPledgedChange} value={this.state.payrollTotalPledged} />
									every month from my payroll towards development oriented projects starting from
									<select id="month" className="form-control-sm" onChange={this.onPledgeMonthChange} value={this.state.pledgeStartsFromMonth}>
										{months.map(function(month, index) {
											return (
												<option key={index} value={month}>{month}</option>
											)
										})}
									</select>
									of
									<select id="year" className="form-control-sm" onChange={this.onPledgeYearChange} value={this.state.pledgeStartsFromYear}>
										{years.map(function(year, index) {
											return (
												<option key={index} value={year}>{year}</option>
											)
										})}
									</select>
									I understand that if I miss selecting my favourite project any month, the platform can allocate the sum to any relevant project. I also understand that I can opt out of Payroll Giving by un-checking this declaration before 23rd day of the month for it come to effect from the next month
								</label>
							</div>

							<div className="form-group">
								<input type="checkbox" name="toggle-notif" onChange={this.onEnableNotificationsChange} checked={this.state.enableNotifications}/>
								<label className="checkbox-label" htmlFor='toggle-notif'>
									Receive Notifications
								</label>
							</div>

							<div className="form-group">
								<button className="btn btn-center btn-sq btn-le" type="button" onClick={this.onPreferenceSubmit}>
									Save
								</button>
							</div>
						</form>
					</Col>
				</Row>
			</div>
		);
	}
}


Preferences.propTypes = {
	preferableCity: PropTypes.string,
	payrollTotalPledged: PropTypes.number,
	pledgeStartsFromMonth: PropTypes.string,
	pledgeStartsFromYear: PropTypes.string,
	hasPledged: PropTypes.bool,
	enableNotifications: PropTypes.bool,
};

function mapStateToProps(state) {
	return {
		preferableCity: state.EEPreferences.preferableCity,
		payrollTotalPledged: state.EEPreferences.payrollTotalPledged,
		pledgeStartsFromMonth: state.EEPreferences.pledgeStartsFromMonth,
		pledgeStartsFromYear: state.EEPreferences.pledgeStartsFromYear,
		hasPledged: state.EEPreferences.hasPledged,
		enableNotifications: state.EEPreferences.enableNotifications,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);