import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import Tour from 'reactour';

import * as action from '../../../../actions/EEVolunteerHistoryAction';

import VolunteerHistoryList from './VolunteerHistoryList';
import VolunteerHistoryFilter from './VolunteerHistoryFilter';
import RoleContext from '../../../../contexts/RoleContext';
import configs from '../../../../config/urlConfigs';

const readCookie = require('../../../../cookie.js').readCookie;

class VolunteerHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isTourOpen: false,
			totalGuestHours : '00:00'
		};

		this.steps = [];
	}
	componentDidMount() {
		fetch(configs.BACKEND_API_URL + '/get-user-stats', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			// console.log("data:",data);

			this.props.actions.updateCountOfVos(data.countOfVos);
			this.props.actions.updateCountOfCompletedVos(data.countOfCompletedVos);
			this.props.actions.updateTotalHoursClocked(data.totalHoursClocked);
			if(data){
				fetch(configs.BACKEND_API_URL + "/ee/volunteer-opportunity-history", {
					method: "GET",
					credentials: "include",
					headers: {
					  Auth: JSON.parse(readCookie("access_token")).access_token,
					},
				  })
					.then(function (response) {
					  return response.json();
					})
					.then(
					  function (data) {
					
						this.setState({totalGuestHours :data.totalGuestHours});
					  }.bind(this)
					);
			}
		
			// this.setState({totalGuestHours:data.totalGuestHours});
		}.bind(this));

		let status = '';
		if (this.props.location.search && this.props.location.search.match('status=') !== null) {
			let searchQuery = this.props.location.search.split('status=')[1];
			if (searchQuery === 'upcoming') status = '?status=Live';
			else if (searchQuery === 'completed') status = '?status=Completed';
		}

		fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity-history' + status, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			if (data.data) {
				this.steps.push({
					selector: '#volunteer-history-filters-container',
					content: 'Apply your preferred critera and click here to see the filtered opportunities.'
				});
				const readyForDecalaration = (this.props.history || []).filter(hist => hist.status === 'Completed' && !hist.hasOwnProperty('attendeeDeclaration'));
				if (readyForDecalaration.length) {
					this.steps.push({
						selector: '.btn-declare-attendance',
						content: 'Click here to declare your attendance for the opportunity you took part in.'
					})
				}
				if (this.steps.length) {
					// this.setState({ isTourOpen: true });
				}
			}
			this.props.actions.updateHistory(data.data);
			this.props.actions.updateUpcomingCount(data.upcomingCount);
			this.props.actions.updateHoursClocked(data.hoursClocked);
			this.props.actions.updateCompletedCount(data.completedCount);
			let descriptions = [];
			for (let i = 0; i < data.data.length; i++) {
				descriptions.push(data.data[i].description)
			}
			this.props.actions.updateProjectDescription(descriptions);
		}.bind(this));

		
	}

	handleClick(data) {
		if (data === 'live-vo') window.location = '/my-volunteer-history?status=upcoming';
		else if (data === 'vo-hist') window.location = '/my-volunteer-history?status=completed';
	}

	render() {
		return (
			<RoleContext.Consumer>
				{(role) => <div className="ee-page-container volunteer-history-page">
				<Row>
					<Col md={12} className="text-center volunteering-opportunities-header">
						<h2 className="ee-page-title">My Opportunities</h2>
						<p className='volunteering-opportunities-tag-line'></p>
					</Col>
					<Tour
						accentColor="var(--themeColor)"
						steps={this.steps}
						badgeContent={(curr, tot) => `${curr} of ${tot}`}
						closeWithMask
						isOpen={this.state.isTourOpen}
						onRequestClose={() => { this.setState({ isTourOpen: false }) }} />
					<Col md={4}>
						<div class="right-txt-wrp-volunteer-history" onClick={this.handleClick.bind(this, 'live-vo')}>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								{/* <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/hold.png" /> */}
								<img style={{ height: 40, margin: 10, color: 'grey',marginLeft: 40 }} src="/img/IBL/volunteer.png" />
								<div style={{ marginLeft: 10 }}>
									<p class="right-txt-wrp-title" style={{ marginTop: 10 }}>
										{this.props.upcomingCount}
									</p>
									<p className="dashboard-motivational-elements-2-p">Upcoming Projects</p>
								</div>
							</div>
							{/* <Row>
								<Col md={2} sm={3} xs={3}>
									<img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/hold.png" />
								</Col>
								<Col md={10} sm={9} xs={9}>
									<p class="right-txt-wrp-title" style={{ marginTop: 10 }}>
										{this.props.upcomingCount}
									</p>
									<p className="dashboard-motivational-elements-2-p">Upcoming Projects</p>
								</Col>
							</Row> */}
							<div class="row align-items-center">
							</div>
						</div>
						{/* <div className="single-stat recommendation mb10" onClick={this.handleClick.bind(this, 'live-vo')}>
							<img src="/img/icons/hold.png" />
							<div>
								<span>{this.props.upcomingCount}</span>
								<span>Upcoming Projects</span>
							</div>
						</div> */}
					</Col>

					<Col md={4}>
						<div class="right-txt-wrp-volunteer-history" onClick={this.handleClick.bind(this, 'vo-hist')}>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								{/* <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/trophy.png" /> */}
								<img style={{ height: 40, margin: 10, color: 'grey',marginLeft: 40 }} src="/img/IBL/trophy.png" />
								<div style={{ marginLeft: 10 }}>
									<p class="right-txt-wrp-title" style={{ marginTop: 10 }}>
										{this.props.completedCount}
									</p>
									<p className="dashboard-motivational-elements-2-p">Projects Completed</p>
								</div>
							</div>
							{/* <Row>
								<Col md={2} sm={3} xs={3}>
									<img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/trophy.png" />
								</Col>
								<Col md={10} sm={9} xs={9}>
									<p class="right-txt-wrp-title" style={{ marginTop: 10 }}>
										{this.props.completedCount}
									</p>
									<p className="dashboard-motivational-elements-2-p">Projects Completed</p>
								</Col>
							</Row> */}
							<div class="row align-items-center">
							</div>
						</div>
						{/* <div className="single-stat recommendation mb10" onClick={this.handleClick.bind(this, 'vo-hist')}>
							<img src="/img/icons/trophy.png" />
							<div>
								<span>{this.props.completedCount}</span>
								<span>Projects Completed</span>
							</div>
						</div> */}
					</Col>

					<Col md={4}>
						<div class="right-txt-wrp-padding" onClick={this.handleClick.bind(this, 'vo-hist')}>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
                      <img style={{ height: '65px', width: '75px', marginLeft: 13,marginTop :19, color: 'grey' }} src="/img/IBL/clock.png" />
                      {/* <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/clock.png" /> */}
                      <div style={{ marginLeft: 10 }}>
                        <p className="right-txt-wrp-title" style={{ marginTop: 10 }}>
                         { moment().hours(Number.parseInt(this.props.hoursClocked.toString())).
                          minutes((this.props.hoursClocked.toString()*60)%60).format("HH:mm").split(':')[0] + ' hours ' 
                          + moment().hours(Number.parseInt(this.props.hoursClocked.toString())).
                          minutes((this.props.hoursClocked.toString()*60)%60).format("HH:mm").split(':')[1] + ' minutes '}
                        </p>
                        <p className="dashboard-motivational-elements-2-p"> My Volunteering Hours</p>
                        <p className="right-txt-wrp-title" style={{ marginTop: 10 }}>
						{(this.state.totalGuestHours.toString().length < 2 ? ('0' + Number.parseInt(this.state.totalGuestHours) +
                         ' hours ' +  ('0' + Number.parseInt(this.state.totalGuestHours*60)%60).slice(-2) + ' minutes ' )
                         : ('' + Number.parseInt(this.state.totalGuestHours)) + ' hours '  + ('0' + Number.parseInt(this.state.totalGuestHours*60)%60).slice(-2) +' minutes ' )}
						  {/* {this.state.totalGuestHours ? this.state.totalGuestHours.split(':')[0] + ' hours ' + this.state.totalGuestHours.split(':')[1] + ' minutes': `00 hours 00 mins`} */}
                        </p>
                        <p className="dashboard-motivational-elements-2-p"> My Guest Volunteering Hours</p>
                      </div>
                    </div>
							{/* <Row>
								<Col md={2} sm={3} xs={3}>
									<img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/clock.png" />
								</Col>
								<Col md={10} sm={9} xs={9}>
									<p class="right-txt-wrp-title" style={{ marginTop: 10 }}>
										{this.props.hoursClocked}
									</p>
									<p className="dashboard-motivational-elements-2-p">Hours Clocked</p>
								</Col>
							</Row> */}
							<div class="row align-items-center">
							</div>
						</div>
						{/* 
						<div className="single-stat recommendation mb10" onClick={this.handleClick.bind(this, 'vo-hist')}>
							<img src="/img/icons/clock.png" />
							<div>
								<span>{this.props.hoursClocked}</span>
								<span>Hours Clocked</span>
							</div>
						</div> */}
					</Col>

				</Row>
				{this.props.history !== null && this.props.history !== undefined ? (
					<div>
						{/* <VolunteerHistoryFilter causes={this.props.causes} updateHistory={this.props.actions.updateHistory} status={this.props.match.params.status}
							updateLocation={this.props.actions.updateLocation} updateCauses={this.props.actions.updateCauses} /> */}
						<VolunteerHistoryList history={this.props.history} updateHistory={this.props.actions.updateHistory} projectDescription={this.props.projectDescription} />
					</div>
				) : (null)}
				</div>}
			</RoleContext.Consumer>
		);
	}
}


VolunteerHistory.propTypes = {
	countOfVos: PropTypes.number,
	countOfCompletedVos: PropTypes.number,
	totalHoursClocked: PropTypes.number,
	history: PropTypes.array,
	causes: PropTypes.array,
	completedCount: PropTypes.number,
	upcomingCount: PropTypes.number,
	hoursClocked: PropTypes.number,
	projectDescription: PropTypes.array
};

function mapStateToProps(state) {
	return {
		countOfVos: state.EEFeed.countOfVos,
		countOfCompletedVos: state.EEFeed.countOfCompletedVos,
		totalHoursClocked: state.EEFeed.totalHoursClocked,
		history: state.EEVolunteerHistory.history,
		causes: state.EEVolunteerHistory.causes,
		completedCount: state.EEVolunteerHistory.completedCount,
		upcomingCount: state.EEVolunteerHistory.upcomingCount,
		hoursClocked: state.EEVolunteerHistory.hoursClocked,
		projectDescription: state.EEVolunteerHistory.projectDescription

	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VolunteerHistory);