import React, { useEffect, useState } from 'react';
import './FAQs.css';

const FAQs = () => {
    useEffect(() => {
        const buttons = document.querySelectorAll('.faq-toggle');



        buttons.forEach((button) => {

            button.addEventListener('click', () => {
                button.parentNode.classList.toggle('active')
            })
        });
    }, [])

    const INITIAL_FAQObjs = [{
        class: 'faq-toggle', 
        question: 'What is employee volunteering?',
        answer: <><div>Volunteering is the commitment of time and skills for the benefit of society/communities and the environment, without expecting anything in return. Volunteering is undertaken freely and by choice and is generally unpaid and is done through an NGO and/or a social enterprise, whose mission is to improve some aspect of society, the environment or culture.</div><br/><div>Employee Volunteering is when the Bank creates such opportunities for employees to participate and Make a Difference in society beyond their job.</div></>
    }, {
        class: 'faq-toggle', 
        question: 'Why is the Bank doing Employee Volunteering and why should I participate?',
        answer: <><div>Please refer to the Bank’s Employee Volunteering Policy by clicking on this link: <a href="https://www.indusind.com/content/dam/indusind-corporate/Other/employee-volunteering-policy.pdf" target="_blank">https://www.indusind.com/content/dam/indusind-corporate/Other/employee-volunteering-policy.pdf</a></div></>
    }, {
        class: 'faq-toggle', 
        question: 'Is it necessary for my device to be connected to IBL’s network for me to be able to access this portal?',
        answer: <><div>No. The portal can be accessed from the internet, even if your device is not connected to IBL’s network. It can be accessed through the browser on your phone or computer. Just type <a href="https://volunteering.indusind.com" target="_blank">volunteering.indusind.com</a></div></>
    }, {
        class: 'faq-toggle', 
        question: 'How to explore or register for volunteering opportunities?',
        answer: <><div><strong>Option 1:</strong> From your dashboard click on [Show Me Projects]</div><br/><div><strong>Option 2:</strong> From the navigation bar on the left click on [Volunteer Opportunities] -&gt; [Discover Opportunities]</div></>
    }, {
        class: 'faq-toggle', 
        question: 'What is ‘My Opportunities’?',
        answer: <><div>Under ‘My Opportunities’ you will be able to view the opportunities you have applied for.</div></>
    },
    {
        class: 'faq-toggle', 
        question: 'How do family members register for an opportunity?',
        answer: <><div>Only active employees of IndusInd Bank can access this portal. If a volunteering opportunity indicates that family members can participate, while registering yourself, you will get the option to add family members.</div></>
    }, {
        class: 'faq-toggle', 
        question: 'Will my Family members and me receive certificates for participating in an activity?',
        answer: <><div>Yes. You will need to mark your attendance:</div><ul style={{listStyleType:"disc"}}><li>With the IBL Champion for the activity during the activity</li><li>On the portal after the activity</li></ul><div>Once the portal admin approves your attendance, volunteering hours for you and your family members will be credited on your dashboard and you will be able to generate and download e-certificates for yourselves and your family members.</div><div>To download the certificate, click on: [My Opportunities] -&gt; Name of Opportunity Attended -&gt; [View Certificates]</div></>
    }, {
        class: 'faq-toggle', 
        question: 'In case I face any issues or clarification, whom can I contact?',
        answer: <><div>You can write to <u>oswin.dsouza@indusind.com</u> with a CC to <u>sattvam@indusind.com</u></div></>
    },]
    
    const [FAQObjs, setFAQObjs] = useState(INITIAL_FAQObjs);
    return <div className="ee-page-container feed-page">
            <h1>Frequently Asked Questions</h1>
            <div className="faq-container">
            {FAQObjs.map((faqObj, index)=><div id={index} className="faq">
                <h3 className="faq-title single-project-title-name">
                    {faqObj.question}    
                    </h3>
                    <p className="faq-text">
                        {faqObj.answer}
                    </p>
                <button className="faq-toggle"
                    // onClick={() => { let faqCpy = FAQObjs; faqCpy[index]['class'] = faqCpy[index]['class'].includes('active') ? 'faq-toggle' : 'faq-toggle active'; console.log(faqCpy, 'faqCpy'); setFAQObjs(faqCpy) }}
                >
                    <i className="fa fa-angle-double-down"></i>
                        <i className="fa fa-angle-double-up"></i>
                    </button>
                </div>)}


                

            </div>

    
    </div>
}

export default FAQs;