import moment from 'moment';
import swal from 'sweetalert2';
import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import DisclaimerModal from "../../2-ImpactVolunteering/DiscoverOpportunities/SingleOpportunity/DisclaimerModal"
let partners = require('./../../EEPayrollGiving/payrollPartners.json');
let causes = { "Education": "Education", "Livelihoods": "Livelihoods", "Health": "Health", "Agriculture": "Agriculture" };
let states = { "Andhra Pradesh": "Andhra Pradesh", "Arunachal Pradesh": "Arunachal Pradesh", "Assam": "Assam", "Bihar": "Bihar", "Chhattisgarh": "Chhattisgarh", "Goa": "Goa", "Gujarat": "Gujarat", "Haryana": "Haryana", "Himachal Pradesh": "Himachal Pradesh", "Jammu and Kashmir": "Jammu and Kashmir", "Jharkhand": "Jharkhand", "Karnataka": "Karnataka", "Kerala": "Kerala", "Madhya Pradesh": "Madhya Pradesh", "Maharashtra": "Maharashtra", "Manipur": "Manipur", "Meghalaya": "Meghalaya", "Mizoram": "Mizoram", "Nagaland": "Nagaland", "Odisha": "Odisha", "Punjab": "Punjab", "Rajasthan": "Rajasthan", "Sikkim": "Sikkim", "Tamil Nadu": "Tamil Nadu", "Telangana": "Telangana", "Tripura": "Tripura", "Uttarakhand": "Uttarakhand", "Uttar Pradesh": "Uttar Pradesh", "West Bengal": "West Bengal", "Andaman and Nicobar Islands": "Andaman and Nicobar Islands", "Chandigarh": "Chandigarh", "Dadra and Nagar Haveli": "Dadra and Nagar Haveli", "Daman and Diu": "Daman and Diu", "Delhi": "Delhi", "Lakshadweep": "Lakshadweep", "Puducherry": "Puducherry" };

export default class EEAdminManageNGOs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ngos: null,
			approvedNGOs: {},
			targetLink:null,
			disclaimerModal:false,
		};
	}

	componentDidMount() {
		this.setState({ ngos: partners });
		let approvedNGOs = localStorage.getItem('approvedNGOs');
		if (approvedNGOs) {
			approvedNGOs = JSON.parse(approvedNGOs);
			this.setState({ approvedNGOs });
		}
	}
	showDisclaimerModal(url){
		let orgUrl = 'https://www.letsendorse.com/ngo/' + url
		this.setState({disclaimerModal :true});
		this.setState({targetLink:orgUrl});
	  }
	  hideDisclaimerModal(){
		this.setState({disclaimerModal:false})
	  }

	approveNgo = (ngo) => {
		let approvedNGOs = this.state.approvedNGOs;
		swal({
			type: "warning",
			title: "Are you sure you wish to proceed?",
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, Approve',
			text: "If you approve this, this organization will become available for contribution by your team members."
		}).then((result) => {
			if (result) {
				approvedNGOs[ngo.id] = { ...ngo, amount: 0 };
				localStorage.setItem('approvedNGOs', JSON.stringify(approvedNGOs));
				this.setState({ approvedNGOs });
			}
		});
	}

	dataFormat = (type, cell, row) => {
		if (type === "name") {
			if (cell) {
				if (row.customUrl){
				return ( <a onClick={this.showDisclaimerModal.bind(this,row.customUrl)}
				// href={"https://www.letsendorse.com/ngo/" + row.customUrl}
				//  target="_blank" 
				 title={cell}>{cell}</a>
				)} 
				else if (row.id){
				return <a onClick={this.showDisclaimerModal.bind(this,row.id)}
				// href={"https://www.letsendorse.com/ngo/" + row.id} 
				// target="_blank" 
				title={cell}>{cell}</a>;
				}	
				else return cell;
			} else return null;
		} else if (type === "80G") {
			if (cell) return "Yes";
			else return "No";
		} else if (type === "status") {
			if (this.state.approvedNGOs.hasOwnProperty(cell)) {
				return <button className="btn approved-btn">Approved</button>;
			} else {
				return <button className="btn pending-btn" onClick={this.approveNgo.bind(this, row)}>Pending</button>;
			}
		}
	}
	

	render() {
		const options = {
			sizePerPage: 20,
			sizePerPageList: [20, 50, 100]
		};
		return (
			<div className="ee-page-container manage-ngos-page">
				<ToastContainer position="bottom-center" autoClose={5000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
				<Row>
					<Col md={12} className="text-center">
						<p className='ee-page-title'>Manage NGOs</p>

						{this.state.ngos !== null ? (
							<BootstrapTable className="manage-ngos-table mt30" data={this.state.ngos} options={options} striped={true} hover={true}>
								<TableHeaderColumn dataField="id" isKey={true} hidden={true}>ID</TableHeaderColumn>
								<TableHeaderColumn dataField="name" width='250px' dataAlign="center" hidden={false} dataFormat={this.dataFormat.bind(this, 'name')}>Name</TableHeaderColumn>
								<TableHeaderColumn dataField="cause" width='120px' dataAlign="center" hidden={false} filter={{ type: 'SelectFilter', options: causes }}>Cause</TableHeaderColumn>
								<TableHeaderColumn dataField="state" width='180px' dataAlign="center" hidden={false} filter={{ type: 'SelectFilter', options: states }}>State</TableHeaderColumn>
								<TableHeaderColumn dataField="80G" width='100px' dataAlign="center" hidden={false} dataFormat={this.dataFormat.bind(this, '80G')}>Tax Exemption</TableHeaderColumn>
								<TableHeaderColumn dataField="id" width='100px' dataAlign="center" columnClassName="status-btn-container" hidden={false} dataFormat={this.dataFormat.bind(this, 'status')}>Status</TableHeaderColumn>
							</BootstrapTable>
						) : (null)}
					</Col>
				</Row>
				{ this.state.disclaimerModal ? (
              	<DisclaimerModal 
              	disclaimerModalVisibility = {this.state.disclaimerModal}
              	hideDisclaimerModal ={this.hideDisclaimerModal.bind(this)}
              targetLink ={this.state.targetLink}/>) :null}



			</div>
		);
	}
}
