import React, { Component } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import "sweetalert2/dist/sweetalert2.min.css";

export default class RecommendedOpportunityModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.selectedOpportunity.hasOwnProperty("description")) {
      return (
        <Modal
          show={this.props.showRecommendedOpportunityModal}
          onHide={this.props.closeModal}
          className="square-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Submitted By:{" "}
              {this.props.selectedOpportunity.recommendedBy.fullName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={10} mdOffset={1} className="margin-col-30">
                <div>
                  <b>
                    My comments:
                  </b>{" "}
                  {" " + this.props.selectedOpportunity.description}
                </div>
                <br />
                <div>
                  <b>
                    Email of contact person:
                    {/* <u>EMAIL OF POC:</u> */}
                  </b>{" "}
                  {" " + this.props.selectedOpportunity.emailOfPOC}
                </div>
                <br />
                <div>
                  <b>
                    Phone of contact person:
                    {/* <u>PHONE OF POC:</u> */}
                  </b>{" "}
                  {" " + this.props.selectedOpportunity.phoneOfPOC}
                </div>
                <br />
                <div>
                  <b>
                    Note:
                  </b>{" "}
                  {" " + this.props.selectedOpportunity.introductoryNote}
                </div>
                <br />
                <div>
                  <b>
                    Status:
                  </b>{" "}
                  {this.props.selectedOpportunity.status ? this.props.selectedOpportunity.status : "Yet to take action"}
                </div>
                <br />
                <div>
                  <b>
                    Comments by Admin:
                  </b>{" "}
                  {" " + this.props.selectedOpportunity.feedback && this.props.selectedOpportunity.feedback}
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {/*<Button onClick={this.submitApply} className="btn-sq btn-le">Reserve</Button>*/}
            <Button
              onClick={this.props.closeModal}
              className="btn btn-primary"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );
    } else {
      return null;
    }
  }
}
