import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import moment from 'moment';
import { ToastContainer, toast } from "react-toastify"; 
import configs from '../../../../../config/urlConfigs.js';

const readCookie = require('../../../../../cookie.js').readCookie;

export default class SingleDiscussionComment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			subCommentText: '',
		}
	}

	onShowReply(i) {
		let comment = this.props.comment;
		comment.showReply = !comment.showReply;
		this.props.updateSelectedCommentShowReply(comment);
	}
	error =(text)=> toast.error(text)
	onSubCommentTextChange(e) {
		let commentRegex = new RegExp(`^[\.a-zA-Z0-9,!?:;@#$~' ]*$`)
		if (commentRegex.test(e.target.value) === true || true){
				this.setState({ subCommentText: e.target.value });
			}
			else {
				this.error("Only letters, numbers and special characters are allowed")
			}
	}

	onSubCommentPost() {
		let data = {};
		data.text = this.state.subCommentText.trim();
		let http_reg = /(?:^|[^"'])(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim,
			www_reg = /(?:^|[^"'\/\/])(\bwww?\.[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;

		data.text = data.text.replace(http_reg, ' <a href="$1" target="_blank">$1</a>');
		data.text = data.text.replace(www_reg, ' <a href="http://$1" target="_blank">$1</a>');

		fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + this.props.volunteerOpportunityId + '/' + this.props.comment.id + '/reply-to', {
			method: "POST",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		}).then((data) => data.json())
		.then(function(data) {
			if(data.status === 'ok') {
				this.setState({ subCommentText: '' });
				let path = window.location.pathname;
				path = path.split('/').pop();

				fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + path, {
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then((data) => data.json())
				.then(function(data) {
					if(data.hasOwnProperty('data')) {
						for(let i = 0; i < data.data.length; i++) {
							data.data.comments[i]['showReply'] = false;
						}
						this.props.updateSelectedOpportunity(data['data']);
					} else {
					}
				}.bind(this));
			} else {
				window.location = "/404";
			}
		}.bind(this));
	}

	render() {
		if(this.props.selectedOpportunity) {
			return (
				<li className="single-comment">
					<Row>
						<Col md={12}>
							<Row className="flex">
								<Col className="comment" md={12}>
									<div className="just-flex">
										<img src={this.props.comment.postedBy.profilePictureUrl || "/img/user.svg"} style={{width: 50, height: 50, background: '#e0e0e0', padding: 3}}
											alt="" className="img-circle img-responsive mr10" />
										<div className="comment-writer flex-col">
											<span className="writer" data-posted-by={this.props.comment.postedBy.id}>
												{this.props.comment.postedBy.id ? <a href={"/profile/" + this.props.comment.postedBy.id} target="_blank">
												{this.props.comment.postedBy.fullName}
													{/* {feedback.fullName} */}
													{/* {cell} */}
												</a> : this.props.comment.postedBy.fullName}
											</span>
											<span className="writer-date" data-posted-by={this.props.comment.postedBy.id}> {moment(new Date(this.props.comment.createdOn)).format('DD/MM/YYYY h:mm a')} </span>
										</div>
									</div>
									<ToastContainer
							 position="bottom-center"
						     autoClose={50000}
						     hideProgressBar={true}
					 	     closeOnClick={false}
						     newestOnTop={false}
						     pauseOnHover={true} />
									<div className="comment-data">
										<p dangerouslySetInnerHTML={{__html: this.props.comment.text}}></p>
									</div>
									{(() => {
										if(this.props.comment.images.length) {
											return (
												<div className="image-sec">
													<ul id="lightgallery"> {/*col-md-12*/}
														{this.props.comment.images.map(function(img, i) {
															return (
																<li data-src={img}>
																	<img alt="comment" src={img} />
																</li>
															)
														})}
													</ul>
												</div>
											)
										}
									})()}
									{/*<div className="comment-options">*/}
										{/*{(() => {*/}
											{/*if(comment.subComments.length) {*/}
												{/*return (*/}
													{/*<span className="view_reply" onClick={this.onShowReply.bind(this, i)}>*/}
													{/*<i className="fa fa-reply-all"> </i>*/}
													{/*<span className="view_reply_text">*/}
														{/*<span>*/}
															{/*View {this.props.comment.subComments.length} Replies*/}
														{/*</span>*/}
													{/*</span>*/}
												{/*</span>*/}
												{/*)*/}
											{/*} else {*/}
												{/*return (*/}
													{/*<span className="reply" onClick={this.onShowReply.bind(this, i)}>*/}
													{/*<i className="fa fa-commenting-o"> </i>*/}
													{/*<span className="reply_text">Reply</span>*/}
								                {/*</span>*/}
												{/*)*/}
											{/*}*/}
										{/*})()}*/}
									{/*</div>*/}
								</Col>
							</Row>
							<div className="reply-section">
								{(() => {
									if(this.props.subComments.length) {
										return (
											<div className="comments-container">
												<ul>
													{this.props.subComments.map(function (subComment, i) {
														return (
															<li>
																<div className="row" id="view_replies">
																	<div className="col-md-10 col-md-offset-1">
																		<div className="row flex">
																			<div className="comment col-md-12 padding0 mar0">
																				<div className="just-flex">
																					<img src={subComment.postedBy.profilePictureUrl || "/img/user.svg"} style={{width: 50, height: 50, background: '#e0e0e0', padding: 3}}
																						alt="" className="img-circle img-responsive mr10"/>
																					<div className="comment-writer flex-col">
																						
																						<span className="writer" data-posted-by={subComment.postedBy.id}>
																							{/* {subComment.postedBy.fullName} */}
																							{subComment.postedBy.id ? <a href={"/profile/" + subComment.postedBy.id} target="_blank">
																								{subComment.postedBy.fullName}
																								{/* {feedback.fullName} */}
																								{/* {cell} */}
																							</a> : subComment.postedBy.fullName}
																						</span>
																						<span className="writer-date" data-posted-by={subComment.postedBy.id}> {moment(new Date(subComment.createdOn)).format('DD/MM/YYYY h:mm a')} </span>
																					</div>
																				</div>
																				<div className="subComment-data">
																					<p dangerouslySetInnerHTML={{__html: subComment.text}}></p>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</li>
														)
													})}
												</ul>
											</div>
										)
									}
								})()}

								<div className="row write_reply">
									<div className="col-md-11">
										<div className="write-reply-container">
											<textarea name="" className="reply-textarea"
							          data-comment-id={this.props.comment.id}
							          value={this.state.subCommentText}
											  placeholder="Add a comment..."
											  onChange={this.onSubCommentTextChange.bind(this)}>
											</textarea>
											{this.state.subCommentText !== '' ? (
												<button className="btn btn-primary reply-btn" onClick={this.onSubCommentPost.bind(this)}>Reply</button>
											) : (
												<button className="btn btn-primary reply-btn" disabled="disabled">Reply</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</li>
			);
		} else {
			return null
		}

	}
}