import React, { Component } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as action from "../../../../../actions/EEAdminOpportunityRecommendationsAction";
const readCookie = require("../../../../../cookie").readCookie;
import { ToastContainer, toast } from "react-toastify";
import configs from "../../../../../config/urlConfigs";
class UpdateAnOpportunitySwitch extends Component {
  constructor() {
    super();
    this.state = { status: "", feedback: "" };
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleStatus = (e) => {
    this.setState({
      status: e.target.value,
    });
    this.props.selectedOpportunity.status = e.target.value;
  };

  handleFeeback = (e) => {
    let commentRegex = new RegExp(`^[\.a-zA-Z0-9,!?:;@#$~' ]*$`);
    if (commentRegex.test(e.target.value) === true || true){
    this.setState({
      feedback: e.target.value,
    });
    this.props.selectedOpportunity.feedback = e.target.value;
  }
  else {
    this.notifyError("Only letters, numbers and special characters are allowed");
  }
  };
  notifyError = (text) => toast.error(text);
  onSubmit(e) {
    e.preventDefault();
    let data = {};
   
    data["status"] =  this.state.status === "" ? this.props.selectedOpportunity.status
        : this.state.status;
    data["feedback"] = this.state.feedback === "" ? this.props.selectedOpportunity.feedback : this.state.feedback;

    if (this.state.feedback || this.state.status) {
      swal({
        title: "Are you sure you want to update this?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Update!",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result) {
          fetch(
            configs.BACKEND_API_URL +
            `/volunteer-opportunity/update-recommendation/${this.props.selectedOpportunity._id}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Auth: JSON.parse(readCookie("access_token")).access_token,
              },
              body: JSON.stringify(data),
            }
          )
            .then(function (response) {
              return response.json();
            })
            .then(
              fetch(
                configs.BACKEND_API_URL +
                "/volunteer-opportunity/check-recommendations",
                {
                  method: "GET",
                  headers: {
                    Auth: JSON.parse(readCookie("access_token")).access_token,
                  },
                }
              )
                .then(function (response) {
                  return response.json();
                })
                .then(
                  function (data) {
                    this.props.actions.updateOpportunities(data.data.reverse());
                  }.bind(this)
                )
            )
            .then(
              function (data) {
                if (data.status === "ok") {
                  swal("Awesome!", "Recommendation has been updated");
                  this.props.closeModal1();
                  window.location.reload();
                }
              }.bind(this)
            );
        }
      });
    }
  }

  render() {
    return (
      <Modal
        show={this.props.showRecommendOpportunityModal}
        onHide={this.props.closeModal1}
      >
        <ToastContainer
						position="bottom-center"
						autoClose={50000}
						hideProgressBar={true}
						closeOnClick={false}
						newestOnTop={false}
						pauseOnHover={true}
					/>
        <Modal.Header closeButton>
          <Modal.Title>Take Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} className="margin-col-5">
              <form name="groupForm" onSubmit={this.onSubmit}>
                <div className="control-label">
                  Description of association: {" "}
                  {" " + this.props.selectedOpportunity.description}
                </div>
                <br />
                <div className="control-label">
                  Email of contact person: {" "}
                  {" " + this.props.selectedOpportunity.emailOfPOC}
                </div>
                <br />
                <div className="control-label">
                  Phone of contact person: {" "}
                  {" " + this.props.selectedOpportunity.phoneOfPOC}
                </div>
                <br />
                <div className="control-label">
                  Message from recommender: {" "}
                  {" " + this.props.selectedOpportunity.introductoryNote}
                </div>
                <br />

                <div className="form-group">
                  <label className="control-label">Status:</label>
                  <select
                    name="body_name"
                    id="body_name"
                    className="form-control"
                    required
                    value={this.props.selectedOpportunity.status}
                    onChange={this.handleStatus}
                  >
                    <option value="Yet to take action">
                      Yet to take action
                    </option>
                    <option value="Under Review">Under Review</option>
                    <option value="Declined by the recommended partner">
                      Declined by the recommended partner
                    </option>
                    <option value="Declined by the company">
                      Declined by the company
                    </option>
                    <option value="Successfully onboarded">
                      Successfully onboarded
                    </option>
                  </select>
                </div>

                <div className="form-group">
                  <label className="control-label">Comments:</label>
                  <textarea
                    className="form-control"
                    id="body_name"
                    name="body_name"
                    placeholder="Comments"
                    required
                    value={this.props.selectedOpportunity.feedback}
                    onChange={this.handleFeeback}
                  ></textarea>
                </div>

                <div className="form-group" align='center'>
                  <button
                    type="submit"
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </Col>
          </Row>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            onClick={this.props.closeModal1}
            className="btn-sq btn-cancel"
          >
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    );
  }
}

UpdateAnOpportunitySwitch.propTypes = {
  opportunities: PropTypes.array,
  selectedOpportunity: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    opportunities: state.EEAdminOpportunityRecommendations.opportunities,
    selectedOpportunity:
      state.EEAdminOpportunityRecommendations.selectedOpportunity,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(action, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateAnOpportunitySwitch);
