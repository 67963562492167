export const skills = {
    le: {
        "Administration & Logistics": ["Administrative Support", "Customer Service", "Data Entry", "Executive Admin", "Program Management", "Transactions", "Inventory Management", "Operations", "Supply Chain", "Warehousing", "Event Design & Planning", "Event Management", "Food Delivery / Distribution"],
        "Animals & Environment": ["Animal Care / Handling", "Environmental Education", "Farming", "Gardening", "Habitat Restoration", "Veterinary"],
        Arts: ["Arts Administration", "Curating", "Dance", "Drawing", "Exhibition Arts", "Interior / Exterior Design", "Music", "Performing Arts", "Painting", "Photography", "Pottery", "Visual Arts"],
        "IT & Web Development": ["Analytics", "Basic Computer Skills", "Database Administration", "Internet Research", "Microsoft Office Suite", "Robotics", "Security / Network Administration", "ERP / CRM", "IT Strategy", "Animation Software", "Graphic Design", "Video Production", "Search Engine Optimization(SEO)", "Software Development", "Web Design"],
        "Disaster Relief": ["Disaster Cleanup", "Disaster Relief Call Center", "Disaster Relief Care & Shelters", "Disaster Response", "Safety & Disaster Education", "Search & Rescue"],
        "Education & Literacy": ["Adult Education", "English as a Secondary Language(ESL)", "Financial Literacy", "General Education", "Literacy / Reading", "Math / Science Instruction", "Mentoring", "Physical Education", "Reading / Writing", "Sign Language", "STEM", "Special Needs", "Sports Coaching", "Teaching / Instruction", "Tutoring"],
        "Finance": ["Accounting", "Bookkeeping", "Budgeting", "Financial Planning"],
        "Nonprofit Development": ["Business Development & Sales Management", "CSR / Volunteer Coordination", "Community Outreach", "Customer Acquisition", "Donor Management", "Fundraising", "Grant Writing / Research", "Merchandising", "Business Analysis", "Capacity Planning", "Market Research", "Product Development"],
        "HR": ["Benefits", "Career Counseling / Coaching", "Compensation", "Human Resources Strategy", "Human Resources Training & Development", "Organization Design", "Performance Management", "Recruiting"],
        "Healthcare & Social Services": ["Counseling / Mental Health", "Crisis Counseling", "Dental", "First Aid / CPR", "Hospice Care", "Life Coaching", "Nutrition", "Child Development", "Elder care", "Family Therapy", "Youth Services"],
        "Interpersonal": ["Critical Thinking", "Networking", "Organization", "People Skills", "Problem Solving", "Project Management", "Public Speaking", "Reading Comprehension", "Relationship Building", "Verbal / Written Communication"],
        "Legal & Advocacy": ["Advocacy", "Conflict Resolution", "Contract Negotiations", "Intellectual Property", "Legal(General)", "Litigation", "Lobbying", "Mergers & Acquisitions", "Paralegal", "Public Advocacy", "Secretarial", "Tax Law"],
        "Marketing & Communications": ["Advertising", "Brand Development & Messaging", "Content Management Systems", "Copy Writing / Copy Editing", "Graphic Design / Print", "Interactive / Social Media / SEO", "Journalism", "Marketing & Communications(Mar / Com)", "Marketing Analytics", "Marketing Strategy & Planning", "Public Relations", "Sales Marketing", "Social Media / Blogging"]
    },
    indusind: ['Organizing Skills', 'High Proficiency in MS Excel', 'Presentation Design', 'Care Giving for Children and elderly', 'Teaching Children', 'Teaching Adults', 'Garnering Volunteers', 'Funds & Material Mobilization for NGO', 'Inter - Personal Communication with Strangers',
        'Help in Kitchen', 'Cleaning Help', 'Participating in Dramatics and Street Plays', 'Singing', 'MIS',
        'Recording of Audio Content for Children', 'Communication Design', 'Digital Marketing', 'Finance & Accounting', 'General Management of NGO', 'Legal guidance', 'IT support', 'Public Speaking (Specify Languages)',
    ]
}