export const updateHistory = (history) => {
	return {
		history,
		type: 'UPDATE_HISTORY'
	}
};

export const updateCountOfProjectsEndorsed = (countOfProjectsEndorsed) => {
	return {
		countOfProjectsEndorsed,
		type: 'UPDATE_COUNT_OF_PROJECTS_ENDORSED'
	}
};

export const updateTotalContributed = (totalContributed) => {
	return {
		totalContributed,
		type: 'UPDATE_TOTAL_CONTRIBUTED'
	}
};

export const updateTotalMatchedByCompany= (totalMatchedByCompany) => {
	return {
		totalMatchedByCompany,
		type: 'UPDATE_TOTAL_MATCHED_BY_COMPANY'
	}
};

export const updateSelectedOpportunity = (selectedOpportunity) => {
	return {
		selectedOpportunity,
		type: 'UPDATE_SELECTED_OPPORTUNITY'
	}
};

export const updateCauses = (causes) => {
	return {
		causes,
		type: 'UPDATE_CAUSES'
	}
};

export const updateLocation = (location) => {
	return {
		location,
		type: 'UPDATE_LOCATION'
	}
}