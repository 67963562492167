import React, { Component } from "react";
import swal from "sweetalert2";
import Multiselect from "react-bootstrap-multiselect";
import $ from "jquery";
import ReactModal from "react-modal";
import EEQuestionnaireModal from "./EEQUestionnaireModal";
import { Row, Col } from "react-bootstrap";
import configs from "../../../config/urlConfigs";

const readCookie = require("../../../cookie.js").readCookie;
const createCookie = require("../../../cookie.js").createCookie;

export default class EEQuestionnaire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionnaire: null,
      questionnairePreferences: null,
      goals: [],
      companyData: {},
      userData: {},
      showModal: false,
      answerQuestions: false,
      questionnaireUpdated: false,
    };
  }

  // async
  componentDidMount() {
    let self = this;
    fetch(
      configs.BACKEND_API_URL + "/get-questionnaire-preference-summary",
      {
        method: "GET",
        headers: {
          Auth: JSON.parse(readCookie("access_token")).access_token,
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {
          let questionnaire = {};
          let showModal =
            data.data.questionnaireEnabled &&
            !data.data.questionnaireAnswered &&
            (data.data.questionnaireMandatory ||
              JSON.parse(readCookie("skippedQuestionnairePreferences")) ===
                undefined);
          let answerQuestions =
            data.data.questionnaireEnabled &&
            !data.data.questionnaireAnswered &&
            JSON.parse(readCookie("skippedQuestionnairePreferences")) !==
              undefined;
          let questionnaireUpdated =
            data.data.questionnaireEnabled &&
            data.data.questionnaireUpdatedAt >
              data.data.questionnaireAnsweredAt;
          if (showModal || answerQuestions || questionnaireUpdated)
            questionnaire = data.data.companyQuestionnaireObj;
          else {
            questionnaire = data.data.userQuestionnaireObj;
          }
          self.setState({
            questionnaire,
            showModal,
            answerQuestions,
            questionnaireUpdated,
          });
        }.bind(this)
      );
    // await this.getQuestionnaireData();
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }
  showModal() {
    this.setState({ showModal: true });
  }

  render() {
    return (
      <div className="ee-page-container questionnaire-page">
        <ul role="tablist" className="nav nav-tabs">
          <li role="presentation">
            <a
              id="settings-profile"
              role="tab"
              aria-controls="settings-profile"
              href="/profile"
            >
              <div className="settings-profile">
                <div style={{ display: "inline-block" }}></div>
                <span> Profile</span>
              </div>
            </a>
          </li>
          <li role="presentation" className="active">
            <a
              id="settings-questionnaire"
              role="tab"
              aria-controls="settings-questionnaire"
            >
              <div className="settings-questionnaire">
                <div style={{ display: "inline-block" }}></div>
                <span> My Preferences</span>
              </div>
            </a>
          </li>
        </ul>
        {this.state.answerQuestions ? (
          <React.Fragment>
            <Col
              md={12}
              className="preferencepage-questionnaire-alert-container"
            >
              <div className="preferencepage-questionnaire-alert" ref={element => { 
             if (element) element.style.setProperty('margin-top', '0.2%', 'important'); 
           }}>
                <span>
                  You have not taken the volunteering preference survey yet!
                </span>
                <button
                  type="button"
                  className="btn mark-attendance-btn"
                  onClick={() => {
                    this.setState({ showModal: true });
                  }}
                >
                  TAKE THE SURVEY
                </button>{" "}
              </div>
            </Col>
            <ReactModal
              isOpen={this.state.showModal}
              contentLabel="onRequestClose Example"
              onRequestClose={this.handleCloseModal}
              shouldCloseOnEsc={true}
              shouldCloseOnOverlayClick={true}
            >
              <button
                className="btn btn-close-modal"
                onClick={this.handleCloseModal}
              >
                <i className="fa fa-close"></i>
              </button>
              <div className="container-fluid">
                <span
                  className="skip-questionnaire"
                  onClick={this.handleClose}
                ></span>
                <EEQuestionnaireModal
                  // userData={this.state.userData}
                  // companyData={this.state.companyData}
                  questionnaire={this.state.questionnaire}
                />
              </div>
            </ReactModal>
          </React.Fragment>
        ) : (
          <div>
            {this.state.questionnaire !== null && (
              <React.Fragment>
                {this.state.answerQuestions !== undefined &&
                  !this.state.answerQuestions &&
                  this.state.questionnaireUpdated && (
                    <div>
                      <Col
                        md={12}
                        className="preferencepage-questionnaire-alert-container"
                      >
                        <div className="preferencepage-questionnaire-alert">
                          <span>
                            Your organisation might have updated the
                            volunteering preference questionnaire!
                          </span>
                          <button
                            type="button"
                            className="btn mark-attendance-btn"
                            onClick={() => {
                              this.setState({ showModal: true });
                            }}
                          >
                            COMPLETE ADDING YOUR PREFERENCE
                          </button>{" "}
                        </div>
                      </Col>
                    </div>
                  )}
                <div className="container-fluid">
                  {this.state.questionnaire.selectedEmployeeQuestions.map(
                    (question) => {
                      return (
                        <div className="form-group">
                          {question.checked && (
                            <p>
                              <strong>{question.question}</strong><hr />
                              <strong>Your Response: &nbsp;</strong>
                              {question.type === "singleselect"
                                ? question.selected
                                : question.selected.join(", ")}
                            </p>
                          )}
                        </div>
                      );
                    }
                  )}
                  <ReactModal
                    isOpen={this.state.showModal}
                    contentLabel="onRequestClose Example"
                    onRequestClose={this.handleCloseModal}
                    shouldCloseOnEsc={true}
                    shouldCloseOnOverlayClick={true}
                  >
                    <button
                      className="btn btn-close-modal"
                      onClick={this.handleCloseModal}
                    >
                      <i className="fa fa-close"></i>
                    </button>
                    <div className="container-fluid">
                      <span
                        className="skip-questionnaire"
                        onClick={this.handleClose}
                      ></span>
                      <EEQuestionnaireModal
                        // userData={this.state.userData}
                        // companyData={this.state.companyData}
                        questionnaire={this.state.questionnaire}
                      />
                    </div>
                  </ReactModal>
                  <button
                    type="button"
                    className="btn btn-sq btn-le"
                    onClick={() => {
                      this.setState({ showModal: true });
                    }}
                  >
                    Edit Preferences
                  </button>
                </div>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    );
  }
}
