const initialState = {
  opportunityDescription: "",
  opportunityEmail: "",
  opportunityPhone: "",
  opportunityNote: "",
  opportunityStatus: "",
  opportunityFeedback: "",
};

const EERecommendOpportunityReducer = (state = initialState, action) => {
	switch (action.type) {
    case "UPDATE_OPPORTUNITY_DESCRIPTION":
      return Object.assign({}, state, {
        opportunityDescription: action.opportunityDescription,
      });
    case "UPDATE_OPPORTUNITY_EMAIL":
      return Object.assign({}, state, {
        opportunityEmail: action.opportunityEmail,
      });
    case "UPDATE_OPPORTUNITY_PHONE":
      return Object.assign({}, state, {
        opportunityPhone: action.opportunityPhone,
      });
    case "UPDATE_OPPORTUNITY_NOTE":
      return Object.assign({}, state, {
        opportunityNote: action.opportunityNote,
      });
    case "UPDATE_OPPORTUNITY_STATUS":
      return Object.assign({}, state, {
        opportunityStatus: action.opportunityStatus,
      });
    case "UPDATE_OPPORTUNITY_FEEDBACK":
      return Object.assign({}, state, {
        opportunityFeedback: action.opportunityFeedback,
      });
    default:
      return state;
  }
}

export default EERecommendOpportunityReducer;