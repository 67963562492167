export const updateOpportunities = (opportunities) => {
	return {
		opportunities,
		type: 'UPDATE_OPPORTUNITIES'
	}
};

export const updateSelectedOpportunity = (selectedOpportunity) => {
	return {
		selectedOpportunity,
		type: 'UPDATE_SELECTED_OPPORTUNITY'
	}
};