const initialState = {
	history: null,
	location: null,
	causes: null,
	countOfProjectsEndorsed: 0,
	totalContributed: 0,
	totalMatchedByCompany: 0,
	selectedOpportunity: {}
};

const EEEndorsementHistoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_HISTORY':
			return Object.assign({}, state, {
				history: action.history,
			});
		case 'UPDATE_COUNT_OF_PROJECTS_ENDORSED':
			return Object.assign({}, state, {
				countOfProjectsEndorsed: action.countOfProjectsEndorsed,
			});
		case 'UPDATE_TOTAL_CONTRIBUTED':
			return Object.assign({}, state, {
				totalContributed: action.totalContributed,
			});
		case 'UPDATE_TOTAL_MATCHED_BY_COMPANY':
			return Object.assign({}, state, {
				totalMatchedByCompany: action.totalMatchedByCompany,
			});
		case 'UPDATE_SELECTED_OPPORTUNITY':
			return Object.assign({}, state, {
				selectedOpportunity: action.selectedOpportunity
			});
		case 'UPDATE_CAUSES':
			return Object.assign({}, state, {
				causes: action.causes,
			});
		case 'UPDATE_LOCATION':
			return Object.assign({}, state, {
				location: action.location,
			});
		default:
			return state
	}
}

export default EEEndorsementHistoryReducer;