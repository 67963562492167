import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn, ButtonGroup, ExportCSVButton } from 'react-bootstrap-table';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, Cell, LabelList } from 'recharts';
import moment from 'moment';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// import EmployeeContributionAnalyticsChart from './EmployeeContributionAnalyticsChart';
// import EmployeePayrollAnalyticsChart from './EmployeePayrollAnalyticsChart';
// import EmployeeMatchingAnalyticsChart from './EmployeeMatchingAnalyticsChart';
import ContributionAnalyticsFilter from "./ContributionAnalyticsFilter";

import * as action from '../../../../../actions/EEAdminContributionAnalyticsAction';
import configs from '../../../../../config/urlConfigs';

const readCookie = require('../../../../../cookie.js').readCookie;

class CustomLineTooltip extends Component {
	render() {
    const { active } = this.props;
    if (active && this.props.payload !== null && this.props.payload.length) {
      const { payload, label } = this.props;
      return (
        <div className="custom-analytics-graph-tooltip text-center">
          <p className="label">{`Date: ${label}`}</p>
          <p className="label" style={{color: payload[0].color}}>{`Contribution Amount: INR ${payload[0].value}`}</p>
          <p className="label" style={{color: payload[1].color}}>{`Matching Giving Amount: INR ${payload[1].value}`}</p>
          <p className="label" style={{color: payload[2].color}}>{`Payroll Amount: INR ${payload[2].value}`}</p>
        </div>
      );
    }
    return null;
  }
}

class CustomBarTooltip extends Component {
	render() {
    const { active } = this.props;
    if (active && this.props.payload !== null && this.props.payload.length) {
      const { payload, label } = this.props;
      return (
        <div className="custom-analytics-bar-tooltip text-center">
          <div className="label" style={{color: payload[0].payload.color, backgroundColor: '#DADADA'}}>{`${payload[0].payload.name}`}</div>
          <p className="label" style={{color: '#FFA500'}}>{`Contribution Amount: INR ${payload[0].value}`}</p>
          <p className="label le-color">{`Matching Giving Amount: INR ${payload[1].value}`}</p>
          <p className="label" style={{color: '#00A0A8'}}>{`Payroll Amount: INR ${payload[2].value}`}</p>
        </div>
      );
    }
    return null;
  }
}

class ContributionAnalytics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lineChartData: [],
			barChartData: [],
			tableData: []
		}
		this.updateContributionAnalyticsData = this.updateContributionAnalyticsData.bind(this);
	}

	componentDidMount() {
		fetch(configs.BACKEND_API_URL + '/get-combined-stats', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			this.updateContributionAnalyticsData(data);
		}.bind(this));
	}

	updateContributionAnalyticsData(apiData) {
		var contributionData = apiData.perDayContribs.slice();

		let employeeTotalContribAmount = 0;
		for (let i = 0; i < contributionData.length; i++) {
			var amount = contributionData[i].amount;
			contributionData[i]['contributionAmount'] = amount;
			delete contributionData[i]['amount'];
			employeeTotalContribAmount += amount;
		}
		let employeeTotalMatchingAmount = 0;
		for (let i = 0; i < apiData.perDayMatchGivingContribs.length; i++) {
			employeeTotalMatchingAmount += apiData.perDayMatchGivingContribs[i].amount;
		}
		let employeeTotalPayrollAmount = 0;
		for (let i = 0; i < apiData.perDayPayrollContribs.length; i++) {
			employeeTotalPayrollAmount += apiData.perDayPayrollContribs[i].amount;
		}

		var lineChartData = [];
		var dates = [];

		lineChartData = contributionData.slice();
		for(var i = 0; i < lineChartData.length; i++) {
			dates.push(lineChartData[i].date);
		}

		var matchingGivingContributionData = apiData.perDayMatchGivingContribs.slice();
		for(var i = 0; i < matchingGivingContributionData.length; i++) {
			if(dates.indexOf(matchingGivingContributionData[i].date) > -1) {
				var index = dates.indexOf(matchingGivingContributionData[i].date);
				lineChartData[index]['givingContributionAmount'] = matchingGivingContributionData[i].amount;
			} else {
				var obj = matchingGivingContributionData[i];
				obj['givingContributionAmount'] = obj['amount'];
				delete obj['amount'];
				lineChartData.push(obj);
				dates.push(obj['date']);
			}
		}

		var payrollContributionData = apiData.perDayPayrollContribs.slice();
		for(var i = 0; i < payrollContributionData.length; i++) {
			if(dates.indexOf(payrollContributionData[i].date) > -1) {
				var index = dates.indexOf(payrollContributionData[i].date);
				lineChartData[index]['payrollContributionAmount'] = payrollContributionData[i].amount;
			} else {
				var obj = payrollContributionData[i];
				obj['payrollContributionAmount'] = obj['amount'];
				delete obj['amount'];
				lineChartData.push(obj);
				dates.push(obj['date']);
			}
		}

		lineChartData = lineChartData.sort(function (a, b) {
	    return moment(a.date, 'DD/MM/YYYY').diff(moment(b.date, 'DD/MM/YYYY'))
		});

		var i = 0;
		while(i < lineChartData.length - 1) {
			var currentDate = moment(lineChartData[i].date, 'DD/MM/YYYY');
			var nextDate = moment(lineChartData[i + 1].date, 'DD/MM/YYYY');
			var dateDiff = nextDate.diff(currentDate, 'days');
			for(var j = 1; j < dateDiff; j++) {
				var date = moment(currentDate, 'DD/MM/YYYY').add(j, 'days').format('DD/MM/YYYY');
				var data = {
					date: date,
					contributionAmount: 0,
					givingContributionAmount: 0,
					payrollContributionAmount: 0
				};
				lineChartData.splice(i + j, 0, data);
			}
			if(dateDiff > 1) i = i + dateDiff;
			else i++;
		}

		for(var i = 0; i < lineChartData.length; i++) {
			if(!lineChartData[i].hasOwnProperty('contributionAmount')) lineChartData[i]['contributionAmount'] = 0;
			if(!lineChartData[i].hasOwnProperty('givingContributionAmount')) lineChartData[i]['givingContributionAmount'] = 0;
			if(!lineChartData[i].hasOwnProperty('payrollContributionAmount')) lineChartData[i]['payrollContributionAmount'] = 0;
		}

		this.setState({ lineChartData });

		var barChartData = [];
		var goalNames = [];

		var contributionCausesData = apiData.causeShareInTotalContribs.slice();
		for (let i = 0; i < contributionCausesData.length; i++) {
			var amount = contributionCausesData[i].amount;
			contributionCausesData[i]['contributionAmount'] = amount;
			delete contributionCausesData[i]['amount'];
		}

		barChartData = contributionCausesData.slice();
		for(var i = 0; i < barChartData.length; i++) {
			goalNames.push(barChartData[i].name);
		}

		var matchingGivingCausesData = apiData.matchGivingCauseShare.slice();
		for(var i = 0; i < matchingGivingCausesData.length; i++) {
			if(goalNames.indexOf(matchingGivingCausesData[i].name) > -1) {
				var index = goalNames.indexOf(matchingGivingCausesData[i].name);
				barChartData[index]['givingContributionAmount'] = matchingGivingCausesData[i].amount;
			} else {
				var obj = matchingGivingCausesData[i];
				obj['givingContributionAmount'] = obj['amount'];
				delete obj['amount'];
				barChartData.push(obj);
				goalNames.push(obj['name']);
			}
		}

		var payrollCausesData = apiData.payrollCauseShare.slice();
		for(var i = 0; i < payrollCausesData.length; i++) {
			if(goalNames.indexOf(payrollCausesData[i].name) > -1) {
				var index = goalNames.indexOf(payrollCausesData[i].name);
				barChartData[index]['payrollContributionAmount'] = payrollCausesData[i].amount;
			} else {
				var obj = payrollCausesData[i];
				obj['payrollContributionAmount'] = obj['amount'];
				delete obj['amount'];
				barChartData.push(obj);
				goalNames.push(obj['name']);
			}
		}

		for(var i = 0; i < barChartData.length; i++) {
			barChartData[i]['axisLabel'] = 'Goal ' + (i + 1);
			if(!barChartData[i].hasOwnProperty('contributionAmount')) barChartData[i]['contributionAmount'] = 0;
			if(!barChartData[i].hasOwnProperty('givingContributionAmount')) barChartData[i]['givingContributionAmount'] = 0;
			if(!barChartData[i].hasOwnProperty('payrollContributionAmount')) barChartData[i]['payrollContributionAmount'] = 0;
		}

		this.setState({ barChartData });

		var tableData = apiData.projects.slice();
		tableData = tableData.sort(function (a, b) {
	    return moment(a.date, 'DD/MM/YYYY').diff(moment(b.date, 'DD/MM/YYYY'))
		});

		this.setState({ tableData });
		// this.props.actions.updateContributionAmount(employeeTotalContribAmount);
		// this.props.actions.updateContribution(contributionData);
		// this.props.actions.updateContributionCauses(apiData.causeShareInTotalContribs);

		// this.props.actions.updateMatchingAmount(employeeTotalMatchingAmount);
		// this.props.actions.updateMatching(matchingGivingContributionData);
		// this.props.actions.updateMatchingCauses(apiData.matchGivingCauseShare);

		// this.props.actions.updatePayrollAmount(employeeTotalPayrollAmount);
		// this.props.actions.updatePayroll(payrollContributionData);
		// this.props.actions.updatePayrollCauses(apiData.payrollCauseShare);
	}

	title(cell, row) {
		return (
			<a cassName="le-color" href={"/admin/project/" + row.projectId}>{cell}</a>
		)
	}

	render() {
		const formatter = (value) => `INR ${value}`;
		const options = {
		  sizePerPage: 20,
		  sizePerPageList: [
		    { text: '20 Per Page', value: 20 },
		    { text: '50 Per Page', value: 50 },
		    { text: '100 Per Page', value: 100 }
		  ]
		};
		return (
			<div className="ee-page-container">
				<Row>
					<Col md={12} className="text-center">
						<h2 className="ee-page-title">Contribution Analytics</h2>
					</Col>
				</Row>
				<ContributionAnalyticsFilter updateContributionAnalyticsData={this.updateContributionAnalyticsData.bind(this)} />
				<Row>
					<Col md={12} className="text-center">
						<label className="control-label"></label>
					</Col>
					<Col md={12} className="mb30">
						<div className="linechart-container volunteering-hours-graph-container" style={{height: 360}}>
							<ResponsiveContainer width='100%' height='100%'>
								<LineChart responsive margin={{top: 10, right: 10, left: 10, bottom: 30}} data={this.state.lineChartData}>
									<XAxis dataKey="date" angle={-45} textAnchor="end" />
									<YAxis />
									<CartesianGrid strokeDasharray="3 3" />
									{this.state.lineChartData.length ? (
										<Tooltip content={<CustomLineTooltip />} />
									) : (null)}
									<Line type="monotone" dataKey="contributionAmount" stroke="#FFA500" strokeWidth={2} />
									<Line type="monotone" dataKey="givingContributionAmount" stroke="#ef5a20" strokeWidth={2} />
									<Line type="monotone" dataKey="payrollContributionAmount" stroke="#00A0A8" strokeWidth={2} />
								</LineChart>
							</ResponsiveContainer>
						</div>
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<div className="linechart-container volunteering-hours-graph-container" style={{height: 400}}>
							<ResponsiveContainer width='100%' height='100%'>
								<BarChart data={this.state.barChartData} barGap={0} maxBarSize={10} margin={{top: 10, right: 10, left: 10, bottom: 20}} layout="vertical">
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis type="number" tickFormatter={formatter} />
									<YAxis type="category" dataKey="axisLabel" />
									{this.state.barChartData.length ? (
										<Tooltip content={<CustomBarTooltip />} />
									) : (null)}
									<Bar dataKey="contributionAmount" barGap={0} fill="#FFA500" />
									<Bar dataKey="givingContributionAmount" barGap={0} fill="$themeColor" />
									<Bar dataKey="payrollContributionAmount" barGap={0} fill="#00A0A8" />
								</BarChart>
							</ResponsiveContainer>
						</div>
					</Col>
				</Row>
				<Row className="m15">
					<Col md={12} className="contribution-analytics-table-container mt30 mb50">
						<BootstrapTable data={this.state.tableData} options={options} striped={false} ref='table' hover={true} exportCSV csvFileName={'Contribution Analytics - ' + String(moment().unix()) + '.csv'}>
						  <TableHeaderColumn width='100px' dataField="date" isKey={true} hidden={false} export={true} searchable={false}>Date</TableHeaderColumn>
						  <TableHeaderColumn dataField="title" hidden={false} export={true} searchable={true} dataFormat={this.title.bind(this)}>Title</TableHeaderColumn>
						  <TableHeaderColumn width='140px' dataField="contribution" hidden={false} export={true} searchable={false}>Total Contribution</TableHeaderColumn>
							<TableHeaderColumn width='130px' dataField="matched" hidden={false} export={true} searchable={true}>Total Matched</TableHeaderColumn>
							<TableHeaderColumn width='140px' dataField="payroll" hidden={false} export={true} searchable={true}>Total Payroll Contribution</TableHeaderColumn>
						</BootstrapTable>
					</Col>
				</Row>
				{/*this.props.employeeContributionLocations !== null ? (
					<EmployeeContributionAnalyticsChart employeeContributionAmount={this.props.employeeContributionAmount}
	          employeeContribution={this.props.employeeContribution}
	          employeeContributionCauses={this.props.employeeContributionCauses}
	          employeeContributionLocations={this.props.employeeContributionLocations}/>
				) : (null)*/}
				{/*this.props.employeePayrollLocations !== null ? (
					<EmployeePayrollAnalyticsChart employeePayrollAmount={this.props.employeePayrollAmount}
		      	employeePayroll={this.props.employeePayroll}
		      	employeePayrollCauses={this.props.employeePayrollCauses}
          	employeePayrollLocations={this.props.employeePayrollLocations}/>
				) : (null)}
				{this.props.employeeMatchingLocations !== null ? (
					<EmployeeMatchingAnalyticsChart employeeMatchingAmount={this.props.employeeMatchingAmount}
            employeeMatching={this.props.employeeMatching}
            employeeMatchingCauses={this.props.employeeMatchingCauses}
            employeeMatchingLocations={this.props.employeeMatchingLocations}/>
				) : (null)*/}
			</div>
		);
	}
}

ContributionAnalytics.propTypes = {
	employeeContributionAmount: PropTypes.number,
	employeeContribution: PropTypes.array,
	employeeContributionCauses: PropTypes.array,
	employeeContributionLocations: PropTypes.array,
	employeeMatchingAmount: PropTypes.number,
	employeeMatching: PropTypes.array,
	employeeMatchingCauses: PropTypes.array,
	employeeMatchingLocations: PropTypes.array,
	employeePayrollAmount: PropTypes.number,
	employeePayroll: PropTypes.array,
	employeePayrollCauses: PropTypes.array,
	employeePayrollLocations: PropTypes.array,
};

function mapStateToProps(state) {
	return {
		employeeContributionAmount: state.EEAdminContributionAnalytics.employeeContributionAmount,
		employeeContribution: state.EEAdminContributionAnalytics.employeeContribution,
		employeeContributionCauses: state.EEAdminContributionAnalytics.employeeContributionCauses,
		employeeContributionLocations: state.EEAdminContributionAnalytics.employeeContributionLocations,
		employeeMatchingAmount: state.EEAdminContributionAnalytics.employeeMatchingAmount,
		employeeMatching: state.EEAdminContributionAnalytics.employeeMatching,
		employeeMatchingCauses: state.EEAdminContributionAnalytics.employeeMatchingCauses,
		employeeMatchingLocations: state.EEAdminContributionAnalytics.employeeMatchingLocations,
		employeePayrollAmount: state.EEAdminContributionAnalytics.employeePayrollAmount,
		employeePayroll: state.EEAdminContributionAnalytics.employeePayroll,
		employeePayrollCauses: state.EEAdminContributionAnalytics.employeePayrollCauses,
		employeePayrollLocations: state.EEAdminContributionAnalytics.employeePayrollLocations,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ContributionAnalytics);