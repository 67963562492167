import React, { Component } from 'react';
import $ from 'jquery';
import swal from 'sweetalert2';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../../actions/RoleAction';
import configs from '../../config/urlConfigs';

const readCookie = require('../../cookie.js').readCookie;
const createCookie = require('../../cookie.js').createCookie;

class FirstLogin extends Component {
	componentDidMount() {
		let pwd1 = '';
		let pwd2 = '';
		let pwd3 = '';
		swal({
			title: 'Kindly create a new password',
			html: '<div class="changePasswordSwalContainer"><div><span class="changePasswordSwal">Old Password: </span><input type="password" id="swal-input1" class="changePasswordSwal swal2-input" value=""></div>' +
				'<div><span class="changePasswordSwal">New Password: </span><input type="password" id="swal-input2" class="changePasswordSwal swal2-input" value=""></div>' +
				'<div><span class="changePasswordSwal">Confirm New Password: </span><input type="password" id="swal-input3" class="changePasswordSwal swal2-input" value=""></div></div>',
			showCancelButton: false,
			closeOnCancel: false,
			allowEscapeKey: false,
			confirmButtonText: 'Submit',
			showLoaderOnConfirm: true,
			preConfirm: function () {
				pwd1 = $('#swal-input1').val();
				pwd2 = $('#swal-input2').val();
				pwd3 = $('#swal-input3').val();
				return new Promise(function (resolve, reject) {
					setTimeout(function () {
						if (pwd1 === '' && pwd2 === '' && pwd2 !== pwd3) {
							reject('Password did not match.')
						} else {
							resolve()
						}
					}, 2000)
				})
			},
			allowOutsideClick: false
		}).then(function (result) {
			pwd1 = $('#swal-input1').val();
			pwd2 = $('#swal-input2').val();
			pwd3 = $('#swal-input3').val();

			if (pwd1 !== '' && pwd2 !== '' && pwd2 === pwd3 && result) {
				var userDataCookie = readCookie('userData');
				var userDataJSON = JSON.parse(userDataCookie);

				let data = {};
				data['userId'] = userDataJSON['id'];
				data['oldPassword'] = pwd1;
				data['newPassword'] = pwd2;

				fetch(configs.BACKEND_API_URL + '/updatePassword', {
					method: "POST",
					headers: {
						'Content-Type': 'application/json',
						'Auth': JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify(data)
				}).then(function (response) {
					return response.json();
				}).then(function (data) {
					if (data.status === 'ok') {
						var userDataCookie = readCookie('userData');
						var userDataJSON = JSON.parse(userDataCookie);

						userDataJSON['hasCustomPassword'] = true;
						delete userDataJSON.organisationData;
						delete userDataJSON.questionnairePreferences;
						delete userDataJSON.preference;
						this.props.actions.handleLogin();
						createCookie('userData', JSON.stringify(userDataJSON), 7);
						swal({
							type: 'success',
							title: 'Your password has been changed successfully!'
						}).then(function () {
							window.location.pathname = '/';
						});
					} else if (data.status === 'error') {
						swal('Oops...',
							'Invalid password entered!',
							'error').then(function () {
								this.componentDidMount();
							}.bind(this));
					}
				}.bind(this)).catch(function () {
					swal(
						'Oops...',
						'Some error occurred!',
						'error'
					).then(function () {
						this.componentDidMount();
					}.bind(this));
				}.bind(this));
			} else if (pwd2 !== pwd3 && result) {
				swal('Oops...',
					'Passwords did not match.',
					'error').then(function () {
						this.componentDidMount();
					}.bind(this));
			} else {
				swal('Oops...',
					'Invalid password entered!',
					'error').then(function () {
						this.componentDidMount();
					}.bind(this));
			}
		}.bind(this))
	}

	render() {
		return (
			<div></div>
		);
	}
}
FirstLogin.propTypes = {
	role: PropTypes.string,
};

function mapStateToProps(state, props) {
	return {
		role: state.RoleReducer,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstLogin);
