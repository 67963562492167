import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import { use } from "react-router-dom";
import moment from "moment";
import ReactModal from "react-modal";
import * as action from "../../../actions/EEFeedAction.js";
import FeedLiveProjects from "./FeedLiveProjects";
import swal from "sweetalert2";
import EEQuestionnaireModal from "../EEQuestionnaire/EEQUestionnaireModal";
import EmployeeDetailsAndPreferenceModal from "./EmployeeDetailsAndPreference/EmployeeDetailsAndPreferenceModal.js";
import { ic_keyboard_arrow_right } from 'react-icons-kit/md/ic_keyboard_arrow_right'
import Icon from "react-icons-kit";
import { OrgDataContext } from "../../../routes.js";
import Tour from 'reactour';
import configs from "../../../config/urlConfigs.js";
import DiscoverOpportunities from "../2-ImpactVolunteering/DiscoverOpportunities/DiscoverOpportunities" ;
import { generatePreSignedGetUrl } from "../../../s3PresignedUrls.js";
import { eraseCookie } from "../../../cookie";

const readCookie = require("../../../cookie").readCookie;
const subdomain = configs.SUBDOMAIN;
// const subdomain = window.location.hostname.split('.')[0];


const tourStyle = {
  fontSize: '18px',
  color: 'darkgrey'
};

let orgContext = null;
class Feed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bulletins: [],
      userPendingDeclarationCount: 0,
      // companyData: {},
      // userData: {},
      questionnaire: {},
      showModal: false,
      answerQuestions: false,
      questionnaireUpdated: false,
      employeePrefModal: false,
      firstLogin: false,
      discoverOpportunities: [],
      completedOpportunities: [],
      isTourOpen: false,
      totalGuestHours : '0',
      opportunityStatus:"Live",
      discoverOpportunitiesLength : 0,
      completedOpportunitiesLength: 0,
      ongoingOpportunitiesLength: 0,
      ongoingOpportunities: [],
      pledgeTakenByUser:false,
      isPledgeActive:false,
      pledgeTitle :null,
      pledgeId :null
    };


    this.steps = [
      {
        style: tourStyle,
        selector: '.ee-header-user-profile-edit',
        content: 'Click here to update your profile details and preferences.',
    },
      {
        style: tourStyle,
        selector: '#show-me-projects',
        content: 'Click here to explore volunteering opportunities for yourself.',
      },

    ];

    if (subdomain === 'indusind') {
      this.steps.push({
        style: tourStyle,
        selector: '#show-me-my-projects',
        // content: 'Click here to check the volunteering opportunities you have signed up for.',
        content: 'Click here to check the volunteering opportunities you have signed up for.',
      },
      );
    }
    else {
      this.steps.push({
        style: tourStyle,
        selector: '#recommend-an-opportunity',
        content: 'Click here to suggest an NGO or a volunteering opportunity to the administrators.'
      }, {
        style: tourStyle,
        selector: '#know-more-about-payroll-giving',
        content: 'Click here to understand or take part in the Payroll giving pledge.',
      },
      )
    }

    
  }


  

  componentDidMount() {
    this.steps.push({
      style: tourStyle,
      selector: '#projects-completed-container',
      content: 'Click to know the volunteering projects you have completed.',
    },
    {
      style: tourStyle,
      selector: '#volunteering-hours-completed-container',
      content: 'Click to know the details of your volunteering hours.',
    },);
    // const userCookie = JSON.parse(readCookie('userData'));
    let endPoint =
      // this.props.member ? `/get-user-data/${this.props.member}` :
      "/get-user-data"
    fetch(configs.BACKEND_API_URL + endPoint, {
      method: "get",
      credentials: "include",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token,
      },
    })
    .then((data) => data.json())
      .then((data) => {
        if (orgContext.eePreferences.enableEmployeeDetailsConfirmation && !data.data.preference) {
          this.setState({ firstLogin: true,});
          swal(
            {
              title: "Welcome to IndusInd Bank's Employee Volunteering Portal",
              // text: "A custom <span style='color:red;'>html</span> message.",
              html: `<p><note style='font-size:16px'>Please share your contact details. It helps us to send you information pertaining to any activity you register for.</note></p>`,
              // html: `<p>Please proceed with your one-time registration process.<br/><br/><note style='font-size:16px'>Please share your contact details. It helps us to send you information pertaining to any activity you register for.</note></p>`,
              // type: "info",
              customClass: 'swal-wide',
              // showCancelButton: true,
              showConfirmButton: true,
              confirmButtonText: 'Proceed >>'
            }
            // "You've successfully endorsed this project.",
          ).then((data) => {
            this.setState({ employeePrefModal: true })
          });

        } else {
          const RESUMPTION_URL = readCookie('RESUMPTION_URL') === '/' ? '' : readCookie('RESUMPTION_URL');
          if (RESUMPTION_URL) {
            eraseCookie('RESUMPTION_URL');
            window.location = RESUMPTION_URL;
          }
        }

      });

    let self = this;
    fetch(
      configs.BACKEND_API_URL + "/get-questionnaire-preference-summary",
      {
        method: "GET",
        credentials: "include",
        headers: {
          Auth: JSON.parse(readCookie("access_token")).access_token,
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {
          let questionnaire = {};
          let showModal =
            data.data.questionnaireEnabled &&
            !data.data.questionnaireAnswered &&
            (data.data.questionnaireMandatory ||
              JSON.parse(readCookie("skippedQuestionnairePreferences")) === undefined);
          let answerQuestions =
            data.data.questionnaireEnabled &&
            !data.data.questionnaireAnswered &&
            JSON.parse(readCookie("skippedQuestionnairePreferences")) !== undefined;
          let questionnaireUpdated =
            data.data.questionnaireEnabled &&
            data.data.questionnaireUpdatedAt >
            data.data.questionnaireAnsweredAt;
          if (showModal || answerQuestions || questionnaireUpdated)
            questionnaire = data.data.companyQuestionnaireObj;
          else {
            questionnaire = data.data.userQuestionnaireObj;
          }
          self.setState({
            questionnaire,
            showModal,
            answerQuestions,
            questionnaireUpdated,
          });
        }.bind(this)
      );

    this.handleCloseModal = this.handleCloseModal.bind(this);

    fetch(configs.BACKEND_API_URL + "/ee/volunteer-opportunity-history", {
      method: "GET",
      credentials: "include",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {
          this.props.actions.updateCountOfVos(data.liveCount);
          this.props.actions.updateCountOfCompletedVos(data.completedCount);
          this.props.actions.updateTotalHoursClocked(data.hoursClocked);
          this.setState({totalGuestHours : data?.totalGuestHours || 0 });
        }.bind(this)
      );

    fetch(
      configs.BACKEND_API_URL +
      "/ee/pending-volunteer-opportunity-declaration-count",
      {
        method: "GET",
        credentials: "include",
        headers: {
          Auth: JSON.parse(readCookie("access_token")).access_token,
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {
          this.setState({
            userPendingDeclarationCount: data.userPendingDeclarationCount,
          });
          
        }.bind(this)
      );

    fetch(configs.BACKEND_API_URL + "/get-user-stats", {
      method: "GET",
        credentials: "include",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {
          this.props.actions.updateCountOfProjects(data.countOfProjects);
          this.props.actions.updateTotalContributed(data.totalContributed);
          this.props.actions.updateTotalMatchedByCompany(
            data.totalMatchedByCompany
          );
          this.props.actions.updateTotalContributedViaPayroll(
            data.totalContributedViaPayroll
          );
          this.props.actions.updateCurrentPayrollBalance(
            data.currentPayrollBalance
          );
          this.props.actions.updateLiveVos(data.liveVos);
          this.props.actions.updateHasPledged(data.hasPledged);
        }.bind(this)
      );

    fetch(configs.BACKEND_API_URL + "/post?type=bulletin", {
      method: "GET",
        credentials: "include",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {
          this.setState({ bulletins: data.posts });
        }.bind(this)
      );

      // fetch recomended
    fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/feed/ee-discover?status=Live', {
      method: "GET",
        credentials: "include",
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token,
      },
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      this.setState({ discoverOpportunities: (data.data || []).filter(x => x.status === 'Live') });
      this.setState({discoverOpportunitiesLength : data.totalVoDataLength});
      if ((data.data || []).length) {
        this.steps.push({
        style: tourStyle,
          selector: '#explore-upcoming-opportunities',
          content: 'Click to learn more about the upcoming opportunities.'
        });
      }
    }.bind(this));
    // fetch recomended
    fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/feed/ee-discover?status=Ongoing', {
      method: "GET",
      credentials: "include",
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token,
      },
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      this.setState({ ongoingOpportunities: (data.data || []).filter(x => x.status === 'Ongoing') });
      this.setState({ ongoingOpportunitiesLength: data.totalVoDataLength });
      if ((data.data || []).length) {
        this.steps.push({
          style: tourStyle,
          selector: '#explore-ongoing-opportunities',
          content: 'Click to learn more about the ongoing opportunities.'
        });
      }
    }.bind(this));
    // fetch recomended
    fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/feed/ee-discover?status=Completed', {
      method: "GET",
        credentials: "include",
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token,
      },
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      this.setState({ completedOpportunities: (data.data || []).filter(x => x.status === 'Completed') });
      this.setState({ completedOpportunitiesLength : data.totalVoDataLength})
      if ((data.data || []).length) {
        this.steps.push({
        style: tourStyle,
          selector: '#explore-completed-opportunities',
          content: 'Click to learn more about the opportunities which just got over.'
        })
      }
    }.bind(this));

    fetch(configs.BACKEND_API_URL + '/ee/pledges/get-pledge-user', {
      method: "GET",
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token,
      },
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      this.setState({
        isPledgeActive:data.isPledgeActive,
        pledgeTakenByUser:data.pledge,
        pledgeTitle:data.pledgeTitle,
        pledgeId :data.pledgeId
      })
    }.bind(this));
  }


  handleClick(data) {
    if (data === "live-vo"){
      // window.location.pathname = "/discover-opportunities";
      this.props.history.push("/discover-opportunities", { status: 'Live'}); 
    }
      if (data === "completed-vo"){
      this.props.history.push("/discover-opportunities", { status: 'Completed'}); 
        // window.location.pathname = "/discover-opportunities";
      }
      // ?status=Completed";
    else if (data === "vo-hist")
      window.location.pathname = "/my-volunteer-history";
    else if (data === "live-giving")
      window.location.pathname = "/endorse-projects";
    else if (data === "pro-hist")
      window.location.pathname = "/my-endorsement-history";
    else if (data === "show-me-projects")
      window.location.pathname = "/discover-opportunities";
    else if (data === "endorse-projects")
      window.location.pathname = "/endorse-projects";
    else if (data === "recommend-an-opportunity")
      window.location.pathname = "/recommend-an-opportunity";
    else if (data === "payroll-giving")
      window.location.pathname = "/payroll-giving";
    else if (data === "profile")
      window.location.pathname = "/profile";
    else if (data === 'my-volunteer-history')
      window.location.pathname = "/my-volunteer-history";

    // else if (data === 'give-feedback') window.location.pathname = '/discover-opportunities';
  }
  handleCloseModal() {
    this.setState({ showModal: false });
  }

  closePreferenceModal() {
    this.setState({ employeePrefModal: false, isTourOpen: true });
  }

  render() {
    if (this.props.countOfVos >= 0) {
      return (
        <OrgDataContext.Consumer>
          {(organisationData) => {
            orgContext = organisationData;
            return (<div className="ee-page-container feed-page">
              <Tour
                steps={this.steps}
                
                showCloseButton={!this.state.firstLogin}
                className="react-tour"
                accentColor="var(--themeColor)"
                badgeContent={(curr, tot) => `${curr} of ${tot}`}
                closeWithMask
                isOpen={this.state.isTourOpen && !this.state.employeePrefModal}
                onRequestClose={() => { this.setState({ isTourOpen: false }) }} />
              <ReactModal
                isOpen={this.state.showModal}
                contentLabel="onRequestClose Example"
                onRequestClose={this.handleCloseModal}
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEsc={false}
              >
                {this.state.questionnaireUpdated &&
                  !this.state.questionnaire.mandatory && (
                    <button
                      className="btn btn-close-modal"
                      onClick={this.handleCloseModal}
                    >
                      <i className="fa fa-close"></i>
                    </button>
                  )}
                <div className="container-fluid">
                  <EEQuestionnaireModal
                    questionnaire={this.state.questionnaire}
                  // userData={this.state.userData}
                  // companyData={this.state.companyData}
                  />
                </div>
              </ReactModal>
              <Row className={false && this.props.hasPledged ? "balance-strip" : ""}>
                {this.state.userPendingDeclarationCount ? (
                  <Col
                    md={12}
                    className="pending-volunteer-opportunity-declaration-container"
                  >
                    <div className="pending-volunteer-opportunity-declaration">
                      <span>
                        You are yet to declare your attendance for{" "}
                        {this.state.userPendingDeclarationCount} event(s) you had
                        signed up for.
                      </span>
                      <button
                        className="btn mark-attendance-btn"
                        onClick={() =>
                          (window.location.pathname = "/my-volunteer-history")
                        }
                      >
                        Mark Attendance
                      </button>
                    </div>
                  </Col>
                ) : null}
                {this.state.answerQuestions !== undefined &&
                  this.state.answerQuestions && (
                    <Col md={12} className="feedpage-questionnaire-alert-container">
                      <div className="feedpage-questionnaire-alert">
                        <span>
                          You have not taken the volunteering preference survey yet!
                        </span>
                        <button
                          type="button"
                          className="btn mark-attendance-btn"
                          onClick={() => {
                            this.setState({ showModal: true });
                          }}
                        >
                          TAKE THE SURVEY
                        </button>{" "}
                      </div>
                    </Col>
                  )}
                {this.state.answerQuestions !== undefined &&
                  !this.state.answerQuestions &&
                  this.state.questionnaireUpdated && (
                    <Col md={12} className="feedpage-questionnaire-alert-container">
                      <div className="feedpage-questionnaire-alert">
                        <span>
                          Your organisation might have updated the volunteering
                          preference questionnaire!
                        </span>
                        <button
                          type="button"
                          className="btn mark-attendance-btn"
                          onClick={() => {
                            this.setState({ showModal: true });
                          }}
                        >
                          COMPLETE ADDING YOUR PREFERENCE
                        </button>{" "}
                      </div>
                    </Col>
                  )}
                  {this.state.isPledgeActive ?  this.state.pledgeTakenByUser ? null
                // <Col
                //     md={12}
                //     className="pending-volunteer-opportunity-declaration-container"
                //   >
                //     <div className="pending-volunteer-opportunity-declaration">
                //       <span>
                //       {`Thank you for taking the pledge- ${this.state.pledgeTitle}`}
                //       </span>
                //     </div>
                //   </Col>
                :
                // <Col md={12} className="welcome-user-container" style={{marginTop:10,marginBottom:10}}>
                // <div>
                //     <a className="welcome" onClick={() =>
                //           (window.location.pathname = `/pledges/${this.state.pledgeId}`)
                //         }
                //         >
                //           {`${this.state.pledgeTitle} - Please take this pledge now`}
                //         {/* Pledge to Save Water - Please take this pledge now  */}
                     
                //     </a>
                    
                //   </div>
                // </Col> 
                <Col
                    md={12}
                    className="pending-volunteer-opportunity-declaration-container"
                  >
                    <div className="pending-volunteer-opportunity-declaration feed-pledge-container">
                      <span className="feed-pledge-title">
                      {`${this.state.pledgeTitle}`}
                      </span>
                      <button
                        className="btn feed-pledge-button"
                        onClick={() =>
                          (window.location.pathname = `/pledges/${this.state.pledgeId}`)
                        }
                      >
                        {`Please take this pledge now`}
                      </button>
                    </div>
                  </Col>
                : null}
                <Col md={12} className="welcome-user-container">
                  <img src="/img/icons/clapping.png" />
                  <div>
                    <span className="welcome">
                      Welcome, {JSON.parse(readCookie("userData")).fullName}
                    </span>
                    <span className="quote">
                      Here's a glimpse of what we have in store for you.
                    </span>
                  </div>
                </Col>
                <Col md={12} className="dashboard-motivational-elements">
                  <Col id="show-me-projects" className="dashboard-motivational-elements-1" md={subdomain==='indusind'?6:4}>
                    <img
                      className="ibl-feed-icon dashboard-motivational-elements-1-img"
                      // src="/img/volunteer.png"
                      height="90px" width="100px"
                      src="/img/IBL/volunteer.png"
                      alt=""
                    />
                    <p className="dashboard-motivational-elements-1-p">
                      I am all set to volunteer for the next upcoming event.
                    </p>
                    <button
                      className="btn btn-primary"
                      onClick={this.handleClick.bind(this, "show-me-projects")}
                    >
                      Show me projects
                    </button>
                  </Col>

                  {subdomain !== 'indusind' ? <Col className="dashboard-motivational-elements-3" id="recommend-an-opportunity" md={4}>
                    <img
                      className="ibl-feed-icon dashboard-motivational-elements-3-img"
                      height="90px" width="100px"
                      src="/img/IBL/raised.png"
                      // src="/img/raised.png"
                      alt=""
                    />
                    <p className="dashboard-motivational-elements-3-p">
                      I want to recommend an opportunity / partner.
                    </p>
                    <button
                      className="btn btn-primary"
                      onClick={this.handleClick.bind(this, "recommend-an-opportunity")}
                    >
                      Recommend an opportunity
                    </button>
                  </Col> : <Col id='show-me-my-projects' className="dashboard-motivational-elements-3" md={6}>
                    <img
                      className="ibl-feed-icon dashboard-motivational-elements-3-img"
                      height="90px" width="100px"
                      src="/img/IBL/raised.png"
                      // src="/img/raised.png"
                      alt=""
                    />
                    <p className="dashboard-motivational-elements-3-p">
                        I want to view my opportunities.
                    </p>
                    <button
                      className="btn btn-primary"
                        onClick={this.handleClick.bind(this, "my-volunteer-history")}
                    >
                      View my opportunities
                    </button>
                  </Col>}

                  {subdomain !== 'indusind' ? (organisationData.eePreferences.enablePayrollGiving
                    ? <Col id="know-more-about-payroll-giving" className="dashboard-motivational-elements-2" md={4}>
                      <img
                        className="ibl-feed-icon dashboard-motivational-elements-2-img"
                        height="90px" width="100px"
                        src="/img/IBL/contribute.png"
                        // src="/img/contribute.png"
                        alt=""
                      />
                      <p className="dashboard-motivational-elements-2-p">
                        I want to know more about Payroll Giving pledge.{" "}
                      </p>
                      <button
                        className="btn btn-primary"
                        onClick={this.handleClick.bind(this, "payroll-giving")}
                      >
                        Know More
                      </button>
                    </Col>
                    : organisationData.eePreferences.enableGiveBack
                      ? <Col className="dashboard-motivational-elements-2" md={4}>
                        <img
                          className="dashboard-motivational-elements-2-img"
                          height="90px" width="100px"
                          src="/img/IBL/contribute.png"
                        // src="/img/contribute.png"                          alt=""
                        />
                        <p className="dashboard-motivational-elements-2-p">
                          Let me contribute to fundraisers of Social organizations.
                        </p>
                        <button
                          className="btn btn-primary"
                          onClick={this.handleClick.bind(this, "endorse-projects")}
                        >
                          Contribute to projects
                        </button>
                      </Col>
                      : <Col className="dashboard-motivational-elements-2" md={4}>
                        <img
                          className="dashboard-motivational-elements-2-img"
                          height="90px" width="100px"
                          src="/img/IBL/care.png"
                          // src="/img/passion.png"
                          alt=""
                        />
                        <p className="dashboard-motivational-elements-2-p">
                          I want to update my details, skills and preferences.{" "}
                        </p>
                        <button
                          className="btn btn-primary"
                          onClick={this.handleClick.bind(this, "profile")}
                        >
                          Update Preferences
                        </button>
                      </Col>): null}

                  {/* <Col className="dashboard-motivational-elements-3" md={4}>
                <img
                  className="dashboard-motivational-elements-3-img"
                  src="/img/raised.png"
                  alt=""
                />
                <p className="dashboard-motivational-elements-3-p">
                  I am still looking for the right opportunity for me.
                </p>
                <button
                  className="dashboard-motivational-elements-3-button"
                  onClick={this.handleClick.bind(this, "give-feedback")}
                >
                  Give feedback
                </button>
              </Col> */}
                </Col>
              </Row>

              {/* <Col md={12} className="mb20">
              <h2 className="ee-page-title mt20">Your Volunteering Snapshot</h2>
            </Col> */}
              <Row style={{ marginTop: 30 }}>
                <Col md={4}>
                  <h2 className="ee-page-title mt20" style={{ textAlign: 'left' }}>Your volunteering snapshot</h2>
                </Col>

                <Col md={4}>
                  {/* <div className="card shadow-lg">
                <div className="card-body">
                  <h5 className="card-title">Card title</h5>
                  <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6>
                  <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                </div>
              </div> */}
                  <div className="right-txt-wrp" onClick={this.handleClick.bind(this, "vo-hist")} id="projects-completed-container">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {/* <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/trophy.png" /> */}
                      <img style={{ height: '65px', width: '75px', margin: 10, color: 'grey' }} src="/img/IBL/trophy.png" />
                      <div style={{ marginLeft: 10 }}>
                        <p className="right-txt-wrp-title" style={{ marginTop: 10 }}>
                          {this.props.countOfCompletedVos}
                        </p>
                        <p className="dashboard-motivational-elements-2-p">Projects Completed</p>
                      </div>
                    </div>
                    <div className="row align-items-center">


                      {/* <div className="col-12 col-sm-6 mb-sm-0 mb-2">
                    <a data-toggle="modal" data-target="#bod_profile6" className="w-100 btn py-2 btn-sm btn-primary card-btn text-uppercase">Explore more</a>
                  </div> */}


                    </div>
                  </div>
                  {/* <div
                className="single-stat recommendation mb10"
                onClick={this.handleClick.bind(this, "vo-hist")}
              >
                <img src="/img/icons/trophy.png" />
                <div>
                  <span>{this.props.countOfCompletedVos}</span>
                  <span>Projects Completed</span>
                </div>
              </div> */}
                </Col>

                <Col md={4}>
                  <div className="right-txt-wrp-padding"  onClick={this.handleClick.bind(this, "vo-hist")} id="volunteering-hours-completed-container">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <img style={{ height: '65px', width: '75px', marginLeft: 13,marginTop :19, color: 'grey' }} src="/img/IBL/clock.png" />
                      {/* <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/clock.png" /> */}
                      <div style={{ marginLeft: 10 }}>
                        <p className="right-txt-wrp-title" style={{ marginTop: 10 }}>
                        {(this.props.totalHoursClocked.toString().length < 2 ? ('0' + Number.parseInt(this.props.totalHoursClocked) +
                         ' hours ' +  ('0' + Number.parseInt(this.props.totalHoursClocked*60)%60).slice(-2) + ' minutes ' )
                         : ('' + Number.parseInt(this.props.totalHoursClocked)) + ' hours '  + ('0' + Number.parseInt(this.props.totalHoursClocked*60)%60).slice(-2) +' minutes ' )}
                         {/* { moment().hours(Number.parseInt(this.props.totalHoursClocked.toString())).
                          minutes((this.props.totalHoursClocked.toString()*60) %60 ).format("HH:mm").split(':')[0] + ' hours ' 
                          + moment().hours(Number.parseInt(this.props.totalHoursClocked.toString())).
                          minutes((this.props.totalHoursClocked.toString()*60)%60).format("HH:mm").split(':')[1] + ' minutes '} */}
                        </p>
                        <p className="dashboard-motivational-elements-2-p"> My Volunteering Hours</p>
                        <p className="right-txt-wrp-title" style={{ marginTop: 10 }}>
                        {(this.state.totalGuestHours?.toString().length < 2 ? ('0' + Number.parseInt(this.state.totalGuestHours) +
                         ' hours ' +  ('0' + Number.parseInt(this.state.totalGuestHours*60)%60).slice(-2) + ' minutes ' )
                         : ('' + Number.parseInt(this.state.totalGuestHours)) + ' hours '  + ('0' + Number.parseInt(this.state.totalGuestHours*60)%60).slice(-2) +' minutes ' )}
                         {/* {(Number.parseInt(this.state.totalGuestHours).length<2 ? '0' + Number.parseInt(this.state.totalGuestHours) + ' hours ' 
                         : '' + Number.parseInt(this.state.totalGuestHours)) + ' hours '  + ('0' + Number.parseInt(this.state.totalGuestHours*60)%60).slice(-2) +' minutes ' } */}


                         {/* {`${Number.parseInt(this.state.totalGuestHours)}:${Number.parseInt(this.state.totalGuestHours*60)%60}`} */}
                        {/* //  . */}
                        {/* //   minutes((this.state.totalGuestHours.toString()*60)%60).format("HH:mm").split(':')[0] */}
                          {/* } */}
                          {/* <br/> */}
                         {/* { moment().hours(Number.parseInt(this.state.totalGuestHours.toString())).
                          minutes((this.state.totalGuestHours.toString()*60)%60).format("HH:mm").split(':')[0] + ' hours ' 
                          + moment().hours(Number.parseInt(this.state.totalGuestHours.toString())).
                          minutes((this.state.totalGuestHours.toString()*60)%60).format("HH:mm").split(':')[1] + ' minutes '} */}
                        </p>
                        <p className="dashboard-motivational-elements-2-p"> My Guest Volunteering Hours</p>
                      </div>
                    </div>
                    {/* <Row>
                  <Col md={2} sm={3} xs={3}>
                    <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/clock.png" />
                  </Col>
                  <Col md={10} sm={9} xs={9}>
                    <p className="right-txt-wrp-title" style={{ marginTop: 10 }}>
                      {this.props.totalHoursClocked}
                    </p>
                    <p className="dashboard-motivational-elements-2-p"> Hours Clocked</p>
                  </Col>
                </Row> */}
                    <div className="row align-items-center">
                    </div>
                  </div>
                  {/* <div
                className="single-stat recommendation mb10"
                onClick={this.handleClick.bind(this, "vo-hist")}
              >
                <img src="/img/icons/clock.png" />
                <div>
                  <span>{this.props.totalHoursClocked}</span>
                  <span>Hours Clocked</span>
                </div>
              </div> */}
                </Col>

                {/*this.props.hasPledged ? (
							<div className="balance-banner">
								As a subscriber of Payroll Giving, you have <span className="amount-span">INR {this.props.currentPayrollBalance}</span> yet to be contributed to impactful projects. Start Allocating now!
							</div>
						) : (null)*/}
              </Row>

              {this.props.liveVos.length && false ? (
                <FeedLiveProjects liveVos={this.props.liveVos} />
              ) : null}

              {subdomain!=='indusind' && (organisationData.eePreferences.enableGiveBack || organisationData.eePreferences.enablePayrollGiving) ? (
                <Row style={{ marginTop: 15 }}>
                  <Col md={4}>
                    <h2 className="ee-page-title mt20" style={{ textAlign: 'left' }}>Your giving snapshot</h2>
                  </Col>

                  <Col md={4}>
                    <div className="right-txt-wrp" onClick={this.handleClick.bind(this, "pro-hist")}>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <img style={{ height: '65px', width: '75px', margin: 10, color: 'grey' }} src="/img/IBL/care.png" />
                        {/* <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/care.png" /> */}
                        <div style={{ marginLeft: 10 }}>
                          <p className="right-txt-wrp-title" style={{ marginTop: 10 }}>
                            INR {this.props.totalContributed}
                          </p>
                          <p className="dashboard-motivational-elements-2-p">Contributed</p>
                        </div>
                      </div>
                      {/* <Row>
                    <Col md={2} sm={3} xs={3}>
                      <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/care.png" />
                    </Col>
                    <Col md={10} sm={9} xs={9}>
                      <p className="right-txt-wrp-title" style={{ marginTop: 10 }}>
                        INR {this.props.totalContributed}
                      </p>
                      <p className="dashboard-motivational-elements-2-p">Contributed</p>
                    </Col>
                  </Row> */}
                      <div className="row align-items-center">
                      </div>
                    </div>
                    {/* <div
                  className="single-stat recommendation mb10"
                  onClick={this.handleClick.bind(this, "pro-hist")}
                >
                  <img src="/img/icons/care.png" />
                  <div>
                    <span>INR {this.props.totalContributed}</span>
                    <span>Contributed</span>
                  </div>
                </div> */}
                  </Col>

                  {this.props.totalMatchedByCompany !== 0 &&
                    this.props.totalMatchedByCompany !== null ? (
                    <Col md={4}>
                      <div className="right-txt-wrp" onClick={this.handleClick.bind(this, "pro-hist")}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/donation.png" />
                          <div>
                            <p className="right-txt-wrp-title" style={{ marginTop: 10, marginLeft: 10 }}>
                              INR {this.props.totalMatchedByCompany}
                            </p>
                            <p className="dashboard-motivational-elements-2-p">Matched By Company</p>
                          </div>
                        </div>
                        {/* <Row>
                      <Col md={2} sm={3} xs={3}>
                        <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/donation.png" />
                      </Col>
                      <Col md={10} sm={9} xs={9}>
                        <p className="right-txt-wrp-title" style={{ marginTop: 10 }}>
                          INR {this.props.totalMatchedByCompany}
                        </p>
                        <p className="dashboard-motivational-elements-2-p">Matched By Company</p>
                      </Col>
                    </Row> */}
                        <div className="row align-items-center">
                        </div>
                      </div>
                      {/* <div
                    className="single-stat recommendation mb10"
                    onClick={this.handleClick.bind(this, "pro-hist")}
                  >
                    <img src="/img/icons/donation.png" />
                    <div>
                      <span>INR {this.props.totalMatchedByCompany}</span>
                      <span>Matched By Company</span>
                    </div>
                  </div> */}
                    </Col>
                  ) : null}
                </Row>
              ) : null}

              {this.state.ongoingOpportunities.length ? <span id="explore-ongoing-opportunities">
                <Row>
                  <Col md={12} style={{ marginBottom: 20, }}>
                    {/* <h2 className="ee-page-title mt20" style={{ textAlign: 'left' }}>Recommended for you {this.state.ongoingOpportunities.length && <span onClick={this.handleClick.bind(this, 'live-vo')}><a onClick={this.handleClick.bind(this, 'live-vo')} className="recomended-opportunities-link">({`Show all ${this.state.ongoingOpportunities.length} opportunities`})</a></span>}</h2> */}
                    <h2 className="ee-page-title mt20" style={{ textAlign: 'left' }}>Explore ongoing opportunities {this.state.ongoingOpportunities.length && <span onClick={this.handleClick.bind(this, 'live-vo')}><a onClick={this.handleClick.bind(this, 'live-vo')} className="recomended-opportunities-link">({`Show all ${this.state.ongoingOpportunitiesLength} opportunities`})</a></span>}</h2>
                  </Col>
                </Row>
                <Row>
                  {this.state.ongoingOpportunities.map((opp, index) => index < 3 &&
                    <Col md={4} lg={4} sm={12} xs={12}
                      style={{ marginBottom: 10, cursor: 'pointer' }}
                      key={opp.id}
                      onClick={() => window.location.pathname = "/opportunity/" + opp.customUrl}
                    >
                      <div className="card-recomended" style={{ minHeight: 335 }}>
                        <div className="volunteer current-state"><span className="current-state-outer ongoing"><span className="outer-arrow"> </span><span className="current-state-text">{opp.status === 'Live' ? 'Ongoing' : opp.status}</span></span></div>
                        <img className="card-img-top recomended-opportunity-card-img" src={generatePreSignedGetUrl(opp.image)} alt={opp.title} />
                        <div className="card-body" style={{ padding: '16px' }}>
                          <p className='recomended-opportunity-card-title' style={{ fontWeight: 'bold' }}>
                            {opp.title}
                          </p>
                          <div
                            className="ql-editor card-text recomended-opportunity-card-des"
                            style={{
                                                
                                  color: "#707070",
                                  display: '-webkit-box',
                                  WebkitLineClamp: '3',
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxHeight: '60px',
                                  padding: '0px'
                            }}
                            dangerouslySetInnerHTML={{
                              __html: opp.description,
                            }}
                          />
                          {/* <p style={{ color: 'black', fontWeight: 100 }} className="card-text recomended-opportunity-card-des" >
                            {opp.description}
                          </p> */}
                          <div className="ee-location-date-section" style={{ color: 'var(--themeColor)', marginTop: '10px' }}>
                            <span className="ee-location recommend-opportunity-location"><i fill="currentColor" className="pad-right-5" style={{ display: 'inline-block' }}>
                              <svg fill="currentColor" height="16" width="16" viewBox="0 0 1408 1792" style={{ display: 'inline-block', verticalalign: 'middle' }}>
                                <path d="M1401 349l-640 1280q-17 35-57 35-5 0-15-2-22-5-35.5-22.5t-13.5-39.5v-576h-576q-22 0-39.5-13.5t-22.5-35.5 4-42 29-30l1280-640q13-7 29-7 27 0 45 19 15 14 18.5 34.5t-6.5 39.5z"></path></svg>
                            </i><span>{opp.city}</span></span>
                            {/* </i><span>{opp.address1}</span></span> */}
                            {/* <br /> */}
                            <span className="ee-event-time"><i fill="currentColor" className="pad-right-5" style={{ display: 'inline-block' }}>
                              <svg fill="currentColor" height="16" width="16" viewBox="0 0 1536 1792" style={{ display: 'inline-block', verticalalign: 'middle' }}>
                                <path d="M896 544v448q0 14-9 23t-23 9h-320q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h224v-352q0-14 9-23t23-9h64q14 0 23 9t9 23zM1312 896q0-148-73-273t-198-198-273-73-273 73-198 198-73 273 73 273 198 198 273 73 273-73 198-198 73-273zM1536 896q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path></svg></i>
                              <span>{moment(opp.date, "DD/MM/YYYY").format('ddd, DD MMM YYYY')}</span></span></div>
                          <div className="card-recomended-read-more-button">
                            <p>Read More</p>
                            <Icon icon={ic_keyboard_arrow_right}
                              className='chevron-icon'
                            />
                            {/* Add Text */}
                          </div>
                        </div>
                      </div>
                    </Col>)}
                </Row>
              </span> : null}
              {this.state.discoverOpportunities.length ? <span id="explore-upcoming-opportunities">
                <Row>
                  <Col  md={12} style={{ marginBottom: 20, }}>
                    {/* <h2 className="ee-page-title mt20" style={{ textAlign: 'left' }}>Recommended for you {this.state.discoverOpportunities.length && <span onClick={this.handleClick.bind(this, 'live-vo')}><a onClick={this.handleClick.bind(this, 'live-vo')} className="recomended-opportunities-link">({`Show all ${this.state.discoverOpportunities.length} opportunities`})</a></span>}</h2> */}
                    <h2 className="ee-page-title mt20" style={{ textAlign: 'left' }}>Explore upcoming opportunities {this.state.discoverOpportunities.length && <span onClick={this.handleClick.bind(this, 'live-vo')}><a onClick={this.handleClick.bind(this, 'live-vo')} className="recomended-opportunities-link">({`Show all ${this.state.discoverOpportunitiesLength} opportunities`})</a></span>}</h2>
                  </Col>
                </Row>
                <Row>
                  {this.state.discoverOpportunities.map((opp, index) => index < 3 &&
                    <Col md={4} lg={4} sm={12} xs={12}
                      style={{ marginBottom: 10, cursor: 'pointer' }}
                      key={opp.id}
                      onClick={() => window.location.pathname = "/opportunity/" + opp.customUrl}
                    >
                      <div className="card-recomended" style={{ minHeight: 335 }}>
                        <div className="volunteer current-state"><span className="current-state-outer upcoming"><span className="outer-arrow"> </span><span className="current-state-text">{opp.status === 'Live' ? 'Upcoming' : opp.status}</span></span></div>
                        <img className="card-img-top recomended-opportunity-card-img" src={generatePreSignedGetUrl(opp.image)} alt={opp.title} />
                        <div className="card-body" style={{ padding: '16px' }}>
                          <p className='recomended-opportunity-card-title' style={{ fontWeight: 'bold' }}>
                            {opp.title}
                          </p>
                          <div
                            className="ql-editor card-text recomended-opportunity-card-des"
                            style={{
                                                
                                  color: "#707070",
                                  display: '-webkit-box',
                                  WebkitLineClamp: '3',
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxHeight: '60px',
                                  padding: '0px'
                            }}
                            dangerouslySetInnerHTML={{
                              __html: opp.description,
                            }}
                          />
                          {/* <p style={{ color: 'black', fontWeight: 100 }} className="card-text recomended-opportunity-card-des" >
                            {opp.description}
                          </p> */}
                          <div className="ee-location-date-section" style={{ color: 'var(--themeColor)', marginTop: '10px' }}>
                            <span className="ee-location recommend-opportunity-location"><i fill="currentColor" className="pad-right-5" style={{ display: 'inline-block' }}>
                              <svg fill="currentColor" height="16" width="16" viewBox="0 0 1408 1792" style={{ display: 'inline-block', verticalalign: 'middle' }}>
                                <path d="M1401 349l-640 1280q-17 35-57 35-5 0-15-2-22-5-35.5-22.5t-13.5-39.5v-576h-576q-22 0-39.5-13.5t-22.5-35.5 4-42 29-30l1280-640q13-7 29-7 27 0 45 19 15 14 18.5 34.5t-6.5 39.5z"></path></svg>
                            </i><span>{opp.city}</span></span>
                          {/* </i><span>{opp.address1}</span></span> */}
                            {/* <br /> */}
                            <span className="ee-event-time"><i fill="currentColor" className="pad-right-5" style={{display: 'inline-block'}}>
                              <svg fill="currentColor" height="16" width="16" viewBox="0 0 1536 1792" style={{ display: 'inline-block', verticalalign: 'middle' }}>
                                <path d="M896 544v448q0 14-9 23t-23 9h-320q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h224v-352q0-14 9-23t23-9h64q14 0 23 9t9 23zM1312 896q0-148-73-273t-198-198-273-73-273 73-198 198-73 273 73 273 198 198 273 73 273-73 198-198 73-273zM1536 896q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path></svg></i>
                              <span>{moment(opp.date, "DD/MM/YYYY").format('ddd, DD MMM YYYY')}</span></span></div>
                          <div className="card-recomended-read-more-button">
                            <p>Read More</p>
                            <Icon icon={ic_keyboard_arrow_right}
                              className='chevron-icon'
                            />
                            {/* Add Text */}
                          </div>
                        </div>
                        </div>
                    </Col>)}
                </Row>
              </span> : null}



              {this.state.completedOpportunities.length ? <span id="explore-completed-opportunities">
                <Row>
                  <Col md={12}  style={{ marginBottom: 20, }}>
                    {/* <h2 className="ee-page-title mt20" style={{ textAlign: 'left' }}>Recommended for you {this.state.discoverOpportunities.length && <span onClick={this.handleClick.bind(this, 'live-vo')}><a onClick={this.handleClick.bind(this, 'live-vo')} className="recomended-opportunities-link">({`Show all ${this.state.discoverOpportunities.length} opportunities`})</a></span>}</h2> */}
                    <h2 className="ee-page-title mt20" style={{ textAlign: 'left' }}>Explore completed opportunities {this.state.completedOpportunities.length && <span onClick={this.handleClick.bind(this, 'completed-vo')}><a onClick={this.handleClick.bind(this, 'live-vo')} className="recomended-opportunities-link">({`Show all ${this.state.completedOpportunitiesLength} opportunities`})</a></span>}</h2>
                  </Col>
                </Row>
                <Row>
                  {this.state.completedOpportunities.map((opp, index) => index < 3 &&
                    <Col md={4} lg={4} sm={12} xs={12}
                      style={{ marginBottom: 10, cursor: 'pointer' }}
                      key={opp.id}
                      onClick={() => window.location.pathname = "/opportunity/" + opp.customUrl}
                    >
                      <div className="card-recomended" style={{ minHeight: 335 }}>
                        <div className="volunteer current-state"><span className="current-state-outer live"><span className="outer-arrow"> </span><span className="current-state-text">{opp.status === 'Live' ? 'Upcoming' : opp.status}</span></span></div>
                        <img className="card-img-top recomended-opportunity-card-img" src={generatePreSignedGetUrl(opp.image)} alt={opp.title} />
                        <div className="card-body" style={{ padding: '16px' }}>
                          <p className='recomended-opportunity-card-title' style={{ fontWeight: 'bold' }}>
                            {opp.title}
                          </p>
                          <div
                            className="ql-editor card-text recomended-opportunity-card-des"
                            style={{
                                                
                                  color: "#707070",
                                  display: '-webkit-box',
                                  WebkitLineClamp: '3',
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxHeight: '60px',
                                  padding: '0px'
                            }}
                            dangerouslySetInnerHTML={{
                              __html: opp.description,
                            }}
                          />
                          {/* <p style={{ color: 'black', fontWeight: 100 }} className="card-text recomended-opportunity-card-des" >
                            {opp.description}
                          </p> */}
                          <div className="ee-location-date-section" style={{ color: 'var(--themeColor)', marginTop: '10px' }}>
                            <span className="ee-location recommend-opportunity-location"><i fill="currentColor" className="pad-right-5" style={{ display: 'inline-block' }}>
                              <svg fill="currentColor" height="16" width="16" viewBox="0 0 1408 1792" style={{ display: 'inline-block', verticalalign: 'middle' }}>
                                <path d="M1401 349l-640 1280q-17 35-57 35-5 0-15-2-22-5-35.5-22.5t-13.5-39.5v-576h-576q-22 0-39.5-13.5t-22.5-35.5 4-42 29-30l1280-640q13-7 29-7 27 0 45 19 15 14 18.5 34.5t-6.5 39.5z"></path></svg>
                            {/* </i><span>{opp.address1}</span></span> */}
                          </i><span>{opp.city}</span></span>
                            {/* <br /> */}
                            <span className="ee-event-time"><i fill="currentColor" className="pad-right-5" style={{ display: 'inline-block' }}>
                              <svg fill="currentColor" height="16" width="16" viewBox="0 0 1536 1792" style={{ display: 'inline-block', verticalalign: 'middle' }}>
                                <path d="M896 544v448q0 14-9 23t-23 9h-320q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h224v-352q0-14 9-23t23-9h64q14 0 23 9t9 23zM1312 896q0-148-73-273t-198-198-273-73-273 73-198 198-73 273 73 273 198 198 273 73 273-73 198-198 73-273zM1536 896q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path></svg></i>
                              <span>{moment(opp.date, "DD/MM/YYYY").format('ddd, DD MMM YYYY')}</span></span></div>
                          <div className="card-recomended-read-more-button">
                            <p>Read More</p>
                            <Icon icon={ic_keyboard_arrow_right}
                              className='chevron-icon'
                            />
                          </div>
                        </div>
                      </div>
                    </Col>)}
                </Row>
              </span> : null}

              {this.state.bulletins.length ? (
                <Row>
                  <Col md={12} className="mb10">
                    <h2 className="ee-page-title mt20" style={{ textAlign: 'left' }}>News and updates</h2>
                  </Col>

                  <Col md={12} className="bulletins-container">
                    {this.state.bulletins.map(
                      function (bulletin, index) {
                        var text = bulletin.text;
                        var exp =
                          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
                        var text1 = text.replace(
                          exp,
                          "<a target='_blank' href='$1'>$1</a>"
                        );
                        var exp2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
                        var html = text1.replace(
                          exp2,
                          '$1<a target="_blank" href="http://$2">$2</a>'
                        );
                        return (
                          <Col md={12} key={index} className="bulletin-container">
                            <div className="bulletin-color">
                              <div
                                style={{
                                  backgroundColor: bulletin.postCategory.color,
                                }}
                              ></div>
                            </div>
                            <div className="bulletin-details">
                              <span>
                                {moment(bulletin.createdAt).format("DD MMM, YYYY") +
                                  " (" +
                                  bulletin.postCategory.name +
                                  ")"}
                              </span>
                              <div
                                className="desc"
                                dangerouslySetInnerHTML={{ __html: html }}
                              ></div>
                            </div>
                            <div className="bulletin-images">
                              {bulletin.images.map(function (image, imageIndex) {
                                return (
                                  <img
                                    className="bulletin-image"
                                    src={image}
                                    key={imageIndex}
                                  />
                                );
                              })}
                            </div>
                          </Col>
                        );
                      }.bind(this)
                    )}
                  </Col>
                </Row>
              ) : null}
              <EmployeeDetailsAndPreferenceModal firstLogin={this.state.firstLogin} show={this.state.employeePrefModal} close={() => this.closePreferenceModal()} />
            </div>)
          }
          }
          </OrgDataContext.Consumer>
      );
    } else {
      return null;
    }
  }
}

Feed.propTypes = {
  countOfVos: PropTypes.number,
  countOfCompletedVos: PropTypes.number,
  totalHoursClocked: PropTypes.number,
  countOfProjects: PropTypes.number,
  totalContributed: PropTypes.number,
  totalMatchedByCompany: PropTypes.number,
  totalContributedViaPayroll: PropTypes.number,
  currentPayrollBalance: PropTypes.number,
  liveVos: PropTypes.array,
  hasPledged: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    countOfVos: state.EEFeed.countOfVos,
    countOfCompletedVos: state.EEFeed.countOfCompletedVos,
    totalHoursClocked: state.EEFeed.totalHoursClocked,
    countOfProjects: state.EEFeed.countOfProjects,
    totalContributed: state.EEFeed.totalContributed,
    totalMatchedByCompany: state.EEFeed.totalMatchedByCompany,
    totalContributedViaPayroll: state.EEFeed.totalContributedViaPayroll,
    currentPayrollBalance: state.EEFeed.currentPayrollBalance,
    liveVos: state.EEFeed.liveVos,
    hasPledged: state.EEFeed.hasPledged,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(action, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Feed);
