export const updateSelectedProject = (selectedProject) => {
	return {
		selectedProject,
		type: 'UPDATE_SELECTED_PROJECT'
	}
};

export const updatePageNum = (pageNum) => {
	return {
		pageNum,
		type: 'UPDATE_PAGENUM'
	}
};

export const updatePaymentType = (paymentType) => {
	return {
		paymentType,
		type: 'UPDATE_PAYMENT_TYPE'
	}
};

export const updateEndorseType = (endorseType) => {
	return {
		endorseType,
		type: 'UPDATE_ENDORSE_TYPE'
	}
};

export const updateEndorseUnit = (endorseUnit) => {
	return {
		endorseUnit,
		type: 'UPDATE_ENDORSE_UNIT'
	}
};

export const updateEndorseUnitAmount = (endorseUnitAmount) => {
	return {
		endorseUnitAmount,
		type: 'UPDATE_ENDORSE_UNIT_AMOUNT'
	}
};

export const updateEndorseAmount = (endorseAmount) => {
	return {
		endorseAmount,
		type: 'UPDATE_ENDORSE_AMOUNT'
	}
};

export const updateEndorseReasonText = (endorseReasonText) => {
	return {
		endorseReasonText,
		type: 'UPDATE_ENDORSE_REASON_TEXT'
	}
};

export const updateAnonymous = (anonymous) => {
	return {
		anonymous,
		type: 'UPDATE_ANONYMOUS'
	}
};

export const updateUserData = (userData) => {
	return {
		userData,
		type: 'UPDATE_USER_DATA'
	}
};

export const updateUsePayroll = (usePayroll) => {
	return {
		usePayroll,
		type: 'UPDATE_USE_PAYROLL'
	}
};

export const updateUsePayrollAmount = (usePayrollAmount) => {
	return {
		usePayrollAmount,
		type: 'UPDATE_USE_PAYROLL_AMOUNT'
	}
};

export const updatePayCompleteWithPayroll = (payCompleteWithPayroll) => {
	return {
		payCompleteWithPayroll,
		type: 'UPDATE_PAY_COMPLETE_WITH_PAYROLL'
	}
};

export const updateFinalPayableAmount = (finalPayableAmount) => {
	return {
		finalPayableAmount,
		type: 'UPDATE_FINAL_PAYABLE_AMOUNT'
	}
};

// Form Actions
export const updateFirstName = (firstName) => {
	return {
		firstName,
		type: 'UPDATE_FIRST_NAME'
	}
};

export const updateLastName = (lastName) => {
	return {
		lastName,
		type: 'UPDATE_LAST_NAME'
	}
};

export const updateEmail = (email) => {
	return {
		email,
		type: 'UPDATE_EMAIL'
	}
};

export const updateIsd = (isd) => {
	return {
		isd,
		type: 'UPDATE_ISD'
	}
};

export const updatePhone = (phone) => {
	return {
		phone,
		type: 'UPDATE_PHONE'
	}
};

export const updateNationality = (nationality) => {
	return {
		nationality,
		type: 'UPDATE_NATIONALITY'
	}
};

export const updateSelectedNationality = (selectedNationality) => {
	return {
		selectedNationality,
		type: 'UPDATE_SELECTED_NATIONALITY'
	}
};

export const updateSelectedNationalityCode = (selectedNationalityCode) => {
	return {
		selectedNationalityCode,
		type: 'UPDATE_SELECTED_NATIONALITY_CODE'
	}
};

export const updateCountry = (country) => {
	return {
		country,
		type: 'UPDATE_COUNTRY'
	}
};

export const updateSelectedCountry = (selectedCountry) => {
	return {
		selectedCountry,
		type: 'UPDATE_SELECTED_COUNTRY'
	}
};

export const updateSelectedCountryCode = (selectedCountryCode) => {
	return {
		selectedCountryCode,
		type: 'UPDATE_SELECTED_COUNTRY_CODE'
	}
};

export const updateState = (state) => {
	return {
		state,
		type: 'UPDATE_STATE'
	}
};

export const updateSelectedState = (selectedState) => {
	return {
		selectedState,
		type: 'UPDATE_SELECTED_STATE'
	}
};

export const updateSelectedStateCode = (selectedStateCode) => {
	return {
		selectedStateCode,
		type: 'UPDATE_SELECTED_STATE_CODE'
	}
};

export const updateCity = (city) => {
	return {
		city,
		type: 'UPDATE_CITY'
	}
};

export const updateSelectedCity = (selectedCity) => {
	return {
		selectedCity,
		type: 'UPDATE_SELECTED_CITY'
	}
};

export const updateSelectedCityCode = (selectedCityCode) => {
	return {
		selectedCityCode,
		type: 'UPDATE_SELECTED_CITY_CODE'
	}
};

export const updateZipcode = (zipcode) => {
	return {
		zipcode,
		type: 'UPDATE_ZIPCODE'
	}
};

export const updateAddress1 = (address1) => {
	return {
		address1,
		type: 'UPDATE_ADDRESS1'
	}
};

export const updateAddress2 = (address2) => {
	return {
		address2,
		type: 'UPDATE_ADDRESS2'
	}
};

export const updatePan = (pan) => {
	return {
		pan,
		type: 'UPDATE_PAN'
	}
};

// Form Validations
export const updateFirstNameValid = (firstNameValid) => {
	return {
		firstNameValid,
		type: 'UPDATE_FIRST_NAME_VALID'
	}
};

export const updateLastNameValid = (lastNameValid) => {
	return {
		lastNameValid,
		type: 'UPDATE_LAST_NAME_VALID'
	}
};

export const updateEmailValid = (emailValid) => {
	return {
		emailValid,
		type: 'UPDATE_EMAIL_VALID'
	}
};

export const updatePhoneValid = (phoneValid) => {
	return {
		phoneValid,
		type: 'UPDATE_PHONE_VALID'
	}
};

export const updateNationalityValid = (nationalityValid) => {
	return {
		nationalityValid,
		type: 'UPDATE_NATIONALITY_VALID'
	}
};

export const updateCountryValid = (countryValid) => {
	return {
		countryValid,
		type: 'UPDATE_COUNTRY_VALID'
	}
};

export const updateStateValid = (stateValid) => {
	return {
		stateValid,
		type: 'UPDATE_STATE_VALID'
	}
};

export const updateCityValid = (cityValid) => {
	return {
		cityValid,
		type: 'UPDATE_CITY_VALID'
	}
};

export const updateZipcodeValid = (zipcodeValid) => {
	return {
		zipcodeValid,
		type: 'UPDATE_ZIPCODE_VALID'
	}
};

export const updateAddress1Valid = (address1Valid) => {
	return {
		address1Valid,
		type: 'UPDATE_ADDRESS1_VALID'
	}
};

export const updatePanValid = (panValid) => {
	return {
		panValid,
		type: 'UPDATE_PAN_VALID'
	}
};

export const updateFormValid = (formValid) => {
	return {
		formValid,
		type: 'UPDATE_FORM_VALID'
	}
};

export const updateFormErrors = (field, error) => {
	return {
		field,
		error,
		type: 'UPDATE_FORM_ERRORS'
	}
};
