import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Check } from 'react-feather';
import Icon from 'react-icons-kit';
import { ic_check_circle } from 'react-icons-kit/md/ic_check_circle'

const RenderArray = ({ dataArray, handleSelect, selected, images }) => {
    return (
        <div>
            <Row>
                {images
                    ? dataArray.map((label, index) => <Col md={2} lg={2} sm={3} xs={4} style={{ paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ paddingTop: 20 }} onClick={() => handleSelect(label)}>
                            <img src={`/img/cause/goal-${index + 1}.png`} alt="" style={{ height: "100%", width: "100%" }} />
                            {selected.includes(label) && <Icon className="check-selected-cause" icon={ic_check_circle} size={25} />}
                        </div>
                    </Col>)
                    : dataArray.map((label, index) => <Col md={6} lg={6} sm={12} xs={12}>
                        <div class="form-group form-check skills-option" onClick={() => handleSelect(label)}>
                            <a className={selected.includes(label) ? 'checkbox-active' : 'checkbox-inactive'} style={{ paddingLeft: 2, paddingRight: 2 }} onChange={() => handleSelect(label)}>
                                <Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
                            </a>
                            <label class="form-check-label skills-option-label" style={{ fontWeight: selected.includes(label) ? 'bold' : 'normal   ' }} for="exampleCheck1">
                                {label}
                            </label>
                        </div>
                    </Col>)}
            </Row>
        </div>
    )
}

export default RenderArray