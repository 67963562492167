const initialState = {
	history: null,
	location: null,
	causes: null,
	completedCount: 0,
	upcomingCount: 0,
	hoursClocked: 0,
	selectedOpportunity: {},
	projectDescription: []
};

const EEVolunteerHistoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_HISTORY':
			return Object.assign({}, state, {
				history: action.history,
			});
		case 'UPDATE_HOURS_CLOCKED':
			return Object.assign({}, state, {
				hoursClocked: action.hoursClocked,
			});
		case 'UPDATE_COMPLETED_COUNT':
			return Object.assign({}, state, {
				completedCount: action.completedCount,
			});
		case 'UPDATE_UPCOMING_COUNT':
			return Object.assign({}, state, {
				upcomingCount: action.upcomingCount,
			});
		case 'UPDATE_SELECTED_OPPORTUNITY':
              return Object.assign({}, state, {
				selectedOpportunity: action.selectedOpportunity
			});
		case 'UPDATE_CAUSES':
			return Object.assign({}, state, {
				causes: action.causes,
			});
		case 'UPDATE_LOCATION':
			return Object.assign({}, state, {
				location: action.location,
			});
		case 'UPDATE_PROJECT_DESCRIPTION':
			return Object.assign({}, state, {
				projectDescription: action.descriptions,
			});
		default:
			return state
	}
}

export default EEVolunteerHistoryReducer;