const initialState = {
	selectedProject: null,
	pageNum: true,
	paymentType: 'indian',
	endorseType: 'unit',
	endorseUnit: 1,
	endorseUnitAmount: 0,
	endorseAmount: 500,
	endorseReasonText: '',
	anonymous: false,
	userData: null,
	usePayroll: false,
	usePayrollAmount: 0,
	payCompleteWithPayroll: false,
	finalPayableAmount: 0,

	firstName: '',
	lastName: '',
	email: '',
	isd: '',
	phone: '',
	nationality: [],
	selectedNationality: '',
	selectedNationalityCode: '',
	country: [],
	selectedCountry: '',
	selectedCountryCode: '',
	state: [],
	selectedState: '',
	selectedStateCode: '',
	city: [],
	selectedCity: '',
	selectedCityCode: '',
	zipcode: '',
	address1: '',
	address2: '',
	pan: '',

	// formValidations
	firstNameValid: false,
	lastNameValid: false,
	emailValid: false,
	phoneValid: false,
	zipcodeValid: false,
	address1Valid: false,
	panValid: false,
	nationalityValid: false,
	countryValid: false,
	stateValid: false,
	cityValid: false,
	formErrors: {
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		zipcode: '',
		address1: '',
		pan: '',
		nationality: '',
		country: '',
		state: '',
		city: ''
	},
	formValid: false,
};

const EEEndorsePayment = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_SELECTED_PROJECT':
			return Object.assign({}, state, {
				selectedProject: action.selectedProject,
			});
		case 'UPDATE_PAGENUM':
			return Object.assign({}, state, {
				pageNum: action.pageNum
			});
		case 'UPDATE_PAYMENT_TYPE':
			return Object.assign({}, state, {
				paymentType: action.paymentType
			});
		case 'UPDATE_ENDORSE_TYPE':
			return Object.assign({}, state, {
				endorseType: action.endorseType
			});
		case 'UPDATE_ENDORSE_UNIT':
			return Object.assign({}, state, {
				endorseUnit: action.endorseUnit
			});
		case 'UPDATE_ENDORSE_UNIT_AMOUNT':
			return Object.assign({}, state, {
				endorseUnitAmount: action.endorseUnitAmount
			});
		case 'UPDATE_ENDORSE_AMOUNT':
			return Object.assign({}, state, {
				endorseAmount: action.endorseAmount
			});
		case 'UPDATE_ENDORSE_REASON_TEXT':
			return Object.assign({}, state, {
				endorseReasonText: action.endorseReasonText
			});
		case 'UPDATE_ANONYMOUS':
			return Object.assign({}, state, {
				anonymous: action.anonymous
			});
		case 'UPDATE_USER_DATA':
			return Object.assign({}, state, {
				userData: action.userData
			});
		case 'UPDATE_USE_PAYROLL':
			return Object.assign({}, state, {
				usePayroll: action.usePayroll
			});
		case 'UPDATE_USE_PAYROLL_AMOUNT':
			return Object.assign({}, state, {
				usePayrollAmount: action.usePayrollAmount
			});
		case 'UPDATE_PAY_COMPLETE_WITH_PAYROLL':
			return Object.assign({}, state, {
				payCompleteWithPayroll: action.payCompleteWithPayroll
			});
		case 'UPDATE_FINAL_PAYABLE_AMOUNT':
			return Object.assign({}, state, {
				finalPayableAmount: action.finalPayableAmount
			});

		//	Form Actions
		case 'UPDATE_FIRST_NAME':
			return Object.assign({}, state, {
				firstName: action.firstName
			});
		case 'UPDATE_LAST_NAME':
			return Object.assign({}, state, {
				lastName: action.lastName
			});
		case 'UPDATE_EMAIL':
			return Object.assign({}, state, {
				email: action.email
			});
		case 'UPDATE_ISD':
			return Object.assign({}, state, {
				isd: action.isd
			});
		case 'UPDATE_PHONE':
			return Object.assign({}, state, {
				phone: action.phone
			});
		case 'UPDATE_NATIONALITY':
			return Object.assign({}, state, {
				nationality: action.nationality
			});
		case 'UPDATE_SELECTED_NATIONALITY':
			return Object.assign({}, state, {
				selectedNationality: action.selectedNationality
			});
		case 'UPDATE_SELECTED_NATIONALITY_CODE':
			return Object.assign({}, state, {
				selectedNationalityCode: action.selectedNationalityCode
			});
		case 'UPDATE_COUNTRY':
			return Object.assign({}, state, {
				country: action.country
			});
		case 'UPDATE_SELECTED_COUNTRY':
			return Object.assign({}, state, {
				selectedCountry: action.selectedCountry
			});
		case 'UPDATE_SELECTED_COUNTRY_CODE':
			return Object.assign({}, state, {
				selectedCountryCode: action.selectedCountryCode
			});
		case 'UPDATE_STATE':
			return Object.assign({}, state, {
				state: action.state
			});
		case 'UPDATE_SELECTED_STATE':
			return Object.assign({}, state, {
				selectedState: action.selectedState
			});
		case 'UPDATE_SELECTED_STATE_CODE':
			return Object.assign({}, state, {
				selectedStateCode: action.selectedStateCode
			});
		case 'UPDATE_CITY':
			return Object.assign({}, state, {
				city: action.city
			});
		case 'UPDATE_SELECTED_CITY':
			return Object.assign({}, state, {
				selectedCity: action.selectedCity
			});
		case 'UPDATE_SELECTED_CITY_CODE':
			return Object.assign({}, state, {
				selectedCityCode: action.selectedCityCode
			});
		case 'UPDATE_ZIPCODE':
			return Object.assign({}, state, {
				zipcode: action.zipcode
			});
		case 'UPDATE_ADDRESS1':
			return Object.assign({}, state, {
				address1: action.address1
			});
		case 'UPDATE_ADDRESS2':
			return Object.assign({}, state, {
				address2: action.address2
			});
		case 'UPDATE_PAN':
			return Object.assign({}, state, {
				pan: action.pan
			});

		//	Form Validations
		case 'UPDATE_FIRST_NAME_VALID':
			return Object.assign({}, state, {
				firstNameValid: action.firstNameValid
			});
		case 'UPDATE_LAST_NAME_VALID':
			return Object.assign({}, state, {
				lastNameValid: action.lastNameValid
			});
		case 'UPDATE_EMAIL_VALID':
			return Object.assign({}, state, {
				emailValid: action.emailValid
			});
		case 'UPDATE_PHONE_VALID':
			return Object.assign({}, state, {
				phoneValid: action.phoneValid
			});
		case 'UPDATE_NATIONALITY_VALID':
			return Object.assign({}, state, {
				nationalityValid: action.nationalityValid
			});
		case 'UPDATE_COUNTRY_VALID':
			return Object.assign({}, state, {
				countryValid: action.countryValid
			});
		case 'UPDATE_STATE_VALID':
			return Object.assign({}, state, {
				stateValid: action.stateValid
			});
		case 'UPDATE_CITY_VALID':
			return Object.assign({}, state, {
				cityValid: action.cityValid
			});
		case 'UPDATE_ZIPCODE_VALID':
			return Object.assign({}, state, {
				zipcodeValid: action.zipcodeValid
			});
		case 'UPDATE_ADDRESS1_VALID':
			return Object.assign({}, state, {
				address1Valid: action.address1Valid
			});
		case 'UPDATE_PAN_VALID':
			return Object.assign({}, state, {
				panValid: action.panValid
			});
		case 'UPDATE_FORM_VALID':
			return Object.assign({}, state, {
				formValid: action.formValid
			});
		case 'UPDATE_FORM_ERRORS':
			let newFormErrors = state.formErrors;
			newFormErrors[action.field] = action.error;
			return Object.assign({}, state, {
				formErrors: newFormErrors
			});


		default:
			return state
	}
};

export default EEEndorsePayment;