import { Select } from "antd";
import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";

import { OrgDataContext } from "../../../routes.js";
import EmployeeDetailsAndPreferenceModal from "../1-Feed/EmployeeDetailsAndPreference/EmployeeDetailsAndPreferenceModal.js";
import configs from "../../../config/urlConfigs.js";
import { generatePreSignedGetUrl } from "../../../s3PresignedUrls.js";

const readCookie = require("../../../cookie.js").readCookie;
const createCookie = require("../../../cookie.js").createCookie;

const { Option } = Select;
export default class EEProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      mobileNo: "",
      fullName: "",
      department: "",
      profilePicture: "",
      designationAtOrganisation: "",
      whatsapp: "",
      receiveNotifications: false,
      uploadingProfilePic: false,
      enableQuestionnaire: false,
      activeTab: 0,
      preference: null,
      displayPreferenceModal: false,
      userCookie: null,
      causeDisplayArray: []
    };

  }

  causeArray = [
    'No powerty',
    'Zero Hunger',
    'Goode health and well-being',
    'Quality education',
    'Gender equality',
    'Clean water amd sanitation',
    'Affordable and clean energy',
    'decent work and economic growth',
    'Industry, innovation and infrastructure',
    'reduced inequalities',
    'sustainable cities and communities',
    'Responsible consumptions and production',
    'Climate Action',
    'Life below water',
    'Life on land',
    'Peace, justice and strong institution',
    'Partnership for the goals'
  ];


  handleSelectedCauseDisplay = (valArr) => {
    if (valArr && valArr.length > 0) {
      const dispSelectedArr = []
      for (let i = 0; i < valArr.length; i++) {
        var n = this.causeArray.indexOf(valArr[i]);
        dispSelectedArr.push(n);
      }
      this.setState({ causeDisplayArray: dispSelectedArr });
    }
  }

  componentDidMount() {
    let self = this;
    let userData = JSON.parse(readCookie("userData"));
    this.setState({ whatsapp: userData.whatsapp });
    if (userData.preference) {
      this.setState({ preference: userData.preference })
      this.handleSelectedCauseDisplay(userData.preference.cause)
    }
    this.setState({ userCookie: userData })
    //this.setState({enableQuestionnaire:userData.organisationData.eePreferences.enableEmployeeQuestionnaire});
    let endPoint = this.props.member ? `/get-user-data/${this.props.member}` : "/get-user-data"
    fetch(configs.BACKEND_API_URL + endPoint, {
      method: "get",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        let mobileNo = data.data.mobileNo;
        if (!mobileNo || mobileNo === "false") mobileNo = "";
        this.setState({
          email: data.data.email || "",
          fullName: data.data.fullName || "",
          mobileNo,
          department: data.data.department || "",
          profilePicture: data.data.profilePicture,
          designationAtOrganisation: data.data.designationAtOrganisation || "",
          receiveNotifications: data.data.receiveNotifications || false
        });
      });
    fetch(configs.BACKEND_API_URL + '/get-questionnaire-preference-summary', {
      method: "GET",
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token
      },
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      self.setState({ enableQuestionnaire: data.data.questionnaireEnabled })
    }.bind(this));
  }

  onSave = () => {
    let data = {};
    data.fullName = this.state.fullName;
    data.mobileNo = this.state.mobileNo;
    data.department = this.state.department;
    data.profilePicture = this.state.profilePicture;
    data.designationAtOrganisation = this.state.designationAtOrganisation;
    data.receiveNotifications = this.state.receiveNotifications
    data.whatsapp = this.state.whatsapp;
    fetch(configs.BACKEND_API_URL + "/save-user-data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: JSON.parse(readCookie("access_token")).access_token,
      },
      body: JSON.stringify(data),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        let userData = JSON.parse(readCookie("userData"));
        userData.fullName = data.fullName;
        userData.mobileNo = data.mobileNo;
        userData.department = data.department;
        userData.profilePicture = data.profilePicture;
        userData.designationAtOrganisation = data.designationAtOrganisation;
        userData.receiveNotifications = this.state.receiveNotifications;
        userData.whatsapp = this.state.whatsapp;
        if (!userData.consentLastUpdated || userData.consentLastUpdated !== this.state.receiveNotifications) {
          userData.consentLastUpdated = new Date();
        }
        delete userData.organisationData;
        delete userData.questionnairePreferences;
        delete userData.preference;

        createCookie("userData", JSON.stringify(userData), 7);
        if (response.status === "ok") {
          swal("Good job!", "Your details have been saved!", "success").then(
            function () {
              window.location.reload();
            }
          );
        } else {
          swal({
            type: "error",
            title: "Oops...",
            text: "Something went wrong!",
          }).then(function () {
            window.location.reload();
          });
        }
      });
  };

  notifyError = (text) => toast.error(text);

  upload = (e) => {
    let data = new FormData();
    data.append("image", e.target.files[0]);
    this.setState({ uploadingProfilePic: true });
    fetch(configs.BACKEND_API_URL + "/uploadImage", {
      method: "POST",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token,
      },
      body: data,
    })
      .then((data) => data.json())
      .then(
        function (data) {
          if (data && data.url) {
            this.setState({
              profilePicture: data.url,
              uploadingProfilePic: false,
            });
          }
        }.bind(this)
    ).catch(err => { this.notifyError("Unsupported file format detected!"); e.target.value = null; });
  };

  onChange = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  render() {
    return (
      <OrgDataContext.Consumer>
      {(organisationData)=>{
      return (<div className="ee-page-container">
        {this.props.match &&
          <ul role="tablist" className="nav nav-tabs">
            <li role="presentation" className={this.state.activeTab == 0 ? "active" : null}>
              <a
                id="settings-profile"
                role="tab"
                aria-controls="settings-profile"
                onClick={() => this.setState({ activeTab: 0 })}
              >
                <div className="settings-profile">
                  <div style={{ display: "inline-block" }}></div>
                  <span>Profile</span>
                </div>
              </a>
            </li>
            {this.state.enableQuestionnaire && (
              <li role="presentation">
                <a
                  id="settings-questionnaire"
                  role="tab"
                  aria-controls="settings-questionnaire"
                  href="/questionnaire"
                >
                  <div className="settings-questionnaire">
                    <div style={{ display: "inline-block" }}></div>
                    <span> My Preferences</span>
                  </div>
                </a>
              </li>
            )}
          </ul>}

        <div className="profile-modal-bg mt30">
          <div className="profile-modal">
            {/* {!this.props.noEdit && <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'flex-end' }}>
              <button
                className="profileSubmit"
                onClick={() => this.setState({ displayPreferenceModal: true })}
                style={{ marginTop: "20px" }}
              >
                Edit profile/preferences
              </button>
            </div>} */}
            <Row>
              <Col md={4} lg={4} sm={12} xs={12} >
                <div
                  className="modal-img-container"
                  style={{ position: "relative" }}
                >
                  <div
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      border: "solid 1px #eee",
                      position: "relative",
                      marginTop: 80
                    }}
                  >
                    {this.state.profilePicture ? (
                      <img
                        className="ee-header-user-profile-img"
                        src={generatePreSignedGetUrl(this.state.profilePicture)}
                        // src={this.state.profilePicture}
                      />
                    ) : (
                      <img
                        className="ee-header-user-profile-img"
                        src="/img/user.png"
                      />
                    )}
                    {!this.props.noEdit && <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <button
                        className="profileSubmit"
                        onClick={() => this.setState({ displayPreferenceModal: true })}
                        style={{ marginTop: "20px" }}
                      >
                        Edit profile/preferences
                      </button>
                    </div>}
                    <div
                      className={
                        this.state.uploadingProfilePic
                          ? "is-uploading-profpic"
                          : "is-uploading-profpic loader-hidden"
                      }
                    >
                      <i className="fa fa-spin fa-spinner fa-3x"></i>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4} lg={4} sm={12} xs={12}>
                <div className="proifle-email-container profile-uneditable-container">
                  <h3 className="profile-email">Email :</h3>
                  <p className="profile-email-input">{this.state.email}</p>
                </div>

                <div className="proifle-name-container profile-editable-container">
                  <h3 className="profile-name">Name :</h3>
                  <input
                    className="profile-name-input"
                    type="text"
                    onChange={this.onChange.bind(this, "fullName")}
                    placeholder="Full Name"
                    value={this.state.fullName}
                    disabled
                  />
                </div>

                <div className="proifle-phoneNo-container profile-editable-container">
                  <h3 className="profile-phoneNo">Phone:</h3>
                  <input
                    className="profile-phoneNo-input"
                    type="text"
                    onChange={this.onChange.bind(this, "mobileNo")}
                    placeholder="Mobile No."
                    value={this.state.mobileNo}
                    disabled
                  />
                </div>

                {this.state.whatsapp && <div className="proifle-phoneNo-container profile-editable-container">
                  <h3 className="profile-phoneNo">WhatsApp:</h3>
                  <input
                    className="profile-phoneNo-input"
                    type="text"
                    onChange={this.onChange.bind(this, "whatsapp")}
                    placeholder="Mobile No."
                    value={this.state.whatsapp}
                    disabled
                  />
                </div>}

                <div className="proifle-department-container profile-editable-container">
                  <h3 className="profile-department">Department :</h3>
                  <input
                    className="profile-department-input"
                    type="text"
                    onChange={this.onChange.bind(this, "department")}
                    placeholder="Department"
                    value={this.state.department}
                    disabled
                  />
                </div>

                <div className="proifle-designation-container profile-editable-container">
                  <h3 className="profile-designation">Designation :</h3>
                  <input
                    className="profile-department-input"
                    type="text"
                    onChange={this.onChange.bind(this, "designationAtOrganisation")}
                    placeholder="Designation"
                    value={this.state.designationAtOrganisation}
                    disabled
                  />
                </div>
              </Col>
              {organisationData && organisationData.eePreferences.enableEmployeeDetailsConfirmation
                && this.state.userCookie.preference
                && <Col md={4} lg={4} sm={12} xs={12} style={{ marginTop: 30 }}>
                  <div style={{ maxWidth: 400 }}>
                    <h3 className="profile-phoneNo" style={{ fontSize: 16, alignSelf: 'flex-start' }}>Skills I can contribute to :</h3>
                    <Select
                      mode="multiple"
                      style={{ width: '100%' }}
                      size='large'
                      placeholder="Please select"
                      value={this.state.preference && this.state.preference.skills}
                      disabled
                    >
                    </Select>
                  </div>
                  <div style={{ maxWidth: 400, marginTop: 20 }}>
                    <h3 className="profile-phoneNo" style={{ fontSize: 16, alignSelf: 'flex-start' }}>Causes I would like to support :</h3>
                    <Row>
                      {this.state.causeDisplayArray.map(x => <Col md={3} style={{ padding: 0 }}>
                        <div style={{ padding: 10 }}>
                          <img src={`/img/cause/goal-${x + 1}.png`} style={{ width: '100%', height: '100%' }} />
                        </div>
                      </Col>)}
                    </Row>
                    {/* <Select
                      mode="multiple"
                      style={{ width: '100%' }}
                      size='large'
                      placeholder="Please select"
                      value={this.state.preference && this.state.preference.cause}
                      disabled
                    >
                    </Select> */}
                  </div>
                </Col>}
            </Row>
          </div>
        </div>
        <EmployeeDetailsAndPreferenceModal show={this.state.displayPreferenceModal} close={() => this.setState({ displayPreferenceModal: false })} editMode />
        <ToastContainer
          position="bottom-center"
          autoClose={50000}
          hideProgressBar={true}
          closeOnClick={false}
          newestOnTop={false}
          pauseOnHover={true}
        />
          </div>)
        }}
      </OrgDataContext.Consumer>
    );
  }
}
