const initialState = {
	interests: null,
};

const CorporateReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_INTERESTS':
			return Object.assign({}, state, {
				interests: action.interests
			});
		default:
			return state
	}
}

export default CorporateReducer;