export const updatePreferableCity = (preferableCity) => {
	return {
		preferableCity,
		type: 'UPDATE_PREFERABLE_CITY'
	}
};

export const updatePayroll = (payrollTotalPledged) => {
	return {
		payrollTotalPledged,
		type: 'UPDATE_PAYROLL'
	}
};

export const updatePledgeMonth = (pledgeStartsFromMonth) => {
	return {
		pledgeStartsFromMonth,
		type: 'UPDATE_PLEDGE_MONTH'
	}
};

export const updatePledgeYear = (pledgeStartsFromYear) => {
	return {
		pledgeStartsFromYear,
		type: 'UPDATE_PLEDGE_YEAR'
	}
};

export const updateHasPledged = (hasPledged) => {
	return {
		hasPledged,
		type: 'UPDATE_HAS_PLEDGED'
	}
};

export const updateEnableNotifications = (enableNotifications) => {
	return {
		enableNotifications,
		type: 'UPDATE_ENABLE_NOTIFICATIONS'
	}
};