const COMPANY_SUBDOMAIN_INFO = {
  indusind: {
    clientData: {
      "logo_url": "https://assets.letsendorse.com/IndusInd_Logo_on_login_screen.png",
      "name": "IndusInd Bank Limited",
      // "sso_url": "https://sts.indusind.com/adfs/ls/idpinitiatedsignon?logintorp=indusind_uat_letsengage_co",
      "sso_url": process.env.REACT_APP_SSO_ENTRY_POINT,
      "privacyNotice": "Privacy notice shows here.",
      "ssoEnabled": true
    }, ssoEnabledCompany: true },
  netmagic: {
    clientData: {
      "logo_url": "https://www.theindianwire.com/wp-content/uploads/2017/07/Netmagic.png",
      "name": "Netmagic",
      "ssoEnabled": false
    }, ssoEnabledCompany: false },
  netapp: {
    clientData: {
      "logo_url": "https://blog.seagate.com/wp-content/uploads/2018/12/netapp-logo.png",
      "ssoEnabled": false,
      "name": "NetApp"
    }, ssoEnabledCompany: false },
  // indusind: { clientData: data.data, ssoEnabledCompany: false },
}

function subdomainIsValid(subdomain) {
  if (
    // subdomain === 'le' ||
    subdomain === 'netmagic' ||
    subdomain === 'netapp' ||
    subdomain === 'indusind'
    // ||
    // subdomain === 'staging'
  ) return COMPANY_SUBDOMAIN_INFO[subdomain.toLowerCase()];
  return false;
}

exports.subdomainIsValid = subdomainIsValid;