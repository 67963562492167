import React, { Component } from 'react';
import {Row, Col} from 'react-bootstrap';
import 'rc-calendar/assets/index.css';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import enUS from 'rc-calendar/lib/locale/en_US';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'rc-select/assets/index.css';
import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css';
import configs from '../../../../config/urlConfigs.js';

const format = 'DD-MM-YYYY';
const subdomain = configs.SUBDOMAIN;

const now = moment();
now.locale('en-gb').utcOffset('+5:30');
now.set({hour: 0, minute: 0, second: 0, millisecond: 0});

function getFormat(time) {
	return time ? format : 'DD-MM-YYYY';
}

const defaultCalendarValue = now.clone();
defaultCalendarValue.add(-1, 'month');

const readCookie = require('../../../../cookie.js').readCookie;


const SHOW_TIME = true;

class Picker extends React.Component {
	getDefaultProps() {
		return {
			showTime: SHOW_TIME,
			disabled: false,
		};
	}

	render() {
		const props = this.props;
		const calendar = (<Calendar
			locale={enUS}
			defaultValue={now}
			disabledDate={props.disabledDate}
		/>);
		return (
			<DatePicker
				animation="slide-up"
				disabled={props.disabled}
				calendar={calendar}
				value={props.value}
				onChange={props.onChange}>
				{
					({ value }) => {
						return (
							<span>
	              <input
	                placeholder="Select Date"
	                disabled={props.disabled}
	                readOnly
	                className="form-control inline readonly-white"
	                value={(value && value.format(getFormat(props.showTime))) || ''}
	              />
              </span>
						);
					}
				}
			</DatePicker>
		);
	}
}

export default class LeaderboardFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startValue: null,
			endValue: null,
			type: 'hours',
			office: '',
			department: '',
			cities: [],
		}
		this.fetchIBLCities = this.fetchIBLCities.bind(this);
	}

	onChange(field, value) {
		if(field === 'type' || field === 'office' || field === 'department') {
			if(field === 'type') this.props.updateFilterType(value.target.value);
			this.setState({ [field]: value.target.value }, this.filterResult);
		} else {
			this.setState({ [field]: value }, this.filterResult);
		}
	}

	disabledEndDate(endValue) {
		if (!endValue) {
			return false;
		}
		const startValue = this.state.startValue;
		if (!startValue) {
			return false;
		}
		const date = moment(new Date());
		return endValue.valueOf() > date.valueOf();  // can not select days after today
	}

	disabledStartDate(startValue) {
		if (!startValue) {
			return false;
		}
		const endValue = this.state.endValue;
		if (!endValue) {
			return false;
		}
		return SHOW_TIME ? startValue.isAfter(endValue) : startValue.diff(endValue, 'days') > 0;
	}

	filterResult() {
		let urlParams = '?';

		if(this.state.startValue) {
			if(urlParams !== "?") urlParams += '&';
			urlParams += 'startDate=' + this.state.startValue;
		}

		if(this.state.endValue) {
			if(urlParams !== "?") urlParams += '&';
			urlParams += 'endDate=' + this.state.endValue;
		}

		if(this.state.office !== '') {
			if(urlParams !== "?") urlParams += '&';
			// urlParams += 'listId=' + this.state.office;
			subdomain.toLowerCase() === 'indusind' ? 
			urlParams += 'city=' + this.state.office : 
			urlParams += 'listId=' + this.state.office
		}

		if(this.state.department !== '') {
			if(urlParams !== "?") urlParams += '&';
			urlParams += 'department=' + this.state.department;
		}

		if(this.state.type === 'hours') {
			fetch(configs.BACKEND_API_URL + '/ee/get-vo-leaderboard' + urlParams, {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				this.props.updateLeaderboard(data.data, this.state.type)
			}.bind(this));
		} else {
			fetch(configs.BACKEND_API_URL + '/get-donations-leaderboard' + urlParams, {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				this.props.updateLeaderboard(data.data, this.state.type)
			}.bind(this));
		}

	}

	resetFilters() {
		let startValue = null;
		let endValue = null;

		this.setState({ endValue: null, startValue: null }, function() {
			this.setState({ endValue, startValue, office: '', department: '', type: 'hours' });
		});

		fetch(configs.BACKEND_API_URL + '/ee/get-vo-leaderboard', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			this.props.updateLeaderboard(data.data, 'hours');
		}.bind(this));
	}

	fetchIBLCities() {
		var that = this;
		fetch(configs.BACKEND_API_URL + '/ibl-particulars/city', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			let cities = [];
			// let selectedCities = [];
			for (var i = 0; i < data.cities.length; i++) {
				let city = {};
				city['value'] = data.cities[i];
				city['label'] = data.cities[i];
				// if (that.props.opportunity.config.hasOwnProperty('cities')) {
				// 	if (Array.isArray(that.props.opportunity.config.cities)) {
				// 		if (that.props.opportunity.config.cities.indexOf(data.cities[i]) > -1) {
				// 			city['selected'] = true;
				// 			selectedCities.push(data.cities[i]);
				// 		}
				// 	} else if (that.props.opportunity.config.cities === true) {
				// 		city['selected'] = true;
				// 		selectedCities.push(data.cities[i]);
				// 	}
				// } else {
				// 	city['selected'] = true;
				// 	selectedCities.push(data.cities[i]);
				// }
				cities.push(city);
			}
			that.setState({
				cities,
				// selectedCities
			});
		});
	}

	render () {
		if(this.props.leaderboard) {
			return (
				<div>
					<Row className="pad-tb-20 flex-between">
						<Col md={2} className="form-group mb0">
							<label className="control-label">Start Date:</label>
							<Picker
								className="form-control inline"
								value={this.state.startValue}
								disabledDate={this.disabledStartDate.bind(this)}
								onChange={this.onChange.bind(this, 'startValue')}
							/>
						</Col>

						<Col md={2} className="form-group mb0">
							<label className="control-label">End Date:</label>
							<Picker className="form-control inline"
								disabledDate={this.disabledEndDate.bind(this)}
								onChange={this.onChange.bind(this, 'endValue')}
								value={this.state.endValue} />
						</Col>

						<Col md={2} className="form-group mb0">
							<label className="control-label">Type:</label>
							<select className="form-control selectpicker" required
				        onChange={this.onChange.bind(this, 'type')}
				        value={this.state.type}>
								<option value="hours">Hours</option>
								{subdomain.toLowerCase() === 'indusind' ? null :<option value="donations">Donations</option>}
							</select>
						</Col>

						<Col md={2} className="form-group mb0">
							{subdomain.toLowerCase() === 'indusind' ? <label className="control-label">City:</label>:<label className="control-label">Office:</label>}
							<select className="form-control selectpicker" required
				        onChange={this.onChange.bind(this, 'office')}
				        value={ this.state.office}
						//  value={this.state.cities}
						>
				        {subdomain.toLowerCase() === 'indusind' ? <option disabled value="">Select City</option> : <option disabled value="">Select Office</option>}
				       {subdomain.toLowerCase() === 'indusind'  ? ( this.props.cities.map(function(office, index) {
				        	return (
				        		<option key={index} value={office.value}>{office.label}</option>
				        	)
				        })) :this.props.offices.map(function(office, index) {
				        	return (
				        		<option key={index} value={office.id}>{office.name}</option>
				        	)
				        }) } 
							</select>
						</Col>

						<Col md={2} className="form-group mb0">
							<label className="control-label">Department:</label>
							<select className="form-control selectpicker" required
				        onChange={this.onChange.bind(this, 'department')}
				        value={this.state.department}>
				        <option disabled value="">Select Department</option>
				        {this.props.departments.map(function(department, index) {
				        	return (
				        		<option key={index} value={department}>{department}</option>
				        	)
				        })}
							</select>
						</Col>

						<Col md={2} className="form-group mb0">
							<label className="control-label" style={{color: 'white'}}>Actions:</label>
							<div className="flex-center">
								<button className="btn-sq btn-le btn-pad-5-20 w100" onClick={this.resetFilters.bind(this)}>Reset</button>
							</div>
						</Col>
					</Row>
				</div>
			);
		} else {
			return null;
		}
	}
}