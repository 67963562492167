import React, { Component } from "react";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AddAnOpportunitySwitch from "./AddAnOpportunitySwitch";
import RecommendAnOpportunitySwitch from "./RecommendAnOpportunitySwitch";
import * as action from "../../../../actions/EERecommendOpportunityAction";

class RecommendAnOpportunity extends Component {
  state = {
    disabled: false,
    toggleValue: true,
  };

  onChange() {
    this.setState({ toggleValue: !this.state.toggleValue });
  }

  render() {
    return (
      <div className="ee-page-container">
        <div className="ee-recommend-switch-container" hidden={true}>
          <span className="ee-recommend-switch-left-text">
            Add an opportunity
          </span>
          <Switch
            onChange={this.onChange.bind(this)}
            disabled={this.state.disabled}
            checked={this.state.toggleValue}
          />
          <span className="ee-recommend-switch-right-text">
            Recommend an opportunity
          </span>
        </div>
        {this.state.toggleValue ? (
          <RecommendAnOpportunitySwitch
            opportunityDescription={this.props.opportunityDescription}
            opportunityPhone={this.props.opportunityPhone}
            opportunityNote={this.props.opportunityNote}
            opportunityEmail={this.props.opportunityEmail}
            updateOpportunityDescription={
              this.props.actions.updateOpportunityDescription
            }
            updateOpportunityEmail={this.props.actions.updateOpportunityEmail}
            updateOpportunityPhone={this.props.actions.updateOpportunityPhone}
            updateOpportunityNote={this.props.actions.updateOpportunityNote}
            showRecommendOpportunityModal={
              this.props.showRecommendOpportunityModal
            }
            closeModal1={this.props.closeModal1}
            openModal1={this.props.openModal1}
            opportunitiesLength={this.props.opportunitiesLength}
          />
        ) : (
          <AddAnOpportunitySwitch />
        )}
      </div>
    );
  }
}

RecommendAnOpportunity.propTypes = {
  opportunityDescription: PropTypes.string,
  opportunityEmail: PropTypes.string,
  opportunityPhone: PropTypes.string,
  opportunityNote: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    opportunityDescription: state.EERecommendOpportunity.opportunityDescription,
    opportunityEmail: state.EERecommendOpportunity.opportunityEmail,
    opportunityPhone: state.EERecommendOpportunity.opportunityPhone,
    opportunityNote: state.EERecommendOpportunity.opportunityNote,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(action, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecommendAnOpportunity);
