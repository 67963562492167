import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Multiselect from 'react-bootstrap-multiselect';
import 'rc-calendar/assets/index.css';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import enUS from 'rc-calendar/lib/locale/en_US';
import moment from 'moment';
import 'moment/locale/en-gb';
import $ from 'jquery';
import Geosuggest from 'react-geosuggest';
import { ToastContainer, toast } from 'react-toastify';

import Select, { Option } from 'rc-select';
import 'rc-select/assets/index.css';
import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css';
import configs from '../../../../config/urlConfigs.js';

const format = 'DD-MM-YYYY';

const now = moment();
now.locale('en-gb').utcOffset('+5:30');
now.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

function getFormat(time) {
	return time ? format : 'DD-MM-YYYY';
}

const defaultCalendarValue = now.clone();
defaultCalendarValue.add(-1, 'month');

const readCookie = require('../../../../cookie.js').readCookie;


const SHOW_TIME = true;

const subdomain = configs.SUBDOMAIN;
// const subdomain = window.location.hostname.split('.')[0];
class Picker extends React.Component {
	getDefaultProps() {
		return {
			showTime: SHOW_TIME,
			disabled: false,
		};
	}

	render() {
		const props = this.props;
		const calendar = (<Calendar
			locale={enUS}
			defaultValue={now}
			disabledDate={props.disabledDate}
		/>);
		return (
			<DatePicker animation="slide-up" disabled={props.disabled}
				calendar={calendar} value={props.value} onChange={props.onChange}>
				{({ value }) => {
					return (
						<span>
							<input placeholder="Select Date"
								disabled={props.disabled} readOnly
								className="form-control inline readonly-white"
								value={value && value.format(getFormat(props.showTime)) || ''} />
						</span>
					);
				}}
			</DatePicker>
		);
	}
}

export default class EndorsementProjectsFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			goal: null,
			goalValue: null,
			goalValueIndusInd: null,
			startValue: null,
			endValue: null,
			latitude: null,
			longitude: null,
		}
	}

	onChange(field, value) {
		this.setState({
			[field]: value,
		},
			this.filterResult
		)
	}

	onSuggestSelect(suggest) {
		if (suggest && suggest.label !== '') {
			this.setState({
				latitude: suggest.location.lat,
				longitude: suggest.location.lng,
			},
				this.filterResult
			)
		} else {
			this._geoSuggest.clear();
			this.setState({
				latitude: null,
				longitude: null,
			},
				this.filterResult
			)
		}
	}

	disabledEndDate(endValue) {
		if (!endValue) {
			return false;
		}
		const startValue = this.state.startValue;
		if (!startValue) {
			return false;
		}
		return SHOW_TIME ? endValue.isBefore(startValue) :
			endValue.diff(startValue, 'days') <= 0;
	}

	disabledStartDate(current) {
		if (!current) {
			// allow empty select
			return false;
		}
		const date = moment();
		date.hour(0);
		date.minute(0);
		date.second(0);
		return current.valueOf() < date.valueOf();  // can not select days before today
	}

	componentDidMount() {
		fetch(configs.BACKEND_API_URL + '/getOtAttributes?type=goal', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			// let d = data.otAttributes;
			let arr = data.otAttributes;
			let d = [];
			for (var i = 0; i < arr.length; i++) {
				if (subdomain.toLowerCase() === 'indusind') {
					if (arr[i].organization && arr[i].organization === 'indusind') {
						const existingIndex = d.map(x => x.name).indexOf(arr[i].parent)
						if (existingIndex > -1) {
							d[existingIndex].ids.push(arr[i]._id)
						} else {
							const obj = {
								id: arr[i].id,
								name: arr[i].parent,
								_id: arr[i]._id,
								type: arr[i].type,
								totalIdeas: arr[i].totalIdeas,
								ids: []
							}
							d.push(obj);
						}
					}
				} else if (!arr[i].hasOwnProperty('organization')) {
					d.push(arr[i]);
				}
			}

			let goal = [];
			let goalValue = [];
			let goalValueIndusInd = [];
			this.props.updateCauses(d);
			for (let i = 0; i < d.length; i++) {
				let goalObj = {};
				goalObj['value'] = d[i]._id;
				goalObj['label'] = d[i].name;
				goalObj['selected'] = true;
				goalObj['ids'] = d[i].ids;
				goal.push(goalObj);
				if (subdomain.toLowerCase() === 'indusind') {
					for (let j = 0; j < d[i].ids.length; j++) {
						goalValueIndusInd.push(d[i].ids[j])
					}
				}
				goalValue.push(d[i]._id);
			}
			this.setState({ goal, goalValue, goalValueIndusInd });
		}.bind(this));

		fetch(configs.BACKEND_API_URL + '/volunteer-opportunity/all-cities', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			let d = data.data;
			let location = [];
			this.props.updateLocation(d);
			for (let i = 0; i < d.length; i++) {
				let locationObj = {};
				locationObj['value'] = d[i];
				location.push(locationObj);
			}
			this.setState({
				location
			});
		}.bind(this));
	}

	showButtonText(options, select) {
		if (options.length === 0) {
			return 'None selected ...';
		} else if (options.length === 15) {
			return 'All selected';
		} else if (options.length > 1) {
			return options.length + ' selected';
		} else {
			let labels = [];
			options.each(function () {
				if ($(this).attr('label') !== undefined) {
					labels.push($(this).attr('label'));
				} else {
					labels.push($(this).html());
				}
			});
			return labels.join(', ') + '';
		}
	}

	handleChange(name, value) {
		if (subdomain.toLowerCase() === 'indusind' && name === 'goalValue') {
			const goalArr = value.parent().val();
			const selected = this.state.goal.filter(x => goalArr.includes(x.value));
			let arr = selected.map(y => y.value);
			for (let i = 0; i < selected.length; i++) {
				for (let j = 0; j < selected[i].ids.length; j++) {
					arr.push(selected[i].ids[j]);
				}
			}
			this.setState({ goalValue: arr }, this.filterResult);
		} else {
			this.setState({ [name]: $(value).parent().val() }, this.filterResult);
		}
	}

	onSearchChange(e) {
		let commentRegex = new RegExp(`^[\.a-zA-Z0-9,!?:;@#$~' ]*$`)
		if (commentRegex.test(e.target.value) === true || true){
		this.setState({ search: e.target.value }, this.filterResult);}
		else {
			this.notifyError("Only letters, numbers and special characters are allowed");
		}
	}
 notifyError = (text) => toast.error(text);


	filterResult() {
		let urlParams = '?';

		if (this.state.search.length) {
			urlParams += 'query=' + this.state.search;
		}

		// if(this.state.startValue) {
		// 	if(urlParams !== "?") {
		// 		urlParams += '&';
		// 	}

		// 	urlParams += 'startDate=' + this.state.startValue;
		// }

		// if(this.state.endValue) {
		// 	if(urlParams !== "?") {
		// 		urlParams += '&';
		// 	}

		// 	urlParams += 'endDate=' + this.state.endValue;
		// }

		if (this.state.goalValue) {
			if (urlParams !== "?") {
				urlParams += '&';
			}

			urlParams += 'goalId=' + this.state.goalValue;
		}

		if (this.state.latitude) {
			if (urlParams !== "?") {
				urlParams += '&';
			}

			urlParams += 'latitude=' + this.state.latitude;
		}

		if (this.state.longitude) {
			if (urlParams !== "?") {
				urlParams += '&';
			}

			urlParams += 'longitude=' + this.state.longitude;
		}

		if (urlParams === '?') urlParams = '';

		fetch(configs.BACKEND_API_URL + '/projects/ee-discover' + urlParams, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			this.props.updateProjects(data.data)
		}.bind(this));
	}

	resetFilters() {
		let search = "";
		let goal = null;
		let goalValue = "";
		let latitude = null;
		let longitude = null;
		let startValue = null;
		let endValue = null;

		this._geoSuggest.clear();

		this.setState({ search: null, goal: null, goalValue: null, latitude: null, longitude: null, endValue: null, startValue: null }, function () {
			this.setState({ search, goal, goalValue, latitude, longitude, endValue, startValue });
		});

		fetch(configs.BACKEND_API_URL + '/projects/ee-discover', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			this.props.updateProjects(data.data)
		}.bind(this));

		fetch(configs.BACKEND_API_URL + '/getOtAttributes?type=goal', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			let d = data.otAttributes;
			let goal = [];
			let goalValue = [];
			this.props.updateCauses(d);
			for (let i = 0; i < d.length; i++) {
				let goalObj = {};
				goalObj['value'] = d[i]._id;
				goalObj['label'] = d[i].name;
				goalObj['selected'] = true;
				goal.push(goalObj);
				goalValue.push(d[i]._id);
			}
			this.setState({ goal, goalValue });
		}.bind(this));

		fetch(configs.BACKEND_API_URL + '/volunteer-opportunity/all-cities', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			let d = data.data;
			let location = [];
			this.props.updateLocation(d);
			for (let i = 0; i < d.length; i++) {
				let locationObj = {};
				locationObj['value'] = d[i];
				location.push(locationObj);
			}
			this.setState({
				location
			});
		}.bind(this));
	}

	render() {
		if (this.props.causes) {
			return (
				<div>
					<Row className="pad-tb-20 flex-between">
					<ToastContainer
						position="bottom-center"
						autoClose={50000}
						hideProgressBar={true}
						closeOnClick={false}
						newestOnTop={false}
						pauseOnHover={true}
					/>
						{/*<Col md={2} className="form-group mb0">
							<label className="control-label">Start Date:</label>
							<Picker
								className="form-control inline"
								disabledDate={this.disabledStartDate.bind(this)}
								value={this.state.startValue}
								onChange={this.onChange.bind(this, 'startValue')}
							/>
						</Col>

						<Col md={2} className="form-group mb0">
							<label className="control-label">End Date:</label>
							<Picker
								className="form-control inline"
								disabledDate={this.disabledEndDate.bind(this)}
								value={this.state.endValue}
								onChange={this.onChange.bind(this, 'endValue')}
							/>
						</Col>*/}
						<Col md={3} className="form-group mb0">
							<label className="control-label">Location:</label>
							<Geosuggest id="location" className="form-control" ref={el => this._geoSuggest = el} onSuggestSelect={this.onSuggestSelect.bind(this)} />
						</Col>
						<Col md={3} className="form-group mb0">
							<label className="control-label">Cause:</label>
							<Multiselect id="causeGoal" className="form-control inline" value={this.state.goalValue} onChange={this.handleChange.bind(this, 'goalValue')} buttonWidth={'100%'} buttonText={this.showButtonText.bind(this)} data={this.state.goal} enableCaseInsensitiveFiltering multiple />
						</Col>
						<Col md={3} className="form-group mb0">
							<label className="control-label">Search:</label>
							<input type="search" className="form-control inline" value={this.state.search} onChange={this.onSearchChange.bind(this)} />
						</Col>
						<Col md={2} className="form-group mb0">
							<label className="control-label"> </label>
							<div className="flex-center">
								<button className="btn-sq btn-le btn-pad-5-20 w100" onClick={this.resetFilters.bind(this)}>Reset</button>
							</div>
						</Col>
					</Row>
				</div>
			);
		} else {
			return null;
		}
	}
}