import React, { Component } from "react";
import swal from "sweetalert2";
import Multiselect from "react-bootstrap-multiselect";
import $, { data } from "jquery";
import configs from "../../../config/urlConfigs.js";

const readCookie = require("../../../cookie.js").readCookie;
const createCookie = require("../../../cookie.js").createCookie;

export default class EEQuestionnaireModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionnaire: { mandatory: false },
      // questionnairePreferences: null,
      // goals: [],
      // updatedRecently: false,
      errorsPresent: false,
      // questionsAnswered: false,
      responseErrors: new Array(15).fill(false),
      // employeeQuestions: []
    };
    this.validateResponses = this.validateResponses.bind(this);
  }

  componentWillMount() {
    this.setState({questionnaire:this.props.questionnaire});
  
    this.handleClose = this.handleClose.bind(this);
  }
  onSave = () => {
    if (!this.validateResponses()) {
      let data = {};
      data["questionnairePreferences"] = {
        ...this.state.questionnaire,
        questionsAnswered: true,
        questionsAnsweredAt: new Date().toISOString(),
      };
      fetch(configs.BACKEND_API_URL + "/save-user-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Auth: JSON.parse(readCookie("access_token")).access_token,
        },
        body: JSON.stringify(data),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (response) {
          if (response.status === "ok") {
            swal("Good job!", "Your details have been saved!", "success").then(
              function () {
                window.location.reload();
              }
            );
          } else {
            swal({
              type: "error",
              title: "Oops...",
              text: "Something went wrong!",
            }).then(function () {
              window.location.reload();
            });
          }
        });
    }
  };

  updateQuestionnairePreferences(questionNo, response) {
    let selectedEmployeeQuestions = this.state.questionnaire
      .selectedEmployeeQuestions;
    let selectedOptions = [];
    if (selectedEmployeeQuestions[questionNo]["type"] === "multiselect") {
      selectedOptions = $(response).parent().val();
      selectedEmployeeQuestions[questionNo]["options"].forEach(
        (option, index) => {
          if (selectedOptions.indexOf(option.label) !== -1)
            selectedEmployeeQuestions[questionNo]["options"][index][
              "selected"
            ] = true;
        }
      );
    }
    selectedEmployeeQuestions[questionNo]["selected"] =
      selectedEmployeeQuestions[questionNo]["type"] === "number"
        ? Number(response.target.value)
        : selectedEmployeeQuestions[questionNo]["type"] === "multiselect"
        ? $(response).parent().val()
        : response.target.value;
    this.setState({
      questionnaire: {
        mandatory: this.state.questionnaire.mandatory,
        selectedEmployeeQuestions,
      },
    });
  }

  skipQuestionnaire() {
    createCookie("skippedQuestionnairePreferences", true, 7);
    window.location.reload();
  }
  validateResponses() {
    let responseErrors = this.state.responseErrors;
    let errorsPresent = false;
    this.state.questionnaire.selectedEmployeeQuestions.forEach(
      (question, index) => {
        if (question.checked) {
          switch (question.type) {
            case "multiselect":
              responseErrors[index] =
                !question.hasOwnProperty("selected") ||
                question.selected.length === 0;
              break;
            case "singleselect":
              responseErrors[index] =
                !question.hasOwnProperty("selected") ||
                question.selected === null;
              break;
            case "number":
              responseErrors[index] =
                !question.hasOwnProperty("selected") ||
                question.selected === "";
              break;
          }
          errorsPresent = errorsPresent || responseErrors[index];
        }
      }
    );
    this.setState({ responseErrors, errorsPresent });
    return errorsPresent;
  }
  handleClose() {
    this.setState({ showModal: false });
  }
  render() {
    return (
      <div
        id="questionnaire-modal"
        className="ee-page-container-modal ee-page-container questionnaire-page"
      >
        {this.state.questionnaire !== null ? (
          <div className="questionnaire-container mt30">
            {/* {this.state.updatedRecently && <div class="alert alert-info" role="alert">
  Your organizational has updated questionnaire recently, Please answer them!
</div>} */}
            <div className="questionnaire-header-modal">
              <h3>Volunteering Preferences Questionnaire</h3>
              <hr />
              {!this.state.questionnaire.mandatory ? (
                <span
                  id="skip-text"
                  onClick={this.skipQuestionnaire.bind(this)}
                >
                  Skip this step
                </span>
              ) : null}
            </div>
            <div className="questionnaires">
              {this.state.questionnaire.selectedEmployeeQuestions.map(
                function (question, index) {
                  if (question.checked) {
                    if (question.type === "multiselect") {
                      return (
                        <React.Fragment>
                          <div className="container-fluid">
                            <div
                              className="questionnaire form-group"
                              key={index}
                            >
                              <label className="control-label">
                                {question.question}
                              </label>
                              <Multiselect
                                id={index}
                                className="form-control inline"
                                value={question.selected}
                                onChange={this.updateQuestionnairePreferences.bind(
                                  this,
                                  index
                                )}
                                buttonWidth={"50%"}
                                data={question.options}
                                enableCaseInsensitiveFiltering
                                multiple
                              />
                            </div>
                            {this.state.responseErrors[index] && (
                              <span>
                                <small className="invalid-feedback">
                                  <hr /> Please select a value!
                                </small>
                              </span>
                            )}
                          </div>
                        </React.Fragment>
                      );
                    } else if (question.type === "singleselect") {
                      return (
                        <React.Fragment>
                          <div className="container-fluid">
                            <div
                              className="questionnaire form-group"
                              key={index}
                            >
                              <label className="control-label">
                                {question.question}
                              </label>
                              <select
                                className="form-control inline form-select"
                                name={question.question}
                                id={index}
                                value={question.selected}
                                onChange={this.updateQuestionnairePreferences.bind(
                                  this,
                                  index
                                )}
                              >
                                {question.options.map((option) => {
                                  return (
                                    <option
                                      key={option}
                                      name={option}
                                      value={option}
                                    >
                                      {option}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {this.state.responseErrors[index] && (
                              <span>
                                <small className="invalid-feedback">
                                  <hr /> Please select a value!
                                </small>
                              </span>
                            )}
                          </div>
                        </React.Fragment>
                      );
                    } else if (question.type === "number") {
                      return (
                        <React.Fragment>
                          <div className="container-fluid">
                            <div
                              className="questionnaire form-group"
                              key={index}
                            >
                              <label className="control-label">
                                {question.question}
                              </label>
                              <input
                                type="number"
                                className="form-control inline"
                                value={question.selected}
                                onChange={this.updateQuestionnairePreferences.bind(
                                  this,
                                  index
                                )}
                              />
                            </div>
                            {this.state.responseErrors[index] && (
                              <span>
                                <small className="invalid-feedback">
                                  <hr /> Please enter a value!
                                </small>
                              </span>
                            )}
                          </div>
                        </React.Fragment>
                      );
                    }
                  }
                }.bind(this)
              )}
            </div>
            <div className="text-center">
              {this.state.errorsPresent && (
                <div>
                  <small>
                    Please check if you have answered all questions in the
                    survey!
                  </small>
                </div>
              )}
              <button
                className="btn save-questionnaire-btn"
                onClick={this.onSave}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
