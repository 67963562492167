import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Col} from 'react-bootstrap';

import ManageCorporate from './ManageCorporate';
import ManageNGO from './ManageNGO';
import AddMember from './AddMember';
import ProfileSettings from './ProfileSettings';

import * as action from '../../actions/AdminAction';

import 'bootstrap/dist/css/bootstrap.css';
import configs from '../../config/urlConfigs';

const readCookie = require('../../cookie.js').readCookie;
const eraseCookie = require('../../cookie.js').eraseCookie;
const eraseAllCookies = require('../../cookie.js').eraseAllCookies;

class AdminDashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	componentDidMount() {
		var userData = JSON.parse(readCookie('userData'));
		if(userData.type === 'super_admin') {
			this.props.actions.changeLoginStatus(true);
		}
	}

	logout() {
		fetch(configs.BACKEND_API_URL + '/logout', {
			method: "get",
			credentials: "include",
			headers: {
				Auth: JSON.parse(readCookie("access_token")).access_token,
			},
		})
		.then((data) => data.json())
		.then((data) => {
			eraseAllCookies();
			this.props.actions.changeLoginStatus(false);
			eraseCookie('userData');
			eraseCookie('access_token');
		});
	}

	render() {
		if(this.props.adminLoggedIn) {
			return (
				<div className="admin-dashboard-container">
					<div id="mySidenav" className="sidenav">
						<span className="heading">Menu</span>
					  {this.props.selectedMenuItem === 'manageCorporate' ? (<a className="active" href="#">Manage Corporate</a>) : (<a href="#">Manage Corporate</a>)}
					  {this.props.selectedMenuItem === 'manageNGO' ? (<a className="active" href="#">Manage NGO</a>) : (<a href="#">Manage NGO</a>)}
					  {this.props.selectedMenuItem === 'addMember' ? (<a className="active" href="#">Add Member</a>) : (<a href="#">Add Member</a>)}
					  {this.props.selectedMenuItem === 'profileSettings' ? (<a className="active" href="#">Profile Settings</a>) : (<a href="#">Profile Settings</a>)}
					  <a href="/admin" onClick={this.logout.bind(this)}>Logout</a>
					</div>
					<div id="main">
						<Col md={12}>
							{(() => {
								if(this.props.selectedMenuItem === 'manageCorporate') return <ManageCorporate updateCorporateUsers={this.props.actions.updateCorporateUsers} corporateUsers={this.props.corporateUsers} />
								else if (this.props.selectedMenuItem === 'manageNGO') return <ManageNGO updateNgoUsers={this.props.actions.updateNgoUsers} ngoUsers={this.props.ngoUsers} />
								else if (this.props.selectedMenuItem === 'addMember') return <AddMember />
								else if (this.props.selectedMenuItem === 'profileSettings') return <ProfileSettings />
								else return null;
							})()}
						</Col>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

AdminDashboard.propTypes = {
	adminLoggedIn: PropTypes.bool,
	selectedMenuItem: PropTypes.string,
	corporateUsers: PropTypes.array,
	ngoUsers: PropTypes.array
};

function mapStateToProps(state) {
	return {
		adminLoggedIn: state.Admin.adminLoggedIn,
		selectedMenuItem: state.Admin.selectedMenuItem,
		corporateUsers: state.Admin.corporateUsers,
		ngoUsers: state.Admin.ngoUsers,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);