import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import configureStore from './store/configure-store';
import Routes from './routes'
import 'antd/dist/antd.css';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'raf/polyfill';
import configs from './config/urlConfigs';

const store = configureStore();
const subdomainIsValid = require('./subdomainCheck.js').subdomainIsValid;

var subdomain = configs.SUBDOMAIN, url = '';
if(!subdomain && window.location.hostname.split('.').length >= 3 && window.location.hostname.split('.')[0] !== 'www') {
	subdomain = window.location.hostname.split('.')[0];
}

if(subdomain && subdomainIsValid(subdomain)) {
	url = window.location.origin + '/themes/' + subdomain + '.theme.css';
	document.body.innerHTML = document.body.innerHTML + "<style> @import '"+url+"'; </style>";
} else {
	url = window.location.origin + '/themes/le.theme.css';
	document.body.innerHTML = document.body.innerHTML + "<style> @import '" + url + "'; </style>";
}

ReactDOM.render(
	<Provider store={store}>
		<Routes />
	</Provider>,
	document.getElementById('root')
);