const initialState = {
	causes: null,
	filteredCauses: null,
	searchTerm: '',
	selectedCause: {},
	projects: null,
	priceRangeMax: 0,
	priceRangeMin: 0
}

const DiscoverCausesReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_CAUSES':
			return Object.assign({}, state, {
				causes: action.causes
			});
		case 'UPDATE_SEARCH_TERM':
			return Object.assign({}, state, {
				searchTerm: action.searchTerm
			});
		case 'UPDATE_FILTERED_CAUSES':
			return Object.assign({}, state, {
				filteredCauses: action.filteredCauses
			});
		case 'UPDATE_SELECTED_CAUSE':
			return Object.assign({}, state, {
				selectedCause: action.selectedCause
			});
		case 'UPDATE_PROJECTS':
			let priceRangeMax = 0;
			action.projects.map(function(project) {
				if(project.max_budget > priceRangeMax) {
					return priceRangeMax = project.max_budget
				}
			});
			return Object.assign({}, state, {
				projects: action.projects,
				priceRangeMax
			});
		default:
			return state
	}
}

export default DiscoverCausesReducer;