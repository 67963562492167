export const updateLeaderboard = (leaderboard) => {
	return {
		leaderboard,
		type: 'UPDATE_LEADERBOARD'
	}
};

export const updateFilterType = (filterType) => {
	return {
		filterType,
		type: 'UPDATE_FILTER_TYPE'
	}
};
