const initialState = {
	preferableCity: '',
	payrollTotalPledged: 0,
	pledgeStartsFromMonth: '',
	pledgeStartsFromYear: '',
	hasPledged: false,
	enableNotifications: true
};

const EEPreferencesReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_PREFERABLE_CITY':
			return Object.assign({}, state, {
				preferableCity: action.preferableCity,
			});
		case 'UPDATE_PAYROLL':
			return Object.assign({}, state, {
				payrollTotalPledged: action.payrollTotalPledged,
			});
		case 'UPDATE_PLEDGE_MONTH':
			return Object.assign({}, state, {
				pledgeStartsFromMonth: action.pledgeStartsFromMonth,
			});
		case 'UPDATE_PLEDGE_YEAR':
			return Object.assign({}, state, {
				pledgeStartsFromYear: action.pledgeStartsFromYear,
			});
		case 'UPDATE_HAS_PLEDGED':
			return Object.assign({}, state, {
				hasPledged: action.hasPledged,
			});
		case 'UPDATE_ENABLE_NOTIFICATIONS':
			return Object.assign({}, state, {
				enableNotifications: action.enableNotifications,
			});
		default:
			return state
	}
}

export default EEPreferencesReducer;