import React, {Component} from 'react';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/en-gb';
import {
	InputNumber,
	// AutoComplete, Select
} from 'antd';
import Multiselect from 'multiselect-react-dropdown';
import Switch from 'rc-switch';
import $ from 'jquery';
// import Multiselect from 'react-bootstrap-multiselect';
// import DayPicker, { DateUtils } from 'react-day-picker';

import 'antd/dist/antd.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'rc-time-picker/assets/index.css';
import 'rc-calendar/assets/index.css';
import 'rc-switch/assets/index.css';
import configs from '../../../../../config/urlConfigs.js';

const format = 'h:mm a';

// const Option = AutoComplete.Option;
// const { Option } = Select;

const now = moment().hour(0).minute(0);
now.locale('en-gb').utcOffset(0);

const defaultCalendarValue = now.clone();
defaultCalendarValue.add(-1, 'month');

const readCookie = require('../../../../../cookie.js').readCookie;
const subdomain = window.location.hostname.split('.')[0];

export default class MakeLive extends Component {
	constructor(props) {
		super(props);
		let startDate;
		if (this.props.opportunity.config.date) {
			startDate = moment(this.props.opportunity.config.date).format('DD/MM/YYYY');
		} else {
			startDate = null;
		}
		this.state = {
			startDate,
			volunteersNeeded: this.props.opportunity.config.volunteersNeeded ,
			isTransportRequired: this.props.opportunity.config.isTransportRequired || false,
			isTshirtProvided: this.props.opportunity.config.isTshirtProvided || false,
			isExclusiveOpportunity: this.props.opportunity.config.isExclusiveOpportunity || true,
			// disabled: false,
			disabled: this.props.opportunity.status === 'Completed',
			toggleValue: this.props.opportunity.status !== 'Pending HR Approval',
			//  ?false: true,
			unavailableDates: [],
			datesReady: false,
			changedStatus: this.props.opportunity.status === 'Live' || this.props.opportunity.status === "Pending HR Approval"? "Live" : false ,
			understand: false,
			triggerEmail: this.props.opportunity.config.triggerEmail || false,
			enableNotifications: this.props.opportunity.config.enableNotifications || false,
			error: false,

			cities: [],
			citiesToDisplay: [],
			selectedCities: (this.props.opportunity.config.cities || []).map(x=>({label:x, value:x})) || [],
			citySearchQuery: '',

			offices: [],
			departments: [],
			selectedOffices: [],
			selectedDepartments: [],
			allowGuests: this.props.opportunity.config.allowGuests || false,
			maxGuests: this.props.opportunity.config.maxGuests || '',
			optionCancelledCheck:false,
			displayAsCancelled:false,
			reasonForCancellation:"",
		}
		this.submitApply = this.submitApply.bind(this);
		this.isTransportRequiredChange = this.isTransportRequiredChange.bind(this);
		this.isTshirtProvidedChange = this.isTshirtProvidedChange.bind(this);
		this.isExclusiveOpportunityChange = this.isExclusiveOpportunityChange.bind(this);
		this.changeVolunteersNeeded = this.changeVolunteersNeeded.bind(this);
		this.fetchIBLCities = this.fetchIBLCities.bind(this);
	}

	onChange(field, value) {
		if(field === 'toggleValue') {
			var changedStatus;
			if(!this.state.toggleValue === true) changedStatus = 'live';
			else changedStatus = 'pending';
			if (this.state.toggleValue===false) {
				this.setState({ enableNotifications: false});
			}
			this.setState({ toggleValue: !this.state.toggleValue, changedStatus, understand: false });
		} else {
			this.setState({ [field]: value });
		}
	}

	changeVolunteersNeeded(value) {
		this.setState({ volunteersNeeded: value });
	}

	changeMaxGuests(value) {
		this.setState({ maxGuests: value });
	}

	disabledStartDate(current) {
		if (!current) {
			return false;
		}
		const date = moment();
		date.hour(0);
		date.minute(0);
		date.second(0);
		return current.valueOf() < date.valueOf();  // can not select days before today
	}

	submitApply() {
		let data = {}
		data['voId'] = this.props.volunteerOpportunityId;
		data['isTransportRequired'] = this.state.isTransportRequired;
		data['isTshirtProvided'] = this.state.isTshirtProvided;
		data['isExclusiveOpportunity'] = true;
		data['triggerEmail'] = this.state.triggerEmail;
		data['enableNotifications'] = this.state.enableNotifications;
		data['volunteersNeeded'] = this.state.volunteersNeeded;
		data['cities'] = this.state.selectedCities;
		if(this.state.selectedCities.length === this.state.cities.length) data['cities'] = true;
         data['reasonForCancellation'] = this.state.reasonForCancellation;
		// if(this.state.selectedOffices.length === this.state.offices.length) data['offices'] = true;
		// if(this.state.selectedDepartments.length === this.state.departments.length) data['departments'] = true;
		// data['allowGuests'] = this.state.allowGuests;
		// if(this.state.allowGuests) data['maxGuests'] = this.state.maxGuests;
		if (this.props.opportunity.status === 'Completed' && (this.props.opportunity.status === 'Completed' && (this.state.volunteersNeeded >= this.props.opportunity.minVolunteers)
			&& (this.state.volunteersNeeded <= this.props.opportunity.maxVolunteers))) {

			fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/update-vol-req', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then(function (response) {
				return response.json();
			}).then(function (data) {
				if (data.status === 'ok') {
					fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/admin-discover', {
						method: "GET",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						},
					}).then(function (response) {
						return response.json();
					}).then(function (data) {
						swal(
							'Updated Volunteers',
							'You have successfully updated the volunteers required',
							'success'
						);
						this.props.updateOpportunities(data.data, data.totalOpportunities)
					}.bind(this));
					this.props.closeReserveModal();
				}
			}.bind(this));
		}
		else if (this.state.understand
			// || this.state.changedStatus === false
		) {
			// if(this.state.toggleValue && this.state.selectedOffices.length && ((this.state.allowGuests && this.state.maxGuests && this.state.maxGuests !== '0') || !this.state.allowGuests)) {
			if (this.state.toggleValue
				&& (this.props.opportunity.physicalOpportunity ? this.state.selectedCities.length : true)) {
				fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/make-live', {
					method: "POST",
					headers: {
						'Content-Type': 'application/json',
						'Auth': JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify(data)
				}).then(function(response) {
					return response.json();
				}).then(function(data) {
					if(data.status === 'ok') {
						fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/admin-discover', {
							method: "GET",
							headers: {
								'Auth': JSON.parse(readCookie('access_token')).access_token
							},
						}).then(function(response) {
							return response.json();
						}).then(function(data) {
							swal(
								'Marked as live',
								'You have made this opportunity live',
								'success'
							);
							this.props.updateOpportunities(data.data)
						}.bind(this));
						this.props.closeReserveModal();
					}
				}.bind(this))
			} else if(!this.state.toggleValue && !this.state.displayAsCancelled) {
				fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/make-unlive', {
					method: "POST",
					headers: {
						'Content-Type': 'application/json',
						'Auth': JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify(data)
				}).then(function(response) {
					return response.json();
				}).then(function(data) {
					if(data.status === 'ok') {
						fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/admin-discover', {
							method: "GET",
							headers: {
								'Auth': JSON.parse(readCookie('access_token')).access_token
							},
						}).then(function(response) {
							return response.json();
						}).then(function(data) {
							swal(
								'Marked as hidden',
								'You have marked this opportunity hidden',
								'success'
							);
							this.props.updateOpportunities(data.data, data.totalOpportunities)
						}.bind(this));
						this.props.closeReserveModal();
					}
				}.bind(this));
			} 
			else if(!this.state.toggleValue && this.state.displayAsCancelled){

				data['displayAsCancelled'] = this.state.displayAsCancelled;
				data['reasonForCancellation'] = this.state.reasonForCancellation;

				fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/make-unlive', {
					method: "POST",
					headers: {
						'Content-Type': 'application/json',
						'Auth': JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify(data)
				}).then(function(response) {
					return response.json();
				}).then(function(data) {
					if(data.status === 'ok') {
						fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/admin-discover', {
							method: "GET",
							headers: {
								'Auth': JSON.parse(readCookie('access_token')).access_token
							},
						}).then(function(response) {
							return response.json();
						}).then(function(data) {
							swal(
								'Marked as Cancel',
								'You have marked this opportunity cancel',
								'success'
							);
							this.props.updateOpportunities(data.data)
						}.bind(this));
						this.props.closeReserveModal();
					}
				}.bind(this));

			}
			else {
				this.setState({ error: true });
			}
		} else {
			this.setState({ error: true });
		}
	}

	isTransportRequiredChange(e) {
		this.setState({ isTransportRequired: e.target.checked });
	}

	isTshirtProvidedChange(e) {
		this.setState({ isTshirtProvided: e.target.checked });
	}

	allowGuestsChange(e) {
		this.setState({ allowGuests: e.target.checked });
	}

	isExclusiveOpportunityChange(e) {
		this.setState({ isExclusiveOpportunity: e.target.checked });
	}

	changeUnderstand(e) {
		var error;
		if(e.target.checked) error = false;
		else error = true;
		this.setState({ understand: e.target.checked, error });
	}

	triggerEmail(e) {
		this.setState({ triggerEmail: e.target.checked, enableNotifications: !e.target.checked });
	}

	enableNotificationsCheck(e) {
		this.setState({ triggerEmail: !e.target.checked, enableNotifications: e.target.checked });
	}

	displayAsCancelled(e){
		this.setState({ displayAsCancelled: e.target.checked });
	}

	reasonForCancellation(e){
		this.setState({reasonForCancellation :e.target.value});
	}

	componentDidMount() {
		this.fetchIBLCities();
		fetch(configs.BACKEND_API_URL + '/list', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			let offices = [];
			let selectedOffices = [];
			for(var i = 0; i < data.lists.length; i++) {
				let office = {};
				office['value'] = data.lists[i].id;
				office['label'] = data.lists[i].name;
				if(this.props.opportunity.config.hasOwnProperty('offices')) {
					if(Array.isArray(this.props.opportunity.config.offices)) {
						if(this.props.opportunity.config.offices.indexOf(data.lists[i].id) > -1) {
							office['selected'] = true;
							selectedOffices.push(data.lists[i].id);
						}
					} else if(this.props.opportunity.config.offices === true) {
						office['selected'] = true;
						selectedOffices.push(data.lists[i].id);
					}
				} else {
					office['selected'] = true;
					selectedOffices.push(data.lists[i].id);
				}
				offices.push(office);
			}
      this.setState({ offices, selectedOffices });
    }.bind(this));

    fetch(configs.BACKEND_API_URL + '/departments', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			let departments = [];
			let selectedDepartments = [];
			for(var i = 0; i < data.data.length; i++) {
				let department = {};
				department['value'] = data.data[i];
				department['label'] = data.data[i];
				if(this.props.opportunity.config.hasOwnProperty('departments')) {
					if(Array.isArray(this.props.opportunity.config.departments)) {
						if(this.props.opportunity.config.departments.indexOf(data.data[i]) > -1) {
							department['selected'] = true;
							selectedDepartments.push(data.data[i]);
						}
					} else if(this.props.opportunity.config.departments === true) {
						department['selected'] = true;
						selectedDepartments.push(data.data[i]);
					}
				} else {
					department['selected'] = true;
					selectedDepartments.push(data.data[i]);
				}
				departments.push(department);
			}
      this.setState({ departments, selectedDepartments });
    }.bind(this));
	}
	handleCitySelection(value) {
		// console.log("🚀 ~ file: MakeLive.js:287 ~ handleSearch ~ value", value)
		// let citiesToDisplay = this.state.cities.filter(city => city.value.toLowerCase().includes(value.toLowerCase()));
		// citiesToDisplay = citiesToDisplay.map(city => city.value);
		this.setState({ selectedCities: value });
	}
	handleCitySearch(value) {
		const citiesToDisplay = this.state.cities.filter(city => city.toLowerCase().includes(value.toLowerCase()));
		this.setState({ citiesToDisplay });
	}

	fetchIBLCities() {
		var that = this;
		fetch(configs.BACKEND_API_URL + '/ibl-particulars/city', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			let cities = [];
			// let selectedCities = [];
			for (var i = 0; i < data.cities.length; i++) {
				let city = {};
				city['value'] = data.cities[i];
				city['label'] = data.cities[i];
				// if (that.props.opportunity.config.hasOwnProperty('cities')) {
				// 	if (Array.isArray(that.props.opportunity.config.cities)) {
				// 		if (that.props.opportunity.config.cities.indexOf(data.cities[i]) > -1) {
				// 			city['selected'] = true;
				// 			selectedCities.push(data.cities[i]);
				// 		}
				// 	} else if (that.props.opportunity.config.cities === true) {
				// 		city['selected'] = true;
				// 		selectedCities.push(data.cities[i]);
				// 	}
				// } else {
				// 	city['selected'] = true;
				// 	selectedCities.push(data.cities[i]);
				// }
				cities.push(city);
			}
			that.setState({
				cities,
				// selectedCities
			});
		});
	}
	showButtonText(options, select) {
		if($(select).attr('id') === 'select-offices') {
			let selectedOffices = [];
	    $(options).each(function() {
	      selectedOffices.push($(this).val());
	    });
	    this.setState({ selectedOffices });
	  } else {
	    let selectedDepartments = [];
	    $(options).each(function() {
	      selectedDepartments.push($(this).val());
	    });
	    this.setState({ selectedDepartments });
	  }
		if (options.length === 0) {
			return 'None selected ...';
		} else if (options.length === $(select).find('option').length) {
			return 'All selected';
		} else if (options.length > 1) {
			return options.length + ' selected';
		} else {
			let labels = [];
			options.each(function() {
				if ($(this).attr('label') !== undefined) {
					labels.push($(this).attr('label'));
				} else {
					labels.push($(this).html());
				}
			});
			return labels.join(', ') + '';
		}
  }

  handleMultiselectChange(name, value) {
  }

	render () {
		if(this.props.opportunity) {
			return (
				<Modal show={this.props.showReserveModal} onHide={this.props.closeReserveModal} className="admin-make-volunteer-opportunity-live-modal">
					<Modal.Header closeButton>
						<Modal.Title>MAKE LIVE</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row>
							<Col md={12} className="mb20">
								<Col md={12}>
									<div className="ee-recommend-switch-container">
										<span className="ee-recommend-switch-left-text">Pending Approval</span>
										<Switch onChange={this.onChange.bind(this, 'toggleValue')} disabled={this.state.disabled} checked={this.state.toggleValue} />
										<span className="ee-recommend-switch-right-text">Live</span>
									</div>
								</Col>
								<form name="groupForm" className="make-volunteer-opportunity-live-modal-form">
									{this.props.opportunity.physicalOpportunity && <React.Fragment><Col md={12} className="text-center">
										<div className="form-group">
											<input type="checkbox" className="" id="transport-provided"
									       onChange={this.isTransportRequiredChange}
										   disabled={ (this.props.opportunity.status !== 'Completed' && this.state.toggleValue) ? false : true}
									      defaultChecked={this.props.opportunity.config.isTransportRequired} />
											<label className={this.state.toggleValue ? "checkbox-label" : "checkbox-label"} htmlFor="transport-provided">
												Is transport provided?
											</label>
										</div>
									</Col>

									<Col md={12} className="text-center">
										<div className="form-group">
											<input type="checkbox" className="" id="tshirt-provided"
									      required onChange={this.isTshirtProvidedChange}
													disabled={(this.props.opportunity.status !== 'Completed' && this.state.toggleValue) ? false : true }
									      defaultChecked={this.props.opportunity.config.isTshirtProvided} />
											<label className={this.state.toggleValue ? "checkbox-label" : "checkbox-label"} htmlFor="tshirt-provided">
												Are T-shirts provided?
											</label>
										</div>
									</Col></React.Fragment>}


									<Col md={12} className="text-center">
										<div className="form-group">
											<label className={this.state.toggleValue ? "is-imp control-label" : "control-label"}>Volunteers Needed: </label>
											<div className="volunteers-needed-container">
										    <span>{'Min: ' + this.props.opportunity.minVolunteers}</span>
										    <InputNumber min={this.props.opportunity.minVolunteers}
													disabled={ this.state.toggleValue ? false : true }
										    	max={this.props.opportunity.maxVolunteers} className="form-control"
										    	onChange={this.changeVolunteersNeeded.bind(this)}
										    	defaultValue={this.props.opportunity.config.volunteersNeeded} />
										    <span>{'Max: ' + this.props.opportunity.maxVolunteers}</span>
										  </div>
										</div>
									</Col>


																		<Col md={12} className="text-center">
										<div className="form-group">
											<label className={(this.state.toggleValue && this.props.opportunity.physicalOpportunity) ? "is-imp control-label" : "control-label"}>Cities: </label>
											<div className="volunteers-needed-container">
												<Multiselect
												style={{height:'100%'}}
													// singleSelect={true}
													disable={this.props.opportunity.status === 'Completed'}
													options={this.state.cities} // Options to display in the dropdown
													selectedValues={this.state.selectedCities} // Preselected value to persist in dropdown
													onSelect={this.handleCitySelection.bind(this)} // Function will trigger on select event
													onRemove={this.handleCitySelection.bind(this)} // Function will trigger on remove event
													displayValue="label" // Property name to display in the dropdown options
													
												/>
												
											</div>
										</div>
									</Col>


									<Col md={12} className="mt10">
										{this.state.changedStatus ? (
											<div className="form-group">
												<input type="checkbox" className="" id="consent" required checked={this.state.understand} onChange={this.changeUnderstand.bind(this)}/>
												<label className={this.state.error ? "checkbox-label is-imp error" : "checkbox-label is-imp"} htmlFor="consent">
													{this.state.changedStatus === 'live' && this.state.toggleValue === false ? (
														'I know that my action would make the opportunity disappear from the list of available opportunities.'
													) : this.state.changedStatus === 'live' || this.state.toggleValue === true ? (
														'I understand that once I make this opportunity live, it would become available to all employees and sign-ups shall be activated.'
													) : ('I know that my action would make the opportunity disappear from the list of available opportunities.') 
													 }
												</label>
											</div>
										) : (null)}

										{
										// this.state.changedStatus !== 'live'
										!this.state.toggleValue && this.state.understand 
										// && this.props.opportunity.status === "Pending HR Approval" 
										? (
											<div className="form-group">
												<input type="checkbox" className="" id="consent" required checked={this.state.triggerEmail} onChange={this.triggerEmail.bind(this)}/>
												<label className="checkbox-label" htmlFor="consent">
													Trigger regret email to employees now.
												</label>
											</div>
												) : null}
										{
											(this.state.toggleValue && this.state.understand) || this.state.enableNotifications ? (
												(<div className="form-group">
													<input type="checkbox" className="" disabled={this.props.opportunity.status === 'Completed'} id="consent" required checked={this.state.enableNotifications} onChange={this.enableNotificationsCheck.bind(this)} />
													<label className="checkbox-label" htmlFor="consent">
														Enable notification email to employees.
													</label>
												</div>)
											) : null
										}
										{
										// this.state.changedStatus !== 'live'
										!this.state.toggleValue && this.state.understand 
										// && this.props.opportunity.status === "Pending HR Approval" 
										? (
											<div className="form-group">
												<input type="checkbox" className="" id="consent" required checked={this.state.displayAsCancelled} 
												onChange={this.displayAsCancelled.bind(this)}/>
												<label className="checkbox-label" htmlFor="consent">
													Do you want this event as Cancelled in the employees event list? If you check this,it will show as Cancelled.If you do not, it will disappear from their list.
												</label>
											</div>
										) : (null)}
										{
											this.state.displayAsCancelled ?
											(<>
										<Row>
											<Col className="form-group" md={12} sm ={6} xs={6}>
                    					<label className="checkbox-label is-imp" style={{paddingLeft:0 }}>Briefly write the reason for cancellation for employees to see</label>
                    		<input  type="text" className="form-control" onChange={this.reasonForCancellation.bind(this)}
                      placeholder="Write reason" required defaultValue={this.state.reasonForCancellation} />
                    {/* { !this.state.cancelledText  ? (
                      <span className="red-color">Please write reason for cancellation.</span>
                    ) : (null)} */}
                  </Col>
				  </Row>
											</>):null
										}

									</Col>
								</form>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						{
							((this.props.opportunity.status === 'Completed' && (this.state.volunteersNeeded >= this.props.opportunity.minVolunteers)
								&& (this.state.volunteersNeeded <= this.props.opportunity.maxVolunteers)) ?
								<Button onClick={this.submitApply} className="btn-sq btn-le">Save</Button>
								: ((this.state.volunteersNeeded >= this.props.opportunity.minVolunteers)
							&& (this.state.volunteersNeeded <= this.props.opportunity.maxVolunteers)
							&& this.state.toggleValue)
							? (
						 this.state.understand && 
						 (this.state.volunteersNeeded !== undefined  && 
						this.state.volunteersNeeded !=="" ) ?
						<Button onClick={this.submitApply} className="btn-sq btn-le"> Save </Button>:
						<Button  className="btn-sq btn-save-disabled" disabled>Save</Button> )
						: (this.state.understand && this.state.toggleValue === false 
						&& this.props.opportunity.status === "Live" ? 
						( this.state.toggleValue ===false && this.state.changedStatus === "pending" ? 
						 <Button onClick={this.submitApply} className="btn-sq btn-le"> Save </Button> : ( this.state.understand && this.state.toggleValue ===false 
							&& this.props.opportunity.status === "Live" && this.state.reasonForCancellation !== "" && this.state.displayAsCancelled ? <Button onClick={this.submitApply} className="btn-sq btn-le"> Save </Button>: <Button  className="btn-sq btn-save-disabled" disabled>Save</Button>)) :
						 <Button  className="btn-sq btn-save-disabled" disabled>Save</Button> 
						))}
						{/* { ((this.state.volunteersNeeded !== undefined  && this.state.volunteersNeeded !=="" ) && this.state.understand && this.props.opportunity.status === "Live") 
						|| ((this.state.volunteersNeeded !== undefined  && this.state.volunteersNeeded !=="" ) &&
						 this.state.understand ) ? <Button onClick={this.submitApply} className="btn-sq btn-le"> Save </Button>:<Button  className="btn-sq btn-save-disabled" disabled>Save</Button> } */}
						<Button onClick={this.props.closeReserveModal} className="btn-sq btn-cancel">Close</Button>
					</Modal.Footer>
				</Modal>
			);

			// ( <Button onClick={this.submitApply} className="btn-sq btn-le"> Save </Button>) 
			// 			: (this.state.understand && this.state.toggleValue ===false 
			// 				&& this.props.opportunity.status === "Live"  
			// 				&& this.state.cancelledText !== "" && this.state.changedStatus === "cancel"?
			// 			<Button onClick={this.submitApply} className="btn-sq btn-le"> Save </Button> 
			// 			: <Button  className="btn-sq btn-save-disabled" disabled>Save</Button>) : 
			// 			<Button  className="btn-sq btn-save-disabled" disabled>Save</Button>
						
		} else {
			return null
		}
	}
}