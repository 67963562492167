const initialState = {
	enablePayrollGiving: false,
	enableMatchGiving: false,
	autoApproveFundraisingProjects: false,
	autoApproveVolunteerOpportunities: false,
	enableGiveBack: false,
};

const EEAdminSettingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_ENABLE_PAYROLL_GIVING':
			return Object.assign({}, state, {
				enablePayrollGiving: action.enablePayrollGiving,
			});
		case 'UPDATE_ENABLE_MATCH_GIVING':
			return Object.assign({}, state, {
				enableMatchGiving: action.enableMatchGiving,
			});
		case 'UPDATE_AUTO_APPROVE_FUNDRAISER':
			return Object.assign({}, state, {
				autoApproveFundraisingProjects: action.autoApproveFundraisingProjects,
			});
		case 'UPDATE_AUTO_APPROVE_VOLUNTEER':
			return Object.assign({}, state, {
				autoApproveVolunteerOpportunities: action.autoApproveVolunteerOpportunities,
			});
		case 'UPDATE_ENABLE_GIVE_BACK':
			return Object.assign({}, state, {
				enableGiveBack: action.enableGiveBack,
			});
		default:
			return state
	}
}

export default EEAdminSettingsReducer;