import React, { Component } from 'react';

import {Row, Col, Button} from 'react-bootstrap';
import Icon from 'react-icons-kit';
import { locationArrow } from 'react-icons-kit/fa/locationArrow';
import { calendar } from 'react-icons-kit/fa/calendar';


export default class FeedLiveProjects extends Component {
	constructor(props) {
		super(props);
	}

	render () {
		if(this.props.liveVos) {
			return (
				<Row>
					<Col md={12}>
						<h2 className="ee-page-title mb20 mt20"><u>Live Opportunities</u></h2>
						{this.props.liveVos.map(function (opportunity, i) {
							return (
								<div className="ee-single-opportunity" key={i}>
									<Col md={3} className="ee-single-opportunity-img-container">
										<a href={"/opportunity/" + opportunity.customUrl}>
											<div className="ee-single-opportunity-img"
											     style={{background: 'url(' + opportunity.image + ')'}}
											>{''}</div>
										</a>
									</Col>
									<Col md={9} className="ee-single-opportunity-details-container">
										<div className="ee-title-section">
											<p>
												<a href={"/opportunity/" + opportunity.customUrl}>{opportunity.title}</a>
												<a href={"/opportunity/" + opportunity.customUrl}
												   className="ee-read-more">Read More</a>
											</p>
										</div>
										<div className="ee-location-date-section">
									<span className="ee-location">
										<Icon className="pad-right-10" icon={locationArrow}/>
										<span>{opportunity.city}</span>
									</span>
											<span className="ee-calendar">
										<Icon className="pad-right-10" icon={calendar}/>
										<span>
											{/*{opportunity.eventDates.map(function (eventDate, i) {*/}
											{/*// if (opportunity.eventDates.length - i === 1) return opportunity.eventDates[i]*/}
											{/*// else return opportunity.eventDates[i] + ', '*/}
											{/*return opportunity.eventDates[0]*/}
											{/*})}*/}
											{opportunity.eventDates[0]}
										</span>
									</span>
										</div>
										<div className="ee-related-project-section">
											{/*<a href="#0">Related Projects</a>*/}
											<span>{opportunity.volunteersRequired - opportunity.attendees.length}/{opportunity.volunteersRequired} slots available</span>
											{(() => {
												if(opportunity.isUserAttendee) {
													return (
														<Button
															className="btn-sq btn-cancel btn-pad-5-20" disabled>Applied</Button>
													);
												} else {
													return (
														<Button
															className="ee-apply-btn btn-sq btn-le btn-pad-5-20" data-opp-id={opportunity.id} onClick={this.openReserveModal}>Apply</Button>
													);
												}
											})()}

										</div>
									</Col>
								</div>
							)
						}.bind(this))}
					</Col>
				</Row>
			);
		} else {
			return null;
		}
	}
}