const initialState = {
	name: null,
	email: null,
	message: null,
};

const ContactReducer = (state = initialState, action) => {
	switch (action.type) {

		default:
			return state
	}
}

export default ContactReducer;