import React, { Component } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import configs from "../../../../../config/urlConfigs";
const readCookie = require('../../../../../cookie').readCookie;



export default class UploadCSVModal extends Component {
  constructor(props) {
    super(props);
   
    this.state = {
        file: null,
        uploadFile:true,
    };
    this.onChange = this.onChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  handleUpload(){
    let formData = new FormData();
    formData.append('externalParticipantFiles', this.state.file);
    this.setState({uploadFile:false});
   
    // fetch(process.env.REACT_APP_API_URL + "/ee/external-participants/upload/" + this.props.selectedOpportunity.id,
      fetch(configs.BACKEND_API_URL + "/ee/external-participants/upload/" + this.props.selectedOpportunity.id ,
      {
        method: "POST",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
        body: formData,
      }
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.status == 'ok') {
            this.setState({uploadFile:true});
            this.props.hideUploadCSVModal();
            this.notifySuccess("Uploaded successfully");
            this.props.getExternalParticipantsList();
        } else if(data.error && data.message) {

          console.log("Error in uploading CSV", data.message);
          this.notifyError(data.message);
          this.setState({uploadFile:true});
        }
        else {
          console.log("Error in uploading CSV", data);
          this.notifyError("Error in Uploading CSV");
          this.setState({uploadFile:true});
        //   this.setState({ uploadingLoanApplicationFiles: false });
        }})
  }
        notifySuccess = (text) => toast.success(text);
        notifyError = (text) => toast.error(text);
       
        
        onChange(e) {
            this.setState({file:e.target.files[0]});
        }


  render() {
    return (
      <Modal   className="declare-modal" show={this.props.showUploadCSVModal}
         onHide={this.props.hideUploadCSVModal} >
        <Modal.Header closeButton>
          <Modal.Title className="modal-header-custom">  Upload CSV File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="margin-col-50" md={12} style={{ marginTop:0,marginBottom:10 }}>
            <note className="is-imp " > Note:  External Participant Name, Nature of Relation with Organization (Client, Customer, Influencer, etc.,) & POC Email are mandatory fields.</note>
            </Col>
          </Row>
          <Row>
            
            <Col className="margin-col-50" md={12} style={{ marginTop:0,marginBottom:10 }}>
            <a  style={{textTransform:"capitalize" }}  
                                      href='/img/sample_csv.csv' download='sample.csv'   
                                      className="uploadCSVStyle"
                                      > Download Sample CSV</a>
            </Col>
          </Row>
          <Row>
            <Col className="margin-col-50" md={12} style={{marginTop:"5%"}}>
	            <div className="form-group pb0 mb0" >
                    <input type="file" name='file' multiple={false} accept=".csv"  onChange= {this.onChange}/>
                        </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
        <a  class="btn btn-primary-disabled" 
            onClick={this.props.hideUploadCSVModal} >Close{" "}</a>
           {this.state.uploadFile ?
           <button class="btn btn-primary" onClick={ this.handleUpload}  >Upload{" "}</button> :
           <i className="fa fa-spin fa-spinner fa-3x"></i>}
            </Modal.Footer>
      </Modal>
    );
  }
}
