export const updateOpportunities = (opportunities
	, totalOpportunities
) => {
	return {
		opportunities,
		totalOpportunities,
		type: 'UPDATE_OPPORTUNITIES'
	}
};

export const updatePageNumber = (pageNumber) => {
	return {
		pageNumber,
		type: 'UPDATE_PAGE_NUMBER'
	}
};

export const updateCauses = (causes) => {
	return {
		causes,
		type: 'UPDATE_CAUSES'
	}
};

export const updateSelectedOpportunity = (selectedOpportunity) => {
	return {
		selectedOpportunity,
		type: 'UPDATE_SELECTED_OPPORTUNITY'
	}
};

export const updateLocation = (location) => {
	return {
		location,
		type: 'UPDATE_LOCATION'
	}
};