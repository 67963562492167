export const updateContributionAmount = (employeeContributionAmount) => {
	return {
		employeeContributionAmount,
		type: 'UPDATE_CONTRIBUTION_AMOUNT'
	}
};

export const updateContribution = (employeeContribution) => {
	return {
		employeeContribution,
		type: 'UPDATE_CONTRIBUTION'
	}
};

export const updateContributionCauses = (employeeContributionCauses) => {
	return {
		employeeContributionCauses,
		type: 'UPDATE_CONTRIBUTION_CAUSES'
	}
};

export const updateContributionLocations = (employeeContributionLocations) => {
	return {
		employeeContributionLocations,
		type: 'UPDATE_CONTRIBUTION_LOCATIONS'
	}
};

export const updateMatchingAmount = (employeeMatchingAmount) => {
	return {
		employeeMatchingAmount,
		type: 'UPDATE_MATCHING_AMOUNT'
	}
};

export const updateMatching = (employeeMatching) => {
	return {
		employeeMatching,
		type: 'UPDATE_MATCHING'
	}
};

export const updateMatchingCauses = (employeeMatchingCauses) => {
	return {
		employeeMatchingCauses,
		type: 'UPDATE_MATCHING_CAUSES'
	}
};

export const updateMatchingLocations = (employeeMatchingLocations) => {
	return {
		employeeMatchingLocations,
		type: 'UPDATE_MATCHING_LOCATIONS'
	}
};

export const updatePayrollAmount = (employeePayrollAmount) => {
	return {
		employeePayrollAmount,
		type: 'UPDATE_PAYROLL_AMOUNT'
	}
};

export const updatePayroll = (employeePayroll) => {
	return {
		employeePayroll,
		type: 'UPDATE_PAYROLL'
	}
};

export const updatePayrollCauses = (employeePayrollCauses) => {
	return {
		employeePayrollCauses,
		type: 'UPDATE_PAYROLL_CAUSES'
	}
};

export const updatePayrollLocations = (employeePayrollLocations) => {
	return {
		employeePayrollLocations,
		type: 'UPDATE_PAYROLL_LOCATIONS'
	}
};