import React, { useState, useEffect, useReducer } from 'react'
import { Button, Modal, Row, Col } from 'react-bootstrap'
import { readCookie } from '../../../../../cookie';
import moment from 'moment'
import swal from 'sweetalert2';
import { ToastContainer, toast } from "react-toastify";
import configs from '../../../../../config/urlConfigs';
const SingleOpportunityBackoutModal = ({ show, handleClose, opportunity }) => {
    const [friendsList, setFriendsList] = useState([]);
    const [allowedRegistrations, setAllowedRegistrations] = useState(0);
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0);
    const [isResponse,setIsResponse] = useState(true);

    useEffect(() => {
        getFriendsList();
    }, [])

    const getAllowedRegistations = () => {
        if (parseInt(opportunity.registrationsAllowed) > 0) {
            if (opportunity.volunteersRequired - (opportunity.attendees.length + opportunity.friends.length) > parseInt(opportunity.registrationsAllowed)) {
                setAllowedRegistrations(parseInt(opportunity.registrationsAllowed))
            } else {
                setAllowedRegistrations(opportunity.volunteersRequired - opportunity.attendees.length - 1)
            }
        }
    }

    useEffect(() => {
        opportunity && getAllowedRegistations();
    }, [opportunity])

    const getFriendsList = () => {
        fetch(configs.BACKEND_API_URL + '/ee/friends-list/' + opportunity.id,
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Auth': JSON.parse(readCookie('access_token')).access_token
                },
            }).then(function (response) {
                return response.json();
            }).then(function (data) {
                setFriendsList(data.friends);
            }.bind(this))
    }

    const removeFriends = (id) => {
        swal({
            title: "Are you sure you want to remove this guest? ",
            // text: "Are you sure you want to remove this guest",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result) {
                fetch(configs.BACKEND_API_URL + '/ee/remove-friend/' + id,
                    {
                        method: "PUT",
                        headers: {
                            'Content-Type': 'application/json',
                            'Auth': JSON.parse(readCookie('access_token')).access_token
                        },
                    }).then(function (response) {
                        return response.json();
                    }).then(function () {
                        getFriendsList();
                    }.bind(this))
            }
        })
    }

    const addFriends = () => {
        setIsResponse(false);

        const data = friendsList.filter(x => !x._id);
        fetch(configs.BACKEND_API_URL + '/ee/add-friends/' + opportunity.id,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Auth': JSON.parse(readCookie('access_token')).access_token
                },
                body: JSON.stringify(data)
            }).then(function (response) {
                return response.json();
            }).then(function (data) {
                if (data.status === 'ok') {
                    swal("Great", "List Updated!", "success")
                        .then(function () {
                            window.location.reload();
                        })
                        .catch(function () {
                            window.location.reload();
                        });
                } else {
                    swal("Oops...", "Something went wrong!", "error");
                }
            }.bind(this))
    }

    const friendsDetailsAdded = () => {
        if (friendsList.length > 0) {
            const index = friendsList.length - 1;
            if (friendsList[index].name && friendsList[index].gender && friendsList[index].age) {
                return true
            } else return false
        }
        return true;
    }

    const addRow = () => {
        if (friendsDetailsAdded()) {
            var addRow = [];
            addRow = friendsList;
            addRow.push({ id: friendsList.length });
            setFriendsList(addRow);
            forceUpdate();
        }
    }

    const handleAddFriends = (index, value, key) => {
        let commentRegex = new RegExp(`^[\.a-zA-Z0-9,!?:;@#$~' ]*$`);
        if (commentRegex.test(value) === true || true){
        if (value) {
            if (!friendsList[index].hasOwnProperty('_id')) {
                var friends = friendsList;
                friends[index][key] = value
                setFriendsList(friends);
                forceUpdate();
            }
        }}
        else {
            notifyError("Only letters, numbers and special characters are allowed");
        }
    }
    const notifyError = (text) => toast.error(text);

    return (
        <Modal show={show} onHide={handleClose} bsSize='lg'>
            <Modal.Header closeButton>
                <Modal.Title><strong>Guest list of {JSON.parse(readCookie('userData')).fullName}</strong></Modal.Title>
            </Modal.Header>
            <Modal.Body bsSize='lg'>
                <Row style={{ margin: 0 }}>
                    <Col md={6} sm={6} style={{ padding: 0 }}>
                        <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >Name</label>
                    </Col>
                    <Col md={3} sm={3} style={{ padding: 0 }}>
                        <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsGender' >Gender</label>
                    </Col>
                    <Col md={3} sm={3} style={{ padding: 0 }}>
                        <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >Age</label>
                    </Col>
                    {friendsList.map((friend, index) => <div>
                        <Col md={6} sm={6} style={{ padding: 0 }}>
                            <div className="form-group pb0 mb0" >
                                <input
                                    type="text"
                                    className="form-control"
                                    value={friend.name}
                                    disabled={friend._id}
                                    onChange={(e) => handleAddFriends(index, e.target.value, 'name')}
                                />
                            </div>
                        </Col>

                        <Col md={3} sm={3} style={{ padding: 0 }}>
                            <div className="form-group pb0 mb0">
                                <select
                                    className="form-control"
                                    required
                                    value={friend.gender}
                                    disabled={friend._id}
                                    onChange={(e) => handleAddFriends(index, e.target.value, 'gender')}
                                >
                                    <option selected disabled>Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Prefer not to say">Prefer not to say</option>
                                </select>
                            </div>
                        </Col>
                        <Col md={2} sm={2} style={{ padding: 0 }}>
                            <div className="form-group pb0 mb0">
                                <input
                                    type="number"
                                    className="form-control"
                                    value={friend.age}
                                    disabled={friend._id}
                                    onChange={(e) => handleAddFriends(index, e.target.value, 'age')}
                                />
                            </div>
                        </Col>
                        <Col md={1} sm={1} style={{ padding: 0 }}>
                            <div className="form-group pb0 mb0">
                                <button className='btn btn-primary btn-sm' style={{ marginLeft: 5 }}
                                    // onClick={() => deleterow(index)}
                                    onClick={() => removeFriends(friend._id)}
                                >
                                    remove</button>
                            </div>
                        </Col>
                    </div>)}
                </Row>
                {allowedRegistrations > friendsList.length && <div align='right' style={{ marginTop: 10 }}>
                    <button className={`btn ${friendsDetailsAdded() ? 'btn-primary' : 'btn-primary-disabled'} btn-sm`} onClick={() => addRow()}>
                        <i className="fa fa-plus"></i> add guest
                    </button>
                </div>}
            </Modal.Body>

            { isResponse && friendsDetailsAdded() ? <div align='right' style={{ margin: 10 }}>
                <button className='btn btn-primary' onClick={addFriends}>
                    Save changes
                </button>
            </div> : <div align='right' style={{ margin: 10 }}>
                <button className='btn btn-primary-disabled' >
                    Save changes
                </button> </div>}
        </Modal>
    )
}

export default SingleOpportunityBackoutModal