import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

export default class Billing extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	componentDidMount() {

	}

	render () {
		return (
			<div className="ee-page-container billing-page-container">
				<Row>
					<Col md={12} className="text-center">
						<h2 className="ee-page-title">Billing</h2>
					</Col>
				</Row>
				<Row>
					<Col md={12}>
					</Col>
				</Row>
			</div>
		);
	}
}