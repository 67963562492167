import $ from 'jquery';

export const changeLoginType = (loginType) => {
	if (loginType === 'corporate') {
		let el = $('#signup-ngo');
		$(el).css({'transform':'translateY(1000px)'});
		setTimeout(function () {
			$(el).hide();
			$('.signup-switch-button-corporate').show();
		}, 400);
	} else {
		let el = $('#signup-corporate');
		$(el).css({'transform':'translateY(1000px)'});
		setTimeout(function () {
			$(el).hide();
			$('.signup-switch-button-ngo').show();
		}, 400);
	}
	return {
		loginType,
		type: 'CHANGE_LOGIN_TYPE'
	}
};

export const cancelCorporateSignin = () => {
	let el = $('#signup-ngo');
	$(el).show();
	setTimeout(function () {
		$(el).css({'transform':'translateY(0px)'});
		$('.signup-switch-button-corporate').hide();
	}, 400);
	return {
		type: 'CANCEL_CORPORATE_SIGNIN'
	}
};

export const cancelNgoSignin = () => {
	let el = $('#signup-corporate');
	$(el).show();
	setTimeout(function () {
		$(el).css({'transform':'translateY(0px)'});
		$('.signup-switch-button-ngo').hide();
	}, 400);
	return {
		type: 'CANCEL_NGO_SIGNIN'
	}
};

export const flipCorporateSignin = (el) => {
	$(el).closest('.flip-container').toggleClass('hover');
	return {
		type: 'FLIP_CORPORATE_SIGNIN'
	}
};

export const flipNgoSignin = (el) => {
	$(el).closest('.flip-container').toggleClass('hover');
	return {
		type: 'FLIP_NGO_SIGNIN'
	}
};

export const changeLoginEmail = (loginEmail) => {
	return {
		loginEmail,
		type: 'CHANGE_LOGIN_EMAIL'
	}
};

export const changeloginPassword = (loginPassword) => {
	return {
		loginPassword,
		type: 'CHANGE_LOGIN_PASSWORD'
	}
};