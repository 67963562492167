import React, {Component} from 'react';
import { Row } from 'react-bootstrap';

let google = window.google || null;
let map, marker;
// let theme = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]

export default class SingleOpportunityMap extends Component {
	constructor(props) {
        super(props);
        this.mapRef = React.createRef();
    }

	componentDidMount() {
		if (!google) {
            console.log("Google Maps API is not loaded!");
            return;
        }
		let lat = parseFloat(this.props.selectedOpportunity.latitude);
		let lng = parseFloat(this.props.selectedOpportunity.longitude);
		
		if (!this.mapRef.current) {
            console.log("Map container ref is not available!");
            return;
        }

		map = new google.maps.Map(this.mapRef.current, {
			center: {lat, lng},
			zoom: 13,
			// styles: theme,
			disableDefaultUI: false,
			zoomControl: true
		});
		

		let icon= {
			url: `${process.env.PUBLIC_URL + '/img/red-marker.png'}`, // url
			scaledSize: new google.maps.Size(32, 32), // scaled size
			origin: new google.maps.Point(0,0), // origin
			anchor: new google.maps.Point(12, 32) // anchor
		};

		marker = new google.maps.Marker({
			position: {lat, lng},
			icon: icon,
			map: map,
			animation: google.maps.Animation.DROP,
		});

		marker.setMap(map);
	}

	render() {
		return (
			<Row>
				<div style={{width: this.props.width, height: 200}} ref={this.mapRef}></div>
			</Row>
		);
	}
}