import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as action from '../../../../actions/EEEndorsementHistoryAction';

import EndorsementHistoryList from './EndorsementHistoryList';
import EndorsementHistoryFilter from './EndorsementHistoryFilter';
import configs from '../../../../config/urlConfigs';

const readCookie = require('../../../../cookie.js').readCookie;

class EndorsementHistory extends Component {
	componentDidMount() {
		fetch(configs.BACKEND_API_URL + '/project-history', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			this.props.actions.updateHistory(data.data);
			this.props.actions.updateCountOfProjectsEndorsed(data.countOfProjectsEndorsed);
			this.props.actions.updateTotalContributed(data.totalContributed);
			this.props.actions.updateTotalMatchedByCompany(data.totalMatchedByCompany);
		}.bind(this));
	}

	handleClick(data) {
		if (data === 'endorse-projects') window.location.pathname = '/endorse-projects';
	}

	render() {
		return (
			<div className="ee-page-container endorsement-history-page feed-page">
				<Row>
					<Col md={12} className="text-center mb20">
						<h2 className="ee-page-title">My Contribution History</h2>
					</Col>
					<Col md={4}>
						<div class="right-txt-wrp">
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								{/* <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/hold.png" /> */}
								<img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/IBL/volunteer.png" />
								<div style={{ marginLeft: 10 }}>
									<p class="right-txt-wrp-title" style={{ marginTop: 10 }}>
										{this.props.countOfProjectsEndorsed}
									</p>
									<p className="dashboard-motivational-elements-2-p">Projects Supported</p>
								</div>
							</div>
							<div class="row align-items-center">

							</div>
							{/* <div className="single-stat recommendation mb10">
							<img src="/img/icons/hold.png" />
							<div>
								<span>{this.props.countOfProjectsEndorsed}</span>
								<span>Projects Supported</span>
							</div> */}
						</div>
					</Col>
					<Col md={4}>
						<div class="right-txt-wrp">
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								{/* <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/trophy.png" /> */}
								<img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/IBL/trophy.png" />
								<div style={{ marginLeft: 10 }}>
									<p class="right-txt-wrp-title" style={{ marginTop: 10 }}>
										INR {this.props.totalContributed}
									</p>
									<p className="dashboard-motivational-elements-2-p">Total Contribution</p>
								</div>
							</div>
							<div class="row align-items-center">
							</div>
							{/* <div className="single-stat recommendation mb10">
							<img src="/img/icons/trophy.png" />
							<div>
								<span>{'INR ' + this.props.totalContributed}</span>
								<span>Total Contribution</span>
							</div> */}
						</div>
					</Col>
					{this.props.totalMatchedByCompany !== 0 && this.props.totalMatchedByCompany !== null ? (
						<Col md={4}>
							<div class="right-txt-wrp">
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									<img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/IBL/trophy.png" />
									{/* <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/trophy.png" /> */}
									<div style={{ marginLeft: 10 }}>
										<p class="right-txt-wrp-title" style={{ marginTop: 10 }}>
											INR {this.props.totalMatchedByCompany}
										</p>
										<p className="dashboard-motivational-elements-2-p">Contribution Matched</p>
									</div>
								</div>
								<div class="row align-items-center">
								</div>
								{/* <div className="single-stat recommendation mb10">
								<img src="/img/icons/trophy.png" />
								<div>
									<span>{'INR ' + this.props.totalMatchedByCompany}</span>
									<span>Contribution Matched</span>
								</div> */}
							</div>
						</Col>
					) : (null)}
				</Row>
				{this.props.history !== null ? (
					<div>
						<EndorsementHistoryFilter causes={this.props.causes} history={this.props.history} updateHistory={this.props.actions.updateHistory}
							updateLocation={this.props.actions.updateLocation} updateCauses={this.props.actions.updateCauses} />
						<EndorsementHistoryList history={this.props.history} />
					</div>
				) : (
					null
				)}
			</div>
		);
	}
}

EndorsementHistory.propTypes = {
	history: PropTypes.array,
	causes: PropTypes.array,
	countOfProjectsEndorsed: PropTypes.number,
	totalContributed: PropTypes.number,
	totalMatchedByCompany: PropTypes.number,
};

function mapStateToProps(state) {
	return {
		history: state.EEEndorsementHistory.history,
		causes: state.EEEndorsementHistory.causes,
		countOfProjectsEndorsed: state.EEEndorsementHistory.countOfProjectsEndorsed,
		totalContributed: state.EEEndorsementHistory.totalContributed,
		totalMatchedByCompany: state.EEEndorsementHistory.totalMatchedByCompany,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EndorsementHistory);