import React, { Component } from 'react';
import { Row, Col, Breadcrumb, Modal, Button, Tabs, Tab } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import swal from 'sweetalert2';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import $ from 'jquery';
import { Menu } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from "recharts";
import AdminDeclareModal from "../../../../EE/2-ImpactVolunteering/DiscoverOpportunities/SingleOpportunity/AdminDeclareModal"
import SingleOpportunityFeedbackModal from "../../../../EE/2-ImpactVolunteering/DiscoverOpportunities/SingleOpportunity/SingleOpportunityFeedbackModal"
import 'sweetalert2/dist/sweetalert2.min.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { OrgDataContext } from '../../../../../routes';
import configs from '../../../../../config/urlConfigs';

const readCookie = require('../../../../../cookie.js').readCookie;
let organisationDataContext = null;
class CustomTooltip extends Component {
	render() {
		const { active } = this.props;
		if (active && this.props.payload !== null && this.props.payload.length) {
			const { payload, label } = this.props;
			return (
				<div className="custom-analytics-bar-tooltip">
					<div className="label text-center" style={{ color: '#666', backgroundColor: '#EAEAEA' }}>
						{`${payload[0].payload.date}, Total Views: ${payload[0].payload.Views}`}
					</div>
					<div className="viewers-container">
						{payload[0].payload.viewers.map(function (viewer, index) {
							return (
								<div key={index} className="viewer-container">{`${viewer.name} `}<span>{`(${viewer.viewCount})`}</span></div>
							)
						})}
					</div>
				</div>
			);
		}
		return null;
	}
}

export default class SingleOpportunityAnalytics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			offices: [],
			departments: [],
			office: '',
			department: '',
			selectedOpportunity: null,
			analyticsItems: {},
			dateViewGraphData: [],
			participantsData: [],
			showAdminDeclareModal: false,
			attendeeId: null,
			editingRowIndex: null,
			friendsList:[],
			tableData :props.tableData,
			attendees :[],
			totalGuestParticipation :"",
			totalGuestHours :0,
		}
		this.adminDeclaredAttendance = this.adminDeclaredAttendance.bind(this);
		this.declaredAttendance = this.declaredAttendance.bind(this);
		this.editButton = this.editButton.bind(this);
		this.feedback = this.feedback.bind(this);
	}

	componentDidMount() {
 fetch(configs.BACKEND_API_URL + '/list', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			this.setState({ offices: data.lists });
		}.bind(this));

		fetch(configs.BACKEND_API_URL + '/departments', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			this.setState({ departments: data.data });
		}.bind(this));

		let path = this.props.path;
		fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + path + '/analytics', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				if (data.status === 'ok') {
			fetch( configs.BACKEND_API_URL + 
				'/ee/friends-list/total/' + data.selectedOpportunity.id ,
					{
					  method: "GET",
					  headers: {
						'Content-Type': 'application/json',
						'Auth': JSON.parse(readCookie('access_token')).access_token
					  },
					}).then(function (response) {
					  return response.json();
					}).then(function (d) {
						if(d.status ==="ok"){
							this.setState({ friendsList: d.data });
							{
						 	var num = 0;
						 	const guest = d.data
						 	  ? d.data.map((x) => {
								data.attendees.map((attendeeData)=>{
									if(attendeeData.id === x.volunteerId){
									if (attendeeData['updatedParticipatedAttendence'] !=="" 
									|| attendeeData['participated'] !== "") {
									x.friends.map((data) => {
									 if (data.hasOwnProperty("attended") || attendeeData['updatedParticipatedAttendence'] === true) {
										if(data.attended === true){
										  num++;
										}}
									});
								  }
								  else return null}    })})
						 	  : null;
						 	this.setState({ totalGuestParticipation: num });
						   }
							{if ((data.attendees !== undefined ||
									data.attendees.length !== 0) &&
								  this.state.selectedOpportunity.status === "Completed"
								) {
								 const guest = d.data
									? d.data.map((friendsData) => {
									//    
									data.attendees.map((attendeeData)=>{
									if(friendsData.volunteerId === attendeeData.id){
											if (friendsData.volunteerId) {
										  var num = 0;var sumTime = 0;
										  friendsData.friends.map((data) => {
											if (data.hasOwnProperty("attended") || 
											attendeeData['updatedParticipatedAttendence'] === true) {
											  if (data.attended === true) {
												num++;
											  }
											}
										  });
										 let attendeeHours = attendeeData.hoursClocked.split(":");
										 let attendeeUpdatedHours = attendeeData.updatedHoursTime !== "" ? attendeeData.updatedHoursTime.split(":") :null;
										 let hoursClocked =  attendeeHours[0] * num +":" + attendeeHours[1] * num;
										 let updatedHours =   attendeeUpdatedHours !== null ?attendeeUpdatedHours[0] * num + ":" + attendeeUpdatedHours[1] * num :null;
										 sumTime += moment.duration(updatedHours || hoursClocked).asMinutes();
										
										   }
									
										   var duration = moment.duration(sumTime, "minutes");

										   let h = sumTime / 60,
										   m = sumTime % 60;
										// let totalHours = 
												   this.setState({ totalGuestHours: moment().hours(h).minutes(m).format('HH:mm')});}	})
										
									  }): null;
									} else {
											return null;
											}}}}.bind(this))

					var participantsData = [];
					for (var i = 0; i < data.attendees.length; i++) {
						var participantData = {
							"id": data.attendees[i].id,
							"listId": data.attendees[i].listId,
							"Employee ID": data.attendees[i].employeeId,
							"Full Name": data.attendees[i].fullName,
							"Department": data.attendees[i].department || 'N/A',
							"Mobile": data.attendees[i].mobileNo || 'N/A',
							"Start Time": data.attendees[i].startTime || 'N/A',
							"End Time": data.attendees[i].endTime || 'N/A',
							"Hours Clocked": data.attendees[i].hoursClocked || 'N/A',
							"T-shirt collected": data.attendees[i].tshirtCollected || 'No',
							"Experience": data.attendees[i].experience || 'N/A',
							"Reason": data.attendees[i].reason || 'N/A',
							"updatedParticipatedAttendence":data.attendees[i].updatedParticipatedAttendence || '',
							"updatedEndTime" :data.attendees[i].updatedEndTime ||'N/A',
							"updatedStartTime":data.attendees[i].updatedStartTime || 'N/A',
							"adminName":data.attendees[i].adminName || 'N/A',
							"updatedHoursTime":data.attendees[i].updatedHoursTime || "",
							'participated' :data.attendees[i].participated ,
							'adminId' :data.attendees[i].adminId

						};
						if (data.attendees[i].updatedParticipatedAttendence=== true ||data.attendees[i].participated === true) participantData["Attended Event"] = 'Yes, Attended';
						else participantData["Attended Event"] = 'Not Attended';
						if (data.attendees[i].declared) participantData["Declared Attendance"] = 'Yes, Declared';
						else participantData["Attended Event"] = 'Not Declared';
						if (data.attendees[i].type) {
							var type = data.attendees[i].type;
							if (type === 'mannual') type = 'manual';
							participantData["Declaration Type"] = type.charAt(0).toUpperCase() + type.slice(1);
						} else participantData["Declaration Type"] = 'N/A';
						participantsData.push(participantData);
					}
					this.setState({ selectedOpportunity: data.selectedOpportunity, 
						analyticsItems: data.analyticsItems, dateViewGraphData: data.dateViewGraphData, 
						participantsData , attendees:data.attendees});}}.bind(this));}

	goBack() {
		// if (document.referrer !== '' && document.referrer !== window.location.href) window.location.href = document.referrer;
		// else 
		window.location.pathname = '/volunteer-management';
	}

	gotoOverviewPage() {
		window.location.pathname = window.location.pathname.split('/analytics')[0];
	}

	gotoGalleryPage() {
		window.location.pathname = window.location.pathname.split('/analytics')[0] + '/gallery';
	}

	checkEmpId(cell, row) {
		if (cell) {
			if (JSON.parse(readCookie('userData')).role === 'admin' && row.listId && row.id) {
				return (
					<a href={'/team-management/' + row.listId + '/' + row.id} target="_blank">{cell}</a>
				)
			} else return cell;
		} else return 'N/A';
	}

	checkName(cell, row) {
		if (cell) {
			if (JSON.parse(readCookie('userData')).role === 'admin' && row.listId && row.id) {
				return (
					<a href={'/team-management/' + row.listId + '/' + row.id} target="_blank">{cell}</a>
				)
			} else return cell;
		} else return 'N/A';
	}

	tshirtCollectedFormatter(cell, row) {
		if (cell === 'No') {
			return (
				<div className="tshirt-not-collected">
					No
					<button className="btn tshirt-collected-btn" onClick={this.tshirtCollected.bind(this, row.id)}>Collect</button>
				</div>
			)
		} else return cell;
	}

	tshirtCollected(userId) {
		swal({
			title: 'T-shirt Collected',
			html: '<span class="is-imp">Please select the t-shirt size</span><select id="swal-input1" class="form-control swal2-input" value=""><option value="">Select one size</option><option value="sm">XS</option><option value="s">S</option><option value="m">M</option><option value="l">L</option><option value="xl">XL</option><option value="xxl">XXL</option></select>',
			showCancelButton: true,
			confirmButtonText: 'Save',
			cancelButtonText: 'Cancel'
		}).then(function (result) {
			let size = $('#swal-input1').val();
			if (size !== '' && result) {
				swal({
					title: 'Are you sure?',
					text: "You won't be able to revert this!",
					type: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes, Collected!',
					cancelButtonText: 'No, cancel!'
				}).then(function (result2) {
					if (result2) {
						fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/tshirt-collected', {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
								'Auth': JSON.parse(readCookie('access_token')).access_token
							},
							body: JSON.stringify({
								tshirtSize: size,
								employeeId: userId,
								voId: this.state.selectedOpportunity.id
							})
						}).then((response) => response.json())
							.then(function (data) {
								if (data['status'] === 'ok') {
									swal('Great', 'T-shirt collected!', 'success').then(function () {
										window.location.reload();
									}).catch(function () {
										window.location.reload();
									});
								} else {
									swal('Oops...', 'Something went wrong!', 'error');
								}
							}.bind(this));
					}
				}.bind(this)).catch(swal.noop);
			}
		}.bind(this)).catch(swal.noop);
	}

	getHours(cell, row) {
		if(row['participated'] === true){
				return (
					row["startTime"] +
					" - " +
					row["endTime"] +
					"(" +
					row["hoursClocked"] +
					" hours)"
				);
			} 
			else if (
				row["updatedParticipatedAttendence"] === true &&
				row["updatedStartTime"] !== "" &&
				row["updatedEndTime"] !== ""
			) {
				return (
					row["updatedStartTime"] +
					" - " +
					row["updatedEndTime"] +
					" (" +
					row['updatedHoursTime'] +
					" hours)"
				);
			} else if (
				row["updatedParticipatedAttendence"] === true &&
				row["updatedStartTime"] === "" &&
				row["updatedEndTime"] === ""
			) {
				return (
					row["startTime"] +
					" - " +
					row["endTime"] +
					"(" +
					row["hoursClocked"] +
					" hours)"
				);
			} else {
				return "-";
			}
		
	}

	onOfficeOrDeptChange(field, value) {
		this.setState({ [field]: value.target.value }, this.filterAttendeeAnalytics);
	}

	filterAttendeeAnalytics() {
		let urlParams = '?',
			path = this.props.path;
		if (this.state.office !== '') {
			if (urlParams !== "?") {
				urlParams += '&';
			}urlParams += 'listId=' + this.state.office;
		}
			if (this.state.department !== '') {
			if (urlParams !== "?") {
				urlParams += '&';
			}
			urlParams += 'department=' + this.state.department;
		}
		if (urlParams === '?') urlParams = '';

		fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + path + '/attendee-analytics' + urlParams, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			if (data.status === 'ok') {
				var participantsData = [];
				for (var i = 0; i < data.attendees.length; i++) {
					var participantData = {
						"id": data.attendees[i].id,
						"listId": data.attendees[i].listId,
						"Employee ID": data.attendees[i].employeeId,
						"Full Name": data.attendees[i].fullName,
						"Department": data.attendees[i].department || 'N/A',
						"Mobile": data.attendees[i].mobileNo || 'N/A',
						"Start Time": data.attendees[i].startTime || 'N/A',
						"End Time": data.attendees[i].endTime || 'N/A',
						"Hours Clocked": data.attendees[i].hoursClocked || 'N/A',
						"T-shirt collected": data.attendees[i].tshirtCollected || 'No',
						"Experience": data.attendees[i].experience || 'N/A',
						"Reason": data.attendees[i].reason || 'N/A'
					};
					if (data.attendees[i].participated) participantData["Attended Event"] = 'Yes, Attended';
					else participantData["Attended Event"] = 'Not Attended';
					if (data.attendees[i].declared) participantData["Declared Attendance"] = 'Yes, Declared';
					else participantData["Attended Event"] = 'Not Declared';
					if (data.attendees[i].type) {
						var type = data.attendees[i].type;
						if (type === 'mannual') type = 'manual';
						participantData["Declaration Type"] = type.charAt(0).toUpperCase() + type.slice(1);
					} else participantData["Declaration Type"] = 'N/A';
					participantsData.push(participantData);
				}
				this.setState({ participantsData });
			}
		}.bind(this));
	}
	approvedHours(cell, row) {
		if (cell && cell !== "N/A") {
			if (
				row["updatedParticipatedAttendence"] === true &&
				row["updatedStartTime"] !== "" &&
				row["updatedEndTime"] !== ""
			) {
				return (
					row["updatedStartTime"] +
					" - " +
					row["updatedEndTime"] +
					" (" +
					cell +
					" hours)"
				);
			} else if (
				row["updatedParticipatedAttendence"] === true &&
				row["updatedStartTime"] === "" &&
				row["updatedEndTime"] === ""
			) {
				return (
					row["startTime"] +
					" - " +
					row["endTime"] +
					"(" +
					row["hoursClocked"] +
					" hours)"
				);
			} else {
				return "-";
			}
		} else return "-";
	}
	declaredAttendance(cell, row) {
		if (row.participated === true) {
			return <div>Attendance Declared</div>;
		  } else if (
			row.participated === false &&
			row.updatedParticipatedAttendence === true
		  ) {
			return <div>Attendance Declared</div>;
		  } else if (
			row.participated === "" &&
			row.updatedParticipatedAttendence === true
		  ) {
			return <div>Attendance Declared</div>;
		  } else if (
			row.participated === "" &&
			row.updatedParticipatedAttendence === false
		  ) {
			return <div>Missed</div>;
		  } else if (row.participated === false && row.declared === true) {
			return <div>Missed</div>;
		  } else if (row.participated === "") {
			return <div>Pending Declaration</div>;
		  }
	}

	adminDeclaredAttendance(cell, row) {
		if (row["updatedParticipatedAttendence"] === true) {
			return (<div>Approved</div>);
		} else if (
			row["updatedParticipatedAttendence"] === false) {
			return (<div>Declined </div>);
		} else if (row["adminName"] === "-") {
			return (<div>Pending Approval</div>);
		}
	}
	hideDeclareModal(row) {
		this.setState({ showAdminDeclareModal: false, attendeeId: null });
	}
	showDeclareModal(attendeeDetails, attendeeIndex) {
		this.setState({
			showAdminDeclareModal: true,
			attendeeId: attendeeDetails,
			editingRowIndex: attendeeIndex,
		});
	}
	editButton(cell, row, _, index) {
		if (
			JSON.parse(readCookie("userData")).role === "admin" ||
			(row.listId && row.id)
		) {
			return (
				<Button
					className="btn-sq btn-cancel"
					onClick={this.showDeclareModal.bind(this, row, index)}
				>
					Edit
				</Button>
			);
		} else if (this.state.selectedParticipant === row.id) {
			return (
				<Button
					type="submit"
					className="btn-sq btn-success save"
					value="save"
					id="save"
				>
					Save Changes
				</Button>
			);
		}
	}
	feedback(cell, row, _, index) {
		
		if (row.experience !== "" && row.participated === true) {
		  return (
			<a onClick={this.showFeedbackModal.bind(this, row, index)}>
			  Experience
			</a>
		  );
		} else {
		  return "";
		}
	  }
	hideFeedbackModal() {
		this.setState({ showFeedbackModal: false });
	  }
	  showFeedbackModal(attendeeDetails, attendeeIndex) {
		this.setState({
		  showFeedbackModal: true,
		  attendeeId: attendeeDetails,
		  editingRowIndex: attendeeIndex,
		});
	  }
	

	render() {

		if (this.state.selectedOpportunity !== null && this.state.selectedOpportunity.hasOwnProperty('title')) {
			
			return (
				<OrgDataContext.Consumer>
					{(organisationData) => {
						organisationDataContext = organisationData;
						return <div className="single-opportunity-analytics-page">
							<Row className={
								
								// window.location.pathname.match('/admin') !== null &&
								JSON.parse(readCookie('userData')).role === 'admin' ? "mt30" : ""}>
						<ToastContainer position="bottom-center" autoClose={4000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
						{/* <Col md={12} className="campTitle mb10">
							<span className="title-name single-project-title-name">
								<i className="fa fa-chevron-left mr5" onClick={this.goBack}></i>
								{this.state.selectedOpportunity.title}
							</span>
						</Col> */}
						<Col md={12} className="analytics-container">
							<div className="analytics-items">
								<div className="analytics-item-container">
									<span className="title">Lifetime views</span>
									<span className="value">{this.state.analyticsItems.views || 0}</span>
								</div>
								<div className="analytics-item-container">
									<span className="title">Total Sign-ups</span>
									<span className="value">{this.state.analyticsItems.signups || 0}</span>
								</div>
								<div className="analytics-item-container">
									<span className="title">Total Attendees</span>
									<span className="value">{this.state.analyticsItems.attendees || 0}</span>
								</div>
								<div className="analytics-item-container">
									<span className="title">Hours Clocked</span>
									<span className="value">{this.state.analyticsItems.hoursClocked || 0}</span>
								</div>
								<div className="analytics-item-container">
									<span className="title">Total Back-outs</span>
									<span className="value">{this.state.analyticsItems.backouts || 0}</span>
								</div>
                   {
					   this.state.tableData.hasOwnProperty("registrationsAllowed") ? 
					   (this.state.tableData.registrationsAllowed > 0 ?<div className="analytics-item-container">
					   <span className="title">Total Guests</span>
					   <span className="value">{this.state.totalGuestParticipation || 0}</span>
				   </div> :null ):(null)
				   }
				       {
					   this.state.tableData.hasOwnProperty("registrationsAllowed") ? 
					   (this.state.tableData.registrationsAllowed > 0 ?<div className="analytics-item-container">
					   <span className="title">Total Guest Hours</span>
					   <span className="value">{this.state.totalGuestHours || "00:00"}</span>
				   		</div> :null ):(null)
				   			}</div>
							<div className="date-views-graph-container mt30">
								<ResponsiveContainer height={400} width="95%">
									<LineChart width={900} data={this.state.dateViewGraphData} 
									margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
										<XAxis dataKey="date" />
										<YAxis />
										<CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
										{this.state.dateViewGraphData.length ? (
											<Tooltip content={<CustomTooltip />} />
										) : (null)}
										<Legend />
										<Line type="monotone" dataKey="Views" stroke={process.env.REACT_APP_THEME_COLOR} strokeWidth={3} />
									</LineChart>
								</ResponsiveContainer>
							</div>

							{/* <div className="mt30 attendee-analytics">
								<div className="attendee-analytics-header">
									<span className="heading">Attendee Analytics</span>
									<div className="filters-container">
										<div className="form-group mb0">
											<label className="control-label">Office:</label>
											<select className="inline" value={this.state.office}
												onChange={this.onOfficeOrDeptChange.bind(this, 'office')}>
												<option value=''>All</option>
												{this.state.offices.map(function (office, index) {
													return (
														<option value={office.id}>{office.name}</option>
													)
												})}
											</select>
										</div>

										<div className="form-group mb0">
											<label className="control-label">Department:</label>
											<select className="inline" value={this.state.department}
												onChange={this.onOfficeOrDeptChange.bind(this, 'department')}>
												<option value=''>All</option>
												{this.state.departments.map(function (department, index) {
													return (
														<option value={department}>{department}</option>
													)
												})}
											</select>
										</div>
									</div>
								</div>
								<BootstrapTable data={this.state.attendees} hover
								 exportCSV csvFileName={this.state.selectedOpportunity.title + 
								 ' - Participants - ' + String(moment().unix()) + '.csv'}>
									<TableHeaderColumn isKey dataField='id' hidden>ID</TableHeaderColumn>
									{JSON.parse(readCookie('userData')).role === 'admin' ? (
										<TableHeaderColumn width='100px' dataField='employeeId' export dataFormat={this.checkEmpId}>Employee ID</TableHeaderColumn>
									) : (null)}
									<TableHeaderColumn width='180px' dataField='fullName' export dataSort dataFormat={this.checkName}>Name</TableHeaderColumn>
									<TableHeaderColumn width='130px' dataField='department' export dataSort>Department</TableHeaderColumn>
									<TableHeaderColumn width='120px' dataField='mobileNo' export dataSort>Mobile</TableHeaderColumn>
									{/* <TableHeaderColumn width='120px' dataField='Attended Event' export dataSort>Attended Event</TableHeaderColumn> */}
									{/* <TableHeaderColumn width='150px'
										dataFormat={this.declaredAttendance}
										export dataSort>Declared Attendance</TableHeaderColumn>
									{/* {JSON.parse(readCookie('userData')).role === 'admin' ? (
										<TableHeaderColumn width='140px' dataField='Declaration Type' export dataSort>Declaration Type</TableHeaderColumn>
									) : (null)} 
									{JSON.parse(readCookie('userData')).role === 'admin' ? (
										<TableHeaderColumn width='140px' dataField='tshirtCollected' export dataSort dataFormat={this.tshirtCollectedFormatter.bind(this)}>T-shirt collected</TableHeaderColumn>
									) : (null)}
								
									{this.state.selectedOpportunity.status === 'Completed' ? (
										<TableHeaderColumn width='200px' export dataField='Hours Clocked' dataFormat={this.getHours}>Hours Clocked</TableHeaderColumn>
									) : (null)}
									{this.state.selectedOpportunity.status === 'Completed' ? (
										<TableHeaderColumn width='200px' export dataField='Feedback' dataFormat={this.feedback}  columnTitle>Experience</TableHeaderColumn>
									) : (null)}
								
									{organisationData.eePreferences.enableEmployeeAttendance ? (
										<TableHeaderColumn width="220px" export dataFormat={this.adminDeclaredAttendance} >
											Admin Declared Attendance
										</TableHeaderColumn>
									) : null}
									{organisationData.eePreferences.enableEmployeeAttendance ? (
										<TableHeaderColumn width="200px" export dataField="updatedHoursTime"
											dataFormat={this.approvedHours} >
											Approved Hours
										</TableHeaderColumn>
									) : null}

									{organisationData.eePreferences.enableEmployeeAttendance ? (
										<TableHeaderColumn
											width="140px"
											export
											dataField="adminName"
										// export dataFormat={this.adminName}
										>
											Approver name
										</TableHeaderColumn>
									) : null}
									{organisationData.eePreferences.enableEmployeeAttendance ?
										(
											<TableHeaderColumn
												width="140px"
												export
												dataFormat={this.editButton}
											>
												Edit
											</TableHeaderColumn>
										) : null}
								</BootstrapTable>
							</div> */} 
						</Col>
					</Row>
					{
						this.state.attendeeId !== null &&
							this.state.showAdminDeclareModal ? (
							<AdminDeclareModal
								setParticipants={(data) =>
									this.setState({ participantsData: data })
								}
								participants={this.state.attendees}
								opportunityId={this.state.attendeeId.id}
								tableData = {this.state.tableData}
								index={this.state.editingRowIndex}
								guestList = {this.state.friendsList}
								selectedOppurtunityId={this.state.selectedOpportunity.id}
								showDeclareModalVisibility={this.state.showAdminDeclareModal}
								hideAdminDeclareModal={this.hideDeclareModal.bind(this)}
							/>
						) : null}
						 {this.state.showFeedbackModal ? (
            <SingleOpportunityFeedbackModal
			   selectedOpportunity ={this.state.selectedOpportunity}
              opportunityId={this.state.selectedOpportunity.id}
              showDeclareModalVisibility={this.state.showFeedbackModal}
              index={this.state.editingRowIndex}
              hideDeclareModal={this.hideFeedbackModal.bind(this)}
              participants={this.state.attendees}
              data={this.props}
            />
          ) : null}

				</div>}}
				</OrgDataContext.Consumer>
			)

		} else {
			return null;
		}

	}
}
