import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import moment from 'moment';

import 'bootstrap/dist/css/bootstrap.css';
import configs from '../../config/urlConfigs.js';

const readCookie = require('../../cookie.js').readCookie;

export default class ManageVolunteerOpportunity extends Component {
	constructor(props) {
		super(props);
		this.state = {
			volunteerOpportunities: []
		};
	}

	componentWillMount() {
		fetch(configs.BACKEND_API_URL + '/volunteer-opportunity/discover', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			this.setState({ volunteerOpportunities: data.data });
		}.bind(this));
	}

	manageVO(cell, row) {
		return (
			<button className="btn btn-manage" onClick={this.openManageSingleOpportunity.bind(this, row.id)}>Manage</button>
		)
	}

	addedOn(cell, row) {
		return (
			<span>{moment(cell).format('DD/MM/YYYY')}</span>
		)
	}

	addedBy(cell, row) {
		return (
			<span>{cell.fullName}</span>
		)
	}

	openManageSingleOpportunity(opportunityId) {
		window.location.pathname = '/manage-volunteer-opportunity/' + opportunityId;
	}

	render() {
		if(this.state.volunteerOpportunities.length) {
			const options = {
				clearSearch: true,
				clearSearchBtn: this.createCustomClearButton,
				sizePerPage: 20,
				sizePerPageList: [20, 50, 100]
			};
			return (
				<div className="ee-page-container manage-volunteer-opportunity-page">
					<Col md={12} className="manage-volunteer-opportunity-container">
						<h3>Manage Volunteer Opportunities</h3>
						<BootstrapTable className="manage-volunteer-opportunity-table" data={this.state.volunteerOpportunities} options={options} striped={true} hover={true} search searchPlaceholder="Search by Title">
							<TableHeaderColumn dataField="id" isKey={true} dataSort={false} hidden={true}  searchable={false}>ID</TableHeaderColumn>
							<TableHeaderColumn dataField="title" dataSort={false} columnTitle hidden={false} searchable={true}>Title</TableHeaderColumn>
							<TableHeaderColumn dataField="addedOn" width='200px' dataSort={false} hidden={false} searchable={true} dataFormat={this.addedOn.bind(this)}>Added On</TableHeaderColumn>
							<TableHeaderColumn dataField="addedBy" width='200px' dataSort={false} hidden={false} searchable={true} dataFormat={this.addedBy.bind(this)}>Added By</TableHeaderColumn>
							<TableHeaderColumn dataField="city" width='150px' dataSort={false} hidden={false} searchable={true}>City</TableHeaderColumn>
							<TableHeaderColumn dataField="customUrl" width='110px' dataAlign="center" tdStyle={{ padding: 0 }} dataSort={false} hidden={false} dataFormat={this.manageVO.bind(this)}>Manage</TableHeaderColumn>
						</BootstrapTable>
					</Col>
				</div>
			);
		} else return null;
	}
}