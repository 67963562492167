import moment from 'moment';
import swal from 'sweetalert2';
import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {Row, Col, Modal, Button} from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';

let causes = { "Education": "Education", "Livelihoods": "Livelihoods", "Health": "Health", "Agriculture": "Agriculture" };
let states = { "Andhra Pradesh": "Andhra Pradesh", "Arunachal Pradesh": "Arunachal Pradesh", "Assam": "Assam", "Bihar": "Bihar", "Chhattisgarh": "Chhattisgarh", "Goa": "Goa", "Gujarat": "Gujarat", "Haryana": "Haryana", "Himachal Pradesh": "Himachal Pradesh", "Jammu and Kashmir": "Jammu and Kashmir", "Jharkhand": "Jharkhand", "Karnataka": "Karnataka", "Kerala": "Kerala", "Madhya Pradesh": "Madhya Pradesh", "Maharashtra": "Maharashtra", "Manipur": "Manipur", "Meghalaya": "Meghalaya", "Mizoram": "Mizoram", "Nagaland": "Nagaland", "Odisha": "Odisha", "Punjab": "Punjab", "Rajasthan": "Rajasthan", "Sikkim": "Sikkim", "Tamil Nadu": "Tamil Nadu", "Telangana": "Telangana", "Tripura": "Tripura", "Uttarakhand": "Uttarakhand", "Uttar Pradesh": "Uttar Pradesh", "West Bengal": "West Bengal", "Andaman and Nicobar Islands": "Andaman and Nicobar Islands", "Chandigarh": "Chandigarh", "Dadra and Nagar Haveli": "Dadra and Nagar Haveli", "Daman and Diu": "Daman and Diu", "Delhi": "Delhi", "Lakshadweep": "Lakshadweep", "Puducherry": "Puducherry" };


export default class EEPayrollPartners extends Component {
	constructor(props) {
		super(props);
		this.state = {
			payrollPartnersData: null
		};
	}

	componentDidMount() {
		let approvedNGOs = localStorage.getItem('approvedNGOs');
		if(approvedNGOs) {
			approvedNGOs = JSON.parse(approvedNGOs);
			this.setState({ approvedNGOs, payrollPartnersData: Object.values(approvedNGOs) });
		}
	}

	dataFormat = (type, cell, row) => {
		if(type === "name") {
			if(cell) {
				if(row.customUrl) return <a href={"https://www.letsendorse.com/ngo/" + row.customUrl} target="_blank" title={cell}>{cell}</a>;
				else if(row.id) return <a href={"https://www.letsendorse.com/ngo/" + row.id} target="_blank" title={cell}>{cell}</a>;
				else return cell;
			} else return null;
		} else if(type === "80G") {
			if(cell) return "Yes";
			else return "No";
		} else if(type === "amount") {
			if(cell) return "INR " + cell;
			else return "INR 0";
		} else if(type === "pledge") {
			return <button className="btn pledge-btn" onClick={this.pledge}>I PLEDGE</button>;
		}
	}

	pledge = () => {
		swal({
			type: "warning",
			title: "",
			showCancelButton: true,
			cancelButtonText: 'CANCEL',
			confirmButtonText: 'YES, GO AHEAD',
			text: "Are you sure you want to PLEDGE your contribution this time to this organization? You would not be able to revert this after confirmation."
		}).then((result) => {
			if(result) {
				this.notifySuccess('You have successfully pledged for the organization!');
			}
		});
	}

	notifySuccess = (text) => toast.success(text)

	render() {
		const options = {
			sizePerPage: 20,
			sizePerPageList: [20, 50, 100]
		};
		return (
			<div className="ee-page-container payroll-partners-page">
				<ToastContainer position="bottom-center" autoClose={5000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
				<Row>
					<Col md={12} className="text-center">
						<div className="payroll-partners-banner">
							<div className="banner-img"></div>
							<div className="banner-text">
								<h3>Select from the list of NGOs approved for contribution by your organization</h3>
								<span>Create sustained impact using cyclic giving</span>
							</div>
						</div>

						{this.state.payrollPartnersData !== null ? (
							<BootstrapTable className="payroll-partners-table" data={this.state.payrollPartnersData} options={options} striped={true} hover={true}>
								<TableHeaderColumn dataField="id" isKey={true} hidden={true}>ID</TableHeaderColumn>
								<TableHeaderColumn dataField="name" width='150px' dataAlign="center" hidden={false} dataFormat={this.dataFormat.bind(this, 'name')}>Name</TableHeaderColumn>
								<TableHeaderColumn dataField="cause" width='100px' dataAlign="center" hidden={false} filter={{ type: 'SelectFilter', options: causes }}>Cause</TableHeaderColumn>
								<TableHeaderColumn dataField="state" width='110px' dataAlign="center" hidden={false} filter={{ type: 'SelectFilter', options: states }}>State</TableHeaderColumn>
								<TableHeaderColumn dataField="80G" width='120px' dataAlign="center" hidden={false} dataFormat={this.dataFormat.bind(this, '80G')}>Offers Tax Exemption</TableHeaderColumn>
								<TableHeaderColumn dataField="amount" width='110px' dataAlign="center" hidden={false} dataFormat={this.dataFormat.bind(this, 'amount')}>My Total Contribution</TableHeaderColumn>
								<TableHeaderColumn dataField="id" width='110px' dataAlign="center" columnClassName="pledge-btn-container" hidden={false} dataFormat={this.dataFormat.bind(this, 'pledge')}>PLEDGE SUPPORT</TableHeaderColumn>
							</BootstrapTable>
						) : (null)}
					</Col>
				</Row>
			</div>
		);
	}
}
