import React, { Component } from "react";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UpdateAnOpportunitySwitch from "./UpdateAnOpportunitySwitch";
import * as action from "../../../../../actions/EERecommendOpportunityAction";

class RecommendAnOpportunity extends Component {
  state = {
    disabled: false,
    toggleValue: true,
  };

  onChange() {
    this.setState({ toggleValue: !this.state.toggleValue });
  }

  render() {
    return (
      <div className="ee-page-container">
        <div className="ee-recommend-switch-container" hidden={true}>
          <Switch
            onChange={this.onChange.bind(this)}
            disabled={this.state.disabled}
            checked={this.state.toggleValue}
          />
        </div>
        <UpdateAnOpportunitySwitch
          opportunityDescription={this.props.opportunityDescription}
          opportunityPhone={this.props.opportunityPhone}
          opportunityNote={this.props.opportunityNote}
          opportunityEmail={this.props.opportunityEmail}
          updateOpportunityDescription={
            this.props.actions.updateOpportunityDescription
          }
          updateOpportunityEmail={this.props.actions.updateOpportunityEmail}
          updateOpportunityPhone={this.props.actions.updateOpportunityPhone}
          updateOpportunityNote={this.props.actions.updateOpportunityNote}
          updateOpportunityStatus={this.props.actions.updateOpportunityStatus}
          updateOpportunityFeedback={
            this.props.actions.updateOpportunityFeedback
          }
          showRecommendOpportunityModal={
            this.props.showRecommendOpportunityModal
          }
          closeModal1={this.props.closeModal1}
          openModal1={this.props.openModal1}
          selectedOpportunity={this.props.selectedOpportunity}
          updateOpportunities={this.props.actions.updateOpportunities}
          opportunities={this.props.opportunities}
        />
      </div>
    );
  }
}

RecommendAnOpportunity.propTypes = {
  opportunityDescription: PropTypes.string,
  opportunityEmail: PropTypes.string,
  opportunityPhone: PropTypes.string,
  opportunityNote: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    opportunityDescription: state.EERecommendOpportunity.opportunityDescription,
    opportunityEmail: state.EERecommendOpportunity.opportunityEmail,
    opportunityPhone: state.EERecommendOpportunity.opportunityPhone,
    opportunityNote: state.EERecommendOpportunity.opportunityNote,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(action, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecommendAnOpportunity);
