import React, { Component } from 'react';
import { Row, Col, Breadcrumb, Tabs, Tab, ProgressBar } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DocumentTitle from 'react-document-title';
import moment from 'moment';
import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DisclaimerModal from "../../../2-ImpactVolunteering/DiscoverOpportunities/SingleOpportunity/DisclaimerModal"
import * as action from '../../../../../actions/EEEndorseProjectsAction';
import configs from '../../../../../config/urlConfigs';

const readCookie = require('../../../../../cookie.js').readCookie;

class SingleProject extends Component {
	constructor(props) {
		super();
		this.state = {
			budgetBreakup: [],
			fundraisingTarget: 0,
			disclaimerModal:false,
			targetLink :null,
		};
		localStorage.clear();
	}
	componentDidMount() {
		if (!this.props.selectedProject.hasOwnProperty('customUrl')) {
			let path = this.props.match.params.name;
			fetch(configs.BACKEND_API_URL + '/get-project/' + path, {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
				.then(function (data) {
					if (data.hasOwnProperty('data')) {
						this.props.actions.updateSelectedProject(data['data']);
						this.setState({
							budgetBreakup: data.data.budgetBreakup,
							fundraisingTarget: parseInt(data.data.fundraisingTarget, 10)
						});
						this.makeCalculations();
					} else {
						window.location = "/404";
					}
				}.bind(this));

			switch (this.props.paymentGateway) {
				case 'instamojo':
					if (window.location.search) {
						let urlParams = new URLSearchParams(window.location.search);
						let entries = urlParams.entries();

						let dataToForward = {};
						for (let key of entries) {
							dataToForward[key[0]] = key[1];
						}

						dataToForward.paymentGateway = 'instamojo';


						fetch(configs.BACKEND_API_URL + '/payment/complete', {
							method: "POST",
							headers: {
								'Content-Type': 'application/json',
								'Auth': JSON.parse(readCookie('access_token')).access_token
							},
							body: JSON.stringify(dataToForward)
						}).then((data) => data.json())
							.then(function (data) {
								if (data.status === 'ok') {
									swal(
										"Awesome!",
										"You've successfully endorsed this project.",
										"success"
									);
								} else if (data.status === 'warning') {
									window.location = '/project/' + this.props.selectedProject.customUrl;
								} else {
									swal(
										"Oops!",
										"Something went wrong! Kindly try again.",
										"error"
									);
								}
							}.bind(this));
					}
					break;
				case 'citrus':
					if (window.location.search) {
						let urlParams = new URLSearchParams(window.location.search);
						let entries = urlParams.entries();

						let dataToForward = {};
						for (let key of entries) {
							dataToForward[key[0]] = key[1];
						}

						dataToForward.paymentGateway = 'citrus';


						fetch(configs.BACKEND_API_URL + '/payment/complete', {
							method: "POST",
							headers: {
								'Content-Type': 'application/json',
								'Auth': JSON.parse(readCookie('access_token')).access_token
							},
							body: JSON.stringify(dataToForward)
						}).then((data) => data.json())
							.then(function (data) {
								if (data.status === 'ok') {
									swal(
										"Awesome!",
										"You've successfully endorsed this project.",
										"success"
									);
								} else if (data.status === 'warning') {
									window.location = '/project/' + this.props.selectedProject.customUrl;
								} else {
									swal(
										"Oops!",
										"Something went wrong! Kindly try again.",
										"error"
									);
								}
							}.bind(this));
					}
					break;
				default:
					break;
			}

		}
	}


	showDisclaimerModal(url){
		let orgUrl = 'https://www.letsendorse.com/ngo/' + url
	this.setState({disclaimerModal :true});
		this.setState({targetLink:orgUrl});
	  }
	  hideDisclaimerModal(){
		this.setState({disclaimerModal:false})
	  }

	makeCalculations() {
		let totalBudgetBreakupAmt = 0,
			fundraisingTarget = this.state.fundraisingTarget,
			budgetBreakup = this.state.budgetBreakup;

		budgetBreakup.map(function (budget, i) {
			totalBudgetBreakupAmt += parseInt(budget.budgetAmount, 10);
			let budgetPercent = (budget.budgetAmount / fundraisingTarget) * 100;
			budgetBreakup[i].budgetPercent = Math.round(budgetPercent * 100) / 100 + '%';
		});

		let remainingBudgetAmount = fundraisingTarget - totalBudgetBreakupAmt;
		let remainingBudgetPercent = (remainingBudgetAmount / fundraisingTarget) * 100;

		budgetBreakup.push({
			id: fundraisingTarget * remainingBudgetAmount + totalBudgetBreakupAmt,
			budgetAmount: remainingBudgetAmount,
			budgetDetail: 'Administrative expenses and Transaction charges',
			budgetPercent: Math.round(remainingBudgetPercent * 100) / 100 + '%',
		});

		this.setState({ budgetBreakup });
	}

	clearSelectedProject() {
		let projectJSON = {};
		this.props.actions.updateSelectedProject(projectJSON);
	}

	getHTMLText(html) {
		let tmp = document.createElement("DIV");
		tmp.innerHTML = html;
		return tmp.textContent || tmp.innerText || "";
	}

	render() {
		if (this.props.selectedProject.hasOwnProperty('title')) {
			return (
				<div className="ee-page-container">
					<DocumentTitle title={this.props.selectedProject.title} />
					<Row>
						<Col md={12}>
							<Breadcrumb>
								<Breadcrumb.Item onClick={this.clearSelectedProject.bind(this)}
									href={this.props.location.pathname.match('/admin/') === null ? "/endorse-projects" : "/admin/fundraiser-management"}>
									Endorse Projects
								</Breadcrumb.Item>
								<Breadcrumb.Item active>
									{this.props.selectedProject.title}
								</Breadcrumb.Item>
							</Breadcrumb>
						</Col>

						<div className="col-md-12 campContainer">
							<div className="col-md-8">
								<Row>
									<div className="eyebrow">
										<p className="eyebrow-text left">
											<span className="camp-location">
												<i className="glyphicon glyphicon-map-marker" style={{ color: '#212121' }}></i> {this.props.selectedProject.address}</span>
										</p>
									</div>
									<div className="campTitle">
										<span className="title-name">
											{this.props.selectedProject.title}
										</span>
									</div>
									<div className="desc-container desc-style">
										{this.props.selectedProject.objective}
									</div>
									<div className="mediaContainer " >
										<div className="img-container" style={{ backgroundImage: `${'url(' + this.props.selectedProject.photo + ')'}`, backgroundSize: 'cover', width: '100%', height: '400px' }}>
										</div>
									</div>
									<p className="representation-text">*The visual content above is for representational purpose and may not correspond to this project.
									</p>
								</Row>
							</div>


							<div className="col-md-4 pad30">
								<div className="col-md-12 " >
									<div className="row budget-block">
										<div className="col-md-12 goal-detail">
											<Row className="flex-center">
												<div className="col-md-2">
													<div className="goalImg" style={{
														backgroundImage: `${'url(https://le-uploaded-image-bucket.s3.amazonaws.com/' + this.props.selectedProject.goalData.iconUrl + ')'}`,
														backgroundSize: 'cover'
													}}>

													</div>
												</div>
												<div className="col-md-10 goalname">
													{this.props.selectedProject.goalData.name}
												</div>
											</Row>
										</div>
									</div>

									<div className="row whiteBg bord-btm text-center">
										<div className="col-md-12 top20 " >
											<ProgressBar active now={this.props.selectedProject.fundraisePercentDone} label={`${this.props.selectedProject.fundraisePercentDone}%`} />
										</div>

										<div className="col-md-12 whiteBg snapshot-item bord-btm black-header text-center">
											<div className="top5">
												<span className="number "><span>₹ </span>{this.props.selectedProject.totalRaisedFunds}</span>
												<span className=""> OF </span>
												<span className="number "><span>₹ </span>{this.props.selectedProject.fundraisingTarget}</span>
												<br />
												<span> RAISED</span>
											</div>
										</div>
										<div className="col-md-12 whiteBg snapshot-item bord-btm black-header">
											<div className="top5">
												<span className="number ">{this.props.selectedProject.totalEndorsement}</span>
												ENDORSERS
											</div>
										</div>
										<div className="col-md-12 whiteBg snapshot-item bord-btm black-header text-center">
											<div className="top5">
												<span className="number dayleft">
													{(() => {
														let diff = moment(this.props.selectedProject.endDate).diff(moment(), 'days')
														if (diff > 0) {
															return (
																diff
															);
														} else {
															return (
																0
															)
														}
													})()}
												</span> DAYS LEFT
											</div>
										</div>
										<div className="col-md-12 whiteBg snapshot-item bord-btm black-header last-snapshot">
											<div className="pad20">
												<div className="text-center">
													<form action="/endorse" method="GET" id="endorse-modal-submit" className="">
														<input type="hidden" id="projectId" name="projectId" value={this.props.selectedProject.id} />
														<input type="hidden" id="donorType" name="donorType" value="User" />
														<button type="submit" className="btn btn-endorse" id="trigger-sticky-header">ENDORSE NOW</button>
													</form>
												</div>
												{
													this.props.selectedProject.org.section ? (
														this.props.selectedProject.org.section.map(function (sect, i) {
															if (sect === 'Section 80G') {
																return (
																	<a className="tagItem" key={i}>
																		80G
																		<span className="tooltiptext">
																			This means that the endorser is eligible for a tax-exemption of 50% of her/his contribution to this project.
																		</span>
																	</a>
																)
															} else if (sect === 'FCRA' && this.props.selectedProject.org.fcraPaymentAllowed) {
																return (
																	<a className="tagItem" key={i}>
																		FCRA
																		<span className="tooltiptext">
																			This project is eligible to receive funds from outside India.
																		</span>
																	</a>
																)
															} else if (sect === '35AC') {
																return (
																	<a className="tagItem" key={i}>
																		80G
																		<span className="tooltiptext">
																			This means that the endorser is eligible for a tax-exemption of 100% of her/his contribution to this project.
																		</span>
																	</a>
																)
															} else {
																return null;
															}
														}.bind(this))
													) : (
														null
													)
												}
											</div>
											{
												this.props.selectedProject.org.fcraPaymentAllowed ? (
													null
												) : (
													<div className="col-md-12 brand-color text-center representation-text" style={{ fontSize: '12px', fontWeight: 'normal', padding: '0%', marginBottom: '3%' }}>This project does not accept contributions from foreign citizens (foreign passport holders).</div>
												)
											}
										</div>

										<div className="col-md-12 top20 noPadding " >

											<div className="whiteBg ">
												<div className="col-md-12 goalDetail flex-center">
													<div className="noPadding">
														<div className="ngoImg"
															style={{ backgroundImage: `${'url(https://le-uploaded-image-bucket.s3.amazonaws.com/' + this.props.selectedProject.org.logo}` }}>

														</div>
													</div>
													<div className="black-header" id="byProj">
														Benefiting Organisation <br />
														<a className="brand-color"
														  onClick={this.showDisclaimerModal.bind(this,this.props.selectedProject.org.id)}
														//  href={'https://www.letsendorse.com/ngo/' 
														//  + this.props.selectedProject.org.id} 
														 target="_blank">
															<span className="col-orange">{this.props.selectedProject.org.name}</span>
														</a>
													</div>
												</div>
											</div>


											{(() => {
												if (this.props.selectedProject.projectPartner.length) {
													return (
														<div className="row whiteBg bord-btm" style={{ border: 'none' }}>
															<div className="col-md-12 top20">
																<span className="col-orange">In association with</span>
															</div>
															{
																this.props.selectedProject.projectPartner.map(function (partner, i) {
																	return (
																		<div className="col-md-12" style={{ paddingLeft: 0 }}>
																			<div className="col-md-12 project-partners flex-center m10">
																				<i className="fa fa-hand-o-right"></i>
																				<p style={{ textDecoration: 'none', color: '#333' }} className="ml5">{partner.name}</p>
																			</div>
																		</div>
																	)
																})
															}
														</div>
													)
												} else {
													return null
												}
											})()}

										</div>


										{/*{*/}
										{/*this.props.selectedProject.innovation.length ? (*/}
										{/*<div className="col-md-12 noPadding text-center innovation-tile " >*/}
										{/*<h3 className="black-header projInsp">This Project is Inspired By</h3>*/}
										{/*<br/>*/}
										{/*<div className="inspiration-image">*/}
										{/*<a href={'https://www.letsendorse.com/innovation/' + this.props.selectedProject.innovation.id} target="_blank">*/}
										{/*<img className="img-inspiration" src={'https://le-uploaded-image-bucket.s3.amazonaws.com/' + this.props.selectedProject.innovation.image} />*/}
										{/*<p className="insp-title">{this.props.selectedProject.innovation.title}</p>*/}
										{/*</a>*/}
										{/*</div>*/}
										{/*<div className="inspiration-tile-details">*/}
										{/*<p>*/}
										{/*A solution by:*/}
										{/*<a href={this.props.selectedProject.innovation.websiteUrl} target="_blank" className="le-color">*/}
										{/*{this.props.selectedProject.innovation.orgName}*/}
										{/*</a>*/}
										{/*</p>*/}
										{/*</div>*/}
										{/*</div>*/}
										{/*) : (*/}
										{/*null*/}
										{/*)*/}
										{/*}*/}
									</div>
								</div>
							</div>

							<Col md={12}>
								<div className="tabsContainer">
									<Tabs defaultActiveKey={1} className="nav tab-container black-header mt20 col-md-12" id='single-project-tabs'>
										<Tab eventKey={1} title="OVERVIEW">
											<div className="tab-pane" id="overview">
												<div className="col-md-12 whiteBg tabcontent">
													<div className="row mt50">
														<div className="col-md-12 overviewContainer white-Block">
															<div className="col-md-2">
																<div className="impact-icon">
																	<img src='https://www.letsendorse.com/images/campaign/why.png' alt="THE NEED" />
																</div>
																<h4 className="impact-Title text-center">THE NEED</h4>
															</div>
															<div className="col-md-10">
																<div className="impact-detail padbtm0">
																	<p dangerouslySetInnerHTML={{ __html: this.getHTMLText(this.props.selectedProject.whyCampaign) }}></p>
																</div>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-12 overviewContainer white-Block">
															<div className="col-md-2">
																<div className="impact-icon">
																	<img src='https://www.letsendorse.com/images/campaign/action.png' alt="ACTION" />
																</div>
																<h4 className="impact-Title text-center">PLAN OF ACTION</h4>
															</div>
															<div className="col-md-10">
																<div className="impact-detail padbtm0">
																	<p dangerouslySetInnerHTML={{ __html: this.getHTMLText(this.props.selectedProject.description) }}></p>
																</div>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-12 overviewContainer white-Block">
															<div className="col-md-2">
																<div className="impact-icon">
																	<img src='https://www.letsendorse.com/images/campaign/impact.png' alt="EXPECTED IMPACT" />
																</div>
																<h4 className="impact-Title text-center">EXPECTED IMPACT</h4>
															</div>
															<div className="col-md-10">
																<div className="impact-detail padbtm0">
																	<p dangerouslySetInnerHTML={{ __html: this.getHTMLText(this.props.selectedProject.expectedImpact) }}></p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</Tab>
										<Tab eventKey={2} title="BUDGET DETAILS" id='budget-details'>
											<div className="tab-pane " id="budgetDetail" >
												<div className="col-md-12 whiteBg mt50 tabcontent">
													{
														this.state.budgetBreakup.length ? (
															<BootstrapTable data={this.state.budgetBreakup} hover>
																<TableHeaderColumn isKey dataField='budgetDetail' dataSort>Expense Header</TableHeaderColumn>
																<TableHeaderColumn dataField='budgetAmount' dataSort>Amount</TableHeaderColumn>
																<TableHeaderColumn dataField='budgetPercent' dataSort>Percentage</TableHeaderColumn>
															</BootstrapTable>
														) : (
															null
														)
													}
												</div>
											</div>
										</Tab>
									</Tabs>
								</div>
							</Col>
						</div>
					</Row>

					{ this.state.disclaimerModal ? (
              <DisclaimerModal 
              disclaimerModalVisibility = {this.state.disclaimerModal}
              hideDisclaimerModal ={this.hideDisclaimerModal.bind(this)}
              targetLink ={this.state.targetLink}/>) :null}



				</div>
				
			)
		} else {
			return null;
		}
	}
}

SingleProject.propTypes = {
	selectedProject: PropTypes.object,
};

function mapStateToProps(state) {
	return {
		selectedProject: state.EEEndorseProject.selectedProject,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleProject);