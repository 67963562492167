export const updateOpportunities = (opportunities,
	totalOpportunities
) => {
	return {
		opportunities,
		totalOpportunities,
		type: 'UPDATE_OPPORTUNITIES'
	}
};

export const updatePageNumber = (pageNumber) => {
	return {
		pageNumber,
		type: 'UPDATE_PAGE_NUMBER'
	}
};

export const updateSelectedOpportunity = (selectedOpportunity) => {
	var participantsData = [];
	for (var i = 0; i < selectedOpportunity.attendees.length; i++) {
		var participantData = {
			"id": selectedOpportunity.attendees[i].id,
			"listId": selectedOpportunity.attendees[i].listId,
			"Employee ID": selectedOpportunity.attendees[i].employeeId,
			"Full Name": selectedOpportunity.attendees[i].fullName,
			"Department": selectedOpportunity.attendees[i].department || 'N/A',
			"Mobile": selectedOpportunity.attendees[i].mobileNo || 'N/A',
			"Start Time": selectedOpportunity.attendees[i].startTime || 'N/A',
			"End Time": selectedOpportunity.attendees[i].endTime || 'N/A',
			"Hours Clocked": selectedOpportunity.attendees[i].hoursClocked || 'N/A',
			"T-shirt collected": selectedOpportunity.attendees[i].tshirtCollected || 'No',
			"Experience":selectedOpportunity.attendees[i].experience||"",
			"Created on":selectedOpportunity.attendees[i].createdOn || '',
		    "updatedParticipatedAttendence": selectedOpportunity.attendees[i].updatedParticipatedAttendence,
			"updatedStartTime": selectedOpportunity.attendees[i].updatedStartTime || '-',
			"updatedEndTime": selectedOpportunity.attendees[i].updatedEndTime || '-',
			"updatedHoursTime": selectedOpportunity.attendees[i].updatedHoursTime || '-',
		};
		if (selectedOpportunity.attendees[i].participated) participantData["Attended Event"] = 'Yes, Attended';
		else participantData["Attended Event"] = 'Not Attended';
		if (selectedOpportunity.attendees[i].declared) participantData["Declared Attendance"] = 'Yes, Declared';
		else participantData["Attended Event"] = 'Not Declared';
		if (selectedOpportunity.attendees[i].type) {
			var type = selectedOpportunity.attendees[i].type;
			if (type === 'manual') type = 'manual';
			participantData["Declaration Type"] = type.charAt(0).toUpperCase() + type.slice(1);
		} else participantData["Declaration Type"] = 'N/A';
		participantsData.push(participantData);
	}
	return {
		participantsData,
		selectedOpportunity,
		type: 'UPDATE_SELECTED_OPPORTUNITY',

	}
};

export const updateCauses = (causes) => {
	return {
		causes,
		type: 'UPDATE_CAUSES'
	}
};

export const updateLocation = (location) => {
	return {
		location,
		type: 'UPDATE_LOCATION'
	}
};

export const updateCommentText = (commentText) => {
	return {
		commentText,
		type: 'UPDATE_COMMENT_TEXT'
	}
};

export const updateCommentImage = (commentImage) => {
	return {
		commentImage,
		type: 'UPDATE_COMMENT_IMAGE'
	}
};

export const updateSubCommentText = (subCommentText) => {
	return {
		subCommentText,
		type: 'UPDATE_SUB_COMMENT_TEXT'
	}
};

export const updateCommentId = (commentId) => {
	return {
		commentId,
		type: 'UPDATE_COMMENT_ID'
	}
};

export const updateToggleCommentPost = (toggleCommentPost) => {
	return {
		toggleCommentPost,
		type: 'UPDATE_TOGGLE_COMMENT_POST'
	}
};