import React, {Component,useRef} from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, Cell, Text, Sector, LabelList, Legend } from 'recharts';
import {Row, Col} from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn, ButtonGroup, ExportCSVButton } from 'react-bootstrap-table';
import moment from 'moment';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import VolunteeringAnalyticsFilter from "./VolunteeringAnalyticsFilter";

import * as action from '../../../../../actions/EEAdminVolunteeringAnalyticsAction';
import configs from '../../../../../config/urlConfigs';

const readCookie = require('../../../../../cookie.js').readCookie;
import { CSVLink, CSVDownload } from 'react-csv';

class CustomTooltip extends Component {
  render() {
    const { active } = this.props;
    if (active && this.props.payload !== null && this.props.payload.length) {
      const { payload, label } = this.props;
      return (
        <div className="custom-analytics-bar-tooltip text-center">
          <div className="label" style={{color: payload[0].payload.backgroundColor, backgroundColor: '#EAEAEA'}}>{`${payload[0].payload.department || ''}`}</div>
          {payload[0].payload.totalHours === undefined ?  
		  (<p className="label">{` ${payload[0].payload.totalHours} hours`}</p>)
		  :(payload[0].payload.guestHours === undefined ?(<p className="label">{`${payload[0].payload.totalHours } hours`}</p>):(<p className="label">{`${payload[0].payload.totalHours } hours ${payload[0].payload.totalHours} hours`}</p>)) 
 }</div>);
    }
    return null;
  }
}

const COLORS = ['#1034A6', '#0080FF', '#0F52BA', '#008ECC', '#6593F5', '#57A0D3', '#4682B4'];

class VolunteeringAnalytics extends Component {
	constructor() {
		super();
		this.state = {
			employeeVolunteering: null,
			activeIndex: 0,
			totalGuest :0,
			tableData:[],
			volunteer:'',
			participantData:[],
			partcipantsCsv :false
		};
		this.filterResult = this.filterResult.bind(this);

		this.onPieEnter = this.onPieEnter.bind(this);
		this.updateEmployeeVolunteering = this.updateEmployeeVolunteering.bind(this);
		this.tickFormatter = this.tickFormatter.bind(this);
		this.fetchParticipantData = this.fetchParticipantData.bind(this);
		this.csvLinkRef = React.createRef();
	}

	getInitialState() {
    return {
      activeIndex: 0
    };
  }

  onPieEnter(data, index) {
    this.setState({
      activeIndex: index,
    });
  }
	filterResult() {
		let urlParams = "?";
		if (this.state.lifetime) {
			if (urlParams !== "?") {
				urlParams += "&";
			}
			urlParams += "lifetime=" + this.state.lifetime;
		}

		if (this.state.startValue && this.state.startValue) {
			if (urlParams !== "?") {
				urlParams += "&";
			}
			urlParams += "startDate=" + this.state.startValue;
		}

		if (this.state.endValu && this.state.endValue) {
			if (urlParams !== "?") {
				urlParams += "&";
			}

			urlParams += "endDate=" + this.state.endValue;
		}

		if (this.state.office && this.state.office !== "") {
			if (urlParams !== "?") {
				urlParams += "&";
			}

			urlParams += "listId=" + this.state.office;
		}

		if (this.state.department && this.state.department !== "") {
			if (urlParams !== "?") {
				urlParams += "&";
			}

			urlParams += "department=" + this.state.department;
		}

		if (urlParams === "?") urlParams = "";

		fetch(
			configs.BACKEND_API_URL +
			"/ee/volunteer-opportunity/per-day-hours" +
			urlParams,
			{
				method: "GET",
				headers: {
					Auth: JSON.parse(readCookie("access_token")).access_token,
				},
			}
		)
			.then(function (response) {
				return response.json();
			})
			.then(
				function (data) {

					let totalAttendeeHours = 0, guestTotalHours = 0;

					if (this.state.volunteer === "guestHours") {
						let d = { tableData: [], goalsData: [], volunteeringHoursData: [], totalExternalParticipantsHours: '00:00', totalAttendeeHours: '00:00', totalGuestHours: '00:00', };

						data.eventIdTableData.map((x) => {
							// totalAttendeeHours+= data.hours
							d.tableData.push({
								totalGuest: x.totalGuest,
								guestHours: x.guestHours,
								customUrl: x.customUrl,
								date: x.date,
								id: x.id,
								title: x.title,
							});
						});
						data.goalsData.map((data) => {
							d.goalsData.push({
								axisLabel: data.axisLabel,
								backgroundColor: data.backgroundColor,
								totalGuestHours: data.totalGuestHours,
								iconUrl: data.iconUrl,
								id: data.id,
								name: data.name,
								value: data.value,
							});
						});
						data.volunteeringHoursData.map((data) => {
							d.volunteeringHoursData.push({
								date: data.date,
								totalGuestHours: data.totalGuestHours,
							});
						});

						d.attendeeHours = totalAttendeeHours;
						d.totalGuestHours = data.totalGuestHours;
						d.totalExternalParticipantsHours = data.totalExternalParticipantsHours;

						d['departmentData'] = data.departmentData;

						this.props.getContribution(this.state.volunteer);
						this.updateEmployeeVolunteering(d);
					} else if (this.state.volunteer === "employeesHours") {
						let d = {
							tableData: data.tableData,
							goalsData: [],
							volunteeringHoursData: [],
							totalAttendeeHours: data.totalAttendeeHours,
							totalGuestHours: data.totalGuestHours,
							totalExternalParticipantsHours: data.totalExternalParticipantsHours
						};
						// data.eventIdTableData.map((x) => {
						//   totalAttendeeHours+= x.hours;
						//   guestTotalHours+= x.guestHours;
						//   // console.log("x.guestHours .. . . .. . >>>>",x.guestHours);


						//   d.tableData.push({
						//     signUpAttendees :x.signUpAttendees,
						//     totalGuestAttendees : x.totalGuestAttendees,
						//     totalGuestHours: x.totalGuestHours,
						//     customUrl: x.customUrl,
						//     date: x.date,
						//     hours: x.hours,
						//     id: x.id,
						//     title: x.title,
						//     totalAttendees: x.totalAttendees,
						//     guestHours: x.guestHours,
						//     externalParticipantsHours :x.externalParticipantsHours,
						//   });
						// });
						// data.goalsData.map((data) => {
						//   d.goalsData.push({
						//     axisLabel: data.axisLabel,
						//     backgroundColor: data.backgroundColor,
						//     hours: data.hours,
						//     iconUrl: data.iconUrl,
						//     id: data.id,
						//     name: data.name,
						//     value: data.value,
						//   });
						// });
						// data.volunteeringHoursData.map((data) => {
						//   d.volunteeringHoursData.push({
						//     date: data.date,
						//     hours: data.hours,
						//   });
						// });

						// console.log("x.guestHours .. . . .. . >>>>",x.guestHours);
						// d.totalGuestHours = data.totalGuestHours;
						d['departmentData'] = data.departmentData;
						d.totalExternalParticipantsHours = data.totalExternalParticipantsHours;
						this.props.getContribution(this.state.volunteer);
						this.updateEmployeeVolunteering(d);
					} else if (this.state.volunteer === "employeesAndGuestHours") {
						this.props.getContribution(this.state.volunteer);
						this.updateEmployeeVolunteering(data);
					} else {
						this.updateEmployeeVolunteering(data);
					}
				}.bind(this)
			);
	}

	fetchParticipantData() {

      this.setState({partcipantsCsv:true})
		let urlParams = "?";
		if (this.state.lifetime) {
			if (urlParams !== "?") {
				urlParams += "&";
			}
			urlParams += "lifetime=" + this.state.lifetime;
		}

		if (this.state.startValue && this.state.startValue) {
			if (urlParams !== "?") {
				urlParams += "&";
			}
			urlParams += "startDate=" + this.state.startValue;
		}

		if (this.state.endValu && this.state.endValue) {
			if (urlParams !== "?") {
				urlParams += "&";
			}

			urlParams += "endDate=" + this.state.endValue;
		}

		if (this.state.office && this.state.office !== "") {
			if (urlParams !== "?") {
				urlParams += "&";
			}

			urlParams += "listId=" + this.state.office;
		}

		if (this.state.department && this.state.department !== "") {
			if (urlParams !== "?") {
				urlParams += "&";
			}

			urlParams += "department=" + this.state.department;
		}

		if (urlParams === "?") urlParams = "";

		fetch(
			configs.BACKEND_API_URL +
			"/ee/volunteer-opportunity/per-day-hours/participants" +
			urlParams,
			{
				method: "GET",
				headers: {
					Auth: JSON.parse(readCookie("access_token")).access_token,
				},
			}
		)
			.then(function (response) {
				return response.json();
			})
			.then(
				function (data) {
					let updatedParticipantsData = []
					data.tableData.forEach((opportunity) => {
						opportunity.participantsData.forEach((item) => {
							updatedParticipantsData.push({
								"Opportunity Id": opportunity.id,
								"Opportunity Title": opportunity.title,
								"Date":opportunity.date,
								"Status":opportunity.status,
								"Hexa ID": item.userId,
								"Name": item.fullName,
								'EmployeeId': item.employeeId,
								"Email": item.email,
								"Role": item.role,
								"Mobile": item.mobileNo || "",
								"Whatsapp": item.whatsapp || "",
								"Alternative Number": item.alternateMobileNumber || "",
								"Gender": item.gender,
								"Designation": item.designationAtOrganisation || "",
								"Department": item.department || "",
								"Transport Required": item.transportRequired || "No",
								"Participation Status": item.participated ? "Attendance Declared"
								  : (!item.participated && item.declared ? "Missed" : ""),
								"Hours": item.hasOwnProperty("hoursClocked") && item.hoursClocked === "N/A" ? ("") :
								  (item.hasOwnProperty("hoursClocked") && item.hoursClocked !== "N/A" ? (moment(item.hoursClocked, "HH:mm").format("HH.mm")) : ("")),
					   
								// Adding all data from pulse
								"DOJ": item.doj,
								"CURRENT_DESIGNATION": item.designationAtOrganisation,
								"BUSINESS_UNIT": item.business_unit,
								"OU NAME": item.ou_Name,
								"REPORTING MANAGER": item.reporting_manager_emp_ID,
								"REPORTING_MANAGER_NAME": item.reporting_manager_name,
								"REPORTING_MANAGER_DESIGNATION": item.reporting_manager_designation || "",
								"REPORTING_MANAGER_MAILID": item.reporting_manager_email || "",
								"FUNCTIONAL REPORTING MANAGER": item.funtional_reporting_manager_emp_ID || "",
								"COUNTRY": item.country || "",
								"EMPLOYEE STATUS": item.employee_status || "",
								"STATUS": item.status || "",
								"BRANCH_CODE": item.branch_code || "",
								"BRANCH ADDRESS": item.branch_address || "",
								"CURRENT_LOCATION": item.location || "",
								"CITY_NAME": item.city || "",
								"REGION": item.region || "",
								"ZONE": item.zone || "",
								"Shift Start Time": item.shift_start || "",
						   
								// moment(item.hoursClocked,"HH:mm").format("HH.mm")  
								"Experience": item.experience,
								"Question-1": item.questionSelectedOption1,
								"Question-2": item.questionSelectedOption2,
								"Question-3": item.questionSelectedOption3,
								"Reason": item.reason,
								"Admin Approved Status": item.updatedParticipatedAttendence === true ?
								  "Approved" : (item.updatedParticipatedAttendence === false ? "Unapproved" : ""),
								"Admin Approved Hours": item.updatedHoursTime,
								"Admin Approved Start Time": item.updatedStartTime,
								"Admin Approved End Time": item.updatedEndTime,
								"Guest-Count": opportunity.guestCount,
					   
								//  ===================from here =================
								"Guest-1 Name": item?.friends && item?.friends?.length > 0 && item?.friends[0]?.name || "",
								"Guest-1 Age": item?.friends && item?.friends?.length > 0 && item?.friends[0]?.age || "",
								"Guest-1 Gender": item?.friends && item?.friends?.length > 0 && item?.friends[0]?.gender || "",
								"Guest-1 Status": item?.friends && item?.friends?.length > 0 && item?.friends[0]?.hasOwnProperty("attended") ? (item?.friends && item?.friends?.length > 0 && item?.friends[0]?.attended === true ? "Approved" :
								  item?.friends && item?.friends?.length > 0 && item?.friends[0]?.attended === false ? "Unapproved" : "") : "",
								"Guest-1 Status-Approved": item?.friends && item?.friends?.length > 0 && item?.friends[0]?.hasOwnProperty('adminUpdatedAttendance') &&
								  item?.friends[0]?.hasOwnProperty('startTime') ? (item?.friends && item?.friends?.length > 0 && item?.friends[0]?.adminUpdatedAttendance === true &&
									item?.friends[0]?.startTime !== null ? "Approved" : item?.friends && item?.friends?.length > 0 && item?.friends[0]?.adminUpdatedAttendance
									  && item?.friends[0]?.attended ? "Unapproved" : "") : "",
					   
								"Guest-2 Name": item?.friends && item?.friends?.length > 0 && item?.friends[1]?.name || "",
								"Guest-2 Age": item?.friends && item?.friends?.length > 0 && item?.friends[1]?.age || "",
								"Guest-2 Gender": item?.friends && item?.friends?.length > 0 && item?.friends[1]?.gender || "",
								"Guest-2 Status": item?.friends && item?.friends?.length > 0 &&
								  item?.friends[1]?.hasOwnProperty("attended") ?
								  (item?.friends && item?.friends?.length > 0 &&
									item?.friends[1]?.attended === true ? "Approved" :
									item?.friends[1]?.attended === false ? "Unapproved" : "") : "",
								"Guest-2 Status-Approved": item.friends && item.friends.length > 0 &&
								  item?.friends[1]?.hasOwnProperty('adminUpdatedAttendance') &&
								  item?.friends[1]?.hasOwnProperty('startTime') ? (item.friends && item.friends.length > 0 &&
									item?.friends[1]?.adminUpdatedAttendance === true &&
									item.friends && item.friends.length > 0 && item?.friends[1]?.startTime !== null ? "Approved" :
									item.friends && item.friends.length > 0 && item?.friends[1]?.adminUpdatedAttendance
									  && item?.friends[1]?.attended ? "Unapproved" : "") : "",
					   
								"Guest-3 Name": item.friends && item.friends.length > 0 && item?.friends[2]?.name || "",
								"Guest-3 Age": item.friends && item.friends.length > 0 && item?.friends[2]?.age || "",
								"Guest-3 Gender": item.friends && item.friends.length > 0 && item?.friends[2]?.gender || "",
								"Guest-3 Status": item.friends && item.friends.length > 0 && item?.friends[2]?.hasOwnProperty("attended") ? (item.friends && item.friends.length > 0 && item?.friends[2]?.attended === true ? "Approved" :
								  item.friends && item.friends.length > 0 && item?.friends[2]?.attended === false ? "Unapproved" : "") : "",
								"Guest-3 Status-Approved": item.friends && item.friends.length > 0 &&
								  item?.friends[2]?.hasOwnProperty('adminUpdatedAttendance') &&
								  item.friends && item.friends.length > 0 && item?.friends[2]?.hasOwnProperty('startTime') ? (item.friends && item.friends.length > 0 && item?.friends[2]?.adminUpdatedAttendance === true &&
									item?.friends[2]?.startTime !== null ? "Approved" : item.friends && item.friends.length > 0 && item?.friends[2]?.adminUpdatedAttendance
									  && item?.friends[2]?.attended ? "Unapproved" : "") : "",
					   
								   
					   
								"Guest-4 Name": item.friends && item.friends.length > 0 && item?.friends[3]?.name || "",
								"Guest-4 Age": item.friends && item.friends.length > 0 && item?.friends[3]?.age || "",
								"Guest-4 Gender": item.friends && item.friends.length > 0 && item?.friends[3]?.gender || "",
								"Guest-4 Status": item.friends && item.friends.length > 0 && item?.friends[3]?.hasOwnProperty("attended") ? item.friends && item.friends.length > 0 && (item?.friends[3]?.attended === true ? "Approved" :
								  item.friends && item.friends.length > 0 && item?.friends[3]?.attended === false ? "Unapproved" : "") : "",
								"Guest-4 Status-Approved": item.friends && item.friends.length > 0 && item?.friends[3]?.hasOwnProperty('adminUpdatedAttendance') &&
								  item.friends && item.friends.length > 0 && item?.friends[3]?.hasOwnProperty('startTime') ? item.friends && item.friends.length > 0 && (item?.friends[3]?.adminUpdatedAttendance === true &&
									item.friends && item.friends.length > 0 && item?.friends[3]?.startTime !== null ? "Approved" : item.friends && item.friends.length > 0 && item?.friends[3]?.adminUpdatedAttendance
									  && item.friends && item.friends.length > 0 && item?.friends[3]?.attended ? "Unapproved" : "") : "",
								
							   
								"Guest-5 Name": item.friends && item.friends.length > 0 && item?.friends[4]?.name || "",
								"Guest-5 Age": item.friends && item.friends.length > 0 && item?.friends[4]?.age || "",
								"Guest-5 Gender": item.friends && item.friends.length > 0 && item?.friends[4]?.gender || "",
								"Guest-5 Status": item.friends && item.friends.length > 0 && item?.friends[4]?.hasOwnProperty("attended") ? (item.friends && item.friends.length > 0 && item?.friends[4]?.attended === true ? "Approved" :
								  item.friends && item.friends.length > 0 && item?.friends[4]?.attended === false ? "Unapproved" : "") : "",
								"Guest-5 Status-Approved": item.friends && item.friends.length > 0 && item?.friends[4]?.hasOwnProperty('adminUpdatedAttendance') &&
								  item.friends && item.friends.length > 0 && item?.friends[4]?.hasOwnProperty('startTime') ? (item.friends && item.friends.length > 0 && item?.friends[4]?.adminUpdatedAttendance === true &&
									item.friends && item.friends.length > 0 && item?.friends[4]?.startTime !== null ? "Approved" : item.friends && item.friends.length > 0 && item?.friends[4]?.adminUpdatedAttendance
									  && item.friends && item.friends.length > 0 && item?.friends[4]?.attended ? "Unapproved" : "") : "",
					   
								"Guest-6 Name": item.friends && item.friends.length > 0 && item?.friends[5]?.name || "",
								"Guest-6 Age": item.friends && item.friends.length > 0 && item?.friends[5]?.age || "",
								"Guest-6 Gender": item.friends && item.friends.length > 0 && item?.friends[5]?.gender || "",
								"Guest-6 Status": item.friends && item.friends.length > 0 && item?.friends[5]?.hasOwnProperty("attended") ? (item.friends && item.friends.length > 0 && item?.friends[5]?.attended === true ? "Approved" :
								  item.friends && item.friends.length > 0 && item?.friends[5]?.attended === false ? "Unapproved" : "") : "",
								"Guest-6 Status-Approved": item.friends && item.friends.length > 0 && item?.friends[5]?.hasOwnProperty('adminUpdatedAttendance') &&
								  item.friends && item.friends.length > 0 && item?.friends[5]?.hasOwnProperty('startTime') ? (item.friends && item.friends.length > 0 && item?.friends[5]?.adminUpdatedAttendance === true &&
									item.friends && item.friends.length > 0 && item?.friends[5]?.startTime !== null ? "Approved" : item.friends && item.friends.length > 0 && item?.friends[5]?.adminUpdatedAttendance
									  && item.friends && item.friends.length > 0 && item?.friends[5]?.attended ? "Unapproved" : "") : "",
					   
					   
								"Guest-7 Name": item.friends && item.friends.length > 0 && item?.friends[6]?.name || "",
								"Guest-7 Age": item.friends && item.friends.length > 0 && item?.friends[6]?.age || "",
								"Guest-7 Gender": item.friends && item.friends.length > 0 && item?.friends[6]?.gender || "",
								"Guest-7 Status": item.friends && item.friends.length > 0 && item?.friends[6]?.hasOwnProperty("attended") ? (item.friends && item.friends.length > 0 && item?.friends[6]?.attended === true ? "Approved" :
								  item.friends && item.friends.length > 0 && item?.friends[6]?.attended === false ? "Unapproved" : "") : "",
								"Guest-7 Status-Approved": item.friends && item.friends.length > 0 && item?.friends[6]?.hasOwnProperty('adminUpdatedAttendance') &&
								  item.friends && item.friends.length > 0 && item?.friends[6]?.hasOwnProperty('startTime') ? (item.friends && item.friends.length > 0 && item?.friends[6]?.adminUpdatedAttendance === true &&
									item.friends && item.friends.length > 0 && item?.friends[6]?.startTime !== null ? "Approved" : item.friends && item.friends.length > 0 && item?.friends[6]?.adminUpdatedAttendance
									  && item.friends && item.friends.length > 0 && item?.friends[6]?.attended ? "Unapproved" : "") : "",
					   
					   
					   
					   
								"Guest-8 Name": item.friends && item.friends.length > 0 && item?.friends[7]?.name || "",
								"Guest-8 Age": item.friends && item.friends.length > 0 && item?.friends[7]?.age || "",
								"Guest-8 Gender": item.friends && item.friends.length > 0 && item?.friends[7]?.gender || "",
								"Guest-8 Status": item.friends && item.friends.length > 0 && item?.friends[7]?.hasOwnProperty("attended") ? (item.friends && item.friends.length > 0 && item?.friends[7]?.attended === true ? "Approved" :
								  item.friends && item.friends.length > 0 && item?.friends[7]?.attended === false ? "Unapproved" : "") : "",
								"Guest-8 Status-Approved": item.friends && item.friends.length > 0 && item?.friends[7]?.hasOwnProperty('adminUpdatedAttendance') &&
								  item.friends && item.friends.length > 0 && item?.friends[7]?.hasOwnProperty('startTime') ? (item.friends && item.friends.length > 0 && item?.friends[7]?.adminUpdatedAttendance === true &&
									item.friends && item.friends.length > 0 && item?.friends[7]?.startTime !== null ? "Approved" : item.friends && item.friends.length > 0 && item?.friends[7]?.adminUpdatedAttendance
									  && item.friends && item.friends.length > 0 && item?.friends[7]?.attended ? "Unapproved" : "") : "",
							   
					   
					   
								"Guest-9 Name": item.friends && item.friends.length > 0 && item?.friends[8]?.name || "",
								"Guest-9 Age": item.friends && item.friends.length > 0 && item?.friends[8]?.age || "",
								"Guest-9 Gender": item.friends && item.friends.length > 0 && item?.friends[8]?.gender || "",
								"Guest-9 Status": item.friends && item.friends.length > 0 && item?.friends[8]?.hasOwnProperty("attended") ? (item.friends && item.friends.length > 0 && item?.friends[8]?.attended === true ? "Approved" :
								  item.friends && item.friends.length > 0 && item?.friends[8]?.attended === false ? "Unapproved" : "") : "",
								"Guest-9 Status-Approved": item.friends && item.friends.length > 0 && item?.friends[8]?.hasOwnProperty('adminUpdatedAttendance') &&
								  item.friends && item.friends.length > 0 && item?.friends[8]?.hasOwnProperty('startTime') ? (item.friends && item.friends.length > 0 && item?.friends[8]?.adminUpdatedAttendance === true &&
									item.friends && item.friends.length > 0 && item?.friends[8]?.startTime !== null ? "Approved" : item.friends && item.friends.length > 0 && item?.friends[8]?.adminUpdatedAttendance
									  && item.friends && item.friends.length > 0 && item?.friends[8]?.attended ? "Unapproved" : "") : "",
					   
					   
					   
					   
								"Guest-10 Name": item.friends && item.friends.length > 0 && item?.friends[9]?.name || "",
								"Guest-10 Age": item.friends && item.friends.length > 0 && item?.friends[9]?.age || "",
								"Guest-10 Gender": item.friends && item.friends.length > 0 && item?.friends[9]?.gender || "",
								"Guest-10 Status": item.friends && item.friends.length > 0 && item?.friends[9]?.hasOwnProperty("attended") ? (item.friends && item.friends.length > 0 && item?.friends[9]?.attended === true ? "Approved" :
								  item.friends && item.friends.length > 0 && item?.friends[9]?.attended === false ? "Unapproved" : "") : "",
								"Guest-10 Status-Approved": item.friends && item.friends.length > 0 && item?.friends[9]?.hasOwnProperty('adminUpdatedAttendance') &&
								  item.friends && item.friends.length > 0 && item?.friends[9]?.hasOwnProperty('startTime') ? (item.friends && item.friends.length > 0 && item?.friends[9]?.adminUpdatedAttendance === true &&
									item.friends && item.friends.length > 0 && item?.friends[9]?.startTime !== null ? "Approved" : item.friends && item.friends.length > 0 && item?.friends[9]?.adminUpdatedAttendance
									  && item.friends && item.friends.length > 0 && item?.friends[9]?.attended ? "Unapproved" : "") : "",
					   
					   
					   
								"Guest-11 Name": item.friends && item.friends.length > 0 && item?.friends[10]?.name || "",
								"Guest-11 Age": item.friends && item.friends.length > 0 && item?.friends[10]?.age || "",
								"Guest-11 Gender": item.friends && item.friends.length > 0 && item?.friends[10]?.gender || "",
								"Guest-11 Status": item.friends && item.friends.length > 0 && item?.friends[10]?.hasOwnProperty("attended") ? (item.friends && item.friends.length > 0 && item?.friends[10]?.attended === true ? "Approved" :
								  item.friends && item.friends.length > 0 && item?.friends[10]?.attended === false ? "Unapproved" : "") : "",
								"Guest-11 Status-Approved": item.friends && item.friends.length > 0 && item?.friends[10]?.hasOwnProperty('adminUpdatedAttendance') &&
								  item.friends && item.friends.length > 0 && item?.friends[10]?.hasOwnProperty('startTime') ? (item.friends && item.friends.length > 0 && item?.friends[10]?.adminUpdatedAttendance === true &&
									item.friends && item.friends.length > 0 && item?.friends[10]?.startTime !== null ? "Approved" : item.friends && item.friends.length > 0 && item?.friends[10]?.adminUpdatedAttendance
									  && item.friends && item.friends.length > 0 && item?.friends[10]?.attended ? "Unapproved" : "") : "",
					   
					   
					   
					   
								"Guest-12 Name": item.friends && item.friends.length > 0 && item?.friends[11]?.name || "",
								"Guest-12 Age": item.friends && item.friends.length > 0 && item?.friends[11]?.age || "",
								"Guest-12 Gender": item.friends && item.friends.length > 0 && item?.friends[11]?.gender || "",
								"Guest-12 Status": item.friends && item.friends.length > 0 && item?.friends[11]?.hasOwnProperty("attended") ? (item.friends && item.friends.length > 0 && item?.friends[11]?.attended === true ? "Approved" :
								  item.friends && item.friends.length > 0 && item?.friends[11]?.attended === false ? "Unapproved" : "") : "",
								"Guest-12 Status-Approved": item.friends && item.friends.length > 0 && item?.friends[11]?.hasOwnProperty('adminUpdatedAttendance') &&
								  item.friends && item.friends.length > 0 && item?.friends[11]?.hasOwnProperty('startTime') ? (item.friends && item.friends.length > 0 && item?.friends[11]?.adminUpdatedAttendance === true &&
									item.friends && item.friends.length > 0 && item?.friends[11]?.startTime !== null ? "Approved" : item.friends && item.friends.length > 0 && item?.friends[11]?.adminUpdatedAttendance
									  && item.friends && item.friends.length > 0 && item?.friends[11]?.attended ? "Unapproved" : "") : "",
					   
					   
					   
					   
								"Guest-13 Name": item.friends && item.friends.length > 0 && item?.friends[12]?.name || "",
								"Guest-13 Age": item.friends && item.friends.length > 0 && item?.friends[12]?.age || "",
								"Guest-13 Gender": item.friends && item.friends.length > 0 && item?.friends[12]?.gender || "",
								"Guest-13 Status": item.friends && item.friends.length > 0 && item?.friends[12]?.hasOwnProperty("attended") ? (item.friends && item.friends.length > 0 && item?.friends[12]?.attended === true ? "Approved" :
								  item.friends && item.friends.length > 0 && item?.friends[12]?.attended === false ? "Unapproved" : "") : "",
								"Guest-13 Status-Approved": item.friends && item.friends.length > 0 && item?.friends[12]?.hasOwnProperty('adminUpdatedAttendance') &&
								  item.friends && item.friends.length > 0 && item?.friends[12]?.hasOwnProperty('startTime') ? (item.friends && item.friends.length > 0 && item?.friends[12]?.adminUpdatedAttendance === true &&
									item.friends && item.friends.length > 0 && item?.friends[12]?.startTime !== null ? "Approved" : item.friends && item.friends.length > 0 && item?.friends[12]?.adminUpdatedAttendance
									  && item.friends && item.friends.length > 0 && item?.friends[12]?.attended ? "Unapproved" : "") : "",
					   
					   
					   
					   
								"Guest-14 Name": item.friends && item.friends.length > 0 && item?.friends[13]?.name || "",
								"Guest-14 Age": item.friends && item.friends.length > 0 && item?.friends[13]?.age || "",
								"Guest-14 Gender": item.friends && item.friends.length > 0 && item?.friends[13]?.gender || "",
								"Guest-14 Status": item.friends && item.friends.length > 0 && item?.friends[13]?.hasOwnProperty("attended") ? (item.friends && item.friends.length > 0 && item?.friends[13]?.attended === true ? "Approved" :
								  item.friends && item.friends.length > 0 && item?.friends[13]?.attended === false ? "Unapproved" : "") : "",
								"Guest-14 Status-Approved": item.friends && item.friends.length > 0 && item?.friends[13]?.hasOwnProperty('adminUpdatedAttendance') &&
								  item.friends && item.friends.length > 0 && item?.friends[13]?.hasOwnProperty('startTime') ? (item.friends && item.friends.length > 0 && item?.friends[13]?.adminUpdatedAttendance === true &&
									item.friends && item.friends.length > 0 && item?.friends[13]?.startTime !== null ? "Approved" : item.friends && item.friends.length > 0 && item?.friends[13]?.adminUpdatedAttendance
									  && item.friends && item.friends.length > 0 && item?.friends[13]?.attended ? "Unapproved" : "") : "",
					   
					   
					   
								"Guest-15 Name": item.friends && item.friends.length > 0 && item?.friends[14]?.name || "",
								"Guest-15 Age": item.friends && item.friends.length > 0 && item?.friends[14]?.age || "",
								"Guest-15 Gender": item.friends && item.friends.length > 0 && item?.friends[14]?.gender || "",
								"Guest-15 Status": item.friends && item.friends.length > 0 && item?.friends[14]?.hasOwnProperty("attended") ? (item.friends && item.friends.length > 0 && item?.friends[14]?.attended === true ? "Approved" :
								  item.friends && item.friends.length > 0 && item?.friends[14]?.attended === false ? "Unapproved" : "") : "",
								"Guest-15 Status-Approved": item.friends && item.friends.length > 0 && item?.friends[14]?.hasOwnProperty('adminUpdatedAttendance') &&
								  item.friends && item.friends.length > 0 && item?.friends[14]?.hasOwnProperty('startTime') ? (item.friends && item.friends.length > 0 && item?.friends[14]?.adminUpdatedAttendance === true &&
									item.friends && item.friends.length > 0 && item?.friends[14]?.startTime !== null ? "Approved" : item.friends && item.friends.length > 0 && item?.friends[14]?.adminUpdatedAttendance
									  && item.friends && item.friends.length > 0 && item?.friends[14]?.attended ? "Unapproved" : "") : "",
							 
								//  ======================== till here friends guest =================================
								
								"Admin-Name": item.adminName,
								"startTime": item.hasOwnProperty("startTime") ? item.startTime : "",
								"endTime": item.hasOwnProperty("endTime") ? item.endTime : "",
								"Admin Approved Hours(Guest)": item.guesthour || '-',// && moment(moment(item.guesthour, "HH:mm")).isValid() ? moment(item.guesthour, "HH:mm").format("HH:mm"): '-',
								"AdminApprovedStartTime(Guest)": item.guestAttended > 0 ? (item.hasOwnProperty("startTime") && item.updatedParticipatedAttendence === "" ? item.startTime : item.updatedStartTime) : "",
								"AdminApprovedEndTime(Guest)": item.guestAttended > 0 ? (item.hasOwnProperty("endTime") && item.updatedParticipatedAttendence === "" ? item.startTime : item.updatedStartTime) : "",
							  
							  })
						})
					})
					// this.setState({});
					this.setState({ participantData:updatedParticipantsData,partcipantsCsv:false }, () => {
						// this.csvLinkRef.current.link.click();
						// if (this.csvLinkRef.current) {
							this.csvLinkRef.current.link.click();
						//   }
					  });
				}.bind(this)
			);
	}
	componentDidMount() {
		// fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/per-day-hours', {
		// 	method: "GET",
		// 	headers: {
		// 		'Auth': JSON.parse(readCookie('access_token')).access_token
		// 	},
		// }).then(function(response) {
		// 	return response.json();
		// }).then(function(data) {
		// 	this.updateEmployeeVolunteering(data);
		// 	}.bind(this));
		this.filterResult();
	}

	title(cell, row) {
		return (
			<a className="le-color" href={"/admin/opportunity/" + row.id}>{cell}</a>
			)
	}

	updateEmployeeVolunteering(data) {
		var goalsData = data.goalsData;
		var departmentData = data['departmentData'];
		let totalAttendeeHours =0 ;
		if (departmentData && departmentData.length){for (var i = 0; i < departmentData.length; i++) {

			if (departmentData[i]['guestTotalHours']) {
				var hours = parseInt(departmentData[i]['guestTotalHours'].split(':')[0]);
				var mins = parseInt(departmentData[i]['guestTotalHours'].split(':')[1]);
				var hoursAndMins = (hours * 60) + mins;
				departmentData[i]['value'] = hoursAndMins / 60;
				departmentData[i]['axisLabel'] = 'Goal ' + (i + 1);
			}
			if (departmentData[i]['totalHours']) {
				var hours = parseInt(departmentData[i]['totalHours'].split(':')[0]);
				var mins = parseInt(departmentData[i]['totalHours'].split(':')[1]);
				var hoursAndMins = (hours * 60) + mins;
				departmentData[i]['guestvalue'] = hoursAndMins / 60;
				departmentData[i]['axisLabel'] = 'Goal ' + (i + 1);
			}
		}}
		// for(var i = 0; i < goalsData.length; i++) {

		// 	if(goalsData[i]['hours']){
		// 		var hours = parseInt(goalsData[i]['hours'].split(':')[0]);
		// 		var mins = parseInt(goalsData[i]['hours'].split(':')[1]);
		// 		var hoursAndMins = (hours * 60) + mins;
		// 		goalsData[i]['value'] = hoursAndMins / 60;
		// 		goalsData[i]['axisLabel'] = 'Goal ' + (i + 1);
		// 	}
		// 	if(goalsData[i]['totalGuestHours']){
		// 		var hours = parseInt(goalsData[i]['totalGuestHours'].split(':')[0]);
		// 		var mins = parseInt(goalsData[i]['totalGuestHours'].split(':')[1]);
		// 		var hoursAndMins = (hours * 60) + mins;
		// 		goalsData[i]['guestvalue'] = hoursAndMins / 60;
		// 		goalsData[i]['axisLabel'] = 'Goal ' + (i + 1);
		// 	}
		// }
		// data.tableData.map((d) => {
		// 	totalAttendeeHours+= d.hours
		//   });

	// 	  var hours = parseInt(totalAttendeeHours);
	//   var mins = parseInt((totalAttendeeHours - hours) * 60);
	//   let totalHours = [];
	//   totalHours.push(hours);
	//   totalHours.push(mins);

		var employeeVolunteeringData = {};
		employeeVolunteeringData['goalsData'] = goalsData;
		employeeVolunteeringData['tableData'] = data.tableData;
		employeeVolunteeringData['departmentData'] = departmentData; 
		employeeVolunteeringData['volunteeringHoursData'] = data.volunteeringHoursData;
		employeeVolunteeringData['totalAttendeeHours'] = data.totalAttendeeHours;
		employeeVolunteeringData['totalGuestHours'] = data.totalGuestHours;
		employeeVolunteeringData['totalExternalParticipantsHours'] = data.totalExternalParticipantsHours; 
		employeeVolunteeringData['totalOrganisationHours'] = data.totalOrganisationHours;
		
		// var i = 0;
		// while(i < employeeVolunteeringData.volunteeringHoursData.length - 1) {
		// 	var currentDate = moment(employeeVolunteeringData.volunteeringHoursData[i].date, 'DD/MM/YYYY');
		// 	var nextDate = moment(employeeVolunteeringData.volunteeringHoursData[i + 1].date, 'DD/MM/YYYY');
		// 	var dateDiff = nextDate.diff(currentDate, 'days');
		// 	for(var j = 1; j < dateDiff; j++) {
		// 		var date = moment(currentDate, 'DD/MM/YYYY').add(j, 'days').format('DD/MM/YYYY');
		// 		var hours = 0;
		// 	     var totalGuestHoursData = 0;

		// 		var data = {
		// 			date: date,
		// 			hours: hours,
		// 			totalGuestHoursData:totalGuestHoursData
		// 		};
		// 		employeeVolunteeringData.volunteeringHoursData.splice(i + j, 0, data);
		// 	}
		// 	if(dateDiff > 1) i = i + dateDiff;
		// 	else i++;
		// }
		this.setState({ employeeVolunteering: employeeVolunteeringData });
	}

	hours(cell, row) {
		if (cell) {
			var objs = cell.split(":");
			var hours = parseInt(objs[0]);
		var mins = parseInt(objs[1]);
		if(mins === 0) return <span>{hours + ' hours'}</span>;
		else if(hours === 0) return <span>{mins + ' mins'}</span>;
		else return <span>{hours + ' hours, ' + mins + ' mins'}</span>;
		}
		else {
			return ;
		}}
	guesthours(cell,row){
	if(row.hasOwnProperty("guestHours")){
  		if(row.guestHours === 0) {
		return '00:00';
	} else {
	  let time= row?.guestHours?.split(":");
	  let hh = time[0] + ' hours,' + time[1] +' mins';
	  if(time[1] === 0 ) return <span>{time[0]+ ' hours'}</span>;
	  else if(time[0] === 0) return <span>{time[1] + ' mins'}</span>;
	  else return <span>{hh}</span>;
		}
	}
}
	totalGuestList(cell,row){
		if(row.hasOwnProperty("totalGuest")){
	      if(row.guestHours === 0){
			return '0';
		  }	else {
			if(row.id){
				return (<p>{row.totalGuest}</p>)
			}
		}
	}
}
tickFormatter = (tick) => {
	// console.log("vlaue === >>>>> ",tick);
	const limit = 15; // put your maximum character
	// if(value.length< 10)
	if (tick.length > limit) {
		// console.log( "less than limit if === >>>",` ${tick.substring(0, limit)}...`);
		return `${tick.substring(0, limit)}...`;
		}
		else {
			// console.log("jdflskjlgs=== >>> ",tick);
			return tick;
		}	
	
 };

 

	render () {

		const headers = [
			{ label: "Opportunity Title", key: "Opportunity Title" },
			{label : "Opportunity ID",key: "Opportunity Id"},
			{label :"Date",key :"Date"},
			{label :"Status",key :"Status"},
			{label:"Hexa ID",key:"Hexa ID"},
			{ label: "Name", key: "Name" },
			{ label: "Employee Id", key: "EmployeeId" },
			{ label: "Email", key: "Email" },
			{ label: "Role", key: "Role" },
			{ label: "Mobile", key: "Mobile" },
			{ label: "Alternative Number", key: "Alternative Number" },
			{ label: "Whatsapp number", key: "Whatsapp" },
			{ label: "Department", key: "Department" },
			{label:"Designation",key:"Designation" },
			{ label: "Gender", key: "Gender" },
			{ label: "Transport Required", key: "Transport Required" },
			{label:"Participation Status",key:"Participation Status"},
			{label:"Applied Start Time",key:"startTime"},
		   {label:"Applied End Time",key:"endTime"},
		   { label: "Applied Hours", key: "Hours" },
		  
			
	  
		   // adding IBL pulse headers
			  {
				"key": "REPORTING_MANAGER_DESIGNATION",
				"label": "REPORTING MANAGER DESIGNATION"
			  },
			  {
				"key": "REPORTING_MANAGER_MAILID",
				"label": "REPORTING MANAGER MAILID"
			  },
			  {
				"key": "FUNCTIONAL REPORTING MANAGER",
				"label": "FUNCTIONAL REPORTING MANAGER"
			  },
			  {
				"key": "COUNTRY",
				"label": "COUNTRY"
			  },
			  {
				"key": "EMPLOYEE STATUS",
				"label": "EMPLOYEE STATUS"
			  },
			  {
				"key": "STATUS",
				"label": "STATUS"
			  },
			  {
				"key": "BRANCH_CODE",
				"label": "BRANCH CODE"
			  },
			  {
				"key": "BRANCH ADDRESS",
				"label": "BRANCH ADDRESS"
			  },
			  {
				"key": "CURRENT_LOCATION",
				"label": "CURRENT LOCATION"
			  },
			  {
				"key": "CITY_NAME",
				"label": "CITY NAME"
			  },
			  {
				"key": "REGION",
				"label": "REGION"
			  },
			  {
				"key": "ZONE",
				"label": "ZONE"
			  },
			  {
				"key": "Shift Start Time",
				"label": "Shift Start Time"
			  },
	  
	  
		   {label:"Admin Approval Status",key:"Admin Approved Status"},
			{label :"Admin Approval Start Time",key:"Admin Approved Start Time"},
			{label :"Admin Approval End Time",key:"Admin Approved End Time"},
		   //  convert into hours as in 6.5
		  // admin approved Time (Both) 
		   {label:"Admin Approved Hours (Volunteers)",key:"Admin Approved Hours"},// convwertinto 1.5 hour format
		   {label:"Experience", key: "Experience"},
		   {label:"How much of an impact do you feel your volunteer work had?",key:"Question-1"},
		   { label:"How satisfied are you with your volunteer experience?",key:"Question-2"},
		   { label:"How likely are you to participate in a similar event again?",key:"Question-3"},
		   {label:"Reason For Missing Event",key:"Reason"},
		   {label: "Guest-Count",key: "Guest-Count"},
	  
	  
		  //  ============================== from here guest list ====================
		  //  {label:"Guest-Name",key:"Guest-Name"},
		  //  {label: "Guest-Age",key: "Guest-Age"},
		  //  {label:"Guest-Gender",key:"Guest-Gender"},
		  //  {label:"Guest-Applied-Status",key:"Guest-Status"},
		  //  {label:"Guest-Approved-Status",key:"Guest-Status-Approved"},
	  
	  
	  
		   {label:"Guest-1 Name",key:"Guest-1 Name"},
		   {label: "Guest-1 Age",key: "Guest-1 Age"},
		   {label:"Guest-1 Gender",key:"Guest-1 Gender"},
		   {label:"Guest-1 Applied-Status",key:"Guest-1 Status"},
		   {label:"Guest-1 Approved-Status",key:"Guest-1 Status-Approved"},
	  
	  
		   {label:"Guest-2 Name",key:"Guest-2 Name"},
		   {label: "Guest-2 Age",key: "Guest-2 Age"},
		   {label:"Guest-2 Gender",key:"Guest-2 Gender"},
		   {label:"Guest-2 Applied-Status",key:"Guest-2 Status"},
		   {label:"Guest-2 Approved-Status",key:"Guest-2 Status-Approved"},

		   {label:"Guest-3 Name",key:"Guest-3 Name"},
		   {label: "Guest-3 Age",key: "Guest-3 Age"},
		   {label:"Guest-3 Gender",key:"Guest-3 Gender"},
		   {label:"Guest-3 Applied-Status",key:"Guest-3 Status"},
		   {label:"Guest-3 Approved-Status",key:"Guest-3 Status-Approved"},
	  
		   {label:"Guest-4 Name",key:"Guest-4 Name"},
		   {label: "Guest-4 Age",key: "Guest-4 Age"},
		   {label:"Guest-4 Gender",key:"Guest-4 Gender"},
		   {label:"Guest-4 Applied-Status",key:"Guest-4 Status"},
		   {label:"Guest-4 Approved-Status",key:"Guest-4 Status-Approved"},
	  
	  
		   {label:"Guest-5 Name",key:"Guest-5 Name"},
		   {label: "Guest-5 Age",key: "Guest-5 Age"},
		   {label:"Guest-5 Gender",key:"Guest-5 Gender"},
		   {label:"Guest-5 Applied-Status",key:"Guest-5 Status"},
		   {label:"Guest-5 Approved-Status",key:"Guest-5 Status-Approved"},
	  
		   {label:"Guest-6 Name",key:"Guest-6 Name"},
		   {label: "Guest-6 Age",key: "Guest-6 Age"},
		   {label:"Guest-6 Gender",key:"Guest-6 Gender"},
		   {label:"Guest-6 Applied-Status",key:"Guest-6 Status"},
		   {label:"Guest-6 Approved-Status",key:"Guest-6 Status-Approved"},
	  
	  
		   {label:"Guest-7 Name",key:"Guest-7 Name"},
		   {label: "Guest-7 Age",key: "Guest-7 Age"},
		   {label:"Guest-7 Gender",key:"Guest-7 Gender"},
		   {label:"Guest-7 Applied-Status",key:"Guest-7 Status"},
		   {label:"Guest-7 Approved-Status",key:"Guest-7 Status-Approved"},
	  
	  
		   {label:"Guest-8 Name",key:"Guest-8 Name"},
		   {label: "Guest-8 Age",key: "Guest-8 Age"},
		   {label:"Guest-8 Gender",key:"Guest-8 Gender"},
		   {label:"Guest-8 Applied-Status",key:"Guest-8 Status"},
		   {label:"Guest-8 Approved-Status",key:"Guest-8 Status-Approved"},
	  
	  
		   {label:"Guest-9 Name",key:"Guest-9 Name"},
		   {label: "Guest-9 Age",key: "Guest-9 Age"},
		   {label:"Guest-9 Gender",key:"Guest-9 Gender"},
		   {label:"Guest-9 Applied-Status",key:"Guest-9 Status"},
		   {label:"Guest-9 Approved-Status",key:"Guest-9 Status-Approved"},
	  
	  
		   {label:"Guest-10 Name",key:"Guest-10 Name"},
		   {label: "Guest-10 Age",key: "Guest-10 Age"},
		   {label:"Guest-10 Gender",key:"Guest-10 Gender"},
		   {label:"Guest-10 Applied-Status",key:"Guest-10 Status"},
		   {label:"Guest-10 Approved-Status",key:"Guest-10 Status-Approved"},
	  
	  
		   {label:"Guest-11 Name",key:"Guest-11 Name"},
		   {label: "Guest-11 Age",key: "Guest-11 Age"},
		   {label:"Guest-11 Gender",key:"Guest-11 Gender"},
		   {label:"Guest-11 Applied-Status",key:"Guest-11 Status"},
		   {label:"Guest-11 Approved-Status",key:"Guest-11 Status-Approved"},
	  
		   {label:"Guest-12 Name",key:"Guest-12 Name"},
		   {label: "Guest-12 Age",key: "Guest-12 Age"},
		   {label:"Guest-12 Gender",key:"Guest-12 Gender"},
		   {label:"Guest-12 Applied-Status",key:"Guest-12 Status"},
		   {label:"Guest-12 Approved-Status",key:"Guest-12 Status-Approved"},
	  
	  
		   {label:"Guest-13 Name",key:"Guest-13 Name"},
		   {label: "Guest-13 Age",key: "Guest-13 Age"},
		   {label:"Guest-13 Gender",key:"Guest-13 Gender"},
		   {label:"Guest-13 Applied-Status",key:"Guest-13 Status"},
		   {label:"Guest-13 Approved-Status",key:"Guest-13 Status-Approved"},
	  
	  
	  
		   {label:"Guest-14 Name",key:"Guest-14 Name"},
		   {label: "Guest-14 Age",key: "Guest-14 Age"},
		   {label:"Guest-14 Gender",key:"Guest-14 Gender"},
		   {label:"Guest-14 Applied-Status",key:"Guest-14 Status"},
		   {label:"Guest-14 Approved-Status",key:"Guest-14 Status-Approved"},
	  
	  
		   {label:"Guest-15 Name",key:"Guest-15 Name"},
		   {label: "Guest-15 Age",key: "Guest-15 Age"},
		   {label:"Guest-15 Gender",key:"Guest-15 Gender"},
		   {label:"Guest-15 Applied-Status",key:"Guest-15 Status"},
		   {label:"Guest-15 Approved-Status",key:"Guest-15 Status-Approved"}	
		  ];
		const VolunteerHousVsDevGoalsChart = () => {
			return <div className="linechart-container volunteering-hours-graph-container"
				style={{ height: 400 }}>
				<ResponsiveContainer width='100%' height='100%'>
					<BarChart data={this.state.employeeVolunteering.goalsData}
						barGap={5} maxBarSize={10} layout="vertical">
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis type="number" unit="hours" />
						{/* <XAxis type="number" unit="guestHours" /> */}
						<YAxis type="category" dataKey="axisLabel" />
						{this.state.employeeVolunteering.goalsData.length ? (
							<Tooltip content={<CustomTooltip />} />
						) : (null)}
						<Bar dataKey="value" barGap={5}>
							{this.state.employeeVolunteering.goalsData.map(function (entry, index) {
								return (
									<Cell fill={entry.backgroundColor} />
								)
							})}
							<LabelList dataKey="hours" position="right" />
						</Bar>
						{
							this.state.volunteer === "guestHours" || this.state.volunteer === "employeesAndGuestHours" ?
								<Bar dataKey="guestvalue" barGap={5}>
									{this.state.employeeVolunteering.goalsData.map(function (entry, index) {
										return (
											<Cell fill={entry.backgroundColor} />
										)
									})}
									<LabelList dataKey="totalGuestHours" position="right" />
								</Bar> : null
						}

					</BarChart>
				</ResponsiveContainer>
			</div>;
		};
		const VolunteerHousVsDeptsChart = () => {
			// return <div className="linechart-container volunteering-hours-graph-container"
			// 	style={{ height: 400 }}> <ResponsiveContainer width="100%" height="100%">
			// 		<BarChart
			// 			width={500}
			// 			height={300}
			// 			data={this.state.employeeVolunteering.departmentData}
			// 			margin={{
			// 				top: 20,
			// 				right: 30,
			// 				left: 20,
			// 				bottom: 5,
			// 			}}
			// 		>
			// 			<CartesianGrid strokeDasharray="3 3" />
			// 			<XAxis dataKey="totalHours" />
			// 			<YAxis dataKey="department" />
			// 			<Tooltip />
			// 			<Legend />
			// 			<Bar dataKey="totalHours" stackId="a" fill="#8884d8" />
			// 			<Bar dataKey="guestTotalHours" stackId="a" fill="#82ca9d" />
			// 			{/* <Bar dataKey="uv" fill="#ffc658" /> */}
			// 		</BarChart>
			// 	</ResponsiveContainer> </div>;
			return <div className="linechart-container volunteering-hours-graph-container"
				style={{ height: 400 }}>
				<ResponsiveContainer width='100%' height='100%'>
					<BarChart data={this.state.employeeVolunteering.departmentData}
						barGap={5} maxBarSize={10} layout="vertical">
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis type="number" unit=" hours" />
						{/* <XAxis type="number" unit="guestHours" /> */}
						<YAxis width={130}   tickFormatter={(tick)=> this.tickFormatter(tick)} type="category" dataKey="department" />
						{this.state.employeeVolunteering.departmentData.length ? (
							<Tooltip content={<CustomTooltip />} />
						) : (null)}

						
						{/* <Bar dataKey="value" barGap={5}>
							{this.state.employeeVolunteering.departmentData.map(function (entry, index) {
								return (
									// <Cell fill={entry.backgroundColor} />
									// <Cell fill={"#82ca9d"} />
									<Cell fill={"#84181F"} />
								)
							})}
							<LabelList dataKey="guestTotalHours" position="right" />
						</Bar> */}
						{/* {
							this.state.volunteer === "guestHours" || this.state.volunteer === "employeesAndGuestHours" ? */}
								<Bar dataKey="guestvalue" barGap={5}>
									{this.state.employeeVolunteering.departmentData.map(function (entry, index) {
										return (
											<Cell fill={"#F7A31E"} />
											// <Cell fill={"#8884d8"} />
										)
									})}
							<LabelList dataKey="totalHours" position="right" />
						</Bar>
						{/* : null
						} */}

					</BarChart>
				</ResponsiveContainer>
			</div>;
		};
		// if(this.state.partcipantsCsv){
		// 	return (
		// 		<div className="ee-page-container">
		// 			<Row>
		// 				<Col md={12} className="text-center">
		// 					<h2 className="ee-page-title">Volunteering Analytics</h2>
		// 				</Col>
		// 			</Row>
		// 			<Row>
		// 				<Col md={12} className="text-center">
		// 					<div className="data-loader" />
		// 					{/* <p className="no-data-text">No Data Yet</p> */}
		// 				</Col>
		// 			</Row>
		// 		</div>
		// 	)
		// } else 
		if(this.state.employeeVolunteering !== null) {
			const formatter = (value) =>{ 
			return ` ${value} Hours`
			} ;
			const options = {
			  sizePerPage: 20,
			  sizePerPageList: [
			    { text: '20 Per Page', value: 20 },
			    { text: '50 Per Page', value: 50 },
			    { text: '100 Per Page', value: 100 }
			  ]
			};
			return (
				<div className="ee-page-container admin-volunteering-analytics-page">
					<Row>
						<Col md={12} className="text-center">
							<h2 className="ee-page-title">Volunteering Analytics</h2>
						</Col>
					</Row>
					<VolunteeringAnalyticsFilter employeeVolunteering={this.state.employeeVolunteering}
						updateEmployeeVolunteering={this.updateEmployeeVolunteering.bind(this)}  
						getContribution={(data)=> this.setState({volunteer:data}) } />
					<Row>
						
					<Row className="dashboard-motivation-participant-list " style={{marginRight:'-30px'}}>
						 <Col md={12} >
							<div class="txt-wrp-volunteer-participant-list" >
                        		
									{/* <span >
									<span style={{ display:'flex',height: 40, margin: 10, color: 'white', marginLeft:'28%'}}>
									<i className="fa fa-users "></i>
									</span>
									</span> */}
                          			<img class ="teamIcon" src="/img/icons/organisationTeam.png" />
                          <div >
                            <p class="right-txt-wrp-title" id="total-volunteering-hours-value">
						   {this.state.employeeVolunteering.totalOrganisationHours ? 
						   this.state.employeeVolunteering.totalOrganisationHours.split(':')[0] + 
						   ' hours ' + 
						   (this.state.employeeVolunteering.totalOrganisationHours.split(':')[1] === '0' ? '00' + ' minutes':
						   this.state.employeeVolunteering.totalOrganisationHours.split(':')[1] + 
						   ' minutes') : '00 hours 00 minutes'}
						    </p>
                            <p className="dashboard-motivational-elements-2-p" id="total-volunteering-hours-label">Total volunteering hours of the organization</p>
                          </div>
                        
                      </div>
							</Col>
						</Row>
					<Row className="dashboard-motivation-participant-list">
                    <Col md={4}>
                      <div class="right-txt-wrp-volunteer-participant-list" >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/employee.png" />
                          <div style={{ marginLeft: 10 }}>
                            <p class="right-txt-wrp-title" style={{ marginTop: 10 }}>
						   {/* {this.state.employeeVolunteering.totalAttendeeHours[0] +' hours ' + 
						   this.state.employeeVolunteering.totalAttendeeHours[1] + ' minutes '} */}
												{this.state.employeeVolunteering.totalAttendeeHours ? this.state.employeeVolunteering.totalAttendeeHours.split(':')[0] + ' hours ' + this.state.employeeVolunteering.totalAttendeeHours.split(':')[1] +' minutes' : '00 hours 00 minutes'}
												
						    </p>
                            <p className="dashboard-motivational-elements-2-p">Total Employee Volunteers Hours</p>
                          </div>
                        </div>
                      </div>
                    </Col>
					<Col md={4}>
                      <div class="right-txt-wrp-volunteer-participant-list" >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/guest2.png" />
                          <div style={{ marginLeft: 10 }}>
                            <p class="right-txt-wrp-title" style={{ marginTop: 10 }}>
												{this.state.employeeVolunteering.totalGuestHours ? this.state.employeeVolunteering.totalGuestHours.split(':')[0] + ' hours ' + this.state.employeeVolunteering.totalGuestHours.split(':')[1] +' minutes' : '00 hours 00 minutes'}
                            </p>
                            <p className="dashboard-motivational-elements-2-p">Total Guest Volunteers Hours</p>
                          </div>
                        </div>
                      </div>
                    </Col>
					<Col md={4}>
                      <div class="right-txt-wrp-volunteer-participant-list" >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <img style={{ height: 40, margin: 10, color: 'grey' }} src="/img/icons/guest2.png" />
                          <div style={{ marginLeft: 10 }}>
                            <p class="right-txt-wrp-title" style={{ marginTop: 10 }}>
							{this.state.employeeVolunteering.totalExternalParticipantsHours.split(':')[0] + ' hours ' + this.state.employeeVolunteering.totalExternalParticipantsHours.split(':')[1] +' minutes'}
                            </p>
                            <p className="dashboard-motivational-elements-2-p">Total External Guest Hours </p>
                          </div>
                        </div>
                      </div>
                    </Col>
					</Row>

{/* 

						<Col md={12} className="text-center">
							<label className="control-label">Volunteering Hours VS Dates</label>
						</Col>
						<Col md={12} className="mb30">
							<div className="linechart-container volunteering-hours-graph-container" style={{height: 300}}>
								<ResponsiveContainer width='100%' height='100%'>
									<LineChart responsive style={{margin: 10}}
										data={this.state.employeeVolunteering.volunteeringHoursData}>
										<XAxis dataKey="date"/>
										<YAxis tickFormatter={formatter} />
										<CartesianGrid strokeDasharray="3 3"/>
										{this.state.employeeVolunteering.volunteeringHoursData.length ? (
											<Tooltip/>
										) : (null)}
										<Line type="monotone" dataKey="hours" stroke="#ef5a20" strokeWidth={2} />
										{this.state.volunteer === "guestHours" || this.state.volunteer === "employeesAndGuestHours" ? 
										<Line type="monotone" dataKey="totalGuestHours" stroke="#c7242f" strokeWidth={2} /> :null
									}
										
									</LineChart>
								</ResponsiveContainer>
							</div>
						</Col> */}
					</Row>
					{/* <Row>
						<Col md={12} className="text-center mt20">
							<label className="control-label">Volunteering Hours VS Development Goals</label>
						</Col>
						<Col md={12}>
							<VolunteerHousVsDevGoalsChart/>
						</Col>
					</Row> */}


					<Row>
					


						
						<Col md={12} className="text-center mt20">
							<label className="control-label">Department-wise Volunteering Hours</label>
						</Col>
						<Col md={12} sm={12} xs={12}>
						<VolunteerHousVsDeptsChart/>
						</Col>
					</Row>
					<Row>



			
					<Col md={12} className="volunteering-hours-table-container mt30" 
					style={{
						display:"flex",
							flexDirection:"column"
					}}>
						<div style={{position:"relative"}}>
					<div 
					// style={{    position: "absolute",
					// 	/* top: -1px; */
					// 	/* left: 10vw; */
					// 	/* z-index: 1; */
					// 	// marginTop: "34px",
					// 	// marginLeft: "12px"}}
					style={{marginBottom:"10px"}}
					// style={{ position: "absolute", top: "-2px", left: "10vw",zIndex: 1  }} 
					className='participants-button'>
        
				<ExportCSVButton
            style={{
              background: 'var(--themeColor)',
              borderColor: 'var(--themeColor)',
              boxShadow: "1px 1px 1px #515356",
			  cursor:"pointer",
			  fontSize :"12px"
			//   height:"30px"

            }}
            btnText='Download participants data' 
			onClick={ this.fetchParticipantData}
			/>
           <CSVLink
            data={this.state.participantData}
            filename='participants_data.csv'
            target='_blank'
			headers={headers}
			className="hidden"
        	ref={this.csvLinkRef}
			>
        </CSVLink>

		<div className={this.state.partcipantsCsv ? 'overlay' : 'hidden'}>
          <div className="loader"></div>
		  <div>Preparing CSV...</div>
        </div>
</div>
</div>
							<BootstrapTable data={this.state.employeeVolunteering.tableData} options={options} striped={false} ref='table' hover={true} exportCSV csvFileName={'Volunteering Analytics - ' + String(moment().unix()) + '.csv'}>
							  <TableHeaderColumn expandable className="wrapped-table-header" width='80px' dataField="date" isKey={true} hidden={false} export={true} searchable={false} >Date</TableHeaderColumn>
							  <TableHeaderColumn expandable className="wrapped-table-header" width='230px' dataField="title" hidden={false} export={true} searchable={true} dataFormat={this.title.bind(this)}>Title</TableHeaderColumn>
							  {/* {this.state.volunteer === "employeesHours" ||
								   this.state.volunteer === "employeesAndGuestHours" ? <> */}
										<TableHeaderColumn expandable className="wrapped-table-header" width='100px' dataField="signups" hidden={false} export={true} 
								   searchable={true}>Signed Up Employees </TableHeaderColumn>
								   {/* </> :null
							  } */}
							  
							  {/* {this.state.volunteer === "employeesHours" ||
								   this.state.volunteer === "employeesAndGuestHours" ? <> */}
										<TableHeaderColumn expandable className="wrapped-table-header" width='100px' dataField="totalApprovedCount" hidden={false} export={true} 
								   searchable={true}>Approved (attendance) Employees</TableHeaderColumn>
								   {/* </> :null
							  } */}
							  {/* { this.state.volunteer === "employeesHours" ||
								   this.state.volunteer === "employeesAndGuestHours"  ?<> */}
										<TableHeaderColumn expandable className="wrapped-table-header" width='110px' dataField="employeeHours" hidden={false} export={true} searchable={true}
								 dataFormat={this.hours.bind(this)}>Employees Hours</TableHeaderColumn>   
								   {/* </> :null

							  } */}
							  {/* { this.state.volunteer === "guestHours" || this.state.volunteer === "employeesAndGuestHours" ?<>
								<TableHeaderColumn expandable className="wrapped-table-header" width='120px' dataField="totalGuestAttendees" dataFormat ={this.totalGuestList} export={true} searchable={true}>Total Guest</TableHeaderColumn>
								</> :null
							}	 */}
							{/* {this.state.volunteer === "guestHours" || this.state.volunteer === "employeesAndGuestHours" ? */}
							
							<TableHeaderColumn expandable className="wrapped-table-header" width='100px' dataField="guestSignupCount" hidden={false} export={true} 
								   searchable={true}>Signed Up Guest </TableHeaderColumn>
								
							<TableHeaderColumn expandable className="wrapped-table-header" width='100px' dataField="guestCount" hidden={false} export={true} 
								   searchable={true}>Approved (attendance) Guest</TableHeaderColumn>
								<TableHeaderColumn expandable className="wrapped-table-header" width='110px' dataField="guestHours" hidden={false}  dataFormat={this.hours} export={true} searchable={true} 
								>Total Guest hours</TableHeaderColumn>
								 {/* :null */}
							{/* } */}
								<TableHeaderColumn expandable className="wrapped-table-header" width='110px' dataField="externalParticipantHours" hidden={false}   export={true} searchable={true} 
									dataFormat={this.hours}	>External Guest hours</TableHeaderColumn>

								<TableHeaderColumn expandable className="wrapped-table-header" width='110px' dataField="totalHours" hidden={false} export={true} searchable={true}
									dataFormat={this.hours}	>Total hours</TableHeaderColumn>



							</BootstrapTable>
						</Col>
					</Row>
				</div>
			);
		} else {
			return (
				<div className="ee-page-container">
					<Row>
						<Col md={12} className="text-center">
							<h2 className="ee-page-title">Volunteering Analytics</h2>
						</Col>
					</Row>
					<Row>
						<Col md={12} className="text-center">
							<div className="data-loader" />
							{/* <p className="no-data-text">No Data Yet</p> */}
						</Col>
					</Row>
				</div>
			);
		}
	}
}

VolunteeringAnalytics.propTypes = {
	employeeVolunteering: PropTypes.array,
};

function mapStateToProps(state) {
	return {
		employeeVolunteering: state.EEAdminVolunteeringAnalytics.employeeVolunteering,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VolunteeringAnalytics);