import { createSessionCookie, eraseCookie, readCookie } from "../cookie";
var CryptoJS = require("crypto-js");


const decryptData = (ciphertext) => {
    try {
        var bytes = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_ENCRYPTION_KEY);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
    catch (err) {
        return '';
    }
}

const encryptData = (plainText) => CryptoJS.AES.encrypt(plainText, process.env.REACT_APP_ENCRYPTION_KEY).toString();

const INITIAL_STATE = decryptData(readCookie('le_role')) || 'user';

const ACTIONS = {
    'USER_LOGGED_IN': 'USER_LOGGED_IN',
    'USER_ROLE_CHANGED': 'USER_ROLE_CHANGED',
    'USER_LOGGED_OUT': 'USER_LOGGED_OUT',
};

const RoleReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTIONS.USER_LOGGED_IN:
            createSessionCookie('le_role', encryptData(INITIAL_STATE), 1);
            return INITIAL_STATE;
        case ACTIONS.USER_ROLE_CHANGED:
            createSessionCookie('le_role', encryptData(action.role || INITIAL_STATE), 1);
            return action.role || INITIAL_STATE;
        case ACTIONS.USER_LOGGED_OUT:
            eraseCookie('le_role');
            return null;
        default:
            return INITIAL_STATE;
    }
}

export default RoleReducer;