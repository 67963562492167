const initialState = {
	employeeVolunteering: null
};

const EEAdminVolunteeringAnalyticsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_EMPLOYEE_VOLUNTEERING':
			return Object.assign({}, state, {
				employeeVolunteering: action.employeeVolunteering,
			});
		default:
			return state
	}
}

export default EEAdminVolunteeringAnalyticsReducer;