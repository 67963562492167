export const updateCountOfVos = (countOfVos) => {
	return {
		countOfVos,
		type: 'UPDATE_COUNT_OF_VOS'
	}
};

export const updateCountOfCompletedVos = (countOfCompletedVos) => {
	return {
		countOfCompletedVos,
		type: 'UPDATE_COUNT_OF_COMPLETED_VOS'
	}
};

export const updateTotalHoursClocked = (totalHoursClocked) => {
	return {
		totalHoursClocked,
		type: 'UPDATE_TOTAL_HOURS_CLOCKED'
	}
};

export const updateCountOfProjects = (countOfProjects) => {
	return {
		countOfProjects,
		type: 'UPDATE_COUNT_OF_PROJECTS'
	}
};

export const updateTotalContributed = (totalContributed) => {
	return {
		totalContributed,
		type: 'UPDATE_TOTAL_CONTRIBUTED'
	}
};

export const updateTotalMatchedByCompany = (totalMatchedByCompany) => {
	return {
		totalMatchedByCompany,
		type: 'UPDATE_TOTAL_MATCHED_BY_COMPANY'
	}
};

export const updateTotalContributedViaPayroll = (totalContributedViaPayroll) => {
	return {
		totalContributedViaPayroll,
		type: 'UPDATE_TOTAL_CONTRIBUTED_VIA_PAYROLL'
	}
};

export const updateCurrentPayrollBalance = (currentPayrollBalance) => {
	return {
		currentPayrollBalance,
		type: 'UPDATE_CURRENT_PAYROLL_BALANCE'
	}
};

export const updateLiveVos = (liveVos) => {
	return {
		liveVos,
		type: 'UPDATE_LIVE_VOS'
	}
};

export const updateHasPledged = (hasPledged) => {
	return {
		hasPledged,
		type: 'UPDATE_HAS_PLEDGED'
	}
};