import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import MakeLive from './MakeLive';

export default class FundraisersList extends Component {
	constructor() {
		super();
		this.state = {
			showReserveModal: false,
		};

		this.closeReserveModal = this.closeReserveModal.bind(this);
		this.openReserveModal = this.openReserveModal.bind(this);
	}

	closeReserveModal() {
		this.setState({ showReserveModal: false, currentProject: null });
	}

	openReserveModal(e, project) {
		let volunteerOpportunityId = e.currentTarget.getAttribute('data-id');
		this.setState({
			showReserveModal: true,
			volunteerOpportunityId,
			currentProject: project
		});
	}

	render () {
		if(this.props.projects.length) {
			return (
				<div className="fundraisers-container">
					<Row>
						{
							this.props.projects.map(function (project, i) {
								return (
									<Col lg={4} md={4} sm={6} style={{marginBottom: '20px'}} key={i}>
										<Row style={{marginLeft: 0, marginRight: 0}}>
											<Col md={12} className="manage-fundraiser ideaResult">
												<div className="manage-fundraiser current-state">
													{(() => {
														if(!project.isLive) {
															return (
																<span className="current-state-outer submitted">
																<span className="outer-arrow"> </span>
																<span className="current-state-text">
																	submitted
																</span>
															</span>
															)
														} else {
															if(project.isCompleted) {
																return (
																	<span className="current-state-outer completed">
																		<span className="outer-arrow"> </span>
																		<span className="current-state-text">
																			completed
																		</span>
																	</span>
																)
															} else {
																return (
																	<span className="current-state-outer live">
																		<span className="outer-arrow"> </span>
																		<span className="current-state-text">
																			live
																		</span>
																	</span>
																)
															}
														}
													})()}
												</div>
												<Col md={12} className="manage-fundraiser resultCover"
												     style={{background: 'url(' + project.photo + ')'}}>
												</Col>
												<h2 className="manage-fundraiser resultName">
													{project.title}
												</h2>
												<h4 className="resultLocation">
													{project.city + ', ' + project.country}
												</h4>
												<Col md={12} className="manage-fundraiser resultDescription">
													<button onClick={() => {window.location.pathname = "/admin/project/" + project.customUrl}} className="btn-pad-5-20 btn-sq btn-view mr5">View</button>
													<button className="btn-pad-5-20 btn-sq btn-le ml5"
													        onClick={(e) => {this.openReserveModal(e, project)}}
													        data-id={project.id}
													>
														Edit
													</button>
												</Col>
											</Col>
										</Row>
									</Col>
								)
							}.bind(this))
						}
					</Row>

					{
						this.state.currentProject ? (
							<MakeLive volunteerOpportunityId={this.state.volunteerOpportunityId}
							          updateProjects={this.props.updateProjects}
							          project={this.state.currentProject}
							          showReserveModal={this.state.showReserveModal}
							          closeReserveModal={this.closeReserveModal}
							          openReserveModal={this.openReserveModal}
							/>
						) : (
							null
						)
					}
				</div>
			);
	 	} else {
			return (
				<Row>
					<Col md={12} className="text-center">
						<p className="no-data-text">No Projects Found</p>
					</Col>
				</Row>
			);
		}
	}
}