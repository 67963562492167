export const updateOpportunityDescription = (opportunityDescription) => {
  return {
    opportunityDescription,
    type: "UPDATE_OPPORTUNITY_DESCRIPTION",
  };
};

export const updateOpportunityEmail = (opportunityEmail) => {
  return {
    opportunityEmail,
    type: "UPDATE_OPPORTUNITY_EMAIL",
  };
};

export const updateOpportunityPhone = (opportunityPhone) => {
  return {
    opportunityPhone,
    type: "UPDATE_OPPORTUNITY_PHONE",
  };
};

export const updateOpportunityNote = (opportunityNote) => {
  return {
    opportunityNote,
    type: "UPDATE_OPPORTUNITY_NOTE",
  };
};

export const updateOpportunityStatus = (opportunityStatus) => {
  return {
    opportunityStatus,
    type: "UPDATE_OPPORTUNITY_STATUS",
  };
};

export const updateOpportunityFeedback = (opportunityFeedback) => {
  return {
    opportunityFeedback,
    type: "UPDATE_OPPORTUNITY_FEEDBACK",
  };
};
