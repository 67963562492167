import React, { Component } from 'react';
import $ from 'jquery';

export default class TopBar extends Component {
	componentDidMount() {
		$('#top-bar > .fa.fa-bars').click(function() {
			$(this).hide();
			$(this).siblings('.fa').show();
			$('.ee-container > .ee-header-container').addClass('open');
		});

		$('#top-bar > .fa.fa-times').click(function() {
			$(this).hide();
			$(this).siblings('.fa').show();
			$('.ee-container > .ee-header-container').removeClass('open');
		});
	}

	render() {
		return (
			<div id="top-bar">
				<i className="fa fa-bars"></i>
				<i className="fa fa-times"></i>
			</div>
		);
	}
}
