import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import $ from 'jquery';
import 'lightgallery/src/css/lightgallery.css';
import SingleDiscussionComment from "./SingleDiscussionComment";
import { ToastContainer, toast } from "react-toastify";
import configs from '../../../../../config/urlConfigs';
require('lightgallery');
require('lg-thumbnail');

const readCookie = require('../../../../../cookie.js').readCookie;

export default class SingleOpportunityDiscussion extends Component {
	constructor(props) {
		super(props);
		this.state = {
			comments: this.props.comments,
			commentImage: [],
			commentText: '',
			toggleCommentPost: true
		};
		this.updateCommentText = this.updateCommentText.bind(this);
		this.updateCommentImage = this.updateCommentImage.bind(this);
		this.updateToggleCommentPost = this.updateToggleCommentPost.bind(this);
	}

	componentDidMount() {
		if ($('#lightgallery').data('lightGallery')) $('#lightgallery').data('lightGallery').destroy(true)
		$('#lightgallery').lightGallery({
			thumbnail:true,
			animateThumb: false,
			showThumbByDefault: true,
			getCaptionFromTitleOrAlt: false
		})
	}

	componentDidUpdate() {
		if ($('#lightgallery').data('lightGallery')) $('#lightgallery').data('lightGallery').destroy(true)
		$('#lightgallery').lightGallery({
			thumbnail:true,
			animateThumb: false,
			showThumbByDefault: true,
			getCaptionFromTitleOrAlt: false
		})
	}
	notifyError = (text) => toast.error(text);
	onCommentTextChange(e) {
		let value = e.target.value;
		let textarea = e.currentTarget;

		textarea.style.height = "";
		textarea.style.height = Math.min(textarea.scrollHeight, 100) + "px";

		this.updateCommentText(value);
		this.updateToggleCommentPost(false);
	}

	updateCommentText(value) {
		let commentRegex = new RegExp(`^[\.a-zA-Z0-9,!?:;@#$~' ]*$`)
		if (commentRegex.test(value) === true || true){
				this.setState({ commentText: value });
			}
			else {
				this.notifyError("Only letters, numbers and special characters are allowed")
			}
	}

	updateCommentImage(value) {
		this.setState({ commentImage: value });
	}

	updateToggleCommentPost(value) {
		this.setState({ toggleCommentPost: value });
	}

	onImageUpload(e) {
		let imageUrls = [],
			fileLength = e.target.files.length;
		for (let i = 0; i < fileLength; i++) {
			if( /\.(jpe?g|png)$/i.test(e.target.files[i].name) === false ){
				
				this.notifyError("Only png and jpeg formats are accepted.")
			}
             else {
			let data = new FormData();

			data.append("image", e.target.files[i]);
			fetch(configs.BACKEND_API_URL + '/uploadImage', {
				method: "POST",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token,
				},
				body: data
			}).then((data) => data.json())
			.then(function(data) {
				if(data.status === 'ok') {
					imageUrls.push(data.url);

					if(fileLength === imageUrls.length) {
						this.updateCommentImage(imageUrls);
						this.updateToggleCommentPost(false);
					}
				}
			}.bind(this)).catch(err => { this.notifyError("Unsupported file format detected!"); e.target.value = null; });
		}
		}
	}
	
	postComment() {
		this.updateToggleCommentPost(true);
		let data = {};
		data.text = this.state.commentText.trim();

		if(this.state.commentImage.length) {
			data.images = this.state.commentImage;
		}

		// data.text = data.text.replace(/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/g, "<a href='$1' target='_blank'>$1</a>");
		let http_reg = /(?:^|[^"'])(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim,
			www_reg = /(?:^|[^"'\/\/])(\bwww?\.[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;

		data.text = data.text.replace(http_reg, ' <a href="$1" target="_blank">$1</a>');
		data.text = data.text.replace(www_reg, ' <a href="http://$1" target="_blank">$1</a>');

		fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + this.props.volunteerOpportunityId + '/add-comment', {
			method: "POST",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		}).then((data) => data.json())
		.then(function(data) {
			if(data.status === 'ok') {
				this.updateCommentText('');
				this.updateCommentImage([]);
				let path = window.location.pathname;
				path = path.split('/').pop();

				fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + path, {
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then((data) => data.json())
				.then(function(data) {
					if(data.hasOwnProperty('data')) {
						for(let i = 0; i < data.data.length; i++) {
							data.data.comments[i]['showReply'] = false;
						}
						this.props.updateSelectedOpportunity(data['data']);
						this.updateToggleCommentPost(false);
					} else {
					}
				}.bind(this));
			} else {
				// window.location = "/404";
			}
		}.bind(this));
	}

	cancelPost() {
		this.setState({ commentImage: [], commentText: '', toggleCommentPost: true });
	}

	render () {
		return (
			<div className="ee-discussion-container">
				<div className="mb10">
					<div id="post-sec">
						<div className="input-area-parent">
							<div className="input-section-text-block">
								<textarea name="" id="input-section-textarea" placeholder='Join the discussion...' value={this.state.commentText} onChange={this.onCommentTextChange.bind(this)}></textarea>
								<div className="input-actions">
									<label htmlFor="postImageUpload" className="attachment btn-file">
										<i className="fa fa-camera"> </i>
										<input id="postImageUpload" accept="image/*" type="file" multiple="multiple" style={{'display': 'block !important'}} onChange={this.onImageUpload.bind(this)}/>
									</label>
									{this.state.commentText.length > 0 || this.state.commentImage.length > 0 ? (
										<button className="post btn btn-sq btn-le btn-primary" disabled={this.state.toggleCommentPost} onClick={this.postComment.bind(this)}>Post</button>
									) : (
										<button className="post btn btn-sq btn-le btn-primary" disabled="disabled">Post</button>
									)}
									<button className="post cancel btn btn-sq btn-le btn-primary" onClick={this.cancelPost.bind(this)}>Cancel</button>
								</div>
							</div>
						</div>
					</div>

					{this.state.commentImage.length ? (
						<div className="col-md-12 uploadedImage">
							{this.state.commentImage.map(function (img, i) {
								return (
									<div style={{marginLeft: '10px', border: '2px solid black', float: 'left', width: '64px', height: '64px'}} key={i}>
										<img className="updImage" src={img} alt="image" style={{width: '60px', height: '60px'}} />
									</div>
								)
							})}
						</div>
					) : (null)}
				</div>

				{this.props.comments.length > 0 ? (
					this.props.comments.map(function (comment, i) {
						return(
							<Col md={12} className="comments-sec" key={i}>
								<ul>
									<SingleDiscussionComment selectedOpportunity={this.props.selectedOpportunity}
		                subComments={comment.subComments} comment={comment}
		                updateSelectedOpportunity={this.props.updateSelectedOpportunity}
		                volunteerOpportunityId={this.props.volunteerOpportunityId}
		                updateSubCommentText={this.props.updateSubCommentText} />
								</ul>
							</Col>
						)
					}.bind(this))
				) : (null)}
				   <ToastContainer
                position="bottom-center"
                autoClose={4000}
                hideProgressBar={true}
                closeOnClick={false}
                newestOnTop={false}
                pauseOnHover={true}
              />
			</div>
		)
	}
}