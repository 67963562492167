import React, { Component } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import "sweetalert2/dist/sweetalert2.min.css";
import {warning} from 'react-icons-kit/fa/warning'
import Icon from "react-icons-kit";
import configs from "../../../../../config/urlConfigs.js";
const readCookie = require("../../../../../cookie.js").readCookie;

export default class AdminDeclareModal extends Component {
  constructor(props) {
    super(props);
   
    this.state ={
        diclaimer :false,
        targetLink :props.targetLink,
    }
  }

 
  openLocationInMap(url) {
    window.open(url, "_blank");
  }

  


  render() {
    return (
      <Modal 
      bsSize="sm"
      className="declare-modal"
      show={this.props.disclaimerModalVisibility}
      onHide={this.props.hideDisclaimerModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-header-custom">
            Disclaimer
          </Modal.Title>
        </Modal.Header>
 
        <Modal.Body>
          <Row>
            <Col className="margin-col-50" md={12}>
             <Col md={12}>
               <div>
               <Icon icon={warning} size={25}
                     style={{color:configs.THEME_COLOR}}/>
                   <span>
                    <Col >ATTENTION </Col>
                    <Col>This link opens in a new browser tab and takes you to another website outside this portal.
                   </Col>
                   {/* <Col>Confirming that you want to continue, should open a new tab and open the destination website.</Col> */}
                   </span>
               </div></Col>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
        <a  class="btn btn-primary-disabled" 
            onClick={this.props.hideDisclaimerModal} 
            target="_blank">Close{" "}</a>
           <a  class="btn btn-primary" 
           onClick={
           this.openLocationInMap.bind(this,
           this.state.targetLink)} target="_blank">Continue{" "}</a>
            </Modal.Footer>
      </Modal>
    );
  }
}
