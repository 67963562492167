const initialState = {
	ngoCount: 0,
	companyCount: 0,
	projectCount: 0,
	featured: null,
};

const LandingReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_NGO_COUNT':
			return Object.assign({}, state, {
				ngoCount: action.count
			});
		case 'UPDATE_COMPANY_COUNT':
			return Object.assign({}, state, {
				companyCount: action.count
			});
		case 'UPDATE_PROJECT_COUNT':
			return Object.assign({}, state, {
				projectCount: action.count
			});
		case 'UPDATE_FEATURED':
			return Object.assign({}, state, {
				featured: action.featured
			});
		default:
			return state
	}
}

export default LandingReducer;