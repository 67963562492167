import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Tour from 'reactour';
import axios from 'axios';

import * as action from '../../../../actions/EEDiscoverOpportunitiesAction';

import DiscoverOpportunitiesList from './DiscoverOpportunitiesList';
import DiscoverOpportunitiesFilter from './DiscoverOpportunitiesFilter'
import configs from '../../../../config/urlConfigs';

const readCookie = require('../../../../cookie.js').readCookie;

class DiscoverOpportunities extends Component {
	constructor(props) {
		super(props);
		// this.axiosFetch = this.axiosFetch.bind(this);
		this.state = {
			isTourOpen: false,
			opportunityStatus:!props.location.state ?   "" :this.props.location.state.status || "Ongoing",
		};

		this.steps = [];
	}

	// axiosFetch = async () => {
	// 	try {
	// 		const response = await axios.get(
	// 			`${configs.BACKEND_API_URL}/ee/volunteer-opportunity/ee-discover?status=${this.state.opportunityStatus}`,
	// 			{
	// 				headers: {
	// 					'Auth': JSON.parse(readCookie('access_token')).access_token
	// 					// Authorization: `Bearer ${JSON.parse(readCookie('access_token')).access_token}`,
	// 				},
	// 			}
	// 		);

	// 		const data = response.data;
	// 		this.props.actions.updateOpportunities(data.data);
	// 		if ((data.data || []).length) {
	// 			this.steps.push({
	// 				selector: '.discover-opportunities-filters-container',
	// 				content: 'Apply your preferred critera and click here to see the filtered opportunities.'
	// 			}, {
	// 				selector: '.ee-apply-btn',
	// 				content: 'Click on the date to learn more about this opportunity.'
	// 			});
	// 			// this.setState({isTourOpen: true})
	// 		}
	// 	} catch (error) {
	// 		console.error('There was an error with the Axios operation:', error);
	// 	}
	// }		

	componentDidMount() {
		// this.axiosFetch();
		fetch(configs.BACKEND_API_URL + `/ee/volunteer-opportunity/ee-discover${this.state.opportunityStatus ? '?status='+this.state.opportunityStatus:''}`, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			console.log("🚀 ~ file: DiscoverOpportunities.js:33 ~ DiscoverOpportunities ~ componentDidMount ~ response:", response)
			// console.log("🚀 ~ file: DiscoverOpportunities.js:33 ~ DiscoverOpportunities ~ componentDidMount ~ response:", response.json())
			return response.json();
		}).then(function (data) {
			this.props.actions.updateOpportunities(data.data, data.totalOpportunities);
			if ((data.data || []).length) {
				this.steps.push({
					selector: '.discover-opportunities-filters-container',
					content: 'Apply your preferred critera and click here to see the filtered opportunities.'
				},{
					selector: '.ee-apply-btn',
					content: 'Click on the date to learn more about this opportunity.'
				});
				// this.setState({isTourOpen: true})
			}
		}.bind(this)).catch((err) => {
			console.log("🚀 ~ file: DiscoverOpportunities.js:49 ~ DiscoverOpportunities ~ componentDidMount ~ err:", err)
			
		});

		
	}


	render() {
		return (
			<div className="ee-page-container discover-opportunities-page">
				
				<Row>
					<Col md={12} className="text-center">
						<h2 className="ee-page-title">Discover Opportunities</h2>
					</Col>
				</Row>
				{this.props.opportunities !== null ? (
					<div>
						<DiscoverOpportunitiesFilter causes={this.props.causes}
							updateOpportunities={this.props.actions.updateOpportunities}
							updateLocation={this.props.actions.updateLocation}
							updateCauses={this.props.actions.updateCauses} 
							status={!this.props.location.state ? "" :this.props.location.state.status}
							updatePageNumber={this.props.actions.updatePageNumber}
						/>
						<DiscoverOpportunitiesList opportunities={this.props.opportunities}
							updateOpportunities={this.props.actions.updateOpportunities}
							updatePageNumber={this.props.actions.updatePageNumber}
							totalOpportunities={this.props.totalOpportunities}
							pageNumber={this.props.pageNumber}
						/>
						<Tour
							accentColor="var(--themeColor)"
							steps={this.steps}
							badgeContent={(curr, tot) => `${curr} of ${tot}`}
							closeWithMask
							isOpen={this.state.isTourOpen && this.props.opportunities && this.props.opportunities.length}
							onRequestClose={() => { this.setState({ isTourOpen: false }) }} />
					</div>
				) : <div className="data-loader" />}

			</div>
		);
	}
}

DiscoverOpportunities.propTypes = {
	opportunities: PropTypes.array,
	causes: PropTypes.array,
	pageNumber: PropTypes.number,
	totalOpportunities: PropTypes.number,
};

function mapStateToProps(state) {
	return {
		opportunities: state.EEDiscoverOpportunity.opportunities,
		causes: state.EEDiscoverOpportunity.causes,
		pageNumber: state.EEAdminDiscoverOpportunities.pageNumber,
		totalOpportunities: state.EEAdminDiscoverOpportunities.totalOpportunities,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverOpportunities);