import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as action from '../../../../../actions/EEAdminFundraisersAction';

import FundraisersList from './FundraisersList';
import FundraisersFilter from './FundraisersFilter';
import configs from '../../../../../config/urlConfigs';

const readCookie = require('../../../../../cookie.js').readCookie;

class Fundraisers extends Component {
	componentDidMount() {
		fetch(configs.BACKEND_API_URL + '/project/admin-discover', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			this.props.actions.updateProjects(data.data)
		}.bind(this));
	}

	render () {
		return (
			<div className="ee-page-container fundraisers-page">
				<Row>
					<Col md={12} className="text-center">
						<h2 className="ee-page-title">Manage Fundraisers</h2>
					</Col>
				</Row>

				{this.props.projects !== null ?
					(
						<div>
							<FundraisersFilter causes={this.props.causes}
              	updateProjects={this.props.actions.updateProjects}
              	updateLocation={this.props.actions.updateLocation}
              	updateCauses={this.props.actions.updateCauses}/>
							<FundraisersList projects={this.props.projects} updateProjects={this.props.actions.updateProjects} />
						</div>
					) : (
						null
					)
				}
			</div>
		);
	}
}


Fundraisers.propTypes = {
	projects: PropTypes.array,
	causes: PropTypes.array,
};

function mapStateToProps(state) {
	return {
		projects: state.EEAdminFundraisers.projects,
		causes: state.EEAdminFundraisers.causes
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Fundraisers);