import React from 'react'
import { causes } from '../../../../constants/Causes';
import RenderArray from './RenderArray';
import RenderObject from './RenderObject';

const Step3Cause = ({ selectedCause, handleSelectCause, subdomain }) => {
    return (
        <div>
            {subdomain === 'indusind'
                ? <RenderObject
                    dataObject={causes.indusind}
                    handleSelect={handleSelectCause}
                    selected={selectedCause}
                />
                : <RenderArray
                    dataArray={causes.le}
                    handleSelect={handleSelectCause}
                    selected={selectedCause}
                    images
                />}
        </div>
    )
}

export default Step3Cause