import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {bindActionCreators} from 'redux';
import * as action from '../../../../actions/EEAdminLeaderboardAction';
import LeaderboardFilter from "./LeaderboardFilter";
import configs from '../../../../config/urlConfigs';
import { OrgDataContext } from '../../../../routes';
import Pagination from './Pagination';
import { CSVLink, CSVDownload } from 'react-csv';
const readCookie = require('../../../../cookie.js').readCookie;
const subdomain = configs.SUBDOMAIN;

class Leaderboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			offices: [],
			departments: [],
			cities: [],
			rowsPerPage: 100,
			currentPage: 1,
			leaderBoardCsv :false,
			output:[],
		}
		this.csvLinkRef = React.createRef();
		this.fetchIBLCities = this.fetchIBLCities.bind(this);
		this.fetchLeaderBoardCSV = this.fetchLeaderBoardCSV.bind(this);
	}

	componentDidMount() {
		this.fetchIBLCities()
		fetch(configs.BACKEND_API_URL + '/ee/get-vo-leaderboard', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			this.props.actions.updateLeaderboard(data.data);
			this.setState({totalSize : data.data.length});
		}.bind(this));

		fetch(configs.BACKEND_API_URL + '/list', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			this.setState({ offices: data.lists });
		}.bind(this));

		fetch(configs.BACKEND_API_URL + '/departments', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			this.setState({ departments: data.data });
		}.bind(this));
	}

	employeeId(cell, row) {
		if(cell === '') {
			return ( <span>N/A</span> )
		} else if(row.listId !== '') {
			return (
				<a href={'/team-management/' + row.listId + '/' + row.userId} title={cell}>{cell}</a>
			)
		} else {
			return (
				<span>{cell}</span>
			);
		}
	}

	fullName(cell, row) {
		if(cell === '') {
			return ( <span>N/A</span> )
		} else if(row.listId !== '') {
			return (
				<a href={'/team-management/' + row.listId + '/' + row.userId} title={cell}>{cell}</a>
			)
		} else {
			return (
				<span>{cell}</span>
			);
		}
	}


	updateLeaderboard = (data, type) => {
		this.props.actions.updateFilterType(type);
		this.props.actions.updateLeaderboard(data);
	}
	fetchIBLCities() {
		var that = this;
		fetch(configs.BACKEND_API_URL + '/ibl-particulars/city', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			let cities = [];
			// let selectedCities = [];
			for (var i = 0; i < data.cities.length; i++) {
				let city = {};
				city['value'] = data.cities[i];
				city['label'] = data.cities[i];
				// if (that.props.opportunity.config.hasOwnProperty('cities')) {
				// 	if (Array.isArray(that.props.opportunity.config.cities)) {
				// 		if (that.props.opportunity.config.cities.indexOf(data.cities[i]) > -1) {
				// 			city['selected'] = true;
				// 			selectedCities.push(data.cities[i]);
				// 		}
				// 	} else if (that.props.opportunity.config.cities === true) {
				// 		city['selected'] = true;
				// 		selectedCities.push(data.cities[i]);
				// 	}
				// } else {
				// 	city['selected'] = true;
				// 	selectedCities.push(data.cities[i]);
				// }
				cities.push(city);
			}
			that.setState({
				cities,
				// selectedCities
			});
		});
	}

fetchLeaderBoard(pageNumber) {
	fetch(configs.BACKEND_API_URL + '/ee/get-vo-leaderboard' + (pageNumber ? `?pageNumber=${pageNumber}` : ''), {
		method: "GET",
		headers: {
			'Auth': JSON.parse(readCookie('access_token')).access_token
		},
	}).then(function(response) {
		return response.json();
	}).then(function(data) {
		this.props.actions.updateLeaderboard(data.data)
	}.bind(this))
}
fetchLeaderBoardCSV() {
	this.setState({leaderBoardCsv:true});

	fetch(configs.BACKEND_API_URL + '/ee/get-vo-leaderboard/leaderboardcsv', {
		method: "GET",
		headers: {
			'Auth': JSON.parse(readCookie('access_token')).access_token
		},
	}).then(function(response) {
		return response.json();
	}).then(function(data) {

		let output = [];
		function timeToDecimal(timeStr) {
			// Check if timeStr is valid and formatted as HH:MM or HH:MM:SS
			if (!timeStr || !/^\d{1,2}:\d{2}(:\d{2})?$/.test(timeStr)) {
				console.error('Invalid time format:', timeStr);
				return NaN;
			}
		
			// Append ":00" if the format is HH:MM
			if (timeStr.split(':').length === 2) {
				timeStr += ':00';
			}
		
			let [hours, minutes, seconds] = timeStr.split(':').map(Number);
			
			// Validate the parts to ensure they are numbers
			if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
				console.error('Invalid time parts:', { hours, minutes, seconds });
				return NaN;
			}
		
			let decimalMinutes = minutes / 60;
			let decimalSeconds = seconds / 3600;
			let decimalTime = hours + decimalMinutes + decimalSeconds;
			return parseFloat(decimalTime.toFixed(2));
		}
		data.data.forEach(employee => {
			employee.opportunityData.forEach(opportunity => {
				output.push({
					employeeId: employee.employeeId,
					fullName: employee.employeeName,
					office: employee.office,
					email: employee.email,
					city: employee.city,
					department: employee.department,
					designation: employee.designation,
					lifetimeOpportunities: employee.eventCount,
					lifetimeHours: employee.clockedHours,
					opportunityId: opportunity.opportunityId,
					opportunityTitle: opportunity.opportunityTitle,
					employeeHours: timeToDecimal(opportunity.employeeHours),
					guestHours: timeToDecimal(opportunity.totalGuestHours),
					totalHours: timeToDecimal(opportunity.totalHours),
					date: opportunity.date
				});
			});
		});
		this.setState({ output: output, leaderBoardCsv: false }, () => {
			if (this.csvLinkRef.current) {
			  this.csvLinkRef.current.link.click();
			}
		  });
	}.bind(this))
	
}


paginate = (pageNumber) => {
    this.setState({
      currentPage: pageNumber,
    });
  };

  pageSize = (Number) => {
    this.setState({
      rowsPerPage: Number,
    });
  };

  createCustomExportCSVButton(leaderBoard) {

	
	
	  
	//   console.log(output);
  

   const headers = [
	 {label:"EmployeeId",key:"employeeId"},
	 { label: "Employee Name", key: "fullName" },
	 {label : "Email", key :"email"},
	 { label: "Office", key: "office" },
	 { label: "City", key: "city" },
	 { label: "Department", key: "department" },
	 { label: "Designation", key: "designation" },
	 { label: "Lifetime Number of opportunities", key: "lifetimeOpportunities" },
	 { label: " Lifetime Number of Hours", key: "lifetimeHours" },
	 { label: "Opportunity Id", key: "opportunityId" },
	 {label :"Opportunity Date", key : "date"},
	 { label: "Opportunity Title", key: "opportunityTitle" },
	 {label :"Employee Hours",key :"employeeHours"},
	 {label :"Guest Hours",key :"guestHours"},
	 {label :"Total Hours",key :"totalHours"}
   ];


   return (
	   <>
		 <ExportCSVButton
		   style={{
			 background: 'var(--themeColor)',
			 borderColor: 'var(--themeColor)',
			 boxShadow: "1px 1px 1px #515356"
		   }}
		   // className="btn btn-endorse"
		   btnText='Export to CSV'
		    onClick={this.fetchLeaderBoardCSV}  
		   />
		   <CSVLink
		 headers={headers}
		 data={this.state.output}
		 ref={this.csvLinkRef}
		 filename={
		   "Leaderboard - " +
		   String(moment().unix()) +
		   ".csv"
		 }
		 target="_blank">
	   </CSVLink>
	   </>
   );
 }

	  

	render () {

		const options = { 
			exportCSVBtn: () => this.createCustomExportCSVButton(this.props.leaderboard), 
		  };

		const indexOfLastRow = this.state.currentPage * this.state.rowsPerPage;
		const indexOfFirstRow = indexOfLastRow - this.state.rowsPerPage;
		const currentRows = this.props.leaderboard && this.props.leaderboard.length> 0 && this.props.leaderboard.slice(
		  indexOfFirstRow,
		  indexOfLastRow
		);
	
		if(this.props.leaderboard) {
			return (
				<OrgDataContext.Consumer>
					{(organisationData) => <div className="ee-page-container">
					<Row>
						<Col md={12} className="text-center">
							<h2 className="ee-page-title">Leaderboard</h2>
						</Col>
						<Col md={12}>
							<LeaderboardFilter leaderboard={this.props.leaderboard} offices={this.state.offices}
							cities={this.state.cities}
								updateLeaderboard={this.updateLeaderboard} departments={this.state.departments}
                updateFilterType={this.props.actions.updateFilterType} />
						</Col>
						<div className={this.state.leaderBoardCsv ? 'overlay' : 'hidden'}>
        				  <div className="loader"></div>
		  					<div>Preparing CSV...</div>
        </div>
					</Row>
					{this.props.filterType === 'hours' ? (
					<>	
					
						<BootstrapTable data={currentRows} hover exportCSV className="leaderboard-table" options={options}
							csvFileName={'Leaderboard - ' + String(moment().unix()) + '.csv'}>
							<TableHeaderColumn isKey dataField='userId' hidden export={false}>ID</TableHeaderColumn>
							<TableHeaderColumn dataField='employeeId' dataSort dataFormat={this.employeeId.bind(this)}>Employee ID</TableHeaderColumn>
							<TableHeaderColumn dataField='employeeName' dataSort dataFormat={this.fullName.bind(this)}>Employee Name</TableHeaderColumn>
								<TableHeaderColumn dataField='location' dataSort>Office</TableHeaderColumn>
								<TableHeaderColumn dataField='city' dataSort>City</TableHeaderColumn>
							<TableHeaderColumn dataField='department' dataSort>Department</TableHeaderColumn>
							<TableHeaderColumn dataField='designation' dataSort>Designation</TableHeaderColumn>
								<TableHeaderColumn dataField='eventCount' dataSort>No. of Activities Participated</TableHeaderColumn>
								<TableHeaderColumn dataField='clockedHours' dataSort>Clocked Hours</TableHeaderColumn>
							<TableHeaderColumn dataField='totalPoints' hidden={!organisationData.eePreferences.enableGoodwillPoint} dataSort>Goodwill Points</TableHeaderColumn>
						</BootstrapTable>
		 				<Pagination
						rowsPerPage={this.state.rowsPerPage}
						totalRows={this.props.leaderboard.length}
						paginate={this.paginate}
						pageSize={this.pageSize}
						url="/leaderboard"
          				/> </>
					) : (
						<BootstrapTable data={this.props.leaderboard} hover exportCSV className="leaderboard-table" csvFileName={'Leaderboard - ' + String(moment().unix()) + '.csv'}>
							<TableHeaderColumn isKey dataField='userId' hidden export={false}>ID</TableHeaderColumn>
							<TableHeaderColumn dataField='employeeId' dataSort dataFormat={this.employeeId.bind(this)}>Employee ID</TableHeaderColumn>
							<TableHeaderColumn dataField='employeeName' dataSort dataFormat={this.fullName.bind(this)}>Employee Name</TableHeaderColumn>
							<TableHeaderColumn dataField='donatedAmount' dataSort>Amount Donated</TableHeaderColumn>
							<TableHeaderColumn dataField='count' dataSort>Donation Count</TableHeaderColumn>
								<TableHeaderColumn dataField='totalPoints' hidden={!organisationData.eePreferences.enableGoodwillPoint}  dataSort>Goodwill Points</TableHeaderColumn>
						</BootstrapTable>

					)}
				</div>
				}
					</OrgDataContext.Consumer>);
		} else {
			return null
		}
	}
}


Leaderboard.propTypes = {
	leaderboard: PropTypes.array,
	filterType: PropTypes.string
};

function mapStateToProps(state) {
	return {
		leaderboard: state.EEAdminLeaderboard.leaderboard,
		filterType: state.EEAdminLeaderboard.filterType
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);