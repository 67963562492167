import React, {Component} from 'react';
import { Menu } from 'antd';
import SvgIcon from 'react-icons-kit';

import {ic_class} from 'react-icons-kit/md/ic_class';
import {calendar_add} from 'react-icons-kit/ikons/calendar_add';
import {ic_hourglass_full} from 'react-icons-kit/md/ic_hourglass_full';
import {users} from 'react-icons-kit/feather/users';
import {user_add} from 'react-icons-kit/ikons/user_add';
import {cog} from 'react-icons-kit/icomoon/cog';
import { logout } from 'react-icons-kit/ikons/logout';
import { eraseAllCookies } from '../../cookie.js';
import configs from '../../config/urlConfigs.js';

const readCookie = require('../../cookie.js').readCookie;
const eraseCookie = require('../../cookie.js').eraseCookie;

const SubMenu = Menu.SubMenu;

export default class AdminSidebar extends Component {
	constructor(props) {
		super(props);
		let defaultSelected = window.location.href.split('/').pop();
		this.state = {
			defaultSelected
		};
	}

	gotoPage(page) {
		window.location.pathname =  page;
		// window.location.pathname = '/admin' + page;
	}

	logout() {
		fetch(configs.BACKEND_API_URL + '/logout', {
			method: "get",
			credentials: "include",
			headers: {
				Auth: JSON.parse(readCookie("access_token")).access_token,
			},
		})
			.then((data) => data.json())
			.then((data) => {
				eraseAllCookies();
		eraseCookie('userData');
		eraseCookie('access_token');
		window.location.pathname = '/';
			});
	}

	handleClick(e) {
		if(e.key === 'manage-corporate') this.gotoPage('/manage-corporate');
		else if(e.key === 'create-volunteer-opportunity') this.gotoPage('/create-volunteer-opportunity');
		else if(e.key === 'manage-volunteer-opportunity') this.gotoPage('/manage-volunteer-opportunity');
		else if(e.key === 'manage-ngo') this.gotoPage('/manage-ngo');
		else if(e.key === 'add-member') this.gotoPage('/add-member');
		else if(e.key === 'profile-settings') this.gotoPage('/profile-settings');
		else if(e.key === 'logout') this.logout();
	}

	render() {
		let defaultOpenKeys = [];
		return (
			<div className="ee-header-container">
				<div className="ee-header">
					<div className="ee-header-user">
						<div>
							<div>
								{JSON.parse(readCookie('userData')).fullName}
							</div>
							<div> {JSON.parse(readCookie('userData')).email} </div>
						</div>
					</div>
					<Menu id="ee-navbar" onClick={this.handleClick.bind(this)} style={{ width: 300 }} defaultSelectedKeys={[this.props.selected]} defaultOpenKeys={defaultOpenKeys} mode="inline">
						<Menu.Item key="manage-corporate">
							<SvgIcon size={16} icon={ic_class} />
							<span className="ee-header-menu-text">Manage Corporate</span>
						</Menu.Item>

						<Menu.Item key="create-volunteer-opportunity">
							<SvgIcon size={16} icon={calendar_add} />
							<span className="ee-header-menu-text">Create Volunteer Opportunity</span>
						</Menu.Item>

						<Menu.Item key="manage-volunteer-opportunity">
							<SvgIcon size={16} icon={ic_hourglass_full} />
							<span className="ee-header-menu-text">Manage Volunteer Opportunity</span>
						</Menu.Item>

						<Menu.Item key="manage-ngo">
							<SvgIcon size={16} icon={users} />
							<span className="ee-header-menu-text">Manage NGO</span>
						</Menu.Item>

						<Menu.Item key="add-member">
							<SvgIcon size={16} icon={user_add} />
							<span className="ee-header-menu-text">Add Member</span>
						</Menu.Item>

						<Menu.Item key="profile-settings">
							<SvgIcon size={16} icon={cog} />
							<span className="ee-header-menu-text">Profile Settings</span>
						</Menu.Item>

						<Menu.Item key="logout">
							<SvgIcon size={16} icon={logout} />
							<span className="ee-header-menu-text">Logout</span>
						</Menu.Item>
					</Menu>
				</div>
			</div>
		)
	}
}