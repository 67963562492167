import React, { Component } from 'react';
import $ from 'jquery';
import { Row } from "react-bootstrap";
import swal from 'sweetalert2';
import ReactPasswordStrength from 'react-password-strength';

import 'sweetalert2/dist/sweetalert2.min.css';
import configs from '../../config/urlConfigs.js';

const readCookie = require('../../cookie.js').readCookie;

export default class ChangePasswordLayout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: false,
			password: '',
			passwordData: null,
			confirmPassword: '',
			invalidError: false,
			submittedPassword: false
		}
	}

	change(e){
		this.setState({ error: false, confirmPassword: e.target.value});
	}

	passwordSubmit(e) {
		e.preventDefault();
		let password = this.state.password;
		let confirmPassword = this.state.confirmPassword;
		let passwordData = this.state.passwordData;
		let token = this.props.match.params.token;
		if(passwordData !== null) {
			if(password === confirmPassword && passwordData.isValid) {
				fetch(configs.BACKEND_API_URL + '/reset-password', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						token,
						password
					})
				}).then((response) => response.json())
				.then(function(data) {
					if(data['status'] !== 'ok') {
						swal('Oops...', data.message, 'error');
					} else if(data.status === 'ok') {
						this.setState({ submittedPassword: true });
						swal({
							title: 'Password saved!',
							html: '<h4>You have successfully updated your password</h4>',
							type: 'success'
						});
						window.location.pathname = '/';
					}
				}.bind(this));
			} else {
				if(password !== confirmPassword) this.setState({ error: true });
				if(!passwordData.isValid) this.setState({ invalidError: true });
			}
		} else {
			this.setState({ invalidError: true });
		}
	}

	changeNewPassword(passwordData){
		this.setState({ passwordData, password: passwordData.password, invalidError: false, error: false });
	}

	render() {
		return (
			<div className="signup-container">
				<div className="signup-switcher">
					<div className="switch-container">
						<div className="signup-switch-corporate" id="signup-corporate">
							<span className="signup-switch-sub-text">No one can do everything, but everyone can do something.</span>
							<div id="letsengage-heading">LetsEngage</div>
							<div className="signup-switch-text">
								to make the world a better place for all!
							</div>
							<span className="smiley">&#9787;</span>
						</div>
					</div>
					<div className="forms-container">
						<div className="signup-switch-corporate-forms">
							<div className="flip-container">
								<div className="flipper">
									<div className="front">
										<Row className="signin-container" style={{ boxShadow: "#eee 5px 3px 50px", padding: "10px 20px", borderRadius: "7px", background: "#fff" }}>
											<h1 className="signup-header le-color">Reset Password!</h1>

											<div className="form-group">
											  <label className="control-label is-imp">
											    New Password:
											  </label>
											  <ReactPasswordStrength minLength={4} minScore={1} className="new-password"
													scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
													changeCallback={this.changeNewPassword.bind(this)}
													inputProps={{ name: "password_input", autoComplete: "off", placeholder: "Enter New Password", className: "form-control" }}
												/>
											</div>
											<div className="form-group">
											  <label className="control-label is-imp">
											    Confirm New Password:
											  </label>
											  <input type="password" className="form-control" placeholder="********" required onChange={this.change.bind(this)} />
											</div>

											<span hidden={!this.state.invalidError}><span className="required-span"> Invalid Password! </span></span>
											{this.state.error === true ? (
												<div className="show-error">
													<span>Password did not match. Try again!</span>
												</div>
											) : (null)}

											<div className="form-group">
											  <button id="signinCorporate" className="btn btn-center submit-btn" onClick={this.passwordSubmit.bind(this)}>
											    <span id="login-span">Submit</span>
													<i className="fa fa-check" hidden={!this.state.submittedPassword}></i>
											  </button>
											</div>
										</Row>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
