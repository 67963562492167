import React, { Component } from 'react';
import Multiselect from 'react-bootstrap-multiselect';
import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css';

import {Row, Col, Button, Modal} from 'react-bootstrap';
import { readCookie } from '../../../../cookie';
import { OrgDataContext } from '../../../../routes';
import configs from '../../../../config/urlConfigs';


let organisationDataContext = null;
export default class AddEditMemberModal extends Component {
  // ssoEnabled = organisationDataContext && organisationDataContext.organisationData ? organisationDataContext.organisationData.ssoEnabled : false;
  ssoEnabled = configs.SUBDOMAIN === 'indusind';//organisationDataContext && organisationDataContext.organisationData ? organisationDataContext.organisationData.ssoEnabled : false;

  render() {
    return (
      <OrgDataContext.Consumer>
        {(organisationData) => {
          organisationDataContext = organisationData;
          return (<Modal bsSize="large" className="add-member-modal" show={this.props.addMemberModalVisibility} onHide={this.props.hideAddMemberModal}>
        <Modal.Header>
          <Modal.Title className="modal-header-custom">
            {this.props.editMember ? 'Edit Employee' : 'Add Employee'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="margin-col-50" md={12}>
                  <form id="edit-org-details-modal-form"
                    // ref="vForm"
                  >
                <Col md={12}>
                  <Col className="form-group" md={4}>
                    <label className="control-label">Employee ID: </label>
                    <input disabled={this.ssoEnabled} type="text" className="form-control" id="emp_id" name="emp_id" onChange={this.props.updateChange.bind(this, "emp_id", false)}
                      placeholder="Employee ID" required defaultValue={this.props.emp_id} />
                    {this.props.error.indexOf('emp_id') > -1 ? (
                      <span className="red-color">Incorrect Employee ID!</span>
                    ) : (null)}
                  </Col>

                  <Col className="form-group" md={4}>
                    <label className="control-label is-imp">Full Name: </label>
                    <input disabled={this.ssoEnabled} type="text" className="form-control" id="full_name" name="full_name" onChange={this.props.updateChange.bind(this, "full_name", true)}
                      placeholder="Full Name" required defaultValue={this.props.full_name} />
                    {this.props.error.indexOf('full_name') > -1 ? (
                      <span className="red-color">Incorrect Name!</span>
                    ) : (null)}
                  </Col>

                  <Col className="form-group" md={4}>
                    <label className="control-label is-imp">Email ID: </label>
                    <input  type="email" className="form-control" id="email" name="email" onChange={this.props.updateChange.bind(this, "email", true)}
                      placeholder="Email ID" required defaultValue={this.props.email} disabled={(this.props.editMember || this.ssoEnabled) ? true : false} />
                    {this.props.error.indexOf('email') > -1 ? (
                      <span className="red-color">Incorrect Email ID!</span>
                     ) : (null)}
                  </Col>
                </Col>

                <Col md={12}>
                  <Col className="form-group" md={4}>
                    <label className="control-label">Gender: </label>
                    <select disabled={this.ssoEnabled}  className="form-control" id="gender" name="gender" onChange={this.props.updateChange.bind(this, "gender", false)} required value={this.props.gender}>
                    	<option value="" disabled>Select Gender</option>
                    	<option value="Male">Male</option>
                    	<option value="Female">Female</option>
                    	<option value="Others">Others</option>
                    </select>
                    {this.props.error.indexOf('gender') > -1 ? (
                      <span className="red-color">Incorrect Gender!</span>
                     ) : (null)}
                  </Col>

                  <Col className="form-group" md={4}>
                    <label className="control-label">Department: </label>
                    <input disabled={this.ssoEnabled} type="text" className="form-control" id="department" name="department" onChange={this.props.updateChange.bind(this, "department", false)}
                      placeholder="Department" required defaultValue={this.props.department} />
                    {this.props.error.indexOf('department') > -1 ? (
                      <span className="red-color">Incorrect Department!</span>
                     ) : (null)}
                  </Col>

                  <Col className="form-group" md={4}>
                    <label className="control-label">Phone: </label>
                    <input disabled={this.ssoEnabled} type="mob" className="form-control" id="phone" name="phone" onChange={this.props.updateChange.bind(this, "phone", false)}
                      placeholder="Phone" required defaultValue={this.props.phone} />
                    {this.props.error.indexOf('phone') > -1 ? (
                      <span className="red-color">Incorrect Phone!</span>
                     ) : (null)}
                  </Col>
                </Col>

                <Col md={12}>
                  <Col className="form-group" md={4}>
                    <label className="control-label">Designation: </label>
                    <input disabled={this.ssoEnabled} type="text" className="form-control" id="designation" name="designation" onChange={this.props.updateChange.bind(this, "designation", false)}
                      placeholder="Designation" required defaultValue={this.props.designation} />
                    {this.props.error.indexOf('designation') > -1 ? (
                      <span className="red-color">Incorrect Designation!</span>
                     ) : (null)}
                  </Col>

                  <Col className="form-group" md={4}>
                    <label className="control-label">Status: </label>
                    <select className="form-control" id="status" name="status" onChange={this.props.updateChange.bind(this, "status", false)} required value={this.props.status}>
                    	<option value="" disabled>Select Status</option>
                    	<option value="Active">Active</option>
                    	<option value="Terminated">Deactivated</option>
                    </select>
                    {this.props.error.indexOf('status') > -1 ? (
                      <span className="red-color">Incorrect Status!</span>
                     ) : (null)}
                  </Col>

                  <Col className="form-group" md={4}>
                    <label className="control-label is-imp">Assign Role: </label>
                    <select className="form-control" id="role" name="role" onChange={this.props.updateChange.bind(this, "role", true)} required value={this.props.role}>
                      <option value="" disabled>Select Role</option>
                      <option value="standard">Employee Only</option>
                          <option value="admin">Has Administrator Rights</option>
                          <option value="office-admin">City Admin</option>
                          {/* <option value="office-admin">Has location level access rights</option> */}
                    </select>
                    {this.props.error.indexOf('role') > -1 ? (
                      <span className="red-color">Incorrect Status!</span>
                     ) : (null)}
                      </Col>
                      
                      {this.props.role === 'office-admin' ? <Col className="form-group" md={4}>
                        <label className="control-label is-imp">Select Cities: </label>
                        <Multiselect
                          id="accessLocations"
                          name="accessLocations"
                          className="form-control inline"
                          onChange={this.props.updateChange.bind(this, "accessLocations", true)}
                          required
                          value={this.props.accessLocations}
                          data={this.props.cities} enableCaseInsensitiveFiltering multiple
                          enableFiltering buttonWidth={'100%'}
                          enableClickableOptGroups enableCollapsibleOptGroups numberDisplayed={3} includeSelectAllOption
                        />
                        {this.props.error.indexOf('accessLocations') > -1 ? (
                          <span className="red-color">Please select at least one city!</span>
                        ) : (null)}
                      </Col> : null}
                    </Col>
                    
                    


              </form>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="footer-center">
        	<Button className="btn btn-success" onClick={this.props.addMember.bind(this)}>Save</Button>
        	<Button className="btn btn-danger" onClick={this.props.hideAddMemberModal.bind(this)}>Cancel</Button>
        </Modal.Footer>
        </Modal>)}}
      </OrgDataContext.Consumer>
		)
	}
}