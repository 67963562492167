export const causes = {
    le: [
        'No powerty',
        'Zero Hunger',
        'Goode health and well-being',
        'Quality education',
        'Gender equality',
        'Clean water amd sanitation',
        'Affordable and clean energy',
        'decent work and economic growth',
        'Industry, innovation and infrastructure',
        'reduced inequalities',
        'sustainable cities and communities',
        'Responsible consumptions and production',
        'Climate Action',
        'Life below water',
        'Life on land',
        'Peace, justice and strong institution',
        'Partnership for the goals'
    ],
    indusind: {
        'Environment': ['Trees and Green Cover', 'Cleaning of Beaches and Water Fronts', 'Waste Segregation', 'Swacch Bharat Abhiyan', 'Access to Safe Drinking Water', 'Farmers in Distress'],
        'Wildlife Conservation': ['Tiger Conservation', 'Rhino Conservation', 'Elephant Conservation', 'Protection of Forests and Ecosystems', 'Census of Animals'],
        'Education': ['Education for underprivileged children', 'Education of Adults', 'Infrastructure and resources for Government Schools', 'Serving Mid-Day meals', 'Assisting teachers in Government Schools', 'Digital Literacy', 'Financial Literacy', 'Education of the Differently Abled', 'Education of the Girl Child'],
        'Social Care': ['Care of the Elderly, Street Children, Orphans', 'Home Bound', 'Urban Poor', 'Rural Poor', 'People with mental or physical disabilities'],
        'HealthCare': ['Cancer Care', 'HIV Care', 'Nutrition and Care of Mother and Child', 'Preventive Healthcare Awareness', 'Vaccination Awareness', 'Menstrual health and hygiene', "Blood Donation"],
        'Skill Development': ['Women', 'Youngsters', 'Differently-Abled', 'Unemployed poor'],
        'Relief and Rehabilitation': ['Disaster relief & rehabilitation', 'Rehabilitation of people affected by substance abuse'],
        'Capacity Building of NGOs': ['Helping NGOs to build more professionalism and empowering their staff by teaching them new skills like advanced excel', 'MIS', 'Communication Design', 'Presentation Design', 'Web design and development', 'IT Support', 'Finance & Accounting', 'General Management'],
    }
};