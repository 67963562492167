import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as action from '../../../../../../actions/EEEndorsePaymentAction';
import EndorseMoneyPage from "./EndorseMoneyPage";
import EndorseDetailsPage from "./EndorseDetailsPage";
import configs from '../../../../../../config/urlConfigs';

const readCookie = require('../../../../../../cookie.js').readCookie;

class Endorse extends Component {

	componentDidMount() {
		let urlParams = new URLSearchParams(window.location.search);
		let path = urlParams.get('projectId');

		fetch(configs.BACKEND_API_URL + '/get-project/' + path, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
			.then(function (data) {
				if (data.hasOwnProperty('data')) {
					this.props.actions.updateSelectedProject(data['data']);
					if (!data.data.unitName) {
						this.props.actions.updateEndorseType('custom');
						this.props.actions.updateFinalPayableAmount(500);
					} else {
						this.props.actions.updateEndorseUnitAmount(parseInt(data.data.unitAmount, 10));
						this.props.actions.updateFinalPayableAmount(parseInt(data.data.unitAmount, 10));
					}
					if (!data.data.org.fcraPaymentAllowed) {
						this.props.actions.updateSelectedNationalityCode('101');
						this.props.actions.updateSelectedNationality('India');
						this.props.actions.updateIsd('91')
					}
				} else {
					window.location = "/404";
				}
			}.bind(this));

		fetch(configs.BACKEND_API_URL + '/get-user-data', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			this.props.actions.updateUserData(data.data);
		}.bind(this));

		fetch('https://www.letsendorse.com/getlocation?type=0', {
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			this.props.actions.updateCountry(data);
			this.props.actions.updateSelectedCountryCode('101');
			this.props.actions.updateSelectedCountry('India');
			this.props.actions.updateNationality(data);
			this.props.actions.updateSelectedCountryCode('101');
			this.props.actions.updateSelectedCountry('India');

			fetch('https://www.letsendorse.com/getlocation?type=1&countryId=' + this.props.selectedCountryCode, {
			}).then(function (response) {
				return response.json();
			}).then(function (data) {
				this.props.actions.updateState(data);
			}.bind(this));
		}.bind(this));
	}

	togglePageNum(value) {
		this.props.actions.updatePageNum(value);
	}


	render() {
		if (this.props.selectedProject) {
			return (
				<div className="w100">
					<div className="endorse-sidebar">
						<span className={this.props.pageNum ? 'switch-page-endorse active page1' : 'switch-page-endorse page1'} onClick={this.togglePageNum.bind(this, true)}>1</span>
						<span className={this.props.pageNum ? 'switch-page-endorse page2' : 'active switch-page-endorse page2'} onClick={this.togglePageNum.bind(this, false)}>2</span>
					</div>
					{
						this.props.pageNum ? (
							<EndorseMoneyPage project={this.props.selectedProject}
								userData={this.props.userData}
								paymentType={this.props.paymentType}
								updatePaymentType={this.props.actions.updatePaymentType}
								endorseType={this.props.endorseType}
								updateEndorseType={this.props.actions.updateEndorseType}
								endorseUnit={this.props.endorseUnit}
								updateEndorseUnit={this.props.actions.updateEndorseUnit}
								endorseUnitAmount={this.props.endorseUnitAmount}
								updateEndorseUnitAmount={this.props.actions.updateEndorseUnitAmount}
								endorseAmount={this.props.endorseAmount}
								updateEndorseAmount={this.props.actions.updateEndorseAmount}
								finalPayableAmount={this.props.finalPayableAmount}
								updateFinalPayableAmount={this.props.actions.updateFinalPayableAmount}
								endorseReasonText={this.props.endorseReasonText}
								updateEndorseReasonText={this.props.actions.updateEndorseReasonText}
								anonymous={this.props.anonymous}
								updateAnonymous={this.props.actions.updateAnonymous}
								usePayroll={this.props.usePayroll}
								updateUsePayroll={this.props.actions.updateUsePayroll}
								usePayrollAmount={this.props.usePayrollAmount}
								updateUsePayrollAmount={this.props.actions.updateUsePayrollAmount}
								payCompleteWithPayroll={this.props.payCompleteWithPayroll}
								updatePayCompleteWithPayroll={this.props.actions.updatePayCompleteWithPayroll}
								pageNum={this.props.pageNum}
								updatePageNum={this.props.actions.updatePageNum}
							/>
						) : (
							<EndorseDetailsPage project={this.props.selectedProject}
								paymentType={this.props.paymentType}
								endorseType={this.props.endorseType}
								endorseUnit={this.props.endorseUnit}
								endorseUnitAmount={this.props.endorseUnitAmount}
								endorseAmount={this.props.endorseAmount}
								finalPayableAmount={this.props.finalPayableAmount}
								endorseReasonText={this.props.endorseReasonText}
								pageNum={this.props.pageNum}
								updatePageNum={this.props.actions.updatePageNum}
								userData={this.props.userData}
								firstName={this.props.firstName}
								updateFirstName={this.props.actions.updateFirstName}
								lastName={this.props.lastName}
								updateLastName={this.props.actions.updateLastName}
								email={this.props.email}
								updateEmail={this.props.actions.updateEmail}
								isd={this.props.isd}
								updateIsd={this.props.actions.updateIsd}
								phone={this.props.phone}
								updatePhone={this.props.actions.updatePhone}
								nationality={this.props.nationality}
								updateNationality={this.props.actions.updateNationality}
								selectedNationality={this.props.selectedNationality}
								updateSelectedNationality={this.props.actions.updateSelectedNationality}
								selectedNationalityCode={this.props.selectedNationalityCode}
								updateSelectedNationalityCode={this.props.actions.updateSelectedNationalityCode}
								country={this.props.country}
								updateCountry={this.props.actions.updateCountry}
								selectedCountry={this.props.selectedCountry}
								updateSelectedCountry={this.props.actions.updateSelectedCountry}
								selectedCountryCode={this.props.selectedCountryCode}
								updateSelectedCountryCode={this.props.actions.updateSelectedCountryCode}
								state={this.props.state}
								updateState={this.props.actions.updateState}
								selectedState={this.props.selectedState}
								updateSelectedState={this.props.actions.updateSelectedState}
								selectedStateCode={this.props.selectedStateCode}
								updateSelectedStateCode={this.props.actions.updateSelectedStateCode}
								city={this.props.city}
								updateCity={this.props.actions.updateCity}
								selectedCity={this.props.selectedCity}
								updateSelectedCity={this.props.actions.updateSelectedCity}
								selectedCityCode={this.props.selectedCityCode}
								updateSelectedCityCode={this.props.actions.updateSelectedCityCode}
								zipcode={this.props.zipcode}
								updateZipcode={this.props.actions.updateZipcode}
								address1={this.props.address1}
								updateAddress1={this.props.actions.updateAddress1}
								address2={this.props.address2}
								updateAddress2={this.props.actions.updateAddress2}
								pan={this.props.pan}
								updatePan={this.props.actions.updatePan}
								anonymous={this.props.anonymous}
								updateAnonymous={this.props.actions.updateAnonymous}
								payCompleteWithPayroll={this.props.payCompleteWithPayroll}
								updatePayCompleteWithPayroll={this.props.actions.updatePayCompleteWithPayroll}
								firstNameValid={this.props.firstNameValid}
								updateFirstNameValid={this.props.actions.updateFirstNameValid}
								lastNameValid={this.props.lastNameValid}
								updateLastNameValid={this.props.actions.updateLastNameValid}
								emailValid={this.props.emailValid}
								updateEmailValid={this.props.actions.updateEmailValid}
								phoneValid={this.props.phoneValid}
								updatePhoneValid={this.props.actions.updatePhoneValid}
								zipcodeValid={this.props.zipcodeValid}
								updateZipcodeValid={this.props.actions.updateZipcodeValid}
								address1Valid={this.props.address1Valid}
								updateAddress1Valid={this.props.actions.updateAddress1Valid}
								panValid={this.props.panValid}
								updatePanValid={this.props.actions.updatePanValid}
								nationalityValid={this.props.nationalityValid}
								updateNationalityValid={this.props.actions.updateNationalityValid}
								countryValid={this.props.countryValid}
								updateCountryValid={this.props.actions.updateCountryValid}
								stateValid={this.props.stateValid}
								updateStateValid={this.props.actions.updateStateValid}
								cityValid={this.props.cityValid}
								updateCityValid={this.props.actions.updateCityValid}
								formErrors={this.props.formErrors}
								updateFormErrors={this.props.actions.updateFormErrors}
								formValid={this.props.formValid}
								updateFormValid={this.props.actions.updateFormValid}
							/>
						)
					}
				</div>
			);
		} else {
			return null
		}
	}
}

Endorse.propTypes = {
	selectedProject: PropTypes.object,
	pageNum: PropTypes.bool,
	paymentType: PropTypes.string,
	endorseType: PropTypes.string,
	endorseUnit: PropTypes.number,
	endorseUnitAmount: PropTypes.number,
	endorseAmount: PropTypes.number,
	endorseReasonText: PropTypes.string,
	anonymous: PropTypes.bool,
	userData: PropTypes.object,
	usePayroll: PropTypes.bool,
	usePayrollAmount: PropTypes.number,
	payCompleteWithPayroll: PropTypes.bool,
	finalPayableAmount: PropTypes.number,

	firstName: PropTypes.string,
	lastName: PropTypes.string,
	email: PropTypes.string,
	isd: PropTypes.string,
	phone: PropTypes.string,
	nationality: PropTypes.array,
	selectedNationality: PropTypes.string,
	selectedNationalityCode: PropTypes.string,
	country: PropTypes.array,
	selectedCountry: PropTypes.string,
	selectedCountryCode: PropTypes.string,
	state: PropTypes.array,
	selectedState: PropTypes.string,
	selectedStateCode: PropTypes.string,
	city: PropTypes.array,
	selectedCity: PropTypes.string,
	selectedCityCode: PropTypes.string,
	zipcode: PropTypes.string,
	address1: PropTypes.string,
	address2: PropTypes.string,
	pan: PropTypes.string,

	firstNameValid: PropTypes.bool,
	lastNameValid: PropTypes.bool,
	emailValid: PropTypes.bool,
	phoneValid: PropTypes.bool,
	zipcodeValid: PropTypes.bool,
	address1Valid: PropTypes.bool,
	panValid: PropTypes.bool,
	nationalityValid: PropTypes.bool,
	countryValid: PropTypes.bool,
	stateValid: PropTypes.bool,
	cityValid: PropTypes.bool,
	formErrors: PropTypes.object,
	formValid: PropTypes.bool,
};

function mapStateToProps(state) {
	return {
		selectedProject: state.EEEndorsePayment.selectedProject,
		pageNum: state.EEEndorsePayment.pageNum,
		paymentType: state.EEEndorsePayment.paymentType,
		endorseType: state.EEEndorsePayment.endorseType,
		endorseUnit: state.EEEndorsePayment.endorseUnit,
		endorseUnitAmount: state.EEEndorsePayment.endorseUnitAmount,
		endorseAmount: state.EEEndorsePayment.endorseAmount,
		endorseReasonText: state.EEEndorsePayment.endorseReasonText,
		anonymous: state.EEEndorsePayment.anonymous,
		userData: state.EEEndorsePayment.userData,
		usePayroll: state.EEEndorsePayment.usePayroll,
		usePayrollAmount: state.EEEndorsePayment.usePayrollAmount,
		payCompleteWithPayroll: state.EEEndorsePayment.payCompleteWithPayroll,
		finalPayableAmount: state.EEEndorsePayment.finalPayableAmount,

		firstName: state.EEEndorsePayment.firstName,
		lastName: state.EEEndorsePayment.lastName,
		email: state.EEEndorsePayment.email,
		isd: state.EEEndorsePayment.isd,
		phone: state.EEEndorsePayment.phone,
		nationality: state.EEEndorsePayment.nationality,
		selectedNationality: state.EEEndorsePayment.selectedNationality,
		selectedNationalityCode: state.EEEndorsePayment.selectedNationalityCode,
		country: state.EEEndorsePayment.country,
		selectedCountry: state.EEEndorsePayment.selectedCountry,
		selectedCountryCode: state.EEEndorsePayment.selectedCountryCode,
		state: state.EEEndorsePayment.state,
		selectedState: state.EEEndorsePayment.selectedState,
		selectedStateCode: state.EEEndorsePayment.selectedStateCode,
		city: state.EEEndorsePayment.city,
		selectedCity: state.EEEndorsePayment.selectedCity,
		selectedCityCode: state.EEEndorsePayment.selectedCityCode,
		zipcode: state.EEEndorsePayment.zipcode,
		address1: state.EEEndorsePayment.address1,
		address2: state.EEEndorsePayment.address2,
		pan: state.EEEndorsePayment.pan,

		firstNameValid: state.EEEndorsePayment.firstNameValid,
		lastNameValid: state.EEEndorsePayment.lastNameValid,
		emailValid: state.EEEndorsePayment.emailValid,
		phoneValid: state.EEEndorsePayment.phoneValid,
		zipcodeValid: state.EEEndorsePayment.zipcodeValid,
		address1Valid: state.EEEndorsePayment.address1Valid,
		panValid: state.EEEndorsePayment.panValid,
		nationalityValid: state.EEEndorsePayment.nationalityValid,
		countryValid: state.EEEndorsePayment.countryValid,
		stateValid: state.EEEndorsePayment.stateValid,
		cityValid: state.EEEndorsePayment.cityValid,
		formErrors: state.EEEndorsePayment.formErrors,
		formValid: state.EEEndorsePayment.formValid,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Endorse);