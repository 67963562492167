import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import moment from 'moment';
import swal from 'sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import configs from '../../config/urlConfigs.js';

const readCookie = require('../../cookie.js').readCookie;

export default class ManageSingleVolunteerOpportunity extends Component {
	constructor(props) {
		super(props);
		this.state = {
			volunteerOpportunityId: null,
			volunteerOpportunity: null,
			tableData: null,
			volunteerOpportunitiesToUpdate: {},
			companies: []
		};
		this.createTableData = this.createTableData.bind(this);
	}

	componentWillMount() {
		fetch(configs.BACKEND_API_URL + '/admin/volunteer-opportunity/' + this.props.match.params.opportunityId + '/manage', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			let projectDates = [];
			for(var i = 0; i < data.data.projects.length; i++) {
				projectDates.push(data.data.projects[i].date);
			}
			this.createTableData(data.data, projectDates);
			this.setState({ volunteerOpportunity: data.data, volunteerOpportunityId: this.props.match.params.opportunityId });
		}.bind(this));

		fetch(configs.BACKEND_API_URL + '/admin/getEECompanies', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			this.setState({ companies: data.data });
		}.bind(this));
	}

	createTableData(data, projectDates) {
		let tableData = [];
		if(data.dateType === 'unavailable') {
			for(var i = 0; i < 30; i++) {
				if(data.date.indexOf(moment().add('days', i).format('DD/MM/YYYY')) === -1) {
					var dataObj = {};
					dataObj.date = moment().add('days', i).format('DD/MM/YYYY');
					if(projectDates.indexOf(moment().add('days', i).format('DD/MM/YYYY')) > -1) {
						for(var j = 0; j < data.projects.length; j++) {
							if(data.projects[j].date === moment().add('days', i).format('DD/MM/YYYY')) {
								dataObj.id = data.projects[j].id;
								dataObj.status = data.projects[j].status;
								dataObj.companyId = data.projects[j].companyId;
								j = data.projects.length;
								break;
							}
						}
					} else {
						dataObj.id = '';
						dataObj.status = 'Unassigned';
						dataObj.companyId = '';
					}
					tableData.push(dataObj);
				}
			}
		} else if(data.dateType === 'available') {
			for(var i = 0; i < data.date.length; i++) {
				var dataObj = {};
				dataObj.date = data.date[i];
				if(projectDates.indexOf(data.date[i]) > -1) {
					for(var j = 0; j < data.projects.length; j++) {
						if(data.projects[j].date === data.date[i]) {
							dataObj.id = data.projects[j].id;
							dataObj.status = data.projects[j].status;
							dataObj.companyId = data.projects[j].companyId;
							j = data.projects.length;
							break;
						}
					}
				} else {
					dataObj.id = '';
					dataObj.status = 'Unassigned';
					dataObj.companyId = '';
				}
			}
		}
		this.setState({ tableData });
	}

	assignedToCompany(cell, row) {
		if(row.status === 'Assigned' || row.status === 'Unassigned') {
			return (
				<select className="assigned-to-company-select" value={cell} onChange={this.changeCompany.bind(this, row.date)}>
					<option value="" disabled>Select Company</option>
					{this.state.companies.map(function(company, index) {
						return (
							<option key={index} value={company.id}>{company.name}</option>
						)
					}.bind(this))}
				</select>
			)
		} else {
			let companyName = '';
			for(var i = 0; i < this.state.companies.length; i++) {
				if(this.state.companies[i].id === cell) companyName = this.state.companies[i].name;
			}
			return (
				<div className="assigned-to-company-select text-left">{companyName}</div>
			)
		}
	}

	changeCompany(date, e) {
		let volunteerOpportunitiesToUpdate = this.state.volunteerOpportunitiesToUpdate;
		let tableData = this.state.tableData;
		volunteerOpportunitiesToUpdate[date] = e.target.value;
		for(var i = 0; i < tableData.length; i++) {
			if(tableData[i].date === date) tableData[i].companyId = e.target.value;
		}
		this.setState({ volunteerOpportunitiesToUpdate, tableData });
	}

	updateButton(cell, row) {
		if(row.status === 'Assigned' || row.status === 'Unassigned') {
			return (
				<button className="btn btn-update" onClick={this.updateVO.bind(this, cell)}
					disabled={Object.keys(this.state.volunteerOpportunitiesToUpdate).indexOf(cell) > -1 ? false : true}>
					Update
				</button>
			)
		} else {
			return null;
		}
	}

	updateVO(date) {
		let volunteerOpportunitiesToUpdate = this.state.volunteerOpportunitiesToUpdate;
		if(Object.keys(volunteerOpportunitiesToUpdate).indexOf(date) > -1) {
			var data = {};
			data['date'] = date;
			data['organisationId'] = volunteerOpportunitiesToUpdate[date];
			fetch(configs.BACKEND_API_URL + '/admin/volunteer-opportunity/' + this.state.volunteerOpportunityId + '/assign', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				if(data.status === 'ok') {
					let tableData = this.state.tableData;
					for(var i = 0; i < tableData.length; i++) {
						if(tableData[i].date === date) tableData[i].status = 'Assigned';
					}
					delete volunteerOpportunitiesToUpdate[date];
					swal(
						'Successful!',
						'You have updated the opportunity!',
						'success'
					);
					this.setState({ volunteerOpportunitiesToUpdate, tableData });
				}
			}.bind(this));
		}
	}

	render() {
		if(this.state.volunteerOpportunityId !== null && this.state.volunteerOpportunity !== null) {
			const options = {
	      clearSearch: true,
	      clearSearchBtn: this.createCustomClearButton,
	      sizePerPage: 20,
	      sizePerPageList: [20, 50, 100]
	    };
			return (
				<div className="ee-page-container manage-single-volunteer-opportunity-page">
					<Col md={12} className="manage-single-volunteer-opportunity-container">
						<h3>{this.state.volunteerOpportunity.title}</h3>
						{this.state.tableData !== null ? (
							<BootstrapTable className="manage-single-volunteer-opportunity-table" data={this.state.tableData} options={options} striped={true} hover={true} search searchPlaceholder="Search by Date/Status">
								<TableHeaderColumn dataField="id" isKey={true} dataSort={false} hidden={true} searchable={false}>ID</TableHeaderColumn>
								<TableHeaderColumn dataField="date" width='180px' dataAlign="center" dataSort={false} hidden={false} searchable={true}>Date</TableHeaderColumn>
								<TableHeaderColumn dataField="status" width='180px' dataAlign="center" dataSort={false} hidden={false} searchable={true}>Status</TableHeaderColumn>
								<TableHeaderColumn dataField="companyId" dataSort={false} hidden={false} dataAlign="center" tdStyle={{ padding: 0 }} searchable={true} dataFormat={this.assignedToCompany.bind(this)}>Assigned To Company</TableHeaderColumn>
								<TableHeaderColumn dataField="date" width='140px' dataAlign="center" tdStyle={{ padding: 0 }} dataSort={false} hidden={false} dataFormat={this.updateButton.bind(this)}>Update</TableHeaderColumn>
							</BootstrapTable>
						) : (null)}
					</Col>
				</div>
			);
		} else return null;
	}
}