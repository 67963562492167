import React, { useState,useEffect } from "react";
import { Row, Col, Button, Modal, FormControl, } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from 'sweetalert2';
const readCookie = require('../../../../../cookie').readCookie;
import imageCompression from 'browser-image-compression';
import heic2any from "heic2any";
import configs from "../../../../../config/urlConfigs";
import { serialize } from 'object-to-formdata';


const  SingleOpportunityGalleryUploadModal=({ showModal,
	selectedOpportunity,hideModal,allowUpload, individualLimitExceeded,currentUserUploadsCount,individualLimit,
	attendeeData,fetchImages }) =>  {
  
		
 
  const [compressedImage,setCompressedImage] = useState([]);
  const [displayImages,setDisplayImages]= useState([]);
  const [captions, setCaptions] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [uploading,setUploading] = useState(false);  
  const handleSubmit = async () => {
    
	let files = selectedImages;
	
	var data = new FormData();

	if (files.length > 0) {
		for (let i = 0; i < files.length; i++) {
		if ( /\.(jpe?g|png|heic|heif)$/i.test(files[i].name) === false ) {  
		   toast.error("Only png, heic/heif and jpeg formats are accepted.")
		   return
		  }
		}

		
		for (let i = 0; i < selectedImages.length; i++) {
		  data.append(`images[${i}].image`, selectedImages[i]);
		  data.append(`captions[${i}]`, captions[i] || "");
		  data.append(`images[${i}].compressedImage`, compressedImage[i]);
		}

	   swal({
		   title: 'Are you sure?',
		   showCancelButton: true,
		   confirmButtonText: 'Yes, Upload!',
		   cancelButtonText: 'No, cancel!'
	   }).then(function (result) {
		   if (result) {
			setUploading(true);
			   fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + selectedOpportunity.id + '/add-gallery-multi-images', {
				   method: 'POST',
				   headers: {
					   'Auth': JSON.parse(readCookie('access_token')).access_token
				   },
				   body: data
			   }).then((response) => response.json())
				   .then(function (response) {

					if (response['status'] === 'ok') {
						setUploading(false);
						fetchImages();
						setDisplayImages([]);
						setCompressedImage([]);
						setCaptions([]);
						setSelectedImages([]);
						hideModal();

		   

						

					   } else {
						   swal(
							   'Oops!',
							   'Your file(s) could not be uploaded.',
							   'error'
						   )
					   }
				   }.bind(this));
		   }
	   }.bind(this)).catch(swal.noop);
	   } else {
	   this.notifyError('There were no images selected.')
   }
}

  
const convertImage = (file) => {
	const blobURL = URL.createObjectURL(file);
	fetch(blobURL)
		.then((res) => res.blob())
		.then((blob) => heic2any({ blob }))
		.then(async(conversionResult) => {
			let image = conversionResult;
			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 1920,
				useWebWorker: true
			}
			try {
				const compressedImage = await imageCompression(image, options);
			} catch (error) {
				console.log(error);
			}
		})
		.catch((e) => { 
			console.log(e); this.notifyError('Something went wrong, please try again!');

		});
};



	const handleFileChange = async (e) => {
		let imageSelected = [];
		let compressedImages =[],displayImage =[],uploadImages=[];
		if(((selectedImages.length + e.target.files.length) + currentUserUploadsCount) <= individualLimit){

		for(let i=0;i<e.target.files.length;i++){
			// setSelectedImages({})
			let image = e.target.files[i];
			if ((image.type).includes('hei')) {
			convertImage(image);
			}
	else {			
			const options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 1920,
			useWebWorker: true
		}
		try {
			const compressedImage = await imageCompression(image, options);
			compressedImages.push(compressedImage);
			setCaptions([...captions, ""]);
		} catch (error) {
			console.log(error);
		}

		displayImage.push({ image :URL.createObjectURL(image)});
		uploadImages.push({image});
		imageSelected.push(e.target.files[i])
		
	}
	
}          setSelectedImages((prev) => {
	let temp = prev || [] ;
	temp = [...prev,...imageSelected];
	return temp
});
 
    	if(Number.parseInt(displayImage.length + currentUserUploadsCount) <= individualLimit){
			setCompressedImage((prev) => {
				let temp = prev;
				//temp.push(compressedImage);
		        temp=[...prev,...compressedImage];
				return temp;
			});
			setDisplayImages((prev) => {
				let temp = prev;
				//temp.push(compressedImage);
		        temp=[...prev,...displayImage];
				return temp});
			// setuploadImage(uploadImages )
		}
		else {
			swal(
				'Oops!',
				`You can atmost upload ${individualLimit} image(s)`,
				'error'
			)
			}
	  	}
		else {
			swal(
				'Oops!',
				`You can atmost upload ${individualLimit} image(s)`,
				'error'
			)
		}
	}
	

	  const handleDelete=(i) =>{

		const imageSelected = selectedImages.filter((_, index) => index !== i);
		setSelectedImages(imageSelected);

		let updatedCaptions = captions.filter((_,index) => index !== i);
		setCaptions(updatedCaptions);
	
		const imageDisplay = displayImages.filter((_, index) => index !== i);
		setDisplayImages(imageDisplay);

		let imageCompression = compressedImage.filter((_, index) => index !==i);
		setCompressedImage(imageCompression);
		
	  }

	  const handleCaptionChange =(index,e) => {
		var displayGallery = displayImages
		displayGallery[index].caption = e.target.value;
		const updatedCaptions = [...captions];
		updatedCaptions[index] = e.target.value;
		setCaptions(updatedCaptions);
	  }

	 const  notifyError = (text) => toast.error(text);
	const notifyInfo = (text) => toast.info(text);

    return (
      <Modal   className="declare-modal" show={showModal} onHide={()=> hideModal()} >
        <Modal.Header closeButton>
          <Modal.Title className="modal-header-custom">Upload Image(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
		{/* <ToastContainer position="bottom-right" autoClose={50000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} /> */}
					<input type="file" multiple 
					accept="image/*,.heic,.heif"
					onChange={(e) => handleFileChange(e)} />
					 { displayImages && displayImages.length > 0 ? <Row style={{marginTop:20}}>
										<Col md={3} xs={3}  className="modal-header-custom" >
											Image(s)
										</Col>
										<Col md={4} xs={4} className="modal-header-custom">Captions</Col>
										<Col md={4}  xs={4} className="modal-header-custom" > Actions</Col>
									</Row> :null}
								{
									displayImages && displayImages.length > 0  && displayImages.map( (data,index) => {
									return <>
									<Row style={{ marginTop:10 }} key={`${data.image}-${index}`}>
                                     <Col md={3} xs={3} >
									 <div align='left'> 
									 <img src={data.image} alt='my image' style={{ width: '60%', marginTop: 10, maxWidth:'60%' }} />
									 </div>
									 </Col>
									 <Col md={5} xs={5} align='center' style={{marginTop:20}}>
									  <textarea 
									  class="form-control" 
									  id="Caption" 
									  placeholder='Caption' rows="3" 
									  value={data?.caption}
									  onChange={(e) =>handleCaptionChange(index, e)} />
									  </Col>
									 <Col md={4} xs={4} >
									 <div >
										{
										// !this.state.editMode && 
										<button className='btn btn-primary btn-sm sm' 
										onClick={()=> handleDelete(index)} 
										style={{ marginTop:20}}>Delete</button>}
									</div>
									 </Col>
									</Row>
									</>
								})
							}
        </Modal.Body>
        <Modal.Footer>
		   {  !uploading ?  ( displayImages && displayImages.length  > 0  ? <button className='btn btn-primary' onClick={() => handleSubmit()}> Submit </button> :<button className='btn btn-primary' disabled> Submit </button> ) : 
			<div align='center'><i className="fa fa-spin fa-spinner fa-5x"></i></div>}
            </Modal.Footer>
      </Modal>
    );

}
export default SingleOpportunityGalleryUploadModal;
