const initialState = {
	countOfVos: 0,
	countOfCompletedVos: 0,
	totalHoursClocked: 0,
	countOfProjects: 0,
	totalContributed: 0,
	totalMatchedByCompany: 0,
	totalContributedViaPayroll: 0,
	currentPayrollBalance: 0,
	liveVos: [],
	hasPledged: false
};

const EEFeedReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_COUNT_OF_VOS':
			return Object.assign({}, state, {
				countOfVos: action.countOfVos,
			});
		case 'UPDATE_COUNT_OF_COMPLETED_VOS':
			return Object.assign({}, state, {
				countOfCompletedVos: action.countOfCompletedVos,
			});
		case 'UPDATE_TOTAL_HOURS_CLOCKED':
			return Object.assign({}, state, {
				totalHoursClocked: action.totalHoursClocked,
			});
		case 'UPDATE_COUNT_OF_PROJECTS':
			return Object.assign({}, state, {
				countOfProjects: action.countOfProjects,
			});
		case 'UPDATE_TOTAL_CONTRIBUTED':
			return Object.assign({}, state, {
				totalContributed: action.totalContributed,
			});
		case 'UPDATE_TOTAL_MATCHED_BY_COMPANY':
			return Object.assign({}, state, {
				totalMatchedByCompany: action.totalMatchedByCompany,
			});
		case 'UPDATE_TOTAL_CONTRIBUTED_VIA_PAYROLL':
			return Object.assign({}, state, {
				totalContributedViaPayroll: action.totalContributedViaPayroll,
			});
		case 'UPDATE_CURRENT_PAYROLL_BALANCE':
			return Object.assign({}, state, {
				currentPayrollBalance: action.currentPayrollBalance,
			});
		case 'UPDATE_LIVE_VOS':
			return Object.assign({}, state, {
				liveVos: action.liveVos,
			});
		case 'UPDATE_HAS_PLEDGED':
			return Object.assign({}, state, {
				hasPledged: action.hasPledged,
			});
		default:
			return state
	}
};

export default EEFeedReducer;