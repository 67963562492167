import React, { Component } from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import TimePicker from 'rc-time-picker';
import Switch from 'rc-switch';
import swal from 'sweetalert2';
import moment from 'moment';

import 'sweetalert2/dist/sweetalert2.min.css';
import { Check } from 'react-feather';
import { ToastContainer, toast } from "react-toastify";
import configs from '../../../../../config/urlConfigs.js';
const readCookie = require('../../../../../cookie.js').readCookie;

export default class SingleOpportunityExternalParticipantsEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
                name :props.participantsData.name ||"",
                hoursClocked:props.participantsData.hoursClocked || "",
                relationWithOrganisation:props.participantsData.relationWithOrganisation || "",
                participantEmailId :props.participantsData.participantEmailId || "" ,
                participantPocEmailId:props.participantsData.participantPocEmailId || "",
                feedback:props.participantsData.feedback || "", 
                error: [],         
                startTime: props.participantsData.startTime || "",
                endTime :  props.participantsData.endTime || "",
                mobileNumber :props.participantsData.mobileNumber || "",    
                showLoader:true  
    }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {

    // this.fetchFriendsList()

}


  onChange(name, required, value) {
    let commentRegex = new RegExp(`^[\.a-zA-Z0-9,!?:;@#$~' ]*$`);

    var error = this.state.error;
      var index = error.indexOf(name);
      if (name === 'startTime' || name === 'endTime') {
        if (required && value !== '' && index > -1) error.splice(index, 1);
        this.setState({ [name]: moment(value).format('HH:mm'), error });
      }  else {

      if ((commentRegex.test(value.target.value) === false) && required && index > -1) { 
        error.splice(index, 1)
      }
      else if (name === "feedback" && commentRegex.test(value.target.value) === true || true)
        { 
          this.setState({ [name]: value.target.value, error });  
        }
        else if(name === "name") {
          this.setState({ [name]:value.target.value, error });
        }

        else if (name === "participantEmailId"){ 
            this.setState({ [name]: value.target.value, error });
        }

        else if (name === "participantPocEmailId"){ 
                this.setState({ [name]: value.target.value, error });
              }

        else if (name === "relationWithOrganisation"){ 
            this.setState({ [name]: value.target.value, error });
        }
       else if (name === 'mobileNumber'){
            this.setState({ [name]: value.target.value, error });
        }
      }  
  }


  hours(start_time, end_time) {
    var endTime = moment(end_time, 'h:mm');
    var startTime = moment(start_time, 'h:mm');
    var duration = moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) % 60;
    return hours + ' hour and ' + minutes + ' minutes';
  }

  handleChange(e) {
    this.setState({ relationWithOrganisation: e.target.value });
    this.onChange.bind(this, this.state.relationWithOrganisation, true)
  }


  declare() {
    
    let data = {}
    // data['attendanceValidation'] = this.state.attendanceValidation;
    data['voId'] = this.props.opportunity.id;
    data['name'] = this.state.name;
      data['startTime'] = this.state.startTime;
      data['endTime'] = this.state.endTime;
      data['feedback'] = this.state.feedback;
      data['participantEmailId'] = this.state.participantEmailId;
      data['participantPocEmailId'] = this.state.participantPocEmailId;
      data['relationWithOrganisation'] = this.state.relationWithOrganisation;
      data['city'] = this.props.opportunity.city;
      data['date'] = this.props.opportunity.date;
      data['physicalOpportunity'] = this.props.opportunity.physicalOpportunity;
       data['title'] = this.props.opportunity.title;
       data['mobileNumber'] = this.state.mobileNumber;
      let error = [];
        if (!this.state.startTime === "Invalid date") error.push('startTime');
        if (!this.state.endTime === "Invalid date") error.push('endTime');
              
        if (this.state.startTime && this.state.endTime) {
          let start_time_to_check = moment().hour(this.state.startTime.split(":")[0]).minute
            (this.state.startTime.split(":")[1]).toDate(), end_time_to_check = moment().hour(this.state.endTime.split(":")[0]).minute
              (this.state.endTime.split(":")[1]).toDate();
          if (start_time_to_check > end_time_to_check) {
            error.push('start_time_error')
          }
        }
      this.setState({ error });
      if ((error.length === 0)) {
this.setState({ showLoader :false})
        fetch(
          configs.BACKEND_API_URL + '/ee/external-participants/participated-declared' + '/' + this.props.participantsData._id,
          {
            method: "PUT",
            headers: {
              'Content-Type': 'application/json',
              'Auth': JSON.parse(readCookie('access_token')).access_token
            },
            body: JSON.stringify(data)
          }).then(function (response) {
            return response.json();
          }).then(function (data) {

            if (data.status === 'ok') {
              swal(
                'Awesome!',
                'This participant has been updated.',
                'success'
              ).then(() => {            
                var a = document.createElement('a');
                a.href = data.pdfUrl;
                a.target = '_blank';
                document.body.appendChild(a);
                a.click();
                // window.location.reload(); 
                this.setState({ showLoader :true});
                this.props.getExternalParticipantsList();
                this.props.hideDeclareModal();
              });
            }
          }.bind(this));
      }
    
  }

  render() {
    return (
      <Modal bsSize="lg" className="declare-modal" style={{ width: '100%' }}
        show={this.props.showDeclareModalVisibility} onHide={this.props.hideDeclareModal}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-header-custom">
          Add External Participant
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: 500, overflowY: 'scroll', overflowX: 'hidden' }}>
            <Row>
              <Col md={12}>
                <form id="edit-org-details-modal-form"
                  // ref="vForm"
                >
                  <>
                    <Col md={12}>
                    <Row>
                    <Col md={7}>
                            <label className="control-label is-imp">
                              Name
                            </label>
                          </Col>
                        <Col md={5} sm={5} >
                          <div className="form-group pb0 mb0" >
                            <input type="text" className="form-control" 
                              style={{ margin: 0 }}  
                              value={this.state.name} 
                              onChange={this.onChange.bind(this, "name", true)}
                            />
                          </div>
                        </Col>
                        </Row>
                        <Row>
                        <Col md={7}>
                            <label className="control-label ">
                              Email (Optional)
                            </label>
                          </Col>
                        <Col md={5} sm={5} >
                          <div className="form-group pb0 mb0" >
                            <input type="text" className="form-control" 
                              style={{ margin: 0 }}  
                              value={this.state.participantEmailId} 
                              onChange={this.onChange.bind(this, "participantEmailId", false)}
                            />
                          </div>
                        </Col>
                        </Row>
                        <Row>
                        <Col md={7}>
                            <label className="control-label ">
                            Mobile Number (Optional)
                            </label>
                          </Col>
                        <Col md={5} sm={5} >
                          <div className="form-group pb0 mb0" >
                            <input 
                              type="number" className="form-control" 
                              style={{ margin: 0 }}  
                              value={this.state.mobileNumber} 
                              onChange={this.onChange.bind(this, "mobileNumber", false)}
                            />
                          </div>
                        </Col>
                        </Row>
                        <Row>
                        <Col md={7}>
                            <label className="control-label is-imp">
                            Email ID of the POC from company 
                            </label>
                          </Col>
                        <Col md={5} sm={5} >
                          <div className="form-group pb0 mb0" >
                            <input type="text" className="form-control" 
                              style={{ margin: 0 }}  
                              value={this.state.participantPocEmailId} 
                              onChange={this.onChange.bind(this, "participantPocEmailId", false)}
                            />
                          </div>
                        </Col>
                        </Row>
                        <Row>
                      <Col className="form-group" md={3} >
                        <label className="control-label is-imp">Start Time: </label>

                        <TimePicker defaultValue=
                          {this.state.startTime ? moment().hour(this.state.startTime.split(":")[0]).minute
                            (this.state.startTime.split(":")[1]) : ""}
                          className="form-control" showSecond={false}
                          onChange={this.onChange.bind(this, "startTime", true)} 
                          />
                        {this.state.error.indexOf('start_time')
                          > -1 ? (
                          <span className="red-color">Incorrect Time!</span>
                        ) : (null)}
                        {this.state.error.indexOf('startTime_error') > -1 ? (<span className="red-color">Incorrect Time!</span>) : (null)}
                      </Col>

                      <Col className="form-group" md={3}>
                        <label className="control-label is-imp">End Time: </label>

                        <TimePicker
                          defaultValue={this.state.endTime ? moment().hour(this.state.endTime.split(":")[0]).minute
                            (this.state.endTime.split(":")[1]) : ""}
                          className="form-control" showSecond={false} 
                          onChange={this.onChange.bind(this, "endTime", true)} 
                          />
                        {this.state.error.indexOf('endTime') > -1 ? (
                          <span className="red-color">Incorrect Time!</span>
                        ) : (null)}
                        {this.state.error.indexOf('end_time_error') > -1 ? (<span className="red-color">End time can't be smaller than start Time</span>) : (null)}
                      </Col>
                      </Row>
                    </Col>
 
                    <Col md={12}>
                      <Col className="form-group" md={12}>
                        <Row>
                          <Col md={7}>
                            <label className="control-label is-imp">
                              Relationship With the organisation
                            </label>
                          </Col>
                          
                          <Col md={3} className="selected-option">
                            <select id="type" defaultValue={this.state.relationWithOrganisation} onChange={this.handleChange}>
                              <option value="">Choose one</option>
                              <option value="Client">Client </option>
                              <option value="Investor">Investor</option>
                              <option value="Influencer">Influencer</option>
                              <option value="Other">Other</option>
                            </select>
                            {this.state.error.indexOf('relationWithOrganisation') > -1 ? (<span className="red-color">Please mention relation with organisation!</span>) : (null)}
                          </Col>
                        </Row>
                      </Col>
                    </Col>


                    <Col className="form-group" md={12}>
                      <label className="control-label  declare-modal-question">
                        Feedback 
                        <br /></label>
                      <textarea placeholder="You may want to highlight your favorite parts of the volunteering experience, the difference you created, what could be improved, etc."
                        className="form-control" id="experience" value={this.state.feedback} onChange={this.onChange.bind(this, "feedback", false)} ></textarea>
                    </Col>
                    </>
                </form>
              </Col>
            </Row>  
          </div>
        </Modal.Body>
        <Modal.Footer className="footer-center">
          {/* { ((this.props.participantsData.name !== this.state.name ))
          || (this.props.participantsData.relationWithOrganisation !== this.state.relationWithOrganisation )
          || (this.props.participantsData.participantEmailId !== this.state.participantEmailId) 
          || (this.props.participantsData.feedback !== this.state.feedback ) ||
          (this.state.participantPocEmailId !== this.props.participantsData.participantPocEmailId) 
          || (this.props.participantsData.startTime !== this.state.startTime ) 
         || (this.props.participantsData.endTime !== this.state.endTime) || 
         (this.props.participantsData.mobileNumber !== this.state.mobileNumber)
          ? */}
          {
            this.state.showLoader ? <Button className="btn btn-primary" onClick={this.declare.bind(this)}>
            Submit and generate certificate
             </Button>  : <i className="fa fa-spin fa-spinner fa-3x"></i> 
          }
           
          {/* //  :
          //  <Button className="btn btn-outline-primary"  style={{border:'none' ,cursor :"not-allowed",backgroundColor:"grey",color:"white" }} >
          //   Submit and generate certificate
          //  </Button>   */}
        {/* } */}
          <Button className="btn btn-outline-primary" onClick={this.props.hideDeclareModal.bind(this)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
