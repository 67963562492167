import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import * as action from "../../../../actions/EEAdminOpportunityRecommendationsAction";
import RecommendedOpportunityModal from "./RecommendedOpportunityModal";
import RecommendAnOpportunitySwitch from "../RecommendAnOpportunity/RecommentAnOpportunity";
import Pagination from "./Pagination";
import configs from "../../../../config/urlConfigs";

const readCookie = require("../../../../cookie").readCookie;

class RecommendedOpportunities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRecommendedOpportunityModal: false,
      showRecommendOpportunityModal: false,
      opportunitiesLength: "",
      search: "",
      statusFilter: "",
      filterTerm: "",
      rowsPerPage: 5,
      currentPage: 1,
    };

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal1 = this.closeModal1.bind(this);
    this.openModal1 = this.openModal1.bind(this);
  }

  closeModal() {
    this.setState({ showRecommendedOpportunityModal: false });
  }

  openModal(e) {
    let opportunity = e.currentTarget.getAttribute("data-opp");
    this.props.actions.updateSelectedOpportunity(JSON.parse(opportunity));
    this.setState({
      showRecommendedOpportunityModal: true,
      opportunity,
    });
  }

  closeModal1() {
    this.setState({ showRecommendOpportunityModal: false });
  }

  openModal1(e) {
    this.setState({
      showRecommendOpportunityModal: true,
    });
  }

  handleSearch = (e) => {
    this.setState({
      filterTerm: e.target.value,
      search: e.target.value,
    });
  };

  handleStatusFilter = (e) => {
    this.setState({
      filterTerm: e.target.value,
      statusFilter: e.target.value,
    });
  };

  // Change page
  paginate = (pageNumber) => {
    this.setState({
      currentPage: pageNumber,
    });
  };

  pageSize = (Number) => {
    this.setState({
      rowsPerPage: Number,
    });
  };

  opportunitiesLength = (Number) => {
    this.setState({
      opportunitiesLength: Number,
    });
  };

  componentDidMount() {
    fetch(
      configs.BACKEND_API_URL +
        "/volunteer-opportunity/check-recommendations",
      {
        method: "GET",
        headers: {
          Auth: JSON.parse(readCookie("access_token")).access_token,
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {
          this.props.actions.updateOpportunities(data.data.reverse());
        }.bind(this)
      );
  }


  render() {
    const indexOfLastRow = this.state.currentPage * this.state.rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - this.state.rowsPerPage;
    const currentRows = this.props.opportunities.slice(
      indexOfFirstRow,
      indexOfLastRow
    );
    return (
      <div className="ee-page-container">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingTop: 10 }}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.openModal1}
          >
            Recommend An Opportunity
          </button>
        </div>
        {!this.props.opportunities.length ?
          <>
            {/* <div className="ee-page-container"> */}
            <Row>
              <Col md={12} className="text-center">
                <p className="no-data-text">There are no recommendations!</p>
              </Col>
            </Row>
            {/* </div> */}
          </>
          :
          <> <h1 className="ee-page-title">Your Recommendations</h1>
            {/* <Col md={3} className="form-group mb0">
            <label className="control-label">Search:</label>
            <input
              type="search"
              className="form-control inline"
              value={this.state.search}
              onChange={this.handleSearch}
            />
          </Col>
          <Col md={3} className="form-group mb0">
            <label className="control-label">Status:</label>
            <div className="flex-center">
              <select
                className="form-control"
                id="opportunity-filter-status-select"
                value={this.state.statusFilter}
                onChange={this.handleStatusFilter}
              >
                <option value="Yet to take action">Yet to take action</option>
                <option value="Under Review">Under Review</option>
                <option value="Declined by the recommended partner">
                  Declined by the recommended partner
                </option>
                <option value="Declined by the company">
                  Declined by the company
                </option>
                <option value="Successfully onboarded">
                  Successfully onboarded
                </option>
              </select>
            </div>
          </Col>  */}
            <Row>
              {currentRows
                .filter(
                  (opportunity) =>
                    opportunity.recommendedBy.id ===
                    JSON.parse(readCookie("userData")).id
                )
                .filter((opportunity) => {
                  if (this.state.filterTerm === "") {
                    return opportunity;
                  } else if (
                    Object.values(opportunity)
                      .toString()
                      .includes(this.state.filterTerm.toLowerCase())
                  ) {
                    return opportunity;
                  } else if (
                    opportunity.recommendedBy.fullName
                      .toLowerCase()
                      .includes(this.state.filterTerm.toLowerCase())
                  ) {
                    return opportunity;
                  } else if (opportunity.status === this.state.filterTerm) {
                    return opportunity;
                  }
                })
                .map(
                  function (opportunity, i) {
                    return (
                      <Col md={12} key={i}>
                        <div
                          className='right-txt-wrp'
                          style={{ marginTop: 15, borderRadius: 5 }}
                          // onClick={this.openModal}
                          data-opp={JSON.stringify(opportunity)}
                        >
                          <span className="submitted">
                            Date of recommendation:{" "}
                            {moment(opportunity.submittedOn).format("DD MMMM YYYY")}
                          </span>
                          <br />
                          <span
                            className="submitted">
                            Email of contact person: {opportunity.emailOfPOC}
                          </span>
                          <br />
                          <span className="submitted">
                            Phone of contact person: {opportunity.phoneOfPOC}
                          </span>
                          <br />
                          <span className="submitted">
                            Status: {opportunity.status ? opportunity.status : 'Yet to take action'}
                          </span>
                          <br />
                          <br />
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.openModal}
                            data-opp={JSON.stringify(opportunity)}
                          >
                            View More
                          </button>
                        </div>
                        {/* {JSON.stringify(opportunity, 4, null)} */}
                      </Col>
                    );
                  }.bind(this)
                )}
            </Row>

            {/* <Pagination
            rowsPerPage={this.state.rowsPerPage}
            totalRows={this.props.opportunities.length}
            paginate={this.paginate}
            pageSize={this.pageSize}
            url="/recommend-an-opportunity"
          /> */}

          </>}

        <RecommendedOpportunityModal
          showRecommendedOpportunityModal={
            this.state.showRecommendedOpportunityModal
          }
          selectedOpportunity={this.props.selectedOpportunity}
          closeModal={this.closeModal}
          openModal={this.openModal}
        />

        <RecommendAnOpportunitySwitch
          showRecommendOpportunityModal={
            this.state.showRecommendOpportunityModal
          }
          closeModal1={this.closeModal1}
          openModal1={this.openModal1}
          opportunitiesLength={this.opportunitiesLength}
        />
      </div>
    );
    // else {
    //   return (
    //     <div className="ee-page-container">
    //       <Row>
    //         <Col md={12} className="text-center">
    //           <p className="no-data-text">There are no recommendations!</p>
    //         </Col>
    //       </Row>
    //     </div>
    //   );
    // }
  }
}

RecommendedOpportunities.propTypes = {
  opportunities: PropTypes.array,
  selectedOpportunity: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    opportunities: state.EEAdminOpportunityRecommendations.opportunities,
    selectedOpportunity:
      state.EEAdminOpportunityRecommendations.selectedOpportunity,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(action, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecommendedOpportunities);
