import React, { useState,useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./cropimage.css";
import SvgIcon from "react-icons-kit";
import {check} from 'react-icons-kit/fa/check'
import { ToastContainer, toast } from "react-toastify";
import { createCookie, readCookie } from '../../../../../cookie';
// import { createCookie, readCookie } from '../../../../cookie';
import imageCompression from 'browser-image-compression';
import heic2any from "heic2any";
import configs from "../../../../../config/urlConfigs";

export const CropImage = ({imageData, showCheckboxProfilePicture}) => {
  const [image, setImage] = useState(null);
  const [cropData, setCropData] = useState("");
  const [cropper, setCropper] = useState();
  const [ showCheckbox ,setShowCheckbox] = useState(false);
  const [profilePicture , setProfilePicture ] = useState(false);
  const notifyError = (text) => toast.error(text);


  useEffect(()=>{
    const userData = JSON.parse(readCookie('userData'));
    if (userData.profilePicture ) { 
      setProfilePicture(userData.profilePicture) 
    };
  },[])
 
  const convertImage = (file) => {
    const blobURL = URL.createObjectURL(file);
    fetch(blobURL)
      .then((res) => res.blob())
      .then((blob) => heic2any({ blob }))
      .then(async (conversionResult) => {
        let imageConv = conversionResult;
        
        try {
          const reader = new FileReader();
          reader.onload = () => { setImage(reader.result); };
          reader.readAsDataURL(imageConv);
          setShowCheckbox(true);
        } catch (error) {
          console.log(error);
        }
      })
      .catch((e) => {
        console.log(e); this.notifyError('Something went wrong, please try again!');

      });
  };

const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
     }
  if ((files[0].type).includes('hei')) {
    convertImage(files[0]);
  } else {
      const reader = new FileReader();
      reader.onload = () => { setImage(reader.result); };
      if (/\.(jpe?g|png|heif|heic)$/i.test(files[0].name) === false) {
        notifyError("Only png, heic/heif and jpeg formats are accepted.")
        setShowCheckbox(false);
      }
      else {
          
  reader.readAsDataURL(files[0]);
  setShowCheckbox(true);
          }
    
  }
      };

  const getCropData = (e) => {
    try {
      if (typeof cropper !== "undefined") {
        e.preventDefault();
        if (cropper.getCroppedCanvas()) {
          let image =cropper.getCroppedCanvas().toDataURL()
        setCropData(image);
       const convertedImage = dataURItoBlob(image)
      imageData(convertedImage);
  showCheckboxProfilePicture(showCheckbox);
        }
        else {
          notifyError("Unsupported file format detected!"); e.target.value = null;
        }
      }
    }
    catch (err) {
      console.log('Error', err)
    }
  };
  function dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
     var array = [];
    for(var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
    }

     return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
    }

  return (
    <>
    <div>
  <label for="files" className ="btn btn-primary" style={{background:configs.THEME_COLOR}}>{ profilePicture ? "Update your profile picture" : "Add your profile picture"}</label>
  <input id= 'files' type="file" style={{display:'none'}}  accept="image/x-png,image/gif,image/jpeg, .heic, .heif" onChange={onChange} checked />
</div>
      
        <div className="main-image-cropper-container crop-image-conatiner">
           <Cropper
          //  zoomTo={0.5}
          initialAspectRatio={1 / 1}
          preview=".img-preview"
          src={image}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false} 
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides={true}
        />
        <p className="checklist-for-image-crop">
         {showCheckbox && 
           <button type="button" class="btn btn-primary"  onClick={getCropData}><i className="fa -fa-check"></i>Click to preview image</button>
           }
         </p>
         
          
            <div className="image-conatiner-preview">
         
             {cropData&&   <img src={cropData} alt="cropped"  className="croppedImage"/> }     
           </div>
           <ToastContainer
                position="bottom-center"
                autoClose={4000}
                hideProgressBar={true}
                closeOnClick={false}
                newestOnTop={false}
                pauseOnHover={true}
              />
      </div>
    
      </>
    
  );
};

export default CropImage;

