import React from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { Check, Trash2 } from 'react-feather';
import { skills } from '../../../../constants/skills';
import RenderArray from './RenderArray';
import RenderObject from './RenderObject';

const availableLanguages = ['Assamese', 'Bengali', 'Bhojpuri', 'English', 'Gujarati', 'Haryanvi', 'Hindi', 'Kannada', 'Kashmiri', 'Malayalam', 'Marathi', 'Marwari', 'Odia', 'Punjabi', 'Tamil', 'Telugu', 'Urdu'];

const Step2Skills = ({ selectedSkills, handleSelectSkills, subdomain, handleLangugage, languages, addLanguage, removeLang }) => {
    return (
        <div>
            {subdomain === 'indusind'
                ? <RenderArray
                    dataArray={skills.indusind}
                    handleSelect={handleSelectSkills}
                    selected={selectedSkills}
                />
                : <RenderObject
                    dataObject={skills.le}
                    handleSelect={handleSelectSkills}
                    selected={selectedSkills}
                />}
            {subdomain === 'indusind' && selectedSkills.includes('Public Speaking (Specify Languages)') && <>
                <h4 className='mt10 mb10'>Languages Known</h4>
                {languages.map((lang, index) => <Row>
                    <Col md={3} className="form-group mb0">
                        <select className="form-control" id="opportunity-filter-type-select" defaultValue="" value={lang.language} onChange={e => handleLangugage(index, 'language', e.target.value)}>
                            <option value="" disabled>Select</option>
                            {availableLanguages.map(item => <option value={item} disabled={languages.map(l => l.language).includes(item)}>{item}</option>)}
                        </select>
                    </Col>
                    <Col md={2}>
                        <div class="form-group form-check skills-option" onClick={() => handleLangugage(index, 'speak', !lang.speak)}>
                            <a className={lang.speak ? 'checkbox-active' : 'checkbox-inactive'} style={{ paddingLeft: 2, paddingRight: 2 }} >
                                <Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
                            </a>
                            <label class="form-check-label skills-option-label" for="exampleCheck1">Speak</label>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div class="form-group form-check skills-option" onClick={() => handleLangugage(index, 'read', !lang.read)}>
                            <a className={lang.read ? 'checkbox-active' : 'checkbox-inactive'} style={{ paddingLeft: 2, paddingRight: 2 }} >
                                <Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
                            </a>
                            <label class="form-check-label skills-option-label" for="exampleCheck1">Read</label>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div class="form-group form-check skills-option" onClick={() => handleLangugage(index, 'write', !lang.write)}>
                            <a className={lang.write ? 'checkbox-active' : 'checkbox-inactive'} style={{ paddingLeft: 2, paddingRight: 2 }} >
                                <Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
                            </a>
                            <label class="form-check-label skills-option-label" for="exampleCheck1">Write</label>
                        </div>
                    </Col>
                    <Col md={1}>
                        <Button className='btn btn-primary btn-sm' onClick={() => removeLang(index)}>remove</Button>
                    </Col>
                </Row>)}
                <Row>
                    <Col md={11} >
                        <div align='right'>
                            <Button className='btn btn-primary btn-sm' onClick={addLanguage}>+ Add</Button>
                        </div>
                    </Col>
                </Row>
            </>}
        </div>
    )
}

export default Step2Skills