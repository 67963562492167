import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import EndorsementProjectsList from './EndorsementProjectsList';
import EndorsementProjectsFilter from './EndorsementProjectsFilter';

import * as action from '../../../../actions/EEEndorseProjectsAction';
import configs from '../../../../config/urlConfigs';

const readCookie = require('../../../../cookie.js').readCookie;

class EndorsementProjects extends Component {
	componentDidMount() {
		fetch(configs.BACKEND_API_URL + '/projects/ee-discover', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			this.props.actions.updateProjects(data.data)
		}.bind(this));
	}

	render () {
		return (
			<div className="ee-page-container">
				<Row>
					<Col md={12} className="text-center">
						<h2 className="ee-page-title">Endorse Projects</h2>
					</Col>
				</Row>
				{this.props.projects !== null ? (
					<div>
						<EndorsementProjectsFilter causes={this.props.causes} updateProjects={this.props.actions.updateProjects}
						  updateLocation={this.props.actions.updateLocation} updateCauses={this.props.actions.updateCauses} />
						<EndorsementProjectsList projects={this.props.projects} />
					</div>
				) : (null)}
			</div>
		);
	}
}


EndorsementProjects.propTypes = {
	projects: PropTypes.array,
	causes: PropTypes.array,
};

function mapStateToProps(state) {
	return {
		projects: state.EEEndorseProject.projects,
		causes: state.EEEndorseProject.causes
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EndorsementProjects);