import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import TimePicker from 'rc-time-picker';
import Switch from 'rc-switch';
import swal from 'sweetalert2';
import moment from 'moment';

import 'sweetalert2/dist/sweetalert2.min.css';
import configs from '../../../../../config/urlConfigs.js';

const readCookie = require('../../../../../cookie.js').readCookie;

export default class EmployeeTimeModal extends Component {
  constructor(props) {
    super(props);
    // const { participants } = props;
    
   
    this.state = {
      AttendanceValidation: false,
           start_time:null,
      end_time: null,
      error: [],
      participants: props.participants,
      // attendeesData: null,
    }

    // this.gettingData = this.gettingData.bind(this);
  }
  

  
  
  
  
  
  
  
  onChange(name, required, value) {
    var error = this.state.error;
     
      if (name === 'start_time' || name === 'end_time') {
        if (required && !["", "N/A"].includes(value));
        this.setState({ [name]: moment(value).format('HH:mm'), error });
      } else {
        if (required && value.target.value !== '');
        this.setState({ [name]: value.target.value, error });
      }
    
  }
componentWillMount(){
  let data=this.state.participants.participantsData
let attendeesData = data.filter(data=> data.id === JSON.parse(readCookie('userData')).id)
this.setState({start_time:attendeesData[0]["Start Time"],end_time:attendeesData[0]["End Time"]})
}
hours(start_time, end_time) {
    var endTime = moment(end_time, 'hh:mm');
    var startTime = moment(start_time, 'hh:mm');
    var duration = moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) % 60;
    return hours + ":" + minutes;
  }

  declare() {
    let data = {};
    data['voId'] = JSON.parse(readCookie("userData")).id;
      
    data['Start Time'] = ["", "N/A"].includes(this.state.start_time) ? "" : this.state.start_time;
      data['End Time'] = ["", "N/A"].includes(this.state.end_time) ? "" : this.state.end_time;
    
let attendeesData = this.state.participants.participantsData.filter((data)=>data.id === JSON.parse(readCookie('userData')).id) 
   
    Object.keys(attendeesData[0]).forEach((key)=>{
      attendeesData[0]['Start Time'] = this.state.start_time;
      attendeesData[0]['End Time'] = this.state.end_time;
        attendeesData[0]['Hours Clocked'] = this.hours(this.state.start_time, this.state.end_time)
})
    
    this.props.setParticipants(attendeesData)



if (!this.state.AttendanceValidation &&
     (!["", "N/A"].includes(this.state.start_time) 
    && !["", "N/A"].includes(this.state.end_time))) {
    
      
      fetch(configs.BACKEND_API_URL + 
        '/ee/volunteer-opportunity/update-approval/'
       + this.props.selectedOppurtunityId, {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
          'Auth': JSON.parse(readCookie('access_token')).access_token
        },
        body: JSON.stringify({ tableData: attendeesData }),

      }
     
      ).then(function (response) {
        return response.json();
      }).then(function (data) {

      })
      if(!this.state.AttendanceValidation){
        swal('Awesome!', 'You have changed this' + "  "+this.hours(this.state.start_time, this.state.end_time) + ' Time' )
        this.props.setParticipants(this.state.participants);
        this.props.hideDeclareModal();
      
      }
      
    
    }
   
    var error = [];
    if (this.state.toggleValue === true || this.state.toggleValue === false) {
      if (["", "N/A"].includes(this.state.start_time)) error.push('start_time');
      if (["", "N/A"].includes(this.state.end_time)) error.push('end_time');
      if (!["", "N/A"].includes(this.state.start_time) && !["", "N/A"].includes(this.state.end_time)) {
        const startTimeArray = this.state.start_time.split(':');
        const endTimeArray = this.state.end_time.split(':');

        if (moment().hour(startTimeArray[0]).minute(startTimeArray[1]) >= moment().hour(endTimeArray[0]).minute(endTimeArray[1])) { error.push('start_time_error') }

        else if
          (moment().hour(endTimeArray[0]).minute(endTimeArray[1]) >= moment().hour(startTimeArray[0]).minute(startTimeArray[1])) { error.push('end_time_error') }
      }

    }
    this.setState({ error });
    if (error) return;

  };

  render() {
    return (
      <Modal bsSize="large" className="declare-modal" show={this.props.showTimeModalVisibility} onHide={this.props.hideDeclareModal}>
      
      <Modal.Header closeButton>
          <Modal.Title className="modal-header-custom">
            Change Time
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="margin-col-50" md={12}>
              <form id="edit-org-details-modal-form"
                // ref="vForm"
              >

                {!this.state.AttendanceValidation ? (
                  <Col md={12}>
                    <Col className="form-group" md={3} mdOffset={3}>
                      <label className="control-label is-imp">Start Time: </label>
                      <TimePicker 
                      defaultValue={( this.state.start_time !== "N/A" )
                       ? (moment().hour(this.state.start_time.split(":")[0]).
                       minute(this.state.start_time.split(":")[1])):(null)} 
                      className="form-control" showSecond={false} 
                      onChange={this.onChange.bind(this, "start_time", true)} 

                      />
                      {this.state.error.indexOf('start_time') > -1 ? (
                        <span className="red-color">Incorrect Time!</span>
                      ) : (null)}
                   
                    </Col>
                    <Col className="form-group" md={3}>
                      <label className="control-label is-imp">End Time: </label>
                      <TimePicker 
                      defaultValue= { (this.state.end_time !== "N/A")
                       ? (moment().hour(this.state.end_time.split(":")[0]).
                       minute(this.state.end_time.split(":")[1])):(null)} 
                      className="form-control" showSecond={false} 
                      onChange={this.onChange.bind(this, "end_time", true)} 

                      />
                      {this.state.error.indexOf('end_time') > -1 ? (
                        <span className="red-color">Incorrect Time!</span>
                      ) : (null)}
                     
                    </Col></Col>
                ) : (null)}
              </form>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="footer-center">
        <Button className="btn btn-success" onClick={this.declare.bind(this)}>Submit </Button>

        </Modal.Footer>
      </Modal>
    )
  }
}