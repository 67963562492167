import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import SwitchButton from 'lyef-switch-button';

import 'lyef-switch-button/css/main.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import configs from '../../config/urlConfigs.js';

const readCookie = require('../../cookie.js').readCookie;

export default class ManageCorporate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			corporateUsers: []
		};
	}

	componentWillMount() {
		fetch(configs.BACKEND_API_URL + '/admin/getCorporateUsers', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			this.setState({ corporateUsers: data.data });
		}.bind(this));
	}

  createCustomClearButton(onClick) {
    return (
      <span onClick={onClick}>
        <i className="clear-table-search glyphicon glyphicon-remove"></i>
      </span>
    );
  }

  userVerificationFormatter(value, row) {
  	if(value) {
  		return <span className="verified-corporate">Verified</span>;
  	} else {
  		return (
  			<div>
  				<button className="approve-reject-corporate approve-corporate" onClick={this.approveCorporate.bind(this, row.id)}>Approve</button>
  				<button className="approve-reject-corporate reject-corporate" onClick={this.rejectCorporate.bind(this, row.id)}>Reject</button>
  			</div>
  		);
  	}
  }

  organisationDataFormatter(value, row) {
  	if(value.hasOwnProperty('name')) {
  		return value.name;
  	} else {
  		return 'N/A';
  	}
  }

  approveCorporate(id) {
  	fetch(configs.BACKEND_API_URL + '/admin/approveUser', {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify({ user: id})
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			if(data.status === 'ok') {
				fetch(configs.BACKEND_API_URL + '/admin/getCorporateUsers', {
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then((data) => data.json())
				.then(function(data) {
					this.setState({ corporateUsers: data.data });
				}.bind(this));
			}
		}.bind(this));
  }

  rejectCorporate(id) {
  	fetch(configs.BACKEND_API_URL + '/admin/rejectUser', {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify({ user: id})
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			if(data.status === 'ok') {
				fetch(configs.BACKEND_API_URL + '/admin/getCorporateUsers', {
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then((data) => data.json())
				.then(function(data) {
					this.setState({ corporateUsers: data.data });
				}.bind(this));
			}
		}.bind(this));
  }

  csrAuthoriztion(cell, row) {
  	return (
  		<SwitchButton id={"admin-auth-switch-csr-" + row.id} labelRight="Yes" labelLeft="No" isChecked={row.authorizedFor.hasOwnProperty('marketplace')}
				action={this.changeCSRAuthorization.bind(this, row)} />
  	)
  }

  misAuthoriztion(cell, row) {
  	return (
  		<SwitchButton id={"admin-auth-switch-mis-" + row.id} labelRight="Yes" labelLeft="No" isChecked={row.authorizedFor.hasOwnProperty('mis')}
				action={this.changeMISAuthorization.bind(this, row)} />
  	)
  }

  changeCSRAuthorization(admin) {
  	let authorizedFor = [];
  	if(!admin.authorizedFor.hasOwnProperty('marketplace')) authorizedFor.push('marketplace');
  	if(admin.authorizedFor.hasOwnProperty('mis')) authorizedFor.push('mis');

  	let data = {};
  	data['authorizedFor'] = authorizedFor;

		fetch(configs.BACKEND_API_URL + '/admin/user/' + admin.id, {
			method: "PUT",
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify(data)
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			if(data['status'] === 'ok') {
				fetch(configs.BACKEND_API_URL + '/admin/getCorporateUsers', {
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then((data) => data.json())
				.then(function(data) {
					this.setState({ corporateUsers: data.data });
				}.bind(this));
			}
		}.bind(this));
  }

  changeMISAuthorization(admin) {
  	let authorizedFor = [];
  	if(!admin.authorizedFor.hasOwnProperty('mis')) authorizedFor.push('mis');
  	if(admin.authorizedFor.hasOwnProperty('marketplace')) authorizedFor.push('marketplace');

  	let data = {};
  	data['authorizedFor'] = authorizedFor;

  	fetch(configs.BACKEND_API_URL + '/admin/user/' + admin.id, {
			method: "PUT",
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify(data)
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			if(data['status'] === 'ok') {
				fetch(configs.BACKEND_API_URL + '/admin/getCorporateUsers', {
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then((data) => data.json())
				.then(function(data) {
					this.setState({ corporateUsers: data.data });
				}.bind(this));
			}
		}.bind(this));
  }

	render() {
		if(this.state.corporateUsers.length) {
			const options = {
	      clearSearch: true,
	      clearSearchBtn: this.createCustomClearButton,
	      sizePerPage: 20,
	      sizePerPageList: [20, 50, 100]
	    };
			return (
				<div className="ee-page-container manage-corporate-page">
					<Col md={12} className="manage-corporate-container">
						<h3>Manage Corporate</h3>
						<BootstrapTable className="corporate-users-table" data={this.state.corporateUsers} options={options} striped={true} hover={true} search searchPlaceholder="Search by Name/Email/Designation/Verification Status/Organisation">
							<TableHeaderColumn dataField="id" isKey={true} dataSort={false} hidden={true}  searchable={false}>ID</TableHeaderColumn>
		          <TableHeaderColumn dataField="fullName" dataSort={true} hidden={false} searchable={true}>Name</TableHeaderColumn>
		          <TableHeaderColumn dataField="email" dataSort={true} hidden={false} searchable={true}>Email</TableHeaderColumn>
		          <TableHeaderColumn dataField="mobileNo" dataSort={true} hidden={false} searchable={false}>Mobile</TableHeaderColumn>
		          <TableHeaderColumn dataField="designationAtOrganisation" dataSort={true} hidden={false} searchable={true}>Designation</TableHeaderColumn>
		          <TableHeaderColumn dataField="isUserVerified" dataSort={true} hidden={false} searchable={true} dataFormat={this.userVerificationFormatter.bind(this)}>User Verification</TableHeaderColumn>
		          <TableHeaderColumn dataField="organisationData"	dataSort={true} hidden={false} searchable={true} dataFormat={this.organisationDataFormatter}>Organisation Name</TableHeaderColumn>
		          <TableHeaderColumn dataField="authorizedFor" width='150px' dataSort={false} hidden={false} searchable={false} dataFormat={this.csrAuthoriztion.bind(this)}>CSR Authorization</TableHeaderColumn>
		          <TableHeaderColumn dataField="authorizedFor" width='150px'	dataSort={false} hidden={false} searchable={false} dataFormat={this.misAuthoriztion.bind(this)}>MIS Authorization</TableHeaderColumn>
		        </BootstrapTable>
					</Col>
				</div>
			);
		} else {
			return null;
		}
	}
}