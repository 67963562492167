// const subdomain = window.location.hostname.split('.')[0];
const subdomain = window.location.hostname.includes('indusind') ? 'indusind' : 'le';

const configs = {
    // SUBDOMAIN: subdomain === 'indusind' ? process.env.REACT_APP_IBL_SUBDOMAIN : '',
    SUBDOMAIN: subdomain === 'indusind'   ? process.env.REACT_APP_IBL_SUBDOMAIN: '',
    BACKEND_API_URL: subdomain === 'indusind'   ? process.env.REACT_APP_IBL_API_URL : process.env.REACT_APP_API_URL,
    COOKIE_API_URL: subdomain === 'indusind'   ? process.env.REACT_APP_IBL_COOKIE_API_URL : process.env.REACT_APP_COOKIE_API_URL,
    THEME_COLOR : subdomain === 'indusind' ?  process.env.REACT_APP_IBL_THEME_COLOR : process.env.REACT_APP_THEME_COLOR ,
//    BACKEND_API_URL: 'http://indusind.staging.lvh.me:5000',
    // BACKEND_API_URL: 'https://volunteeringapi.indusind.com',
    // BACKEND_API_URL: "http://volunteering.indusind.lvh.me:5000",
    subdomain,
};

export default configs;
