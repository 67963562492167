import React, { Component } from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
// import TimePicker from 'rc-time-picker';
import { InputNumber } from 'antd';
import Switch from 'rc-switch';
import swal from 'sweetalert2';
import moment from 'moment';

import 'sweetalert2/dist/sweetalert2.min.css';
import { Check } from 'react-feather';
import { ToastContainer, toast } from "react-toastify";
import configs from '../../../../config/urlConfigs.js';
const readCookie = require('../../../../cookie.js').readCookie;

export default class DeclareOffsetHoursModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offsetObj: {
        employees: { hours: 0, count: 0 },
        friendsAndFamily: { hours: 0, count: 0 },
        externalParticipants: { hours: 0, count: 0 },
        ...(props.offsetObj || {})
      },
      // errorObj: {},
      errorObj: false,

    }
    this.handleChange = this.handleChange.bind(this);
    this.checkErrors = this.checkErrors.bind(this);
  }

  componentDidMount() {
    // this.fetchFriendsList()
  }


  onChange(name, type, value) {
  
    let { offsetObj } = this.state;
    offsetObj[name][type] = Number.parseInt(value);
    this.setState({ offsetObj });
  }

  hours(start_time, end_time) {
    var endTime = moment(end_time, 'h:mm');
    var startTime = moment(start_time, 'h:mm');
    var duration = moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) % 60;
    return hours + ' hour and ' + minutes + ' minutes';
  }

  handleChange(e) {
    this.setState({ reason: e.target.value });
    this.onChange.bind(this, this.state.reason, true)
  }


  checkErrors() {
    let errorObj = {};
    if ((this.state.offsetObj.employees.hours && !this.state.offsetObj.employees.count) || (!this.state.offsetObj.employees.hours && this.state.offsetObj.employees.count)) {
      errorObj['employees'] = true;
    }
    if ((this.state.offsetObj.friendsAndFamily.hours && !this.state.offsetObj.friendsAndFamily.count) || (!this.state.offsetObj.friendsAndFamily.hours && this.state.offsetObj.friendsAndFamily.count)) {
      errorObj['friendsAndFamily'] = true;
    }
    if ((this.state.offsetObj.externalParticipants.hours && !this.state.offsetObj.externalParticipants.count) || (!this.state.offsetObj.externalParticipants.hours && this.state.offsetObj.externalParticipants.count)) {
      errorObj['externalParticipants'] = true;
    }
    this.setState({ errorObj });
    return Object.keys(errorObj).length > 0;
  }

  submitDeclaration() {
    // const error = this.checkErrors();
    // if (error) {
    //   this.props.notifyError('Please fill the particulars properly');
    //   return;
    // } else{

    fetch(
      configs.BACKEND_API_URL +
      `/ee/volunteer-opportunity/${this.props.opportunityId}/offset-hours`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Auth: JSON.parse(readCookie("access_token"))
            .access_token,
        },
        body: JSON.stringify({
         offsetObj: this.state.offsetObj
        }),
      }
    )
      .then((response) => response.json())
      .then(
        function (data) {
          if (data["status"]) {
            this.props.notifySuccess("Offset hours successfully saved!")
          }
        }.bind(this)
      );
      this.props.hideDeclareModal();
    // }
  }

  render() {
    return (
      <Modal bsSize="md" className="declare-modal" style={{ width: '100%' }}
        show={this.props.showDeclareModalVisibility} onHide={this.props.hideDeclareModal}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-header-custom">
            Declare Your Volunteer Offset Hours
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: 500, overflowY: 'scroll', overflowX: 'hidden' }}>
            <Row>
              <Col className="form-group" md={6}>
                {/* <label className="control-label mt-3 ">Employees: </label> */}
              </Col>
              <Col className="form-group" md={3}>
                <label className="control-label ">Hours: </label>

              </Col>
              <Col className="form-group" md={3}>
                <label className="control-label ">Count: </label>

              </Col>
            </Row>
            <Row>
              <Col className="form-group" md={6}>
                <label className="control-label mt-3 ">Employees: </label>
              </Col>
              <Col className="form-group" md={3}>
                {/* <label className="control-label ">Hours: </label> */}
                <InputNumber
                  min={0}
                  // max={10}
                  
                                    value={this.state.offsetObj && this.state.offsetObj.employees && this.state.offsetObj.employees.hours ? this.state.offsetObj.employees.hours : 0}
                  // defaultValue={this.state.offsetObj && this.state.offsetObj.employees && this.state.offsetObj.employees.hours ? this.state.offsetObj.employees.hours : 0}
                  className="form-control"
                  onChange={this.onChange.bind(this, 'employees', 'hours')}
 />

              </Col>
              <Col className="form-group" md={3}>
                {/* <label className="control-label ">Count: </label> */}
                <InputNumber
                  min={0}
                  // max={10}
                                    value={this.state.offsetObj && this.state.offsetObj.employees && this.state.offsetObj.employees.count ? this.state.offsetObj.employees.count : 0}
                  // defaultValue={this.state.offsetObj && this.state.offsetObj.employees && this.state.offsetObj.employees.count ? this.state.offsetObj.employees.count : 0}
                  className="form-control"
                  onChange={this.onChange.bind(this, 'employees', 'count')}
                />
              </Col>
              {this.state.errorObj.employees ? (
               <Col md={12}> <span className="red-color">Please input valid count and hour values!</span>
                </Col>) : (null)}
            </Row>

            <Row>
              <Col className="form-group" md={6}>
                <label className="control-label mt-3 ">Friends/ Family: </label>
              </Col>
              <Col className="form-group" md={3}>
                {/* <label className="control-label ">Hours: </label> */}
                <InputNumber
                  min={0}
                  // max={10}
                                    value={this.state.offsetObj && this.state.offsetObj.friendsAndFamily && this.state.offsetObj.friendsAndFamily.hours ? this.state.offsetObj.friendsAndFamily.hours : 0}
                  // defaultValue={this.state.offsetObj && this.state.offsetObj.friendsAndFamily && this.state.offsetObj.friendsAndFamily.hours ? this.state.offsetObj.friendsAndFamily.hours : 0}
                  className="form-control"
                  onChange={this.onChange.bind(this, 'friendsAndFamily', 'hours')}
                />

              </Col>
              <Col className="form-group" md={3}>
                {/* <label className="control-label ">Count: </label> */}
                <InputNumber
                  min={0}
                  // max={10}
                  value={this.state.offsetObj && this.state.offsetObj.friendsAndFamily && this.state.offsetObj.friendsAndFamily.count ? this.state.offsetObj.friendsAndFamily.count : 0}
                  // defaultValue={this.state.offsetObj && this.state.offsetObj.friendsAndFamily && this.state.offsetObj.friendsAndFamily.count ? this.state.offsetObj.friendsAndFamily.count : 0}
                  className="form-control"
                  onChange={this.onChange.bind(this, 'friendsAndFamily', 'count')}
                />
              </Col>
              {this.state.errorObj.friendsAndFamily ? (
                <Col md={12}> <span className="red-color">Please input valid count and hour values!</span>
                </Col>) : (null)}
            </Row>

            <Row>
              <Col className="form-group" md={6}>
                <label className="control-label mt-3 ">External Participants: </label>
              </Col>
              <Col className="form-group" md={3}>
                {/* <label className="control-label ">Hours: </label> */}
                <InputNumber
                  min={0}
                  // max={10}
                  value={this.state.offsetObj && this.state.offsetObj.externalParticipants && this.state.offsetObj.externalParticipants.hours ? this.state.offsetObj.externalParticipants.hours : 0}
                  // defaultValue={this.state.offsetObj && this.state.offsetObj.externalParticipants && this.state.offsetObj.externalParticipants.hours ? this.state.offsetObj.externalParticipants.hours : 0}
                  className="form-control"
                  onChange={this.onChange.bind(this, 'externalParticipants', 'hours')}
                />

              </Col>
              <Col className="form-group" md={3}>
                {/* <label className="control-label ">Count: </label> */}
                <InputNumber
                  min={0}
                  // max={10}
                  value={this.state.offsetObj && this.state.offsetObj.externalParticipants && this.state.offsetObj.externalParticipants.count ? this.state.offsetObj.externalParticipants.count : 0}
                  // defaultValue={this.state.offsetObj && this.state.offsetObj.externalParticipants && this.state.offsetObj.externalParticipants.count ? this.state.offsetObj.externalParticipants.count : 0}
                  className="form-control"
                  onChange={this.onChange.bind(this, 'externalParticipants', 'count')}
                />
              </Col>
              {this.state.errorObj.externalParticipants ? (
                <Col md={12}> <span className="red-color">Please input valid count and hour values!</span>
                </Col>) : (null)}
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="footer-center">
          <Button className="btn btn-primary" onClick={this.submitDeclaration.bind(this)}>
            Submit offset hours
          </Button>
          <Button className="btn btn-outline-primary" onClick={this.props.hideDeclareModal.bind(this)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}