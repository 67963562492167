import React, {Component} from 'react';

import Form from '../EE/Form/Form';

// const readCookie = require('../../cookie.js').readCookie;

export default class CreateVolunteerOpportunity extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		return (
			<Form match={{params: {}}} admin='LEadmin' />
		);
	}
}