import { combineReducers } from 'redux';

import Landing from './LandingReducer';
import Signup from './SignupReducer';
import DiscoverCauses from './DiscoverCausesReducer';
import DiscoverProjects from './DiscoverProjectsReducer';
import DiscoverCSRProjects from './DiscoverCSRProjectsReducer';
import Corporate from './CorporateReducer';
import Admin from './AdminReducer';
import Contact from './ContactReducer';
import Profile from './ProfileReducer';
import EEFeed from './EEFeedReducer';
import EEDiscoverOpportunity from './EEDiscoverOpportunityReducer';
import EEVolunteerHistory from './EEVolunteerHistoryReducer';
import EERecommendOpportunity from './EERecommendOpportunityReducer';
import EEEndorseProject from './EEEndorseProjectsReducer';
import EEEndorsePayment from './EEEndorsePaymentReducer';
import EEEndorsementHistory from './EEEndorsementHistoryReducer';
import EEPreferences from './EEPreferencesReducer';
import EEAdminSettings from './EEAdminSettingsReducer';
import EEAdminDiscoverOpportunities from './EEAdminDiscoverOpportunitiesReducer';
import EEAdminOpportunityRecommendations from './EEAdminOpportunityRecommendationsReducer';
import EEAdminFundraisers from './EEAdminFundraisersReducer';
import EEAdminLeaderboard from './EEAdminLeaderboardReducer';
import EEAdminContributionAnalytics from './EEAdminContributionAnalyticsReducer';
import EEAdminVolunteeringAnalytics from './EEAdminVolunteeringAnalyticsReducer';
import EEAdminTeamManagement from './EEAdminTeamManagementReducer';
import RoleReducer from './RoleReducer';

const indexReducer = combineReducers({
	Landing,
	Signup,
	DiscoverCauses,
	DiscoverProjects,
	DiscoverCSRProjects,
	Corporate,
	Admin,
	Contact,
	Profile,
	EEFeed,
	EEDiscoverOpportunity,
	EEVolunteerHistory,
	EERecommendOpportunity,
	EEEndorseProject,
	EEEndorsePayment,
	EEEndorsementHistory,
	EEPreferences,
	EEAdminSettings,
	EEAdminDiscoverOpportunities,
	EEAdminOpportunityRecommendations,
	EEAdminFundraisers,
	EEAdminLeaderboard,
	EEAdminContributionAnalytics,
	EEAdminVolunteeringAnalytics,
	EEAdminTeamManagement,
	RoleReducer,
});

export default indexReducer;