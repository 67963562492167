const initialState = {
	emails: [],
	inputTag: []
};

const EEAdminTeamManagementReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_EMAILS':
			return Object.assign({}, state, {
				emails: action.emails,
			});
		case 'UPDATE_INPUT_TAG':
			return Object.assign({}, state, {
				inputTag: action.inputTag,
			});
		default:
			return state
	}
}

export default EEAdminTeamManagementReducer;