import React, { Component } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const readCookie = require("../../../../cookie.js").readCookie;
import { ToastContainer, toast } from "react-toastify";
import configs from "../../../../config/urlConfigs.js";
export default class RecommendAnOpportunitySwitch extends Component {
  constructor() {
    super();

    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(field, value) {
    let commentRegex = new RegExp(`^[\.a-zA-Z0-9,!?:;@#$~' ]*$`)
    if (commentRegex.test(e.target.value) === true || true){
    if (field === "opportunityDescription") {
      this.props.updateOpportunityDescription(value.target.value);
    } else if (field === "opportunityEmail") {
      this.props.updateOpportunityEmail(value.target.value);
    } else if (field === "opportunityPhone") {
      this.props.updateOpportunityPhone(value.target.value);
    } else if (field === "opportunityNote") {
      this.props.updateOpportunityNote(value.target.value);
    }
  }
  else {
    this.notifyError("Only letters, numbers and special characters are allowed");
  }
  }
  notifyError = (text) => toast.error(text);

  onSubmit(e) {
    e.preventDefault();
    let data = {};
    data["description"] = this.props.opportunityDescription;
    data["emailOfPOC"] = this.props.opportunityEmail;
    data["phoneOfPOC"] = this.props.opportunityPhone;
    data["introductoryNote"] = this.props.opportunityNote;

    fetch(
      configs.BACKEND_API_URL +
      "/volunteer-opportunity/give-recommendation",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Auth: JSON.parse(readCookie("access_token")).access_token,
        },
        body: JSON.stringify(data),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {
          if (data.status === "ok") {
            this.props.closeModal1();
            this.props.updateOpportunityDescription("");
            this.props.updateOpportunityEmail("");
            this.props.updateOpportunityPhone("");
            this.props.updateOpportunityNote("");
            swal(
              "Awesome!",
              "Your suggestion has been sent to the HR/CSR administrators. You shall hear from them soon!",
              "success"
            ).then(window.location.reload());

            // this.props.opportunitiesLength(Math.floor(Math.random() * 10));
          }
        }.bind(this)
      );
  }

  render() {
    return (
      <Modal
        show={this.props.showRecommendOpportunityModal}
        onHide={this.props.closeModal1}
        size="lg"
       
      >
        <ToastContainer
						position="bottom-center"
						autoClose={50000}
						hideProgressBar={true}
						closeOnClick={false}
						newestOnTop={false}
						pauseOnHover={true}
					/>
        <Modal.Body>
          <Row>
            <Row  closeButton>
              <Col md={12} className="text-center">
                <h2 className="ee-page-title">Recommend An Opportunity</h2>
                <span className="text-center recommend-opportunity-subheading">
                  If you work with/know an organization closely, then you may
                  recommend the same to the CSR/HR team. We would look forward
                  to getting your suggestions on possible NGO partners.
                </span>
              </Col>
            </Row>
            <Col md={10} mdOffset={1}>
              <form name="groupForm" onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label className="control-label is-imp">
                    Description of the opportunity:
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Description of the opportunity"
                    required
                    value={this.props.opportunityDescription}
                    onChange={this.onChange.bind(
                      this,
                      "opportunityDescription"
                    )}
                  ></textarea>
                </div>

                <div className="form-group">
                  <label className="control-label">Email ID of the Contact Person:</label>
                  <input
                    type="email"
                    className="form-control"
                    id="body_name"
                    name="body_name"
                    placeholder="Email ID of the POC"
                    required
                    value={this.props.opportunityEmail}
                    onChange={this.onChange.bind(this, "opportunityEmail")}
                  />
                </div>

                <div className="form-group">
                  <label className="control-label">
                    Phone Number of the Contact Person:
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="body_name"
                    name="body_name"
                    placeholder="Phone Number of the POC"
                    required
                    value={this.props.opportunityPhone}
                    onChange={this.onChange.bind(this, "opportunityPhone")}
                    maxLength="10"
                  />
                </div>

                <div className="form-group">
                  <label className="control-label is-imp">
                    Describe your engagement with/knowledge about this
                    organization. This would help us in reaching out to them
                    with an appropriate context.
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Send an introductory note"
                    required
                    value={this.props.opportunityNote}
                    onChange={this.onChange.bind(this, "opportunityNote")}
                  ></textarea>
                </div>

                <div className="form-group" align='center'>
                  <button
                    type="submit"
                    className="btn btn-primary"
                  // onClick={this.onSubmit}
                  >
                    Submit
                    {/* <i className="fa fa-paper-plane"></i> */}
                  </button>
                  {/* <Button
                    onClick={this.props.closeModal1}
                    className="btn-sq btn-cancel btn-center"
                    style={{ marginLeft: "20%" }}
                  >
                    Close
                  </Button> */}
                </div>
              </form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}
