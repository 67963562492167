import React, {Component} from 'react';
import { Modal, Button, Row, Col } from "react-bootstrap";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import configs from '../../../../config/urlConfigs.js';

const readCookie = require('../../../../cookie.js').readCookie;

export default class CreateListModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
  		list_name: '',
  		list_desc: '',
      address: '',
      lat: '',
      lng: ''
		};
    this.updateChange = this.updateChange.bind(this);
    this.changeAddress = this.changeAddress.bind(this);
    this.selectedAddress = this.selectedAddress.bind(this);
	}

  updateChange(name, e) {
  	this.setState({ [name]: e.target.value, error: null });
  }

  changeAddress(address) {
    this.setState({ address, error: null });
  }

  selectedAddress(address, id) {
    this.setState({ address })
    geocodeByAddress(address)
    .then(results => getLatLng(results[0]))
    .then(function(latLng) {
      this.setState({ lat: latLng.lat, lng: latLng.lng });
    }.bind(this));
  }

  createList() {
    if(this.state.list_name !== '' && this.state.list_desc !== '' && this.state.address !== '' && this.state.lat !== '' && this.state.lng !== '') {
    	var data = {};
    	data['name'] = this.state.list_name;
    	data['description'] = this.state.list_desc;
      data['address'] = this.state.address;
      data['latitude'] = this.state.lat;
      data['longitude'] = this.state.lng;

      fetch(configs.BACKEND_API_URL + '/list', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Auth': JSON.parse(readCookie('access_token')).access_token
        },
        body: JSON.stringify(data)
      }).then((response) => response.json())
      .then(function(data) {
        if(data.status === 'ok' && data.hasOwnProperty('listId')) {
          this.props.hideCreateListModal();
        }
      }.bind(this));
    } else {
      if(this.state.list_name === '') this.setState({ error: 'name' });
      else if(this.state.list_desc === '') this.setState({ error: 'desc' });
      else if(this.state.address === '' || this.state.lat === '' || this.state.lng === '') this.setState({ error: 'address' });
    }
  }

  cancelCreateList() {
  	this.props.hideCreateListModal();
  }

	render() {
    const inputProps = {
      value: this.state.address,
      onChange: this.changeAddress
    };

    const options = {
      // types: [],
    };

    const cssClasses = {
      input: 'form-control',
      autocompleteContainer: 'my-autocomplete-container'
    };

		return (
			<div>
				<Modal bsSize="large" className="create-office-list-modal"
          show={this.props.showCreateListModal}
          onHide={this.props.hideCreateListModal} >
          <Modal.Header>
            <Modal.Title className="modal-header-custom">
              Add Office/City
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <form id="edit-org-details-modal-form"
                  // ref="vForm"
                >

                  <Col className="form-group" md={12}>
                    <label className="control-label is-imp">Office/City Name: </label>
                    <input type="text" className="form-control" id="list_name" name="list_name" onChange={this.updateChange.bind(this, 'list_name')}
                      placeholder="Office/City Name" required defaultValue={this.state.list_name} />
                    {this.state.error === 'name' ? (
                      <span className="red-color">Incorrect Name!</span>
                    ) : (null)}
                  </Col>

                  <Col className="form-group" md={12}>
                    <label className="control-label is-imp">Office/City Description: </label>
                    <textarea className="form-control" id="list_desc" onChange={this.updateChange.bind(this, 'list_desc')}
                      placeholder="Office/City Description" required defaultValue={this.state.list_desc} />
                    {this.state.error === 'desc' ? (
                      <span className="red-color">Incorrect Description!</span>
                    ) : (null)}
                  </Col>

                  <Col className="form-group" md={12}>
                    <label className="control-label is-imp">Office/City Location: </label>
                    <PlacesAutocomplete inputProps={inputProps} onSelect={this.selectedAddress} classNames={cssClasses} options={options} />
                    {this.state.error === 'address' ? (
                      <span className="red-color">Incorrect Address!</span>
                    ) : (null)}
                  </Col>
                </form>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="footer-center">
            <Button className="btn btn-success" onClick={this.createList.bind(this)}>Save</Button>
          	<Button className="btn btn-danger" onClick={this.cancelCreateList.bind(this)}>Cancel</Button>
          </Modal.Footer>
        </Modal>
			</div>
		);
	}
}
