import React, { Component } from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import moment from 'moment';

export default class EndorsementHistoryList extends Component {
	render() {
		if (this.props.history.length) {
			return (
				<Row>
					{this.props.history.map(function (hist, i) {
						return (
							<Col lg={4} md={4} sm={6} style={{ marginBottom: '20px' }} key={i}>
								<Row style={{ marginLeft: 0, marginRight: 0 }}>
									<Col md={12} className="ideaResult">
										<a href={'/project/' + hist.customUrl}>
											<Col md={12} className="resultCover"
												style={{ background: 'url(' + hist.photo + ')' }}>
											</Col>
										</a>
										<div className="manage-fundraiser current-state">
											{(() => {
												if (hist.config.matchingGiving) {
													return (
														<span className="current-state-outer submitted">
															<span className="outer-arrow"> </span>
															<span className="current-state-text">
																matching giving
															</span>
														</span>
													)
												}
											})()}
										</div>
										<h2 className="resultName">
											<a href={'/project/' + hist.customUrl}>
												{hist.title}
											</a>
										</h2>
										<h4 className="resultLocation">
											{hist.city + ', ' + hist.country}
										</h4>
										<Col md={12} className="resultDescription">
											<div className="project-footer">
												<div className="progress-container">
													<ProgressBar active now={hist.fundraisePercentDone} label={hist.fundraisePercentDone + '%'} className="endorsement-progress" />
													<div className="row margin-removal-rows statistics-container">
														<Col md={4} lg={4} xs={4} sm={4} className="text-center padding-removal-columns">
															<div className="widget-statistics-value">{hist.fundraisePercentDone}%</div>
															<div className="widget-statistics-name">ENDORSED</div>
														</Col>
														<Col md={4} lg={4} xs={4} sm={4} className="text-center padding-removal-columns">
															<div className="widget-statistics-value">INR {hist.totalRaisedFunds}</div>
															<div className="widget-statistics-name">RAISED</div>
														</Col>
														<Col md={4} lg={4} xs={4} sm={4} className="text-center padding-removal-columns">
															<div className="widget-statistics-value">
																{(() => {
																	let diff = moment(hist.endDate).diff(moment(), 'days')
																	if (diff > 0) {
																		return diff;
																	} else {
																		return 0;
																	}
																})()}
															</div>
															<div className="widget-statistics-name">DAYS LEFT</div>
														</Col>
													</div>
												</div>
											</div>
										</Col>
									</Col>
								</Row>
							</Col>
						)
					})}
				</Row>
			);
		} else {
			return (
				<Row>
					<Col md={12} className="text-center">
						<p className="no-data-text" style={{ textTransform: 'none' }}>You have not contributed yet</p>
						{/* <button onClick={() => {window.location.pathname = '/endorse-projects'}} className="btn-le btn-sq btn-pad-5-20 m10">endorse projects now</button> */}
					</Col>
				</Row>
			);
		}
	}
}