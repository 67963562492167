export const updateUserRole = (role = null) => {
    return { role, type: 'USER_ROLE_CHANGED'};
};

export const handleLogout = () => {
    return { type: 'USER_LOGGED_OUT' };
}

export const handleLogin = () => {
    return { type: 'USER_LOGGED_IN' };
}