import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import * as action from '../../actions/SignupAction';
import * as roleAction from '../../actions/RoleAction';

import 'bootstrap/dist/css/bootstrap.css';
import Cookies from 'js-cookie'
import SignupSwitch from './SignupSwitch';
import { createCookie, readCookie } from '../../cookie';
import jwt from 'jsonwebtoken';
import { ToastContainer, toast } from 'react-toastify';
import configs from '../../config/urlConfigs';

var CryptoJS = require("crypto-js");

const subdomain = configs.SUBDOMAIN;
// const subdomain = window.location.hostname.split('.')[0];
const notifyError = (text) => toast.error(text);

class Signup extends Component {
    constructor(props) {
        super(props);
        
        // const token = this.props.location.search.split('?token=')[1] || "";
        if (subdomain==='indusind' && !readCookie('access_token') || !readCookie('userData') || !readCookie('letsEngageVersion')) {
            this.checkAPIs();
        }
        this.decryptJWT = this.decryptJWT.bind(this);
    }
    checkAPIs = async () => {
        const { data } = await axios.get(configs.COOKIE_API_URL + '/get-cookies', { withCredentials: true });
        if (data.cookies && data.cookies.ibl_access_token) {
            let token = JSON.parse(data.cookies.ibl_access_token).access_token || '';
            this.decryptJWT(token);
        }
    }

    decryptData = (ciphertext) => {
        try {
            var bytes = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_ENCRYPTION_KEY);
            return bytes.toString(CryptoJS.enc.Utf8);
        }
        catch (err) {
            return '';
        }
    }

    decryptJWT = (token) => {
        // var that = this;
        let decryptedData = '';
        if (token) {
           decryptedData =  this.decryptData(token);
        }
        else {
            notifyError("Login attempt didnt succeed. Please try again later!");
            window.location.href = '/';
        }
        jwt.verify(decryptedData, 'eheHaiMeraSecret!@()#$&*', function (err, savedUserData) {
            if (err) {
                console.error(err, 'eheHaiMeraSecret!@()#$&*');
                // return res.status(401).json({ status: "error" });
            } else {
                // Now check in blacklisted
                delete savedUserData.iat;
                delete savedUserData.exp;
                delete savedUserData.organisationData;
                delete savedUserData.questionnairePreferences;
                delete savedUserData.preference;
                delete savedUserData.grade;
                delete savedUserData.manager;
                delete savedUserData.department;

                createCookie('userData', JSON.stringify(savedUserData), 7);
                createCookie('letsEngageVersion', JSON.stringify(1.2), 7);
                createCookie('access_token', JSON.stringify({ access_token: token }), 7);
                window.location.href = '/';

            }
        });
    }
    render() {
        return (
            <div className="signup-container">
                <SignupSwitch
                    loginType={this.props.loginType}
                    loginEmail={this.props.loginEmail}
                    loginPassword={this.props.loginPassword}

                    changeLoginType={this.props.actions.changeLoginType}
                    changeLoginEmail={this.props.actions.changeLoginEmail}
                    changeLoginPassword={this.props.actions.changeLoginPassword}
                    cancelCorporateSignin={this.props.actions.cancelCorporateSignin}
                    cancelNgoSignin={this.props.actions.cancelNgoSignin}
                    flipCorporateSignin={this.props.actions.flipCorporateSignin}
                    flipNgoSignin={this.props.actions.flipNgoSignin}
                />
                <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar={true}
                    closeOnClick={false}
                    newestOnTop={false}
                    pauseOnHover={true}
                />
            </div>
        );
    }

}

Signup.propTypes = {
    loginType: PropTypes.string,
    loginEmail: PropTypes.string,
    loginPassword: PropTypes.string,
};

function mapStateToProps(state, props) {
	return {
		loginType: state.Signup.loginType,
		loginEmail: state.Signup.loginEmail,
		loginPassword: state.Signup.loginPassword,
		role: state.RoleReducer,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		roleAction: bindActionCreators(roleAction, dispatch),
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);