import React, { Component } from "react";

import FreshdeskWidget from '@personare/react-freshdesk-widget';
import { Menu } from "antd";
import SvgIcon from "react-icons-kit";
import { ic_aspect_ratio } from "react-icons-kit/md/ic_aspect_ratio";
import { ic_business } from "react-icons-kit/md/ic_business";
import { logout } from "react-icons-kit/ikons/logout";
import { speech_bubble_1 } from "react-icons-kit/ikons/speech_bubble_1.js";
import { rupee } from "react-icons-kit/fa/rupee";
import { listUl } from "react-icons-kit/fa/listUl";
import { handPeaceO } from "react-icons-kit/fa/handPeaceO";
import { userSecret } from "react-icons-kit/fa/userSecret";
import $ from 'jquery';
import swal from "sweetalert2";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import "antd/dist/antd.css";
import { OrgDataContext } from "../../../routes.js";
import * as action from "../../../actions/RoleAction";
import { eraseAllCookies } from "../../../cookie.js";
import configs from "../../../config/urlConfigs.js";
import { generatePreSignedGetUrl } from "../../../s3PresignedUrls.js";

const eraseCookie = require("../../../cookie.js").eraseCookie;
const readCookie = require("../../../cookie.js").readCookie;
const createCookie = require("../../../cookie.js").createCookie;

const SubMenu = Menu.SubMenu;
const subdomain = configs.SUBDOMAIN;
// const subdomain = window.location.hostname.split('.')[0];

class Header extends Component {
  constructor(props) {
    super(props);
    let defaultSelected = window.location.href.split("/").pop();
    this.state = {
      defaultSelected,
      profilePictureUrl: '',
      // showPrivacyModal: false,
    };
  }

  logout() {
    this.props.actions.handleLogout();
    swal({
      title: "Are you sure you want to logout?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Logout"
    }).then(function (result) {
      if (result) {
        fetch(configs.BACKEND_API_URL + '/logout', {
          method: "get",
          credentials: "include",
          headers: {
            Auth: JSON.parse(readCookie("access_token")).access_token,
          },
        })
          .then((data) => data.json())
          .then((data) => {
            eraseAllCookies();
        eraseCookie("userData");
        eraseCookie("access_token");
        eraseCookie("letsEngageVersion");
        window.location.pathname = "/";
          });
      }
    }).catch(() => { });
  }

  gotoPage(page) {
    window.location.pathname = page;
  }

  handleClick(e) {
    if (e.key === "feed") this.gotoPage("/feed");
    else if (e.key === "discover-opportunities") this.gotoPage("/discover-opportunities");
    else if (e.key === "my-volunteer-history") this.gotoPage("/my-volunteer-history");
    else if (e.key === "recommend-an-opportunity") this.gotoPage("/recommend-an-opportunity");
    else if (e.key === "endorse-projects") this.gotoPage("/endorse-projects");
    else if (e.key === "my-endorsement-history") this.gotoPage("/my-endorsement-history");
    else if (e.key === "payroll-giving") this.gotoPage("/payroll-giving");
    else if (e.key === "payroll-partners") this.gotoPage("/payroll-partners");
    // else if(e.key === 'preferences') this.gotoPage('/preferences');
    else if (e.key === "settings") this.gotoPage("/settings");
    else if (e.key === "admin") {
      this.props.actions.updateUserRole(JSON.parse(readCookie("userData")).role);
      this.gotoPage("/");
      // this.gotoPage("/admin");
    } else if (e.key === 'faqs') {
      this.gotoPage("/faqs");
    }
    else if (e.key === "logout") { this.logout(); this.props.actions.handleLogout(); }
  }

  openProfileEditModal = () => {
    if (window.location.pathname.match('profile') === null) this.gotoPage("/profile");
  }

  componentDidMount() {
    var that = this;

    if (JSON.parse(readCookie('access_token')) && JSON.parse(readCookie('access_token')).access_token) {
      fetch(configs.BACKEND_API_URL + '/get-user-data', {
        method: "GET",
        headers: {
          'Auth': JSON.parse(readCookie('access_token')).access_token
        },
      }).then(function (response) {
        return response.json();
      }).then(function (responseData) {
        let userData = JSON.parse(readCookie("userData"));
        let data = responseData.data;
        that.setState({ profilePictureUrl: data.profilePicture });
        userData.fullName = data.fullName;
        userData.mobileNo = data.mobileNo;
        userData.department = data.department;
        userData.profilePicture = data.profilePicture;
        userData.designationAtOrganisation = data.designationAtOrganisation;
        delete userData.organisationData;
        delete userData.questionnairePreferences;
        delete userData.preference;

        createCookie("userData", JSON.stringify(userData), 7);
      });      
    }

    $(document).ready(function () {
      var userDivHeight = $('.ee-header-container div.ee-header-user').height();
      var headerTop = parseInt($('.ee-header-container').css('top').split('px')[0]);
      if (headerTop > 0) headerTop += 40;
      $('.ee-header-container ul#ee-navbar').css('max-height', 'calc(100% - ' + (userDivHeight + 185 + headerTop) + 'px)');
    });
  }
  handleClickChangeRoleButton(){
    this.props.actions.updateUserRole(JSON.parse(readCookie("userData")).role);
      this.gotoPage("/");
}

  render() {
    let defaultOpenKeys = [];
    if (this.props.selected === "discover-opportunities" || this.props.selected === "my-volunteer-history" || this.props.selected === "recommend-an-opportunity") defaultOpenKeys[0] = "discover";
    else if (this.props.selected === "endorse-projects" || this.props.selected === "my-endorsement-history" || this.props.selected === "payroll-giving" || this.props.selected === "payroll-partners") defaultOpenKeys[0] = "endorsements";

    return (
      <OrgDataContext.Consumer>
        {(organisationData) =>
        {
          return (<div className={subdomain.toLowerCase() === 'indusind' ? "ee-header-container ibl-header-container" : "ee-header-container"}>

        <div className="ee-header">
         {/* {subdomain.toLowerCase() === 'indusind' ? <div className="ee-org-logo-container">
            <img src="/img/IBL_Logo_top_left.png" width={150} alt="IBL Logo PNG" /> */}
            {/* <div className="top-right-logo-image">
              <img src="/img/IBL_Logo_top_right.png" width={150} alt="IBL Logo PNG" />
              </div> */}
          {/* </div> : (null)} */}
          <div className="ee-header-user">
                <div className="ee-header-user-profile">
                  {subdomain === "indusind" && false ?
                    <div style = {{ marginBottom: '2px', cursor: "pointer", position: "fixed", bottom: '10px', right: '10px', backgroundColor: 'green', borderRadius: '70%', zIndex: '99999px' }}
                      >
                      {/* <button className="btn send-feedback-btn"> */}
                      {/* <Menu.Item key="freshdesk"> */}
                      {/* <SvgIcon size={14} icon={logout} /> */}
                      {/* <i class="fa-duotone fa-message"></i>
                         */}
                      {/* <i class="fa-solid fa-comment"/> */}
                      <FreshdeskWidget url="https://letsendorse.freshdesk.com/" key={'555'} type="pop-up">
                        {/* <span className="ee-header-menu-text">Send Feedback</span>
                         */}
                        {/* <i class="fafa-regular fa-messages-question"></i> */}
                        {/* <i class="fa fa-ticket"></i> */}
                        <div class="bottom-left-caller" style={{ position: "fixed", bottom: "20px", right: "20px", cursor: "pointer" }}>
                          <i class="btn btn-primary btn-lg fa 
                          fa-flip-horizontal fa-5x fa-comments" aria-hidden="true" title="Send feedback"></i>
                        </div>
                        {/* <i class="fa-solid fa-message-question"></i> */}

                      </FreshdeskWidget>
                      {/* </Menu.Item> */}
                      {/* </button> */}
                    </div>
                    : null}
              {/* {JSON.parse(readCookie("userData")).profilePicture &&
                JSON.parse(readCookie("userData")).profilePicture !== null ? (
                <img
                  className="ee-header-user-profile-img"
                  src={JSON.parse(readCookie("userData")).profilePicture || "/img/user.png"}
                />
              ) : (
                <img
                  className="ee-header-user-profile-img"
                  src="/img/user.png"
                />
              )} */}
                  {this.state.profilePictureUrl &&
                    this.state.profilePictureUrl !== null ? (
                    <img
                      className="ee-header-user-profile-img"
                        src={generatePreSignedGetUrl(this.state.profilePictureUrl) || "/img/user.png"}
                        // src={this.state.profilePictureUrl || "/img/user.png"}
                    />
                  ) : (
                    <img
                      className="ee-header-user-profile-img"
                      src="/img/user.png"
                    />
                  )}
              <i
                className={subdomain.toLowerCase() === 'indusind' ? "fa fa-pencil ee-header-user-profile-edit" : "fa fa-pencil ee-header-user-profile-edit"}
                onClick={this.openProfileEditModal}
              ></i>
            </div>
            <div className="details">
              <div className="details-name">
                <p className="details-user-name">
                  {JSON.parse(readCookie("userData")).fullName}
                </p>
              </div>
              {JSON.parse(readCookie("userData")).email ? (
                <div className="details-email">
                  <p className="details-user-email">
                    {JSON.parse(readCookie("userData")).email}
                  </p>
                </div>
              ) : null}
              {JSON.parse(readCookie("userData")).mobileNo &&
                JSON.parse(readCookie("userData")).mobileNo !== "false" ? (
                <div className="details-mobile">
                  <p className="details-user-mobile">
                    {JSON.parse(readCookie("userData")).mobileNo}
                  </p>
                </div>
              ) : null}
              {/* {JSON.parse(readCookie("userData")).hasOwnProperty(
                "organisationData"
              ) &&
              JSON.parse(
                readCookie("userData")
              ).organisationData.hasOwnProperty("name") &&
              organisationData.name !==
                "" ? (
                <div>
                  {organisationData.name}
                </div>
              ) : null} */}
            </div>
          </div>

          <Menu
            id="ee-navbar"
            onClick={this.handleClick.bind(this)}
            style={{ width: 300 }}
            defaultSelectedKeys={[this.props.selected]}
            defaultOpenKeys={defaultOpenKeys}
            mode="inline"
          >
            <Menu.Item key="feed">
              <SvgIcon size={14} icon={listUl} />
              <span className="ee-header-menu-text">Dashboard</span>
            </Menu.Item>

            <SubMenu
              key="discover"
              title={
                <div>
                  <SvgIcon size={14} icon={handPeaceO} />
                  <span className="ee-header-menu-text">
                    Volunteer Opportunities
                  </span>
                </div>
              }
            >
              <Menu.Item key="discover-opportunities">
                <SvgIcon size={14} icon={ic_aspect_ratio} />
                <span className="ee-header-menu-text">
                  Discover Opportunities
                </span>
              </Menu.Item>

              <Menu.Item key="my-volunteer-history">
                <SvgIcon size={14} icon={ic_aspect_ratio} />
                <span className="ee-header-menu-text">My Opportunities</span>
              </Menu.Item>

              {subdomain!=='indusind' ? <Menu.Item key="recommend-an-opportunity">
                <SvgIcon size={14} icon={ic_aspect_ratio} />
                <span className="ee-header-menu-text">
                  Opportunity Recommendations
                </span>
              </Menu.Item>: null}
            </SubMenu>

            {(organisationData.eePreferences.enableGiveBack || organisationData.eePreferences.enablePayrollGiving)
              && <SubMenu
                key="endorsements"
                title={
                  <div>
                    <SvgIcon size={14} icon={rupee} />
                    <span className="ee-header-menu-text">Contribute Funds </span>
                  </div>
                }
              >
                {organisationData.eePreferences
                  .enableGiveBack && <Menu.Item key="endorse-projects">
                    <SvgIcon size={14} icon={ic_aspect_ratio} />
                    <span className="ee-header-menu-text">Fundraisers</span>
                  </Menu.Item>}

                {organisationData
                  .eePreferences.enablePayrollGiving ? (
                  <Menu.Item key="payroll-giving">
                    <SvgIcon size={14} icon={ic_business} />
                    <span className="ee-header-menu-text">Payroll Giving</span>
                    {localStorage.getItem("pledgeSettings") !== null
                      ? JSON.parse(localStorage.getItem("pledgeSettings"))[
                      "pledged"
                      ]
                      : false && (
                        <small className="text-uppercase">
                          {" "}
                          &ensp; &#10004; Subscribed
                        </small>
                      )}
                  </Menu.Item>
                ) : null}

                {organisationData
                  .eePreferences.enablePayrollGiving ? (
                  <Menu.Item key="payroll-partners">
                    <SvgIcon size={14} icon={ic_business} />
                    <span className="ee-header-menu-text">
                      Payroll Partners
                    </span>
                  </Menu.Item>
                ) : null}

                <Menu.Item key="my-endorsement-history">
                  <SvgIcon size={14} icon={ic_aspect_ratio} />
                  <span className="ee-header-menu-text">
                    My Contribution History
                  </span>
                </Menu.Item>
              </SubMenu>}

                <Menu.Item key="faqs">
                  <SvgIcon size={14} icon={speech_bubble_1} />
                  <span className="ee-header-menu-text">FAQs</span>
                </Menu.Item>

                {/* {subdomain === "indusind" ? <Menu.Item key="freshdesk">
                  <SvgIcon size={14} icon={logout} />
                  <FreshdeskWidget url="https://letsendorse.freshdesk.com/" type="pop-up">
                    <span className="ee-header-menu-text">Send Feedback</span>
                  </FreshdeskWidget>
                </Menu.Item> : null} */}

            <Menu.Item key="logout">
                  <SvgIcon size={14} icon={logout} />
              <span className="ee-header-menu-text">Logout</span>
                </Menu.Item>
                
                {/* <Menu.Item key="freshdesk">
                  <SvgIcon size={14} icon={logout} />
                  <span className="ee-header-menu-text"> */}
                
                  {/* <FreshdeskWidget url="https://support.freshdesk.com" type="pop-up"> */}

                  {/* </span>
                </Menu.Item> */}

            {/* <div> */}
            {/*<Menu id='header-bottom-menu' style={{ display: 'flex' }}>
              <Menu.Item key="story">
                <SvgIcon size={14} icon={ic_present_to_all} />
                <a href='https://www.volunteermatch.org/volunteers/stories/share.jsp' target='_blank' style={{ color: '#fff' }} className="ee-header-menu-text">Submit a Story</a>
              </Menu.Item>
              <Menu.Item key="quote">
                <SvgIcon size={14} icon={ic_create} />
                <span className="ee-header-menu-text">Write a Quote!</span>
              </Menu.Item>take-tour-btn'
            </Menu>*/}
            {/* </div> */}
{/* 
            {JSON.parse(readCookie("userData")).role === "admin" ? (
              <Menu.Item className="switch-to-admin-mode" id= "display-none"key="admin">
                <SvgIcon size={14} icon={userSecret} />
                <span className="ee-header-menu-text">
                  Switch to Admin Mode
                </span>
              </Menu.Item>
            ) : null} */}
          </Menu>
{/* to be tested on prod */}
{subdomain!=='indusind' ?<>{
  JSON.parse(readCookie("userData")).role === 'admin'? 
  (<div  onClick={this.handleClickChangeRoleButton.bind(this)} className="back-to-employee-mode sidebar-letsengage-admin-button" key="admin">
  <SvgIcon size={14} icon={userSecret} />
  <span className="ee-header-menu-text" style={{ fontSize: 12 }}>Switch to Admin Mode</span>
</div> ) :null
              }                
          <div id="powered-by-container-letsengage">

            {organisationData.ssoEnabled ? <> <span>
              <SvgIcon size={14} icon={ic_business} /> &ensp;
              <a
                      style={{ color: "#fff" }}
                      onClick={() => {
                        swal(`Welcome to this platform! We take your privacy seriously and want to make sure you understand how we collect, use, and protect your personal information.

1) Information Collection: We collect information that you provide to us directly, such as when you create an account or make a purchase. We may also collect information about your use of our platform, such as browsing history and search queries.

2) Use of Information: We use the information we collect to provide and improve our services, communicate with you, and personalize your experience on our platform. 

3) Information Protection: We use industry-standard security measures to protect your information and keep it safe. We also comply with all applicable laws and regulations regarding data protection.

4) Information Sharing: We do not share your information with third parties for any reasons

5) Your Privacy Rights: You have the right to access and correct your personal information at any time. You also have the right to opt-out of certain uses of your information, such as marketing communications.

If you have any questions or concerns about our privacy notice, please contact us on volunteering@lndusind.com. We are committed to protecting your privacy and providing you with a great user experience.`)
                      }}
                // href="https://www.letsendorse.com/privacy-policy"
                // target="_blank"
              >
                Privacy Notice
              </a>
            </span>
            <a href="https://www.letsendorse.com/" target="_blank">
              Powered by
                {/* <img src="/img/powered_by_le.png" alt="Powered By LetsEndorse" /> */}
                <img src="/img/le_white.png" alt="Powered By LetsEndorse" />
            </a></> : <><span>
              <SvgIcon size={14} icon={ic_business} /> &ensp;
              <a
                style={{ color: "#fff" }}
                href="https://www.letsendorse.com/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </a>
            </span>
            <a href="https://www.letsendorse.com/" target="_blank">
              Powered by
              <img src="/img/powered_by_le.png" alt="Powered By LetsEndorse" />
                  </a></>}
                  </div></>  :<>{
                                  JSON.parse(readCookie("userData")).role === 'admin'? 
                                  (<div  onClick={this.handleClickChangeRoleButton.bind(this)} className="back-to-employee-mode sidebar-admin-button" key="admin">
                                  <SvgIcon size={14} icon={userSecret} />
                                  <span className="ee-header-menu-text" style={{ fontSize: 12 }}>Switch to Admin Mode</span>
                                </div> ) :null
                                }
                                 <div id="powered-by-container">
{/* 
@Todo: add privacy notice logic
*/}


                          {organisationData.ssoEnabled ? <> <span>
                            <SvgIcon size={14} icon={ic_business} /> &ensp;
                            <a
                        style={{ color: "#fff" }}
                        onClick={() => {
                          swal({
                            // icon: 'info',
                            // title: 'Welcome to this platform! We take your privacy seriously and want to make sure you understand how we collect, use, and protect your personal information.',
                            width: '80%',
                            html: `<div style="font-size:var(--themeHeading2) !important; color:var(--themeColor); text-align: left !important;">Privacy Notice</div><br/><p style="text-align:left;    font-size: var(--themeBodyFontSize);">Welcome to this platform! We take your privacy seriously and want to make sure you understand how we collect, use, and protect your personal information.</p><p style="text-align:left; font-size:var(--themeBodyFontSize); margin: 2% 0%">1) Information Collection: We collect information that you provide to us directly, i.e. as when you create or update your profile/account. We may also collect information about your use of our platform, such as browsing history and search queries. <br/><br/>2) Use of Information: We use the information we collect to provide and improve our services, communicate with you, and personalize your experience on our platform. <br/><br/>3) Information Protection: We use industry-standard security measures to protect your information and keep it safe. We also comply with all applicable laws and regulations regarding data protection.<br/><br/>4) Information Sharing: We do not share your information with third parties for any reasons <br/><br/>5) Your Privacy Rights: You have the right to access and correct your personal information at any time. You also have the right to opt-out of certain uses of your information, such as marketing communications.<br/></p><p><p style="text-align:left; font-size: var(--themeBodyFontSize)">If you have any questions or concerns about our privacy notice, please contact us on oswin.dsouza@indusind.com with a CC to sattvam@indusind.com</p></p>`,
                            // html: `<div style="font-size:var(--themeHeading2) !important; color:var(--themeColor); text-align: left !important;">Privacy Notice</div><br/><p style="text-align:left;    font-size: var(--themeBodyFontSize);">Welcome to this platform! We take your privacy seriously and want to make sure you understand how we collect, use, and protect your personal information.</p><p style="text-align:left; font-size:var(--themeBodyFontSize); margin: 2% 0%">1) Information Collection: We collect information that you provide to us directly, such as when you create an account or make a purchase. We may also collect information about your use of our platform, such as browsing history and search queries.<br/><br/>2) Use of Information: We use the information we collect to provide and improve our services, communicate with you, and personalize your experience on our platform. <br/><br/>3) Information Protection: We use industry-standard security measures to protect your information and keep it safe. We also comply with all applicable laws and regulations regarding data protection.<br/><br/>4) Information Sharing: We do not share your information with third parties for any reasons <br/><br/>5)Your Privacy Rights: You have the right to access and correct your personal information at any time. You also have the right to opt-out of certain uses of your information, such as marketing communications.<br/></p><p><p style="text-align:left; font-size: var(--themeBodyFontSize)">If you have any questions or concerns about our privacy notice, please contact us on volunteering@lndusind.com. We are committed to protecting your privacy and providing you with a great user experience.</p></p>`,
                            // footer: '<note>If you have any questions or concerns about our privacy notice, please contact us on volunteering@lndusind.com. We are committed to protecting your privacy and providing you with a great user experience.</note>'
                          });
                          // swal(`<p class="privacy-policy-header">Welcome to this platform! We take your privacy seriously and want to make sure you understand how we collect, use, and protect your personal information.</p><span style="font-size:var(--themeBodyFontSize)>1) Information Collection: We collect information that you provide to us directly, such as when you create an account or make a purchase. We may also collect information about your use of our platform, such as browsing history and search queries.<br/>2) Use of Information: We use the information we collect to provide and improve our services, communicate with you, and personalize your experience on our platform. <br/>3) Information Protection: We use industry-standard security measures to protect your information and keep it safe. We also comply with all applicable laws and regulations regarding data protection.<br/>4) Information Sharing: We do not share your information with third parties for any reasons<br/>5) Your Privacy Rights: You have the right to access and correct your personal information at any time. You also have the right to opt-out of certain uses of your information, such as marketing communications.</span><br/><note>If you have any questions or concerns about our privacy notice, please contact us on volunteering@lndusind.com. We are committed to protecting your privacy and providing you with a great user experience.</note></p>`)
                        }
                        }
                              // href="https://www.letsendorse.com/privacy-policy"
                              // target="_blank"
                            >
                              Privacy Notice
                            </a>
                          </span>
                          <a href="https://www.letsendorse.com/" target="_blank">
                            Powered by
                              {/* <img src="/img/powered_by_le.png" alt="Powered By LetsEndorse" /> */}
                              <img src="/img/le_white.png" alt="Powered By LetsEndorse" />
                          </a></> : <><span>
                            <SvgIcon size={14} icon={ic_business} /> &ensp;
                            <a
                              style={{ color: "#fff" }}
                              href="https://www.letsendorse.com/privacy-policy"
                              target="_blank"
                            >
                              Privacy Policy
                            </a>
                          </span>
                          <a href="https://www.letsendorse.com/" target="_blank">
                            Powered by
                            <img src="/img/powered_by_le.png" alt="Powered By LetsEndorse" />
                              </a></>}
                            </div>
                          </> }




        </div>
      </div>)}}
      </OrgDataContext.Consumer>
    );
  }
}

Header.propTypes = {
  role: PropTypes.string,
};

function mapStateToProps(state) {

  return {
    role: state.RoleReducer,
   };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(action, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

