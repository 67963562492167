const initialState = {
	opportunities: [],
	selectedOpportunity: {}
};

const EEAdminOpportunityRecommendationsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_OPPORTUNITIES':
			return Object.assign({}, state, {
				opportunities: action.opportunities,
			});
		case 'UPDATE_SELECTED_OPPORTUNITY':
			return Object.assign({}, state, {
				selectedOpportunity: action.selectedOpportunity
			});
		default:
			return state
	}
}

export default EEAdminOpportunityRecommendationsReducer;