import React, { Component } from 'react';
import { Row, Col, Breadcrumb, Modal, Button, Tabs, Tab } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import swal from 'sweetalert2';
import Icon from 'react-icons-kit';
import moment from 'moment';

import { calendar } from 'react-icons-kit/fa/calendar';
import { bus } from 'react-icons-kit/fa/bus';
import { dotCircleO } from 'react-icons-kit/fa/dotCircleO';
import { tshirt } from 'react-icons-kit/ionicons/tshirt';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import 'sweetalert2/dist/sweetalert2.min.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

import * as action from '../../../../actions/EEDiscoverOpportunitiesAction';

import SingleOpportunityMap from '../DiscoverOpportunities/SingleOpportunity/SingleOpportunityMap';
import SingleOpportunityApplyModal from '../DiscoverOpportunities/SingleOpportunity/SingleOpportunityApplyModal';
import SingleOpportunityDiscussion from '../DiscoverOpportunities/SingleOpportunity/SingleOpportunityDiscussion';
import { check } from 'react-icons-kit/fa/check';
import { close } from 'react-icons-kit/fa/close';
import parse from 'html-react-parser';
import configs from '../../../../config/urlConfigs';
import { ToastContainer, toast } from "react-toastify";

const readCookie = require('../../../../cookie.js').readCookie;

class EESingleVolunteerHistory extends Component {
	constructor() {
		super();
		this.state = {
			showReserveModal: false,
			showViewPeopleModal: false,
			address: '',
			key: 1,
			feedbacks: [],
			facilities: [],
			safetyProtocol: '',
			dos: [],
			projectLeaderContact: '',
			registrationsAllowed: 0,
			helpfulDocument: null,
			backoutWindow: 0

		};
		this.closeReserveModal = this.closeReserveModal.bind(this);
		this.openReserveModal = this.openReserveModal.bind(this);
		this.closeViewPeopleModal = this.closeViewPeopleModal.bind(this);
		this.openViewPeopleModal = this.openViewPeopleModal.bind(this);
	}

	closeReserveModal() {
		this.setState({ showReserveModal: false });
	}

	openReserveModal() {
		this.setState({ showReserveModal: true });
	}

	closeViewPeopleModal() {
		this.setState({ showViewPeopleModal: false });
	}

	openViewPeopleModal() {
		this.setState({ showViewPeopleModal: true });
	}

	notifyError = (text) => toast.error(text);
	notifySuccess = (text) => toast.success(text);

	componentDidMount() {
		if (!this.props.selectedOpportunity.hasOwnProperty('customUrl')) {
			let path = this.props.match.params.name;
			fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + path, {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
				.then(function (data) {
					this.setState({
						facilities: data.data.facilities ? data.data.facilities : [],
						safetyProtocol: data.data.safetyProtocol ? data.data.safetyProtocol : '',
						dos: data.data.dos ? data.data.dos : [],
						projectLeaderContact: data.data.projectLeaderContact ? data.data.projectLeaderContact : '',
						registrationsAllowed: data.data.registrationsAllowed ? data.data.registrationsAllowed : 0,
						helpfulDocument: data.data.helpfulDocument ? data.data.helpfulDocument : null,
						backoutWindow: data.data.backoutWindow ? data.data.backoutWindow : 0,
					})
					if (data.hasOwnProperty('data')) {
						for (let i = 0; i < data.data.length; i++) {
							data.data.comments[i]['showReply'] = false;
						}
						this.props.actions.updateSelectedOpportunity(data['data']);
						if (data.data.address2) {
							this.setState({
								address: data.data.address1 + ', ' + data.data.address2 + ', ' + data.data.city + ', ' + data.data.state + ', ' + data.data.country + '. ' + data.data.pincode
							})
						} else {
							this.setState({
								address: data.data.address1 + ', ' + data.data.city + ', ' + data.data.state + ', ' + data.data.country + '. ' + data.data.pincode
							})
						}
					} else {
						console.log('error');
					}
				}.bind(this));

			fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + path + '/feedback', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				}
			}).then((data) => data.json())
				.then(function (data) {
					this.setState({ feedbacks: data.data });
				}.bind(this));
		}
	}

	clearSelectedOpportunity() {
		let projectJSON = {};
		this.props.actions.updateSelectedOpportunity(projectJSON);
	}

	checkEmpty(cell, row) {
		if (cell === '') return 'N/A';
		else return cell;
	}

	backout() {
		swal({
			title: 'Are you sure you want to back out of this opportunity?',
			text: 'Please ensure that you find a friend/colleague to sign-up instead, to fill the gap.',
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, I backout!',
			cancelButtonText: 'Cancel'
		}).then((result) => {
			if (result) {
				fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/' + this.props.selectedOpportunity.id + '/backout', {
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then(function (response) {
					return response.json();
				}).then(function (data) {
					if (data.status === 'ok') {
						window.location.reload();
					}
				}.bind(this));
			}
		});
	}

	handleSelect(key) {
		this.setState({ key });
	}

	goBack() {
		// if (document.referrer !== '' && document.referrer !== window.location.href) window.location.href = document.referrer;
		// else 
		window.location.pathname = '/' + window.location.pathname.split('/')[1];
	}

	openLocationInMap(url) {
		window.open(url, '_blank');
	}

	render() {
		if (this.props.selectedOpportunity.hasOwnProperty('title')) {
			return (
				<div className="ee-page-container single-opportunity-page">
					<DocumentTitle title={this.props.selectedOpportunity.title} />
					<Row>
						{/* <Col md={12}>
							<Breadcrumb>
								<Breadcrumb.Item onClick={this.clearSelectedOpportunity.bind(this)}
									href={this.props.location.pathname.match('/admin/') === null ? "/discover-opportunities" : "/admin/volunteer-management"}>
									Volunteering Opportunities
								</Breadcrumb.Item>
								<Breadcrumb.Item active>
									{this.props.selectedOpportunity.title}
								</Breadcrumb.Item>
							</Breadcrumb>
						</Col> */}
						<Col md={12} className="campTitle mb10">
							<span className="title-name single-project-title-name">
								<i className="fa fa-chevron-left mr5" onClick={this.goBack}></i>
								{this.props.selectedOpportunity.title}
							</span>
							<p className="eyebrow-text left">
								<span className="camp-location" onClick={this.openLocationInMap.bind(this, this.props.selectedOpportunity.addressUrl)}>
									<i className="fa fa-map-marker-alt"></i>
									<span>{this.state.address}</span>
								</span>
							</p>
						</Col>
						<Col md={12} className="campContainer">
							<div className="col-md-8 opportunity-desc">
								<Col className="mediaContainer" style={{ margin: '15px 0' }}>
									<div className="img-container" style={{ backgroundImage: `${'url(' + this.props.selectedOpportunity.image + ')'}`, backgroundSize: 'cover', width: '100%', height: '350px', borderRadius: '7px' }}>
									</div>
								</Col>
								<Row style={{ marginBottom: '30px', borderRadius: '7px', border: 'solid 1px #eee', padding: '10px' }}>
									{/* <Col md={12} className="campTitle mb10">
										<span className="title-name">
											{this.props.selectedOpportunity.title}
										</span>
									</Col> */}
									{/* <Col md={12} className="mediaContainer"> */}
									{/* <Col className="mediaContainer">
										<div className="img-container" style={{ backgroundImage: `${'url(' + this.props.selectedOpportunity.image + ')'}`, backgroundSize: 'cover', width: '100%', height: '350px', borderRadius: '7px' }}>
										</div>
									</Col> */}
									{/* <Col md={12}> */}
									<Col>
										<p className="representation-text">*The visual content above is for representational purpose and may not correspond to this project. 123545 </p>
									</Col>
									{/* <Col md={12} className="eyebrow"> */}
									{/* <Col className="eyebrow">
										<p className="eyebrow-text left">
											<span className="camp-location">
												<i className="fa fa-map-marker" style={{ color: '#212121' }}></i> {this.state.address}
											</span>
										</p>
									</Col> */}

									{/* <Col md={12} className="desc-container desc-style"> */}
									<Row>
										<Col md={1} />
										<Col md={3}>
											<div class="form-group form-check">
												<Icon
													size={16}
													icon={this.state.facilities.includes('toilet') ? check : close}
													style={{ color: this.state.facilities.includes('toilet') ? "green" : 'red' }}
													className="list-icon"
												/>
												<label class="form-check-label" style={{ marginLeft: 10 }} for="toilet">Toilet</label>
											</div>
										</Col >
										<Col md={3}>
											<div class="form-group form-check">
												<Icon
													size={16}
													icon={this.state.facilities.includes('drinkingWater') ? check : close}
													style={{ color: this.state.facilities.includes('drinkingWater') ? "green" : 'red' }}
													className="list-icon"
												/>
												<label class="form-check-label" style={{ marginLeft: 10 }} for="drinkingWater">Drinking Water</label>
											</div>
										</Col >

										<Col md={3}>
											<div class="form-group form-check">
												<Icon
													size={16}
													icon={this.state.facilities.includes('mealOrSnack') ? check : close}
													style={{ color: this.state.facilities.includes('mealOrSnack') ? "green" : 'red' }}
													className="list-icon"
												/>
												<label class="form-check-label" style={{ marginLeft: 10 }} for="mealOrSnack">Meal/Snacks</label>
											</div>
										</Col >
									</Row>
									<Col className="desc-container desc-style">
										<div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
											<span className='dot' style={{ width: '8px', height: '8px', borderRadius: '50px' }}></span>
											<h2 style={{ fontSize: '22px', fontWeight: 'normal', color: '#5d5d5d', marginLeft: '8px' }}>About the Project:</h2>
										</div>
										<p style={{ padding: '0 0 0 15px', color: '#707070' }}>{this.props.selectedOpportunity.description}</p>
									</Col>

									{/* <Col md={12} className="desc-container desc-style"> */}
									<Col className="desc-container desc-style">
										<div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
											<span className='dot' style={{ width: '8px', height: '8px', borderRadius: '50px' }}></span>
											<h2 style={{ fontSize: '22px', fontWeight: 'normal', color: '#5d5d5d', marginLeft: '8px' }}>Expected Impact:</h2>
										</div>
										{/* <p className="expected-impact-text single-opportunity-page-expected-impact-heading">Expected Impact</p> */}
										<p style={{ padding: '0 0 0 15px', color: '#707070' }}>{this.props.selectedOpportunity.expectedImpact}</p>
									</Col>
									{this.state.safetyProtocol && <Col className="desc-container desc-style">
										<div
											style={{
												display: "flex",
												alignItems: "center",
												marginBottom: "8px",
											}}
										>
											<span
												className="dot"
												style={{
													width: "8px",
													height: "8px",
													borderRadius: "50px",
												}}
											></span>
											<h2
												style={{
													fontSize: "22px",
													fontWeight: "normal",
													color: "#5d5d5d",
													marginLeft: "8px",
												}}
											>
												Safety Protocol:
											</h2>
										</div>
										<p style={{ padding: "0 0 0 15px", color: "#707070" }}>
											{parse(this.state.safetyProtocol)}
										</p>
									</Col>}
									{this.state.helpfulDocument && <Col className="desc-container desc-style">
										<div
											style={{
												display: "flex",
												alignItems: "center",
												marginBottom: "8px"
											}}
										>
											<h2
												style={{
													fontSize: "22px",
													fontWeight: "normal",
													color: "#5d5d5d",
													marginLeft: "8px",
												}}
											>
												<a href={this.state.helpfulDocument} target="_blank">
													Helpful Document
												</a>
											</h2>
										</div>
									</Col>}
								</Row>

								<Row>
									<Tabs activeKey={this.state.key} onSelect={this.handleSelect.bind(this)} id="controlled-tab-example">
										<Tab eventKey={1} title="Discussion" tabClassName="hello" bsClass="test">
											<Col md={12} className="mt20 mb20">
												<SingleOpportunityDiscussion selectedOpportunity={this.props.selectedOpportunity}
													updateSelectedOpportunity={this.props.actions.updateSelectedOpportunity}
													volunteerOpportunityId={this.props.selectedOpportunity.id}
													comments={this.props.selectedOpportunity.comments} />
											</Col>
										</Tab>
										<Tab eventKey={2} title="Feedback">
											<Col md={12} className="mt20 mb20">
												<div className="ee-discussion-container mb10">
													{this.state.feedbacks.map(function (feedback, index) {
														return (
															<Col md={12} className="comments-sec" key={index}>
																<ul>
																	<li className="single-comment">
																		<Row>
																			<Col md={12}>
																				<Row className="flex">
																					<Col className="comment" md={12}>
																						<div className="just-flex">
																							<img src="/img/user.svg" style={{ width: 50, height: 50, background: '#e0e0e0', padding: 3 }}
																								alt="" className="img-circle img-responsive mr10" />
																							<div className="comment-writer flex-col">
																								<span className="writer">{feedback.fullName}</span>
																								{feedback.hasOwnProperty('date') ? (
																									<span className="writer-date">{moment(feedback.date).format('DD/MM/YYYY h:mm a')}</span>
																								) : (null)}
																							</div>
																						</div>
																						<div className="comment-data">
																							{feedback.experience}
																						</div>
																					</Col>
																				</Row>
																			</Col>
																		</Row>
																	</li>
																</ul>
															</Col>
														)
													}.bind(this))}
												</div>
											</Col>
										</Tab>
									</Tabs>
								</Row>
							</div>

							<div className="col-md-4 pad30" style={{ paddingRight: '0' }}>
								<Col md={12} className="opportunity-details-snapshot">
									<div className="row budget-block">
										<div className="col-md-12 goal-detail">
											<div className="text-center">
												<Icon icon={calendar} className="list-icon" />
												<span style={{ fontWeight: 'bold' }}>{moment(this.props.selectedOpportunity.date,'DD/MM/YYYY').format('ddd, DD MMM YYYY')}</span>
											</div>
										</div>
									</div>

									<div className="row snapshot-container">
										<div className="col-md-12 whiteBg snapshot-item black-header text-center">
											<div className="pad-10">
												<span className="number opportunity-number">{this.props.selectedOpportunity.startTime}</span>
												<span style={{ fontWeight: 'bold' }}> To </span>
												<span className="number opportunity-number">{this.props.selectedOpportunity.endTime}</span>
											</div>
										</div>

										{this.props.selectedOpportunity.status === 'Live' ? (
											<div className="col-md-12 whiteBg snapshot-item black-header text-center">
												<div className="top5">
													<span style={{ fontWeight: 'bold' }}><span className="le-color" style={{ fontWeight: 'bold' }}>
														{/* {this.props.selectedOpportunity.volunteersRequired - this.props.selectedOpportunity.attendees.length}/{this.props.selectedOpportunity.volunteersRequired} */}
														{this.props.selectedOpportunity.volunteersRequired - (this.props.selectedOpportunity.attendees.length + this.props.selectedOpportunity.friends.length)}
														/
														{
															this.props.selectedOpportunity
																.volunteersRequired
														}
													</span> slots available</span>
												</div>
											</div>
										) : (null)}

										{(() => {
											if (this.props.selectedOpportunity.status !== 'Completed' && this.props.selectedOpportunity.config.hasOwnProperty('isTransportRequired') && this.props.selectedOpportunity.config.isTransportRequired) {
												return (
													<div className="col-md-12 whiteBg snapshot-item le black-header mt10" style={{ padding: '0', color: 'fff !important' }}>
														<div className="flex-center" style={{ padding: '2px 0' }}>
															<Icon size={18} icon={bus} style={{ color: '#fff' }} className="list-icon" />
															<span style={{ fontSize: '12px', color: "#fff" }} className="ml5">Transport Provided</span>
														</div>
													</div>
												)
											}
										})()}
										{(() => {
											if (this.props.selectedOpportunity.status !== 'Completed' && this.props.selectedOpportunity.config.hasOwnProperty('isTshirtProvided') && this.props.selectedOpportunity.config.isTshirtProvided) {
												return (
													<div className="col-md-12 whiteBg snapshot-item le black-header mt10" style={{ padding: '0', color: 'fff !important' }}>
														<div className="flex-center" style={{ padding: '2px 0' }}>
															<Icon size={18} icon={tshirt} style={{ color: '#fff' }} className="list-icon" />
															<span style={{ fontSize: '12px', color: "#fff" }} className="ml5">Tshirt Provided</span>
														</div>
													</div>
												)
											}
										})()}

										<div className="col-md-12 whiteBg snapshot-item black-header text-center mt10">
											{(() => {
												if (this.props.selectedOpportunity.isOpen) {
													if (this.props.selectedOpportunity.isUserAttendee) {
														return (
															<div className="reserved-and-backout-container">
																<button className="btn btn-endorse cancel" disabled>RESERVED</button>
																<a className="backout-text" onClick={this.backout.bind(this)}>I want to backout</a>
															</div>
														);
													} else {
														return (
															<button className="btn btn-endorse" onClick={this.openReserveModal}>RESERVE MY SPOT</button>
														);
													}
												} else {
													if (this.props.selectedOpportunity.status === 'Live') {
														return (
															<button className="reserve-seat-btn" disabled="disabled">ALL SPOTS BOOKED</button>
														)
													} else {
														return (
															<button className="reserve-seat-btn" disabled="disabled">{this.props.selectedOpportunity.status}</button>
														)
													}
												}
											})()}
										</div>
									</div>
									{this.state.registrationsAllowed > 0 && <Row>
										<Col md={3} />
										<Col>
											{/* <h5 style={{ marginBottom: 10 }}>upto {this.state.registrationsAllowed} family members/friends allowed</h5> */}
											<h5 style={{ marginBottom: 10 }}>Friends/ Family members allowed</h5>
										</Col>
									</Row>}
								</Col>

								{this.state.projectLeaderContact ? (
									<Col md={12} className="opportunity-details-snapshot">
										<div className="row budget-block">
											<div className="expected-impact-text" style={{ textTransform: 'none' }}>
												Emergency/ Project Leader Contact
											</div>
										</div>
										<div className="row snapshot-container activities-undertaken">
											<div className="activity-list">
												<div className="single-activity-list">
													<span style={{ fontWeight: "bold" }}>
														{this.state.projectLeaderContact}
													</span>
												</div>
											</div>
										</div>
									</Col>
								) : null}

								{this.props.selectedOpportunity.activities.length > 0 ? (
									<Col md={12} className="opportunity-details-snapshot">
										<div className="row budget-block">
											<div className="expected-impact-text">Activities Undertaken</div>
										</div>
										<div className="row snapshot-container activities-undertaken">
											<div className="activity-list">
												{this.props.selectedOpportunity.activities.map(function (activity, i) {
													return (
														<div className="single-activity-list" key={i}>
															<Icon icon={dotCircleO} className="single-activity-icon le-color" />
															<span style={{ fontWeight: 'bold' }}>{activity.name}</span>
														</div>
													);
												})}
											</div>
										</div>
									</Col>
								) : (null)}

								{this.state.dos.length > 0 ? (
									<Col md={12} className="opportunity-details-snapshot">
										<div className="row budget-block">
											<div className="expected-impact-text" style={{ textTransform: 'none' }}>
												DOs and DON'Ts
											</div>
										</div>
										<div className="row snapshot-container activities-undertaken">
											<div className="activity-list">
												{this.state.dos.map(
													function (item, i) {
														return (
															<div className="single-activity-list" key={i}>
																<Icon
																	icon={dotCircleO}
																	className="single-activity-icon le-color"
																/>
																<span style={{ fontWeight: "bold" }}>
																	{item.name}
																</span>
															</div>
														);
													}
												)}
											</div>
										</div>
									</Col>
								) : null}

								<Col md={12} className="opportunity-details-snapshot">
									<SingleOpportunityMap selectedOpportunity={this.props.selectedOpportunity} />
								</Col>

								{this.props.selectedOpportunity.attendees.length ? (
									<Col md={12} className="opportunity-details-snapshot attendees-container">
										<div className="row budget-block">
											<div className="expected-impact-text">Participants</div>
										</div>
										{this.props.selectedOpportunity.attendees.map(function (attendee, index) {
											return (
												<div className="attendee-container">
													<div className="attendee-prof-pic" style={attendee.profilePicture ? { backgroundImage: 'url("' + attendee.profilePicture + '")' } : { backgroundImage: 'url("/img/user.png")' }}></div>
													<div className={attendee.department || attendee.hoursClocked !== "N/A" ? "user-details-container" : "user-details-container center"}>
														<div className="user-name">{attendee.fullName}</div>
														{attendee.department ? (
															<div className="user-department">{attendee.department}</div>
														) : (null)}
														{attendee.hoursClocked !== "N/A" ? (
															<div className="user-hours-clocked">{attendee.hoursClocked}</div>
														) : (null)}
													</div>
												</div>
											)
										}.bind(this))}
									</Col>
								) : (null)}
							</div>
						</Col>
					</Row>

					<SingleOpportunityApplyModal volunteerOpportunity={this.props.selectedOpportunity}
						showReserveModal={this.state.showReserveModal} openReserveModal={this.openReserveModal}
						notifyError={this.notifyError}
						updateSelectedOpportunity={this.props.actions.updateSelectedOpportunity}
						closeReserveModal={this.closeReserveModal} />

					<Modal show={this.state.showViewPeopleModal} onHide={this.closeViewPeopleModal} className="square-modal view-people-coming-modal">
						<Modal.Header closeButton>
							<Modal.Title>{this.props.selectedOpportunity.status === 'Completed' ? 'PEOPLE WHO HAD PARTICIPATED (' : 'PEOPLE WHO HAVE PARTICIPATED ('}{this.props.selectedOpportunity.attendees.length}/{this.props.selectedOpportunity.volunteersRequired})</Modal.Title>
						</Modal.Header>
						<Modal.Body bsClass="pad-0-15">
							<Row className="m0">
								<BootstrapTable data={this.props.selectedOpportunity.attendees} hover>
									<TableHeaderColumn isKey dataField='id' hidden>ID</TableHeaderColumn>
									<TableHeaderColumn dataField='employeeId' dataFormat={this.checkEmpty}>Employee ID</TableHeaderColumn>
									<TableHeaderColumn width='200px' dataField='fullName' dataFormat={this.checkEmpty}>Name</TableHeaderColumn>
									<TableHeaderColumn dataField='department' dataFormat={this.checkEmpty}>Department</TableHeaderColumn>
									<TableHeaderColumn width='140px' dataField='mobileNo' dataFormat={this.checkEmpty}>Mobile</TableHeaderColumn>
									{this.props.selectedOpportunity.status === 'Completed' ? (
										<TableHeaderColumn width='120px' dataField='hoursClocked'>Hours Clocked</TableHeaderColumn>
									) : (null)}
								</BootstrapTable>
							</Row>
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={this.closeViewPeopleModal} className="btn-sq btn-cancel">Close</Button>
						</Modal.Footer>
					</Modal>
					{/*Modal End*/}
				</div >
			);
		} else {
			return null;
		}
	}
}

EESingleVolunteerHistory.propTypes = {
	selectedOpportunity: PropTypes.object,
	commentText: PropTypes.string,
	commentImage: PropTypes.array,
	toggleCommentPost: PropTypes.bool,
	subCommentText: PropTypes.string,
	commentId: PropTypes.string,
};

function mapStateToProps(state) {
	return {
		selectedOpportunity: state.EEDiscoverOpportunity.selectedOpportunity,
		commentText: state.EEDiscoverOpportunity.commentText,
		commentImage: state.EEDiscoverOpportunity.commentImage,
		toggleCommentPost: state.EEDiscoverOpportunity.toggleCommentPost,
		subCommentText: state.EEDiscoverOpportunity.subCommentText,
		commentId: state.EEDiscoverOpportunity.commentId,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EESingleVolunteerHistory);
