import React, {Component} from 'react';
import {Row, Col, Button, Modal} from 'react-bootstrap';
import {arrayMove} from 'react-sortable-hoc';
import $ from 'jquery';
import 'jquery-ui-bundle';
import swal from 'sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';
import configs from '../../../../config/urlConfigs.js';

const readCookie = require('../../../../cookie.js').readCookie;

export default class ImportMemberModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
      showSubmitButton: false,
      systemHeaders: null,
      showSubmittingLoader: false,
      importedMemberData: null,
      sheetUrl: ''
		};
    this.uploadDonorFile = this.uploadDonorFile.bind(this);
    this.showSubmitButton = this.showSubmitButton.bind(this);
    this.updateSystemHeaders = this.updateSystemHeaders.bind(this);
    this.submitHeaderMappings = this.submitHeaderMappings.bind(this);
	}

  showSubmitButton() {
    this.setState({ showSubmitButton: true });
  }

  updateSystemHeaders(systemHeaders) {
    this.setState({ systemHeaders });
  }

  submitHeaderMappings() {
    this.setState({ showSubmittingLoader: true });
    let systemHeaders = this.state.systemHeaders;

    let mappings = {};

    Object.keys(systemHeaders).map(function(value) {
      let systemHeader = systemHeaders[value];
      let key_used_in_csv = $('.select-donor-data-headers').find('.system-headers-div').eq(value).find('ul > li').html()
      let system_key_array = Object.keys(systemHeader);
      let system_key = system_key_array[0];
      if(key_used_in_csv !== undefined) mappings[system_key] = key_used_in_csv;
    });

    let data = {};
    data['mappings'] = mappings;
    data['sheetUrl'] = this.state.sheetUrl;

    fetch(configs.BACKEND_API_URL + '/list/' + this.props.listId + '/import-employees', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Auth': JSON.parse(readCookie('access_token')).access_token
      },
      body: JSON.stringify(data)
    }).then((response) => response.json())
    .then(function(data) {
      this.props.hideImportMemberModal();
      this.setState({ showSubmittingLoader: false });
      swal({
        text: data['message'],
        type: 'success',
      });
    }.bind(this));
  }

  showDonorsFileUploadButton() {
    $('#uploadDonorsFile').removeClass('hidden');
    $('#cancelUploadMembersFile').removeClass('hidden');
  }

  uploadDonorFile() {
    var memberFile = $('input#importMembersFile');

    var data = new FormData();
    data.append('data_sheet', memberFile[0].files[0]);

    fetch(configs.BACKEND_API_URL + '/get-sheet-meta', {
      method: 'POST',
      headers: {
        'Auth': JSON.parse(readCookie('access_token')).access_token
      },
      body: data
    }).then((response) => response.json())
    .then(function(data) {
      if(data['status'] === 'ok') {
        this.updateSystemHeaders(data['data']['system_headers']);
        this.setState({
          importedMemberData: data['data'],
          sheetUrl: data['data']['url'],
          detected_headers: data['data']['detected_headers'],
          system_headers: data['data']['system_headers'],
        }, function() {
          let detected_headers = this.state.detected_headers;

          var link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = '//code.jquery.com/ui/1.12.1/themes/base/jquery-ui.css';
          document.head.appendChild(link);

          $(document).ready(function() {
            $.getScript('//code.jquery.com/ui/1.12.1/jquery-ui.js', function() {
              $("ul.system-header").sortable({
                placeholder: 'placeholder',
                connectWith: "ul.system-header",
                receive: function(event, ui) {
                  var $this = $(this);
                  if ($this.children('li').length > 1 && $this.hasClass('max-header-one')) {
                    $(ui.sender).sortable('cancel');
                  }
                }
              });
              $("ul.system-header").disableSelection();

              for(var i = 0; i < detected_headers.length; i++) {
                let detected_header = detected_headers[i];
                if(detected_header.toLowerCase().match('name') !== null) {
                  if($('#system-headers-fullName ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-fullName ul.system-header');
                } else if(detected_header.toLowerCase().match('email') !== null) {
                  if($('#system-headers-email ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-email ul.system-header');
                } else if(detected_header.toLowerCase().match('phone') !== null || detected_header.toLowerCase().match('mobile') !== null) {
                  if($('#system-headers-mobileNo ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-mobileNo ul.system-header');
                } else if(detected_header.toLowerCase().match('status') !== null) {
                  if($('#system-headers-status ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-status ul.system-header');
                } else if(detected_header.toLowerCase().match('gender') !== null || detected_header.toLowerCase().match('sex') !== null) {
                  if($('#system-headers-gender ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-gender ul.system-header');
                } else if(detected_header.toLowerCase().match('designation') !== null || detected_header.toLowerCase().match('role') !== null || detected_header.toLowerCase().match('post') !== null) {
                  if($('#system-headers-designation ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-designation ul.system-header');
                } else if((detected_header.toLowerCase().match('emp') !== null || detected_header.toLowerCase().match('employee') !== null) && (detected_header.toLowerCase().match('id') !== null)) {
                  if($('#system-headers-employeeId ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-employeeId ul.system-header');
                } else if(detected_header.toLowerCase().match('department') !== null) {
                  if($('#system-headers-department ul.system-header').html() === "") $('.SortableList.system-header').find('li#csv-headers-' + i).detach().appendTo('#system-headers-department ul.system-header');
                }
              }
            });
          });
        }.bind(this));
        $('#uploadDonorsFile').addClass('hidden');
        $('#cancelUploadMembersFile').addClass('hidden');
        $('input#importMembersFile').val('');
        this.showSubmitButton();
      }
    }.bind(this));
  }

  cancelUploadDonorFile() {
    $('#uploadDonorsFile').addClass('hidden');
    $('#cancelUploadMembersFile').addClass('hidden');
    $('input#importMembersFile').val('');
  }

  onSortEnd({oldIndex, newIndex}) {
    this.setState({ detected_headers: arrayMove(this.state.detected_headers, oldIndex, newIndex) });
  }

	render() {
		return (
			<Modal bsSize="large" className="import-member-modal" show={this.props.importMemberModalVisibility} onHide={this.props.hideImportMemberModal}>
        <Modal.Header>
          <Modal.Title className="modal-header-custom">
            Import Employee
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {(() => {
              if (this.state.importedMemberData === null) {
                return (
                  <Row>
                    <Col md={12} className="mt10 mb10">
                      <input id="importMembersFile" className="mt10 mb10 col-md-offset-4 col-md-4" type="file" onChange={this.showDonorsFileUploadButton} />
                    </Col>
                    <Col md={12} className="mt10">
                      <Button id="uploadDonorsFile" className="btn-success react-bs-table-add-btn col-md-offset-2 col-md-3 hidden" onClick={this.uploadDonorFile}>Upload</Button>
                      <Button id="cancelUploadMembersFile" className="btn-danger react-bs-table-add-btn col-md-offset-2 col-md-3 hidden" onClick={this.cancelUploadDonorFile}>Cancel</Button>
                    </Col>
                  </Row>
                );
              } else if(this.state.importedMemberData !== null) {
                return (
                  <Col md={12} className="select-donor-data-headers">
                    <Col md={12}><p className="modal-sec-title">CSV Headers</p></Col>
                    <div id="root">
                      <ul className="SortableList system-header" onSortEnd={this.onSortEnd.bind(this)} helperClass="SortableHelper">
                        {this.state.detected_headers.map(function (data, index) {
                          return (
                            <li id={'csv-headers-' + index} className="SortableItem" key={index} data-index={index}>
                              {data}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <Col md={12}>
                      <p className="modal-sec-title">Our Headers</p>
                      <p>Note: Drag & Drop the csv headers into our headers to map them.</p>
                    </Col>
                    {Object.keys(this.state.system_headers).map(function(key) {
                      var system_header = this.state.system_headers[key];
                      var value = Object.keys(system_header);
                      return (
                        <div id={'system-headers-' + value} className="system-headers-div" key={value}>
                          <span>{system_header[value]}<i className="fa fa-arrows-h"></i></span>
                          <ul className="system-header max-header-one"></ul>
                        </div>
                      );
                    }.bind(this))}
                  </Col>
                );
              }
            })()}
          </Row>
        </Modal.Body>
        <Modal.Footer className="footer-center">
          <Button disabled={this.state.showSubmittingLoader} className="btn-danger mr5 ml5" onClick={this.props.hideImportMemberModal}>Cancel</Button>
        	{this.state.showSubmitButton ? (
            !this.state.showSubmittingLoader ? (
              <Button className="mr5 ml5" onClick={this.submitHeaderMappings.bind(this)}>
                Submit
              </Button>
            ) : (
              <Button disabled className="mr5 ml5">
                Submitting
                <i className="fa fa-spin fa-spinner"></i>
              </Button>
            )
          ) : (null)}
        </Modal.Footer>
      </Modal>
		)
	}
}