import React, {Component} from 'react';

const readCookie = require('../../../../../../cookie.js').readCookie;

export default class Initiate extends Component {
	constructor() {
		super();
		this.state = {
			data: null
		}
	}

	componentDidMount() {
		let data = localStorage.getItem('data');
		data = JSON.parse(data);
		this.setState({	data }, this.onSubmit);
	}

	onSubmit() {
		this.refs.paymentForm.submit();
	}

	render () {
		if(this.state.data ) {
			return (
				<div>
					<div id="main">
						<div id="message_field">
							<span id="message"> </span>
							<i className="fa fa-spinner fa-spin fa-2x loader le-color"> </i>
							<h4>Redirecting to Payment Gateway! <br />Please don't refresh or press the back button.</h4>
						</div>
					</div>
					<form ref="paymentForm" id="paymentForm" action={this.state.data.formPostUrl} method="POST">
						<input type="hidden" id="merchantTxnId" name="merchantTxnId" value={this.state.data.merchantTxnId} />
						<input type="hidden" id="orderAmount" name="orderAmount" value={this.state.data.orderAmount} />
						<input type="hidden" id="currency" name="currency" value={this.state.data.currency} />
						<input type="hidden" name="returnUrl" value={this.state.data.returnUrl} />
						<input type="hidden" id="secSignature" name="secSignature" value={this.state.data.securitySignature} />

						<input type="hidden" id="projectId" name="customParams[0].name" value="projectId" />
						<input type="hidden" id="projectId" name="customParams[0].value" value={this.state.data.projectId} />

						{
							this.state.data.firstName ? (
								<input type="hidden" name="firstName" value={this.state.data.firstName} />
							) : (
								null
							)
						}

						{
							this.state.data.lastName ? (
								<input type="hidden" name="lastName" value={this.state.data.lastName} />
							) : (
								null
							)
						}

						{
							this.state.data.email ? (
								<input type="hidden" name="email" value={this.state.data.email} />
							) : (
								null
							)
						}

						{
							this.state.data.addressStreet1 ? (
								<input type="hidden" name="addressStreet1" value={this.state.data.addressStreet1} />
							) : (
								null
							)
						}

						{
							this.state.data.addressStreet2 ? (
								<input type="hidden" name="addressStreet2" value={this.state.data.addressStreet2} />
							) : (
								null
							)
						}

						{
							this.state.data.addressCity ? (
								<input type="hidden" name="addressCity" value={this.state.data.addressCity} />
							) : (
								null
							)
						}

						{
							this.state.data.addressState ? (
								<input type="hidden" name="addressState" value={this.state.data.addressState} />
							) : (
								null
							)
						}

						{
							this.state.data.addressZip ? (
								<input type="hidden" name="addressZip" value={this.state.data.addressZip} />
							) : (
								null
							)
						}

						{
							this.state.data.phoneNumber ? (
								<input type="hidden" name="phoneNumber" value={this.state.data.mobileNo} />
							) : (
								null
							)
						}

					</form>
				</div>
			);
		} else {
			return null
		}
	}
}