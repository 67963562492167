import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import moment from 'moment';
import swal from 'sweetalert2';

import AddBulletinModal from './AddBulletinModal';

import 'sweetalert2/dist/sweetalert2.min.css';
import configs from '../../../../config/urlConfigs';

const readCookie = require('../../../../cookie.js').readCookie;

export default class Bulletin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bulletins: null,
			bulletinToEdit: null,
			editBulletinModalVisibility: false
		};
	}

	hideAddBulletinModal() {
		this.setState({ addBulletinModalVisibility: false }, function() {
			fetch(configs.BACKEND_API_URL + '/post/admin-discover', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				this.setState({ bulletins: data.posts });
			}.bind(this));
		}.bind(this));
	}

	showAddBulletinModal() {
		this.setState({ addBulletinModalVisibility: true });
	}

	showEditBulletinModal(bulletin) {
		this.setState({ bulletinToEdit: bulletin, editBulletinModalVisibility: true });
	}

	hideEditBulletinModal() {
		this.setState({ bulletinToEdit: null, editBulletinModalVisibility: false }, function() {
			fetch(configs.BACKEND_API_URL + '/post/admin-discover', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				this.setState({ bulletins: data.posts });
			}.bind(this));
		});
	}

	deleteBulletin(bulletinId) {
		swal({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, Delete!',
			cancelButtonText: 'No, Cancel!',
			confirmButtonClass: 'btn',
			cancelButtonClass: 'btn',
		}).then(function (result) {
			if(result) {
				fetch(configs.BACKEND_API_URL + '/post/' + bulletinId, {
					method: "DELETE",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					}
				}).then(function(response) {
					return response.json();
				}).then(function(data) {
					if(data.status === 'ok') {
						swal(
							'Awesome!',
							'You have successfully updated the bulletin.',
							'success'
						)
						fetch(configs.BACKEND_API_URL + '/post/admin-discover', {
							method: "GET",
							headers: {
								'Auth': JSON.parse(readCookie('access_token')).access_token
							},
						}).then(function(response) {
							return response.json();
						}).then(function(data) {
							this.setState({ bulletins: data.posts });
						}.bind(this));
					} else {
						swal(
							'Oops!',
							'The bulletin was not updated. Please try again!',
							'error'
						)
					}
				}.bind(this));
			}
		}.bind(this));
	}

	componentDidMount() {
		fetch(configs.BACKEND_API_URL + '/post/admin-discover', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function(response) {
			return response.json();
		}).then(function(data) {
			this.setState({ bulletins: data.posts });
		}.bind(this));
	}

	render () {
		if(this.state.bulletins !== null) {
			return (
				<div className="ee-page-container bulletin-page">
					<Row>
						<Col md={12} className="text-center">
							<h2 className="ee-page-title">Bulletin</h2>
						</Col>

						{ this.state.bulletins && this.state.bulletins.length !== 0 ? (
							<Col md={12} className="add-new-bulletin-container mt20 mb10">
								<button className="btn add-new-bulletin" onClick={this.showAddBulletinModal.bind(this)}>
									<i className="fa fa-plus"></i>
									Add New Bulletin
								</button>
							</Col>
						) : (null)}

						<Col md={12} className="bulletins-container">
							{this.state.bulletins.map(function(bulletin, index) {
								var text = bulletin.text;
								var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
								var text1 = text.replace(exp, "<a target='_blank' href='$1'>$1</a>");
								var exp2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
								var html = text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
								return (
									<Col md={12} key={index} className="bulletin-container">
										<div className="bulletin-color">
											<div style={{backgroundColor: bulletin.postCategory.color}}></div>
										</div>
										<div className="bulletin-details">
											<div className="bulletin-date-options-container">
												<span>{moment(bulletin.createdAt).format('DD MMM, YYYY') + ' (' + bulletin.postCategory.name + ')'}</span>
												<div className="bulletin-options">
													<div className="bulletin-options-toggle-container">
														<i className="fa fa-ellipsis-h"></i>
													</div>
													<div className="bulletin-options-container">
														<i className="fa fa-pencil" onClick={this.showEditBulletinModal.bind(this, bulletin)}></i>
														<i className="fa fa-trash" onClick={this.deleteBulletin.bind(this, bulletin.id)}></i>
													</div>
												</div>
											</div>
											<div className="desc" dangerouslySetInnerHTML={{ __html: html }}></div>
										</div>
										<div className="bulletin-images">
											{bulletin.images.map(function(image, index) {
												return (
													<img className="bulletin-image" src={image} />
												)
											})}
										</div>
									</Col>
								)
							}.bind(this))}
							{this.state.bulletins.length === 0 ? (
								<Col md={12} className="no-bulletin-container">
									<p className="no-data-text mb10 mt20">No Bulletins Found!</p>
									<button onClick={this.showAddBulletinModal.bind(this)} className="btn-le btn-sq btn-pad-5-20 mb20 mt10">ADD NEW BULLETIN</button>
								</Col>
							) : (null)}
						</Col>
					</Row>
					{this.state.addBulletinModalVisibility ? (
						<AddBulletinModal bulletinModalVisibility={this.state.addBulletinModalVisibility}
							hideBulletinModal={this.hideAddBulletinModal.bind(this)} type='add' />
					) : (null)}

					{this.state.editBulletinModalVisibility && this.state.bulletinToEdit !== null ? (
						<AddBulletinModal bulletinModalVisibility={this.state.editBulletinModalVisibility}
							hideBulletinModal={this.hideEditBulletinModal.bind(this)} type='edit' bulletinToEdit={this.state.bulletinToEdit} />
					) : (null)}
				</div>
			);
		} else return null;
	}
}