import moment from 'moment';
import swal from 'sweetalert2';
import React, { Component } from 'react';
// import Tour from 'reactour'
import { ToastContainer, toast } from 'react-toastify';
import {Row, Col, Modal, Button} from 'react-bootstrap';

export default class EEPayrollGiving extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pan: "",
			errors: [],
			address: "",
			pledged: false,
			pledgeAmount: "",
			taxExemption: false,
			showHistoryModal: false,
			pledgeFrequency: "month",
			oneTimeContribution: false,
			pledgeFromDate: moment().format('YYYY-MM-DD'),
			isTourOpen: false,
		};
	}

	componentDidMount() {
		let pledgeSettings = localStorage.getItem('pledgeSettings');
		if(pledgeSettings) {
			pledgeSettings = JSON.parse(pledgeSettings);
			this.setState({
				pan: pledgeSettings.pan || "",
				address: pledgeSettings.address || "",
				pledged: pledgeSettings.pledged || false,
				pledgeAmount: pledgeSettings.pledgeAmount || "",
				taxExemption: pledgeSettings.taxExemption || false,
				pledgeFrequency: pledgeSettings.pledgeFrequency || "month",
				oneTimeContribution: pledgeSettings.oneTimeContribution || false,
				pledgeFromDate: pledgeSettings.pledgeFromDate || moment().format('YYYY-MM-DD')
			});
		}
	}
	steps = [
		{
			selector: '.ee-page-title',
			content: 'This is my first Step',
		},
		{
			selector: '.switch-to-admin-mode',
			content: 'You could switch to admin mode here',
		},
	]


	onChange = (type, value) => {
		if(type === "pledged" || type === "oneTimeContribution" || type === "taxExemption") {
			this.setState({ [type]: !this.state[type] });
		} else if(type === "pan" || type === "address" || type === "pledgeAmount" || type === "pledgeFrequency" || type === "pledgeFromDate") {
			if(type === "pan") this.setState({ [type]: value.target.value.toUpperCase() });
			else this.setState({ [type]: value.target.value });
		}
	}

	checkSubmitDisability = () => {
		let pledgeSettings = localStorage.getItem('pledgeSettings');

		if(pledgeSettings) {
			pledgeSettings = JSON.parse(pledgeSettings);
			if(pledgeSettings.pledged === this.state.pledged && pledgeSettings.pledgeAmount === this.state.pledgeAmount && pledgeSettings.pledgeFrequency === this.state.pledgeFrequency && pledgeSettings.pledgeFromDate === this.state.pledgeFromDate && pledgeSettings.oneTimeContribution === this.state.oneTimeContribution && pledgeSettings.taxExemption === this.state.taxExemption && pledgeSettings.pan === this.state.pan && pledgeSettings.address === this.state.address) return true;
			else if(!this.state.pledged || !this.state.pledgeAmount || !this.state.pledgeFrequency || !this.state.pledgeFromDate || (!this.state.pan && this.state.taxExemption)) return true;
		} else if(!this.state.pledged || !this.state.pledgeAmount || !this.state.pledgeFrequency || !this.state.pledgeFromDate || (!this.state.pan && this.state.taxExemption)) {
			return true;
		} else if(this.state.pledgeAmount && parseInt(this.state.pledgeAmount) < 100) {
			return true;
		}
		return false;
	}
	//  showSweetAlertRecaptcha() {
	// 	 let grecaptcha = window.grecaptcha;
	// 	swal({
	// 	  title: 'SweetAlert2 + Recaptcha',
	// 	  html: '<div id="recaptcha"></div>',
	// 	  onOpen: function() {
	// 		grecaptcha.render('recaptcha', {
	// 		  'sitekey': '6Ld94V4aAAAAAK4rhWz-aJc-oBR-iS6PCkIEquuz'
	// 		});
	// 	  }, 
	// 	  preConfirm: function () {
	// 		if (grecaptcha.getResponse().length === 0) {
	// 		  swal.showValidationError(`Please verify that you're not a robot`)
	// 		}
	// 	  }
	// 	})
	//   }
	withdrawPledge = () => {
		swal({
			type: "warning",
			title: "Are you sure!",
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, I Withdraw!',
			text: "You are about to withdraw your upcoming pledges.\n\nThis will lead us to immediate deletion of your PAN from our records permanently. Should you decide to resume the pledge anytime in future, you shall have to re-submit the PAN."
		}).then((result) => {
			if(result) {
				let data = {
					pan: "",
					address: "",
					pledged: false,
					pledgeAmount: "",
					taxExemption: false,
					pledgeFromDate: "",
					pledgeFrequency: "",
					oneTimeContribution: false
				};
				localStorage.setItem('pledgeSettings', JSON.stringify(data));
				window.location.reload();
			}
		}).catch(swal.noop);
	}

	submitPledge = () => {
		let panRegex = /([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}$/, errors = [];
		if(this.state.taxExemption && this.state.pan && !panRegex.test(this.state.pan.toUpperCase())) errors.push("pan");

		if(!errors.length) {
			let data = {
				pan: this.state.pan,
				address: this.state.address,
				pledged: this.state.pledged,
				pledgeAmount: this.state.pledgeAmount,
				taxExemption: this.state.taxExemption,
				pledgeFromDate: this.state.pledgeFromDate,
				pledgeFrequency: this.state.pledgeFrequency,
				oneTimeContribution: this.state.oneTimeContribution
			}
			localStorage.setItem('pledgeSettings', JSON.stringify(data));
			swal({
				type: "success",
				title: "Updated your pledge successfully!",
				text: "Thank you very much for your commitment to philanthropy. We greatly appreciate that. Start looking for the organization you would like to support in this cycle."
			});
		}
		this.setState({ errors });
	}

	toggleHistoryModal = () => {
		this.setState({ showHistoryModal: !this.state.showHistoryModal });
	}

	openPayrollPartnersPage = () => {
		window.location.pathname = "/payroll-partners";
	}

	notifyError = (text) => toast.error(text)
	notifySuccess = (text) => toast.success(text)

	render() {
		return (
			<div className="ee-page-container payroll-giving-page">
				{/* <UserGuide buttonConfig={this.buttonConfig} guides={this.guides}> */}
				{/* <Tour
					steps={this.steps}
					badgeContent={(curr, tot) => `${curr} of ${tot}`}
					closeWithMask
					isOpen={this.state.isTourOpen}
					onRequestClose={()=>{this.setState({isTourOpen:false})}} /> */}
			{/* <script src="https://www.google.com/recaptcha/api.js?onload=showSweetAlertRecaptcha"></script>
<script src="https://cdn.jsdelivr.net/npm/sweetalert2@10"></script> */}
				<ToastContainer position="bottom-center" autoClose={5000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
				<Row>
					<Col md={12} className="text-center">
						<h2 id="ee-page-title" className="ee-page-title">My Giving Pledge</h2>
					</Col>
				</Row>
				<Col md={12}>
					{	
						(localStorage.getItem('pledgeSettings')!==null?JSON.parse(localStorage.getItem('pledgeSettings'))['pledged']:false)?
					
						<Col
                  
                  className="feedpage-questionnaire-alert-container"
                >
                  <div className="feedpage-questionnaire-alert">
                    <span className="text-uppercase">
                    You  have subscribed to the payroll giving program.  
                    </span>
					<span style={{textAlign: "right",marginBottom:"0px"}} className="pledge-withdraw-history-buttons pledged-withdraw-history-buttons">
                    <button className="btn withdraw-btn" onClick={this.withdrawPledge}>WITHDRAW</button>
						<button className="btn show-history-btn" onClick={this.toggleHistoryModal}>HISTORY</button>
						</span>
                  </div>
                </Col>
              :
						<div className="pledge-withdraw-history-buttons">
						<button className="btn withdraw-btn" onClick={this.withdrawPledge}>WITHDRAW</button>
						<button className="btn show-history-btn" onClick={this.toggleHistoryModal}>HISTORY</button>
					</div>
					}
					<div className="form-group pledge-payroll-giving-container">
						<input type="checkbox" id="pledge-payroll-giving" checked={this.state.pledged} onChange={this.onChange.bind(this, 'pledged')} />
						<label className="checkbox-label" htmlFor="pledge-payroll-giving">
							I pledge to contribute a part of my remuneration towards development and growth of underserved communities
						</label>
					</div>

					<div className="form-group">
						<label className="control-label is-imp mr20">INR</label>
						<input type="number" className="pledge-amount form-control" min={100} value={this.state.pledgeAmount} onChange={this.onChange.bind(this, 'pledgeAmount')} disabled={!this.state.pledged} />
					</div>

					<div className="form-group">
						<label className="control-label is-imp mr20">every</label>
						<select className="pledge-timeperiod form-control mr30" value={this.state.pledgeFrequency} onChange={this.onChange.bind(this, 'pledgeFrequency')} disabled={!this.state.pledged || this.state.oneTimeContribution}>
							<option value="day">Day</option>
							<option value="week">Week</option>
							<option value="month">Month</option>
						</select>
						<div className="d-flex align-items-center form-group m0">
							<input type="checkbox" id="one-time-contribution" className="mr10 mt0" checked={this.state.oneTimeContribution} onChange={this.onChange.bind(this, 'oneTimeContribution')} disabled={!this.state.pledged} />
							<label className="control-label m0" htmlFor="one-time-contribution">Make it a one-time contribution instead</label>
						</div>
					</div>

					<div className="form-group">
						<label className="control-label is-imp mr20">with effect from</label>
						<input type="date" className="pledge-date form-control" value={this.state.pledgeFromDate} onChange={this.onChange.bind(this, 'pledgeFromDate')} disabled={!this.state.pledged} min={moment().format('YYYY-MM-DD')} />
					</div>

					<div className="form-group">
						<input type="checkbox" id="tax-exemption" className="mr10 mt0" checked={this.state.taxExemption} onChange={this.onChange.bind(this, 'taxExemption')} disabled={!this.state.pledged} />
						<label className="control-label m0" htmlFor="tax-exemption">I seek tax-exemption receipt wherever possible</label>
					</div>

					{this.state.taxExemption ? (
						<div className="form-group">
							<label className="control-label is-imp mr20">PAN</label>
							<input type="text" className="pan form-control" minLength="10" maxLength="10" value={this.state.pan} onChange={this.onChange.bind(this, 'pan')} disabled={!this.state.pledged} />
							{this.state.errors.indexOf("pan") > -1 ? (
								<span className="note ml10">Please enter a valid PAN</span>
							) : (null)}
						</div>
					) : (null)}
					{this.state.taxExemption ? (
						<small className="text-muted"> &ensp; - An individual's PAN is required for generation of donation receipt.</small>
					) : (null)}

					{/* {this.state.taxExemption ? (
						<div className="form-group">
							<label className="control-label">Address (to be printed on receipt)</label>
							<input type="text" style={{width: "100%"}} className="address form-control" value={this.state.address} onChange={this.onChange.bind(this, 'address')} disabled={!this.state.pledged} />
						</div>
					) : (null)} */}

					<div className="pledge-settings-buttons">
						<button className="btn save-btn" disabled={this.checkSubmitDisability()} onClick={this.submitPledge}>SAVE MY PLEDGE PREFERENCE</button>
						<button className="btn show-ngos-btn" onClick={this.openPayrollPartnersPage}>BROWSE NGOs APPROVED BY YOUR ORGANIZATION</button>
					</div>
				</Col>

				<p className="understood-container">
					NOTE:
					<br />
					- The funds are only disbursed to the preferred NGO partner, at the end of the monthly payroll cycle. In case you do not choose any organization, the sum will be automatically allocated to the one in most need.
					<br />
					- To withdraw from the payroll giving program, kindly click on the 'WITHDRAW' button before the last day of the month.
				</p>

				<Modal show={this.state.showHistoryModal} onHide={this.toggleHistoryModal} className="pledge-history-modal">
					<Modal.Header closeButton><h3>PLEDGE HISTORY</h3></Modal.Header>
					<Modal.Body>
						<Row className="m0">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th>Date</th>
										<th>Event</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>17/09/2020</td>
										<td>pledged INR 500 per month starting 01/10/2020</td>
									</tr>
									<tr>
										<td>03/10/2020</td>
										<td>added PAN</td>
									</tr>
									<tr>
										<td>20/10/2020</td>
										<td>withdrawn from payroll giving</td>
									</tr>
									<tr>
										<td>05/11/2020</td>
										<td>pledged INR 400 per month starting 01/12/2020</td>
									</tr>
								</tbody>
							</table>
						</Row>
					</Modal.Body>
					</Modal>
					{/* </UserGuide> */}
			</div>
		);
	}
}
