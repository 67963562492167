const initialState = {
	opportunities: null,
	causes: null,
	location: null,
	selectedOpportunity: {},
	pageNumber: 1,
	totalOpportunities: 0,
};



const EEAdminDiscoverOpportunityReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_OPPORTUNITIES':
			const toReturn = {
				opportunities: action.opportunities,
			};
			if (action.totalOpportunities) {
				toReturn.totalOpportunities = action.totalOpportunities;
			}
			return Object.assign({}, state, toReturn);
		case 'UPDATE_PAGE_NUMBER':
			return Object.assign({}, state, {
				pageNumber: action.pageNumber,
			});
		case 'UPDATE_SELECTED_OPPORTUNITY':
			return Object.assign({}, state, {
				selectedOpportunity: action.selectedOpportunity
			});
		case 'UPDATE_CAUSES':
			return Object.assign({}, state, {
				causes: action.causes,
			});
		case 'UPDATE_LOCATION':
			return Object.assign({}, state, {
				location: action.location,
			});
		default:
			return state
	}
}

export default EEAdminDiscoverOpportunityReducer;