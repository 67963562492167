const initialState = {
	projects: null,
	selectedProject: {},
	priceRangeMax: 0,
	priceRangeMin: 0,
	initialPosition: {
		lat: 12.9716,
		lng: 77.5946
	},
	topProject: null,
};

const DiscoverProjectsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_PROJECTS':
			let priceRangeMax = 0;
			action.projects.map(function(project) {
				if(project.max_budget > priceRangeMax) {
					return priceRangeMax = project.max_budget
				}
			});
			return Object.assign({}, state, {
				projects: action.projects,
				priceRangeMax
			});
		case 'UPDATE_SELECTED_PROJECT':
			return Object.assign({}, state, {
				selectedProject: action.selectedProject
			});
		case 'UPDATE_TOP_PROJECT':
			return Object.assign({}, state, {
				topProject: action.topProject
			});
		default:
			return state
	}
}

export default DiscoverProjectsReducer;