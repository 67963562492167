import React, {Component} from 'react';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Switch from 'rc-switch';
import 'rc-switch/assets/index.css';
import configs from '../../../../../config/urlConfigs.js';

const readCookie = require('../../../../../cookie.js').readCookie;

export default class MakeLive extends Component {
	constructor(props) {
		super(props);
		let matchingGiving, percentPerEmployee, percentPerEmployeePercent, percentPerEmployeeUptoAmtCap, percentPerEmployeeUptoPercent, percentPerEmployeeUptoAmt, upperCap, upperCapAmt;
		if (this.props.project.config) {
			matchingGiving = this.props.project.config.matchingGiving;
			percentPerEmployee = this.props.project.config.percentPerEmployee;
			percentPerEmployeePercent = this.props.project.config.percentPerEmployeePercent;
			percentPerEmployeeUptoAmtCap = this.props.project.config.percentPerEmployeeUptoAmtCap;
			percentPerEmployeeUptoPercent = this.props.project.config.percentPerEmployeeUptoPercent;
			percentPerEmployeeUptoAmt = this.props.project.config.percentPerEmployeeUptoAmt;
			upperCap = this.props.project.config.upperCap;
			upperCapAmt = this.props.project.config.upperCapAmt;
		} else {
			matchingGiving = false;
			percentPerEmployee =  true;
			percentPerEmployeePercent = 0;
			percentPerEmployeeUptoAmtCap = false;
			percentPerEmployeeUptoPercent = 0;
			percentPerEmployeeUptoAmt = 0;
			upperCap = false;
			upperCapAmt = 0;
		}
		this.state = {
			matchingGiving,
			percentPerEmployee,
			percentPerEmployeePercent,
			percentPerEmployeeUptoAmtCap,
			percentPerEmployeeUptoPercent,
			percentPerEmployeeUptoAmt,
			upperCap,
			upperCapAmt,
			disabled: false,
			toggleValue: this.props.project.isLive
		};

		this.submitApply = this.submitApply.bind(this);
		this.isMatchingGiving = this.isMatchingGiving.bind(this);
		this.isPercentPerEmployee = this.isPercentPerEmployee.bind(this);
		this.isPercentPerEmployeeUptoAmtCap = this.isPercentPerEmployeeUptoAmtCap.bind(this);
		this.isUpperCap = this.isUpperCap.bind(this);
		this.onPercentPerEmployeePercentChange = this.onPercentPerEmployeePercentChange.bind(this);
		this.onPercentPerEmployeeUptoAmtCapAmtChange = this.onPercentPerEmployeeUptoAmtCapAmtChange.bind(this);
		this.onPercentPerEmployeeUptoAmtCapPercentChange = this.onPercentPerEmployeeUptoAmtCapPercentChange.bind(this)
		this.onUpperCapPercentChange = this.onUpperCapPercentChange.bind(this);
	}

	submitApply() {
		let data = {}
		data['projectId'] = this.props.volunteerOpportunityId;
		data['matchingGiving'] = this.state.matchingGiving;
		data['percentPerEmployee'] = this.state.percentPerEmployee;
		data['percentPerEmployeePercent'] = this.state.percentPerEmployeePercent;
		data['percentPerEmployeeUptoAmtCap'] = this.state.percentPerEmployeeUptoAmtCap;
		data['percentPerEmployeeUptoPercent'] = this.state.percentPerEmployeeUptoPercent;
		data['percentPerEmployeeUptoAmt'] = this.state.percentPerEmployeeUptoAmt;
		data['upperCap'] = this.state.upperCap;
		data['upperCapAmt'] = this.state.upperCapAmt;

		if(this.state.toggleValue) {
			fetch(configs.BACKEND_API_URL + '/project/make-live', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				if(data.status === 'ok') {
					fetch(configs.BACKEND_API_URL + '/project/admin-discover', {
						method: "GET",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						},
					}).then(function(response) {
						return response.json();
					}).then(function(data) {
						swal(
							'Marked as live',
							'This fundraiser can now be seen by employees.',
							'success'
						);
						this.props.updateProjects(data.data);
					}.bind(this));
					this.props.closeReserveModal();
				}
			}.bind(this))
		} else {
			fetch(configs.BACKEND_API_URL + '/project/make-unlive', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
				body: JSON.stringify(data)
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				if(data.status === 'ok') {
					fetch(configs.BACKEND_API_URL + '/project/admin-discover', {
						method: "GET",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						},
					}).then(function(response) {
						return response.json();
					}).then(function(data) {
						swal(
							'Marked as hidden',
							'This fundraiser cannot be seen by employees.',
							'success'
						);
						this.props.updateProjects(data.data);
					}.bind(this));
					this.props.closeReserveModal();
				}
			}.bind(this))
		}
	}

	isMatchingGiving(e) {
		this.setState({ matchingGiving: e.target.checked });
	}

	isPercentPerEmployee(e) {
		this.setState({ percentPerEmployee: e.target.checked });
	}

	onPercentPerEmployeePercentChange(e) {
		this.setState({ percentPerEmployeePercent: e.target.value });
	}

	isPercentPerEmployeeUptoAmtCap(e) {
		this.setState({ percentPerEmployeeUptoAmtCap: e.target.checked });
	}

	onPercentPerEmployeeUptoAmtCapPercentChange(e) {
		this.setState({ percentPerEmployeeUptoPercent: e.target.value });
	}

	onPercentPerEmployeeUptoAmtCapAmtChange(e) {
		this.setState({ percentPerEmployeeUptoAmt: e.target.value });
	}

	isUpperCap(e) {
		this.setState({ upperCap: e.target.checked });
	}

	onUpperCapPercentChange(e) {
		this.setState({ upperCapAmt: e.target.value });
	}

	onChange(field, value) {
		if(field === "toggleValue") this.setState({ toggleValue: !this.state.toggleValue });
		else this.setState({ [field]: value });
	}

	render () {
		return (
			<Modal show={this.props.showReserveModal} onHide={this.props.closeReserveModal} className="square-modal">
				<Modal.Header closeButton>
					<Modal.Title>MAKE LIVE</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col md={10} mdOffset={1} className="margin-col-30">
							<div className="ee-recommend-switch-container">
								<span className="ee-recommend-switch-left-text">Hidden</span>
								<Switch onChange={this.onChange.bind(this, 'toggleValue')} disabled={this.state.disabled} checked={this.state.toggleValue} />
								<span className="ee-recommend-switch-right-text">Live</span>
							</div>
							<form name="groupForm">
								<div className="form-group col-md-12">
									<input type="checkbox" className="" id="matching-giving" required onChange={this.isMatchingGiving} checked={this.state.matchingGiving} />
									<label className="checkbox-label" htmlFor="matching-giving">Enable matching giving?</label>
								</div>

								{this.state.matchingGiving ? (
									<div>
										<div className="form-group col-md-12">
											<input type="checkbox" className="" id="percent-per-employee" required onChange={this.isPercentPerEmployee} checked={this.state.percentPerEmployee} />
											<label className="checkbox-label" htmlFor="percent-per-employee">
												Percent Per Employee
											</label>
										</div>

										{this.state.percentPerEmployee ? (
											<div>
												<div className="form-group flex-center col-md-6 no-pad-left">
													<label className="control-label">
														Percentage:
													</label>
													<input type="number" className="form-control inline" min="0" max="100" onChange={this.onPercentPerEmployeePercentChange}/>
												</div>
											</div>
										) : (null)}

										<div className="form-group col-md-12">
											<input type="checkbox" className="" id="percent-per-emp-limit"
											required onChange={this.isPercentPerEmployeeUptoAmtCap}/>
											<label className="checkbox-label" htmlFor="percent-per-emp-limit">
											Percent Per Employee upto a limit?
											</label>
										</div>

										{this.state.percentPerEmployeeUptoAmtCap ? (
											<div>
												<div className="form-group flex-center col-md-6 no-pad-left">
													<label className="control-label">Percentage:</label>
													<input type="number" className="form-control inline" min="0" max="100" onChange={this.onPercentPerEmployeeUptoAmtCapPercentChange} />
												</div>

												<div className="form-group flex-center col-md-6 no-pad-right">
													<label className="control-label">Amount:</label>
													<input type="number" className="form-control inline" onChange={this.onPercentPerEmployeeUptoAmtCapAmtChange} />
												</div>
											</div>
										) : (null)}

										<div className="form-group col-md-12">
											<input type="checkbox" className="" id="upper-limit-cap" required onChange={this.isUpperCap} />
											<label className="checkbox-label" htmlFor="upper-limit-cap">Has upper limit?</label>
										</div>

										{this.state.upperCap ? (
											<div>
												<div className="form-group flex-center col-md-6 no-pad-left">
													<label className="control-label">
														Amount:
													</label>
													<input type="number" className="form-control inline"
													       min="0" max="100"
														onChange={this.onUpperCapPercentChange}
													/>
												</div>
											</div>
										) : (null)}
									</div>
								) : (null)}
							</form>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.submitApply} className="btn-sq btn-le">Save</Button>
					<Button onClick={this.props.closeReserveModal} className="btn-sq btn-cancel">Close</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}