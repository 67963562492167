import React, { Component, useContext } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import swal from 'sweetalert2';
import moment from 'moment';

import AddEditMemberModal from './AddEditMemberModal';
import ImportMemberModal from './ImportMemberModal';
import TransferMemberModal from './TransferMemberModal';

import 'sweetalert2/dist/sweetalert2.min.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { OrgDataContext } from '../../../../routes';
import configs from '../../../../config/urlConfigs';
import { CSVLink, CSVDownload } from 'react-csv';

let organisationDataContext = null;
const readCookie = require('../../../../cookie.js').readCookie;

export default class ManageSingleTeam extends Component {
	constructor() {
		super();
		this.state = {
			emails: [],
			selectedRowCount: 0,
			deleteButtonDisabled: true,
			addMemberModalVisibility: false,
			
			full_name: '',
			emp_id: '',
			gender: '',
			department: '',
			phone: '',
			email: '',
			designation: '',
			status: '',
			role: '',
			error: [],
			editMember: false,
			editMemberId: '',
			offices: [],
			cities: [],
			departments: [],
			officeTitle: '',
			transferMember: null,
			totalEmployeeCount: 0,
			transferMemberModalVisibility: false,
			accessLocations: [],
			allOffices: [],
			allCities: [],
			// listId: configs.SUBDOMAIN === 'indusind' ? window.location.hostname.split('/team-management/')[1] || '' : this.props.match.params.listId || '',
			listId: window.location.href.split('/team-management/')[1] || '',
			subdomain :configs.SUBDOMAIN || "",
		};
		this.showAddMemberModal = this.showAddMemberModal.bind(this);
		this.hideAddMemberModal = this.hideAddMemberModal.bind(this);
	}

	componentDidMount() {
		let query = '';
		query = '?listId=' + this.state.listId;
		fetch(configs.BACKEND_API_URL + '/get-employees' + query, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			this.setState({ emails: data.data, totalEmployeeCount: data.others.total, officeTitle: data.others.listTitle });
		}.bind(this));

		fetch(configs.BACKEND_API_URL + '/list', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			let offices = [];
			for (var i = 0; i < data.lists.length; i++) {
				if (this.state.listId !== data.lists[i].id) offices.push(data.lists[i]);
			}
			const allOffices = offices.map(office => ({ label: office.name, value: office.id }))
			this.setState({ offices, allOffices });
		}.bind(this));

		fetch(configs.BACKEND_API_URL + '/cities', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			let cities = [];
			for (var i = 0; i < data.lists.length; i++) {
				if (this.state.listId !== data.lists[i].id) cities.push(data.lists[i]);
			}
			const allCities = cities.map(office => ({ label: office.name, value: office.id }))
			this.setState({ cities, allCities });
		}.bind(this));

		fetch(configs.BACKEND_API_URL + '/departments', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			let departments = data.data;
			this.setState({ departments });
		}.bind(this));
	}

	submitEmails() {
		let data = [];
		this.props.inputTag.map(function (email, i) {
			data.push({ 'email': email });
		});

		fetch(configs.BACKEND_API_URL + '/add-employees', {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify({
				emails: data
			})
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			if (data.status === 'ok') {
				let query = '';
				query = '?listId=' + this.state.listId;
				fetch(configs.BACKEND_API_URL + '/get-employees' + query, {
					method: "GET",
					headers: {
						'Auth': JSON.parse(readCookie('access_token')).access_token
					},
				}).then(function (response) {
					return response.json();
				}).then(function (data) {
					swal(
						'Awesome!',
						'Employees added successfully.',
						'success'
					);
					this.setState({ emails: data.data, totalEmployeeCount: data.others.total, officeTitle: data.others.listTitle });
				}.bind(this));
			}
		}.bind(this))
	}

	createCustomButtonGroup(props, onClick) {

		return <OrgDataContext.Consumer>
			{(organisationDataContextInFunc)=>(organisationDataContextInFunc.ssoEnabled ? (null) : <div>
				<ButtonGroup className='manage-team-buttons' sizeClass='btn-group-md'>
					<button type='button' onClick={this.showAddMemberModal.bind(this)} className='btn btn-le'>
						<span>
							<i className="fa fa-plus"></i>
							Add Employee
						</span>
					</button>
					<button type='button' disabled={this.state.deleteButtonDisabled} onClick={this.getSelectedRowKeys.bind(this)} className='btn btn-le'>
						<span>
							<i className="fa fa-minus"></i>
							Delete Employee
						</span>
					</button>
					<button type='button' onClick={this.showImportMemberModal.bind(this)} className='btn btn-le'>
						<span>
							<i className="fa fa-plus"></i>
							Import Employees
						</span>
					</button>
				</ButtonGroup>
			</div>)
			}
		</OrgDataContext.Consumer>
	}

	showAddMemberModal() {
		this.setState({ addMemberModalVisibility: true });
	}

	getSelectedRowKeys() {
		let empTempId = [];
		let data = this.state.emails;
		let selectedName = this.refs.table.state.selectedRowKeys;
		for (let i = 0; i < selectedName.length; i++) {
			for (let j = 0; j < data.length; j++) {
				if (data[j]['id'] === selectedName[i]) {
					empTempId.push(data[j]['id']);
				}
			}
		}

		swal({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, Delete!',
			cancelButtonText: 'No, Cancel!',
			confirmButtonClass: 'btn',
			cancelButtonClass: 'btn',
		}).then(function (result) {
			if (result) {
				fetch(configs.BACKEND_API_URL + '/delete-employee', {
					method: "POST",
					headers: {
						'Content-Type': 'application/json',
						'Auth': JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify({ employeeIds: empTempId })
				}).then((response) => response.json())
					.then((data) => {
						if (data.status === 'ok') {
							let query = '';
							query = '?listId=' + this.state.listId;
							fetch(configs.BACKEND_API_URL + '/get-employees' + query, {
								method: "GET",
								headers: {
									'Auth': JSON.parse(readCookie('access_token')).access_token
								},
							}).then(function (response) {
								return response.json();
							}).then(function (data) {
								swal(
									'Awesome!',
									'Employees removed successfully.',
									'success'
								);
								this.setState({ emails: data.data, totalEmployeeCount: data.others.total, officeTitle: data.others.listTitle });
							}.bind(this));
						}
					});
			}
		}.bind(this));
	}

	onRowSelect(row, isSelected) {
		if (isSelected) {
			this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length + 1 }, function () {
				if (this.state.selectedRowCount) {
					this.setState({ deleteButtonDisabled: false });
				}
			});
		} else {
			this.setState({ selectedRowCount: this.refs.table.state.selectedRowKeys.length - 1 }, function () {
				if (this.state.selectedRowCount) {
					this.setState({ deleteButtonDisabled: false });
				} else {
					this.setState({ deleteButtonDisabled: true });
				}
			});
		}
	}

	onRowSelectAll(row, isSelected) {
		if (row) {
			this.setState({
				selectedRowCount: this.refs.table.state.data.length,
				deleteButtonDisabled: false
			});
		} else {
			this.setState({
				selectedRowCount: 0,
				deleteButtonDisabled: true
			});
		}
	}

	employeeId(cell, row) {
		if (cell === '') {
			return (<span></span>)
		} else {
			return (
				<a href={'/team-management/' + this.state.listId + '/' + row.id} title={cell}>{cell}</a>
			)
		}
	}

	fullName(cell, row) {
		return (
			<a href={'/team-management/' + this.state.listId + '/' + row.id} title={cell}>{cell}</a>
		)
	}

	email(cell, row) {
		return (
			<span title={cell}>{cell}</span>
		)
	}

	edit(cell, row) {
		return (
			<button className="btn btn-default member-edit-btn" type="button" onClick={this.showEditMemberModal.bind(this, row)}>Edit</button>
		)
	}

	transfer(cell, row) {
		return (
			<button className={row.status === 'Terminated' ? "btn btn-default member-transfer-btn terminated" : "btn btn-default member-transfer-btn"} type="button" onClick={this.showTransferMemberModal.bind(this, row)} disabled={row.status === 'Terminated' ? true : false}>Transfer</button>
		)
	}

	showEditMemberModal(row) {
		this.setState({
			editMember: true,
			editMemberId: row.id,
			addMemberModalVisibility: true,
			full_name: row.fullName,
			emp_id: row.employeeId,
			gender: row.gender,
			department: row.department,
			phone: row.mobileNo,
			email: row.email,
			designation: row.designationAtOrganisation,
			status: row.status,
			role: row.role,
			accessLocations: row.accessLocations || [],
			allOffices: this.state.offices.map(office => ({ label: office.name, value: office.id, selected: (row.accessLocations || []).includes(office.id) })),
			allCities: this.state.cities.map(city => ({ label: city.name, value: city.id, selected: (row.accessLocations || []).includes(city.id) })),
			error: []
		});
	}

	showTransferMemberModal(row) {
		let transferMember = {
			id: row.id,
			department: row.department
		};
		this.setState({ transferMember, transferMemberModalVisibility: true });
	}

	showImportMemberModal() {
		this.setState({ importMemberModalVisibility: true });
	}

	updateChange(name, required, e) {
		var error = this.state.error;
		var index = error.indexOf(name);
		if (required && e.target  && e.target.value !== '' && index > -1) error.splice(index, 1);

		// if (name === 'accessLocations') {
		// 	const officeArray = e.parent().val();
		// 	const allOffices = this.state.offices.map(office => ({ label: office.name, value: office.id, selected: officeArray.includes(office.id) }));
		// 	if (required && officeArray.length && index > -1) error.splice(index, 1);
		// 	this.setState({ [name]: officeArray, allOffices, error });
		// }
		if (name === 'accessLocations') {
			const citiesArray = e.parent().val();
			const allCities = this.state.cities.map(city => ({ label: city.name, value: city.id, selected: citiesArray.includes(city.id) }));
			if (required && citiesArray.length && index > -1) error.splice(index, 1);
			this.setState({ [name]: citiesArray, allCities, error });
		}
		else {
			this.setState({ [name]: e.target.value, error });
		}
	}

	hideAddMemberModal() {
		const allOffices = this.state.offices.map(office => ({ label: office.name, value: office.id }));
		const allCities = this.state.cities.map(city => ({ label: city.name, value: city.id }));
		this.setState({
			addMemberModalVisibility: false,
			full_name: '',
			emp_id: '',
			gender: '',
			department: '',
			phone: '',
			email: '',
			designation: '',
			status: '',
			role: '',
			error: [],
			editMember: false,
			editMemberId: '',
			accessLocations: [],
			allOffices,
			allCities,
		});
		let query = '';
		query = '?listId=' + this.state.listId;
		fetch(configs.BACKEND_API_URL + '/get-employees' + query, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			this.setState({ emails: data.data, totalEmployeeCount: data.others.total, officeTitle: data.others.listTitle });
		}.bind(this));
	}

	hideTransferMemberModal() {
		let query = '';
		query = '?listId=' + this.state.listId;
		fetch(configs.BACKEND_API_URL + '/get-employees' + query, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			this.setState({ transferMember: null, transferMemberModalVisibility: false, emails: data.data, totalEmployeeCount: data.others.total, officeTitle: data.others.listTitle });
		}.bind(this));
	}

	hideImportMemberModal() {
		this.setState({ importMemberModalVisibility: false });
		let query = '';
		query = '?listId=' + this.state.listId;
		fetch(configs.BACKEND_API_URL + '/get-employees' + query, {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			this.setState({ emails: data.data, totalEmployeeCount: data.others.total, officeTitle: data.others.listTitle });
		}.bind(this));
	}

	addMember() {
		var full_name = this.state.full_name;
		var emp_id = this.state.emp_id;
		var gender = this.state.gender;
		var department = this.state.department;
		var phone = this.state.phone;
		var email = this.state.email;
		var designation = this.state.designation;
		var status = this.state.status;
		var role = this.state.role;
		var accessLocations = this.state.accessLocations;
		if (!((full_name === '' || email === '' || role === '') || (role ==='office-admin' &&  accessLocations.length ===0))) {
			var data = {};
			data['fullName'] = full_name;
			data['employeeId'] = emp_id;
			data['gender'] = gender;
			data['department'] = department;
			data['mobileNo'] = phone;
			data['designation'] = designation;
			data['status'] = status;
			data['role'] = role;
			data['accessLocations'] = accessLocations;
			if (!this.state.editMember) data['email'] = email;
			if (!this.state.editMember) data['listId'] = this.state.listId;

			if (!this.state.editMember) {
				fetch(configs.BACKEND_API_URL + '/add-employees', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Auth': JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify(data)
				}).then((response) => response.json())
					.then(function (data) {
						swal(
							'Successful!',
							'You have added the employee.',
							'success'
						);
						this.hideAddMemberModal();
					}.bind(this));
			} else {
				fetch(configs.BACKEND_API_URL + '/employee/' + this.state.editMemberId, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
						'Auth': JSON.parse(readCookie('access_token')).access_token
					},
					body: JSON.stringify(data)
				}).then((response) => response.json())
					.then(function (data) {
						swal(
							'Successful!',
							'You have updated the employee.',
							'success'
						);
						this.hideAddMemberModal();
					}.bind(this));
			}
		} else {
			var error = [];
			if (full_name === '') error.push('full_name');
			if (email === '') error.push('email');
			if (role === '') error.push('role');
			if (role === 'office-admin' && this.state.accessLocations.length === 0) error.push('accessLocations');
			this.setState({ error });
		}
	}

	goBack() {
		window.location.pathname = '/team-management';
	}

	createCustomExportCSVButton() {

	   const headers = [
		 {label:"Hexa ID",key:"id"},
		 { label: "Name", key: "fullName" },
		 { label: "Employee Id", key: "employeeId" },
		 { label: "Email", key: "email" },
		 { label: "Mobile", key: "mobileNo" },
		 { label: "Department", key: "department" },
		 {label:"Designation",key:"designationAtOrganisation" },
         {label :"Status",key:"status" },	
	   ];
   
	   return (
		 <>
		 {/* { this.props.role ==="admin" ?(<> */}
		   <CSVLink
			 headers={headers}
			 data={this.state.emails}
			 onClick={() => {
			 }}
			 filename={ this.state.officeTitle + ' - ' + String(moment().unix()) + '.csv'}
			 target="_blank">
			 <ExportCSVButton
			   style={{
				 background: 'var(--themeColor)',
				 borderColor: 'var(--themeColor)',
				 boxShadow: "1px 1px 1px #515356"
			   }}
			   // className="btn btn-endorse"
			   btnText='Export to CSV' />,
			   {/* sizePerPage: 20,
			sizePerPageList: [20, 50, 100] */}
		   </CSVLink>,
		{/* //  </>)  : null */}
		 {/* } */}
		 </>
	   );
	 }

	render() {
		const selectRowProp = {
			mode: 'checkbox',
			onSelect: this.onRowSelect.bind(this),
			onSelectAll: this.onRowSelectAll.bind(this)
		};
		
		const options = { 
			exportCSVBtn: () => this.createCustomExportCSVButton(), sizePerPage: 20,
			sizePerPageList: [20, 50, 100]
		  };

					return (<div className="ee-page-container manage-single-team-page">
						<Row>
							<Col md={12} className="text-center mb20">
								{this.state.subdomain.includes("indusind") ?<h2 className="ee-page-title">Manage Department</h2> :<h2 className="ee-page-title">Manage Office</h2> }
							</Col>
						</Row>

						<Row className="single-team-member-table-container">
							<div className="back-to-all-teams">
								{this.state.subdomain === "indusind"  ? <>
								<i className="fa fa-chevron-left" onClick={this.goBack.bind(this)}></i>
								<span className="back-to-all-teams-text" onClick={this.goBack.bind(this)}>Back To All Department</span>
								</> : <>
								<i className="fa fa-chevron-left" onClick={this.goBack.bind(this)}></i>
								<span className="back-to-all-teams-text" onClick={this.goBack.bind(this)}>Back To All Offices</span>
								</>
								}
							</div>
							{this.state.subdomain === 'indusind' ? (<div className="team-title ml10">{this.state.officeTitle}</div>) 
							: ( <div className="team-title ml10">{this.state.officeTitle}{this.state.officeTitle.toLowerCase().match('office') === null ? ' Office' : ''}</div>) }
							
							<BootstrapTable ref="table" data={this.state.emails} className="ml10" hover options={options} pagination search selectRow={selectRowProp} exportCSV >
								<TableHeaderColumn isKey dataField='id' width='200' >Hexa ID</TableHeaderColumn>
								<TableHeaderColumn width='100' dataField='employeeId' searchable dataFormat={this.employeeId.bind(this)}>Employee ID</TableHeaderColumn>
								<TableHeaderColumn width='160' dataField='fullName' searchable dataFormat={this.fullName.bind(this)}>Full Name</TableHeaderColumn>
								<TableHeaderColumn width='200' dataField='email' searchable dataFormat={this.email.bind(this)}>Email</TableHeaderColumn>
								<TableHeaderColumn width='100' dataField='mobileNo' searchable>Mobile</TableHeaderColumn>
								<TableHeaderColumn width='100' dataField='department' searchable>Department</TableHeaderColumn>
								<TableHeaderColumn  width='100' dataField='designationAtOrganisation' searchable>Designation</TableHeaderColumn>
								<TableHeaderColumn width='80' dataField='status' searchable>Status</TableHeaderColumn>
								{/*<TableHeaderColumn width='80' dataField='status' export={false} headerAlign='center' columnClassName='member-transfer-btn-container' dataFormat={this.transfer.bind(this)}>Transfer</TableHeaderColumn>*/}
								<TableHeaderColumn width='70' dataField='status' export={false} headerAlign='center' columnClassName='member-edit-btn-container' dataFormat={this.edit.bind(this)}>Edit</TableHeaderColumn>
							</BootstrapTable>
						</Row>

						{/* {this.state.emails.length ? (null) : (
							<h2 className="text-center">Add Member to show!</h2>
						)} */}

						{this.state.addMemberModalVisibility ? (
							<AddEditMemberModal addMemberModalVisibility={this.state.addMemberModalVisibility}
								hideAddMemberModal={this.hideAddMemberModal.bind(this)} role={this.state.role}
								updateChange={this.updateChange.bind(this)} error={this.state.error}
								addMember={this.addMember.bind(this)} emp_id={this.state.emp_id}
								full_name={this.state.full_name} email={this.state.email}
								gender={this.state.gender} department={this.state.department}
								phone={this.state.phone} designation={this.state.designation}
								offices={this.state.allOffices}
								cities={this.state.allCities}
								accessLocations={this.state.accessLocations}
								status={this.state.status} editMember={this.state.editMember} />
						) : (null)}

						{this.state.importMemberModalVisibility ? (
							<ImportMemberModal hideImportMemberModal={this.hideImportMemberModal.bind(this)}
								importMemberModalVisibility={this.state.importMemberModalVisibility}
								listId={this.state.listId} />
						) : (null)}

						{this.state.transferMemberModalVisibility && this.state.transferMember !== null ? (
							<TransferMemberModal hideTransferMemberModal={this.hideTransferMemberModal.bind(this)}
								transferMemberModalVisibility={this.state.transferMemberModalVisibility}
								transferMemberId={this.state.transferMember.id} departments={this.state.departments}
								selectedDepartment={this.state.transferMember.department} offices={this.state.offices} />
						) : (null)}
					</div>)
				
	}
}