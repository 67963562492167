import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import configs from '../../../../../../config/urlConfigs.js';
import { OrgDataContext } from '../../../../../../routes.js';

const readCookie = require('../../../../../../cookie.js').readCookie;
let organisationDataContext = null;
export default class EndorseDetailsPage extends Component {
	constructor(props) {
		super(props);

		if(this.props.selectedNationalityCode === '101') {
			this.props.updateNationalityValid(true);
		} else {
			this.props.updateNationality([]);
			this.props.updateSelectedNationality('');
			this.props.updateSelectedNationalityCode('');
		}

		if(this.props.selectedCountryCode === '101') {
			this.props.updateCountryValid(true);
		} else {
			this.props.updateCountry([]);
			this.props.updateSelectedCountry('');
			this.props.updateSelectedCountryCode('');
		}

		this.props.updateCity([]);
		this.props.updateSelectedCity('');
		this.props.updateSelectedCityCode('');
		this.props.updateZipcode('');
		this.props.updateAddress1('');
		this.props.updateAddress2('');
		this.props.updatePan('');

		this.props.updateZipcodeValid(false);
		this.props.updateAddress1Valid(false);
		this.props.updatePanValid(false);
		this.props.updateStateValid(false);
		this.props.updateCityValid(false);


		this.props.updateFormErrors('zipcode', '');
		this.props.updateFormErrors('address1', '');
		this.props.updateFormErrors('pan', '');
		this.props.updateFormErrors('nationality', '');
		this.props.updateFormErrors('country', '');
		this.props.updateFormErrors('state', '');
		this.props.updateFormErrors('city', '');

		this.props.updateFormValid(false);
	}

	componentDidMount() {

		fetch(configs.BACKEND_API_URL + '/get-user-last-payment-info', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			}
		}).then((data) => data.json())
		.then(function(data) {
			if(data.data.length) {
			} else {
				if(this.props.userData) {
					let data = this.props.userData;
					let name = this.props.userData.fullName.split(' ');
					this.props.updateFirstName(name[0]);
					this.props.updateFirstNameValid(true);
					this.props.updateLastName(name[1]);
					this.props.updateLastNameValid(true);
					this.props.updatePhone(data.mobileNo);
					this.props.updatePhoneValid(true);
					this.props.updateEmail(data.email);
					this.props.updateEmailValid(true);
				} else {
					this.props.updateFirstName('');
					this.props.updateFirstNameValid(false);
					this.props.updateLastName('');
					this.props.updateLastNameValid(false);
					this.props.updatePhone('');
					this.props.updatePhoneValid(false);
					this.props.updateEmail('');
					this.props.updateEmailValid(false);
				}
			}
		}.bind(this));

	}

	handleChange(field, e) {
		if (field === 'firstName') {
			this.props.updateFirstName(e.target.value, this.validateUserInput(field, e.target.value));
		} else if (field === 'lastName') {
			this.props.updateLastName(e.target.value, this.validateUserInput(field, e.target.value));
		} else if (field === 'email') {
			this.props.updateEmail(e.target.value, this.validateUserInput(field, e.target.value));
		} else if (field === 'phone') {
			this.props.updatePhone(e.target.value, this.validateUserInput(field, e.target.value));
		} else if (field === 'zipcode') {
			this.props.updateZipcode(e.target.value, this.validateUserInput(field, e.target.value));
		} else if (field === 'address1') {
			this.props.updateAddress1(e.target.value, this.validateUserInput(field, e.target.value));
		} else if (field === 'address2') {
			this.props.updateAddress2(e.target.value);
		} else if (field === 'pan') {
			this.props.updatePan(e.target.value, this.validateUserInput(field, e.target.value));
		} else if (field === 'nationality') {
			this.props.updateSelectedNationality(e.target.selectedOptions[0].text, this.validateUserInput(field, e.target.selectedOptions[0].text));
			this.props.updateSelectedNationalityCode(e.target.value);
			this.props.updateIsd(e.target.selectedOptions[0].getAttribute('data-phonecode'));
		} else if (field === 'country') {
			this.props.updateSelectedCountry(e.target.selectedOptions[0].text, this.validateUserInput(field, e.target.selectedOptions[0].text));
			this.props.updateSelectedCountryCode(e.target.value);
			fetch('https://www.letsendorse.com/getlocation?type=1&countryId=' + e.target.value, {
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				this.props.updateState(data);
			}.bind(this));
		} else if (field === 'state') {
			this.props.updateSelectedState(e.target.selectedOptions[0].text, this.validateUserInput(field, e.target.selectedOptions[0].text));
			this.props.updateSelectedStateCode(e.target.value);

			fetch('https://www.letsendorse.com/getlocation?type=2&stateId=' + e.target.value, {
			}).then(function(response) {
				return response.json();
			}).then(function(data) {
				this.props.updateCity(data);
			}.bind(this));
		} else if (field === 'city') {
			this.props.updateSelectedCity(e.target.selectedOptions[0].text, this.validateUserInput(field, e.target.selectedOptions[0].text));
			this.props.updateSelectedCityCode(e.target.value);
		}
	}

	validateUserInput(field, value) {
		switch (field) {
			case 'firstName':
				if(value.length) {
					this.props.updateFirstNameValid(true);
				} else {
					this.props.updateFormErrors(field, 'First Name cannot be empty');
					this.props.updateFirstNameValid(false);
				}
				break;
			case 'lastName' :
				if(value.length) {
					this.props.updateLastNameValid(true);
				} else {
					this.props.updateFormErrors(field, 'Last Name cannot be empty');
					this.props.updateLastNameValid(false);
				}
				break;
			case 'email':
				let emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
				if(!emailValid) {
					if(value.length === 0) {
						this.props.updateFormErrors(field, 'Email cannot be empty');
						this.props.updateEmailValid(false);
					} else {
						this.props.updateFormErrors(field, 'Email not Valid');
						this.props.updateEmailValid(false);
					}
				} else {
					this.props.updateEmailValid(true);
				}
				break;
			case 'phone' :
				if(this.props.selectedNationalityCode === '101') {
					let phoneValid = value.match(/^[789]\d{9}$/);
					if(!phoneValid) {
						if(value.length === 0) {
							this.props.updateFormErrors(field, 'Phone number cannot be empty');
							this.props.updatePhoneValid(false);
						} else {
							this.props.updateFormErrors(field, 'Phone number not Valid');
							this.props.updatePhoneValid(false);
						}
					} else {
						this.props.updatePhoneValid(true);
					}
				} else {
					this.props.updatePhoneValid(true);
				}
				break;
			case 'zipcode' :
				if(value.length) {
					this.props.updateZipcodeValid(true);
				} else {
					this.props.updateFormErrors(field, 'Zipcode cannot be empty');
					this.props.updateZipcodeValid(false);
				}
				break;
			case 'address1' :
				if(value.length) {
					this.props.updateAddress1Valid(true);
				} else {
					this.props.updateFormErrors(field, 'Cannot be empty');
					this.props.updateAddress1Valid(false);
				}
				break;
			case 'pan' :
				let panValid = value.match(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/);
				if(!panValid) {
					if(value.length === 0) {
						this.props.updateFormErrors(field, 'Pan number cannot be empty');
						this.props.updatePanValid(false);
					} else {
						this.props.updateFormErrors(field, 'Pan number not Valid');
						this.props.updatePanValid(false);
					}
				} else {
					this.props.updatePanValid(true);
				}
				break;
			case 'nationality' :
				if(value.length) {
					this.props.updateNationalityValid(true);
				} else {
					this.props.updateFormErrors(field, 'Select nationality');
					this.props.updateNationalityValid(false);
				}
				break;
			case 'country' :
				if(value.length) {
					this.props.updateCountryValid(true);
				} else {
					this.props.updateFormErrors(field, 'Select country');
					this.props.updateCountryValid(false);
				}
				break;
			case 'state' :
				if(value.length) {
					this.props.updateStateValid(true);
				} else {
					this.props.updateFormErrors(field, 'Select state');
					this.props.updateStateValid(false);
				}
				break;
			case 'city' :
				if(value.length) {
					this.props.updateCityValid(true);
				} else {
					this.props.updateFormErrors(field, 'Select city');
					this.props.updateCityValid(false);
				}
				break;
			default :
				break;
		}
		setTimeout(function(){
			this.validateForm();
		}.bind(this), 100);
	}

	validateForm() {
		if(this.props.project.org.fcraPaymentAllowed) {
			if(this.props.firstNameValid && this.props.lastNameValid && this.props.emailValid && this.props.phoneValid && this.props.nationalityValid && this.props.countryValid && this.props.stateValid && this.props.cityValid && this.props.zipcodeValid && this.props.address1Valid && this.props.panValid) {
				this.props.updateFormValid(true);
			} else {
				this.props.updateFormValid(false)
			}
		} else {
			if(this.props.firstNameValid && this.props.lastNameValid && this.props.emailValid && this.props.phoneValid && this.props.nationalityValid && this.props.countryValid && this.props.stateValid && this.props.cityValid && this.props.zipcodeValid && this.props.address1Valid) {
				this.props.updateFormValid(true);
			} else {
				this.props.updateFormValid(false)
			}
		}
	}

	startPayment() {
		let data = {};

		data.campaignId = this.props.project.leProjectId;

		if(this.props.endorseType === 'unit') {
			data.amount = this.props.endorseUnitAmount;
		} else {
			data.amount = this.props.endorseAmount;
		}

		data.addressLine1 = this.props.address1;
		data.addressLine2 = this.props.address2;
		data.city = this.props.selectedCity;
		data.state = this.props.selectedState;
		data.pinCode = this.props.zipcode;
		data.mobileNo = this.props.phone;
		data.nationality = this.props.selectedNationality;
		data.country = this.props.selectedCountry;
		data.companyName = organisationDataContext.name;
		data.emailId = this.props.email;
		data.firstName = this.props.firstName;
		data.lastName = this.props.lastName;
		data.ISDCode = this.props.isd;
		data.citrusReturnUrl = window.location.protocol + '//' + window.location.host + '/project/' + this.props.project.customUrl + '/complete-cp';
		data.instamojoReturnUrl = window.location.protocol + '//' + window.location.host + '/project/' + this.props.project.customUrl + '/complete-im';
		data.anonymousCheck = this.props.anonymous;
		data.endorseReason = this.props.endorseReasonText;
		if(this.props.paymentType === 'indian') {
			data.paymentType = this.props.paymentType;
			data.extraAmount = 0;
		} else {
			data.paymentType = 'foreign';

			if(this.props.endorseType === 'unit') {
				data.extraAmount = Math.ceil((this.props.endorseUnitAmount * 2) / 100);
				data.amount = Math.ceil(this.props.endorseUnitAmount + ((this.props.endorseUnitAmount * 2) / 100));
			} else {
				data.extraAmount = Math.ceil((this.props.endorseAmount * 2) / 100);
				data.amount = Math.ceil(this.props.endorseAmount + ((this.props.endorseAmount * 2) / 100));
			}
		}
		data.panNumber = this.props.pan;

		fetch(configs.BACKEND_API_URL + '/payment/initiate', {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
			body: JSON.stringify(data)
		}).then((data) => data.json())
		.then(function(data) {
			if(data.hasOwnProperty('data')) {
				if (data.data.paymentGateway === 'instamojo') {
					window.location.href = data.data.paymentUrl;
				} else if (data.data.paymentGateway === 'citrus') {
					localStorage.setItem('data', JSON.stringify(data.data));
					window.location.href = '/endorse/initiate';
				}
			} else {
			}
		}.bind(this));
	}

	render () {
		if(this.props.project) {
			return (
				<OrgDataContext.Consumer>
					{(organisationData) => {
						organisationDataContext = organisationData;
						return <Row className="endorse-rightbar">
					<p className="endorse-project-title">{this.props.project.title}</p>

					<form name="groupForm" className="col-md-8 col-md-offset-2">
						<Row>
							<Row>
								<Col md={6}>
									<div className={"form-group " + (!this.props.firstNameValid ? "has-error" : "")}>
										<label className="control-label is-imp">First Name:</label>
										<input type="text" className="form-control"
										       value={this.props.firstName}
										       onChange={this.handleChange.bind(this, 'firstName')}
										       required/>
										{
											!this.props.firstNameValid ? (
												<span className="help-block">{this.props.formErrors.firstName}</span>
											) : (
												null
											)
										}
									</div>
								</Col>

								<Col md={6}>
									<div className={"form-group " + (!this.props.lastNameValid ? "has-error" : "")}>
										<label className="control-label is-imp">Last Name:</label>
										<input type="text" className="form-control"
										       value={this.props.lastName}
										       onChange={this.handleChange.bind(this, 'lastName')}
										       required/>
										{
											!this.props.lastNameValid ? (
												<span className="help-block">{this.props.formErrors.lastName}</span>
											) : (
												null
											)
										}
									</div>
								</Col>
							</Row>

							<Row>
								<Col md={6}>
									<div className={"form-group " + (!this.props.emailValid ? "has-error" : "")}>
										<label className="control-label is-imp">Email:</label>
										<input type="email" className="form-control"
										       value={this.props.email}
										       onChange={this.handleChange.bind(this, 'email')}
										       required/>
										{
											!this.props.emailValid ? (
												<span className="help-block">{this.props.formErrors.email}</span>
											) : (
												null
											)
										}
									</div>
								</Col>

								<Col md={6}>
									<div className={"form-group " + (!this.props.phoneValid ? "has-error" : "")}>
										<label className="control-label is-imp">Phone:</label>
										<div className="input-group">
											<span className="input-group-addon" id="basic-addon1">+{this.props.isd}</span>
											<input type="tel" className="form-control"
											       value={this.props.phone}
											       onChange={this.handleChange.bind(this, 'phone')}
											       required/>
											{
												!this.props.phoneValid ? (
													<span className="help-block">{this.props.formErrors.phone}</span>
												) : (
													null
												)
											}
										</div>
									</div>
								</Col>
							</Row>

							<Row>
								<Col md={6}>
									<div className="form-group">
										<label className="control-label is-imp">Nationality:</label>
										<select className="form-control"
										        required
										        defaultValue={this.props.selectedNationalityCode}
										        onChange={this.handleChange.bind(this, 'nationality')}
										        disabled={!this.props.project.org.fcraPaymentAllowed}
										>
											<option disabled>Select nationality</option>
											{
												this.props.nationality.map(function(nationality, i) {
													return (
														<option key={i} value={nationality.id} data-phonecode={nationality.phonecode}>{nationality.name}</option>
													)
												})
											}
										</select>
									</div>
								</Col>

								<Col md={6}>
									<div className="form-group">
										<label className="control-label is-imp">Country:</label>
										<select className="form-control"
										        required
										        onChange={this.handleChange.bind(this, 'country')}
										        defaultValue={this.props.selectedCountryCode}
										>
											<option disabled>Select country</option>
											{
												this.props.country.map(function(country, i) {
													return (
														<option key={i} value={country.id}>{country.name}</option>
													)
												})
											}
										</select>
									</div>
								</Col>
							</Row>

							<Row>
								<Col md={6}>
									<div className="form-group">
										<label className="control-label is-imp">State:</label>
										<select className="form-control"
										        onChange={this.handleChange.bind(this, 'state')}
										        defaultValue={this.props.selectedStateCode ? this.props.selectedStateCode : '-1'}
										        required>
											<option disabled value="-1">Select state</option>
											{
												this.props.state.map(function(state, i) {
													return (
														<option key={i} value={state.id}>{state.name}</option>
													)
												})
											}
										</select>
									</div>
								</Col>

								<Col md={6}>
									<div className="form-group">
										<label className="control-label is-imp">City:</label>
										<select className="form-control"
										        onChange={this.handleChange.bind(this, 'city')}
										        defaultValue={this.props.selectedCityCode ? this.props.selectedCityCode : "-1"}
										        required>
											<option disabled value="-1">Select city</option>
											{
												this.props.city.map(function(city, i) {
													return (
														<option key={i} value={city.id}>{city.name}</option>
													)
												})
											}
										</select>
									</div>
								</Col>
							</Row>

							<Row>
								<Col md={6}>
									<div className={"form-group " + (!this.props.zipcodeValid ? "has-error" : "")}>
										<label className="control-label is-imp">Zip Code:</label>
										<input type="text" className="form-control"
										       onChange={this.handleChange.bind(this, 'zipcode')}
										       defaultValue={this.props.zipcode}
										       required/>
										{
											!this.props.zipcodeValid ? (
												<span className="help-block">{this.props.formErrors.zipcode}</span>
											) : (
												null
											)
										}
									</div>
								</Col>

								<Col md={6}>
									<div className={"form-group " + (!this.props.address1Valid ? "has-error" : "")}>
										<label className="control-label is-imp">Flat/House No.:</label>
										<input type="text" className="form-control"
										       onChange={this.handleChange.bind(this, 'address1')}
										       defaultValue={this.props.address1}
										       required/>
										{
											!this.props.address1Valid ? (
												<span className="help-block">{this.props.formErrors.address1}</span>
											) : (
												null
											)
										}
									</div>
								</Col>
							</Row>

							<Row>
								<Col md={6}>
									<div className="form-group">
										<label className="control-label">Colony/Locality:</label>
										<input type="text" className="form-control"
										       onChange={this.handleChange.bind(this, 'address2')}
										       defaultValue={this.props.address2}
										       required/>
									</div>
								</Col>

								<Col md={6}>
									<div className="form-group">
										<label className={"control-label " + (this.props.project.org.fcraPaymentAllowed ? "is-imp" : "")}>Permanent Account Number (PAN):</label>
										<input type="tel" className="form-control"
										       onChange={this.handleChange.bind(this, 'pan')}
										       defaultValue={this.props.pan}
										       required/>
									</div>
								</Col>
							</Row>

							<Col md={12} className="text-center">
								{
									this.props.endorseType === 'custom' ? (
										<button type="button" className="btn btn-endorse" disabled={!this.props.formValid} id="trigger-sticky-header" onClick={this.startPayment.bind(this)}>
											{(() => {
												if(this.props.paymentType === 'international') {
													return (
														<p>Proceed to pay ₹ {Math.ceil(this.props.endorseAmount + ((this.props.endorseAmount * 2) / 100))}</p>
													)
												} else {
													return (
														<p>Proceed to pay ₹ {this.props.endorseAmount}</p>
													)
												}
											})()}
										</button>
									) : (
										<button type="button" className="btn btn-endorse" disabled={!this.props.formValid} id="trigger-sticky-header" onClick={this.startPayment.bind(this)}>
											{(() => {
												if(this.props.paymentType === 'international') {
													return (
														<p>Proceed to pay ₹ {Math.ceil(this.props.endorseUnitAmount + ((this.props.endorseUnitAmount * 2) / 100))}</p>
													)
												} else {
													return (
														<p>Proceed to pay ₹ {this.props.endorseUnitAmount}</p>
													)
												}
											})()}
										</button>
									)
								}
							</Col>
						</Row>

					</form>
					</Row>}}
				</OrgDataContext.Consumer>
			)
		} else {
			return null
		}
	}
}