
import React from "react";
import { Suspense, lazy } from "react";

const Component1 = lazy(() => import(
    './Leaderboard'))
const NewLeaderboard = ()=>  {
        return (
            <>
                <Suspense fallback=
    {<div >Component1 are loading please wait...</div>}>
                    <Component1 />
                </Suspense>
            </>
        );
    }
    export default NewLeaderboard;