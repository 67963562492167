const initialState = {
	projects: null,
	causes: null,
	location: null,
	selectedProject: {},
};

const EEEndorseProjectsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_PROJECTS':
			return Object.assign({}, state, {
				projects: action.projects,
			});
		case 'UPDATE_SELECTED_PROJECT':
			return Object.assign({}, state, {
				selectedProject: action.selectedProject
			});
		case 'UPDATE_CAUSES':
			return Object.assign({}, state, {
				causes: action.causes,
			});
		case 'UPDATE_LOCATION':
			return Object.assign({}, state, {
				location: action.location,
			});
		default:
			return state
	}
}

export default EEEndorseProjectsReducer;