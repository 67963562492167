const initialState = {
	employeeContributionAmount: 0,
	employeeContribution: null,
	employeeContributionCauses: null,
	employeeContributionLocations: null,
	employeeMatchingAmount: 0,
	employeeMatching: null,
	employeeMatchingCauses: null,
	employeeMatchingLocations: null,
	employeePayrollAmount: 0,
	employeePayroll: null,
	employeePayrollCauses: null,
	employeePayrollLocations: null,
};

const EEAdminContributionAnalyticsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_CONTRIBUTION_AMOUNT':
			return Object.assign({}, state, {
				employeeContributionAmount: action.employeeContributionAmount,
			});
		case 'UPDATE_CONTRIBUTION':
			return Object.assign({}, state, {
				employeeContribution: action.employeeContribution,
			});
		case 'UPDATE_CONTRIBUTION_CAUSES':
			return Object.assign({}, state, {
				employeeContributionCauses: action.employeeContributionCauses,
			});
		case 'UPDATE_CONTRIBUTION_LOCATIONS':
			return Object.assign({}, state, {
				employeeContributionLocations: action.employeeContributionLocations,
			});
		case 'UPDATE_MATCHING_AMOUNT':
			return Object.assign({}, state, {
				employeeMatchingAmount: action.employeeMatchingAmount,
			});
		case 'UPDATE_MATCHING':
			return Object.assign({}, state, {
				employeeMatching: action.employeeMatching,
			});
		case 'UPDATE_MATCHING_CAUSES':
			return Object.assign({}, state, {
				employeeMatchingCauses: action.employeeMatchingCauses,
			});
		case 'UPDATE_MATCHING_LOCATIONS':
			return Object.assign({}, state, {
				employeeMatchingLocations: action.employeeMatchingLocations,
			});
		case 'UPDATE_PAYROLL_AMOUNT':
			return Object.assign({}, state, {
				employeePayrollAmount: action.employeePayrollAmount,
			});
		case 'UPDATE_PAYROLL':
			return Object.assign({}, state, {
				employeePayroll: action.employeePayroll,
			});
		case 'UPDATE_PAYROLL_CAUSES':
			return Object.assign({}, state, {
				employeePayrollCauses: action.employeePayrollCauses,
			});
		case 'UPDATE_PAYROLL_LOCATIONS':
			return Object.assign({}, state, {
				employeePayrollLocations: action.employeePayrollLocations,
			});

		default:
			return state
	}
}

export default EEAdminContributionAnalyticsReducer;