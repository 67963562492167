import React, { Component } from 'react';
import { Row,Col } from 'react-bootstrap';
import $ from 'jquery';
import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import PlacesAutocomplete from 'react-places-autocomplete'
import ReCAPTCHA from "react-google-recaptcha";
import PropTypes from 'prop-types';
 
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.css';
import { IBLNavbar } from '../../routes';
import './Signup.css';
import * as roleActions from '../../actions/RoleAction';
import configs from '../../config/urlConfigs';



var CryptoJS = require("crypto-js");
const readCookie = require('../../cookie.js').readCookie;
const createCookie = require('../../cookie.js').createCookie;
const subdomainIsValid = require('../../subdomainCheck.js').subdomainIsValid;

const domainArr = [
	'gmail.com',
	'yahoo.com',
	'yahoo.co.in',
	'rediffmail.com',
	'hotmail.com',
	'outlook.com'
];

const subdomain = configs.SUBDOMAIN;

class SignupSwitch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			signInEmail: null,
			signInPassword: null,
			ngoSignUpUsername: null,
			ngoSignUpNgoName: null,
			ngoSignUpDesignation: null,
			ngoSignUpEmail: null,
			ngoSignUpPhone: null,
			ngoSignUpProfUrl: null,
			corpSignUpUsername: null,
			corpSignUpName: null,
			corpSignUpEmail: null,
			corpSignUpDesignation: null,
			corpSignUpPhone: null,
			corpSignUpLocation: '',
			corpSignUpUsernameError: '',
			corpSignUpNameError: '',
			corpSignUpEmailError: '',
			corpSignUpDesignationError: '',
			corpSignUpPhoneError: '',
			corpSignUpLocationError: '',
			clientData: null,
			captchaVerifiedSignIn: false,
			captchaVerifiedSignUp: false,
			ssoEnabledCompany: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.onCaptchaChangeSignIn = this.onCaptchaChangeSignIn.bind(this);
		this.onCaptchaChangeSignUp = this.onCaptchaChangeSignUp.bind(this);
		//this.refreshCaptcha = this.refreshCaptcha.bind(this);
	}
	onCaptchaChangeSignUp(value) {
		this.setState({ captchaVerifiedSignUp: true })
	}
	onCaptchaChangeSignIn(value) {
		this.setState({ captchaVerifiedSignIn: true })
	}
	setInitialState() {
		if (!$('#invalid-details').hasClass('hidden')) $('#invalid-details').addClass('hidden');
		this.setState({
			signInEmail: null,
			signInPassword: null,
			ngoSignUpUsername: null,
			ngoSignUpNgoName: null,
			ngoSignUpDesignation: null,
			ngoSignUpEmail: null,
			ngoSignUpPhone: null,
			ngoSignUpProfUrl: null,
			corpSignUpUsername: null,
			corpSignUpName: null,
			corpSignUpEmail: null,
			corpSignUpDesignation: null,
			corpSignUpPhone: null,
			corpSignUpLocation: '',
			corpSignUpUsernameError: '',
			corpSignUpNameError: '',
			corpSignUpEmailError: '',
			corpSignUpDesignationError: '',
			corpSignUpPhoneError: '',
			corpSignUpLocationError: '',
		});
		this.onCorpSignUpLocationChange = (corpSignUpLocation) => this.setState({ corpSignUpLocation })
	}

	changeLoginType(loginType) {
		this.setInitialState(this);
		this.props.changeLoginType(loginType);
	}
	cancelCorporateSignin(e) {
		let evt;
		if (!e) evt = window.event;
		evt.cancelBubble = true;
		if (evt.stopPropagation) evt.stopPropagation();
		this.setInitialState(this);
		this.props.cancelCorporateSignin();
	}

	flipNgoSignin(target) {
		this.setInitialState(this);
		this.props.flipNgoSignin(target);
	}

	flipCorporateSignin(target) {
		this.setInitialState(this);
		//this.refreshCaptcha();
		this.props.flipCorporateSignin(target);
	}

	handleChange(name, value) {
		if (name === 'signInPassword') {
			if (value.key === 'Enter') {
				if (this.state.signInEmail !== '' && this.state.signInPassword !== '') this.login('corporate');
			} else {
				this.setState({
					[name]: value,
					[name + 'Error']: ''
				});
			}
		} else {
			this.setState({ [name]: value, [name + 'Error']: '' });
		}
	}

	handleSubmit(e) {
		if (e.key === 'Enter') {
			if (this.state.signInEmail !== '' && this.state.signInPassword !== '') this.login('corporate');
		}
	}

	validateEmail(email) {
		let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	login(type) {
		if (!this.state.captchaVerifiedSignIn) return;
		let signInEmail = CryptoJS.AES.encrypt(this.state.signInEmail, process.env.REACT_APP_ENCRYPTION_KEY).toString();
		let signInPassword = CryptoJS.AES.encrypt(this.state.signInPassword, process.env.REACT_APP_ENCRYPTION_KEY).toString();
		if (signInEmail !== '' && signInPassword !== '' && signInEmail !== null && signInEmail !== signInPassword) {
			let data = {};
			data['email'] = signInEmail;
			data['password'] = signInPassword;
			data['type'] = 'employee';
			data['enc'] = true;
			// var subdomain = '';
			if (window.location.hostname.split('.').length >= 3 && window.location.hostname.split('.')[0] !== 'www') {
				subdomain = window.location.hostname.split('.')[0];
			}
			if (subdomain !== 'staging')
				data['subdomain'] = subdomain;

			fetch(configs.BACKEND_API_URL + '/login', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			}).then(function (response) {
				return response.json();
			}).then(function (data) {
				if (data['status'] === 'ok') {
					// reduce cookie size with questionnaires
					delete data.data.organisationData;
					delete data.data.questionnairePreferences;
					delete data.data.preference;
					this.props.roleActions.handleLogin();
					createCookie('userData', JSON.stringify({
						...data.data,
						// questionnairePreferences: {
						// 	...data.data.questionnairePreferences,
						// 	selectedEmployeeQuestions: []
						// },
						// organisationData: {
						// 	...data.data.organisationData, eePreferences: {
						// 		...data.data.organisationData.eePreferences,
						// 		employeeQuestions: [],
						// 		enableEmployeeAttendance: data.data.enableEmployeeAttendance || false,
						// 		questionnaire: {
						// 			...data.data.organisationData.eePreferences.questionnaire,
						// 			selectedEmployeeQuestions: []
						// 		}
						// 	}
						// },
						preference: data.data.preference || null,
						whatsapp: data.data.whatsapp || '',
						receiveNotifications: data.data.receiveNotifications || false,
						consentLastUpdated: data.data.consentLastUpdated || null
					}), 7);
					createCookie('access_token', JSON.stringify(data.token), 7);
					createCookie('letsEngageVersion', process.env.REACT_APP_VERSION, 7);
					this.setInitialState(this);
					if (data.data.hasCustomPassword) {
						// if(data.data.role === 'admin') {
						// 	window.location.pathname = '/admin';
						// } else {
						window.location.pathname = '/';
						// }
					} else window.location.pathname = '/change-password';
				} else if (data['status'] === 'error') {
					$('#invalid-details-corp').removeClass('hidden');
					if (data['code'] === 1) {
						swal(
							'Email Verification Pending!',
							'Kindly check the verification link sent to your official email ID.',
							'warning'
						)
					} else if (data['code'] === 2) {
						swal(
							'Verification Pending!',
							'We are in the process of verifying your email. You will receive a password shortly.',
							'warning'
						)
					} else if (data['code'] === 3 || data['message'] === 'Invalid user') {
						$('#invalid-details-corp').removeClass('hidden');
					} else {
						swal(
							'Error!',
							'We are facing some issue. Kindly try after some time.',
							'warning'
						)
					}
				}
			}.bind(this));
		}
	}

	signup(type) {
		if (!this.state.captchaVerifiedSignUp) return;
		let data = {};
		if (type === 'ngo') {
			data['fullName'] = this.state.ngoSignUpUsername;
			data['organisationName'] = this.state.ngoSignUpNgoName;
			data['email'] = this.state.ngoSignUpEmail;
			data['mobileNo'] = this.state.ngoSignUpPhone;
			data['designationAtOrganisation'] = this.state.ngoSignUpDesignation;
			data['le_ngo_id'] = this.state.ngoSignUpProfUrl.split('/').pop();
			data['type'] = type;
			fetch(configs.BACKEND_API_URL + '/signup', {
				method: "POST",
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			}).then(function (response) {
				return response.json();
			}).then(function (data) {
				if (data['status'] === 'ok') {
					this.setInitialState(this);
					swal(
						'Awesome!',
						'An email has been sent to your Official Email ID.',
						'success'
					)
				}
			});
		} else if (type === 'employee') {
			if (this.state.corpSignUpUsername !== null && this.state.corpSignUpName !== null && this.state.corpSignUpEmail !== null && this.state.corpSignUpDesignation !== null && this.state.corpSignUpPhone !== null && this.state.corpSignUpLocation !== null) {
				data['fullName'] = this.state.corpSignUpUsername;
				data['organisationName'] = this.state.corpSignUpName;
				data['email'] = this.state.corpSignUpEmail;
				data['designationAtOrganisation'] = this.state.corpSignUpDesignation;
				data['mobileNo'] = this.state.corpSignUpPhone;
				data['organisationLocation'] = this.state.corpSignUpLocation;
				data['type'] = type;
				fetch(configs.BACKEND_API_URL + '/signup', {
					method: "POST",
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(data)
				}).then(function (response) {
					return response.json();
				}).then(function (data) {
					if (data['status'] === 'ok') {
						swal(
							'Awesome!',
							'An email has been sent to your Official Email ID.',
							'success'
						)
					}
				});
			} else {
				let phoneRegex = new RegExp(/^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$/gm);
				if (this.state.corpSignUpUsername === '' || this.state.corpSignUpUsername === null) this.setState({ corpSignUpUsernameError: 'error' });
				if (this.state.corpSignUpName === '' || this.state.corpSignUpName === null) this.setState({ corpSignUpNameError: 'error' });
				if (this.state.corpSignUpEmail === '' || this.state.corpSignUpEmail === null) this.setState({ corpSignUpEmailError: 'error' });
				if (this.state.corpSignUpDesignation === '' || this.state.corpSignUpDesignation === null) this.setState({ corpSignUpDesignationError: 'error' });
				if (this.state.corpSignUpPhone === '' || this.state.corpSignUpPhone === null || !phoneRegex.test(this.state.corpSignUpPhone)) this.setState({ corpSignUpPhoneError: 'error' });
				if (this.state.corpSignUpLocation === '' || this.state.corpSignUpLocation === null) this.setState({ corpSignUpLocationError: 'error' });
			}
		}
	}

	componentDidMount() {
		// var subdomain = '', url = '';
		// if (window.location.hostname.split('.').length >= 3 && window.location.hostname.split('.')[0] !== 'www') {
		// 	subdomain = window.location.hostname.split('.')[0];
		// }

		const valid_subdomain_obj = subdomainIsValid(subdomain);
		if (subdomain && valid_subdomain_obj) {
			let stateToSet = { clientData: valid_subdomain_obj.clientData, ssoEnabledCompany: valid_subdomain_obj.ssoEnabledCompany };
			this.setState(stateToSet);

			fetch(configs.BACKEND_API_URL + "/ee/check-subdomain/" + subdomain, {
				method: "GET"
			}).then(function (response) {
				return response.json();
			}).then(function (data) {
				if (data.status === "ok") {
					if (data.access_token) {
						stateToSet.access_token = data.access_token;
						createCookie('access_token', data.access_token, 7);
					}
					if (data.userData) {
						stateToSet.userData = data.userData;
						delete data.userData.organisationData;
						delete data.userData.questionnairePreferences;
						delete data.userData.preference;
						this.props.roleActions.handleLogin();
						createCookie('userData', JSON.stringify(data.userData), 7);
					}
					this.setState(stateToSet);
				}
			}.bind(this)).catch(err => { console.error(err) });
		}

		$('#corporateEmail').on('blur', function () {
			let regx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			let email = $(this).val();

			let data = {
				"email": email,
				"type": 'employee'
			};

			if (regx.test(email)) {
				let i = email.indexOf("@");
				if (i !== -1) {
					let domain = email.substring(i + 1);

					if (domainArr.indexOf(domain) > -1) {
						$('#signupCorporate').prop('disabled', true)
						$('#already-signed-up').removeClass('hidden');
						$('#already-signed-up').find('span').html('Please enter an official email id');
					} else {
						$('#signupCorporate').prop('disabled', false)
						fetch(configs.BACKEND_API_URL + '/check-user-existence', {
							method: "POST",
							headers: {
								'Content-Type': 'application/json'
							},
							body: JSON.stringify(data)
						}).then(function (response) {
							return response.json();
						}).then(function (data) {
							if (data['status'] === 'ok') {
								$('#signupCorporate').prop('disabled', false);
								$('#already-signed-up').addClass('hidden');
							} else if (data['status'] === 'error' && data['message'] === 'User already exists') {
								$('#signupCorporate').prop('disabled', true)
								$('#already-signed-up').removeClass('hidden');
								$('#already-signed-up').find('span').html('You have already signed up');
							}
						});
					}
				}
			}
		});

		$('.forgot-link').on('click', function () {
			swal({
				title: 'Forgot Password?',
				text: 'Enter your email',
				input: 'email',
				showCancelButton: true,
				confirmButtonText: 'Submit',
				showLoaderOnConfirm: true,
				preConfirm: function (email) {
					return new Promise(function (resolve, reject) {
						var data = {
							email,
							type: 'employee',
							resetUrl: window.location.origin + '/reset-password/'
						};
						fetch(configs.BACKEND_API_URL + "/forgot-password", {
							method: "POST",
							headers: {
								"Content-Type": "application/json"
							},
							body: JSON.stringify(data)
						}).then(function (response) {
							return response.json();
						}).then(function (data) {
							if (data["status"] === "ok") {
								$("#signupCorporate").prop("disabled", false);
								$("#already-signed-up").addClass("hidden");
								resolve();
							} else if (data["status"] === "error") {
								reject(data.message);
							}
						});
					});
				},
				allowOutsideClick: false
			}).then(function (res) {
				swal({
					type: "success",
					title: "Password Reset Mail sent",
					html: "Please check your email. We have sent you a link for resetting your password."
				});
			}).catch(function (message) {
				swal({
					type: "error",
					title: "Password Reset Failed",
					html: message
				});
			});
		});
	}

	showPrivacyNotice = () => {
		if (this.state.clientData && this.state.clientData.privacyNotice) {
			swal({
				title: 'Privacy Notice',
				html: `<div class="swal-scollable-div">${this.state.clientData.privacyNotice}</div>`,
			});
		}
	}

	ssoSignIn = () => {
		if (this.state.clientData && this.state.clientData.ssoEnabled && this.state.clientData.sso_url) {
			window.location = this.state.clientData.sso_url;
		}
	}

	render() {
		const inputProps = {
			value: this.state.corpSignUpLocation,
			onChange: this.onCorpSignUpLocationChange,
		};

		const options = {
			types: ['(cities)'],
		};

		const cssClasses = {
			input: 'form-control' + this.state.corpSignUpLocationError,
			autocompleteContainer: 'my-autocomplete-container'
		};

		const classNameForLogin = this.state.ssoEnabledCompany ? 'sso-login-div' : 'login-div';

		const name = this.state.clientData && this.state.clientData.name ? this.state.clientData.name : "";
		return (

			name === 'IndusInd Bank Limited' ? <>
			
			<div className='indusind-login-container'>
				{/* <Row className='row'> */}
					<IBLNavbar />
				{/* </Row> */}
				<Row className='main-row'>
				<Col className="indusind-text-container">
					{/* working on this page */}
					{/* this is commented  */}
					  {/* <div >
						 <span className="indusind-welcome-text-mobile-view">
							Welcome to
						</span><br/>
						<span className='indusind-welcome-ev-text'>
							IndusInd Bank's <br /><span className='indusind-welcome-ev-text-employee'>Employee Volunteering Portal</span>
						</span> 

					</div>   */}
					{/* till herer */}
					 <div >
						<span className='indusind-welcome-text indusind-welcome-text-mobile-view'>
							Welcome to
						</span><br />
						<span className='indusind-welcome-ev-text'>
							IndusInd Bank's <br /><span id="content1"className='indusind-welcome-ev-text-employee' >Employee Volunteering 
							</span><span>{" "}Portal</span>
						</span></div>
					<div className="indusind-sign-in-button">
						<button id="signinSSO" onClick={this.ssoSignIn} className="btn ibl-signin-btn"> SIGN IN </button>
					</div>
					<div className='indusind-powered-by-container'>
						<p className='powered-by-text'>Powered by {"  "}
							<img src='https://www.letsendorse.com/images/letsEndorse-Logo-Black-Transparent.png' style={{ width: "10vw" }} alt="LE Logo" />
						</p>
						</div>
						</Col>
				<Col className="md-message-container">
					<div >
					 <img className="md-speech-message" 
					src='/img/IBL/MD.png'
					alt="" />
					</div>
				</Col>
						</Row>
			 </div>  
			  {/* new sign up page */}
				{/* <IBLNavbar />

				<Row  style={{paddingTop: '7.3%'}} 
				className="margin-0"
				>
					<Col xl={6}	xxl={6} lg={6} md={6} style={{border : "1px solid black",height:"100%"}} >
					{/* <Row> */}
					{/* <span className='indusind-welcome-text indusind-welcome-text-mobile-view margin-0'>Welcome to</span>
					<br/>
					<span className='indusind-welcome-ev-text'>
						IndusInd Bank's <br />
					<span id="content1"className='indusind-welcome-ev-text-employee' >
						Employee Volunteering </span> <span>{" "}Portal</span>
					</span>
					{/* </Row> */}
					{/* </Col>
					<Col xl={6}	xxl={6} lg={6} md={6} className="padding0 margin-0 md-image"
					style={{border : "1px solid black"}} */}
					{/* > */}
						{/* <Row xl={6} xxl={6} lg={6} md={6} className="margin-0"><img 
					className="md-speech-message" 
					// style={{height:"80%"}}
					src='/img/IBL/MD.png'
					alt="" /></Row></Col>
				</Row> */}
			</> : 
			<div className="login-page-container">
				<div className={classNameForLogin}><div className="container-login">
					<div className="signup-switch-corporate-forms">
						<div className="flip-container">
							<div className="flipper">
								<div className="front">
									{this.state.clientData !== null && this.state.clientData.logo_url ? (
										<img src={this.state.clientData.logo_url} alt={this.state.clientData.name} title={this.state.clientData.name} style={{ width: "30vw", marginTop: this.state.ssoEnabledCompany ? "-5%" : "0%", marginBottom: this.state.ssoEnabledCompany ? "0" : "-10%" }} />
									) : (null)}
									{this.state.ssoEnabledCompany ? <>
										<b className="sso-signup-header">Welcome to LetsEngage</b>
										{/* @Todo: Add sso navigation */}
										<button id="signinSSO" onClick={this.ssoSignIn} className="btn submit-btn">
											Sign In with SSO
										</button>
										<p className='powered-by-text'>Powered by</p>
										<img src='https://www.letsendorse.com/images/letsEndorse-Logo-Black-Transparent.png' style={{ width: "10vw" }} alt="LE Logo" />

										<p className='privacy-notice' onClick={this.showPrivacyNotice}>Privacy Notice</p>

									</> : <><p className="signup-header">LOGON TO LETSENGAGE</p>
										<Row className="signin-container">
											<div className="form-group">
												<label className="control-label is-imp">Official Email:</label>
												<input type="email" className="form-control" placeholder="yourname@company.com" required onChange={(e) => { this.handleChange('signInEmail', e.target.value) }} />
											</div>

											<div className="form-group">
												<label className="control-label is-imp">Password:</label>
												<input type="password" className="form-control" placeholder="password" required
													onChange={(e) => { this.handleChange('signInPassword', e.target.value) }}
													onKeyDown={this.handleSubmit.bind(this)} />
											</div>

											<div className="form-group hidden" id="invalid-details-corp">
												<span>Invalid user details</span>
											</div>
											<div id="captcha-sign-in"><ReCAPTCHA
												sitekey="6Ld94V4aAAAAAK4rhWz-aJc-oBR-iS6PCkIEquuz"
												onChange={this.onCaptchaChangeSignIn}
											/></div>
											<div className="form-group">
												{this.state.captchaVerifiedSignIn ? <button id="signinCorporate" className="btn btn-center submit-btn" onClick={this.login.bind(this, 'corporate')}>
													Sign In
												</button> : <button id="signinCorporate" className="btn btn-center submit-btn" disabled>
													Sign In
												</button>}
											</div>

											<div className="form-group extra-links">
												<span className="signup-link" onClick={e => this.flipCorporateSignin(e.target)}>New here? Sign-up</span>
												<span className="forgot-link">Forgot Password?</span>
											</div>
											<div className="form-group extra-links"><a href="https://www.letsendorse.com/privacy-policy" target="_blank">Privacy Policy</a></div>
										</Row></>}
								</div>
								<div className="back">
									<p className="signup-header">CORPORATE SIGNUP</p>
									<Row className="signin-container">
										<div className="form-group">
											<label className="control-label is-imp">Your Name:</label>
											<input type="text" className={"form-control " + this.state.corpSignUpUsernameError} placeholder="Your Name" required onChange={(e) => { this.handleChange('corpSignUpUsername', e.target.value) }} />
										</div>

										<div className="form-group">
											<label className="control-label is-imp">Company Name:</label>
											<input type="text" className={"form-control " + this.state.corpSignUpNameError} placeholder="Your Company Name" required onChange={(e) => { this.handleChange('corpSignUpName', e.target.value) }} />
										</div>

										<div className="form-group">
											<label className="control-label is-imp">Official Email:</label>
											<input type="email" id="corporateEmail" data-type="corporate" className={"form-control " + this.state.corpSignUpEmailError} placeholder="yourname@company.com" required onChange={(e) => { this.handleChange('corpSignUpEmail', e.target.value) }} />
										</div>

										<div className="form-group">
											<label className="control-label is-imp">Your Designation:</label>
											<input type="text" className={"form-control " + this.state.corpSignUpDesignationError} placeholder="Manager" required onChange={(e) => { this.handleChange('corpSignUpDesignation', e.target.value) }} />
										</div>

										<div className="form-group">
											<label className="control-label is-imp">Phone Number:</label>
											<input type="tel" className={"form-control " + this.state.corpSignUpPhoneError} placeholder="XXXXXXXXXX" required onChange={(e) => { this.handleChange('corpSignUpPhone', e.target.value) }} />
										</div>

										<div className="form-group mb15">
											<label className="control-label is-imp">Where is your company located:</label>
											<PlacesAutocomplete inputProps={inputProps} classNames={cssClasses} options={options} />
										</div>
									</Row>
									<Row>
										<div className="form-group hidden" id="already-signed-up">
											<span>

											</span>
										</div>
									</Row>
									<div id="captcha-sign-up"><ReCAPTCHA
										sitekey="6Ld94V4aAAAAAK4rhWz-aJc-oBR-iS6PCkIEquuz"
										onChange={this.onCaptchaChangeSignUp}
									/></div>
									<div className="form-group">
										{this.state.captchaVerifiedSignUp ? <button id="signupCorporate" className="btn btn-center submit-btn" onClick={this.signup.bind(this, 'employee')}>
											Sign Up
										</button> : <button id="signupCorporate" className="btn btn-center submit-btn" disabled>
											Sign Up
										</button>}
									</div>
									{/* 
									<div className="form-group">
										<button id="signupCorporate" className="btn btn-center submit-btn" onClick={this.signup.bind(this, 'employee')}>
											Sign Up
										</button>
									</div> */}

									<div className="form-group extra-links">
										<span className="signup-link" onClick={e => this.flipCorporateSignin(e.target)}>
											Already have an account? Sign-in
										</span>
									</div>
									<div className="privacy-policy-container"><a href="https://www.letsendorse.com/privacy-policy" target="_blank">Privacy Policy</a></div>
								</div>
							</div>
						</div>
					</div>
				</div></div></div>

		);
	}

	cancelNgoSignin(e) {
		let evt;
		if (!e) evt = window.event;
		evt.cancelBubble = true;
		if (evt.stopPropagation) evt.stopPropagation();
		this.setInitialState(this);
		this.props.cancelNgoSignin();
	}
}

SignupSwitch.propTypes = {
	role: PropTypes.string,
};

function mapStateToProps(state) {
	return {
		role: state.RoleReducer,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		roleActions: bindActionCreators(roleActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupSwitch);