import React, { Component } from "react";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import TimePicker from "rc-time-picker";
import Switch from "rc-switch";
import swal from "sweetalert2";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

import "sweetalert2/dist/sweetalert2.min.css";
import { Check } from "react-feather";
import configs from "../../../config/urlConfigs.js";

const readCookie = require("../../../cookie.js").readCookie;
const initialAnswers = {};

export default class PledgesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pledges: [],
      answers: initialAnswers,
      pledgeTakenByUser: false,
      isSubmitDisabled: true
    };
    this.updateGeneralChange = this.updateGeneralChange.bind(this);
  }

  componentDidMount() {
    function addActivityStatus(array) {
      const currentDate = new Date();

      // Iterate through each element of the array
      array.forEach((item) => {
        // Convert date string to Date object
        const [day, month, year] = item.endsOn.split("/");

        // JavaScript Date object takes month as 0-indexed (January is 0, February is 1, etc.)
        // So we need to subtract 1 from the month value
        const itemDate = new Date(`${year}-${month}-${day}`);

        // Check if the date is active or inactive
        console.log("itemDate", itemDate, currentDate);
        item.active = itemDate >= currentDate ? true : false;
      });

      return array;
    }

    fetch(configs.BACKEND_API_URL + "/ee/pledges/get-all-pledge", {
      method: "GET",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {
          const updatedArray = addActivityStatus(data.pledges);
          updatedArray.forEach((pledge, pledgeIndex) => {
            if (pledge.active && pledge.Questions.length > 0) {
              pledge.Questions.forEach((_, questionIndex) => {
                const key = `${questionIndex}`;
                initialAnswers[key] = false; // Set default value for each question of active pledges
              });
            }
          });
          this.setState({ pledges: updatedArray });
        }.bind(this)
      );
      const pledgeId =  this.props.match.params.pledgeId
    fetch(configs.BACKEND_API_URL + `/ee/pledges/get-pledge/${pledgeId}`, {
      method: "GET",
      headers: {
        Auth: JSON.parse(readCookie("access_token")).access_token,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {
          this.setState({
            pledgeTakenByUser: data.isPledge,
          });
        }.bind(this)
      );
  }

  declare() {
    let data = {};
    data["responses"] = this.state.answers;

    this.state.pledges.map((pledge) => {
      if (pledge.active) {
        data["pledgeId"] = pledge._id;
      }
    });
    fetch(configs.BACKEND_API_URL + "/ee/pledges/add-pledge", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: JSON.parse(readCookie("access_token")).access_token,
      },
      body: JSON.stringify(data),
    })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (data) {
          if (data.status) {
            swal({
              title: "Awesome!",
              html: `<p>Thank you for choosing to be a #WaterSaver</p>`,
              //  showCancelButton: true,
              cancelButtonColor: configs.THEME_COLOR,
            }).then((result) => {
              if (result) {
                // Redirect to a different page
                window.location.href = "/feed";
              }
            });
          }
        }.bind(this)
      );
  }

  updateGeneralChange(pledgeIndex, questionIndex, e) {
    // const key = `${questionIndex}`; // Create a unique key for each question
    // const key = `${questionIndex}`;

    // const isSubmitDisabled = Object.values(updatedAnswers).every(answer => !answer);

    // this.setState((prevState) => ({
    //   answers: {
    //     ...prevState.answers,
    //     [key]: !prevState.answers[key], // Toggle the answer for the question
    //   },
    // }));

    const key = `${questionIndex}`;
    const updatedAnswers = {
      ...this.state.answers,
      [key]: !this.state.answers[key]
    };
    const isSubmitDisabled = Object.values(updatedAnswers).every(answer => !answer);
    this.setState({
      answers: updatedAnswers,
      isSubmitDisabled: isSubmitDisabled
    });
  }

  render() {
    return (
      <>
        {" "}
        {this.state.pledgeTakenByUser ? (
          <>
            {" "}
    <div className="pledge-form-thank-you-container pledge-form-thannk-you-container-small" >
            <Row>
              <Col
                md={12}
                className="pledge-image-container"
              >
                <div>
                  {this.state.pledges.length > 0 &&
                    this.state.pledges.map((pledge, pledgeIndex) => {
                      if (pledge.active && pledge.coverImageUrl) {
                        // Assuming you have a condition to determine the source dynamically
                        let imgSrc = pledge.coverImageUrl ||
                           "https://cdn1.byjus.com/wp-content/uploads/2023/05/Rainwater-harvesting-1.png" 

                        return (
                          <img
                            key={pledgeIndex}
                            style={{
                              maxWidth: "100%", // Ensure the image doesn't exceed the container width
                              maxHeight: "100%", // Ensure the image doesn't exceed the container height
                              // borderRadius: "50%",
                            }}
                            className="pledge-image"
                            src={imgSrc}
                            alt={`Pledge ${pledgeIndex}`}
                          />
                        );
                      }
                      return null; // Return null if pledge is not active
                    })}
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div>
                  {this.state.pledges.length > 0 &&
                    this.state.pledges.map((pledge, pledgeIndex) => {
                      if (pledge.active) {
                        return (
                          <p
                          className="pledge-title pledge-thank-you"
                          style={{  fontSize: 16,
                            fontWeight: "bold",
                            textAlign: "center"}}
                          >
                           {/* Thank you for taking the pledge - {pledge.title} */}
                           Thank you for choosing to be a #WaterSaver
                          </p>
                        );
                      }
                      return null; // Return null if pledge is not active
                    })}
                </div>
              </Col>{" "}
            </Row>
            <Button
                  type="button"
                  className="btn btn-primary pledge-submit-btn pledge-feed-btn"
                  onClick={() => (window.location.href = "/feed")}
                >
                  Go back to feed
                </Button>
          </div>
          </>
        ) : (
          <div
            className="pledge-form-container"
            
          >
            <Row>
              <Col
                md={12}
                className="pledge-image-container"
              >
                <div>
                  {this.state.pledges.length > 0 &&
                    this.state.pledges.map((pledge, pledgeIndex) => {
                      if (pledge.active && pledge.coverImageUrl) {
                        // Assuming you have a condition to determine the source dynamically
                        let imgSrc =  
                        pledge.coverImageUrl || 
                           "https://cdn1.byjus.com/wp-content/uploads/2023/05/Rainwater-harvesting-1.png" 

                        return (
                          <img
                            key={pledgeIndex}
                            style={{
                              maxWidth: "100%", // Ensure the image doesn't exceed the container width
                              maxHeight: "100%", // Ensure the image doesn't exceed the container height
                              // borderRadius: "50%",
                            }}
                            className="pledge-image"
                            src={imgSrc}
                            alt={`Pledge ${pledgeIndex}`}
                          />
                        );
                      }
                      return null; // Return null if pledge is not active
                    })}
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12} style={{ alignItems: "center", top: "15px" }}>
                <div>
                  {this.state.pledges.length > 0 &&
                    this.state.pledges.map((pledge, pledgeIndex) => {
                      if (pledge.active) {
                        return (
                          <p
                          className="pledge-title"
                          style={{  fontSize: "26px",
                            fontWeight: "bolder",
                            textAlign: "center",
                            // transform: "scaleX(1.5)"
                          }}
                          >
                            {pledge.title}
                          </p>
                        );
                      }
                      return  <p
                      className="pledge-title"
                      style={{  fontSize: "26px",
                        fontWeight: "bolder",
                        textAlign: "center",
                        // transform: "scaleX(1.5)"
                      }}
                      >
                        {pledge.title}
                      </p>; // Return null if pledge is not active
                    })}
                </div>
              </Col>{" "}
            </Row>
   {/*  for pledge description */}
   <Row style={{ paddingLeft: "90px" }}>
              <Col md={12} style={{ marginTop: 20 }}>
                <div className="mt10">
                  {this.state.pledges.length > 0 &&
                    this.state.pledges.map((pledge, pledgeIndex) => {
                      if (pledge.active && pledge.description ) {
                        <p className="pledge-oath" style={{fontWeight: "bold"}}>
                          {pledge.description}
                        </p>
                      } else {
                        return null; // No active pledges or questions
                      }
                    })}
                </div>
              </Col>
            </Row>
            

            {/* for oath message and questions */}
            <div>
        {this.state.pledges.length > 0 &&
          this.state.pledges.map((pledge, pledgeIndex) => {
            if (pledge.active) {
              return (
                <React.Fragment key={pledgeIndex}>
                  <Row style={{ paddingLeft: '90px' }}>
                    <Col md={12} style={{ marginTop: 20 }}>
                      <p
                        className="pledge-oath"
                        style={{
                          fontWeight: 'bolder',
                          color: '#333',
                        }}
                      >
                        I take an oath to conserve water and use it judiciously. I pledge to take the following action(s). Select the action(s) below and click on submit.
                      </p>
                      <div className="mt10">
                        {pledge.Questions.length > 0 &&
                          pledge.Questions.map((question, questionIndex) => {
                            const key = `${questionIndex}`;
                            const text = question;

                            // Split the text based on "("
                            const parts = text.split('(');

                            // Map through the parts and add <br/> after each part except the last one
                            const newText = parts.map((part, index) => (
                              <React.Fragment key={index}>
                                {part}
                                {index !== parts.length - 1 && <span>.</span>}
                                {index !== parts.length - 1 && <br />}
                                {index !== parts.length - 1 && '('}
                              </React.Fragment>
                            ));

                            return (
                              <div className="form-group form-check checkbox-row" key={questionIndex}>
                                <a
                                  className={this.state.answers[key] ? 'checkbox-active' : 'checkbox-inactive'}
                                  onClick={() => this.updateGeneralChange(pledgeIndex, questionIndex)}
                                >
                                  <Check color="white" style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
                                </a>
                                <label
                                  className="pledge-form-check-label"
                                  style={{ margin: 10 }}
                                  htmlFor={`enable-enableEmployeeDetailsConfirmation-${questionIndex}`}
                                >
                                  {newText}
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    </Col>
                  </Row>
                  <Button
                    type="button"
                    className="btn btn-primary pledge-submit-btn"
                    onClick={this.declare}
                    disabled={this.state.isSubmitDisabled}
                  >
                    Submit
                  </Button>
                </React.Fragment>
              );
            } else {
              return (
                <Row key={pledgeIndex}>
                  <Col md={12} style={{ marginTop: 20 }}>
                    <p
                      className="pledge-oath"
                      style={{
                        fontWeight: 'bolder',
                        textAlign: 'center',
                        color: '#333',
                      }}
                    >
                      The Pledge Form is no longer available since the IndusInd Bank Water Month has concluded.
                    </p>
                  </Col>
                  <Button
                    type="button"
                    className="btn btn-primary pledge-submit-btn pledge-feed-btn"
                    style={{ marginLeft: '45%' }}
                    onClick={() => (window.location.href = '/feed')}
                  >
                    Go back to feed
                  </Button>
                </Row>
              );
            }
          })}
      </div>
            
          </div>
        )}
      </>
    );
  }
}
