import React, { useState, useEffect, useReducer, useContext } from 'react'
import { Button, Modal, Row, Col } from 'react-bootstrap'
import { readCookie } from '../../../../../cookie';
import { OrgDataContext } from '../../../../../routes';
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from "react-bootstrap-table";


const SingleOpportunityFamilyFriendModal = ({ show, handleClose, opportunity, index, guestList }) => {
    const organisationData = useContext(OrgDataContext);
    const [friendsList, setFriendsList] = useState([]);

    let attendee = opportunity.attendees[index];
    // useEffect(() => {
    //     // getFriendsList();
    // }, [])
    useEffect(() => {
        let attendeeId = attendee['id'];
        guestList.map((data) => {
            if (data.volunteerId === attendeeId) {
                setFriendsList(data.friends)
            }
        })
    }, [attendee])


    //    const getFriendsList = () => {
    //     let id = attendee['id'];
    //     let opportunityId = opportunity.id
    //         fetch(process.env.REACT_APP_API_URL + '/ee/friends-list/' 
    //         + opportunityId + "/" + id,
    //             {   method: "GET",
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Auth': JSON.parse(readCookie('access_token')).access_token
    //                 },
    //             }).then(function (response) {
    //                 return response.json();
    //             }).then(function (data) {
    //                 setFriendsList(data.friends);
    //             }.bind(this))
    //     }
    const approval =(cell, row,props) =>{
        // opportunity.employeeAttendanceOverride && opportunity.status === "Completed" ? (
        //     friend.hasOwnProperty("adminName") ? (friend.attended === true ? (<Col md={2} sm={2} style={{ padding: 0 }}>
        //         <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >Admin Approved</label>
        //     </Col>) : (<Col md={2} sm={2} style={{ padding: 0 }}>
        //         <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >Admin Declined</label>
        //     </Col>)) : (null)
        // ) : (null)
        
        if(opportunity.employeeAttendanceOverride && opportunity.status ==='Completed'){
            if(row.hasOwnProperty("adminName") && row.adminName){
                if(row.attended === true){
                    return <div>Admin Approved</div>
                }
                else {
                    return <div>Admin Declined</div>
                }
        }
    }

    }
    const participatedInEvent =(cell, row,props) =>{
        if(opportunity.status ==='Completed'){
                if(row.attended){
                    return <div>Yes</div>
                }
                else {
                    return <div>No</div>
                    }
    }

    }
    return (
        <Modal show={show} onHide={handleClose} bsSize='lg'>
            <Modal.Header closeButton>
                <Modal.Title><strong>Guest list of {attendee.fullName}</strong></Modal.Title>
            </Modal.Header>
            <Modal.Body bsSize='lg'>
                {/* <Row style={{ margin: 0 }}>
                    <Col md={5} sm={2} style={{ padding: 0 }}>
                        <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >Name</label>
                    </Col>
                    <Col md={2} sm={2} style={{ padding: 0 }}>
                        <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsGender' >Gender</label>
                    </Col>
                    <Col md={2} sm={2} style={{ padding: 0 }}>
                        <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >Age</label>
                    </Col>
                    {
                        opportunity.employeeAttendanceOverride
                        // organisationData.eePreferences.enableEmployeeAttendance
                        && opportunity.status === "Completed" ?
                        <Col md={2} sm={2} style={{ padding: 0 }}>
                            <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >Admin Approval</label>
                        </Col> : null
                    }

                    {friendsList.map((friend, index) =>
                        <div key={index}>
                            <Col md={5} sm={2} style={{ padding: 0 }}>
                                <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >{friend.name}</label>
                            </Col>
                            <Col md={2} sm={2} style={{ padding: 0 }}>
                                <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsGender' >{friend.gender}</label>
                            </Col>
                            <Col md={2} sm={2} style={{ padding: 0 }}>
                                <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >{friend.age}</label>
                            </Col>

                            {
                                // organisationData.
                                //     eePreferences.enableEmployeeAttendance 
                                    opportunity.employeeAttendanceOverride && opportunity.status === "Completed" ? (
                                    friend.hasOwnProperty("adminName") ? (friend.attended === true ? (<Col md={2} sm={2} style={{ padding: 0 }}>
                                        <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >Admin Approved</label>
                                    </Col>) : (<Col md={2} sm={2} style={{ padding: 0 }}>
                                        <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >Admin Declined</label>
                                    </Col>)) : (null)
                                ) : (null)

                            }
                        </div>
                    )}
                </Row> */}
                {/* <Row style={{ margin: 0 }}>
                    <Col md={4} sm={4} style={{ padding: 0 }}>
                        <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' > Name</label>
                    </Col>
                    <Col md={3} sm={3} style={{ padding: 0 }}>
                        <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsGender' >Gender</label>
                    </Col>
                    <Col md={3} sm={3} style={{ padding: 0 }}>
                        <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >Age</label>
                    </Col>
                    {
                        opportunity.employeeAttendanceOverride
                        // organisationData.eePreferences.enableEmployeeAttendance
                        && opportunity.status === "Completed" ?
                        <Col md={2} sm={2} style={{ padding: 0 }}>
                            <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >Admin Approval</label>
                        </Col> : null
                    }
                    {/* {friendsList.map((friend, index) => <div>
                        <Col md={6} sm={6} style={{ padding: 0 }}>
                            <div className="form-group pb0 mb0" >
                                <input
                                    type="text"
                                    className="form-control"
                                    value={friend.name}
                                    disabled={friend._id}
                                    onChange={(e) => handleAddFriends(index, e.target.value, 'name')}
                                />
                            </div>
                        </Col>

                        <Col md={3} sm={3} style={{ padding: 0 }}>
                            <div className="form-group pb0 mb0">
                                <select
                                    className="form-control"
                                    required
                                    value={friend.gender}
                                    disabled={friend._id}
                                    onChange={(e) => handleAddFriends(index, e.target.value, 'gender')}
                                >
                                    <option selected disabled>Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Prefer not to say">Prefer not to say</option>
                                </select>
                            </div>
                        </Col>
                        <Col md={2} sm={2} style={{ padding: 0 }}>
                            <div className="form-group pb0 mb0">
                                <input
                                    type="number"
                                    className="form-control"
                                    value={friend.age}
                                    disabled={friend._id}
                                    onChange={(e) => handleAddFriends(index, e.target.value, 'age')}
                                />
                            </div>
                        </Col>
                        <Col md={1} sm={1} style={{ padding: 0 }}>
                            <div className="form-group pb0 mb0">
                                <button className='btn btn-primary btn-sm' style={{ marginLeft: 5 }}
                                    // onClick={() => deleterow(index)}
                                    onClick={() => removeFriends(friend._id)}
                                >
                                    remove</button>
                            </div>
                        </Col>
                    </div>)} */}
                     {/* {friendsList.map((friend, index) =>
                        <div key={index}>
                            <Col md={5} sm={2} style={{ padding: 0 }}>
                                <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >{friend.name}</label>
                            </Col>
                            <Col md={3} sm={2} style={{ padding: 0 }}>
                                <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsGender' >{friend.gender}</label>
                            </Col>
                            <Col md={3} sm={2} style={{ padding: 0 }}>
                                <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >{friend.age}</label>
                            </Col>

                            {
                                // organisationData.
                                //     eePreferences.enableEmployeeAttendance 
                                    opportunity.employeeAttendanceOverride && opportunity.status === "Completed" ? (
                                    friend.hasOwnProperty("adminName") ? (friend.attended === true ? (<Col md={2} sm={2} style={{ padding: 0 }}>
                                        <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >Admin Approved</label>
                                    </Col>) : (<Col md={2} sm={2} style={{ padding: 0 }}>
                                        <label className="control-label ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >Admin Declined</label>
                                    </Col>)) : (null)
                                ) : (null)

                            }
                        </div>
                    )} */}
                {/* </Row> */} 
                <BootstrapTable 
                            data={friendsList}
                            hover
                            responsive
                            // options={externalParticipantsOptions}
                           
                          >
                         
                              <TableHeaderColumn
                                width="240px"
                                dataField="name"
                                dataSort
                                export
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                                isKey={true}

                              >
                                Name
                              </TableHeaderColumn>
                            <TableHeaderColumn
                              width="180px"
                              dataField="gender"
                            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              export
                            >
                              Gender
                            </TableHeaderColumn>

                            <TableHeaderColumn
                              width="200px"
                              dataField="age"
                              export
                              tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                              Age
                            </TableHeaderColumn>
                            {opportunity.status === "Completed" ? <TableHeaderColumn  
                            width="200px"
                            dataFormat={participatedInEvent}
                            export
                            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >
                                Participated
                            </TableHeaderColumn> :null}
                            
                            { opportunity.employeeAttendanceOverride
                        && opportunity.status === "Completed" ?  (
                              <TableHeaderColumn
                                width="160px"
                                export={false}
                                dataFormat={approval}
                                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                              >
                                Admin Approval
                              </TableHeaderColumn>
                            ) : null}
                              
                                </BootstrapTable>
            </Modal.Body>
        </Modal>
    )
}

export default SingleOpportunityFamilyFriendModal