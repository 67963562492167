const initialState = {
	opportunities: null,
	causes: null,
	location: null,
	selectedOpportunity: {},
	commentText: '',
	subCommentText: '',
	commentId: '',
	commentImage: [],
	toggleCommentPost: true,
	participantsData: [],
	pageNumber: 1,
	totalOpportunities: 0,
};

const EEDiscoverOpportunityReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_OPPORTUNITIES':
			const toReturn = {
				opportunities: action.opportunities,
			};
			if (action.totalOpportunities) {
				toReturn.totalOpportunities = action.totalOpportunities;
			}
			return Object.assign({}, state, toReturn);
		case 'UPDATE_PAGE_NUMBER':
			return Object.assign({}, state, {
				pageNumber: action.pageNumber,
			});
		case 'UPDATE_SELECTED_OPPORTUNITY':
			return Object.assign({}, state, {
				// attendeeDeclaration:action.,
				participantsData: action.participantsData,
				selectedOpportunity: action.selectedOpportunity
			});
		case 'UPDATE_CAUSES':
			return Object.assign({}, state, {
				causes: action.causes,
			});
		case 'UPDATE_LOCATION':
			return Object.assign({}, state, {
				location: action.location,
			});
		case 'UPDATE_COMMENT_TEXT':
			return Object.assign({}, state, {
				commentText: action.commentText,
			});
		case 'UPDATE_COMMENT_IMAGE':
			return Object.assign({}, state, {
				commentImage: action.commentImage,
			});
		case 'UPDATE_SUB_COMMENT_TEXT':
			return Object.assign({}, state, {
				subCommentText: action.subCommentText,
			});
		case 'UPDATE_COMMENT_ID':
			return Object.assign({}, state, {
				commentId: action.commentId,
			});
		case 'UPDATE_TOGGLE_COMMENT_POST':
			return Object.assign({}, state, {
				toggleCommentPost: action.toggleCommentPost,
			});
		default:
			return state
	}
}

export default EEDiscoverOpportunityReducer;