export const updateCountOfVos = (countOfVos) => {
	return {
		countOfVos,
		type: 'UPDATE_COUNT_OF_VOS'
	}
};

export const updateCountOfCompletedVos = (countOfCompletedVos) => {
	return {
		countOfCompletedVos,
		type: 'UPDATE_COUNT_OF_COMPLETED_VOS'
	}
};

export const updateTotalHoursClocked = (totalHoursClocked) => {
	return {
		totalHoursClocked,
		type: 'UPDATE_TOTAL_HOURS_CLOCKED'
	}
};

export const updateHistory = (history) => {
	return {
		history,
		type: 'UPDATE_HISTORY'
	}
};

export const updateHoursClocked = (hoursClocked) => {
	return {
		hoursClocked,
		type: 'UPDATE_HOURS_CLOCKED'
	}
};

export const updateCompletedCount = (completedCount) => {
	return {
		completedCount,
		type: 'UPDATE_COMPLETED_COUNT'
	}
};

export const updateUpcomingCount = (upcomingCount) => {
	return {
		upcomingCount,
		type: 'UPDATE_UPCOMING_COUNT'
	}
};

export const updateCauses = (causes) => {
	return {
		causes,
		type: 'UPDATE_CAUSES'
	}
};

export const updateLocation = (location) => {
	return {
		location,
		type: 'UPDATE_LOCATION'
	}
}
export const updateProjectDescription = (descriptions) => {
	return {
		descriptions,
		type: 'UPDATE_PROJECT_DESCRIPTION'
	}
}