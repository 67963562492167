import React, { Component } from 'react';
import { Menu } from 'antd';
import { ic_business } from "react-icons-kit/md/ic_business";

import SvgIcon from 'react-icons-kit';
import { ic_aspect_ratio } from 'react-icons-kit/md/ic_aspect_ratio';
import { dashboard } from 'react-icons-kit/fa/dashboard';
import { lineChart } from 'react-icons-kit/fa/lineChart';
import { logout } from 'react-icons-kit/ikons/logout';
import { gear } from 'react-icons-kit/fa/gear';
import { rupee } from 'react-icons-kit/fa/rupee';
import { listUl } from 'react-icons-kit/fa/listUl';
import { group } from 'react-icons-kit/fa/group';
import { chevronLeft } from 'react-icons-kit/fa/chevronLeft';
import $ from 'jquery';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import 'antd/dist/antd.css';
import { OrgDataContext } from '../../../../routes.js';
import * as action from "../../../../actions/RoleAction";
import { eraseAllCookies } from '../../../../cookie.js';
import configs from '../../../../config/urlConfigs.js';
import { generatePreSignedGetUrl } from '../../../../s3PresignedUrls.js';

const eraseCookie = require('../../../../cookie.js').eraseCookie;
const readCookie = require('../../../../cookie.js').readCookie;

const SubMenu = Menu.SubMenu;
const subdomain = configs.SUBDOMAIN;
// const subdomain = window.location.hostname.split('.')[0];

class Header extends Component {
	constructor() {
		super();
		let defaultSelected = window.location.href.split('/').pop();
		this.state = {
			defaultSelected,
			profilePictureUrl:'',
		}
	}

	logout() {
		fetch(configs.BACKEND_API_URL + '/logout', {
			method: "get",
			credentials: "include",
			headers: {
				Auth: JSON.parse(readCookie("access_token")).access_token,
			},
		})
			.then((data) => data.json())
			.then((data) => {
				eraseAllCookies();
				eraseCookie('userData');
				eraseCookie('access_token');
				eraseCookie('letsEngageVersion');
				window.location.pathname = '/';
			});
	}

	gotoPage(page) {
		// window.location.pathname = '/admin' + page;
		window.location.pathname =  page;
	}

	handleClick(e) {
		if (e.key === 'contribution-analytics') this.gotoPage('/contribution-analytics');
		else if (e.key === 'volunteering-analytics') this.gotoPage('/volunteering-analytics');
		else if (e.key === 'leaderboard') this.gotoPage('/leaderboard');
		else if (e.key === 'bulletin') this.gotoPage('/bulletin');
		else if (e.key === 'opportunity-recommendations') this.gotoPage('/opportunity-recommendations');
		else if (e.key === 'volunteer-management') this.gotoPage('/volunteer-management');
		else if (e.key === 'create-volunteer') this.gotoPage('/volunteer-opportunity/create');
		else if (e.key === 'fundraiser-management') this.gotoPage('/fundraiser-management');
		else if (e.key === 'team-management') this.gotoPage('/team-management');
		else if (e.key === 'manage-ngos') this.gotoPage('/manage-ngos');
		else if (e.key === 'settings') this.gotoPage('/settings');
		else if (e.key === 'billing') this.gotoPage('/billing');
		else if (e.key === 'admin') {
			this.props.actions.updateUserRole('user');
			window.location.pathname = '/';
		}
		else if (e.key === 'logout') this.logout();
	}

	openProfileEditModal = () => {
		if (window.location.pathname.match('profile') === null) this.gotoPage("/profile");
	}

	componentDidMount() {
		var that = this;
		if (JSON.parse(readCookie('access_token')) && JSON.parse(readCookie('access_token')).access_token) {
			fetch(configs.BACKEND_API_URL + '/get-user-data', {
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
			}).then(function (response) {
				return response.json();
			}).then(function (responseData) {
				let userData = JSON.parse(readCookie("userData"));
				let data = responseData.data;
				that.setState({ profilePictureUrl: data.profilePicture });
				userData.fullName = data.fullName;
				userData.mobileNo = data.mobileNo;
				userData.department = data.department;
				userData.profilePicture = data.profilePicture;
				userData.designationAtOrganisation = data.designationAtOrganisation;
				delete userData.organisationData;
				delete userData.questionnairePreferences;
				delete userData.preference;

				createCookie("userData", JSON.stringify(userData), 7);
			});
		}
		$(document).ready(function () {
			var userDivHeight = $('.ee-header-container div.ee-header-user').height();
			var headerTop = parseInt($('.ee-header-container').css('top').split('px')[0]);
			if (headerTop > 0) headerTop += 40;
			$('.ee-header-container ul#ee-navbar').css('max-height', 'calc(100% - ' + (userDivHeight + 185 + headerTop) + 'px)')
		});
	}

	handleClickChangeRoleButton(){
			this.props.actions.updateUserRole('user');
			window.location.pathname = '/';
	}
	render() {
		let defaultOpenKeys = [];
		if (this.props.selected === 'contribution-analytics' || this.props.selected === 'volunteering-analytics') defaultOpenKeys[0] = 'dashboard';
		else if (this.props.selected === 'opportunity-recommendations' || this.props.selected === 'volunteer-management' || this.props.selected === 'fundraiser-management' || this.props.selected === 'create-volunteer' || this.props.selected === 'manage-ngos') defaultOpenKeys[0] = 'admin-management';

		return (
			<OrgDataContext.Consumer>
				{(organisationData) => {
					return <div className={subdomain.toLowerCase() === 'indusind' ? "ee-header-container ibl-header-container" : "ee-header-container"}>
				<div className="ee-header">
					{/* {subdomain.toLowerCase() === 'indusind' ? <div className="ee-org-logo-container">
						<img src="/img/IBL_Logo_top_left.png" width={150} alt="IBL Logo PNG" /> */}
						{/* <div className="top-right-logo-image">
							<img src="/img/IBL_Logo_top_right.png" width={150} alt="IBL Logo PNG" />
						</div> */}
					{/* </div> : (null)} */}
					<div className="ee-header-user">
						<div className="ee-header-user-profile">
							{/* {JSON.parse(readCookie("userData")).profilePicture ? (
								<img className="ee-header-user-profile-img" src={JSON.parse(readCookie("userData")).profilePicture || "/img/user.png"} />
							) : (
								<img className="ee-header-user-profile-img" src="/img/user.png" />
									)} */}
																{this.state.profilePictureUrl ? (
								<img className="ee-header-user-profile-img" src={generatePreSignedGetUrl(this.state.profilePictureUrl) || "/img/user.png"} />
								// <img className="ee-header-user-profile-img" src={this.state.profilePictureUrl || "/img/user.png"} />
							) : (
								<img className="ee-header-user-profile-img" src="/img/user.png" />
							)}
							<i className={subdomain.toLowerCase() === 'indusind' ? "fa fa-pencil ee-header-user-profile-edit" : "fa fa-pencil ee-header-user-profile-edit"} onClick={this.openProfileEditModal}></i>
						</div>
						<div className="details">
							<div className="details-name">
								<p className="details-user-name">
									{JSON.parse(readCookie("userData")).fullName}
								</p>
							</div>
							{JSON.parse(readCookie("userData")).email ? (
								<div className="details-email">
									<p className="details-user-email">
										{JSON.parse(readCookie("userData")).email}
									</p>
								</div>
							) : (null)}
							{JSON.parse(readCookie("userData")).mobileNo && JSON.parse(readCookie("userData")).mobileNo !== "false" ? (
								<div className="details-mobile">
									<p className="details-user-mobile">
										{JSON.parse(readCookie("userData")).mobileNo}
									</p>
								</div>
							) : (null)}
						</div>
					</div>
					<Menu id="ee-navbar" onClick={this.handleClick.bind(this)} style={{ width: 300 }} defaultSelectedKeys={[this.props.selected]} defaultOpenKeys={defaultOpenKeys} mode="inline">

						<SubMenu key="dashboard" title={<div><SvgIcon size={14} icon={dashboard} /><span className="ee-header-menu-text">Dashboard</span></div>}>
							<Menu.Item key="volunteering-analytics">
								<SvgIcon size={14} icon={ic_aspect_ratio} />
								<span className="ee-header-menu-text">Volunteering Analytics</span>
							</Menu.Item>

									{
										organisationData.eePreferences
											.enableGiveBack || organisationData.eePreferences
												.enablePayrollGiving ? <Menu.Item key="contribution-analytics">
								<SvgIcon size={14} icon={ic_aspect_ratio} />
								<span className="ee-header-menu-text">Contribution Analytics</span>
							</Menu.Item>: null}
						</SubMenu>

						<Menu.Item key="leaderboard">
							<SvgIcon size={14} icon={lineChart} />
							<span className="ee-header-menu-text">Leaderboard</span>
						</Menu.Item>

						<Menu.Item key="bulletin">
							<SvgIcon size={14} icon={listUl} />
							<span className="ee-header-menu-text">Bulletin</span>
						</Menu.Item>

						<SubMenu key="admin-management" title={<div><SvgIcon size={14} icon={ic_aspect_ratio} /><span className="ee-header-menu-text">Project Management</span></div>}>
							<Menu.Item key="create-volunteer">
								<SvgIcon size={14} icon={ic_aspect_ratio} />
								<span className="ee-header-menu-text">Create Volunteer Opportunity</span>
							</Menu.Item>

							<Menu.Item key="volunteer-management">
								<SvgIcon size={14} icon={ic_aspect_ratio} />
								<span className="ee-header-menu-text">Manage Volunteer Opportunities</span>
							</Menu.Item>

							{subdomain!=='indusind' ? <Menu.Item key="opportunity-recommendations">
								<SvgIcon size={14} icon={ic_aspect_ratio} />
								<span className="ee-header-menu-text">Opportunity Recommendations</span>
							</Menu.Item>:null}

									{organisationData.eePreferences
										.enableGiveBack && 
								<Menu.Item key="fundraiser-management">
								<SvgIcon size={14} icon={ic_aspect_ratio} />
								<span className="ee-header-menu-text">Fundraisers</span>
							</Menu.Item>}

									{organisationData.eePreferences
										.enableGiveBack || organisationData.eePreferences
										.enablePayrollGiving ? <Menu.Item key="manage-ngos">
								<SvgIcon size={14} icon={ic_aspect_ratio} />
								<span className="ee-header-menu-text">Manage NGOs</span>
							</Menu.Item> : null}
						</SubMenu>

						<Menu.Item key="team-management">
							<SvgIcon size={14} icon={group} />
							<span className="ee-header-menu-text">Team Management</span>
						</Menu.Item>

						<Menu.Item key="settings">
							<SvgIcon size={14} icon={gear} />
							<span className="ee-header-menu-text">Settings</span>
						</Menu.Item>

						{/*<Menu.Item key="billing">
							<SvgIcon size={14} icon={rupee} />
							<span className="ee-header-menu-text">Billing</span>
						</Menu.Item>*/}


						<Menu.Item key="logout">
							<SvgIcon size={14} icon={logout} />
							<span className="ee-header-menu-text">Logout</span>
						</Menu.Item>

						{/* <Menu.Item className="back-to-employee-mode" key="admin">
							<SvgIcon size={14} icon={chevronLeft} />
							<span className="ee-header-menu-text" style={{ fontSize: 12 }}>Switch to Employee Mode</span>
						</Menu.Item> */}
					</Menu>
					{subdomain!=='indusind' ? <><div  onClick={this.handleClickChangeRoleButton.bind(this)} className="back-to-employee-mode sidebar-letsengage-employee-button" key="admin">
							<SvgIcon size={14} icon={chevronLeft} />
							<span className="ee-header-menu-text" style={{ fontSize: 12 }}>Switch to Employee Mode</span>
					</div>
					<div id="powered-by-container-letsengage">
						<span><SvgIcon size={14} icon={ic_business} /> &ensp;
							<a style={{ color: "#fff" }} href="https://www.letsendorse.com/privacy-policy" target="_blank">Privacy Policy</a>
						</span>
						 <a href="https://www.letsendorse.com/" target="_blank">
              Powered by
              <img src="/img/powered_by_le.png" alt="Powered By LetsEndorse" />
                </a>
					</div></> :<>
					<div  onClick={this.handleClickChangeRoleButton.bind(this)} className="back-to-employee-mode sidebar-employee-button" key="admin">
					<SvgIcon size={14} icon={chevronLeft} />
					<span className="ee-header-menu-text" style={{ fontSize: 12 }}>Switch to Employee Mode</span>
			</div>
			<div id="powered-by-container">
				<span><SvgIcon size={14} icon={ic_business} /> &ensp;
					<a style={{ color: "#fff" }} href="https://www.letsendorse.com/privacy-policy" target="_blank">Privacy Policy</a>
				</span>
				 <a href="https://www.letsendorse.com/" target="_blank">
	  Powered by
	  <img src="/img/powered_by_le.png" alt="Powered By LetsEndorse" />
		</a>
			</div></>
					}
					
				</div>
			</div>}}
			</OrgDataContext.Consumer >
		);
	}
}


Header.propTypes = {
	role: PropTypes.string,
};

function mapStateToProps(state) {
	return {
		role: state.RoleReducer,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

