import React, { Component } from 'react';
import {Row, Col, Button, Modal} from 'react-bootstrap';
import $ from 'jquery';
import swal from 'sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

const readCookie = require('../../../../cookie.js').readCookie;

export default class TransferMemberModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedOffice: '',
			selectedDepartment: this.props.selectedDepartment,
			error: []
		};
	}

	onChange(name, e) {
		this.setState({ [name]: e.target.value });
		if(name === 'selectedOffice') this.setState({ error: [] });
	}

	transferMemberCheck() {
		if(this.state.selectedOffice && this.state.selectedDepartment) {
			// transfer employee api
		} else {
			let error = [];
			if(!this.state.selectedOffice) error.push('selectedOffice');
			this.setState({ error });
		}
	}

	render() {
		return (
			<Modal bsSize="large" className="transfer-member-modal" show={this.props.transferMemberModalVisibility} onHide={this.props.hideTransferMemberModal}>
        <Modal.Header>
          <Modal.Title className="modal-header-custom">
            Transfer Employee
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="transfer-member-modal-content">
          	<div className="form-group col-md-4">
	          	<label className="control-label label is-imp">Select New Office: </label>
	          	<select className="form-control" onChange={this.onChange.bind(this, 'selectedOffice')} value={this.state.selectedOffice}>
	          		<option value='' disabled>Select New Office</option>
	          		{this.props.offices.map(function(office, index) {
	          			return (
	          				<option value={office.id} key={index}>{office.name}</option>
	          			)
	          		}.bind(this))}
	          	</select>
	          	{this.state.error.indexOf('selectedOffice') > -1 ? (
                <span className="red-color">Incorrect Office!</span>
              ) : (null)}
	          </div>

	          <div className="form-group col-md-4">
	          	<label className="control-label label is-imp">Department: </label>
	          	<select className="form-control" onChange={this.onChange.bind(this, 'selectedDepartment')} value={this.state.selectedDepartment}>
	          		{this.props.departments.map(function(department, index) {
	          			return (
	          				<option value={department} key={index}>{department}</option>
	          			)
	          		}.bind(this))}
	          	</select>
	          </div>
          </Row>
        </Modal.Body>
        <Modal.Footer className="footer-center">
          <Button disabled={this.state.showSubmittingLoader} className="btn-danger mr5 ml5" onClick={this.props.hideTransferMemberModal}>Cancel</Button>
        	<Button className="mr5 ml5" onClick={this.transferMemberCheck.bind(this)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
		);
	}
}