import React from "react";
import { Link } from "react-router-dom";

const Pagination = ({
  rowsPerPage,
  totalRows,
  paginate,
  pageSize,
  url,
  currentPage,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalRows / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <nav  className='Page navigation'>
        <ul className="pagination justify-content-center" >
         
          {pageNumbers.map((number) => (
            currentPage !== number ? 
              <li key={number} className="page-item">
                <Link
                  onClick={() => {if(currentPage !== number)paginate(number)}}
                  to={`${url}`}
                  className="page-link"
                >
                  {number}
                </Link>
              </li> :
              <li key={number} className="page-item">
                <Link
                  // onClick={() => { if (currentPage !== number) paginate(number) }}
                  to={`${url}`}
                  className="page-link page-link-disabled"
                >
                  {number}
                </Link>
              </li>
            ))}
         
        </ul>
      </nav>
    </>
  );
};

export default Pagination;
